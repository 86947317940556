import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';

const TopMenuTab = ({id, link, name, active}) => {
  return (
    <NavItem>
      <NavLink to={link} tag={Link} active={active}>
        {name}
      </NavLink>
    </NavItem>     
  )
}

export default TopMenuTab;