import React, {useEffect, useState, useContext} from "react";
import { Redirect } from "react-router-dom";
import queryString from 'query-string'
import { Spinner } from 'reactstrap';
import justcastApi from '../api/justcast'
import {Mixpanel} from '../api/mixpanel'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AuthContext} from '../context/AuthContext'

const DropboxConnectionCallback = (props)  => {
  const [redirect, setRedirect] = useState('');
  const [loading, setLoading] = useState(true);
  const {add} = useContext(ModalContext);
  const authContext = useContext(AuthContext) 

  //Mixpanel
  const uid = authContext.state.uid;
  Mixpanel.identify(uid);  

  useEffect(() => {
    const values = queryString.parse(props.location.search);   
    if(values && (values['access_token'] || values['code']) ) {
      const token = values['access_token'];
      const code = values['code'];

      //TODO POST TO BACKEND
      setLoading(true);
      justcastApi.post('/v1/dropbox_connections', {dropbox_token: token, dropbox_code: code})
      .then((res) => {
        // tells user we successfully created the connection
        Mixpanel.track('Dropbox Connection Callback', {"Setup": "success"});        
        // wait 3 seconds
        setTimeout(() => {
          setLoading(false);
          add({color: 'danger', title: "Thank you" , message: "Thanks for setting up the connectin, happy podcasting.", isOpen: true})
          setRedirect("/dashboard");
        }, 5000);        
      })
      .catch((err) => {
        setLoading(false);
        Mixpanel.track('Dropbox Connection Callback', {"Setup": "failed", "error_category":"Email has not been verified with Dropbox"});
        add({color: 'danger', title: "Uh oh! Your email is not yet verified." , message: "Email has not been verified with Dropbox, please go to dropbox.com to verify your email.  Learn more on https://help.dropbox.com/accounts-billing/settings-sign-in/verify-email", isOpen: true})
        // might want to have so error message show up
        setRedirect("/dashboard");
      })
    } else {
      // might want to have so error message show up
      setLoading(false);
      // setRedirect("/dashboard")
    }
  }, [])

  if(loading) {
    return <Spinner color="primary" />;
  }
  if(redirect) {
    // wait 3 seconds to redirect, and having a spinner to indicating it's loading    
    return <Redirect to={redirect}/>
  }

  return (<></>) 
}

export default DropboxConnectionCallback