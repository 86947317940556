import React from "react";
import VideoCardItem from './VideoCardItem';

const VideoCardList = ({videos, dropboxUrl}) => {
  if(videos && videos.length > 0) {
    return (
      <>
        {
          videos.map(item => {
            return <VideoCardItem key={item.id} iconclass={item.iconclass} title={item.title} subtitle={item.subtitle} videoUrl={item.videoUrl} dropboxUrl={dropboxUrl} isDropbox={item.isDropbox}/>
          })
        }
      </>
    )
  }
  return null
}

export default VideoCardList