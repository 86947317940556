import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../../../api/justcast';
import {Context as MenuContext} from '../../../context/MenuContext'
import {Context as ScreenContext} from '../../../context/ScreenContext'
import { ListCard, KeywordsCard } from "../../../components/ai-contents";
import { Modal, ModalBody, ModalHeader, Spinner, Progress } from 'reactstrap';
import ToastMessage from '../../../components/ToastMessage';

const AiContents = (props) => {
  const {show_id, audiopost_id } =  props.match.params;
  const {setTeamEpisodePageMenus} = useContext(MenuContext);
  const screenContext = useContext(ScreenContext);
  const [transcriptId, setTranscriptId] = useState(null);
  const [aiTitle, setAiTitle] = useState(null)
  const [aiKeyword, setAiKeyword] = useState(null)
  const [aiIntroduction, setAiIntroduction] = useState(null)
  const [modalOpen, setModalOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastTitle, setToastTitle] = useState("")
  const [toastMessage, setToastMessage] = useState("")
  const [timeoutId, setTimeoutId] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    screenContext.startFetching();

    justcastApi.get(`/v2/team_podcasts/${show_id}/team_audioposts/${audiopost_id}/ai_contents`)
    .then((res) => {
      const {
        show,
        audiopost,
        transcript_id,
        ai_title,
        ai_keyword,
        ai_introduction
      } = res.data

      setAiTitle(ai_title)
      setAiKeyword(ai_keyword)
      setAiIntroduction(ai_introduction)
      setTranscriptId(transcript_id)
      screenContext.finishFetching();

      setTeamEpisodePageMenus({
        title: 'AI Contents',
        subtitle: `${show.name} > ${audiopost.name}`,
        currentPageId: 'episode',
        currentTabId: 'ai_contents',
        showId: show_id,
        episodeId: audiopost_id,
        landingPageUrl: show.landing_page_url,
        websiteUrl: show.player_page_link,
        rssFeed: show.rss_feed,
        passwordProtected: show.is_password_protected,
        advancedPrivate: show.is_advanced_private_feed,
        isPrivate: show.is_private,
        isPrivateShow: show.is_private_show,
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      const {show, audiopost} = err.response.data;
      if(show && audiopost) {
        setProcessing(true)
        setTeamEpisodePageMenus({
          title: 'AI Contents',
          subtitle: `${show.name} > ${audiopost.name}`,
          currentPageId: 'episode',
          currentTabId: 'ai_contents',
          showId: show_id,
          episodeId: audiopost.id,
          landingPageUrl: show.landing_page_url,
          websiteUrl: show.player_page_link,
          rssFeed: show.rss_feed,
          passwordProtected: show.is_password_protected,
          advancedPrivate: show.is_advanced_private_feed,
          isPrivate: show.is_private,
          isPrivateShow: show.is_private_show,
        });
      }
    })

  }, [])

  const handleReRun = (content_prompt_id) => {
    setModalOpen(true);
    if(timeoutId) {
      clearTimeout(timeoutId)
    };

    justcastApi.post(`/v2/team_podcasts/${show_id}/team_audioposts/${audiopost_id}/team_transcripts/${transcriptId}/team_content_prompts/${content_prompt_id}/rerun`)
    .then((res) => {
      const { title } = res.data

      switch(title) {
        case 'Keywords':
          setAiKeyword(res.data)
          break;
        case 'Titles':
          setAiTitle(res.data)
          break;
        case 'Introductions':
          setAiIntroduction(res.data)
          break;
        default:
          break;
      }

      setToastOpen(true)
      setToastTitle("Success")
      setToastMessage(`Generate a new ${title}`)

      const _timeOutId = setTimeout(() => {
        setToastOpen(false)
      }, 2000)

      setModalOpen(false);
      setTimeoutId(_timeOutId)
    })
    .catch((err) => {
      setModalOpen(false);
      setToastOpen(true);
      setToastTitle("Failed")
      setToastMessage(`Something went wrong`)
    })
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }

  const toggleToast = () => {
    setToastOpen(!toastOpen)
  }


  if(screenContext.state.loading) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  if(processing) {
    return (
      <div className="container-fluid">
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Generating new content</ModalHeader>
        <ModalBody>
          <Spinner/>
        </ModalBody>
      </Modal>
      <ToastMessage
        show={toastOpen}
        toggle={toggleToast}
        title={toastTitle}
        message={toastMessage}
      />
      <div className="row">
        <div className="col-12">
          {aiTitle && <ListCard
            data={aiTitle}
            handleReRun={handleReRun}
          />}
          {aiKeyword && <KeywordsCard data={aiKeyword} handleReRun={handleReRun}/>}
          {aiIntroduction && <KeywordsCard data={aiIntroduction} handleReRun={handleReRun}/>}
        </div>
      </div>
    </div>
  )
}

export default AiContents;