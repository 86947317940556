import React, {useContext} from "react";
import {Link} from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import {Context as ModalContext} from '../../context/ModalContext'

const PermissionModal = ({callback}) => {
  const {state, reset} = useContext(ModalContext);
  
  const handleCloseClick = () => {
    if(callback) {
      callback();
    }
    reset()
  }

  return (
    <Modal isOpen={state.startNewPodcast}>
      <ModalHeader toggle={handleCloseClick}>
        <strong>Warning</strong>
      </ModalHeader>
      <ModalBody>
        <p>Please upgrade your plan to unlock the restriction to create podcast without Dropbox. <Link to="/plans" className="text-warning" onClick={handleCloseClick}>Upgrade Now!</Link></p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleCloseClick}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default PermissionModal;