import React, {useContext, useEffect} from "react";
import { Route, Redirect } from 'react-router-dom'
import { Alert } from 'reactstrap';
import {Context as AuthContext} from '../context/AuthContext'

// It checks if the user is authenticated, if they are, redirect them to dashboard
// it renders the "component" prop. if not, then ask them to login/sign up

const AlertMessages = ({messages, color}) => {
  if(messages && messages.length > 0) {
    return (
      <Alert color={color === null ? "danger" : color}>
        {messages.join(' ')}
      </Alert>         
    )
  }
  return null;
}

const AuthRoute = ({ component: Component, ...rest }) => {

  const {state, tryLocalSignin} = useContext(AuthContext)
  
  useEffect(() => {
    tryLocalSignin();
  }, []) 

  if(state.uid && state.signedIn) {
    return (
      <Route {...rest} render={(props) => (
        <Redirect to='/dashboard' />
      )}/>
    )
  } else if (state.signedIn === false) {
    return (
      <Route {...rest} render={(props) => (
        <>
          <AlertMessages messages={state.errorMessages}/>
          <Component {...props} />
        </>
      )}/>
    )
  }

  return null;
}

export default AuthRoute