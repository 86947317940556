import React, {useState, useEffect, useContext} from 'react'
import {EpisodePeople, PeopleListModal, NewPersonModal} from '../episode-people'
import {Context as ModalContext} from '../../context/ModalContext'
import justcastApi from '../../api/justcast';

export default function EpisodeHosts({
  personTaxonomies,
  people,
  hosts,
  setHosts,
  setPeople,
  showId
}) {
  const modalContext = useContext(ModalContext);
  const [peopleModal, setPeopleModal] = useState(false);
  const [addPersonModal, setAddPersonModal] = useState(false);

  const togglePeopleModal = () => {
    setPeopleModal(!peopleModal)
  }

  const toggleHost = (person) => {
    if(hosts.filter((x) => x.person_id === person.id).length === 0) {
      setHosts([...hosts, {...person, id: null, person_id: person.id}]);
    } else {
      const _hosts = [...hosts];
      const __hosts = _hosts.filter((host) => host.person_id !== person.id)
      setHosts(__hosts);
    }
  }

  const handleDeleteHost = (idx) => {
    const _hosts = hosts.filter((_, i) => i !== idx);
    setHosts(_hosts);
  }

  const handleUpdateHostRole = (idx, roles) => {
    const _hosts = [...hosts];
    _hosts[idx]["roles"] = roles;
    setHosts(_hosts);
  }

  const handleAddNewPerson = ({name, email, roles, roleTitle, groupTitle}) => {
    const _person = {name, email, roles, role: roleTitle, group: groupTitle}
    setAddPersonModal(false);
    justcastApi.post(`/v1/shows/${showId}/people`, _person)
    .then((res) => {
      const person = res.data;

      modalContext.addWithMessages({
        color: "primary",
        title: 'Adding a new person',
        messages: [],
        isOpen: true,
        loading: true
      })

      setTimeout(() => {
        setHosts([...hosts, {...person, id: null, person_id: person.id}]);
        setPeople([...people, {...person, selected: true}])
        modalContext.addWithMessages({
          color: "primary",
          title: 'Success add a new person',
          messages: [`Name: ${person.name}`, `Role: ${person.role}`],
          isOpen: true,
          loading: false
        })
      }, 900)
    })
    .catch((err) => {
      if(err.response.status === 500) {
        modalContext.add({
          color: "primary",
          title: 'Error:',
          message: "Something went wrong.  Please contact us for help.",
          isOpen: true,
          loading: false
        })
      } else if(err && err.response && err.response.data) {
        const errs = err.response.data;
        const keys = Object.keys(errs);

        const messages = keys.map((key) => `${key} ${errs[key].join(',')}`)

        modalContext.addWithMessages({
          color: "primary",
          title: 'Error:',
          messages: messages,
          isOpen: true,
          loading: false
        })

      } else {
        console.log(err);

        modalContext.add({
          color: "primary",
          title: 'Error:',
          message: "Something went wrong.  Please contact us for help.",
          isOpen: true,
          loading: false
        })
      }
    })
  }

  const toggleNewPersonModal = () => {
    setAddPersonModal(!addPersonModal);
  }

  const handleSorting = (result) => {
    const items = Array.from(hosts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newPeople = items.map((person, position_order_idx) => {
      return {...person, sort_order: position_order_idx + 1}
    })
    setHosts(newPeople);
  }

  return (
    <>
      <PeopleListModal
        people={people}
        modal={peopleModal}
        toggleHost={toggleHost}
        toggle={togglePeopleModal}
      />
      <NewPersonModal
        addHost={handleAddNewPerson}
        modal={addPersonModal}
        toggle={toggleNewPersonModal}
        personTaxonomies={personTaxonomies}
      />
      <EpisodePeople
        toggleNewPersonModal={toggleNewPersonModal}
        people={people}
        hosts={hosts}
        handleSorting={handleSorting}
        toggleModal={togglePeopleModal}
        personTaxonomies={personTaxonomies}
        handleDeletePerson={handleDeleteHost}
        handleTaxonomyChange={handleUpdateHostRole}
      />
    </>
  )
}
