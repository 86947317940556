import React, {useEffect, useState, useContext} from "react";
import {Redirect} from 'react-router'
import { Button } from 'reactstrap';
import justcastApi from '../../api/justcast';
import {updateSelectedPeople} from '../../libs/updateSelectedPeople'
import {Context as AlertContext} from '../../context/AlertContext'
import {Context as MenuContext} from '../../context/MenuContext'
import {Context as ModalContext} from '../../context/ModalContext'
import { NewEpisodeForm, EpisodeHosts } from '../../components/episode'
import {SeasonForm} from '../../components/seasons'

const NewEpisode = (props) => {
  const showId = props.match.params.id;
  const modalContext = useContext(ModalContext);
  const {addWithTimeout} = useContext(AlertContext);
  const { setEpisodesMenus} = useContext(MenuContext);
  const [showName, setShowName] = useState('');
  const [redirectPage, setRedirectPage] = useState(null);
  const [explicitTypes, setExplicitTypes] = useState([])
  const [episodeTypes, setEpisodeTypes] = useState([])
  const [seasons, setSeasons] = useState([]);
  const [seasonModalOpen, setSeasonModalOpen] = useState(false);
  const [newSeason, setNewSeason] = useState({
    name: "", number: "", id: null
  });
  const [seasonId, setSeasonId] = useState(null);
  const [errorSeasonMessages, setSeasonErrorMessages] = useState({});

  const [people, setPeople] = useState([]); // podcast level
  const [hosts, setHosts] = useState([]) // episode level
  const [personTaxonomies, setPersonTaxonomies] = useState([]);

  const handleHostsChanged = (_hosts) => {
    const _people = updateSelectedPeople({hosts: _hosts, people})
    setPeople(_people);
    setHosts(_hosts);
  }

  useEffect(() => {
    justcastApi.get(`/v2/shows/${showId}`)
    .then((res) => {
      const data = res.data;
      setShowName(data.name)

      setEpisodesMenus({
        title: 'New Episode',
        subtitle: data.name,
        currentPageId: 'episodes',
        currentTabId: 'episode/new',
        showId: showId,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        isDirectUpload: res.data.is_direct_upload,
        shopifyApp: res.data.is_shopify_app
      });
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get(`/v1/shows/${showId}/people`)
    .then((res) => {
      setPeople(res.data.people)
      const options = res.data.person_taxonomies.map((x, idx) => {
        return {
          value: x.code, label: x.role_title, ...x
        }
      });
      setPersonTaxonomies(options)
    })
    .catch((err) => {
      console.log(err)
    })
  },[showId])

  useEffect(() => {
    justcastApi.get('/v1/explicit_types')
    .then((res) => {
      setExplicitTypes(res.data)
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get('/v1/episode_types')
    .then((res) => {
      setEpisodeTypes(res.data)
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get(`/v1/shows/${showId}/seasons`)
    .then((res) => {
      setSeasons(res.data);
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])

  useEffect(() => {
    const dicts = {}
    for(let i = 0; i < hosts.length; i++) {
      const host = hosts[i];
      const hostPersonId = host.person_id;
      dicts[hostPersonId] = hostPersonId;
    }
    const _people = [...people];
    for(let j = 0; j < _people.length; j++) {
      const personId = _people[j]['id'];
      if(dicts[personId] === undefined) {
        _people[j]['selected'] = false;
      } else {
        _people[j]['selected'] = true;
      }
    }
    setPeople(_people);

  }, [people.length, hosts.length])

  const handleAddSeason = () => {
    setSeasonModalOpen(true);
  }

  const handleNewSeasonModalToggle = () => {
    setSeasonModalOpen(false);
  }

  const handleInputNewSeasonChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setNewSeason({...newSeason, [name]: value});
  }

  const handleSeasonSave = () => {
    justcastApi.post(`/v1/shows/${showId}/seasons`, {
      name: newSeason.name,
      number: newSeason.number
    })
    .then((res) => {
      setSeasonErrorMessages({})
      setNewSeason({name: "", number: "", id: null})
      setSeasonId(res.data.id);
      setSeasons([res.data, ...seasons].sort((a, b) => b['number'] - a['number']));
      setSeasonModalOpen(false);
      addWithTimeout({color: 'primary', message: `Added a new season`, isOpen: true, timeout: 3000});
    })
    .catch((err) => {
      setSeasonErrorMessages(err.response.data)
    })
  }

  if(redirectPage) {
    return <Redirect to={redirectPage}/>
  }

  return (
    <>
      <div className="container-fluid">
        <SeasonForm
          title={"Add a new season"}
          isOpen={seasonModalOpen}
          toggle={handleNewSeasonModalToggle}
          data={newSeason}
          errorMessages={errorSeasonMessages}
          onChange={handleInputNewSeasonChange}
        >
          <Button color="primary" onClick={handleSeasonSave}>Save</Button>
        </SeasonForm>
        <div className="row">
          <div className="col-12" style={{margin: "0 0 60px 0"}}>
            <NewEpisodeForm
              showId={showId}
              seasons={seasons}
              seasonId={seasonId}
              setSeasonId={setSeasonId}
              hosts={hosts}
              explicitTypes={explicitTypes}
              episodeTypes={episodeTypes}
              handleAddSeason={handleAddSeason}
              setRedirectPage={setRedirectPage}
            >
              <EpisodeHosts
                showId={showId}
                hosts={hosts}
                people={people}
                setHosts={handleHostsChanged}
                setPeople={setPeople}
                personTaxonomies={personTaxonomies}
              />
            </NewEpisodeForm>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewEpisode;