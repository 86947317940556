import React, {useState, useEffect} from "react";
import {useAtom} from 'jotai'
import {NinjaPlayer} from 'react-podcast-ninja'
import {playerConfigAtom} from '../jotai'
import FormGroupInputSelect from '../components/FormGroupInputSelect';
import WidgetCodeSection from './WidgetCodeSection'

const SingleEpisodeWidget = ({
  showId,
  audioposts,
  is_advanced_private_feed,
  allowSite,
  totalPage,
  currentPage,
  nextPage,
  handleLoadMore
}) => {
  const [audiopostIdx, setAudiopostIdx] = useState(0);
  const [audiopostId, setAudiopostId] = useState('');
  const [configs] = useAtom(playerConfigAtom);

  const handleSelectionChange = (event) => {
    if(event.target.value){
      setAudiopostId(event.target.value)
      const id = audioposts.map((x) => {if(x.id.toString() === event.target.value) return x.id.toString()}).indexOf(event.target.value)
      setAudiopostIdx(Number(id));
    }
  }

  useEffect(() => {
    if(audioposts && audioposts.length > 0) {
      setAudiopostId(audioposts[0].id)
    }
  }, [showId, audioposts])

  if(audioposts && audioposts.length > 0) {
    return (
      <>
        <div className="row">
          <h2 className="col-6">Single episode player:</h2>
        </div>
        <div className="row">
          <div className="col-12">
            <FormGroupInputSelect
              inputType="select"
              inputLabel="Episode"
              inputName='episode_id'
              optionLabel="name"
              optionId="id"
              options={audioposts}
              handleInputChange={handleSelectionChange}
              inputValue={audiopostId}
              totalPage={totalPage}
              currentPage={currentPage}
              nextPageCallback={handleLoadMore}
            />
          </div>
        </div>
        <div className="row">
          {/* <div className="col-12" dangerouslySetInnerHTML={{__html: `<iframe src='${process.env.REACT_APP_LANDING_PAGE}/widget/${showId}/audioposts/${audiopostId}' width='100%' height='180' frameborder='0' scrolling='no' seamless='true' style='width:100%; height:180px;'></iframe>` }} />             */}
          <div className="col-12">
            <NinjaPlayer
              configs={configs}
              playerId={`${showId}-single`}
              episodes={[audioposts[audiopostIdx]]}
              singleEpisode={true}
              themeName="retro"
            />
          </div>
        </div>
        <WidgetCodeSection
          showId={showId}
          episodeId={audiopostId}
          is_advanced_private_feed={is_advanced_private_feed}
          allowSite={allowSite}
        />
      </>
    )
  }
  return null;
}

export default SingleEpisodeWidget;