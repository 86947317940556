import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../api/justcast'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import { Spinner } from 'reactstrap';
import ShowNoteCard from '../components/ShowNoteCard'
import PlaylistItems from '../components/PlaylistItems'

const Playlist = (props) => {
  const screenContext = useContext(ScreenContext)
  const authContext = useContext(AuthContext);
  const menuContext = useContext(MenuContext);

  const id = props.match.params.id;
  const [showName, setShowName] = useState('');
  const [artworkUrl, setArtworkUrl] = useState('https://via.placeholder.com/150');
  const [items, setItems] = useState([]); // episodes

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}/audioposts`)
    .then((res) => {
      setItems(res.data.audioposts);  
      setShowName(res.data.show.name);
      if(res.data.show.artwork_url_256) {
        setArtworkUrl(res.data.show.artwork_url_256);
      }
      menuContext.setEpisodesMenus({
        title: 'Playlist',
        subtitle: res.data.show.podcast_title,
        currentPageId: 'episodes',
        currentTabId: 'playlist',
        showId: id,
        landingPageUrl: res.data.show.landing_page_url,
        websiteUrl: res.data.show.player_page_link,
        rssFeed: res.data.show.rss_feed,
        passwordProtected: res.data.show.is_password_protected,
        advancedPrivate: res.data.show.is_advanced_private_feed,
        isPrivate: res.data.show.is_private,
        isPrivateShow: res.data.show.is_private_show,
        isDirectUpload: res.data.show.is_direct_upload,
        skill_podcast_website: res.data.show.skill_podcast_website
      });      
      screenContext.finishFetching();

    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
    })
  }, [id]) 

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> :
              <>
                <ShowNoteCard name={showName} artworkUrl={artworkUrl} />
                <PlaylistItems items={items} showArtwork={artworkUrl}/>
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Playlist;