import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner} from 'reactstrap';
import ImageUploader  from './ImageUploader'

const ImageUploaderModal = (props) => {
  const [image, setImage] = useState(null);

  const {
    handleUpload, 
    modal, 
    toggle,
    data,
    uploading
  } = props;
  
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{data.title}</ModalHeader>
      <ModalBody>        
        <p>{data.label}</p>
        <hr className="mt-5 mb-5"/>        
        {
          uploading ? <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" /> : <ImageUploader setImage={setImage}/>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          handleUpload(image)
        }}>Save</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>      
    </Modal>
  )
}

export default ImageUploaderModal