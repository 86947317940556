import React from "react";
import { Label, Form, FormGroup, Input, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ErrorPageBody from './ErrorPageBody'
import ImportPageBody from './ImportPageBody'

const ImportPodcastModal = (props) => {
  const {title, message, isOpen, toggle, isErrorPage} = props;

  return (
    <Modal isOpen={isOpen} fade={false} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <p>{message}</p>   
        <ErrorPageBody toggle={toggle} isErrorPage={isErrorPage}/>
        <ImportPageBody isErrorPage={isErrorPage}/>
      </ModalBody>
      <ModalFooter>                
        <Button color="danger" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ImportPodcastModal