import { atom } from "jotai";

const temp = {
  socket: null
}

export const socketAtom = atom(temp);

export const setSocketConnection = atom((get) => {
  return get(socketAtom);
}, (_get, set, socket) => {
  set(socketAtom, {socket})
})