import React, { useRef } from 'react';
import {Row, Spinner, Card} from 'reactstrap';
import { Line, Column } from '@ant-design/charts';
import DateRangePicker from './DateRangePicker'
import DatePickerRange from './DatePickerRange'

const ColumnChartCard = (props) => {
  const {
    datePickerDisplay,
    totalDownloads,
    loading, 
    chartId, 
    title, 
    subtitle, 
    data, 
    optionsData, 
    optionSelected, 
    fromDate,
    toDate,
    handleOptionChange,
    handleDateChange
  } = props;
	const chartRef = useRef();
  
	if(loading) {
		return (
			<Card>
				<div className="card-header">
					<h4 className="card-header-title mr-auto">{title}</h4>
				</div>				
				<div className="card-body"><Spinner/></div>				
			</Card>
		)
	} else {
		const config = {
			data: data ? data : [],
			xField: 'label',
      yField: 'downloads',
      color: '#FCD161',
      // label: {
      //   position: 'middle',
      //   style: {
      //     fill: '#0A0909',
      //     // opacity: 0.6,
      //   },
      // },      
      meta: {
        label: { alias: 'Period' },
        downloads: { alias: 'Downloads' },
      },			
		};

		return (
			<Card id={chartId}>
				<div className="card-header">
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <div style={{fontSize: 24, fontWeight: 'bold'}}>{title}</div>
              {
						    subtitle ? <p className="text-muted mb-3">{subtitle}</p> : null
					    }
            </div>
            <div>
              <div className="text-muted" style={{fontSize: 18, fontWeight: 'bold'}}>Downloads</div>
              <div className="float-right" style={{fontSize: 20, fontWeight: 'bold', color: '#FCD161'}}>{totalDownloads}</div>
            </div>
          </div>
				</div>
				<div className="card-body">
          <div className="row align-items-center">
            <div className="col-4">
              <DateRangePicker
                options={optionsData}
                selected={optionSelected}
                handleChanged={handleOptionChange}
              />
            </div>
          </div>
          <br/>
          <DatePickerRange
            display={datePickerDisplay}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={(x) => {
              handleDateChange('fromDate', x)
            }}
            setToDate={(x) => {
              handleDateChange('toDate', x)
            }}
          />
          <div style={{marginTop: 40, marginLeft: 10, marginRight: 10, marginBottom: 10}}>
            <Column {...config} chartRef={chartRef} />
          </div>
				</div>
			</Card>
		)
	}
}

export default ColumnChartCard