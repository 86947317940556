import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Button, Input, InputGroup, InputGroupAddon,InputGroupText, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem   } from 'reactstrap';

const TextInputClipboard = ({id, name, inputValue, viewLabel, viewLink}) => {
  const [copied, setCopied] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy');

  const handleCopyClicked = () => {
    const copyText = document.querySelector(`#input-${id}`);
    copyText.select();
    document.execCommand("Copy");
    setCopyButtonLabel('Copied');
    setCopied(true);
    // change back to Copy in 3 seconds
    setTimeout(() => {
      setCopyButtonLabel("Copy");
      setCopied(false);
    }, 3000)    
  }

  const handleGo = () => {
    window.open(inputValue)
  }

  return (
    <>
      <InputGroup>  
        <Input valid={copied} readOnly value={inputValue} id={`input-${id}`} />
        <InputGroupAddon addonType="prepend">                    
          {viewLink ? <Link className='btn btn-warning' to={viewLink}>{viewLabel}</Link> : null}
          <Button color="warning" onClick={handleCopyClicked}>{copyButtonLabel}</Button>
          <Button color="primary" onClick={handleCopyClicked} onClick={handleGo}>Go</Button>
        </InputGroupAddon>          
      </InputGroup>      
    </>
  )
}

export default TextInputClipboard;