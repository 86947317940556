import React from 'react';
import PageHeaderTitle from './PageHeaderTitle'
import PageHeaderNavItems from './PageHeaderNavItems'

// headerLinks example
// {
//   id:'dropboxConnectionCreateButton',
//   className:"btn btn-primary",
//   handleOnClick: connectWithDropbox,
//   text:'Create Connection'
// }

const SimplePageHeader = ({
  headerPreTitle, 
  headerPreTitleLink,
  headerTitle, 
  headerLinks, 
  headerNavItems
}) => {
  return (
    <div className="header">
      <div className="container-fluid">
        <div className="header-body">
          <PageHeaderTitle 
            headerPreTitle={headerPreTitle}
            headerPreTitleLink={headerPreTitleLink}
            headerTitle={headerTitle} 
            headerLinks={headerLinks}/>
          <PageHeaderNavItems headerNavItems={headerNavItems}/>
        </div>
      </div>
    </div>
  )
}

export default SimplePageHeader;