import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../api/justcast'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import { Spinner, Card, CardBody, Button } from 'reactstrap';
import {PersonNote, PersonForm} from '../components/people'
import { Link } from "react-router-dom";
import {replaceImageHandler} from '../api/doUploader'

const ShowPerson = (props) => {
  const id = props.match.params.id;
  const showId = props.match.params.show_id;
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const {addWithMessages, add} = useContext(ModalContext);  
  const [personTaxonomies, setPersonTaxonomies] = useState([]);
  const [person, setPerson] = useState({
    name: "", description: "", href: "", img: "", email: "", group: "", role: "", code: "",roles: [], mastodon: "", matrix: "",
    facebook_url: "", twitter_url: "", linkedin_url: "", tiktok_url: "", instagram_url: "", youtube_url: "", patreon: ""
  })
  const [artwork, setArtwork] = useState("");

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${showId}/people/${id}`)
    .then((res) => {
      
      const name = res.data.podcast_title;
      
      setPerson(res.data.person)
      const options = res.data.person_taxonomies.map((x, idx) => {
        return {
          value: x.code, label: x.role_title, ...x
        }
      });

      setPersonTaxonomies(options)
      screenContext.finishFetching();
      
      menuContext.setSettingsMenus({
        title: 'Person',
        subtitle: name,
        currentPageId: 'settings',
        currentTabId: 'people_settings',
        showId: showId,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website     
      });     
            
    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
      // const messages = err.response.data.message.map((x) =>)

    
      // setDenied(true)
    })    
  }, [])  

  const handlePersonChange = (evt) => {
    const {value, name} = evt.target;
    const _person = {...person, [name]: value};
    setPerson(_person);
  }  

  const handlePersonSave = () => {

    addWithMessages({
      color: "primary", 
      title: 'Update person',
      message: "Updateing person info...",
      messages: [], 
      isOpen: true,
      loading: true
    })   

    const fileName = person.img ? (person.img.split("/").pop()) : null;

    replaceImageHandler(artwork, fileName)
    .then((imgRes) => {
      if(imgRes) {
        return {...person, img: imgRes.data.path};
      } else {
        return person;
      }
    })
    .then((_person) => {
      return justcastApi.put(`/v1/shows/${showId}/people/${id}`, _person);
    })   
    .then((res) => {

      addWithMessages({
        color: "primary", 
        title: 'Update person info',
        messages: [],
        isOpen: true,
        loading: true
      })   

      setTimeout(() => {
        addWithMessages({
          color: "primary", 
          title: 'Success update person info',
          messages: [`Name: ${person.name}`, `Role: ${person.role}`], 
          isOpen: true,
          loading: false
        })  
      }, 5000)

    })
    .catch((err) => {
      // console.log(err.response.data.message);
      const errs = err.response.data;
      const keys = Object.keys(errs);
      const messages = keys.map((key) => `${key} ${errs[key].join(',')}`)

      addWithMessages({
        color: "primary", 
        title: 'Error:',
        messages: messages, 
        isOpen: true,
        loading: false
      })         
    })
  }


  const handleTaxonomyChange = (e) => {
    // const {code, group_title, role_title} = e;
    // const _person = {...person, code, group: group_title, role: role_title};
    // setPerson(_person);

    if(e && e !== null && e.length > 0) {
      const _roles = e.map((role) => {
        const {code, group_title, role_title, value, label} = role;
        return {code, group_title, role_title, value, label}
      })

      const {role_title, group_title} = e[0]; // the first one will be our primary
      const _person = {...person, roles: _roles, role: role_title, group: group_title};
      setPerson(_person);
    } else {
      const _person = {...person, roles: [], role: "", group: ""};
      setPerson(_person);
    }    
  }


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {
            screenContext.state.loading ? <Spinner color="primary" /> : 
            <div>
              <PersonForm
                name={person.name}
                description={person.description}
                href={person.href}
                img={person.img}
                role={person.role}
                group={person.group}
                roles={person.roles}
                email={person.email}
                facebook_url={person.facebook_url}
                twitter_url={person.twitter_url}
                linkedin_url={person.linkedin_url}
                tiktok_url={person.tiktok_url}
                instagram_url={person.instagram_url}
                youtube_url={person.youtube_url}    
                patreon={person.patreon}            
                personTaxonomies={personTaxonomies}
                matrix={person.matrix}
                mastodon={person.mastodon}                
                handleChange={handlePersonChange}
                handleTaxonomyChange={handleTaxonomyChange}
                handleSave={handlePersonSave}
                setArtwork={setArtwork}
              >
                {" "}<Link to={`/shows/${showId}/people_settings`}>
                  <Button color="secondary">Cancel</Button>
                </Link>
              </PersonForm>
            </div>              
          }
        </div>
      </div>
    </div>
  )
}

export default ShowPerson;