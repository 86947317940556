import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert} from 'reactstrap'
import ShopifyConfirmation from '../../assets/img/posts/shopify-confirmation-page.webp'

export default function ProductAddedModal({modal, toggle, productId}) {

  return (
    <Modal isOpen={modal ? true : false} toggle={toggle}>
      <ModalHeader toggle={toggle}>Customizing your Shopify Order Thank-you page</ModalHeader>
        <ModalBody>
          <h2>Add a download link to thank-you page</h2>
          <div style={{padding: 20}}>
            <div className="embed-responsive embed-responsive-16by9 rounded mb-3">
              <iframe className="embed-responsive-item" src="https://player.vimeo.com/video/962929596" allowFullScreen></iframe>
            </div>
          </div>
          <hr/>
          <h4>Desktop</h4>
          <ol>
            <li>From your Shopify admin, go to <strong><a href="https://admin.shopify.com/settings/checkout" target="_blank">{'Settings > Checkout'}</a></strong>.</li>
            <li>Click <strong>Customize</strong>on the Configurations section.</li>
            <li>Change to <strong>Thank you</strong>.</li>
            <li>Click the <strong>sections icon</strong>. (the first icon on the left tool bar).</li>
            <li>Click <strong>Add app block</strong></li>
            <li>Choose the App block from JustCast: <strong>Podcast Download Link</strong></li>
            <li>Click Save</li>
          </ol>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
    </Modal>
  )
}
