import React, {useEffect, useState, useContext} from "react";
import {useAtom} from 'jotai'
import justcastApi from '../api/justcast';
import {Context as MenuContext} from '../context/MenuContext'
import {ChaptersPlayer} from '../components/Chapters'
import {
  soundbitesAtom,
} from '../components/Chapters/soundbitesAtom'


const EpisodeSoundbites = (props) => {
  const id = props.match.params.id;
  const showId = props.match.params.show_id;
  const {setEpisodePageMenu} = useContext(MenuContext);
  const [item, setItem] = useState({});
  const [, setSoundbites] = useAtom(soundbitesAtom)

  useEffect(() => {
    justcastApi.get(`/v1/audioposts/${id}/soundbites`)
    .then((res) => {
      const data = res.data;
      setItem(data.audiopost);
      setEpisodePageMenu({
        title: "Soundbites",
        currentPageId: "episodes",
        currentTabId: "soundbites",
        subtitle: `${data.show.podcast_title} > ${data.audiopost.episode_title}`,
        showName: data.show.podcast_title,
        showId,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow: data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website
      })

      setSoundbites(data.soundbites)
    })
    .catch((err) => {
      console.log(err)
    })

  },[id, showId])

  if(item.id) {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ChaptersPlayer
                item={item}
                audiopostId={id}
                type="soundbites"
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  return null;
}

export default EpisodeSoundbites;