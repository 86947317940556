import React from "react";
import { Link } from 'react-router-dom'

const ScreenBlocker = ({message}) => {
  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-body">
          <p>{message}</p>
          <Link to="/plans" className="btn btn-primary">Upgrade</Link>
        </div>        
      </div>     
    </div>
  )
}

export default ScreenBlocker;