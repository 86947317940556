import React from "react";
import PodcastNetwork from './PodcastNetwork'
import distributionList from './../api/distributionInstruction'

const PodcastNetworks = ({feed, show_id}) => {
  return (
    <>
      {
        distributionList.map(network => {
          return (
            <PodcastNetwork 
              msBaseEndpoint={network.msBaseEndpoint}
              msEndpoint={network.msEndpoint}
              key={network.id} 
              id={network.id}
              show_id={show_id}
              title={network.title} 
              feed={feed}
              networkIcon={network.networkIcon}
              lists={network.lists}
              paragraphs={network.paragraphs}
              button={network.button}
            />
          )
        })
      }
    </>
  )
}

export default PodcastNetworks