import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, InputGroup, InputGroupAddon, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem   } from 'reactstrap';
import { Link } from 'react-router-dom';
import SimpleModal from './../SimpleModal'

const PreTitle = ({headerPreTitle, headerPreTitleLink}) => {
  if (headerPreTitle) {
    if(headerPreTitleLink) {
      return (
        <h6 className="header-pretitle">
          <Link to={headerPreTitleLink}>{headerPreTitle}</Link>
        </h6>    
      )      
    }
    return (
      <h6 className="header-pretitle">
        {headerPreTitle}
      </h6>    
    )
  } else {
    return null;
  }
}

const HeaderTitle = ({headerTitle}) => {
  if(headerTitle) {
    return (
      <h1 className="header-title">
        {headerTitle}
      </h1>
    )
  } else {
    return null;
  }
}
// <a className="btn btn-primary lift">New order</a>

const HeaderLinks = ({headerLinks}) => {
  if(headerLinks && headerLinks.length > 0) {
    return (
      <div className="col-auto">
        {
          headerLinks.map((headerButton) => 
          {
            if(headerButton.type === 'link') {
              return (
                <Link
                  key={headerButton.id}
                  id={headerButton.text.toString()}
                  className={headerButton.className}                   
                  to={headerButton.to}>
                  {headerButton.text}
                </Link>
              )
            } else if (headerButton.type === 'simple_modal') {
              return (
                <SimpleModal
                  key={headerButton.id}
                  id={headerButton.id}
                  className={headerButton.className}
                  modalClassName="modal-dialog"
                  message={headerButton.message}
                  htmlMessage={headerButton.htmlMessage}
                  title={headerButton.title}
                  buttonLabel={headerButton.text}
                  buttonHandler={headerButton.buttonHandler}
                />                
              )
            } else if(headerButton.type === 'clipboard') {
              return (
                  <ModalComponent
                    key={headerButton.id}
                    id={'rss-feed'}
                    className={headerButton.className}
                    modalClassName="modal-dialog"
                    inputValue={headerButton.url}
                    buttonLabel={headerButton.text}
                />
              )
            } else if (headerButton.type === 'dropdown') {
              return (
                <DropDownList
                  key={headerButton.id} 
                  id={headerButton.id}
                  text={headerButton.text} 
                  urls={headerButton.urls} 
                  size={headerButton.size}
                  color={headerButton.color}
                />
              )
            } else {
              return (
                <div className={headerButton.className}
                  id={headerButton.id}
                  key={headerButton.id}
                  onClick={() => {
                    headerButton.handleOnClick()
                  }}
                >
                  {headerButton.text}
                </div>
              )
            }
          }            
          )
        }

      </div>      
    )
  } else {
    return null;
  }
}

const PageHeaderTitle = ({headerPreTitle, headerPreTitleLink, headerTitle, headerLinks}) => {
  return (
    <div className="row align-items-center">
      <div className="col">
        <PreTitle headerPreTitle={headerPreTitle} headerPreTitleLink={headerPreTitleLink}/>
        <HeaderTitle headerTitle={headerTitle}/>        
      </div>
      <HeaderLinks headerLinks={headerLinks}/>
    </div>    
  )
}

const ModalComponent = ({id, buttonLabel, className, modalClassName, inputValue}) => {

  const [modal, setModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy');

  const toggle = () => setModal(!modal);

  const handleCopyClicked = () => {
    const copyText = document.querySelector(`#input-${id}`);
    copyText.select();
    document.execCommand("Copy");
    setCopyButtonLabel('Copied');
    setCopied(true);
    // change back to Copy in 3 seconds
    setTimeout(() => {
      setCopyButtonLabel("Copy");
      setCopied(false);
    }, 3000)    
  }

  return (
    <>
      <Button color={className} onClick={toggle}>{buttonLabel}</Button>
      <Modal isOpen={modal} toggle={toggle} className={modalClassName}>
        <ModalHeader toggle={toggle}>{buttonLabel}</ModalHeader>
        <ModalBody>
          <InputGroup>
            <Input valid={copied} readOnly value={inputValue} id={`input-${id}`} />
            <InputGroupAddon addonType="prepend">
              <Button color="primary" onClick={handleCopyClicked}>{copyButtonLabel}</Button>
            </InputGroupAddon>          
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const DropDownList = ({text, urls, color, size, id}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} id={id}>
      <DropdownToggle caret color={color} size={size} >{text}</DropdownToggle>
      <DropdownMenu>
        {
          urls.map((url) => 
            <Link key={url.id.toString()} to={url.to}>
              <DropdownItem >{url.name}</DropdownItem>
            </Link>
          )
        }
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default PageHeaderTitle;