import React, {useState} from 'react'
import { FormText, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Button} from 'reactstrap';

export default function SeasonForm(props) {
  const {isOpen, toggle, title, data, onChange, errorMessages} = props;

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <strong>{title}</strong>
      </ModalHeader>
      <ModalBody>
        <Form className="mb-4">
          <FormGroup>
            <label>Number (required)</label>
            <Input 
              invalid={errorMessages["number"] === undefined ? false : true}
              type="text" 
              className="form-control" 
              value={data.number ? data.number : ""} 
              name="number"
              onChange={onChange}
              placeholder="This represents the season number e.g. 1"/>
              {
                errorMessages["number"] && <FormFeedback>{"Number " + errorMessages["number"].join(",")}</FormFeedback>
              }              
          </FormGroup>
          <FormGroup>
            <label>Name (optional)</label>
            <Input 
              type="text" 
              className="form-control" 
              value={data.name ? data.name : ""} 
              name="name"
              onChange={onChange}
              placeholder="e.g. Season 2 - Web3.0"/>
          </FormGroup>          
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button> {" "}
        {props.children}
      </ModalFooter>
    </Modal>
  )
}