import React, {useEffect, useState, useContext, useRef} from "react";
import {Redirect} from 'react-router'
import io from "socket.io-client";
import {Button} from 'reactstrap';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import justcastApi from '../api/justcast';
import {uploadFileWithCondition} from '../api/doUploader'
import {uploadFileHandler} from '../api/doDirectUpload'
import EpisodeForm from '../components/EpisodeForm';
import {SeasonForm} from '../components/seasons'
import {EpisodePeople, PeopleListModal, NewPersonModal} from '../components/episode-people'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as FileUploadContext} from '../context/FileUploadContext'
import {ModalBox, PermissionModal} from '../components/FileUploader'

const EpisodeNew = (props) => {
  const quillRef = useRef(null);
  const showId = props.match.params.id;
  const {addWithTimeout} = useContext(AlertContext);
  const { setEpisodesMenus} = useContext(MenuContext);
  const { skill_direct_upload } = useContext(AuthContext).state;
  const modalContext = useContext(ModalContext);
  const [showName, setShowName] = useState('');
  const [episodeName, setEpisodeName] = useState('');
  const [podieoAllowTime, setPodieoAllowTime] = useState(0);
  const [item, setItem] = useState({});
  const [audioDate, setAudioDate] = useState(null);
  const [explicitTypes, setExplicitTypes] = useState([])
  const [episodeTypes, setEpisodeTypes] = useState([])
  const [fileTypeGroup, setFileGroupType] = useState('');
  const [isDirectUpload, setIsDirectUpload] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadArtworkFile, setUploadArtworkFile] = useState(null);
  const [supportFiles, setSupportFiles] = useState([]);
  const [modal, setModal] = useState(false);  
  const [redirectPage, setRedirectPage] = useState(null);
  const [seasons, setSeasons] = useState([]);
  const [seasonId, setSeasonId] = useState(-1);
  const [isSeasonModalOpen, setSeasonModalOpen] = useState(false);
  const [newSeason, setNewSeason] = useState({
    name: "", number: "", id: null
  });
  const [errorSeasonMessages, setSeasonErrorMessages] = useState({});

  const [people, setPeople] = useState([]); // podcast level
  const [hosts, setHosts] = useState([]) // episode level
  const [personTaxonomies, setPersonTaxonomies] = useState([]);
  const [peopleModal, setPeopleModal] = useState(false);
  const [addPersonModal, setAddPersonModal] = useState(false);
  const [convertToMp3, setConvertToMp3] = useState(false);

  useEffect(() => {
    const dicts = {}
    for(let i = 0; i < hosts.length; i++) {
      const host = hosts[i];
      const hostPersonId = host.person_id;
      dicts[hostPersonId] = hostPersonId;
    }
    const _people = [...people];
    for(let j = 0; j < _people.length; j++) {
      const personId = _people[j]['id'];
      if(dicts[personId] === undefined) {
        _people[j]['selected'] = false;
      } else {
        _people[j]['selected'] = true;
      }
    }
    setPeople(_people);

  }, [people.length, hosts.length])

  const toggleHost = (person) => {
    if(hosts.filter((x) => x.person_id === person.id).length === 0) {
      setHosts([...hosts, {...person, id: null, person_id: person.id}]);
    } else {
      const _hosts = [...hosts];
      const __hosts = _hosts.filter((host) => host.person_id !== person.id)
      setHosts(__hosts);
    }
  }

  const togglePeopleModal = () => {
    setPeopleModal(!peopleModal)
  }

  const toggleNewPersonModal = () => {
    setAddPersonModal(!addPersonModal);
  }

  // file context
  const fileUploadContext = useContext(FileUploadContext);
  const {state, add, addChannel, addDataToChannel} = fileUploadContext;
  const {socket, channels, updatingAudioposts}  = state;
  const channelData = updatingAudioposts[`direct_upload_${item.id}`];
  const { directUploadStatusCode } = channelData ? channelData : {directUploadStatusCode: null};
  
  const handleSocketCallback = (channelName, data) => {
    addDataToChannel(channelName, data);
  }
  // end file context

  const handleMediaFileChange = (src, filesize, support_file_type) => {
    setItem({...item, 'audio_url': src, file_size: filesize, support_file_type})
  }

  const handleArtworkChange = (src) => {
    setItem({...item, artwork_url: src});
  }

  const handleDatepickerChange = (date) => {
    const audioDate = date[0];
    
    if(audioDate > new Date()) {
      // console.log(formatDistance(subDays(new Date(), 3), new Date()))
      const message = "Did you want this published at a future time/date? This episode won't be released in " + formatDistance(audioDate, new Date())      
      addWithTimeout({color: 'primary', message, isOpen: true, timeout: 5000})
    }
    setAudioDate(audioDate)
    // setItem({...item, 'audio_date': date[0]})
  }

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setItem({...item, [key]: value})
  }

  const handleQuillEditorChange = (value) => {
    setItem({...item, description: value})
  }

  const handleRemoveImage = () => {
    setUploadArtworkFile(null);
    setItem({...item, artwork_url: ""})
  }

  const handleInputValueChange = ({key, value}) => {
    if(key === 'artwork_url' && value === "") {
      setUploadArtworkFile(null);
    }
    setItem({...item, [key]: value})
  }

  const handleSeasonChange = (id) => {
    setSeasonId(id)
  }

  const handleProgressUpload = (data) => {   
    // uploadedSize: 0, totalSize: 0
    const  {uploadedSize, totalSize, audiopostId} = data;
    const channelName = `direct_upload_${audiopostId}`;    
    addDataToChannel(channelName, {
      fileSize: totalSize,
      uploadedFileSize: uploadedSize,
      title: "Initialing file upload..."
    });
    // setUploadingFileSize(data);
    // setModalContent({...modalContent, title: "Initialing file upload..."})
  }

  const toggleModal = (x) => {
    setModal(x)   
    setRedirectLink(); 
  }

  const setRedirectLink = () => {
    setRedirectPage(`/shows/${showId}/episodes`);
  }

  const handlePublish = () => {
    handleUpdate(item);
  }

  const handleSaveAsDraft = () => {
    handleUpdate({...item, is_draft: true})
  }

  const handleUpdate = (item) => {
    // console.log(item)
    // check if name exist and audio_url exist
    const description = quillRef?.current?.unprivilegedEditor?.getHTML();
    const errorMessages = [];

    if(item.name === "" || item.name.replace(/ /g, "").length === 0) {
      errorMessages.push('Episode name can not be emtpy')      
    }

    if(item.audio_url === "" || item.audio_url === undefined || item.audio_url === null) {
      errorMessages.push('Missing audio file')
    }

    for(let i = 0; i < hosts.length; i++) {
      const host = hosts[i];
      if(host.roles === undefined || host.roles === null || (host.roles && host.roles.length === 0)) {
        errorMessages.push(`${host.name} is missing roles.`)
      }
    }

    const newAduioDate = audioDate ? audioDate : item.audio_date;

    if(errorMessages.length > 0) {
      const msgstr = errorMessages.join(', and ')
      
      modalContext.add({
        color: "primary", 
        title: 'Error:',
        message: msgstr, 
        isOpen: true,
        loading: false
      })      
    } else {
      
      justcastApi.get(`/v1/shows/${showId}/prepare_upload`)
      .then((res) => {
        setModal(true);
        return;
      })
      .then(() => {
        return uploadFileWithCondition(uploadArtworkFile)
      })
      // uploadFileWithCondition(uploadArtworkFile)
      .then((res) => {
        const artwork_url = res.data.path;
        const newData = {...item, description, audio_url: "", audio_date: newAduioDate};
        
        if(artwork_url) {
          return {...newData, artwork_url}
        } else {
          return newData;
        }
      })
      .then((uploadData) => {
        return justcastApi.post(`/v1/shows/${showId}/audioposts/`, {
          ...uploadData,
          hosts,
          season_id: seasonId > 0 ? seasonId : null
        })
      })
      .then((res) => {
        const audiopostId = res.data.id;
        setItem({...item, id: audiopostId});
        // make a request to micro-service to upload file
        return uploadFileHandler(uploadFile, showId, audiopostId, showName, item.name, handleProgressUpload, convertToMp3)
      })
      .then((res) => {
        // setModal(false)
        const showId = res.data.showId;
        const id = res.data.audiopostId;
        if(showId && id) {
          // establish socket connection
          if(!socket) {
            add({
              socket: io(process.env.REACT_APP_MS_DIRECT_UPLOAD_PROXY)
            })
          }          
        }
        // redirect to uploading epsidoe page
      })      
      .catch((err) => {
        const data = err.response.data;
        if(data) {
          const error = data.error;
          modalContext.add({
            color: "primary", 
            title: 'Error:',
            message: error, 
            isOpen: true,
            loading: false
          })
          // setRedirectLink();
        }        
      })
    }
  }

  useEffect(() => {
    // observating uploading progress with socket.io
    if (!socket || !item.id ) return;
    const channelName = `direct_upload_${item.id}`;    
    if(channels.indexOf(channelName) === -1) {
      addChannel([...channels, channelName], {...updatingAudioposts, [channelName]: {title: "Processing..."}})
      // addChannel([...channels, channelName])
      // console.log(`listen to live_download_${id}`)
      socket.on(channelName, (data) => {
        handleSocketCallback(channelName, data)
      })
    }
  }, [socket, item.id])    

  useEffect(() => {
    justcastApi.get(`/v1/shows/${showId}/people`)
    .then((res) => {
      setPeople(res.data.people)
      const options = res.data.person_taxonomies.map((x, idx) => {
        return {
          value: x.code, label: x.role_title, ...x
        }
      });
      setPersonTaxonomies(options)      
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get(`/v1/shows/${showId}`)
    .then((res) => {
      const data = res.data;
      // if(data.support_file && data.support_file.file_type_group) {
      //   setFileGroupType(data.support_file.file_type_group)
      // }
      const d = new Date()
      const content_hash = `${showId}-${d.getTime()}-${Math.floor(Math.random() * 1000000)}`

      setItem({
        // id: content_hash,
        name: "",
        audio_date: new Date(),
        // created_at: new Date(),
        description: "",
        itunes_keywords: "",
        explicit_type_id: 2,
        episode_type_id: 1,
        artwork_url: "",
        episode_number: 0,
        audio_url: "",
        content_hash,
        duration: "",
        support_file_type: "",
        file_size: 0
      });
      
      setShowName(data.name)

      setIsDirectUpload(data.is_direct_upload);

      if(data.allow_podieo_max_second && data.allow_podieo_max_second > 0) {
        setPodieoAllowTime(data.allow_podieo_max_second)
      }
      // setEpisodeName(data.name)

      setEpisodesMenus({
        title: 'New Episode',
        subtitle: data.name,
        currentPageId: 'episodes',
        currentTabId: 'new-episode',
        showId: showId,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        isDirectUpload: res.data.is_direct_upload,
      });
    })
    .catch((err) => {
      console.log(err)
    })
    
    justcastApi.get('/v1/explicit_types')
    .then((res) => {
      setExplicitTypes(res.data)
    })
    .catch((err) => {
      console.log(err)
    })   
    
    justcastApi.get('/v1/episode_types')
    .then((res) => {
      setEpisodeTypes(res.data)
    })
    .catch((err) => {
      console.log(err)
    })  
    
    justcastApi.get('/v1/allow_upload_files')
    .then((res) => {
      //setSupportFiles(res.data)
      const data = res.data.map((x) => x.file_extension);
      setSupportFiles(data);
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get(`/v1/shows/${showId}/seasons`)
    .then((res) => {
      setSeasons(res.data);
    })
    .catch((err) => {
      console.log(err)
    })      

    justcastApi.get(`/v1/shows/${showId}/seasons`)
    .then((res) => {
      setSeasons(res.data);
    })
    .catch((err) => {
      console.log(err)
    })  
  },[showId])

  useEffect(() => {
    if(directUploadStatusCode === 200) {
      // add an alert message saying it's been uploaded
      const message = `${item.name} is published.`
      addWithTimeout({color: 'warning', message, isOpen: true, timeout: 5000})      
      // setRedirectPage(`/shows/${showId}/episodes`);
      setRedirectLink();
    }
  }, [directUploadStatusCode])

  useEffect(() => {
    if(skill_direct_upload !== true) {
      modalContext.createNewPodcast()
    }
  }, [skill_direct_upload])

  const handleAddSeason = () => {
    setSeasonModalOpen(true);
  }

  const handleNewSeasonModalToggle = () => {
    setSeasonModalOpen(false);
  }

  const handleInputNewSeasonChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setNewSeason({...newSeason, [name]: value});
  }

  const handleSeasonSave = () => {
    justcastApi.post(`/v1/shows/${showId}/seasons`, {
      name: newSeason.name,
      number: newSeason.number
    })
    .then((res) => {
      setSeasonId(res.data.id);
      setSeasonErrorMessages({})
      setNewSeason({name: "", number: "", id: null})
      setSeasons([res.data, ...seasons]);
      setSeasonModalOpen(false);
      addWithTimeout({color: 'primary', message: `Added a new season`, isOpen: true, timeout: 3000});
    })
    .catch((err) => {
      setSeasonErrorMessages(err.response.data)
    })
  }  
  
  const handleDeletePerson = (idx) => {
    const _hosts = hosts.filter((_, i) => i !== idx);
    setHosts(_hosts);
  }

  const handleUpdatePersonRole = (idx, roles) => {
    // console.log({idx, roles});
    const _hosts = [...hosts];
    _hosts[idx]["roles"] = roles;
    setHosts(_hosts);
  }

  const handleSorting = (result) => {
    const items = Array.from(hosts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newPeople = items.map((person, position_order_idx) => {
      return {...person, sort_order: position_order_idx + 1}
    })
    setHosts(newPeople);
  }

  const handleAddNewPerson = ({name, email, roles, roleTitle, groupTitle}) => {
    const _person = {name, email, roles, role: roleTitle, group: groupTitle}
    setAddPersonModal(false);
    justcastApi.post(`/v1/shows/${showId}/people`, _person)
    .then((res) => {
      const person = res.data;

      modalContext.addWithMessages({
        color: "primary", 
        title: 'Adding a new person',
        messages: [], 
        isOpen: true,
        loading: true
      })   

      setTimeout(() => {
        setHosts([...hosts, {...person, id: null, person_id: person.id}]);
        setPeople([...people, person])        
        modalContext.addWithMessages({
          color: "primary", 
          title: 'Success add a new person',
          messages: [`Name: ${person.name}`, `Role: ${person.role}`], 
          isOpen: true,
          loading: false
        })   
      }, 900)      
    })
    .catch((err) => {
      if(err.response.status === 500) {
        modalContext.add({
          color: "primary", 
          title: 'Error:',
          message: "Something went wrong.  Please contact us for help.", 
          isOpen: true,
          loading: false
        }) 
      } else if(err && err.response && err.response.data) {
        const errs = err.response.data;
        const keys = Object.keys(errs);

        const messages = keys.map((key) => `${key} ${errs[key].join(',')}`)

        modalContext.addWithMessages({
          color: "primary", 
          title: 'Error:',
          messages: messages, 
          isOpen: true,
          loading: false
        })                    

      } else {
        console.log(err);

        modalContext.add({
          color: "primary", 
          title: 'Error:',
          message: "Something went wrong.  Please contact us for help.", 
          isOpen: true,
          loading: false
        })  
      }
    })
  }


  if(redirectPage) {
    return <Redirect to={redirectPage}/>
  }

  return (
    <>
      <PeopleListModal
        people={people}
        modal={peopleModal}
        toggleHost={toggleHost}
        toggle={togglePeopleModal}
      />
      <NewPersonModal
        addHost={handleAddNewPerson}
        modal={addPersonModal}
        toggle={toggleNewPersonModal}
        personTaxonomies={personTaxonomies}
      />    
      <div className="container-fluid">
        <PermissionModal callback={setRedirectLink}/>
        <SeasonForm 
          title={"Add a new season"}
          isOpen={isSeasonModalOpen}
          toggle={handleNewSeasonModalToggle}
          data={newSeason}
          errorMessages={errorSeasonMessages}
          onChange={handleInputNewSeasonChange}
        >
          <Button color="primary" onClick={handleSeasonSave}>Save</Button>
        </SeasonForm>         
        <div className="row">
          <ModalBox
            modal={modal}
            setModal={toggleModal}
            channelName={`direct_upload_${item.id}`}
          />          
          <div className="col-12">
            <EpisodeForm
              isCreateForm={true}
              quillRef={quillRef}
              backLink={`/shows/${showId}/episodes`}
              handleUpdate={handlePublish}
              handleToggleDraft={handleSaveAsDraft}
              name={item.name} 
              audio_date={item.audio_date}
              created_at={item.created_at}
              description={item.description}
              itunesKeywords={item.itunes_keywords}
              explicitTypeId={item.explicit_type_id}
              episodeTypeId={item.episode_type_id}
              explicitTypes={explicitTypes}
              episodeTypes={episodeTypes}
              artworkUrl={item.artwork_url}
              season_id={seasonId}
              episodeNumber={item.episode_number}
              audioUrl={item.audio_url}
              supportFiles={supportFiles}
              fileTypeGroup={fileTypeGroup}
              isDirectUpload={isDirectUpload}
              uploadFile={uploadFile}
              seasons={seasons}
              handleMediaFileChange={handleMediaFileChange}
              handleQuillEditorChange={handleQuillEditorChange}
              handleSelectionChange={handleSelectionChange}
              handleDatepickerChange={handleDatepickerChange}
              handleInputValueChange={handleInputValueChange}
              handleUploadFileChange={setUploadFile}
              handleArtworkChange={handleArtworkChange}
              handleArtworkFileChange={setUploadArtworkFile}          
              handleRemoveImage={handleRemoveImage}
              handleTitleChange={handleInputValueChange}
              handleSeasonChange={handleSeasonChange}
              submitButtonLabel={"Save Draft"}
              saveButtonLabel={"Publish"}
              handleAddSeason={handleAddSeason}
              setConvertToMp3={setConvertToMp3}
            >
              <EpisodePeople
                toggleNewPersonModal={toggleNewPersonModal}
                people={people}
                hosts={hosts}
                handleSorting={handleSorting}
                toggleModal={togglePeopleModal}
                personTaxonomies={personTaxonomies}
                handleDeletePerson={handleDeletePerson}
                handleTaxonomyChange={handleUpdatePersonRole}
              />              
            </EpisodeForm>
          </div>
        </div>
      </div>
    </>
  )
}

export default EpisodeNew;