import React from "react";
import { Button, Card, CardTitle, CardText, CardBody, CardFooter, CardHeader } from 'reactstrap';
import CardAvatar from './CardAvatar'

const Description = ({text}) => {
  if(text) {
    return <p className="small text-muted mb-1">{text}</p>
  }
  return null;
}

const ConnectedFooter = ({screenName, handleDelete}) => {
  if(screenName) {
    return (
      <CardFooter className="card-footer-boxed">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <small><span className="text-success">●</span> Connected</small>
          </div>
          <div className="col-auto">
            <div className="btn btn-danger btn-sm" onClick={handleDelete}>Disconnect</div>
          </div>
        </div>
      </CardFooter>
    )
  }

  return null;
}

const CardItem = (props) => {

  const {
    title, 
    description, 
    buttonTitle, 
    icon, 
    buttonClick,
    screenName,
    handleDelete
  } = props;

  return (
    <Card>
      <CardBody>
        <div className="row align-items-center">
          <div className="col-auto">
            <CardAvatar avatar={icon}/>
          </div>
          <div className="col ml-n2">
            <h2 className="mb-1">{title}</h2>
            <Description text={description}/>
          </div>
          {
            buttonTitle && <div className="col-auto"><Button color="primary" className="btn btn-sm" onClick={buttonClick}>{buttonTitle}</Button></div>
          }
        </div>        
      </CardBody>  
      {props.children}    
      <ConnectedFooter screenName={screenName} handleDelete={handleDelete}/>
    </Card>
  )
}

export default CardItem;