import React, {useEffect, useState, useContext} from "react";
import {Redirect} from 'react-router-dom'
import justcastApi from '../api/justcast';
import {uploadImageHandler} from '../api/doUploader'
import ImageUploadForm from '../components/ImageUploadForm';
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import { Spinner } from 'reactstrap';
import SimpleMedia from '../components/SimpleMedia'

const ShowHeaderImage = (props) => {
  const {addWithTimeout} = useContext(AlertContext);
  const screenContext = useContext(ScreenContext);
  const menuContext = useContext(MenuContext);
  const id = props.match.params.id;
  const [artwork, setArtwork] = useState(null);
  const [imgUrl, setImgUrl] = useState('https://via.placeholder.com/150')
  const [denied, setDenied] = useState(false);

  const handleUpdate = () => {
    uploadImageHandler(artwork)
    .then((res) => {
      const url = res.data.path;
      return justcastApi.put(`/v1/shows/${id}`, {header_img_url: url});
    })
    .then((res) => {        
      addWithTimeout({color: 'success', message: `Artwork has been updated!`, isOpen: true, timeout: 3000})
      if(res.data.header_img_url) {
        setImgUrl(res.data.header_img_url);
      }
    })
    .catch((err) => {
      console.log(err.message)
      addWithTimeout({color: 'danger', message: err.message, isOpen: true, timeout: 3000})
    })
  }

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.name;
      if(res.data.header_img_url) {
        setImgUrl(res.data.header_img_url)
      }
      screenContext.finishFetching();

      menuContext.setWebsiteMenu({
        title: 'Header image',
        subtitle: name,
        currentPageId: 'website_settings',
        currentTabId: 'header_image',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show        
      }); 

    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true)
      console.log(err)
    })    
  }, [id])

  if(denied) {
    return <Redirect to="/dashboard"/>
  }
  
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> : <>
                <p>This photo will be used on your podcast website.</p>
                <SimpleMedia img={imgUrl} imgAltName={'podcast header image'} headingLabel='Header Image URL' bodyText={imgUrl} />
                <hr/>
                <ImageUploadForm setArtwork={setArtwork} handleUpdate={handleUpdate} showId={id} artworkWidth={1} artworkHeight={1}/>
              </>
            }            
          </div>
        </div>    
      </div>    
    </>
  )
}

export default ShowHeaderImage;