import React, {useRef} from "react";
import { CustomInput, FormGroup } from 'reactstrap';

const AudioFileForm = ({setSrc, setFile, setModal}) => {
  const accept = ['audio/mp3', 'audio/mpeg', 'audio/aac', 'audio/wav', 'video/mp4', 'audio/x-m4a', 'audio/x-m4b', 'video/quicktime', 'video/x-m4v']
  const mp3 = ['audio/mp3', 'audio/mpeg'];

  return (
    <FormGroup>
      <CustomInput
        type="file"
        accept={accept.join(",")}
        id="audiopicker"
        name="audio file picker"
        onChange={(event) => {
          if(event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const src = URL.createObjectURL(file);
            const type = file['type'];
            if(mp3.indexOf(type) === -1) {
              setModal(true);
            }
            setSrc(src)
            setFile(file)
          }
        }}
      />
    </FormGroup>
  )
}

export default AudioFileForm;