import React from 'react'
import { ListGroup, ListGroupItem, Row, Col, Spinner} from 'reactstrap';
import { Link } from "react-router-dom";
import moment from 'moment';

export default function TranscriptList({transcripts, audiopost_id}) {
  if(transcripts) {
    return (
      <ListGroup className="list-group-flush">
      {
        transcripts.map((transcript, id) => <ListGroupItem key={id}>
          <Row className="align-items-center">
            <Col>
              <h4>{transcript.status}</h4>
              <small className='text-muted'>Created at {moment(transcript.created_at).format("MMM Do YYYY, h:mm:ss a")}</small>
            </Col>
            <Col className="col-auto">
              {transcript.is_completed ? <Link to={`/episodes/${audiopost_id}/transcripts/${transcript.id}/segments`} className="btn btn-primary">View</Link> : <Spinner/>}
            </Col>
          </Row>
        </ListGroupItem>)
      }
      </ListGroup>
    )
  }

  return null;
}
