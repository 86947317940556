import React, {useContext, useState, useEffect, useRef } from "react";
import { Button, Spinner, FormGroup } from 'reactstrap';
import justcastApi from '../../../api/justcast';
import {Context as MenuContext} from '../../../context/MenuContext'
import {Context as ScreenContext} from '../../../context/ScreenContext'
import { EditForm } from '../../../components/team/episodes'
import AudioPreviewPlayer from "../../../components/episode/AudioPreviewPlayer"
import ToastMessage from '../../../components/ToastMessage';

export default function Settings(props) {
  const {show_id, id} = props.match.params;
  const menuContext = useContext(MenuContext);
  const screenContext = useContext(ScreenContext);
  const [openToast, setOpenToast] = useState(false);
  const [toastTitle, setToastTitle] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [explicitTypes, setExplicitTypes] = useState([])
  const [episodeTypes, setEpisodeTypes] = useState([])
  const [seasons, setSeasons] = useState([]);
  const [audiopostData, setAudiopostData] = useState({});
  const [description, setDescription] = useState(null);
  const [location, setLocation] = useState({location_name: null, location_geo: null})
  const [audioSrc, setAudioSrc] = useState(null);

  const quillRef = useRef(null);

  useEffect(() => {
    if(show_id && id) {
      screenContext.startFetching();

      justcastApi.get(`/v2/team_podcasts/${show_id}/team_audioposts/${id}`)
      .then((res) => {
        screenContext.finishFetching();

        const { show, location_geo, location_name, audio_src, audio_url} = res.data;
        setLocation({location_geo, location_name});
        setDescription(res.data.description);
        setSeasons(res.data.seasons.map((item) => {
          return {id: item.id, name: item.name || item.number, label: item.name || item.number, value: item.number}
        }))
        setEpisodeTypes(res.data.episode_types.map((item) => {
          return {id: item.id, name: item.name, label: item.name, value: item.id}
        }))
        setExplicitTypes(res.data.explicit_types)
        setAudiopostData(res.data);
        setAudioSrc(audio_src || audio_url)

        menuContext.setTeamEpisodePageMenus({
          title: 'Settings',
          subtitle: `${show.name} > ${res.data.name}`,
          currentPageId: 'episode',
          currentTabId: 'settings',
          showId: show_id,
          episodeId: id,
          landingPageUrl: show.landing_page_url,
          websiteUrl: show.player_page_link,
          rssFeed: show.rss_feed,
          passwordProtected: show.is_password_protected,
          advancedPrivate: show.is_advanced_private_feed,
          isPrivate: show.is_private,
          isPrivateShow: show.is_private_show,
        });
      })
      .catch((err) => {
        screenContext.finishFetching();
      })
    }
  }, [show_id, id])

  const toggleToast = () => {
    setOpenToast(!openToast);
  }

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setAudiopostData(prev => {return {...prev, [name]: value}})
  }

  const handleLocationChange = ({name, lat, lng}) => {
    const geo = `geo:${lat},${lng}`;
    setLocation({location_name: name, location_geo: geo})
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    handleUpdate();
  }

  const handleUpdate = () => {
    if(timeoutId) {
      clearTimeout(timeoutId)
    };

    const message = quillRef?.current?.unprivilegedEditor?.getHTML()

    justcastApi.put(`/v2/team_podcasts/${show_id}/team_audioposts/${id}`, {
      audiopost: {...audiopostData, ...location, description: message}
    }).then((res) => {
      setOpenToast(true);
      setToastTitle("Success")
      setToastMessage(`${res.data.name} has been updated!`)
      const data = res.data;
      setAudiopostData(data);
      setDescription(data.description)

      const _timeOutId = setTimeout(() => {
        setOpenToast(false);
      }, 2000)

      setTimeoutId(_timeOutId)
    }).catch((err) => {
      setOpenToast(true);
      setToastTitle("Error")
      setToastMessage("Something went wrong");
      const _timeOutId = setTimeout(() => {
        setOpenToast(false);
      }, 2000)

      setTimeoutId(_timeOutId)
    })
  }

  if(screenContext.state.loading) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  if(!audiopostData.name) {
    return null;
  }

  return (
    <div className="container-fluid" style={{paddingBottom: 20}}>
      <ToastMessage
        toggle={toggleToast}
        show={openToast}
        title={toastTitle}
        message={toastMessage}
      />
      <EditForm
        name={audiopostData.name}
        quillRef={quillRef}
        audio_date={audiopostData.audio_date}
        is_draft={audiopostData.is_draft}
        seasons={seasons}
        season_id={audiopostData.season_id}
        description={description}
        itunes_keywords={audiopostData.itunes_keywords}
        episode_number={audiopostData.episode_number}
        explicitTypes={explicitTypes}
        explicit_type_id={audiopostData.explicit_type_id}
        episodeTypes={episodeTypes}
        episode_type_id={audiopostData.episode_type_id}
        publishStatus={audiopostData.is_draft ? {value: true, id: 1, label: "Draft"} : {value: false, id: 2, label: "Publish"}}
        handleInputChange={handleInputChange}
        setDescription={setDescription}
        location_name={location.location_name}
        handleLocationChange={handleLocationChange}
        handleSubmit={handleSubmit}
      >
        {audioSrc && <FormGroup>
          <label>Audio File</label>
          <AudioPreviewPlayer
            url={audioSrc}
            title={audiopostData.name}
          />
          <div style={{marginTop: "8px"}}><small className="text-muted">Supported file types are MP3, WAV, or M4A. Audio files will be encoded up to 128kbps MP3s and the file size cannot exceed 1GB. Variable bit rate files under 128 kbps are not supported.</small></div>
        </FormGroup>
        }
      </EditForm>
      <div style={{margin: "40px 0 80px 0", display: "flex", justifyContent: "space-between", flexDirection: "row-reverse"}}>
        <Button color="primary" onClick={handleUpdate}>Save</Button>
      </div>
    </div>
  )
}
