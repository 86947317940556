import React, {useEffect, useState, useContext} from "react";
import {Context as MenuContext} from '../context/MenuContext'
import {Mixpanel} from '../api/mixpanel'
import introVideos from '../libs/introVideos'
import VideoCardList from '../components/VideoCardList'
import {getDropboxAuthUrl} from '../libs/DropboxOAuth';

const IntroVideos = ()  => {
  const menuContext = useContext(MenuContext);
  const [dropboxUrl, setDropboxUrl] = useState('');

  useEffect(() => {
    menuContext.setMainPageLeftMenu({title: 'Introductory Videos', subtitle: "Overview", currentPageId: 'intro_videos'});
    getDropboxAuthUrl((url) => {
      if(url) {
        setDropboxUrl(url);
      }
    })
  }, [])
  return (
    <>
      <div className="container-fluid">
        <p>Start your podcast journey using JustCast with this set of introductory videos! These videos are designed to give you an overview of JustCast various features and quickly get you familiar with them.</p>
        <VideoCardList videos={introVideos} dropboxUrl={dropboxUrl}/>
      </div>
    </>
  )
}

export default IntroVideos;