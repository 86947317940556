import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {Context as AuthContext} from '../../context/AuthContext'
import {Context as MenuContext} from '../../context/MenuContext'

const UserMenu = () => {
  const {state, signout} = useContext(AuthContext);
  const menuContext = useContext(MenuContext);
  const {toggleUserMenu} = menuContext;

  return (
    <>
      <ButtonDropdown 
        direction="left"
        isOpen={menuContext.state.userModalOpen} 
        toggle={toggleUserMenu}>
        <DropdownToggle caret >
          <i className="fe fe-user"/>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{state.email}</DropdownItem>
          <DropdownItem divider />
          {
            menuContext.state.userSettings.map((item) => 
              <DropdownItem 
                key={item.id} 
                tag={Link} 
                to={item.link}
              >
                  <i className={item.iconClassName}/><span style={{marginLeft: "10px"}}>{item.name}</span> 
              </DropdownItem>
            )
          }
          <DropdownItem
            onClick={signout}
            className="d-flex align-items-center"
          >
            <i className='fe fe-log-out'/> 
            <span style={{marginLeft: "10px"}}>Sign out</span>              
          </DropdownItem>          
        </DropdownMenu>
      </ButtonDropdown>
      {/* <div className="col-12 col-md-auto mt-3 mt-md-0">
        <div className="btn btn-white lift" onClick={toggleUserMenu}>
          <i className="fe fe-user"/>
        </div>
      </div> */}
    </>
  )
}

export default UserMenu;