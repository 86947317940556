import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap';
import CardContent from './CardContent'
import ContentPagnation from './ContentPagnation'

export default function ContentCard({data, handleReRun}) {
  const {id, title, label, is_list, status, is_completed, ai_contents} = data;
  const [currentPage, setCurrentPage] = useState(0);

  const totalPage = ai_contents && ai_contents.length > 0 ? ai_contents.length : 0;

  const updatePage = (number) => {
    setCurrentPage(number);
  }

  const handleReRunClicked = (id) => {
    handleReRun(id);
    setCurrentPage(0)
  }

  return (
    <Card>
      <ContentPagnation
        id={id}
        title={label}
        totalPage={totalPage}
        currentPage={currentPage}
        updatePage={updatePage}
        handleReRun={handleReRunClicked}
      >
        <CardBody>
          <CardContent status={status} is_completed={is_completed}>
            {
              is_list ? <ol>
              {
                ai_contents[currentPage]?.map((content, key) => {
                  return (
                    <li key={key}>{content}</li>
                  )
                })
              }
            </ol> : <p>
              {ai_contents[currentPage]?.join(", ")}
            </p>
            }

          </CardContent>
        </CardBody>
      </ContentPagnation>
    </Card>
  )
}
