import React from 'react'
import { Card, CardBody, Button } from 'reactstrap';
import { Play } from 'react-feather';

export default function TranscriptCard({playSentence, start_time,minmima_index, speaker_name, sentence_speaker_code, text, start_time_str, updateSpeakerModal}) {

  const handlePlayClick= () => {
    playSentence(start_time)
  }

  const openModal = () => {
    updateSpeakerModal({
      open: true,
      speaker_name,
      sentence_speaker_code,
      minmima_index
    })
  }


  return (
    <Card>
      <CardBody>
        <div className='jc-transcript-editor-sentence'>
          <div className="play-btn" onClick={handlePlayClick}><Play size="12"/></div>
          {speaker_name && <Button size="sm" color="primary" onClick={openModal}>{speaker_name}</Button>}
          <div>
            {start_time_str}
          </div>
        </div>
        <p dangerouslySetInnerHTML={{__html: text}}/>
      </CardBody>
    </Card>
  )
}
