import React, {useState} from 'react'
import {useAtom} from 'jotai'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { getHHMMSSFromSeconds } from '../../libs/timeTransformation'
import { updateChapterAtom } from './chapterAtom'
import { updatePlayerAtom } from './playerAtom'

export default function ChapterItem({
  title, start_time, id, url, img, handleDestroyChapter
}) {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [, updateChapter] = useAtom(updateChapterAtom);
  const [player, updatePlayer] = useAtom(updatePlayerAtom)

  const handleEditClick = () => {
    updateChapter({
      id,
      url,
      img,
      title,
      start_time,
      isOpen: true
    })
  }

  const handlePlayPreview = () => {
    player.playerRef.current.seekTo(start_time, 'seconds')
    if(!player.playing) {
      updatePlayer({
        playing: true
      })
    }
  }

  const handleDestroy = () => {
    handleDestroyChapter(id)
  }

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
      <DropdownToggle caret  color="primary">
        {getHHMMSSFromSeconds(start_time)} | {title}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handlePlayPreview}><i className='fe fe-play'/> Play</DropdownItem>
        <DropdownItem onClick={handleEditClick}><i className='fe fe-edit'/> Edit</DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={handleDestroy}><i className='fe fe-trash'/> Delete</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  )
}
