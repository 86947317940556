import React, {useEffect, useContext, useState} from "react";
import { Button, Card, CardHeader, CardBody} from 'reactstrap';
import justcastApi from '../api/justcast'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AuthContext} from '../context/AuthContext'
import NewPodcastModal from '../components/NewPodcastModal';
import {PermissionModal} from '../components/FileUploader';
import {getDropboxAuthUrl} from '../libs/DropboxOAuth';
import {ImportPodcastModal} from '../components/ImportPodcastModal'

const ShowNew = () => {
  const menuContext = useContext(MenuContext);
  const modalContext = useContext(ModalContext);
  const { createNewPodcast } = modalContext;
  const authContext = useContext(AuthContext);
  const { skill_direct_upload } = authContext.state

  const [connections, setConnections] = useState([]);
  // import podcast modal states
  const [importModal, setImportModal] = useState(false);
  const [importTitle, setImportTitle] = useState('');
  const [importMessage, setImportMessage] = useState('');
  const [importModalError, setImportModalError] = useState(false);
//const dropboxUrl = getDropboxAuthUrl();
  const [dropboxUrl, setDropboxUrl] = useState('');


  const toggleImportModal = () => {
    setImportModal(!importModal);
  }

  useEffect(() => {
    menuContext.setMainPageLeftMenu({title: 'New Podcast', subtitle: "Overview", currentPageId: 'new_podcast'});
    justcastApi.get('/v1/clouddrive_connections')
    .then((res) => {
      // console.log(res.data)
      setConnections(res.data);
    })
    .catch((err) => {
      console.log(err.response.data)
    })
    getDropboxAuthUrl((url) => {
      if(url) {
        setDropboxUrl(url);
      }
    })
  },[])

  const handleNewPodcastClicked = () => {
    createNewPodcast();
  }

  const handleImportPodcastClick = () => {
    justcastApi.get('/v1/prepare_migration')
    .then((res) => {
      const title = "Import an Existing Podcast"
      const message = "Making the swtich?  Import your existing podcast by entering your RSS feed.  After confirming ownership, we'll email you when it's done importing."
      setImportModal(true)
      setImportModalError(false)
      setImportTitle(title)
      setImportMessage(message)
    })
    .catch((err) => {
      const {title, message} = err.response.data;
      setImportModal(true)
      setImportModalError(true)
      setImportTitle(title)
      setImportMessage(message)
    })
  }

  return (
    <div className="container-fluid">
      <ImportPodcastModal title={importTitle} message={importMessage} isOpen={importModal} toggle={toggleImportModal} isErrorPage={importModalError}/>
      <NewPodcastModal/>
      {
        <Card>
          <CardBody>
            <h2>Create a new podcast with JustCast</h2>
            <p>
              Starting a new podcast? You'll be ready to go in a only a few short steps: enter show information, upload artwork, and add your first episode.
            </p>
            <div>
              <Button color="warning" onClick={handleNewPodcastClicked}>Start from scratch</Button>
              {/* {' '}<Button color="primary" onClick={handleImportPodcastClick}>Import from elsewhere</Button> */}
            </div>
          </CardBody>
        </Card>
      }
    </div>
  )
}

export default ShowNew;