import React, {useEffect, useState, useContext} from "react";
import { Redirect } from "react-router-dom";
import { InputGroup, InputGroupText, InputGroupAddon, Input, Button } from 'reactstrap';
import justcastApi from '../api/justcast';
import {Context as AlertContext} from '../context/AlertContext'
import {Context as AuthContext} from '../context/AuthContext'
import ShowPageHeader from '../components/PageHeader/ShowPageHeader';

const ShowImport = (props) => {
  const id = props.match.params.id;
  const authContext = useContext(AuthContext);
  const {addWithTimeout} = useContext(AlertContext);
  const [showName, setShowName] = useState('')
  const [item, setItem] = useState({});
  const [rss, setRSS] = useState('');
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.name;
      setShowName(name);
      setItem(res.data);      
    })
    .catch((err) => {
      console.log(err)
    })        
  },[id])

  const handleImportClick = () => {
    if(rss.length > 30) {
      justcastApi.post(`/v1/shows/${id}/import_from_rss_feed`, {
        rss_feed: rss
      })
      .then((res) => {
        // redirect to shows page, having an alert message saying migraiton has started
        const message = res.data.message;
        addWithTimeout({color: 'warning', message, isOpen: true, timeout: 3000})
        setRedirect(true);
        // console.log(res.data.message);
      })
      .catch((err) => {
        addWithTimeout({color: 'danger', message:  'Failed to Import.', isOpen: true, timeout: 3000})
        console.log(err);
      })      
    }
  }


  if(redirect) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <>
      <ShowPageHeader 
        showId={id}
        isPrivate={item.is_private}
        isAdvancedPrivateFeed={item.is_advanced_private_feed}
        headerPreTitle={'Import From RSS'}        
        headerTitle={showName}
        currentPageName={'import_from_rss'}
        showSlug={item.slug}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card card-body">
              <div className="row">
                <h2 className="col-12">Import an Existing Show</h2>
                <div className="col-12">
                  <p>Making a swtich? Imort your show by entering your RSS feed.  We'll email you when it's done!</p>
                </div>                
                <div className="col-12">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">RSS feed</InputGroupAddon>
                    <Input 
                      type="text" 
                      name="rss" 
                      id="rss"
                      placeholder="https://abc.com/123.rss"
                      onChange={(e) => {
                        setRSS(e.target.value)
                      }}
                    />
                  </InputGroup>                  
                </div>                               
                <div className="col-12">
                  <hr/> 
                  <Button color="primary"
                    onClick={handleImportClick}
                  >Begin Import</Button>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShowImport