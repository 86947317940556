import {updateHeaderCommons, setItem} from '../api/justcast'
import queryString from 'query-string'

const AsyncStorage = {}

AsyncStorage.setHeaderItems = (headers) => {

  const accessToken = headers['access-token']
  const client = headers['client']
  const expiry = headers['expiry']
  const accessType = headers['access-type']
  const uid = headers['uid']

  localStorage.setItem('access-token', accessToken)
  localStorage.setItem('client', client)
  localStorage.setItem('expiry', expiry)
  localStorage.setItem('token-type', accessType)
  localStorage.setItem('uid', uid)

  updateHeaderCommons({accessToken, client, expiry, accessType, uid})
}

AsyncStorage.updateHeaderCommons = () => {
  const accessToken = localStorage.getItem('access-token')
  const client = localStorage.getItem('client')
  const expiry = localStorage.getItem('expiry')
  const accessType = localStorage.getItem('access-type')
  const uid = localStorage.getItem('uid')

  updateHeaderCommons({accessToken, client, expiry, accessType, uid})
}

AsyncStorage.removeHeaderItems = () => {
  localStorage.removeItem('access-token')
  localStorage.removeItem('client')
  localStorage.removeItem('expiry')
  localStorage.removeItem('access-type')
  localStorage.removeItem('uid')
}

AsyncStorage.setRequestHeaderItem = (key, value) => {
  setItem(key, value)
  return {[key]: value};
}

AsyncStorage.getItem = (key) => {
  return localStorage.getItem(key)
}

AsyncStorage.setReferralCode = (pathString) => {
  const values = queryString.parse(pathString);
  const currentCode = localStorage.getItem('via');
  const currentTerm = localStorage.getItem('utm_term');

  if(!currentCode && values && values["via"]) {
    const via = values['via'];
    localStorage.setItem('via', via);
  }

  if(!currentTerm && values && values["utm_term"]) {
    const utm_term = values['utm_term'];
    localStorage.setItem('utm_term', utm_term);
  }
}

AsyncStorage.setShopifyPublicId = (shopify_connection_public_id) => {
  if(shopify_connection_public_id) {
    localStorage.setItem('shopify_connection_public_id', shopify_connection_public_id);
  }
}

AsyncStorage.removeShopifyPublicId = () => {
  localStorage.removeItem('shopify_connection_public_id')
}



export default AsyncStorage;