import React, {useEffect, useState, useContext} from "react";
import { Card, CardBody, Row } from 'reactstrap';
import justcastApi from '../api/justcast';
import PodcastNetworks from '../components/PodcastNetworks';
import InputWithCopyButton from '../components/InputWithCopyButton';
import DistributeCheckingListItem from '../components/DistributeCheckingListItem';
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import { Spinner } from 'reactstrap';
import {Mixpanel} from '../api/mixpanel'
import { Rss, Check, CheckCircle } from 'react-feather';

const ShowDistributions = (props) => {
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const id = props.match.params.id;
  const [feedUrl, setFeedUrl] = useState('');
  const [distributionChecklist, setDistributionChecklist] = useState([]);
  
  // Mixpanel
  const authContext = useContext(AuthContext);
  const uid = authContext.state.uid;  

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;
      const showSlug = res.data.slug;
      setFeedUrl(`${process.env.REACT_APP_RSS_FEED_BASE_PATH}/shows/${showSlug}/audioposts.rss`)
      
      menuContext.setPodcastPageMenu({
        title: 'Distributions',
        subtitle: name,
        currentPageId: 'distributions',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website         
      });

      // setup check list for distribution
      const audioposts_count_checked = res.data.audioposts_count && res.data.audioposts_count > 0 ? true : false;
      const artwork_url_checked = res.data.artwork_url && res.data.artwork_url.length > 10 ? true : false;
      const description_checked = res.data.description && res.data.description.length > 10 ? true : false;
      const category_id_checked = res.data.category_id ? true : false;
      const language_id_checked = res.data.language_id ? true : false;

      const _distributionChecklist = [
        {id: "d1", text: 'Upload a high-quality podcast artwork image.', linkTitle: 'Upload artwork', link: `/shows/${id}/artwork`, checked: artwork_url_checked},
        {id: "d2",text: 'Have at least one published episode with uploaded audio file.', checked: audioposts_count_checked},
        {id: "d3",text: `Fill out your podcast's description.`, linkTitle: 'Add description', link: `/shows/${id}/settings`, checked: description_checked},
        {id: "d4",text: 'Choose a primary podcast category.', linkTitle: 'Pick a category', link: `/shows/${id}/settings`, checked: category_id_checked},
        {id: "d5",text: 'Choose the spoken language for your podcast.', linkTitle: 'Pick a language', link: `/shows/${id}/settings`, checked: language_id_checked},
      ];

      setDistributionChecklist(_distributionChecklist);

      // Mixpanel
      Mixpanel.identify(uid);
      Mixpanel.track('Show Distributions loaded', {show_id: id});

      screenContext.finishFetching();

    })
    .catch((err) => {
      screenContext.finishFetching();
      // console.log(err)
    })
  }, [id]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <p id="distribution_intro">Distribution of your podcast to as many directories and apps as possible ensures that your listeners are able to easily discover and listen to your show. Your RSS Feed serves as the main source of information for these apps and directories.</p>
          </div>
          <div className="col-12">
            <Card>
              <CardBody>
                <Row className="align-items-center">
                  <div className="col-auto">
                    <div className="avatar">
                      <span className="avatar-title rounded-circle">
                        <Rss size={24} />
                      </span>
                    </div>
                  </div>
                  <div className="col ml-n2">
                    <h2 className="margin-bottom-10px">Your RSS Feed</h2>
                    <InputWithCopyButton id="distribute-rss-feed" inputValue={feedUrl}/>
                  </div>
                </Row>           
              </CardBody>
            </Card>            
          </div>
          <div className="col-12">            
            <Card>
              <CardBody>
                <Row className="align-items-center">
                  <div className="col-auto">
                    <div className="avatar">
                      <span className="avatar-title rounded-circle">
                        <CheckCircle size={24} />
                      </span>
                    </div>
                  </div>
                  <div className="col ml-n2">
                    <h2 className="margin-bottom-10px">Distribution Checklist</h2>
                    <p>Before publishing your podcast and distributing it out to the various podcasting directories and apps, you'll need to make sure you meet the minimum requirements.</p>
                  </div>
                </Row>           
              </CardBody>
              <CardBody>
                <div className="list-group list-group-flush">    
                  {
                    distributionChecklist.map((item) => 
                      <DistributeCheckingListItem
                        key={item.id}
                        text={item.text}
                        checked={item.checked}
                        linkTitle={item.linkTitle}
                        link={item.link}
                      />
                    )
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </div>        
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> : <PodcastNetworks feed={feedUrl} show_id={id}/>
            }            
          </div>
        </div>
      </div>
    </>
  )
}

export default ShowDistributions;