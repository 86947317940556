import React, {useState, useEffect} from 'react'
import justcastApi from '../../api/justcast';
import TreeItem from "./TreeItem"

const ExplorerList = ({showId}) => {
  const [shows, setShows] = useState([]);

  const endpointUrl = `/v1/shows/${showId}/get_show_subfolders`;

  const fetchShows = () => {
    if(showId) {
      justcastApi.get(endpointUrl)
      .then((res) => {
        setShows(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }

  useEffect(() => {
    fetchShows()
  }, [showId])

  return (
    <div>
      {
        shows.map((show, idx) => {
          return (
            <TreeItem node={show} key={show.id}/>
          )
        })
      }
    </div>
  )
}

export default ExplorerList;