import React, {useEffect, useState, useContext} from 'react'
import {Context as MenuContext} from '../../context/MenuContext'
import justcastApi from '../../api/justcast'
import queryString from 'query-string'

export default function ShopifyBillingCallback(props) {
  const menuContext = useContext(MenuContext);

  const [loading, setLoading] = useState(true);
  const [planName, setPlanName] = useState(null);
  const [active, setActive] = useState(false)
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(null)

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    const {charge_id} = values || {};

    menuContext.setMainPageLeftMenu({title: 'Shopify', subtitle: "Connections", currentPageId: 'connections'});
    if(charge_id) {
      justcastApi.get(`/v2/shopify_application_charges/sync_shopify_charge.json?shopify_charge_id=${charge_id}`)
      .then((res) => {
        setActive(res.data.active)
        setPlanName(res.data.internal_plan_name)
        setStatus(res.data.status)
      })
      .catch((err) => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }

  }, [])

  if(loading) {
    return null;
  }

  if(error) {
    return (
      <div className="container-fluid">
        <p>Something went wrong.</p>
      </div>
    )
  }

  if(planName) {
    return (
      <div className="container-fluid">
        {active && <p>✅ Welcome to the {planName} Plan. Your subscription is now active. We're excited to have you with us and look forward to supporting your journey.</p>}
        {!active && <p>Your {planName} Plan is {status}.</p>}
      </div>
    )
  }

  return null;
}
