import React, {useEffect, useState, useContext} from 'react'
import justcastApi from '../../api/justcast'
import { Spinner } from 'reactstrap';
import queryString from 'query-string'

export default function ShopifyOauth(props) {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    const { hmac, shop } = values;
    if(hmac && shop) {
      const message = props.location.search.replace("?", "").replace(`hmac=${values["hmac"]}`, "").replace("&host", "host");
      const shop_name = shop.replace(".myshopify.com","")

      justcastApi.post("/v2/shopify_connections/auth_link.json", {
        shop_name,
        hmac,
        message
      })
      .then((res) => {
        setLoading(false)
        window.location.href = res.data.redirect_url
      })
      .catch((err) => {
        setErrorMessage(err.response.data)
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [])

  if(loading) {
    return (
      <div className="container">
        <div className="row align-items-center">
          <Spinner color="warning"/>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <p>{errorMessage}</p>
    </div>
  )
}
