import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Row, Spinner, Card} from 'reactstrap';
import BoostrapTable from './../BoostrapTable'
import {DateFormatCell, NumberFormatCell, DecodeURIFormatCell} from '../../libs/TableCellFormat'

const LinkToEpisodePage = ({cell}) => {
  return <Link to={`episodes/${cell.row.original.audiopost_id}/metrics`} style={{color: '#E9CB8F'}}>{cell.value}</Link>
}

const CardTable = (props) => {
  const {loading, stats, title, subtitle} = props;


  const columns = [
    {
      Header: 'Name',
      accessor: 'audiopost_name',
      Cell: LinkToEpisodePage
    },
    {
      Header: 'Published Date',
      accessor: 'audio_date',
      Cell: DateFormatCell
    },
    {
      Header: 'Downloads #',
      accessor: 'total_count',
      Cell: NumberFormatCell
    },
    {
      Header: 'First 3 day #',
      accessor: 'first_three_day_count',
      Cell: NumberFormatCell      
    },
    {
      Header: '4th to 15th day #',
      accessor: 'three_to_fifteen_day_count',
      Cell: NumberFormatCell      
    },
    {
      Header: 'After 15th day #',
      accessor: 'later_fifteen_day_count',
      Cell: NumberFormatCell      
    }         
  ]


  if(loading) {
		return (
			<Card>
				<div className="card-header">
					<h4 className="card-header-title mr-auto">{title}</h4>
				</div>				
				<div className="card-body"><Spinner/></div>				
			</Card>
		)
	} else {
    return (
			<Card>
				<div className="card-header">
          <div>
            <div style={{fontSize: 24, fontWeight: 'bold'}}>{title}</div>
            {
              subtitle ? <p className="text-muted mb-3">{subtitle}</p> : null
            }
          </div>
				</div>
				<div className="card-body">
          {
            stats.length > 0 ? <div className="table-responsive">
            <BoostrapTable data={stats} columns={columns}/>
            </div> : null
          }
          
        </div>				
			</Card>
    )
  }

}

export default CardTable;