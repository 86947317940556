import React, {useContext, useState} from "react";
import { Button, Tooltip } from 'reactstrap';
import { Link } from "react-router-dom";
import moment from 'moment';
import defaultImg from './../assets/img/avatars/teams/team-logo-5.jpg'
import dropboxImg from './../assets/img/avatars/teams/team-logo-5.jpg'
import dropboxIcon from './../assets/img/icons/dropbox.svg';
import onedriveIcon from './../assets/img/icons/onedrive.svg';
import {Context as ModalContext} from '../context/ModalContext'

const ShareDriveIcon = ({driveName}) => {
  if(driveName === 'dropbox') {
    return (
      <div className="avatar avatar-sm">
        <img src={dropboxIcon} width="10" className="avatar-img rounded-circle"/>
      </div>
    )
  } else if (driveName === 'onedrive') {
    return (
      <div className="avatar avatar-sm">
        <img src={onedriveIcon} width="10" className="avatar-img rounded-circle"/>
      </div>
    )
  } else {
    return null;
  }
}

const GroupCard = ({to, showId, artwork_url, name, description, refreshed_date, dropboxConnection, cloudDrivePath, dropboxFolderPath, isPrivateShow, isDirectUpload, isAdvancedDropbox, generateAssetFolderOnDropbox}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { destroyDirectPodcast } = useContext(ModalContext);

  const handleDeleteClicked = () => {
    destroyDirectPodcast({
      title: 'Are you absolutely sure?',
      name: name,
      message: "Unexpected bad things will happen if you don’t read this!",
      messages: [
        `This action cannot be undone. This will permanently delete the ${name} podcast, episodes, download statistics, artworks, audiogram and remove all collaborator associations.`,
        `Please type ${name} to confirm.`
      ],
      deleteButtonLabel: "podcast",
      endpoint: `/v1/shows/${showId}`
    })
  }

  const handleCreateAssetsFolder = () => {
    generateAssetFolderOnDropbox(showId);
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-auto">
            <Link to={to} className="avatar avatar-md">
              <img src={artwork_url ? artwork_url : defaultImg} className="avatar-img rounded"/>
            </Link>
          </div>
          <div className="col ml-n2">
            <h4 className="mb-1"><Link to={to}>{name}</Link></h4>
            {
              cloudDrivePath ? <>
                <h5 className="small mb-1"><a href={cloudDrivePath.link} target="_blank">{cloudDrivePath.label}</a></h5>
              </> : null
            }
            {
              (isAdvancedDropbox && cloudDrivePath) && (
                <div>
                  <Button id="DropboxTooltip" size="sm" color="secondary" onClick={handleCreateAssetsFolder}>Generate assets folder</Button>
                  <Tooltip placement="right" isOpen={tooltipOpen} autohide={false} target="DropboxTooltip" toggle={toggle}>
                    Add a folder to your Dropbox to handle upload images for the show.
                  </Tooltip>
                </div>
              )
            }
          </div>
          <div className="col-auto">
            {
              isDirectUpload ?
                <div className="btn btn-danger btn-sm" onClick={handleDeleteClicked}>
                  <i className="fe fe-trash-2"/> Delete
                </div> : <ShareDriveIcon driveName={cloudDrivePath.id}/>
            }
          </div>
        </div>
      </div>
      {
        isPrivateShow && <div className="card-footer card-footer-boxed">
          <div className="row align-items-center">
            <div className="col-6 col-md-auto"><span className="badge badge-primary"> Private</span></div>
          </div>
      </div>
      }
    </div>
  )
}

const ShowGroupCardList = ({items, generateAssetFolderOnDropbox}) => {
  return (
    <div className="col-12">
      {
        items.map((item) =>
          <GroupCard key={item.id.toString()}
            to={`/shows/${item.id}/overview`}
            showId={item.id}
            isAdvancedDropbox={item.advanced_dropbox}
            artwork_url={item.artwork_url_256}
            name={item.podcast_title}
            isPrivateShow={item.is_private_show}
            refreshed_date={item.refreshed_date}
            description={item.description}
            cloudDrivePath={item.cloud_drive_path}
            dropboxConnection={item.dropbox_connection}
            dropboxFolderPath={item.dropbox_path_url}
            isDirectUpload={item.is_direct_upload}
            generateAssetFolderOnDropbox={generateAssetFolderOnDropbox}
          />
        )
      }
    </div>
  )
}

export default ShowGroupCardList;