import React, {useState, useEffect, useContext} from "react";
import { Card, CardBody, Button, Col, Row, FormGroup, InputGroup, InputGroupAddon, FormFeedback, FormText} from 'reactstrap';
import { useAtom } from 'jotai';
import {
  playerAtom,
  playInSecondsAtom
} from './playerAtom'
import { soundbiteChangeAtom, soundbitesStatusMessageAtom } from './soundbitesAtom'
import {Context as ModalContext} from '../../context/ModalContext'
import {checkHHMMSS, getHHMMSSFromSeconds} from '../../libs/timeTransformation'
import ChapterInputField from './ChapterInputField'
import ChapterDelete from './ChapterDelete'

const SoundbiteInputCard = (props) => {
  const [player] = useAtom(playerAtom);
  const {playedSeconds} = player;
  const { add } = useContext(ModalContext);
  const [message] = useAtom(soundbitesStatusMessageAtom);

  const {audiopostId, title
    , idx, id, handleDelete
    , startTime, startTimeText
    , endTime, endTimeText
  } = props;  

  useEffect(() => {
    if(message) {
      add({
        color: "primary", 
        title: 'Update Soundbites',
        message, 
        isOpen: true,
        loading: false
      })
    }
  }, [message])  

  const [startTimeInputValue, setStartTimeInputValue] = useState('');
  const [endTimeInputValue, setEndTimeInputValue] = useState('');  
  const [initStartTime, setInitStartTime] = useState(true);
  const [initEndTime, setInitEndTime] = useState(true);

  const [, playInSeconds] = useAtom(playInSecondsAtom);
  const [, soundbiteChange] = useAtom(soundbiteChangeAtom)

  useEffect(() => {
    if(initStartTime) {
      if((startTimeText === undefined) && startTime >= 0) {
        const label = getHHMMSSFromSeconds(startTime)
        setStartTimeInputValue(label)
        setInitStartTime(false)
      } else if (startTimeText) {
        setStartTimeInputValue(startTimeText)
        setInitStartTime(false)
      } else {
        setStartTimeInputValue("")
        setInitStartTime(false)
      }
    }

  }, [startTimeText, startTime])

  useEffect(() => {
    if(initEndTime) {
      if((endTimeText === undefined) && endTime >= 0) {
        const label = getHHMMSSFromSeconds(endTime)
        setEndTimeInputValue(label)
        setInitEndTime(false)
      } else if (endTimeText) {          
        setEndTimeInputValue(endTimeText)
        setInitEndTime(false)
      } else {
        setEndTimeInputValue("")
        setInitEndTime(false)
      }
    }
  }, [endTimeText, endTime])  
  // const [__, changeChapter] = useAtom(changeChapterValueAtom);
  // const [, removeChapter] = useAtom(removeChapterAtom)

  const handleCopyPlayedSecondsToStart = () => {
    const label = getHHMMSSFromSeconds(playedSeconds);    
    setStartTimeInputValue(label)
    soundbiteChange({idx, name: 'startTimeText', value: label})
  }

  const handleCopyPlayedSecondsToEnd = () => {
    const label = getHHMMSSFromSeconds(playedSeconds);    
    setEndTimeInputValue(label)
    soundbiteChange({idx, name: 'endTimeText', value: label})
  }  

  const handlePlay = () => {
    playInSeconds(startTime)
  }

  const handleStartTimeInputChange = (data) => {
    const label = data.value;
    soundbiteChange(data)
    setStartTimeInputValue(label)
  }

  const handleEndTimeInputChange = (data) => {
    const label = data.value;
    soundbiteChange(data)
    setEndTimeInputValue(label)
  }  

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Button onClick={handleCopyPlayedSecondsToStart} color="primary">Use playing time</Button>
                </InputGroupAddon>
                <ChapterInputField
                  invalid={checkHHMMSS(startTimeInputValue) ? false : true} 
                  name="startTimeText" 
                  type="text"
                  placeholder="Start time (hh:mm:ss)" 
                  value={startTimeInputValue} 
                  category="soundbite"
                  handleOnChange={handleStartTimeInputChange}                  
                  id={id} 
                  idx={idx}/>
                <InputGroupAddon addonType="append">
                  <Button 
                    disabled={startTimeInputValue && startTimeInputValue.length > 1 ? false : true}
                    onClick={handlePlay} 
                    color="warning"><i className="fe fe-play"/></Button>                  
                </InputGroupAddon>
                <FormFeedback>Time can't be empty, and please make sure it follows HH:MM:SS</FormFeedback>
              </InputGroup>
            </FormGroup>   
          </Col>
          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Button onClick={handleCopyPlayedSecondsToEnd} color="primary">Use playing time</Button>                 
                </InputGroupAddon>
                <ChapterInputField
                  invalid={checkHHMMSS(endTimeInputValue) ? false : true} 
                  name="endTimeText" 
                  type="text"
                  placeholder="End time (hh:mm:ss)" 
                  value={endTimeInputValue} 
                  category="soundbite"
                  handleOnChange={handleEndTimeInputChange}                  
                  id={id} 
                  idx={idx}/>
                <InputGroupAddon addonType="append">
                  <ChapterDelete id={id} idx={idx} audiopostId={audiopostId} handleDelete={handleDelete}/>
                </InputGroupAddon>
                <FormFeedback>Time can't be empty, and please make sure it follows HH:MM:SS</FormFeedback>
              </InputGroup>
            </FormGroup>             
          </Col> 
        </Row>
        <Row>
          <Col md={12}>
            <ChapterInputField 
              name="title" 
              type="text" 
              placeholder="Enter soundbite title (optional)" 
              value={title} 
              id={id} 
              idx={idx}
              category="soundbite"
              handleOnChange={soundbiteChange}
            />            
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default SoundbiteInputCard;