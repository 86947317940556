import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {useDropzone} from 'react-dropzone'
// import {uploadHandler} from '../api/doUploader'

const Thumb = ({file, removeFile, setImage}) => {
  return (
    <li className="list-group-item px-0">
      <div className="row align-items-center">
        <div className="col-auto">
          <div className="avatar">
            <img
                className="avatar-img rounded"
                src={URL.createObjectURL(file)}
              />
          </div>
        </div>
        <div className="col ml-n3">
          <h4 className="mb-1">{file.path}</h4>
          {/* <p className="small text-muted mb-0">...</p> */}
        </div>
        <div className="col-auto">
          <div onClick={() => {
            removeFile([])
            setImage(null);
          }}><i className="fe fe-more-fe fe-x"></i></div>
        </div>
      </div>
    </li>
  )
}

const Thumbs = ({files, removeFile, setImage}) => {
  return (
    <div className="col-12 col-md-6">
      <div className="card bg-light border">
        <div className="card-body">
          <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
            {
              files.map(file => 
                <Thumb file={file} removeFile={removeFile} setImage={setImage} key={file.path}/>
              )
            }
          </ul>
        </div>
      </div>      
    </div>   
  )
}

const ImageUploaderErrors = ({errors}) => {
  return (
    <div className="col-12 col-md-6">
      <div className="card bg-light border">
        <div className="card-body">        
          <h4 className="mb-2">
            <i className="fe fe-alert-triangle"></i> Errors
          </h4>
          <ul>
            {
              errors.map((err) => 
                <li key={err}>{err}</li>
              )
            }
          </ul>
        </div>
      </div>    
    </div>
  ) 
}

const WarningMessage = ({text, files, errors, removeFile, setImage}) => {
  if(errors.length > 0) {
    return <ImageUploaderErrors errors={errors}/>
  } else if(files.length > 0) {
    return (
      <Thumbs files={files} removeFile={removeFile} setImage={setImage}/>
    )   
  } else if(text) {
    return (
      <div className="col-12 col-md-6">
        <div className="card bg-light border">
          <div className="card-body">        
            <h4 className="mb-2">
              <i className="fe fe-alert-triangle"></i> Warning
            </h4>
            <p className="small text-muted mb-0">
              {text}
            </p>
          </div>
        </div>    
      </div>
    )    
  }
  return null;
}


//https://github.com/react-dropzone/react-dropzone/issues/321
const imageOnDrop = (acceptedFiles, setImage, setFiles, setErrors) => {
  //TODO: replace setFiles with setArtwork
  // exactly
  const errors = [];
  const img = new Image();
  img.onload = function(){
    if(img.width < 1) {
      errors.push(`This image must be more than 1 pixels wide.`);
    }
    if(img.height < 1) {
      errors.push(`This image must be more than 1 pixels height.`);
    }
    
    if(errors.length > 0) {
      // error hanlding...
      setErrors(errors)
      setFiles([]);
      //  setArtwork(null);
    } else {
      setErrors([])
      setFiles(acceptedFiles)
      setImage(acceptedFiles[0])
      //handleUpload(acceptedFiles[0]);
    }
  }
  
  img.src = URL.createObjectURL(acceptedFiles[0])  
  

  //setFiles(acceptedFiles)
}

const EpisodeImageUpload = ({handleUpload, audiopostId}) => {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  const [image, setImage] = useState(null);
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');

  const {acceptedFiles, rejectedFiles, getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: (acceptedFiles, rejectedFiles) => {
      if(rejectedFiles.length > 0) {
        
        const errors = ['Only *.jpeg and *.png images will be accepted'];
        rejectedFiles.forEach((file) => {
          errors.push(`Rejected file: ${file.path}`)
        })
        setFiles([]);
        setImage(null);
        setErrors(errors)
      }
      if(acceptedFiles.length > 0) {
        imageOnDrop(acceptedFiles, setImage, setFiles, setErrors)
      }      
    }
  });

  return (
    <>      
      <div className="row">
        <WarningMessage 
          text={`Please make sure your image size is more than 1px by 1px. (Only *.jpeg and *.png images will be accepted)`}
          files={files}
          setImage={setImage}
          removeFile={setFiles}
          errors={errors}/>
        <div className="col-12 col-md-6">        
          <div {...getRootProps({className: 'dropzone'})}>
            <div className="dz-default dz-message">
              <input {...getInputProps()}/>
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </div>        
        </div>
      </div>
      <hr className="mt-5 mb-5"/>
      <Form>
        <FormGroup row>
          <Label for="subtitle" sm={2}>Subtitle</Label>
          <Col sm={10}>
            <Input 
              type="subtitle" 
              name="subtitle" 
              id="subtitle" 
              placeholder="subtitle..." 
              value={subtitle}
              onChange={(e) => {
                setSubtitle(e.target.value)
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="description" sm={2}>Description</Label>
          <Col sm={10}>
            <Input 
              type="textarea" 
              name="description" 
              id="description" 
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }} 
            />
          </Col>
        </FormGroup>        
      </Form>
      <hr className="mt-5 mb-5"/>
      <div className="row" style={{marginBottom: 40}}>      
        <div className="col-12">
          <Button 
            color="primary"
            disabled={image === null ? true : false}
            onClick={() => {
              if(image) {
                handleUpload({image: image, subtitle, description})
              }            
            }}
          >
            Save
          </Button>{' '}
          <Link className="btn btn-secondary" to={`/episodes/${audiopostId}/images`}>Cancel this change</Link>
        </div>        
      </div>      
    </>
  );
}

export default EpisodeImageUpload