import React from "react";
import 'flatpickr/dist/themes/dark.css'
import Flatpickr from 'react-flatpickr'
import {Form} from 'reactstrap';

const DatePickerRange = (props) => {
  const {fromDate, setFromDate, toDate, setToDate, display} = props;

  if(display) {
    return (
      <Form>
        <div className="form-row">
          <div className="col">
            <div className="form-group">
              <label>Start date</label>
              <Flatpickr 
                className='form-control mb-3'
                value={fromDate} 
                onChange={setFromDate}/>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>End date</label>
              <Flatpickr 
                className='form-control mb-3'
                value={toDate} 
                onChange={setToDate}/>
            </div>
          </div>            
        </div>
      </Form>       
    )
  }

  return null;
}

export default DatePickerRange;