import React from "react";
import { Card, Form, FormGroup, Input, CustomInput, InputGroup,InputGroupAddon, InputGroupText,Button, Row, Col} from 'reactstrap';
import CardBody from "reactstrap/lib/CardBody";

const ValueRecipient = (props) => {
  const {id, name, value_type, 
    address, fee, split, idx, 
    custom_key, custom_value,
    handleChange, handleDelete} = props;

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label>Name (required)</label>
              <InputGroup>                
                <Input
                  type={"text"}
                  name={"name"}
                  id={"recipient-name"}
                  placeholder="A free-form string that designates who or what this recipient is."
                  value={name ? name : ""}
                  onChange={(e) => {
                    handleChange({idx, name: e.target.name, value: e.target.value})
                  }}
                />
                <InputGroupAddon addonType="append">
                  <Button color="danger" onClick={() =>{
                    handleDelete(idx)
                  }}><i className="fe fe-trash-2"/></Button>
                </InputGroupAddon>                
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label>Type (required)</label>
              <Input 
                type="text" 
                className="form-control" 
                value={value_type ? value_type : ""} 
                name="value_type"
                placeholder="A slug that represents the type of receiving address that will receive the payment." 
                onChange={(e) => {
                  handleChange({idx, name: e.target.name, value: e.target.value})
                }}
                />              
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label>Address (required)</label>
              <Input 
                type="text" 
                className="form-control" 
                value={address ? address : ""} 
                name="address"
                placeholder="This denotes the receiving address of the payee." 
                onChange={(e) => {
                  handleChange({idx, name: e.target.name, value: e.target.value})
                }}
                />              
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label>Split (required)</label>
              <Input 
                type="number" 
                className="form-control" 
                value={split ? split : ""} 
                name="split"
                placeholder="The number of shares of the payment this recipient will receive." 
                onChange={(e) => {
                  handleChange({idx, name: e.target.name, value: e.target.value})
                }}
                />              
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label>Fee (optional)</label>
              <CustomInput 
                type="switch"
                id={`switch-fee-${idx+1}`}
                onChange={() => {
                  handleChange({idx, name: 'fee', value: !fee})
                }}
                name="switch-fee"
                checked={fee}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label>Custom key</label>
              <Input 
                type="text" 
                className="form-control" 
                value={custom_key ? custom_key : ""} 
                name="custom_key"
                placeholder="The name of a custom record key to send along with the payment." 
                onChange={(e) => {
                  handleChange({idx, name: e.target.name, value: e.target.value})
                }}
                />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label>Custom value</label>
              <Input 
                type="text" 
                className="form-control" 
                value={custom_value ? custom_value : ""} 
                name="custom_value"
                placeholder="A custom value to pass along with the payment. This is considered the value that belongs to the customKey."
                onChange={(e) => {
                  handleChange({idx, name: e.target.name, value: e.target.value})
                }}
                />
            </FormGroup>
          </Col>          
        </Row>
      </CardBody>
    </Card>
  )
}

export default ValueRecipient;