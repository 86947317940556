import apple_podcasts_icon from './../assets/img/podcasts/apple_podcasts.svg'
import spotify_podcasts_icon from './../assets/img/podcasts/spotify_podcasts.svg'
import google_podcasts_icon from './../assets/img/podcasts/google_podcasts.svg'
import pocketcasts_podcasts_icon from './../assets/img/podcasts/pocketcasts_podcasts.svg'
import stitcher_podcasts_icon from './../assets/img/podcasts/stitcher_podcasts.svg'
import tunein_podcasts_icon from './../assets/img/podcasts/tunein_podcasts.jpg'
import amazon_music_icon from './../assets/img/podcasts/amazon_music.svg'
import rss_icon from './../assets/img/podcasts/rss.svg'
import podcast_index_icon from './../assets/img/podcasts/podcast_index.svg'

const distributionList = [
  {
    id: 'podcast_index',
    title: 'Podcast Index',
    networkIcon: podcast_index_icon,
    msBaseEndpoint: `/v1/shows`,
    msEndpoint: `create_podcast_index`
  },
  {
    id: 'apple',
    title: 'Apple Podcasts',
    networkIcon: apple_podcasts_icon,
    lists: [
      {
        title: 'Requirements',
        lists: [
          `<a href="https://support.apple.com/en-us/HT204316" target="_blank">An Apple ID account.</a>`,
          "At least one published episode.",
          "Attractive, high quality podcast artwork.",
          "An accurate title, summary so that listeners can find your podcast.",
          "Podcast category selected.",
          "Mark any explicit episodes (or the entire podcast) as so."          
        ]
      },
      {
        title: 'How to submit to Apple Podcasts',
        lists: [
          `Click <a href="https://podcastsconnect.apple.com/my-podcasts/new-feed?submitfeed=_RSS_FEED_" target="_blank">Submit to Apple Podcasts</a> (Apple's podcast portal for podcasters).`,
          `Log in with an existing Apple ID or <a href="https://support.apple.com/en-us/HT204316" target="_blank">create one</a>.`,
          `Enter your RSS feed URL: <a href="_RSS_FEED_" target="_blank">_RSS_FEED_</a>`,
          `Click validate.`,
          `Click submit.`
        ]
      }     
    ],
    paragraphs: [
      `Apple manually reviews each podcast submission.  Depending on their backlog, it make take up to a week before your podcast is added (~ 3 days is typical).  
      Once your podcast is approved, there is no need to re-submit.  All new episodes published in JustCast will be distributed automatically.`,
    ],
    button: {
      label: 'Submit to Apple Podcasts',
      url: 'https://podcastsconnect.apple.com/my-podcasts/new-feed?submitfeed=_RSS_FEED_'
    }
  },
  {
    id: 'amazon_music',
    title: 'Amazon Music',
    networkIcon: amazon_music_icon,
    lists: [
      {
        title: 'Requirements',
        lists: [
          "At least one published episode.",
          "Attractive, high quality podcast artwork.",
          "An accurate title, summary so that listeners can find your podcast.",
          "Podcast category selected.",
          "Mark any explicit episodes (or the entire podcast) as so."
        ]
      },
      {
        title: 'How to submit to Amazon Music',
        lists: [
          `Click <a href="https://www.amazon.com/music/lp/podcasts" target="_blank">Get started</a>.`,
          `Scroll down and look for "Have a podcast? Submit it to Amazon Music here."  Click on Here`,
          `SELECT "Next" AT THE BOTTOM OF THE PAGE WHEN YOU'RE READY TO START`,
          `From the drop down box to select 1 to submit your podcast, click "Next"`,
          `Enter name of your podcast, and RSS feed URL: <a href="_RSS_FEED_" target="_blank">_RSS_FEED_</a>`,
          `Enter your name, and your email address.`,
          `On the "Who is your podcast Hosting Provider?" Select "Other, please specify" and enter JustCast`,
          `Pick the country is your podcast primarily for`,
          `Click Yes, I agree after you have read and agree on it's Content License Agreement.`,
          `Click "Next"`
        ]
      }     
    ],
    paragraphs: [
      `Amazon Music manually reviews each podcast submission.  Depending on their backlog, it make take up to a week before your podcast is added (~ 3 days is typical).  
      Once your podcast is approved, there is no need to re-submit.  All new episodes published in JustCast will be distributed automatically.`,
    ],
    button: {
      label: 'Submit to Amazon Music',
      url: 'https://www.amazon.com/music/lp/podcasts'
    }
  },  
  {
    id: 'spotify',
    title: 'Spotify',
    networkIcon: spotify_podcasts_icon,
    lists: [
      {
        title: 'Requirements',
        lists: [
          "At least one published episode.",
          "Attractive, high quality podcast artwork.",
          "An accurate title, summary so that listeners can find your podcast.",
        ]
      },
      {
        title: 'How to submit to Spotify',
        lists: [
          `Clikc <a href="https://accounts.spotify.com/login?continue=https%3A%2F%2Fpodcasters.spotify.com%2Fgateway" target="_blank">Get started</a>`,
          `Paste in the link to your podcast's RSS feed: <a href="_RSS_FEED_">_RSS_FEED_</a>`,
          `Add podcast info like the category, language, and country.`,
          `Review the details and submit.`
        ]
      }                  
    ],
    paragraphs: [
      `Spotify manually reviews each podcast submission.  
      Depending on their backlog, it make take up to a week before your podcast is added (~ 3 days is typical).  
      Once your podcast is approved, there is no need to re-submit.  All new episodes published in JustCast will be distributed automatically.`,
    ],
    button: {
      label: 'Submit to Spotify',
      url: 'https://accounts.spotify.com/login?continue=https%3A%2F%2Fpodcasters.spotify.com%2Fgateway'
    }
  },
  {
    id: 'google',
    title: 'Google Podcasts',
    networkIcon: google_podcasts_icon,
    lists: [
      {
        title: 'Requirements',
        lists: [
          "At least one published episode.",
          "Attractive, high quality podcast artwork.",
          "An accurate title, summary so that listeners can find your podcast.",
        ]
      },
      {
        title: 'How to submit to Google Podcasts',
        lists: [
          `Click <a href="https://podcastsmanager.google.com/about?hl=en" target="_blank">Submit to Google Podcasts Manager</a>.`,
          `Log in with an existing Google ID or create one.`,
          `Click the “Start now” Button`,
          // `Review Google's Terms of service, check I accept the Terms of service. Click on "I Accept" button`,
          `Enter your RSS feed URL: <a href="_RSS_FEED_" target="_blank">_RSS_FEED_</a>`,
          `Click Next Step`,
          `Preview the feed.  Check to make sure the information is correct.`,
          `Click Next Step`,
          `Click Send code`,
          `Verify ownership`
        ]
      }                       
    ],
    paragraphs: [
      `Google manually reviews each podcast submission.  Depending on their backlog, it make take up to a week before your podcast is added (~ 3 days is typical).  
      Once your podcast is approved, there is no need to re-submit.  All new episodes published in JustCast will be distributed automatically.`,
    ],
    button: {
      label: 'Submit to Google Podcasts',
      url: 'https://play.google.com/music/podcasts/portal'
    }
  },
  {
    id: 'pocketcasts',
    title: 'Pocket Casts',
    networkIcon: pocketcasts_podcasts_icon,
    lists: [
      {
        title: 'Requirements',
        lists: [
          "At least one published episode.",
          "Attractive, high quality podcast artwork.",
          "An accurate title, summary so that listeners can find your podcast.",
        ]
      },
      {
        title: 'How to submit to Pocket Casts',
        lists: [
          `Click <a href="https://www.pocketcasts.com/submit/" target="_blank">Submit to Pocket Casts</a>.`,
          `Enter your RSS feed URL: <a href="_RSS_FEED_" target="_blank">_RSS_FEED_</a>`,
          `Click on Public`,
          `Click Submit`
        ]
      }                                  
    ],
    paragraphs: [
      `If you have already submitted your podcasts to Apple podcasts, your podcast should automatically be distributed to Pocket Casts.`,
    ],
    button: {
      label: 'Submit to Pocket Casts',
      url: 'https://www.pocketcasts.com/submit/'
    }     
  },
  {
    id: 'stitcher',
    title: 'Stitcher',
    networkIcon: stitcher_podcasts_icon,
    lists: [
      {
        title: 'Requirements',
        lists: [
          "At least one published episode.",
          "Attractive, high quality podcast artwork.",
          "An accurate title, summary so that listeners can find your podcast.",
          `<a href="https://www.stitcher.com/content-providers.php">Register as a Stitcher content provider.</a>`,
        ]
      },
      {
        title: 'How to submit to Stitcher',
        lists: [
          `<a href="https://www.stitcher.com/content-providers.php">Register as a Stitcher content provider.</a>`,
          `Provide your RSS feed URL (<a href="_RSS_FEED_" target="_blank">_RSS_FEED_</a>)`,
          'Agree to the terms and conditions and wait for Stitcher to review your show.'
        ]
      }
    ],
    button: {
      label: 'Submit to Stitcher',
      url: 'https://www.stitcher.com/content-providers.php'
    }
  },
  {
    id: 'tunrssein',
    title: 'TuneIn',
    networkIcon: tunein_podcasts_icon,
    lists: [
      {
        title: 'Requirements',
        lists: [
          "At least one published episode.",
          "Attractive, high quality podcast artwork.",
          "An accurate title, summary so that listeners can find your podcast.",          
        ]
      },
      {
        title: 'How to submit to TuneIn',
        lists: [
          `To add your podcast, please submit your Podcast details <a href="https://help.tunein.com/contact/add-podcast-S19TR3Sdf">here</a>.`,
          `Provide your RSS feed URL (<a href="_RSS_FEED_" target="_blank">_RSS_FEED_</a>)`,
          'Agree to the terms and conditions and wait for TuneIn to review your show.'
        ]
      }
    ],
    button: {
      label: 'Submit to TuneIn',
      url: 'https://help.tunein.com/contact/add-podcast-S19TR3Sdf'
    }
  },
  {
    id: 'rss',
    title: 'RSS feed',
    networkIcon: rss_icon,
    lists: [
      {
        title: 'Requirements',
        lists: [
          "At least one published episode.",
          "Attractive, high quality podcast artwork."
        ]
      },
      {
        title: 'How to use your RSS feed (Podcast URL)',
        lists: [
          `All podcast directories and applications accept your RSS feed.  Depending on the directory, the submission process may vary.`,
          `Provide your RSS feed URL (<a href="_RSS_FEED_" target="_blank">_RSS_FEED_</a>)`
        ]
      }
    ],
    paragraphs: [
      `<strong>Note:</strong> Most directories use the Apple Podcasts directory as their data source so you may not need to publish everywhere.`
    ]
  }  
]

export default distributionList