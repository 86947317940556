class TimeString {
	constructor() {}

	getSecond(str) {
    // check if we have the right format
    // https://eloquentjavascript.net/09_regexp.html
    if (/\d{2}:\d{2}:\d{2}/.test(str)) {
      const a = str.split(':'); // split it at the colons
      return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    }
		return null;
	}

	getTimeString(sec) {	
    let second = Math.floor(sec);
		let hours = (second - second % 3600) / 3600;
		let minutes = ((second -  hours * 3600) - (second -  hours * 3600) % 60) / 60;
		let seconds = second - hours * 3600 - minutes * 60;		
		if (hours   < 10) {hours   = "0"+hours;}
		if (minutes < 10) {minutes = "0"+minutes;}
		if (seconds < 10) {seconds = "0"+seconds;}
		return hours+':'+minutes+':'+seconds;	
	}
}

export default TimeString;