import React, { useContext} from "react";
import { Button } from 'reactstrap';
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import TimeString from '../../libs/TimeString'

const timeStringHandler = new TimeString();

const AudioPlayerButton = ({handlePlayPause}) => {
  const {state} = useContext(PodieoAudioContext);
  const styleText = {
    fontSize: "12px"
  }

  return (
    <div style={{
      display:"flex", 
      flexDirection: "column", 
      alignItems: "center",
      height: "80px",
      width: "120px",
      marginRight: "20px"
    }}>
      <Button color="primary" className="btn btn-rounded-circle" onClick={handlePlayPause}>
        <span className={state.playing ? "fe fe-pause" : "fe fe-play"}></span>
      </Button>
      <div>
        <span style={styleText}>{timeStringHandler.getTimeString(state.playedSeconds)}</span> 
        <span style={{...styleText, marginLeft: "5px", marginRight: "5px"}}>/</span>
        <span style={styleText}>{timeStringHandler.getTimeString(state.duration)}</span>
      </div>
    </div>
  )
}

export default AudioPlayerButton;