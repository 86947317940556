import React, {useContext, useState, useEffect, useRef} from "react";
import { Link, Redirect } from "react-router-dom";
import { Card, CardBody, FormGroup, Button, CustomInput, Row, Alert, Input, CardHeader, Label, Col } from 'reactstrap';
import justcastApi from '../api/justcast';
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as MenuContext} from '../context/MenuContext'
import {CustomEmailText} from '../components/private-podcast'
import PodcastSenderCard from '../components/sendgrid-senders/PodcastSenderCard'
import PodcastSenderForm from "../components/sendgrid-senders/PodcastSenderForm"
import PodcastSenderToast from '../components/sendgrid-senders/PodcastSenderToast'

const InvitationEmail = (props) => {
  const quillRef = useRef(null)
  const showId = props.match.params.show_id;
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const {addWithTimeout} = useContext(AlertContext);

  const [show, setShow] = useState({});
  const [instructionEmail, setInstructionEmail] = useState(null);
  const [denied, setDenied] = useState(false);
  const [bodyText, setBodyText] = useState("");
  const [subjectText, setSubjectText] = useState("");
  const [isActivated, setIsActivated] = useState(false);
  const [isExcludingLinks, setIsExcludingLink] = useState(false);
  const [connectedSender, setConnectedSender] = useState(null)
  const [verifiedSenders, setVerifiedSenders] = useState([])
  const [toastTitle, setToastTitle] = useState(null)
  const [toastMessage, setToastMessage] = useState(null)
  const [toastOpen, setTotastOpen] = useState(false);
  const [defaultSender, setDefaultSender] = useState(null);

  const setupPayload = () => {
    const message = quillRef?.current?.unprivilegedEditor?.getHTML();
    let data = {
      "subject_text": subjectText,
      "body_text": message,
      "is_activated": isActivated,
      "without_default_link": isExcludingLinks
    };
    if(instructionEmail) {
      data = {...instructionEmail, ...data};
    }
    return data;
  }

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v2/shows/${showId}`)
    .then((res) => {
      setShow(res.data);
      setInstructionEmail(res.data.instruction_email)
      if(res.data.instruction_email?.id) {
        const { body_text, subject_text, is_activated, without_default_link } = res.data.instruction_email || {}
        setBodyText(body_text);
        setSubjectText(subject_text)
        setIsActivated(is_activated)
        setIsExcludingLink(without_default_link)
      }
      if(res.data.connected_sender_domain) {
        setConnectedSender(res.data.connected_sender_domain)
        const {from_email, id} = res.data.connected_sender_domain;
        setDefaultSender({
          id,
          value: id,
          label: from_email
        })
      }
      if(res.data.verified_sender_domains) {
        setVerifiedSenders(res.data.verified_sender_domains);
      }
      screenContext.finishFetching();

      menuContext.setPrivateSubscribersrMenu({
        title: 'Settings',
        subtitle: res.data.name,
        currentPageId: 'subscribers',
        currentTabId: 'invitation-email',
        showId: showId,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true)
    })
  }, [showId])

  const handleSave = () => {
    const data = setupPayload();
    setBodyText(data.body_text);
    justcastApi.post(`/v1/shows/${showId}/instruction_email`, data)
    .then((res) => {
      addWithTimeout({color: "warning", message: "Customize the instructions email saved", isOpen: true, timeout: 2000})
      setBodyText(res.data.body_text);
      setInstructionEmail(res.data.instruction_email)
    })
    .catch((err) => {
      addWithTimeout({color: "warning", message: `Failed to save. ${err.response.data.error}`, isOpen: true, timeout: 5000})
      console.log(err)
    })
  }

  const handlePreviewEmail = () => {
    const data = setupPayload();
    setBodyText(data.body_text);
    justcastApi.post(`/v1/shows/${showId}/instruction_email/preview_email`, data)
    .then((res) => {
      addWithTimeout({color: "warning", message: res.data.message, isOpen: true, timeout: 2000})
    })
    .catch((err) => {
      addWithTimeout({color: "warning", message: `Failed to save. ${err.response.data.error}`, isOpen: true, timeout: 5000})
    })
  }

  const handleSaveSuccessCallback = (data) => {
    setConnectedSender(data.sender_domain)
    setTotastOpen(true);
    setToastTitle(data.title)
    setToastMessage(data.message)
    setDefaultSender({
      id: data.sender_domain.id,
      value: data.sender_domain.id,
      label: data.sender_domain.from_email
    })
  }

  const handleSaveSenderId = (data) => {
    const sender_domain_id = data?.sender_email?.id
    const senderId = connectedSender?.id;
    // if connectedSender exist, then update it
    // if connectedSender is empty, then create a new one
    if(sender_domain_id) {
      justcastApi.post(`/v2/shows/${showId}/sender_domains`, {
        sender_domain_id
      })
      .then((res) => {
        handleSaveSuccessCallback(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }

  const handleRemoveSenderId = () => {
    justcastApi.delete(`/v2/shows/${showId}/sender_domains`)
    .then((res) => {
      setConnectedSender(null);
      setTotastOpen(true);
      setToastTitle(res.data.title)
      setToastMessage(res.data.message)
      setDefaultSender(null)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const toggleTotast = () => {
    setTotastOpen(!toastOpen);
  }

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <div className="container-fluid">
      <PodcastSenderToast
        toggle={toggleTotast}
        show={toastOpen}
        title={toastTitle}
        message={toastMessage}
      />
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader>
              Customize the instructions email
            </CardHeader>
            <CardBody>
              <small className="text-muted">
                You can preview what the email will look like by clicking Send Preview. It will be sent to your account's email address.
              </small>
              <hr/>
              <FormGroup>
                <label>Subject</label>
                <Input
                  type="text"
                  name="subject"
                  className="subject"
                  value={subjectText}
                  placeholder="You have subscribed!"
                  onChange={(event) => {
                    setSubjectText(event.target.value)
                  }}/>
              </FormGroup>
              <label>Message</label>
              <CustomEmailText
                quillRef={quillRef}
                bodyText={bodyText}
                onChange={setBodyText}
              />
              <Row form style={{marginTop: "20px"}}>
                <Col xs={12} md={6}>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="check"
                      id="is_activated"
                      onChange={() => {
                        setIsActivated(!isActivated)
                      }}
                      checked={isActivated}/>
                    <Label for="is_activated" check>Go Live</Label>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="check"
                      id="without_default_link"
                      onChange={() => {
                        setIsExcludingLink(!isExcludingLinks)
                      }}
                      checked={isExcludingLinks}/>
                    <Label for="without_default_link" check>Exclude default links</Label>
                  </FormGroup>
                </Col>
              </Row>
              <hr/>
              <div style={{display: "flex", columnGap: "10px", justifyContent: "flex-end"}}>
                <Button color="warning" onClick={handlePreviewEmail}>Send Preview</Button>
                <Button color="primary" onClick={handleSave}>Save</Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <PodcastSenderCard
            verifiedSenders={verifiedSenders}
          >
            <PodcastSenderForm
              handleRemoveSenderId={handleRemoveSenderId}
              handleSave={handleSaveSenderId}
              defaultSender={defaultSender}
              verifiedSenders={verifiedSenders}
            />
          </PodcastSenderCard>
        </div>
      </div>
    </div>
  )
}

export default InvitationEmail;