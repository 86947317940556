import React from "react";
import QRCode from "react-qr-code";

const QrCodeComponent = ({link}) => {

  if(link) {
    return (
      <div style={{display: "flex", justifyContent: "center"}}>
        <QRCode value={link} size={200}/>
      </div>
    )    
  }

  return null;
}

export default QrCodeComponent;