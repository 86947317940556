import React from 'react'

const Messages = ({title, messages}) => {
  return (    
    <div className="card bg-light border">
      <div className="card-body">        
        <h4 className="mb-2">
          <i className="fe fe-alert-triangle"></i> {title}
        </h4>
        <ul>
          {
            messages.map((msg) => 
              <li key={msg}>{msg}</li>
            )
          }
        </ul>
      </div>
    </div>        
  ) 
}

export default Messages