import React from 'react'
import { Button, Modal, ModalBody, ModalHeader, ButtonGroup } from 'reactstrap';

export default function NeedToConvertModal({modal, toggle, handleChooseConvert}) {
  return (
    <Modal isOpen={modal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Convert audio file</ModalHeader>
      <ModalBody>
        <p>This audio file is not a mp3. Spotify requires all audio to be in ISO/IEC 11172-3 MPEG-1 Part 3 (MP3) audio with bitrates between 96 and 320 kbps. In other words, an audio mp3 file with good quality audio.</p>
        <p>Would you like us to convert your into a mp3 with 128 kbps?</p>
        <ButtonGroup>
          <Button color="primary" onClick={() => handleChooseConvert(true)}>Yes</Button>
          <Button color="secondary" onClick={() => handleChooseConvert(false)}>No</Button>
        </ButtonGroup>
      </ModalBody>
    </Modal>
  )
}
