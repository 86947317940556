import React from 'react'
import { Button } from 'reactstrap'

export default function TranscriptButtonGroup({href, handleRemove}) {
  return (
    <>
      <Button size="sm" color="secondary" onClick={handleRemove}>Remove</Button> {" "}
      <a href={href} className='btn btn-primary btn-sm' target="_blank">Download</a>
    </>
  )
}