import React, {useState, useEffect} from "react";
import PodieoListItem from './PodieoListItem';

const PodieoList = ({items, name, handleDeleteClick}) => {

  return (
    <ul className="list-group list-group-lg list-group-flush list my-n4">
      {
        items.map(item => {
          return (
            <PodieoListItem 
              key={item.id} 
              width={item.width} 
              height={item.height}
              duration={item.duration}
              id={item.id}
              name={name}
              message={item.message}
              progress={item.progress}
              createdAt={item.created_at}
              remoteUrl={item.remote_url}
              formatType={item.format_type}
              handleDeleteClick={handleDeleteClick}
            />
          )
        })
      }
    </ul>
  )
}

export default PodieoList;