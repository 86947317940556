import React, {useEffect, useState, useContext } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Card, CardBody, CardHeader } from 'reactstrap';
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import PanelProjectTab from './PanelProjectTab'
import PanelTextTab from './PanelTextTab'
import PanelImageTab from './PanelImageTab'
import PanelElementsTab from './PanelElementsTab'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.isSelected ? 'white' : 'blue',
  })
}

const LeftSidePanel = () => {  
  const {state} = useContext(PodieoAudioContext);

  return (
    <Card style={{"minHeight": "460px"}}>
      <CardHeader>
        <h4 className="card-header-title mr-auto">{state.buttonSelectedTitle}</h4>
      </CardHeader>
      <CardBody>
        {state.buttonSelectedId === 'project' ? <PanelProjectTab/> : null}
        {state.buttonSelectedId === 'texts' ? <PanelTextTab/> : null}
        {state.buttonSelectedId === 'images' ? <PanelImageTab/> : null}
        {state.buttonSelectedId === 'elements' ? <PanelElementsTab/> : null}
      </CardBody>
    </Card>
  )
}

export default LeftSidePanel;