import React, {useState, useContext, useEffect}  from 'react';
import {Context as MenuContext} from '../../context/MenuContext'
import {Context as ModalContext} from '../../context/ModalContext'
import TopMenuTitle from './TopMenuTitle'
import TopMenuTab from './TopMenuTab'
import UserMenu from './UserMenu'
import ModalButton from './ModalButton'

const TopMenu = () => {
  const [isReady, setIsReady] = useState(false)
  const {state} = useContext(MenuContext);
  const {addWithInputs} = useContext(ModalContext);
  const {title, subtitle, tabs, currentTabId, advancedPrivate, isPrivateShow, rssFeed,  websiteUrl, landingPageUrl, skill_podcast_website, passwordProtected} = state;

  useEffect(() => {
    if(title) {
      setIsReady(true)
    }
  }, [title])

  const handleRSSFeedClick = () => {
    addWithInputs({
      title: "Podcast RSS Feed URL",
      isOpen: true,
      message: "You'll need your RSS Feed URL to submit to Apple Podcasts, Google Podcast, Pocket Casts, etc.",
      inputs: [
        {name: "RSS feed", url: rssFeed, id: 'rss-feed-modal', qrLink: rssFeed}
      ]
    })
  }

  const handleWebsiteClick = () => {
    const inputs = [{name: "Social Media Landing Page", url: landingPageUrl, id: 'landing-page-modal'}]
    if(isPrivateShow === false) {
      inputs.push({name: "JustCast Website", url: websiteUrl, id: 'website-page-modal'});
    }
    addWithInputs({
      title: "Podcast Website URLs",
      isOpen: true,
      inputs
    })
  }

  if (isReady) {
    return (
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-center">
              <TopMenuTitle title={title} subtitle={subtitle}/>
              <div className="col-auto">
                <ModalButton
                  name="RSS Feed"
                  className="btn btn-warning"
                  visiable={!advancedPrivate}
                  handleClick={handleRSSFeedClick}
                />
                <ModalButton
                  name="Podcast Website"
                  className="btn btn-primary"
                  visiable={(!isPrivateShow) || (isPrivateShow === true && !advancedPrivate && !passwordProtected)}
                  handleClick={handleWebsiteClick}
                />
                <UserMenu/>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col">
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  {
                    tabs.map((navItem) =>
                      <TopMenuTab
                        key={navItem.id}
                        name={navItem.name}
                        link={navItem.link}
                        active={navItem.id === currentTabId}
                      />
                    )
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return null;
}

export default TopMenu;