import React from "react";
import { Link } from "react-router-dom";

const LinkCondition = ({link, linkTitle, checked}) => {
  if(checked === false) {
    return <Link to={link ? link : ''}>  {linkTitle}</Link>
  }
  return null;
}

const DistributeCheckingListItem = ({text, link, linkTitle, checked}) => {
  return (
    <div className="list-group-item">
      <div className="row align-items-center">
        <div className="col-auto">
          <div className="avatar avatar-sm">
            <div className={`avatar-title font-size-lg rounded-circle ${checked ? 'text-success' : 'text-warning'}`}>
              <i className={`fe fe-${checked ? 'check' : 'x'}`}></i>
            </div>
          </div>
        </div>
        <div className="col ml-n2">
          <div className="small">
            <strong>{text}</strong>
            <LinkCondition link={link} linkTitle={linkTitle} checked={checked}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DistributeCheckingListItem;