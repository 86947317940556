import React, {useContext, useState, useEffect, useReducer} from "react";
import { Redirect } from "react-router-dom";
import { Form, Row, Card, CardBody, CardHeader } from 'reactstrap';
import justcastApi from '../../api/justcast';
import {Context as ScreenContext} from '../../context/ScreenContext'
import {Context as MenuContext} from '../../context/MenuContext'
import FormGroupInputSwitch from '../../components/FormGroupInputSwitch';
import FormGroupInput from '../../components/FormGroupInput'
import ToastMessage from '../../components/ToastMessage'

const MemberPodcastSettings = (props) => {
  const showId = props.match.params.show_id;
  const [timeoutId, setTimeoutId] = useState(null);

  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)

  const [show, setShow] = useState({});
  const [denied, setDenied] = useState(false);
  const [toast, setToast] = useState({open: false});

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v2/shows/${showId}`)
    .then((res) => {
      setShow(res.data);
      screenContext.finishFetching();

      if(res.data.is_advanced_private_feed !== true) {
        setDenied(true)
      }
      menuContext.setPrivateSubscribersrMenu({
        title: 'Settings',
        subtitle: res.data.name,
        currentPageId: 'subscribers',
        currentTabId: 'private-settings',
        showId: showId,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true)
    })
  }, [showId])

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  const handleSubmit = () => {
    justcastApi.put(`/v2/shows/${showId}`, show)
    .then((res) => {
      setShow(res.data)
      setToast((prev) => ({...prev, title: "Success", message: "Settings updated.", open: true}))

      if(timeoutId) {
        clearTimeout(timeoutId)
      };

      const _timeOutId = setTimeout(() => {
        setToast((prev) => ({...prev, open: false}));
      }, 2000)

      setTimeoutId(_timeOutId)
    })
    .catch((err) => {
      const {error} = err?.response?.data ?? {}
      setToast((prev) => ({...prev, title: "Error", message: error, open: true}))
    })
  }

  const handleToggleChange = (event) => {
    const key = event.target.name;
    const value = event.target.checked;
    setShow((prev) => ({...prev, [key]: value}))
  }

  const handleInputValueChange = ({key, value}) => {
    setShow((prev) => ({...prev, [key]: value}))
  }

  const toggleToast = () => {
    const open = !toast.open;
    setToast((prev) => ({...prev, open}))
  }

  return (
    <>
      <ToastMessage
        show={toast.open}
        title={toast.title}
        message={toast.message}
        toggle={toggleToast}
      />
      <div className="container-fluid">
        <Form className="mb-4" onSubmit={handleSubmit}>
          <Card>
            <CardBody>
              <Row>
                <div className="col-12 col-md-6">
                  <FormGroupInputSwitch
                    inputType='switch'
                    inputName='own_intro'
                    inputLabel="Send your own invite"
                    mutedText="You will send your own email to your new subscribers."
                    inputValue={show.own_intro ? show.own_intro : false}
                    inputCSSId='own_intro'
                    warningMessage=""
                    handleInputChange={handleToggleChange}
                  />
                </div>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Invite Request</CardHeader>
            <CardBody>
              <Row>
                <div className="col-12 col-md-6">
                  <FormGroupInputSwitch
                    inputType='switch'
                    inputName='skip_private_feed_review'
                    inputLabel="Skip Review"
                    mutedText="Skip the review process, so that subscribers would receive the rss feed once their have requested."
                    inputValue={show.skip_private_feed_review ? show.skip_private_feed_review : false}
                    inputCSSId='skip_private_feed_review'
                    warningMessage=""
                    handleInputChange={handleToggleChange}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormGroupInputSwitch
                    inputType='switch'
                    inputName='requires_full_name'
                    inputLabel="Requires enter full name"
                    mutedText="Subscriber has to provide its name when making a request."
                    inputValue={show.requires_full_name ? show.requires_full_name : false}
                    inputCSSId='requires_full_name'
                    warningMessage=""
                    handleInputChange={handleToggleChange}
                  />
                </div>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Notification</CardHeader>
            <CardBody>
              <Row>
                <div className="col-12 col-md-6">
                  <FormGroupInputSwitch
                    inputType='switch'
                    inputName='new_private_member_notification'
                    inputLabel="New request"
                    mutedText="You will get an email notification whenever someone requires to join your private podcast."
                    inputValue={show.new_private_member_notification ? show.new_private_member_notification : false}
                    inputCSSId='new_private_member_notification'
                    warningMessage=""
                    handleInputChange={handleToggleChange}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormGroupInputSwitch
                    inputType='switch'
                    inputName='private_member_subscribed_notification'
                    inputLabel="Accepted invite"
                    mutedText="You will get an email notification whenever someone accepted the invite."
                    inputValue={show.private_member_subscribed_notification ? show.private_member_subscribed_notification : false}
                    inputCSSId='private_member_subscribed_notification'
                    warningMessage=""
                    handleInputChange={handleToggleChange}
                  />
                </div>
              </Row>
            </CardBody>
          </Card>
          <div style={{marginBottom: "80px", display: "flex", columnGap: "10px", justifyContent: "flex-end"}}>
            <div className="btn btn-primary" onClick={handleSubmit}>Save change</div>
          </div>
        </Form>
      </div>
    </>
  )
}

export default MemberPodcastSettings;