import React, {useState, useContext, useEffect} from 'react'
import { Form, FormGroup, Label, Input, 
  Button, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap'
import {Context as AuthContext} from '../../context/AuthContext'

export default function MailerLiteApiKeyModal({toggleModal, isOpen}) {
  const authContext = useContext(AuthContext);
  const {mailer_lite_accounts} = authContext.state;
  const {createMailerLiteAccount} = authContext;
  const [apiKey, setApiKey] = useState("")

  // useEffect(() => {
  //   if(mailer_lite_accounts.length > 0) {
  //     setApiKey(mailer_lite_accounts[0].api_key);
  //   } else {
  //     setApiKey("")
  //   }
  // }, [mailer_lite_accounts])
  
  const handleSubmit = (event) => {
    event.preventDefault();
    createMailerLiteAccount(apiKey)
    toggleModal();
    setApiKey("")
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggleModal}>
        <strong>MailerLite API Token</strong>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="apiToken">API Token</Label>
            <Input type="text" name="apiToken" id="apiToken" 
              placeholder="abc2fasdfl" value={apiKey}
              onChange={(event) => setApiKey(event.target.value)}
            />
          </FormGroup>
          <Button onClick={handleSubmit} color="warning">Submit</Button>
        </Form>
      </ModalBody>
      <ModalFooter>        
        <Button onClick={toggleModal} color="primary">Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
