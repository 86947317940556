const html = ({title, description, rssFeed, facebookLink, twitterLink, widgetUrl, headerImage, artwork}) => `<html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
  <title>${title}</title>
  </head>
  <body>
    <article class="page sans">
      <header>
        <img class="page-cover-image" src="${headerImage}" style="object-position:center 50%"/>
        <div class="page-header-icon page-header-icon-with-cover"><img class="icon" src=${artwork}/></div>
        <h1 class="page-title">${title}</h1></header><div class="page-body"><figure class="block-color-gray_background callout" style="white-space:pre-wrap;display:flex">
          <div style="font-size:1.5em"><span class="icon">📢</span></div><div style="width:100%">${description}</div></figure><div class="column-list"><div style="width:50%" class="column"><h2  class="">Follow us</h2><hr /><ul class="bulleted-list"><li><a href="${twitterLink ? twitterLink : ''}">Twitter</a></li></ul><ul class="bulleted-list"><li><a href="${facebookLink ? facebookLink : ""}">Facebook</a></li></ul><p class="">
  </p></div><div style="width:50%" class="column"><h2 class="">Subscribe</h2><hr/><ul class="bulleted-list"><li><a href="${rssFeed}">RSS Feed</a></li></ul><p class="">
  </p></div></div><figure><div class="source"><a href="${widgetUrl}">${widgetUrl}</a></div></figure><p class="">
  </p></div></article></body></html>`;

  export default html;

  