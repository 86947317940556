import React, {useEffect, useState, useContext} from "react";
import { useAtom } from 'jotai'
import {Redirect} from 'react-router-dom'
import justcastApi from '../api/justcast';
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AuthContext} from '../context/AuthContext'
import { Spinner, Card, CardBody, CardHeader, Button, Input, FormGroup } from 'reactstrap';
import {ExplorerList} from '../components/sub-show-explorer'

export default function SubfolderShow(props) {
  const screenContext = useContext(ScreenContext);
  const {setSortingMenu} = useContext(MenuContext);
  const {state} = useContext(AuthContext)

  const id = props.match.params.id;
  const [denied, setDenied] = useState(false);
  const [show, setShow] = useState({});
  const endpointUrl = `/v1/shows/${id}`;

  const fetchShow = () => {
    screenContext.startFetching();
    justcastApi.get(endpointUrl)
    .then((res) => {
      screenContext.finishFetching();
      setShow(res.data);
      setSortingMenu({
        title: 'Subfolder',
        subtitle: res.data.podcast_title,
        currentPageId: 'episode_sorting',
        currentTabId: 'sub_folder',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        isDirectUpload: res.data.is_direct_upload,
        skill_podcast_website: res.data.skill_podcast_website,
        allow_dropbox_subfolders: state.allow_dropbox_subfolders
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      // add notifcation
      if(err.response.status === 401) {
        setDenied(true)
      }
    })
  }

  useEffect(() => {
    fetchShow()
  }, [id])

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader>{show.name}</CardHeader>
            <CardBody>
              <ExplorerList showId={id}/>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
}
