const countryAlias = {
  'SOUTH KOREA': 'KOREA, REPUBLIC OF',
  'RUSSIA': "Russian Federation",
  'CHINA': "People's Republic of China",
  'HONG KONG': 'HK',
  'TAIWAN': "TM",
  'MACAO': "MO"
};

export const joinCountryData = (geodata, countryData) => {
  // console.log(countryData)
  const countryDataObj = {};
  countryData.forEach((item) => {
    const upperCaseName = item.name.toUpperCase();
    const countryAliasName = upperCaseName;
    // const countryAliasName = countryAlias[upperCaseName] ? countryAlias[upperCaseName] : upperCaseName;
    const {
      name,
      flag,
      downloads
    } = item;

    // const countryName = name.toUpperCase();

    countryDataObj[countryAliasName] = {
      name,
      flag,
      downloads,
    };
  })

  const geoObj = {};

  geodata.features.forEach((feature) => {
    const { name_en } = feature.properties;
    geoObj[name_en] = feature.properties;
    const downloads = countryDataObj[name_en] || {};
    feature.properties = {
      ...feature.properties,
      ...downloads
    };
  });

  return geodata;
}

export {};