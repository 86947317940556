import React from "react";
import { Input } from 'reactstrap';

const ChapterInputField = (props) => {
  const {value, placeholder, name, type, idx, id, invalid, category, handleOnChange} = props;
  // const [, chapterChange] = useAtom(chapterChangeAtom)

  const handleChange = (e) => {
    handleOnChange({
      idx, name, value: e.target.value
    })
  }

  return (
    <Input
      invalid={invalid}
      type={type}
      name={name}
      id={`${category}-${name}-${idx+1}`}
      placeholder={placeholder ? placeholder : ""}
      value={value ? value : ""}
      onChange={handleChange}
    />
  )
}

export default ChapterInputField;