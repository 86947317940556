import React, {useEffect, useState, useContext } from "react";
import {Button} from 'reactstrap';
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'

const PanelImagePreviewThumb = ({id, artworkUrl, width, height}) => {
  
  const {state, updateState} = useContext(PodieoAudioContext); 
  const {selectingObject, images} = state;

  const handleDelete = () => {
    const imageIndex = images.findIndex(t => t.id === id);
    if(imageIndex > -1) {
      //const text = state.texts[textIndex];;
      images.splice(imageIndex, 1);
      updateState({images})
    }
    if(selectingObject.type === "images" && selectingObject.id === id) {
      updateState({selectingObject: {type: null, id: null}})
    }
  }

  const handleSelect = () => {
    updateState({selectingObject: {type: "images", id}})
  }

  return (
    <>
      <div style={{ "display": "flex", justifyContent: "space-between", alignItems: "center"}}>
        <img 
          src={artworkUrl} 
          width={60} 
          height={60}
          onClick={handleSelect}
        />
        <Button 
            onClick={handleDelete}            
          ><span className="fe fe-trash"/>
        </Button>
      </div>
      <hr/>
    </>
  )
}

export default PanelImagePreviewThumb