import React, {useState, useEffect, useContext} from 'react';
import { Redirect } from "react-router-dom";
import {CardElement, injectStripe} from 'react-stripe-elements';
import justcastApi from '../api/justcast'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AlertContext} from '../context/AlertContext'
import SpinnerLoading from './SpinnerLoading'

const StripeUpdateCardForm = ({stripe}) => {
  const authContext = useContext(AuthContext);
  const {addWithMessages} = useContext(ModalContext);
  const {addWithTimeout} = useContext(AlertContext);  
  const [completeState, setCompleteState] = useState(false)
  const [paymentState, setPaymentState] = useState(false);
  const [errorState, setErrorState] = useState({status: false, message: ''})
  const email = authContext.state.email;

  const handlSubmit = (ev) => {
    stripe.createToken({name: email})
    .then((token) => {
      setPaymentState(true);
      if(token) {
        const url = `/v1/subscriptions/1`; // TODO: we don't need to use subscription id for now, we will like to change to subscription_id in the future when we are offering multiple subscriptions
        return justcastApi.put(url, {
          "stripeToken": token.token.id          
        })
      } else {
        throw new Error('Please try again with another credit card')
      }      
    })
    .then(() => {
      setPaymentState(false);
      setCompleteState(true);
      addWithTimeout({color: "warning", title: 'Payment method updated', message: "Thanks for updating the new card.", isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      setPaymentState(false);
      if(err.response && err.response.data) {
        const message = err.response.data.errors[0];
        addWithMessages({
          color: "primary", title: 'Error', messages: [message, "Please try with another card."] , isOpen: true, loading: false
        })
      } else if (err.message) {
        addWithMessages({
          color: "primary", title: 'Error', messages: [err.message, "Please try with another card."] , isOpen: true, loading: false
        })
      }      
    })    
  }

  if (completeState) return <Redirect to='/plans' />;
  if (paymentState) return <SpinnerLoading title={'This will take few seconds, please waiting.'}></SpinnerLoading>
  
  return (
    <div>
      <div className="stipe-form checkout">
        <h5>New Credit Card</h5>
        <p>Please enter the new credit card information</p>
        <CardElement />        
        <button onClick={handlSubmit}>Update</button>
      </div>      
    </div>
  )
}

export default injectStripe(StripeUpdateCardForm);