import React, { useState } from "react";
import { SketchPicker } from 'react-color'
import { Row, Col, Form, FormGroup, Label } from 'reactstrap';
import Select from 'react-select'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',    
  })
}


const swatch = {
  padding: '5px',
  background: '#fff',
  borderRadius: '1px',
  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  display: 'inline-block',
  cursor: 'pointer',  
}

const popover = {
  position: 'absolute',
  zIndex: '2',  
}

const cover = {
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
}

const ColorPickerControl = ({color, handleChange, displayColorPicker, handleClick}) => {
  if(displayColorPicker) {
    return (
      <div style={popover}>
        <div style={ cover } onClick={ handleClick }/>
        <SketchPicker color={ color } onChange={ handleChange } />
      </div>
    )
  }
  
  return null;
}


const ColorPicker = ({color, name, handleColorChange, id, width, height}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  }

  const handleChange = (selectedColor) => {
    handleColorChange({[id]: selectedColor.hex})
  }  

  return (
    <FormGroup>      
      <Label>{name}</Label>
      <div>
        <div style={swatch} onClick={handleClick}>
          <div style={ {
            backgroundColor: color ? color : "#fff",
            width: width ? width : '36px',
            height: height ? height : '14px',
            borderRadius: '2px'
          } } />
        </div>  
        <ColorPickerControl color={color ? color : "#fff"} handleChange={handleChange} displayColorPicker={displayColorPicker} handleClick={handleClick}/>    
      </div>
    </FormGroup>
  )
}

const ShowCustomizeForm = ({
  showColorThemes, 
  showColorThemeId,
  showColorThemeLabel,
  websiteColorSchem,
  setColor,
  setTheme
}) => {

  const handleColorChange = (e) => {
    setColor(e)
  }
  
  return (
    <Form>
      <FormGroup>
        <Label for="websiteTheme">Website Theme</Label>        
        <Select 
          styles={customStyles}
          value={{label: showColorThemeLabel, id: showColorThemeId, value: showColorThemeId}}
          options={showColorThemes.map((theme) => {
            return { label: theme.label, id: theme.id, value: theme.id }
          })} 
          onChange={(x) => {
            setTheme(x)
          }}
        />        
      </FormGroup>
      <Row form>
        <Col md={2}>
          <ColorPicker color={websiteColorSchem.cardBackgroundColor} name={"Card Color"} handleColorChange={handleColorChange} id={"cardBackgroundColor"}/>
        </Col>
        <Col md={2}>
          <ColorPicker color={websiteColorSchem.textColor} name={"Text Color"} handleColorChange={handleColorChange} id={"textColor"}/>
        </Col>
        <Col md={2}>
          <ColorPicker color={websiteColorSchem.buttonColor} name={"Button Color"} id={"buttonColor"} handleColorChange={handleColorChange}/>
        </Col>
        <Col md={4}>
          <ColorPicker color={websiteColorSchem.buttonTextColor} name={"Button Text Color"} id={"buttonTextColor"} handleColorChange={handleColorChange}/>
        </Col>
      </Row>      
    </Form>
  )
}

export default ShowCustomizeForm;