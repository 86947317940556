import React from "react";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

const MailchimpButtonForm = (props) => {
  const {handleSubmit, handleChange, show_form, button_text, success_message, button_title_message} = props;

  const handleInputChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    handleChange({[key]: value});
  }

  if(show_form) {
    return (
      <div>
        <h3>Description</h3>
        <Form onSubmit={(e) => {
           e.preventDefault();
           handleSubmit()
        }}>
          <FormGroup>
            <Label for="button_title_message">Section title</Label>
            <Input type="text" name="button_title_message" id="button_title_message" placeholder="Join our newsletter!" value={button_title_message} onChange={handleInputChange}/>
          </FormGroup>
          <FormGroup>
            <Label for="button_text">Button text</Label>
            <Input type="text" name="button_text" id="button_text" placeholder="Subscribe" value={button_text} onChange={handleInputChange}/>
          </FormGroup>    
          <FormGroup>
            <Label for="success_message">Success message</Label>
            <Input type="text" name="success_message" id="success_message" placeholder="Thanks for subscribing!" value={success_message} onChange={handleInputChange}/>
          </FormGroup>
          <Button color="primary">Save</Button>
        </Form>
      </div>
    )
  }

  return null;
}

export default MailchimpButtonForm;