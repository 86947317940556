import { atom } from "jotai";

export const playerAtom = atom({
  playerRef: null,
  duration: 0,
  playedSeconds: 0,
  playPauseStatus: "PAUSE", // PLAY, PUASE, PRESSED
  playing: false,
})

export const initAtom = atom((get) => get(playerAtom), (_get, set, data) => {
  const {playerRef, duration} = data;
  set(playerAtom, () => {
    return {
      playerRef, 
      duration, 
      playedSeconds: 0,
      playing: false,        
    };
  });
})

export const updatePlayerAtom = atom((get) => get(playerAtom), (_get, set, data) => {
  const state = _get(playerAtom);
  set(playerAtom, {...state, ...data});
})