import React, {useContext, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {Context as AuthContext} from '../context/AuthContext'
import AuthForm from '../components/AuthForm'
import happinessImg from './../assets/img/illustrations/happiness.svg'
import justcastApi from '../api/justcast'
import AsyncStorage from '../libs/AsyncStorage'
import {Mixpanel} from '../api/mixpanel'

const SignUp = (props)  => {
  const {state, signup} = useContext(AuthContext);
  const [showPasswordInput, setShowPasswordInput] = useState(true);
  const [redirectBack, setRedirectBack] = useState(false)
  const [allowMigration, setAllowMigration] = useState(false)

  useEffect(() => {
    AsyncStorage.setReferralCode(props.location.search);
    Mixpanel.track('Sign up page loaded');
  }, [])

  return (
    <div className="container authentication">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 d-none d-md-inline-block">
          <div className="text-center"><img src={happinessImg} alt="..." className="img-fluid"/></div>
        </div>
        <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
          <h1 className="display-4 text-center mb-3">Sign up</h1>
          <AuthForm
            headerText="Free access to our dashboard."
            errorMessages={state.errorMessages}
            submitButtonText="Sign up"
            buttonId="justcast_signup"
            showPasswordInput={showPasswordInput}
            redirectBack={redirectBack}
            allowMigration={allowMigration}
            onSubmit={signup}
          />
          <br/>
          <div className="text-center">
            <small className="text-muted text-center">
              Already have an account? <Link to="/signin">Sign in</Link> instead.
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp


