import React, {useState} from "react";
import { Redirect } from "react-router";
import {Alert, Button, InputGroup, InputGroupAddon, InputGroupText, Input} from 'reactstrap';
import justcastApi from '../../api/justcast'
import MetaCard from './MetaCard'
import ImportMessage from './ImportMessage'

const ImportPageBody = ({isErrorPage}) => {

  const [redirectUrl, setRedirectUrl] = useState('');
  const [rssFeed, setRssFeed] = useState('');
  const [podcast, setPodcast] = useState(null);
  const [podcastName, setPodcastName] = useState('');
  const [podcastAuthor, setPodcastAuthor] = useState('');
  const [podcastCount, setPodcastCount] = useState(0);
  const [artworkUrl, setArtworkUrl] = useState('https://via.placeholder.com/150');
  const [email, setEmail] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [sentEmail, setSentEmail] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const getRSSfeedData = () => {
    // TODO: set loading button
    justcastApi.post('/v1/get_podcast_remote_rss_meta', {rss_feed: rssFeed})
    .then((res) => {
      setPodcast(res.data);
      setPodcastName(res.data.name)
      setPodcastAuthor(res.data.author)
      setPodcastCount(res.data.total_entries_count)
      setEmail(res.data.email)
      setSentEmail('');
      setAlertMessage('')
      if(res.data.artwork_url) {
        setArtworkUrl(res.data.artwork_url)
      }      
    })
    .catch((err) => {
      setAlertMessage(err.response.data.error)
      setSentEmail('');
      // console.log(err.response.error)
    })
  }

  const handleSendVerificationEmail = () => {
    justcastApi.post('/v1/send_verification_email', {email, show_name: podcastName})
    .then((res) => {
      // setAlertMessage(res.data.message)
      // console.log(res.data.message)
      setAlertMessage('')
      setPasscode('');
      setSentEmail(res.data.message);
    })
    .catch((err) => {
      setAlertMessage('Error....')
      setSentEmail('');
      setPasscode('');
      // console.log(err.response.error)
    })
  }

  const handleSubmit = () => {
    // TODO loading...
    setSubmitting(true);
    justcastApi.post('/v1/import_from_rss_to_direct_upload_show', {passcode, rss_feed: rssFeed})
    .then((res) => {
      setSubmitting(false);
      const showId = res.data.id;
      setRedirectUrl(`/shows/${showId}/overview`);
    })
    .catch((err) => {
      setAlertMessage(err.response.data.error)
      setSubmitting(false);
      setPasscode('');
      setSentEmail('');
      // console.log(err.response.data.error)
    })
  }

  const handlePasscode = (event) => {
    const value = event.target.value;
    setPasscode(value)
  }

  const reset = () => {
    setRssFeed('');
    setPodcast(null);
    setPodcastName('');
    setPodcastAuthor('');
    setPodcastCount(0);
    setArtworkUrl('https://via.placeholder.com/150');
    setEmail('');  
    setSentEmail('');
    setAlertMessage('');
    setPasscode('');
  }

  if(redirectUrl) {
    return (
      <Redirect to={redirectUrl} />
    )    
  }

  if(isErrorPage) {
    return null;
  } 

  if(podcast) {
    return (
      <>
        <MetaCard 
          name={podcastName} 
          author={podcastAuthor}
          podcastCount={podcastCount}
          artworkUrl={artworkUrl}
        />
        <ImportMessage 
          email={email} 
          reset={reset}
          passcode={passcode}
          alertMessage={alertMessage}
          handlePasscode={handlePasscode} 
          handleSubmit={handleSubmit}
          sentEmail={sentEmail}
          submitting={submitting}
          sendVerifyCodeEmail={handleSendVerificationEmail}
        />
      </>
    );
  }
  
  return (
    <div>
      {alertMessage ? <Alert color="danger">{alertMessage}</Alert> : null}
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText><i className="fe fe-rss"/></InputGroupText>
        </InputGroupAddon>
        <Input 
          value={rssFeed}
          onChange={(event) => {
            // event.target.value
            setRssFeed(event.target.value)
          }}
          placeholder="e.g. https://yourpodcast.fm/feed.rss" 
        />
      </InputGroup>      
      <br/>
      <Button color="primary" onClick={getRSSfeedData}>Next step: Validation</Button>
    </div>
  )
  
}

export default ImportPageBody;