import React, {useState} from "react";
import {Button, InputGroup, InputGroupAddon, InputGroupText, Input} from 'reactstrap';

const ImportMessage = ({
    email, 
    reset, 
    alertMessage,
    sentEmail, 
    sendVerifyCodeEmail, 
    passcode, 
    handlePasscode,
    handleSubmit,
    submitting
  }) => {
  if(email) {
    return (
      <>
      <div>
        {
          sentEmail ? 
            <>
              <p>{sentEmail}</p>
              <Input 
                placeholder="e.g. 123456"
                value={passcode}
                onChange={handlePasscode}
              />
              <br/>
            </>
          : <p>Before importing can begin, we need to first send an ownership verification code to <strong>{email}.</strong>  Enter the code inside the email to start the import.</p>
        } 
        </div>
        {
          alertMessage ? <p className="text-danger">{alertMessage}</p> : null
        }                      
        <div>
          {
            sentEmail ? <Button color="warning" onClick={handleSubmit} disabled={submitting}>Submit</Button> : <Button color="primary" onClick={sendVerifyCodeEmail}>Send verification email and continue</Button>
          }          
          {' '}
          <Button color="danger" onClick={reset}>Cancel and start over</Button>
        </div>
      </>     
    )
  } else {
    return (
      <>
        <p>Missing email address in the RSS feed</p>
        <div><Button color="danger" onClick={reset}>Cancel and start over</Button></div>
      </>
    )
  }
}

export default ImportMessage;