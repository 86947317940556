import React, {useEffect, useState, useContext} from "react";
import {Redirect} from 'react-router-dom'
import justcastApi from '../api/justcast';
import {uploadHandler} from '../api/doUploader'
import ShowPageHeader from '../components/PageHeader/ShowPageHeader';
import ImageUploadForm from '../components/ImageUploadForm';
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as MenuContext} from '../context/MenuContext'
import { Spinner } from 'reactstrap';
import SimpleMedia from '../components/SimpleMedia'


const ShowArtwork = (props) => {
  const {addWithTimeout} = useContext(AlertContext);
  const menuContext = useContext(MenuContext);
  const screenContext = useContext(ScreenContext)  
  const id = props.match.params.id;
  const [showName, setShowName] = useState('');
  const [item, setItem] = useState({});
  const [artwork, setArtwork] = useState(null);
  const [imgUrl, setImgUrl] = useState('https://via.placeholder.com/150')
  const [denied, setDenied] = useState(false);

  const handleUpdate = () => {
    screenContext.startFetching();
    window.scrollTo(0, 0);
    justcastApi.put(`/v1/shows/${id}`, item)
    .then(() => {      
      return uploadHandler(artwork)
    })
    .then((res) => {
      const url = res.data.path;      
      return justcastApi.put(`/v1/shows/${id}`, {artwork_url: url});
    })
    .then((res) => {
      // gives a 5 seconds delay so that digital ocean will have the file ready
      setTimeout(() => {
        screenContext.finishFetching();
        addWithTimeout({color: 'warning', message: `Artwork has been updated!`, isOpen: true, timeout: 3000})
        if(res.data.artwork_url) {
          setImgUrl(res.data.artwork_url);
        }
      }, 5000)
    })
    .catch((err) => {
      console.log(err.message)
      screenContext.finishFetching();
      addWithTimeout({color: 'danger', message: err.message, isOpen: true, timeout: 3000})
    })
  }

  const deleteArtwork = () => {
    setArtwork(null);
    setImgUrl('https://via.placeholder.com/150');

    justcastApi.put(`/v1/shows/${id}`, {...item, artwork_url: null})
    .then(() => {
      addWithTimeout({color: 'warning', message: `Artwork has been Deleted!`, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err.message)
      screenContext.finishFetching();
      addWithTimeout({color: 'danger', message: err.message, isOpen: true, timeout: 3000})
    })    
  }

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;      
      if(res.data.artwork_url) {
        setImgUrl(res.data.artwork_url)
      }
      
      menuContext.setSettingsMenus({
        title: 'Artwork',
        subtitle: name,
        currentPageId: 'settings',
        currentTabId: 'artwork',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website        
      });

      setShowName(name);
      setItem(res.data);
      screenContext.finishFetching();
    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true)
      console.log(err)
    })    
  }, [id])

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <>      
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> : <>
                <SimpleMedia 
                  handleRemoveFile={deleteArtwork}
                  img={imgUrl} 
                  imgAltName={'podcast artwork'} 
                  headingLabel='Artwork URL' 
                  bodyText={imgUrl} />
                <hr/>
                <ImageUploadForm setArtwork={setArtwork} handleUpdate={handleUpdate} showId={id} artworkWidth={1400} artworkHeight={1400} />
              </>
            }            
          </div>
        </div>    
      </div>    
    </>
  )
}

export default ShowArtwork;