import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function EpisodeListSortable({items, handleSorting}) {
  // console.log(items)
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    handleSorting(result)
  }

  if(items && items.length > 0) {
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="episodes">
          {
            (provided) => (
              <div className="sort-episodes list-group list-group-flush list-group-activity my-n3" {...provided.droppableProps} ref={provided.innerRef}>
                {
                  items.map((item, idx) => {
                    return (
                      <Draggable isDragDisabled={false} key={`episode-${idx+1}`} draggableId={`episode-${idx+1}`} index={idx}>
                        {
                          (provided) => (
                            <div className="list-group-item" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                              <div className="row">
                                <div className="col-auto">
                                  <div className="avatar avatar-sm">
                                    <div className="avatar-title font-size-lg bg-success-soft rounded-circle text-success">
                                      <span>{item.episode_number ? item.episode_number : "N/A"}</span>
                                    </div>
                                  </div>                  
                                </div>
                                <div className="col ml-n2">
                                  <h5 className="mb-1">{item.name}</h5>
                                  <small className="text-muted">{item.audio_date_label}</small>
                                </div>                          
                              </div>
                            </div>
                          )
                        }
                      </Draggable>
                    )
                  })
                }
                {provided.placeholder}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
    )
  }

  return null;
}
