const introVideos = [
  {
    id: 1,
    iconclass: 'fe fe-mic',
    title: 'Getting started a new podcast',
    subtitle: 'How to create and upload a podcast',
    videoUrl: 'https://player.vimeo.com/video/590140243'
  },
  {
    id: 4,
    iconclass: 'fe fe-mic',
    title: 'Announcing JustCast AI',
    subtitle: 'A pioneering tool that seamlessly integrates AI into your podcasting journey',
    videoUrl: 'https://player.vimeo.com/video/883652053'
  },
  {
    id: 2,
    iconclass: 'fe fe-mic',
    title: 'Getting started with Dropbox',
    subtitle: 'Set up and learn the basics of JustCast.',
    videoUrl: 'https://player.vimeo.com/video/419194361',
    isDropbox: true
  },
  {
    id: 3,
    iconclass: 'fe fe-image',
    title: 'Upload artwork for your podcast',
    subtitle: 'Add an artwork to your podcast, and episodes.',
    videoUrl: 'https://player.vimeo.com/video/419204052'
  },
  // {
  //   id: 4,
  //   iconclass: 'fe fe-edit',
  //   title: 'Customize',
  //   subtitle: 'Personalize JustCast to suit your podcast needs through changing settings.',
  //   videoUrl: ''
  // },
  // {
  //   id: 5,
  //   iconclass: 'fe fe-camera',
  //   title: 'Podieo',
  //   subtitle: 'Create a video trailer for your podcast to share to share on social media.',
  //   videoUrl: 'https://player.vimeo.com/video/419213963'
  // }
];

export default introVideos;