import React from "react";
import ReactPlayer from 'react-player'
import { Button, Row, Progress, Alert } from 'reactstrap';

const FileUploadProgressbar = (props) => {
  const {
    url, 
    newFileSize,
    isCreateForm,
    fileTypeGroup, 
    isDirectUpload, 
    statusCode,
    uploadedSize,
    totalSize,
    clearMessages,
    handleMediaFileChange,
    handleUploadFileChange,
    handleDurationChange,
  } = props;

  if(url) {
    return (
      <>
        <Row className="align-items-center">
          <div className="col">Current {fileTypeGroup}</div>
          <DeleteButton 
            isCreateForm={isCreateForm}
            newFileSize={newFileSize}
            isDirectUpload={isDirectUpload}
            clearMessages={clearMessages} 
            handleUploadFileChange={handleUploadFileChange} 
            handleMediaFileChange={handleMediaFileChange}
          />
        </Row>
        <br/>
        <ReactPlayer 
          url={url} 
          controls={true}
          height={"40px"}
          width={"100%"}
          onDuration={
            (x) => {                  
              let date = new Date(null);
              date.setSeconds(x);
              if(isDirectUpload) {
                const duration = date.toISOString().substr(11, 8);
                // console.log({duration})
                handleDurationChange({
                  key: 'duration',
                  value: duration
                }) 
              }                
            }
          }
          config={{
            file: {
              attributes: {
                preload: 'none'
              }
            }
          }}
        />
      </>
    )
  } else if (!url && isDirectUpload && statusCode === 202) {
    return (
      <>
        <div>Uploading...</div>
        <p>{totalSize > 0 ? Math.floor(uploadedSize  * 100 /totalSize) : uploadedSize}%</p>
        <Progress animated value={uploadedSize} max={totalSize}/>
      </>
    )
  } else if (!url && isDirectUpload && statusCode === 400) {
    return(
      <Alert color="danger">
        Failed upload the file, please try to upload it again.
      </Alert>
    )
  }

  if(isCreateForm) return null;
  
  return <><div>Loading...</div></>;
}

const DeleteButton = ({isCreateForm, newFileSize, isDirectUpload, handleUploadFileChange, handleMediaFileChange, clearMessages}) => {
  if(isDirectUpload && handleUploadFileChange && handleMediaFileChange) {
    if(isCreateForm) {
      return (
        <Button color="danger" size="sm" onClick={() => {
          handleMediaFileChange('','', '')
          handleUploadFileChange(null);
          clearMessages()
        }}>Remove</Button>
      )
    } else if (newFileSize) {
      return (
        <Button color="danger" size="sm" onClick={() => {
          handleMediaFileChange(null,null, null)
          handleUploadFileChange(null);
          clearMessages()
        }}>Cancel upload</Button>
      )
    }

  }

  return null;
}

export default FileUploadProgressbar;