import React, {useContext, useState} from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Button } from 'reactstrap';
import {Context as AuthContext} from '../context/AuthContext'
import ForgotPasswordEmailSent from '../components/ForgotPassowrdEmailSent'

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const {state, forgotPassword} = useContext(AuthContext);

  if (state.forgotPassowrdEmailSent) {
    return (<ForgotPasswordEmailSent bodyText={"If the email address you provide is associated with an account in our records, you will receive an email with instructions for resetting your password.  If you don't receive this email, please check your junk mail folder or contact us."}/>)
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-5 col-xl-4 my-5">
          <h1 className="display-4 text-center mb-3">Password reset</h1>
          <p className="text-muted text-center mb-5">Enter your email to get a password reset link.</p>
          <Form onSubmit={(event) => {
            forgotPassword(email);
            event.preventDefault();
          }}>
            <FormGroup>
              <label>Password</label>
              <Input type="text" 
              name="Email"
              placeholder="name@address.com" 
              value={email} 
              onChange={(event) => {
                setEmail(event.target.value)
              }}/>
            </FormGroup>
            <Button color="primary"
              type="submit"
              className="btn btn-primary btn-block mt-3" 
            >Reset Password</Button> 
          </Form>
          <br/>
          <div className="text-center">
            <small className="text-muted text-center">
              <Link to="/signin">Go to login page</Link>
            </small>            
          </div>          
        </div>
      </div>      
    </div>
  )
}

export default ForgotPassword;