import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../api/justcast'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import { Spinner, Button } from 'reactstrap';
import {PersonNote, PeopleSortList} from '../components/people'
import {uploadImageHandler} from '../api/doUploader'

const ShowPeopleSorting = (props) => {
  const id = props.match.params.id;
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const {addWithMessages, add} = useContext(ModalContext);
  const [showName, setShowName] = useState('');
  const [people, setPeople] = useState([])

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}/people`)
    .then((res) => {
      const name = res.data.podcast_title;
      setShowName(name);
      setPeople(res.data.people)

      screenContext.finishFetching();
      
      menuContext.setSettingsMenus({
        title: 'Person',
        subtitle: name,
        currentPageId: 'settings',
        currentTabId: 'people_settings',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website     
      });     
            
    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
    })    
  }, [])

  const handlePersonSave = () => {
    addWithMessages({
      color: "primary", 
      title: 'Update people sorting',
      message: "",
      messages: [], 
      isOpen: true,
      loading: true
    })   

    justcastApi.post(`/v1/shows/${id}/update_people_sort`, {people})  
    .then((res) => {
      addWithMessages({
        color: "primary", 
        title: 'Updating...',
        messages: [], 
        isOpen: true,
        loading: true
      })   

      setTimeout(() => {
        setPeople(res.data)
        addWithMessages({
          color: "primary", 
          title: 'Success update people sorting',
          messages: ["Saved the new order"], 
          isOpen: true,
          loading: false
        })   
      }, 5000)

      return res.data;
    })
    .catch((err) => {
      // console.log(err.response.data.message);
      const errs = err.response.data;
      const keys = Object.keys(errs);
      const messages = keys.map((key) => `${key} ${errs[key].join(',')}`)

      addWithMessages({
        color: "primary", 
        title: 'Error:',
        messages: messages, 
        isOpen: true,
        loading: false
      })         
    })
  }

  const handleSorting = (result) => {
    const items = Array.from(people);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newPeople = items.map((person, position_order_idx) => {
      return {...person, sort_order: position_order_idx + 1}
    })
    setPeople(newPeople);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> : 
              <div>
                <PersonNote>
                  <p>Drag the person to reorder the people list</p>
                </PersonNote>
                <PeopleSortList
                  handleSorting={handleSorting}
                  showId={id} 
                  people={people}
                >                  
                  <Button color="primary" size="sm" onClick={handlePersonSave}>Save Change</Button>                
                </PeopleSortList>
              </div>              
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ShowPeopleSorting;