import createDataContext from './createDataContext';

const initState = {
  id: "",
  name: "",
  // audio_date: "",
  // url: "",
  description: "",
  artwork: "",
  // hide: true,
  playing: false,
  duration: 0,
  loaded: 0, // in percentage
  loadedSeconds: 0,
  played: 0, // in percentage
  playedSeconds: 0,
  seeking: false,
  playingId: "",
  playingName: "",
  playingUrl: "",
  playingDescription: "",
  playingArtwork: "",
  backgroudColor: "",
  textColor: "",
  buttonColor: "",
  items: []
}

const mobilePlayerReducer = (state, action) => {
  switch(action.type) {
    case 'add':
      return {...state, ...action.payload}
    case 'play_pause':
      return {...state, playing: !state.playing}
    case 'update_duration':
      return {...state, ...action.payload}
    case 'update_progress':
      // We only want to update time slider if we are not currently seeking
      if(!state.seeking) {
        return {...state, ...action.payload}
      }
      return {...state};
    case 'toggle_seeking':
      return {...state, seeking: !state.seeking}      
    case 'seek_change':
      return {...state, ...action.payload}      
    case 'reset': 
      return {...initState}
    default:
      return state      
  }
}

const add = dispatch => {
  return ({
    id, 
    name, 
    artwork, 
    playingId,
    playingName,
    playingUrl,
    playingDescription,
    playingArtwork,
    items
  }) => {
    dispatch({type: 'reset'})
    dispatch({type: 'add', payload: {
      id, 
      name, 
      artwork, 
      playingId,
      playingName,
      playingUrl,
      playingDescription,
      playingArtwork,
      items
    }})    
  }
}


export const {Provider, Context} = createDataContext(
  mobilePlayerReducer,
  {
    add,   
  },
  initState
)

export default {}
