import React, {useContext, useState, useEffect } from "react";
import { Spinner } from 'reactstrap';
import justcastApi from '../../../api/justcast';
import {Context as MenuContext} from '../../../context/MenuContext'
import {Context as ScreenContext} from '../../../context/ScreenContext'
import ShowForm from '../../../components/ShowForm';
import ToastMessage from '../../../components/ToastMessage';

export default function TeamPodcast(props) {
  const id = props.match.params.id;
  const menuContext = useContext(MenuContext);
  const screenContext = useContext(ScreenContext);
  const [openToast, setOpenToast] = useState(false);
  const [toastTitle, setToastTitle] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [item, setItem] = useState({})
  const [languages, setLanguages] = useState([]);
  const [categoryId, setCategoryId] = useState('')
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [explicitTypes, setExplicitTypes] = useState([]);
  const [showName, setShowName] = useState('');
  const itunesTypes = [{id: 'itune_type_id_episodic',mutedText: 'Specify episodic for stand-alone episodes or when you want your episodes presented and recommended last-to-first. This option also supports seasons, with the newest episode at the top.', label: 'Episodic (most common)', value: false}, {id: 'itune_type_id_serial', label: 'Serial', value: true, mutedText: 'Specify serial when you want your episodes presented and recommended first-to-last. This option supports narratives, storytelling, thematic, and multiple seasons.'}]
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState({location_name: null, location_geo: null})

  useEffect(() => {
    if(id) {
      screenContext.startFetching();

      justcastApi.get(`/v2/team_podcasts/${id}`)
      .then((res) => {
        screenContext.finishFetching();
        const show = res.data;
        setShowName(show.podcast_title);
        setDescription(show.description);
        setItem(show);
        setLanguages(show.languages)
        setCategories(show.categories)
        setExplicitTypes(show.explicit_types)
        setLocation({location_geo: show.location_geo, location_name: show.location_name})
        if(show.category_id) {
          setCategoryId(show.category_id)
        } else {
          setCategoryId(1)
        }

        menuContext.setTeamPodcastSettingsPageMenus({
          title: 'Settings',
          subtitle: show.name,
          currentPageId: 'settings',
          currentTabId: 'settings',
          showId: id,
          landingPageUrl: show.landing_page_url,
          websiteUrl: show.player_page_link,
          rssFeed: show.rss_feed,
          passwordProtected: show.is_password_protected,
          advancedPrivate: show.is_advanced_private_feed,
          isPrivate: show.is_private,
          isPrivateShow: show.is_private_show,
        });
      })
      .catch((err) => {
        screenContext.finishFetching();
      })
    }
  }, [id])

  useEffect(() => {
    if (categoryId) {
      justcastApi.get(`/v1/categories/${categoryId}/subcategories`)
      .then((res) => {
        setSubCategories(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [categoryId])


  const handleInputValueChange = ({key, value}) => {
    setItem({...item, [key]: value})
  }

  const handleNameChange = ({key, value}) => {
    setItem({...item, [key]: value})
    setShowName(value)
  }

  const handleLocationChange = ({name, lat, lng}) => {
    const geo = `geo:${lat},${lng}`;
    setLocation({location_name: name, location_geo: geo})
  }

  const handleToggleChange = (event) => {
    const key = event.target.name;
    const value = event.target.checked;
    setItem({...item, [key]: value})
  }

  const handleSelectionChange = (event) => {
    let value = event.target.value;
    const key = event.target.name;
    if(key === 'category_id') {
      setCategoryId(value)
    }

    if(key === 'itunes_type_is_serial') {
      if(value === 'true') {
        value = true;
      } else {
        value = false;
      }
    }

    setItem({...item, [key]: value})
  }

  const handleQuillEditorChange = (value) => {
    setDescription(value);
  }

  const handleUpdate = () => {
    if(timeoutId) {
      clearTimeout(timeoutId)
    };
    // setItem({...item, description})
    justcastApi.put(`/v2/team_podcasts/${id}`, {podcast: {...item, ...location, description}})
    .then((res) => {
      const data = res.data;
      setItem(data);
      setOpenToast(true);
      setToastTitle("Success")
      setToastMessage(`${showName} has been updated!`)

      const _timeOutId = setTimeout(() => {
        setOpenToast(false);
      }, 2000)

      setTimeoutId(_timeOutId)
    })
    .catch((err) => {
      setOpenToast(true);
      setToastTitle("Error")
      setToastMessage("Something went wrong");
      const _timeOutId = setTimeout(() => {
        setOpenToast(false);
      }, 2000)

      setTimeoutId(_timeOutId)
    })
  }

  const toggleToast = () => {
    setOpenToast(!openToast);
  }

  if(screenContext.state.loading) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  return (
    <div className="container-fluid" style={{paddingBottom: 20}}>
      <ToastMessage
        toggle={toggleToast}
        show={openToast}
        title={toastTitle}
        message={toastMessage}
      />
      <ShowForm
        name={showName}
        isDirectUpload={item.is_direct_upload}
        showNameEditable={item.show_name_editable ? item.show_name_editable : false}
        author={item.author}
        email={item.email}
        link={item.link}
        location_geo={location.location_geo}
        location_name={location.location_name}
        medium_type={item.medium_type}
        description={description}
        languageId={item.language_id}
        languages={languages}
        explicitTypeId={item.explicit_type_id}
        explicitTypes={explicitTypes}
        categoryId={item.category_id}
        categories={categories}
        subcategoryId={item.subcategory_id}
        itunesTypeIsSerial={item.itunes_type_is_serial}
        itunesComplete={item.itune_complete}
        itunesKeywords={item.itunes_keywords}
        locked={item.locked}
        itunesTypes={itunesTypes}
        copyright={item.copyright}
        subcategories={subCategories}
        handleQuillEditorChange={handleQuillEditorChange}
        handleSelectionChange={handleSelectionChange}
        handleToggleChange={handleToggleChange}
        handleUpdate={handleUpdate}
        handleInputValueChange={handleInputValueChange}
        handleNameChange={handleNameChange}
        handleLocationChange={handleLocationChange}
      />
    </div>
  )
}
