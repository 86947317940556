import createDataContext from './createDataContext';
// https://dribbble.com/shots/11331921-Video-editor/attachments/2943165?mode=media
// https://dribbble.com/shots/10836842-Online-Video-Editor-Timeline-VEED-IO
// https://dribbble.com/shots/11034773-Online-Video-Editor-Subtitle-Tool/attachments/2629749?mode=media

const justcastBadgeDefault = {x: 0, y: 0, height: 50, width: 180, artwork: "https://justcast.sfo2.digitaloceanspaces.com/LandingPageAssets/Imgs/justcastbadge.png"}

const initState = {    
  url: "", // pass to micro-service
  playerDivId: "audiogram-player",
  playAble: false,
  onReady: false,
  duration: 0,
  loaded: 0, // in percentage
  loadedSeconds: 0,
  played: 0, // in percentage
  playedSeconds: 0,
  scrolledScreen: 0,
  seeking: false,
  playing: false,
  maxClipDuration: 120, // 2 mins
  clipFromSeconds: 0,  // pass to micro-service
  clipEndSeconds: 0, // pass to micro-service
  clipFromText: "00:00:00",
  clipEndText: "00:00:00",  
  step: 0.1,
  videoSpecs: [],
  canvasId: 0,
  canvasName: "",
  canvasCode: "",
  demoDivWidth: 100,
  demoDivHeight: 100,
  demoSvgWidth: 100,
  demoSvgHeight: 100,
  demoSvgRatio: 1, // pass to micro-service
  canvasWidth: 100, // pass to micro-service
  canvasHeight: 100, // pass to micro-service
  buttonSelectedId: 'project',
  buttonSelectedTitle: 'Project',
  buttons: [
    {id: "project", title: "Project", iconClass: "fe fe-crop"},
    {id: "texts", title: "Text", iconClass: "fe fe-type"},
    {id: "images", title: "Image", iconClass: "fe fe-image"},
    {id: "elements", title: "Elements", iconClass: "fe fe-package"}
  ],
  defaultBackgroundColor: '#032944',
  backgroundColor: '#032944', // pass to micro-service
  images: [], // with default podcast isRemoteURL,artwork, x, y, height, width, opacity // pass to micro-service
  texts: [], // with default podcast name, episode title, publish date, each text has styles, e.g. name, fontSize, color, xy, height, width, textAnchor? // pass to micro-service
  selectingObject: {type: null, id: null},
  soundWaveAdded: false, // pass to micro-service
  soundWave: {id: null, type: null, width: null, height: null, x: 10, y: 100, barHeight: 40, barWidth: 5, barGap: 2, barColor: '#fff', fillColor: "#E35986"}, // pass to micro-service
  soundWaveData: [],
  audioRawData: [],
  audioContext: null,
  audioAnalyser: null,
  audioEle: null,
  audioSource: null,
  countSoundWaveChanged: 0,
  progressBarAdded: false, // pass to micro-service
  progressBar: {id: null, type: null, width: null, height: 20, x: 0, y: 0, filledColor: "#fff", backgroundFillOpacity: "0.5", playedFillOpacity: "0.8", fontSize: 12, showTimer: true}, // pass to micro-service
  showBadge: true,
  justcastBadge: justcastBadgeDefault
}

const podieoAudioReducer = (state, action) => {
  switch(action.type) {
    case 'add':
      return {...state, ...action.payload}
    case 'play_pause':
      return {...state, playing: !state.playing}    
    case 'reset': 
      return {...initState}
    default:
      return state
  }
}

const add = dispatch => {
  return (url) => {
    dispatch({type: 'add', payload: {...initState, url}})
  }
}

const playPause = dispatch => {
  return () => {
    dispatch({type: 'play_pause'});
  }
}

// const addPlayAble = dispatch => {
//   return (playAble) => {
//     dispatch({type: 'add', payload: {playAble}})
//   }
// }

const updateState = dispatch => {
  return (changed) => {
    dispatch({type: 'add', payload: changed})
  }
}

const updateSvgState = dispatch => {
  return ({demoDivWidth, demoDivHeight, canvasWidth, canvasHeight}) => {

    if(demoDivWidth && demoDivHeight && canvasWidth && canvasHeight) {
      let demoSvgRatio;
      if (canvasWidth - canvasHeight > 0) {
        demoSvgRatio = demoDivWidth * 0.8 / canvasWidth;
      } else if (canvasWidth - canvasHeight === 0) {
        demoSvgRatio = demoDivWidth * 0.6 / canvasWidth;
      } else {
        demoSvgRatio = demoDivHeight / canvasHeight;
      }

      const demoSvgWidth = canvasWidth * demoSvgRatio;
      const demoSvgHeight = canvasHeight * demoSvgRatio;

      const justcastBadge = {...justcastBadgeDefault, x: demoSvgWidth / 2 - 45, y: demoSvgHeight - 40, height: justcastBadgeDefault.height * demoSvgRatio, width: justcastBadgeDefault.width * demoSvgRatio}

      dispatch({type: 'add', payload: {demoDivWidth, demoDivHeight, demoSvgRatio, demoSvgWidth, demoSvgHeight, justcastBadge}})
    }
  }
}

// const addText = dispatch => {
//   return ({}) => {

//   }
// }

export const {Provider, Context} = createDataContext(
  podieoAudioReducer,
  {
    // addText,
    // deleteText,
    // addImage,
    // deleteImage,
    add,
    updateState,
    playPause,
    updateSvgState,
  },
  initState
)
