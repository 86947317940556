import React, {useEffect, useState, useContext } from "react";
import Select from 'react-select'
import { Row, Col, Form, FormGroup, Label, Input, Card, CardBody, CardHeader } from 'reactstrap';
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import ColorPicker from './ColorPicker'
import TimeString from '../../libs/TimeString'

const timeStringHandler = new TimeString();

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.isSelected ? 'white' : 'blue',
  })
}

const PanelProjectTab = () => {

  const {state, updateState, updateSvgState} = useContext(PodieoAudioContext);
  const [displayColorPicker, setDisplayColorPicker] = useState(false); 

  const handleChange = (x) => {
    const canvasId = x['id'];
    const canvasName = x['name'];
    const canvasCode = x['code'];
    const canvasWidth = x['width'];
    const canvasHeight = x['height'];    

    // const demoSvgRatio = state.demoDivWidth / canvasWidth;
    // const demoSvgWidth = demoSvgRatio * canvasWidth;
    // const demoSvgHeight = demoSvgRatio * canvasHeight;
    const demoDivWidth = state.demoDivWidth;
    const demoDivHeight = state.demoDivHeight;
    
    updateSvgState({demoDivWidth, demoDivHeight, canvasWidth, canvasHeight})
    updateState({canvasId, canvasName, canvasCode, canvasWidth, canvasHeight});
  }

  const handleInputChange = (event) => {
    // pause it once we enter
    updateState({playing: false})

    const key = event.target.id;
    const val = event.target.value;
    // this one is quite complicated
    // we need to convert the string back to seconds
    // we need to handle the playing time
    // console.log({[key]: val})
    const seconds = timeStringHandler.getSecond(val);
    if(seconds && seconds >= 0 && seconds <= state.duration) {
      let clipFromSeconds = 0;
      let playedSeconds = 0;
      let clipEndSeconds = 0;

      if(key === 'clipEndText') {
        clipFromSeconds = (seconds - state.clipFromSeconds) > 0 ? state.clipFromSeconds : Math.max((seconds - 90), 0);
        playedSeconds = (state.playedSeconds >= clipFromSeconds && state.playedSeconds <= seconds) ? state.playedSeconds : (seconds + clipFromSeconds) / 2;
        clipEndSeconds = seconds;
      } else {
        clipEndSeconds = (state.clipToSeconds - seconds) > 0 ? state.clipEndSeconds : Math.min((seconds + 90), state.duration);
        playedSeconds = (state.playedSeconds >= seconds && state.playedSeconds <= clipEndSeconds) ? state.playedSeconds : (seconds + clipEndSeconds) / 2;
        clipFromSeconds = seconds;
      }

      const clipFromText = timeStringHandler.getTimeString(clipFromSeconds);
      const clipEndText = timeStringHandler.getTimeString(clipEndSeconds);

      updateState({clipFromSeconds, playedSeconds, clipEndSeconds, clipFromText, clipEndText})      
    } else {
      updateState({[key]: val})
    }       
  }

  const handleColorChanged = (x) => {
    updateState({...x})
  }

  return (
    <Form>      
      <FormGroup>
        <Label for="canvas">Canvas</Label>
        {
          state.videoSpecs.length > 0 ? 
          <Select
            defaultValue={state.videoSpecs[state.canvasId-1]}
            styles={customStyles}
            options={state.videoSpecs}
            onChange={handleChange}
          /> 
          : null
        }              
      </FormGroup>
      <hr/>
      <div style={{display: "flex","justifyContent": "space-between", "alignItems":"center"}}>
        <div>Background color</div>
        <ColorPicker 
          color={state.backgroundColor ? state.backgroundColor : state.defaultBackgroundColor}
          handleColorChange={handleColorChanged}       
          tagName="backgroundColor"
          width="40px"
          height="16px"
          displayColorPicker={displayColorPicker}
          setDisplayColorPicker={setDisplayColorPicker}
        />
      </div>
      <hr/>
    
      <FormGroup>
        <Label for="clipStarts">Starts</Label>
        <Input 
          type="text" 
          name="starts" 
          id="clipFromText" 
          placeholder="hh:mm:ss"
          maxLength="8"
          value={state.clipFromText}
          onChange={handleInputChange}
        />
      </FormGroup>
    
      <FormGroup>
        <Label for="clipEnds">Ends</Label>
        <Input             
          type="text" 
          name="ends" 
          id="clipEndText" 
          placeholder="hh:mm:ss"
          maxLength="8"
          value={state.clipEndText}
          onChange={handleInputChange}
        />
      </FormGroup>
        
      
    </Form>     
  )
}

export default PanelProjectTab;