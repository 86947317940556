import React, { useState, useContext }  from "react";
import {Row, Card, CardImg, CardText, CardBody,CardTitle, CardSubtitle, Button, Collapse} from 'reactstrap';
import justcastApi from '../api/justcast';
import {Context as ModalContext} from '../context/ModalContext'

const CardList = ({feed, lists}) => {  
  if(lists) {
    return (
      lists.map((list, index) => {
        return (
          <div key={'list|'+index}>
            <h3>{list.title}</h3>
            <ul>
              {
                list.lists.map((item, itemIdx) => {
                  const listText = item.replace(/_RSS_FEED_/g, feed);
                  return (
                    <li key={`list|${index}|${itemIdx}`} dangerouslySetInnerHTML={{__html: listText}}/>
                  )
                })
              }
            </ul>
          </div>
        )
      })
    )    
  }
  return null;
}

const CardParagraphs = ({paragraphs}) => {
  if(paragraphs) {
    return (
      paragraphs.map((paragraph, index) => {
        return (
          <p key={`paragraphs|${index}`} dangerouslySetInnerHTML={{__html: paragraph}}/>
        )
      })
    )
  }
  return null;
}

const CardButton = ({feed, button}) => {
  if(button && feed) {
    let buttonURL = button.url;
    buttonURL = buttonURL.replace(/_RSS_FEED_/g, feed);
    return (
      <Button color="primary" href={buttonURL} target="_blank" >{button.label}</Button>
    )
  }
  return null;
}

const CardDescription = ({feed, lists, paragraphs, button}) => {

  return (
    <>
      <CardList feed={feed} lists={lists} />
      <CardParagraphs paragraphs={paragraphs}/>
      <CardButton feed={feed} button={button}/>
    </>
  )
}

const PodcastNetwork = ({msBaseEndpoint, msEndpoint, show_id, title, networkIcon, feed, lists, paragraphs, button}) => {
  const [collapse, setCollapse] = useState(false);
  // add modal to print message
  const { add } = useContext(ModalContext);

  const handleSubmitClick = () => {
    justcastApi.get(`${msBaseEndpoint}/${show_id}/${msEndpoint}`)
    .then((res) => {
      // console.log(res.data)
      add({
        color: "primary", 
        title: 'Success',
        message: res.data.message, 
        isOpen: true,
        loading: false
      })        
    })
    .catch((err) => {
      console.log(err)
      add({
        color: "primary", 
        title: 'Error',
        message: "Error", 
        isOpen: true,
        loading: false
      })         
    })
  }

  const toggle = () => {
    if(msEndpoint) {
      handleSubmitClick();
    } else {
      setCollapse(!collapse)
    }    
  };

  return (
    <Card>
      <CardBody>
        <div className="mb-3">
          <Row className="align-items-center" onClick={toggle}>
            <div className="col-auto">
              <div className="avatar">
                <img src={networkIcon} alt={title} className="avatar-img rounded"/>
              </div>
            </div>
            <div className="col ml-n2">
              <h2 className="mb-1">{title}</h2>
            </div>
            <div className="col-auto d-none d-sm-block">
              <Button color="primary" size="sm">{`Submit to ${title}`}</Button>
            </div>
          </Row>
        </div>
        <Collapse isOpen={collapse}>
          <div className="mb-3">
            <CardDescription
              feed={feed}
              lists={lists}
              paragraphs={paragraphs}
              button={button}
            />
          </div>          
        </Collapse>
      </CardBody>
    </Card>
  )
}

export default PodcastNetwork;