import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../../api/justcast';
import {Context as MenuContext} from '../../context/MenuContext'
import EditForm from './EditForm';
import { Redirect } from 'react-router-dom'

const UpdateAd = (props) => {
  const {id} = props.match.params;
  const [title, setTitle] = useState("")
  const [attachmentSrc, setAttachmentSrc] = useState(null)
  const [attachmentFilename, setAttachmentFilename] = useState(null)
  const [description, setDescription] = useState("")
  const [isRedirect, setIsRedirect] = useState(false);
  // const [duration, setDuration] = useState(0)
  const { setDynamicContentMenu } = useContext(MenuContext);

  useEffect(() => {
    setDynamicContentMenu({
      title: 'New File',
      subtitle: "Dynamic Contents",
      currentPageId: 'dynamic-contents',
      currentTabId: "dynamic-contents"
    });

    justcastApi.get(`/v2/ad_contents/${id}.json`)
    .then((res) => {
      setTitle(res.data.title)
      setDescription(res.data.description)
      setAttachmentSrc(res.data.attachment_src)
      setAttachmentFilename(res.data.attachment_filename)
      setDynamicContentMenu({
        title: 'Edit File',
        subtitle: "Dynamic Contents",
        currentPageId: 'dynamic-contents',
        currentTabId: "dynamic-contents"
      });
    })
    .catch((err) => {

    })
  }, [id])

  const handleSave = (event) => {
    const formData = new FormData();
    if(event.title !== title) {
      formData.append("ad_content[title]", event.title);
    }
    if(event.duration) {
      formData.append("ad_content[duration]", event.duration);
    }
    if(description !== event.description) {
      formData.append("ad_content[description]", event.description);
    }
    if(event.file[0]) {
      formData.append("file", event.file[0])
    }

    justcastApi.put(`/v2/ad_contents/${id}.json`, formData)
    .then((res) => {
      setIsRedirect(true);
    })
    .catch((err) => {

    })
  }

  if(isRedirect) {
    return <Redirect to='/dynamic-contents'/>
  }

  return (
    <div className="container-fluid">
      <EditForm
        setAttachmentSrc={setAttachmentSrc}
        attachmentSrc={attachmentSrc}
        title={title}
        description={description}
        attachmentFilename={attachmentFilename}
        handleSave={handleSave}
      />
    </div>
  )
}

export default UpdateAd