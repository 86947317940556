import React from "react";
import BoostrapTable from './BoostrapTable'
import { Button } from 'reactstrap';
import { PlainTextCell} from '../libs/TableCellFormat'

const WidgetAllowSiteSetting = ({
  blockWidget,
  allowSites,
  handleAllowSiteDeleted
}) => {

  const DeleteCell = ({cell}) => {
    return (
      <Button color="danger" size="sm" onClick={() => {
        handleAllowSiteDeleted(cell.value)
      }}>Delete</Button>
    )
  }

  const columns = [
    {
      Header: 'Security Code',
      accessor: 'url_address',
      Cell: PlainTextCell
    },
    {
      Header: "",
      accessor: 'id',
      Cell: DeleteCell
    },  
  ];

  if( blockWidget ) {
    return (
      <BoostrapTable data={allowSites ? allowSites : []} columns={columns}/>
    )
  }
  return null;
}

export default WidgetAllowSiteSetting