import React from "react";

const PanelAddNewItem = ({ title , handleClick}) => {
  return (
    <div 
      onClick={handleClick}
      style={{"display": "flex", "alignItems":"center", "alignItems": "center", "cursor": "pointer"}}>
      <span className="fe fe-plus-circle" style={{"fontSize": 24}}/>
      <span style={{"marginLeft": "10px"}}>{title}</span>
    </div>
  )
}
  
export default PanelAddNewItem;