import React from "react";
import {Card, CardHeader, CardBody,Row, Col} from 'reactstrap';
import BoostrapTable from '../components/BoostrapTable'

//[{Header: "Name", accessor: "name"}, {Header: "#", accessor: "count"}]
const TableCard = ({title, data, columns}) => {
  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <div className="col">
            <h4 className="card-header-title">
              {title}
            </h4>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="table-responsive mb-0">
          <BoostrapTable data={data} columns={columns}/>
        </div>
      </CardBody>
    </Card>
  )
}
  
  export default TableCard