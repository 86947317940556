import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const IconSpan = ({sortingText}) => {
  if(sortingText === 'Asc') {
    return <span className="fe fe-arrow-up"/>
  } else if (sortingText === 'Desc') {
    return <span className="fe fe-arrow-down"/>
  }
  return null;
}

const SortingDropdownButton = ({label, name, value, handleClick}) => {
  
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
      <DropdownToggle caret color="warning" outline={value ? false : true}>
        <IconSpan sortingText={value}/> {label}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => {
          handleClick({name, value: null})
        }}>No sorting</DropdownItem>        
        <DropdownItem onClick={() => {
          handleClick({name, value: "Asc"})
        }}>Asc</DropdownItem>
        <DropdownItem onClick={() => {
          handleClick({name, value: "Desc"})
        }}>Desc</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default SortingDropdownButton;