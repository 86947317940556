import React from "react";
import { Form, FormGroup, Input, Button, Label, CustomInput } from 'reactstrap';
import FormMutedText from './FormMutedText'

const WarningMessage = ({title, text}) => {
  if(text) {
    return (
      <div className="col-12 col-md-6">
        <div className="card bg-light border">
          <div className="card-body">        
            <h4 className="mb-2">
              <i className="fe fe-alert-triangle"></i> {title ? title : "Warning"}
            </h4>
            <p className="small text-muted mb-0">
              {text}
            </p>
          </div>
        </div>    
      </div>
    )    
  }
  return null;
}

const FormGroupInputSwitch = ({
  inputLabel, 
  inputType, 
  inputName,
  messageTitle,
  warningMessage, 
  inputValue, 
  inputClassName, 
  handleInputChange,
  inputCSSId,
  mutedText
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <FormGroup>
          <Label className="mb-1">{inputLabel}</Label>
          <FormMutedText text={mutedText}/>
          <div>
            <CustomInput 
              type={inputType}
              id={inputCSSId}
              onChange={handleInputChange}
              name={inputName}
              checked={inputValue ? inputValue : false}
              />
          </div>
        </FormGroup>
      </div>
      <WarningMessage title={messageTitle} text={warningMessage}/>
    </div>    
  )
}

export default FormGroupInputSwitch;