import React, {useState, useRef, useReducer} from 'react'
import ReactPlayer from 'react-player'
import { Play, Pause, RotateCcw, RotateCw } from 'react-feather';
import { binarySearch } from '../../libs/transcriptHtmlNodeParser'
import TranscriptContainer from './TranscriptContainer'
import WordEditingModal from './WordEditingModal'
import SpeakerNameModal from './SpeakerNameModal'

const buttonStyle = {
  height: "40px",
  width: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "100%",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "#53a0e4",
  backgroundColor: "#53a0e4",
  cursor: "pointer",
  flexShrink: 0
}

const rotateButtonStyle = {
  height: "40px",
  width: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  flexShrink: 0
}

const containerStyle = {
  marginTop: "10px",
  display: "flex",
  gap: "10px",
  flexDirection: "column",
  alignContent: "space-between"
}

export default function AudioPreviewPlayer(props) {
  const { url, transcript, handleUpdateWord, handleDeleteWord, handleUpdateSpeaker } = props;
  const playerRef = useRef(null);
  const currentWordIdxRef = useRef(null);
  const [editWord, setEditWord] = useState("");
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [durationStr, setDurationStr] = useState(null);
  const [played, setPlayed] = useState(null);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [onSeeking, setOnSeeking] = useState(true);
  const { wordsId, wordsStartTime } = transcript;
  const [openModal, setOpenModal] = useState(false);
  const [speakerModal, updateSpeakerModal] = useReducer((prev, next) => {
    return {...prev, ...next}
  }, {
    open: false, speaker_name: "", sentence_speaker_code: null, minmima_index: null
  })

  const toggleModal = () => {
    setOpenModal(!openModal);
  }

  const toggleSpeakerModal = () => {
    updateSpeakerModal({open: !speakerModal.open, speaker_name: "", sentence_speaker_code: ""})
  }

  const handlePlayPauseClick = () => {
    setPlaying(!playing)
  }

  const handleBackward = () => {
    const seekTo = Math.max(playedSeconds-5, 0)
    playerRef.current.seekTo(seekTo, 'seconds')
  }

  const handleForward = () => {
    const seekTo = Math.min(playedSeconds+5, duration)
    playerRef.current.seekTo(seekTo, 'seconds')
  }

  const handleUpdateWordId = () => {
    if(playing) {
      const id = binarySearch(wordsStartTime, playerRef.current.getCurrentTime())
      if(id) {
        const wordId = wordsId[id];
        if(wordId) {
          const prevWord = document.getElementsByClassName('jc-transcript-editor-active-word');
          if(prevWord && prevWord.length > 0) {
            prevWord[0].classList.remove('jc-transcript-editor-active-word');
          }
          const childNode = document.getElementById(`i${wordId}`);
          childNode.classList.add('jc-transcript-editor-active-word');
        }
      }
    }
  }

  const wordSave = () => {
    const wordIdx = currentWordIdxRef.current;
    if(wordIdx >= 0 && wordsId[wordIdx] && editWord) {
      handleUpdateWord(wordsId[wordIdx], editWord)
      setOpenModal(false);
    }
  }

  const deleteWord = () => {
    const wordIdx = currentWordIdxRef.current;
    if(wordIdx >= 0 && wordsId[wordIdx]) {
      handleDeleteWord(wordsId[wordIdx])
      setOpenModal(false);
    }
  }

  const handlEditWordChange = (event) => {
    setEditWord(event.target.value);
  }

  const handleDoubleClickedWord = (e) => {
    if(e.target.className.indexOf("jc-transcript-word") >= 0) {
      const spanId = e.target.id?.slice(1);
      if(spanId) {
        const wordIdx = wordsId.indexOf(Number(spanId))
        if(wordIdx >= 0) {
          currentWordIdxRef.current = wordIdx;
          setEditWord(e.target.textContent);
          setPlaying(false);
          setOpenModal(true);
        }
      }
    }
  }

  const jumpToWordTime = () => {
    if(openModal) {
      const wordIdx = currentWordIdxRef.current;
      if(wordIdx !== null && wordIdx !== undefined && wordIdx >= 0) {
        const wordStartTime = wordsStartTime[wordIdx];
        playerRef.current.seekTo(wordStartTime, 'seconds');
        if(!playing) {
          setPlaying(true);
        }
        setOpenModal(false);
      }
    }
  }

  const jumpToWord = (playedSeconds) => {
    const id = binarySearch(wordsStartTime, playedSeconds)
    const wordId = wordsId[id];
    const childNode = document.getElementById(`i${wordId}`);
    childNode.scrollIntoView({ behavior: 'smooth' });
  }

  const playSentence = (seconds) => {
    playerRef.current.seekTo(seconds, 'seconds')
    if(!playing) {
      setPlaying(true);
    }
  }

  return (
    <div>
      <SpeakerNameModal
        speakerModal={speakerModal}
        toggleModal={toggleSpeakerModal}
        handleSave={handleUpdateSpeaker}
      />
      <WordEditingModal
        openModal={openModal}
        toggleModal={toggleModal}
        jumpToWordTime={jumpToWordTime}
        deleteWord={deleteWord}
        wordSave={wordSave}
        editWord={editWord}
        handlEditWordChange={handlEditWordChange}
      />
      <TranscriptContainer
        handleDoubleClickedWord={handleDoubleClickedWord}
        paragraphs={transcript.paragraphs}
        playSentence={playSentence}
        updateSpeakerModal={updateSpeakerModal}
      >
        {props.children}
      </TranscriptContainer>
      <div className='jc-editor-preview-player'>
        <input
          className='jc-editor-player-slider'
          type="range"
          min={0}
          max={duration}
          step={0.01}
          value={playedSeconds}
          onChange={(e) => {
            setPlayedSeconds(Number(e.target.value));
          }}
          onPointerDown={() => {
            setOnSeeking(false);
          }}
          onPointerUp={() => {
            setOnSeeking(true)
            playerRef.current.seekTo(playedSeconds, 'seconds')
            jumpToWord(playedSeconds)
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            background: `linear-gradient(90deg, #53a0e4 ${duration <= 1 ? 0 : playedSeconds * 100 / duration}%,  #d6f6fa ${duration <= 1 ? 0 : playedSeconds * 100 / duration}% )`
          }}
        />
        <div style={containerStyle}>
          <div>
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: '4px',
            }} className="text-muted">
              <small>{played}</small>
              <span>/</span>
              <small>{durationStr}</small>
            </div>
          </div>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 20px 20px 20px"
          }}>
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "20px"
            }}>
              <div style={rotateButtonStyle}
                name="backward 5 seconds"
                onClick={handleBackward}
              >
                <RotateCcw/>
              </div>
              <div
                style={buttonStyle}
                name="play button"
                onClick={handlePlayPauseClick}
              >
                {playing ? <Pause/> : <Play/> }
              </div>
              <div style={rotateButtonStyle}
                name="forward 5 seconds"
                onClick={handleForward}
              >
                <RotateCw/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactPlayer
        ref={playerRef}
        url={url}
        controls={false}
        width="0%"
        height="0%"
        onDuration={(res) => {
          const date = new Date(null);
          date.setSeconds(res);
          setDuration(res);
          const durationTimeStr = date.toISOString().slice(11, 19);
          setDurationStr(durationTimeStr)
        }}
        progressInterval={50}
        onProgress={(res) => {
          if(onSeeking) {
            const date = new Date(null);
            date.setSeconds(res.playedSeconds);
            setPlayedSeconds(res.playedSeconds)
            setPlayed(date.toISOString().slice(11, 19))
            handleUpdateWordId()
          }
        }}
        onEnded={() => {
          setPlaying(false);
        }}
        playing={playing}
      />
    </div>
  )
}
