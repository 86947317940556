import React from 'react'
import { useAtom } from 'jotai'
import { setSelectedOrdersAtom, setChangeOptionAtom, handleDnDSelectedOrdersAtom} from './episodesSortingAtom'
import { DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

export default function SortablePanel() {
  const [selectedOptions] = useAtom(setSelectedOrdersAtom);
  const [, setChangeOption] = useAtom(setChangeOptionAtom);
  const [, handleDropEnd] = useAtom(handleDnDSelectedOrdersAtom);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    handleDropEnd(result)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="selected-options">
        {
          (provided, snapshot) => (
            <div
              className="options list-group list-group-flush"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                // backgroundColor: snapshot.isDraggingOver ? "lightblue" : 'lightgrey'
              }}
            >
              {
                selectedOptions.map((option, index) => {
                  return (
                    <Draggable isDragDisabled={false} key={`ao-${index+1}`} draggableId={`ao-${index+1}`} index={index}>
                      {(provided) => (
                        <div className="list-group-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style
                          }}
                        >
                          <div style={{
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>
                            <div style={{display: "flex", gap: "20px", alignItems: "center"}}>
                              <div style={{width: "110px"}}>{option.label}</div>
                              <div style={{display: "flex", gap: "2px"}}>
                                <div className={`btn btn-${option.ordering === "ASC" ? "warning" : "outline-warning"} btn-sm`} onClick={() => {
                                  setChangeOption({...option, ordering: "ASC"})
                                }}>ASC</div>
                                <div className={`btn btn-${option.ordering === "DESC" ? "warning" : "outline-warning"} btn-sm`} onClick={() => {
                                  setChangeOption({...option, ordering: "DESC"})
                                }}>DESC</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                })
              }
              {provided.placeholder}
            </div>
          )
        }
      </Droppable>
    </DragDropContext>
  )
}
