import React from 'react';
import Select from 'react-select'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
  })
}

const options = [{value: true, id: 1, label: "Draft"}, {value: false, id: 2, label: "Publish"}]

export default function PublishOptions({
  value,
  handleChange
}) {
  return (
    <Select
      value={value}
      options={options}
      styles={customStyles}
      onChange={handleChange}
    />
  )
}
