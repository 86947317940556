import React from 'react';

const ModalButton = ({name, handleClick, className, visiable}) => {
  if(visiable) {
    return (
      <div className={className} style={{marginRight: 10}} onClick={handleClick}>
        {name}
      </div>
    )
  }
  return null;
}

export default ModalButton;