import React, {useEffect, useState, useContext} from "react";
import { useAtom } from 'jotai'
import {Redirect} from 'react-router-dom'
import justcastApi from '../api/justcast';
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AuthContext} from '../context/AuthContext'
import { Spinner, Card, CardBody, CardHeader, Button } from 'reactstrap';
import {AvaiableOptions, SortablePanel, setAvaiableOptionsAtom, setSelectedOrdersAtom} from '../components/no-code-sorting'

export default function EpisodesSorting(props) {
  const screenContext = useContext(ScreenContext);
  const {setSortingMenu } = useContext(MenuContext);
  const {add} = useContext(ModalContext);
  const {state} = useContext(AuthContext)
  const [, setAvaiableOptions] = useAtom(setAvaiableOptionsAtom)
  const [selectedOrders, setSelectedOrders] = useAtom(setSelectedOrdersAtom)

  const id = props.match.params.id;
  const [denied, setDenied] = useState(false);

  const fetchEpisodes = () => {
    screenContext.startFetching();
    let endpointUrl = `/v2/shows/${id}/show_order_methods.json`;

    justcastApi.get(endpointUrl)
    .then((res) => {
      // setEpisodes(res.data.audioposts);
      screenContext.finishFetching();
      // console.log(res.data.show.episodes_sorting)
      setSortingMenu({
        title: 'Episode Sorting',
        subtitle: res.data.show.podcast_title,
        currentPageId: 'episode_sorting',
        currentTabId: 'episode_sorting',
        showId: id,
        landingPageUrl: res.data.show.landing_page_url,
        websiteUrl: res.data.show.player_page_link,
        rssFeed: res.data.show.rss_feed,
        passwordProtected: res.data.show.is_password_protected,
        advancedPrivate: res.data.show.is_advanced_private_feed,
        isPrivate: res.data.show.is_private,
        isPrivateShow: res.data.show.is_private_show,
        isDirectUpload: res.data.show.is_direct_upload,
        skill_podcast_website: res.data.show.skill_podcast_website,
        allow_dropbox_subfolders: state.allow_dropbox_subfolders
      });

      setAvaiableOptions(res.data.show_order_methods)
      setSelectedOrders(res.data.show_order_methods)
    })
    .catch((err) => {
      screenContext.finishFetching();
      // add notifcation
      if(err.response.status === 401) {
        setDenied(true)
      }
    })
  }

  const handleUpdateSort = () => {
    const new_data = selectedOrders.filter((x) => x.selected === true).map((x, index) => {
      return {
        name: x['label'],
        target_name: x['name'],
        direction: x['ordering'],
        show_id: id,
        position: index + 1
      }
    })
    justcastApi.post(`/v2/shows/${id}/show_order_methods.json`, {
      show_order_methods: new_data
    })
    .then((res) => {

      add({
        color: "primary",
        title: 'Success',
        message: "Updated the episodes ordering.",
        isOpen: true,
      })
    })
    .catch((err) => {
      add({
        color: "primary",
        title: 'Something went wrong',
        message: "We faild to updat the episodes ordering.",
        isOpen: true,
      })
    })
  }

  useEffect(() => {
    fetchEpisodes()
  }, [id])

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Card>
            <CardBody>
              <AvaiableOptions/>
            </CardBody>
          </Card>
          <div className="row">
            <div className="col-12">
              <Card>
                <CardHeader>
                  <div className="row align-items-center">
                    <div className="col">
                      <h4 className="card-header-title">Episodes ordering on</h4>
                    </div>
                    <div className="col-auto">
                      {/* <Button size="sm" color="warning" onClick={handlePreview}>Preview</Button> {" "} */}
                      <Button color="primary" onClick={handleUpdateSort}>Save</Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <SortablePanel/>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
