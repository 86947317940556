import React, {useContext, useState, useEffect} from "react";
import { Link, Redirect } from "react-router-dom";
import { Card, CardBody, Button, CustomInput, Row, Alert, CardHeader, Form, FormGroup} from 'reactstrap';
import justcastApi from '../api/justcast';
import FormGroupInput from '../components/FormGroupInput';
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as MenuContext} from '../context/MenuContext'
import { PremiumSellerPrice } from '../components/private-podcast'

const PrivatePodcastTipSettings = (props) => {
  const showId = props.match.params.show_id;

  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const {addWithTimeout} = useContext(AlertContext);

  const [show, setShow] = useState({});
  const [denied, setDenied] = useState(false);
  const [prices, setPrices] = useState([]);
  const [dripDays, setDripDays] = useState(null);
  const [expiresDays, setExpiresDays] = useState(null);

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${showId}/tip_jar_prices`)
    .then((res) => {

      const {drip_separate_days, expires_days} = res.data.show
      setDripDays(drip_separate_days);
      setShow(res.data.show);
      const _prices = res.data.prices.filter((price) => price.id && price.active === true).map((price) => {
        const unit_amount = price['unit_amount'];
        const unit_amount_text = unit_amount ? (Math.round(unit_amount) / 100).toFixed(2) : "0.00";
        return {...price, unit_amount_text }
      })

      setPrices(_prices);
      screenContext.finishFetching();
      if(res.data.show.is_advanced_private_feed !== true) {
        setDenied(true)
      }
      menuContext.setPrivateSubscribersrMenu({
        title: 'Settings',
        subtitle: res.data.show.name,
        currentPageId: 'subscribers',
        currentTabId: 'private-settings',
        showId: showId,
        landingPageUrl: res.data.show.landing_page_url,
        websiteUrl: res.data.show.player_page_link,
        rssFeed: res.data.show.rss_feed,
        passwordProtected: res.data.show.is_password_protected,
        advancedPrivate: res.data.show.is_advanced_private_feed,
        isPrivate: res.data.show.is_private,
        isPrivateShow: res.data.show.is_private_show
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true)
    })
  }, [showId])

  const handleOwnIntroToggled = () => {
    const {own_intro} = show;
    setShow({...show, own_intro: !own_intro});
  }

  const handleEmailNotificationToggled = () => {
    const {new_private_member_notification} = show;
    setShow({...show, new_private_member_notification: !new_private_member_notification});
  }

  const handleAcceptedInviteNotificationToggled = () => {
    const {private_member_subscribed_notification} = show;
    setShow({...show, private_member_subscribed_notification: !private_member_subscribed_notification});
  }

  const toggleSkipPrivateFeedReview = () => {
    const {skip_private_feed_review} = show;
    setShow({...show, skip_private_feed_review: !skip_private_feed_review})
  }

  const toggleRequiresFullName = () => {
    const {requires_full_name} = show;
    setShow({...show, requires_full_name: !requires_full_name})
  }

  const handleUpdateShow = () => {
    justcastApi.put(`/v1/shows/${showId}`, show)
    .then((res) => {
      // handle success notification
      addWithTimeout({color: 'warning', message: "Updated", isOpen: true, timeout: 1000})
    })
    .catch((err) => {
      // handle failed
    })
  }

  const handleSavePrices = () => {
    justcastApi.put(`/v1/shows/${showId}/batch_tip_jar_is_premium_subscription`, {
      prices
    })
    .then((res) => {
      addWithTimeout({color: 'warning', message: "Updated", isOpen: true, timeout: 1000})
    })
    .catch((err) => {
      console.log(err);
    })
    // update prices to tipjar endpoint to handle the toggle changed
  }

  const handlePriceToggled = (event) => {
    const id = Number(event.target.name);
    const _prices = [...prices];
    _prices[id]['is_premium_subscription'] = !_prices[id]['is_premium_subscription'];
    setPrices(_prices);
  }

  const handleInputValueChange = ({key, value}) => {
    if(key === "drip_separate_days") {
      setDripDays(value);
    } else if (key === 'expires_days') {
      setExpiresDays(value)
    }
  }

  const updateShow = (key) => {
    const data = {};
    if(key === "drip_separate_days") {
      data[key] = dripDays;
    } else if (key === "expires_days") {
      data[key] = expiresDays;
    }

    justcastApi.put(`/v2/shows/${showId}`, data)
    .then((res) => {
      // handle success notification
      addWithTimeout({color: 'warning', message: "Updated", isOpen: true, timeout: 1000})
    })
    .catch((err) => {
      // handle failed
    })
  }

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <>
      <div className="container-fluid">
        {
          prices.length > 0 && (
            <>
              <hr/>
              <div className="row align-items-center" style={{margin: "20px 0 20px 0"}}>
                <div className="col">
                  <h2 className="card-header-title">Unlock private feed</h2>
                  <p>Automatically insert new patron as subscribers of your private podcast</p>
                </div>
                <div className="col-auto">
                  <Button size="sm" color="primary" onClick={handleSavePrices}>Save</Button>
                </div>
              </div>
              <Alert color="primary">
                Use the switch to pick the prices to automatically insert new patron as subscribers
              </Alert>
              {
                prices.map((price, idx) =>
                  <PremiumSellerPrice key={idx} idx={idx} price={price} handlePriceToggled={handlePriceToggled}/>
                )
              }
            </>
          )
        }
      </div>
    </>
  )
}

export default PrivatePodcastTipSettings;