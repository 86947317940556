import React from 'react'
import { Button } from 'reactstrap'

export default function ChooseFileButton({handleUpload, handleRemove}) {
  return (
    <>
      <Button size="sm" color="secondary" onClick={handleRemove}>Remove</Button> {" "}
      <Button size="sm" color="primary" onClick={handleUpload}>Save</Button>
    </>
  )
}