import React from "react";
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Row, Button, CardFooter } from 'reactstrap';

const ImagesList = ({images, deleteHandler, openModal}) => {
  return (
    <>
    {
      images.map((image) => 
        <Card key={image.id}>
          <CardBody>
            <Row className="align-items-center">
              <div className="col-auto">
                <div className="avatar">
                  <img src={image.src ? image.src : 'https://via.placeholder.com/150'} alt={image.title} className="avatar-img"/>
                </div>
              </div>
              <div className="col ml-n2">
                <h4 className="mb-1">{image.title}</h4>
                <p className="small text-muted mb-1">{image.label}</p>
              </div>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <div className="col-auto">
                <div className="btn btn-primary btn-sm" onClick={() => {
                  openModal(image);
                }}>
                  Add Photo
                </div>
              </div>
              {
                image.src ? 
                <div className="col-auto">
                  <Button size="sm" color="danger" onClick={() => {
                    deleteHandler(image);
                  }} >Remove</Button>
                </div> : null
              }
            </Row>
          </CardFooter>
        </Card>      
      )
    }
    </>
  )
}

export default ImagesList;