import React, {useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
// import {uploadHandler} from '../api/doUploader'

const Thumb = ({file, removeFile}) => {
  return (
    <li className="list-group-item px-0">
      <div className="row align-items-center">
        <div className="col-auto">
          <div className="avatar">
            <img
              className="avatar-img rounded"
              src={URL.createObjectURL(file)}
            />
          </div>
        </div>
        <div className="col ml-n3">
          <h4 className="mb-1">{file.path}</h4>
          {/* <p className="small text-muted mb-0">...</p> */}
        </div>
        <div className="col-auto">
          <div onClick={() => {
            removeFile([])
          }}><i className="fe fe-more-fe fe-x"></i></div>
        </div>
      </div>
    </li>
  )
}

const Thumbs = ({files, removeFile}) => {
  return (
    <div className="col-12 col-md-6">
      <div className="card bg-light border">
        <div className="card-body">
          <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
            {
              files.map(file => 
                <Thumb file={file} removeFile={removeFile} key={file.path}/>
              )
            }
          </ul>
        </div>
      </div>      
    </div>   
  )
}

const ImageUploaderErrors = ({errors}) => {
  return (
    <div className="col-12 col-md-6">
      <div className="card bg-light border">
        <div className="card-body">        
          <h4 className="mb-2">
            <i className="fe fe-alert-triangle"></i> Errors
          </h4>
          <ul>
            {
              errors.map((err) => 
                <li key={err}>{err}</li>
              )
            }
          </ul>
        </div>
      </div>    
    </div>
  ) 
}

const WarningMessage = ({text, files, errors, removeFile}) => {
  if(errors.length > 0) {
    return <ImageUploaderErrors errors={errors}/>
  } else if(files.length > 0) {
    return (
      <Thumbs files={files} removeFile={removeFile}/>
    )   
  } else if(text) {
    return (
      <div className="col-12 col-md-6">
        <div className="card bg-light border">
          <div className="card-body">        
            <h4 className="mb-2">
              <i className="fe fe-alert-triangle"></i> Warning
            </h4>
            <p className="small text-muted mb-0">
              {text}
            </p>
          </div>
        </div>    
      </div>
    )    
  }
  return null;
}


//https://github.com/react-dropzone/react-dropzone/issues/321
const imageOnDrop = (acceptedFiles, setArtwork, setFiles, setErrors, imageWidth, imageHeight, requirement, isSquare) => {
  //TODO: replace setFiles with setArtwork
  // exactly
  const errors = [];
  const img = new Image();
  img.onload = function(){
    if(imageWidth > 1 && img.width < imageWidth) {
      errors.push(`This image must be at least ${imageWidth} pixels wide.`);
    }
    if(imageHeight > 1 && img.height < imageHeight) {
      errors.push(`This image must be at least ${imageHeight} pixels tall.`);
    }
    
    if(isSquare && img.height !== img.width) {
      errors.push(`This image must be a square.`);
    }
    
    if(errors.length > 0) {
      // error hanlding...
      setErrors(errors)
      setFiles([]);
      setArtwork(null);
    } else {
      setErrors([])
      setFiles(acceptedFiles)
      setArtwork(acceptedFiles[0]);
    }
  }
  
  img.src = URL.createObjectURL(acceptedFiles[0])  
  

  //setFiles(acceptedFiles)
}

const ImageUploader = ({setArtwork, artworkWidth, artworkHeight, isSquare}) => {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  const imageWidth = artworkWidth ? artworkWidth : 1400;
  const imageHeight = artworkHeight ? artworkHeight : 1400;
  const requirement = (artworkWidth === 1 || isSquare === false )? 'at least' : 'exactly';

  const {acceptedFiles, rejectedFiles, getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: (acceptedFiles, rejectedFiles) => {
      if(rejectedFiles.length > 0) {
        
        const errors = ['Only *.jpeg and *.png images will be accepted'];
        rejectedFiles.forEach((file) => {
          errors.push(`Rejected file: ${file.path}`)
        })

        setArtwork(null)
        setFiles([]);
        setErrors(errors)
      }
      if(acceptedFiles.length > 0) {
        imageOnDrop(acceptedFiles, setArtwork, setFiles, setErrors, imageWidth, imageHeight, requirement, (isSquare === false ? false : true))
      }      
    }
  });

  // const onSubmit = () => {
  //   if(files.length > 0) {
  //     const file = files[0]; 
  //     uploadHandler(file)
  //     .then((res) => {
  //       console.log(res.data.path)
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  //   }    
  // }
  
  return (
    <>      
      <div className="row">
        <WarningMessage 
          text={`Please make sure your image size is ${requirement} ${imageWidth}px by ${imageHeight}px. (Only *.jpeg and *.png images will be accepted)`}
          files={files}
          removeFile={setFiles}
          errors={errors}/>
        <div className="col-12 col-md-6">        
          <div {...getRootProps({className: 'dropzone'})}>
            <div className="dz-default dz-message">
              <input {...getInputProps()}/>
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </div>        
        </div>
      </div>
    </>
  );
}

export default ImageUploader