import React from 'react'
import { useAtom } from 'jotai'
import { availableOptionsAtom, setChangeOptionAtom } from './episodesSortingAtom'

export default function AvaiableOptions() {

  const [avaiableOptions] = useAtom(availableOptionsAtom);
  const [, setChangeOption] = useAtom(setChangeOptionAtom);

  return (
    <div>
      <div style={{
        display: 'flex', gap: 10
      }} className='small-screen-flex'>
        {
          avaiableOptions.map((option, idx) => {
            const {selected, name, label, ordering} = option;
            return (
              <label key={name} className={`btn ${selected ? "btn-primary" : "btn-secondary"}`} onClick={() => {
                setChangeOption({name, label, ordering, selected: !selected});
              }}>
                {selected ? <i className="fe fe-check-circle"/> : <i className="fe fe-circle"/>} {label}
              </label>
            )
          })
        }
      </div>
    </div>
  )
}
