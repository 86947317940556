import createDataContext from './createDataContext';

const alertReducer = (state, action) => {
  switch(action.type) {
    case 'add':
      return {...action.payload}
    case 'reset': 
      return {...action.payload}
    default:
      return state
  }
}

const add = dispatch => {
  return ({color, title, message, isOpen}) => {
    dispatch({type: 'add', payload: {color, title, message, isOpen}})
  }
}

const addWithTimeout = dispatch => {
  return ({color, title, message, isOpen, timeout}) => {
    dispatch({type: 'add', payload: {color, title, message, isOpen}})

    setTimeout(() => {
      dispatch({type: 'reset', payload: {color: null, title: null, message: null, isOpen: false}})
    }, timeout);
  }
}

const reset = dispatch => {
  return () => {
    dispatch({type: 'reset', payload: {color: null, title: null, message: null, isOpen: false}})
  }
}

export const {Provider, Context} = createDataContext(
  alertReducer,
  {
    add,
    reset,
    addWithTimeout
  },
  {
    color: null,
    title: null,
    message: null,
    isOpen: false
  }
)

export default {}