import React, {useEffect, useState, useContext} from "react";
import { Link, Redirect } from "react-router-dom";
import justcastApi from '../api/justcast';
import UpdateNotification from '../components/UpdateNotification';
import {MainForm, ValueRecipients, AddValueRecipient} from '../components/value-to-value';
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AuthContext} from '../context/AuthContext'

import { Spinner, Card, CardBody } from 'reactstrap';

const ShowValueForValue = (props) => {
  const id = props.match.params.id;
  const add_wallet = props.location.search;
  const {add} = useContext(ModalContext);
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const authContext = useContext(AuthContext);
  const {createAlbyConnection} = authContext
  const {alby_wallet_connections} = authContext.state;
  const [loaded, setLoaded] = useState(false);

  const [showName, setShowName] = useState('');
  const [items, setItems] = useState([]);
  const [valueSettings, setValueSettings] = useState({}) 

  const addAlbyToItems = (alby_wallet) => {
    const {keysend_custom_key, keysend_custom_value, keysend_pubkey, lightning_address} = alby_wallet;
    const newItem = {
      name: lightning_address,
      value_type:"node",
      address: keysend_pubkey,
      custom_key: keysend_custom_key,
      custom_value: keysend_custom_value,
      fee: 0,
      split: 10
    }
    const newItems = [newItem, ...items];
    setItems(newItems)
  }

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}/show_recipients`)
    .then((res) => {
      const name = res.data.show.podcast_title;

      setShowName(name);      
      setItems(res.data.value_recipients);

      setValueSettings({
        value_type: res.data.show.value_type,
        value_method: res.data.show.value_method,
        value_suggested_amount: res.data.show.value_suggested_amount
      })
      screenContext.finishFetching();
      setLoaded(true)

      menuContext.setSettingsMenus({
        title: 'Value for Value',
        subtitle: name,
        currentPageId: 'settings',
        currentTabId: 'value_for_value_settings',
        showId: id,
        landingPageUrl: res.data.show.landing_page_url,
        websiteUrl: res.data.show.player_page_link,
        rssFeed: res.data.show.rss_feed,
        passwordProtected: res.data.show.is_password_protected,
        advancedPrivate: res.data.show.is_advanced_private_feed,
        isPrivate: res.data.show.is_private,
        isPrivateShow: res.data.show.is_private_show,
        skill_podcast_website: res.data.show.skill_podcast_website     
      });      
    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
      // setDenied(true)
    })
  },[id])

  useEffect(() => {
    if(loaded && add_wallet === '?add_wallet=alby') {
      handleAblyClick();
    }
  }, [loaded])

  const handleInputChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setValueSettings({...valueSettings, [name]: value})
  }

  const handleDeleteRecipient = (idx) => {
    const newItems = [...items];
    const item_id = newItems[idx]['id'];
    newItems.splice(idx, 1)
    setItems(newItems)
    if(item_id) {
      justcastApi.delete(`/v1/shows/${id}/show_recipients/${item_id}`)
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    }    
  }

  const handleAddNewRecipient = () => {
    const newItems = [...items];
    newItems.push({
      name: "",
      value_type:"",
      address: "",
      fee: 0,
      split: "10"
    })

    setItems(newItems)
  }

  const handleRecipientChange = (data) => {
    const {name, value, idx} = data;
    const newItems = [...items];
    newItems[idx][name] = value;
    setItems(newItems)
  }

  const handleSave = () => {
    const data = {...valueSettings, items}
    justcastApi.post(`/v1/shows/${id}/create_many_recipients`, data)
    .then((res) => {
      setItems(res.data.value_recipients);      
      add({
        color: "primary", 
        title: 'Update Value for Value',
        message: 'Updated settings and recipients', 
        isOpen: true,
        loading: false
      })  
    })
    .catch((err) => {
      add({
        color: "primary", 
        title: 'Error:',
        message: err.response.data.message, 
        isOpen: true,
        loading: false
      })      
    })
  }

  const handleAblyClick = () => {
    if(alby_wallet_connections.length > 0) {
      add({
        color: "primary", 
        title: 'Ably',
        message: 'Getting wallet data from Ably', 
        isOpen: true,
        loading: true
      });
            
      justcastApi.get(`/v1/alby_connections/value4value`)
      .then((res) => {        
        addAlbyToItems(res.data);
        add({
          color: "primary", 
          title: 'Ably',
          message: 'Getting wallet data from Ably', 
          isOpen: false,
          loading: false
        });
      })
      .catch((err) => {
        console.log(err)
        add({
          color: "primary", 
          title: 'Ably',
          message: 'Getting wallet data from Ably', 
          isOpen: false,
          loading: false
        });
      })
    } else {
      createAlbyConnection(`show_id=${id}`)
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> : 
              <MainForm
                value_type={valueSettings.value_type}
                value_method={valueSettings.value_method}
                value_suggested_amount={valueSettings.value_suggested_amount}
                handleChange={handleInputChange}
              >
                <div style={{marginBottom: "20px"}}>
                  <AddValueRecipient handleAddClick={handleAddNewRecipient} handleAblyClick={handleAblyClick}/>
                  <ValueRecipients 
                    items={items}
                    handleChange={handleRecipientChange}
                    handleDelete={handleDeleteRecipient}
                  />
                  <div className="btn btn-block btn-primary" onClick={handleSave}>Save change</div>                  
                </div>
              </MainForm>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ShowValueForValue