import React from "react";
import {ButtonGroup, Button} from 'reactstrap'
// import {Link} from 'react-router-dom'

const CloudDriveCardButton = (props) => {
  const {isActive, handleOnClick, id} = props;

  if(isActive) {
    return (
      <ButtonGroup>
        <Button name="sync" id={id} size="sm" color="primary" onClick={handleOnClick}>Sync</Button>
        {/* <Button name="reconnect" id={id} size="sm" color="warning" onClick={handleOnClick}>Reconnect</Button> */}
        <Button name="settings" id={id} size="sm" color="warning" onClick={handleOnClick}>Settings</Button>
        <Button name="delete" id={id} size="sm" color="secondary" onClick={handleOnClick}>Delete</Button>
      </ButtonGroup>
    )
  }

  return (
    <ButtonGroup>
      <Button name="setup" id={id} size="sm" color="primary" onClick={handleOnClick}>Setup connection</Button>
    </ButtonGroup>
  )
}

export default CloudDriveCardButton;