import React, {useState, useContext, useEffect} from "react";
import justcastApi from '../../api/justcast';
import SimplePageHeader from './SimplePageHeader';
import {Context as AuthContext} from './../../context/AuthContext'
import newEpisode from '../../pages/new_episode'

const ShowPageHeader = ({
  showId, 
  isPrivate,   
  showSlug, 
  isAdvancedPrivateFeed,  
  currentPageName, 
  headerPreTitle, 
  headerPreTitleLink, 
  headerTitle, 
  fetchEpisodes, 
}) => {
  const authContext = useContext(AuthContext);
  const userState = authContext.state;
  const [show, setShow] = useState({});
  const [showPlayList, setShowPlayList] = useState(false);
  const [showDistribution, setShowDistribution] = useState(false);
  const [audiopostsCount, setAudiopostsCount] = useState(0);
  const [feedUrl, setFeedUrl] = useState('')
  const [landingpage, setLandingpage] = useState('')
  const [headerNavItems, setHeaderNavItems] = useState([]);
  const [headerLinks, setHeaderLinks] = useState([]);
  

  useEffect(() => {
    justcastApi.get(`/v1/shows/${showId}`)
    .then((res) => {  
      setShow(res.data);
      setAudiopostsCount(res.data.audioposts_count)
      // if(res.data.is_private === true || res.data.is_advanced_private_feed === true || res.data.is_password_protected === true){
      //   setShowPlayList(true);
      // }

      // if(res.data.is_private !== true && res.data.is_advanced_private_feed !== true && res.data.is_password_protected !== true) {
      //   setShowDistribution(true);
      // }

      setFeedUrl(`${process.env.REACT_APP_RSS_FEED_BASE_PATH}/shows/${res.data.slug}/audioposts.rss`)
      setLandingpage(`${process.env.REACT_APP_LANDING_PAGE}/shows/${res.data.slug}/audioposts`)

      // show skills
      setupShowSkills(res.data);

    })
    .catch((err) => {
      console.log(err);
    })
  }, [showId])


  const setupShowSkills = (data) => {
    let playListOn = false;
    let distributionOn = false;
    if(data.is_private === true || data.is_advanced_private_feed === true || data.is_password_protected === true) {
      playListOn = true;
    }

    if(data.is_private !== true && data.is_advanced_private_feed !== true && data.is_password_protected !== true) {
      distributionOn = true;
    }

    const _headerNavItems = [
      {
        text: 'Episodes',
        to: `/shows/${showId}/episodes`,
        className: `nav-link ${'episodes' === currentPageName ? 'active': ''}`
      },               
    ];
  
    if (data.skill_podcast_website && distributionOn) {
      _headerNavItems.push({
        text: 'Distributions',
        to: `/shows/${showId}/distributions`,
        className: `nav-link ${'distributions' === currentPageName ? 'active': ''}`
      })    
    }  
  
    if (data.skill_embedded_player ) {
      _headerNavItems.push(
        {
          text: 'Widgets',
          to: `/shows/${showId}/widgets`,
          className: `nav-link ${'widgets' === currentPageName ? 'active': ''}`
        }      
      )
    }
  
    if( playListOn ) {
      _headerNavItems.push({
        text: 'Playlist',
        to: `/shows/${showId}/playlist`,
        className: `nav-link ${'playlist' === currentPageName ? 'active': ''}`
      })
    }
  
    _headerNavItems.push({
      text: 'Links',
      to: `/shows/${showId}/links`,
      className: `nav-link ${'links' === currentPageName ? 'active': ''}`
    })  
  
    const urls = [
      {name: 'General', id: 'generate-setting', to: `/shows/${showId}`,},
      {name: 'Advanced', id: 'advance-setting', to: `/shows/${showId}/settings`},
      {name: 'Artwork', id: 'artwork', to: `/shows/${showId}/artwork`,},    
    ];
    
    if(data.skill_podcast_website && !data.is_private) {    
      urls.push({name: 'Header image', id: 'header-image', to: `/shows/${showId}/header_image`,})
    }
  
    if(data.skill_podcast_website && distributionOn ) {    
      urls.push({name: 'Social', id: 'Social-setting', to: `/shows/${showId}/social`})
      urls.push({name: 'Integrations', id: 'integrations', to: `/shows/${showId}/integrations`})
    }
    
    if(data.audioposts_count === 0) {
      urls.push({name: 'Import from Existing', id: 'import-from-rss', to: `/shows/${showId}/import_from_rss`})
    }    
  
    const _headerLinks = [
      {
        id: 'show-settings',
        type: 'dropdown',
        size: 'sm',
        color: 'danger',
        text: 'Settings',
        urls: urls
      },       
    ] 
  
  
    if(data.is_advanced_private_feed !== true) {
      const metricsUrl = [
        {name: 'Summary Metrics', id: 'summary-metrics', to: `/shows/${showId}/metrics`,},
        {name: 'Downloads Metrics', id: 'download-metrics', to: `/shows/${showId}/downloads`},    
      ]    
  
      _headerLinks.push({
        id: 'show-header-metrics',
        type: 'dropdown',
        size: 'sm',
        color: 'warning',
        text: 'Metrics',
        urls: metricsUrl
      })    
    }
  
    if(data.is_advanced_private_feed === true) {
      // urls.push({name: 'Private Subscribers', id: 'private-podcast-subscribers', to: `/shows/${showId}/subscribers`})
      _headerLinks.push({
        id: 'private-podcast-subscribers',
        className:"btn btn-sm btn-warning",
        text:'Private Subscribers',
        to: `/shows/${showId}/subscribers`,
        type: 'link'
      })
    }
  
    _headerLinks.push({
      type: 'simple_modal',
      id:'new-audiopost',      
      className:"btn btn-sm btn-primary rss-button",
      text:'New episode',
      message: '',
      htmlMessage: newEpisode(headerTitle),
      title: 'Upload an episode file',
      buttonHandler: fetchEpisodes
    }) 
    
    setHeaderLinks(_headerLinks)
    setHeaderNavItems(_headerNavItems)
  }

  // const feedUrl = `${process.env.REACT_APP_API_PROXY_SERVER_BASE_PATH}/shows/${showSlug}/audioposts.rss`
  // const landingpage = `${process.env.REACT_APP_LANDING_PAGE}/shows/${showSlug}/audioposts`  

  return (
    <SimplePageHeader
      headerPreTitle={headerPreTitle}
      headerTitle={headerTitle}
      headerPreTitleLink={headerPreTitleLink}
      headerNavItems={headerNavItems}
      headerLinks={headerLinks}
    />
  )
}

export default ShowPageHeader;