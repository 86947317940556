import React from "react";
import { Form, FormGroup, Input, Button, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import FormMutedText from './FormMutedText'

const SelectionOptions = ({options, inputLabel, optionLabel, optionId}) => {
  return (
    options.map((item) =>
      <option
      key={inputLabel+item[optionId].toString()}
      value={item[optionId]} >{item[optionLabel]}</option>
    )
  )
}

const FromGroupInputSelect = ({
  inputLabel,
  inputType,
  inputName,
  inputPlaceholder,
  inputValue,
  inputClassName,
  handleInputChange,
  options,
  optionLabel,
  optionId,
  mutedText,
  currentPage,
  totalPage,
  nextPageCallback,
}) => {

  const loadMore = () => {
    if(nextPageCallback) {
      nextPageCallback()
    }
  }
  return (
    <FormGroup>
      <Label>{inputLabel}</Label>
      <FormMutedText text={mutedText}/>
      <InputGroup>
        <Input
          className={inputClassName}
          type={inputType}
          name={inputName}
          value={inputValue ? inputValue : -10}
          placeholder={inputPlaceholder}
          onChange={handleInputChange}
        >
          <SelectionOptions options={options} inputLabel={inputLabel} optionLabel={optionLabel} optionId={optionId}/>
        </Input>
        {
          totalPage > 1 && <InputGroupAddon addonType="append"><Button color="secondary" disabled={currentPage === totalPage} onClick={loadMore}>Load more</Button></InputGroupAddon>
        }
      </InputGroup>
    </FormGroup>
  )
}

export default FromGroupInputSelect;