import React, {useContext, useState, useEffect} from "react";
import justcastApi from '../../api/justcast';
import {Context as ModalContext} from '../../context/ModalContext'
import {Context as MenuContext} from '../../context/MenuContext'
import { SelectProductCard, ConnectedProducts, ProductAddedModal } from '../../components/podcast-integrations'

export default function ShopifyPodcastConnection(props) {
  const showId = props.match.params.show_id;
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([])
  const [error, setError] = useState(null);
  const menuContext = useContext(MenuContext)
  const [connectedProducts, setConnectedProducts] = useState([]);
  const {add, reset} = useContext(ModalContext);
  const [addedProductId, setAddedProductId] = useState("");
  const [openAddedProductModal, setOpenAddedProductModal] = useState(false);

  const closeModal = () => {
    setOpenAddedProductModal(false)
    setAddedProductId("")
  }

  const openProductSettingModal = (productId) => {
    setAddedProductId(productId)
    setOpenAddedProductModal(true)
  }

  useEffect(() => {
    justcastApi.get(`/v2/shows/${showId}/shopify_product_shows.json`)
    .then((res) => {
      menuContext.setPrivateSubscribersrMenu({
        title: 'Shopify',
        subtitle: res.data.podcast.podcast_title,
        currentPageId: 'subscribers',
        currentTabId: 'podcast-connections',
        showId: showId,
        landingPageUrl: res.data.podcast.landing_page_url,
        websiteUrl: res.data.podcast.player_page_link,
        rssFeed: res.data.podcast.rss_feed,
        passwordProtected: res.data.podcast.is_password_protected,
        advancedPrivate: res.data.podcast.is_advanced_private_feed,
        isPrivate: res.data.podcast.is_private,
        isPrivateShow: res.data.podcast.is_private_show
      });

      setProducts(res.data.store_products)
      setConnectedProducts(res.data.conntected_products)
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      // console.log()
    })
  }, [])

  const handleAddItem = ({id, label}) => {
    justcastApi.post(`/v2/shows/${showId}/shopify_product_shows.json`, {
      shopify_product_id: id,
      shopify_product_name: label
    })
    .then((res) => {
      setConnectedProducts((prevProducts) => {
        return ([res.data, ...prevProducts])
      })

      setOpenAddedProductModal(true)
      setAddedProductId(res.data.shopify_product_id)
    })
    .catch((err) => {
      add({title: "Failed to connect", message: err.response.data.message, isOpen: true, loading: false})
    })
  }

  const handleDelete = (conntected_product_id) => {
    justcastApi.delete(`/v2/shows/${showId}/shopify_product_shows/${conntected_product_id}.json`)
    .then((res) => {
      setConnectedProducts((prevProducts) => {
        return prevProducts.filter((product) => product.id !== res.data.id )
      })
    })
    .catch((err) => {
      console.log(err)
    })
  }

  if(loading) {
    return null;
  }

  if(error) {
    return (
      <div className="container-fluid">
        <p>Something went wrong</p>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <ProductAddedModal
        toggle={closeModal}
        modal={openAddedProductModal}
        productId={addedProductId}
      />
      <div className="row">
        <div className="col-12">
          <SelectProductCard title="Shopify products" items={products} handleAddItem={handleAddItem} buttonLabel={"Link Product"}/>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ConnectedProducts
            title={"Connected Shopify Products"}
            items={connectedProducts}
            handleDelete={handleDelete}
            openProductSettingModal={openProductSettingModal}
          />
        </div>
      </div>
    </div>
  )
}
