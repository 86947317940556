import React, {useEffect, useState, useContext} from "react";
import { saveAs } from 'file-saver';
import {Mixpanel} from '../api/mixpanel'
import justcastApi from '../api/justcast';
import {Context as AuthContext} from '../context/AuthContext'
import ShowPageHeader from '../components/PageHeader/ShowPageHeader';
import html from './../pages/notion_page'

const ShowNotion = (props) => {
  const id = props.match.params.id;
  const [showName, setShowName] = useState('');
  const [item, setItem] = useState({});

  // Mixpanel
  const authContext = useContext(AuthContext);
  const uid = authContext.state.uid;  

  useEffect(() => {
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.name;
      setShowName(name);
      setItem(res.data);
      // Mixpanel
      Mixpanel.identify(uid);
      Mixpanel.track('Show Notion page loaded', {show_id: id});
    })
    .catch((err) => {
      console.log(err)
    })
  }, [id])

  const handleDownloadHTML = () => {
    const rssFeed = `${process.env.REACT_APP_RSS_FEED_BASE_PATH}/shows/${item.slug}/audioposts.rss`
    const widgetUrl = `${process.env.REACT_APP_API_PROXY_SERVER_BASE_PATH}/widget/${item.slug}/audioposts`
    
    const htmlPage = html({
      title: item.name,
      description: item.description ? item.description : item.name,
      rssFeed,
      widgetUrl,
      artwork: item.artwork_url,
      headerImage: item.header_img_url ? item.header_img_url : item.artwork_url,
      twitterLink: item.twitter_handle,
      facebookLink: item.facebook_page,
    });
    const blob = new Blob([htmlPage], {type: "text/html;charset=utf-8"});
    saveAs(blob, `${item.name}.html`);
  }

  return (
    <>
      <ShowPageHeader 
        showId={id}
        isPrivate={item.is_private}
        headerPreTitle={'Notion'}        
        headerTitle={showName}
        currentPageName={'notion'}
        showSlug={item.slug}
      />
      <div className="container">
        <h2>Create a podcast page on Notion</h2>
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="row">
              <div className="avatar avatar-sm">
                <div className="avatar-title font-size-lg bg-primary rounded-circle">
                  <i className="fe fe-download"></i>
                </div>
              </div>
              <div className="col ml-n2">
                <h5 className="mb-1">Download the HTML page</h5>                
                <div className="btn btn-primary btn-sm" onClick={handleDownloadHTML}>Download <span className="fe fe-download"></span></div>                
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="row">
              <div className="avatar avatar-sm">
                <div className="avatar-title font-size-lg bg-primary rounded-circle">
                  <i className="fe fe-file-text"></i>
                </div>
              </div>
              <div className="col ml-n2">
                <h5 className="mb-1">Notion</h5>
                <p>In <strong><a href="https://www.notion.com" target="_blank">notion</a></strong> go to <span className="badge badge-primary">Import</span> in the left-hand sidebar, choose <span>HTML</span>, and upload the <span className="badge badge-primary">{`${item.name}.html`}</span> file you downloaded.</p>
              </div>
            </div>            
          </div>
        </div>        
      </div>
    </>
  )
}

export default ShowNotion