import React, {useEffect, useState, useContext,} from "react";
import {Redirect} from 'react-router-dom'
import justcastApi from '../api/justcast';
import ShowForm from '../components/ShowForm';
import {Context as AuthContext} from '../context/AuthContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import { Spinner } from 'reactstrap';
import {Mixpanel} from '../api/mixpanel'

const Show = (props) => {
  const id = props.match.params.id;
  const {addWithTimeout} = useContext(AlertContext);
  const screenContext = useContext(ScreenContext);
  const menuContext = useContext(MenuContext);
  const [showName, setShowName] = useState('');
  const [item, setItem] = useState({});
  const [languages, setLanguages] = useState([]);
  const [categoryId, setCategoryId] = useState('')
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [explicitTypes, setExplicitTypes] = useState([]);
  const [artwork, setArtwork] = useState(null);
  const [denied, setDenied] = useState(false);
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState({location_name: null, location_geo: null})

  const itunesTypes = [{id: 'itune_type_id_episodic',mutedText: 'Specify episodic for stand-alone episodes or when you want your episodes presented and recommended last-to-first. This option also supports seasons, with the newest episode at the top.', label: 'Episodic (most common)', value: false}, {id: 'itune_type_id_serial', label: 'Serial', value: true, mutedText: 'Specify serial when you want your episodes presented and recommended first-to-last. This option supports narratives, storytelling, thematic, and multiple seasons.'}]
  // Mixpanel
  const authContext = useContext(AuthContext);
  const uid = authContext.state.uid;

  const handleInputValueChange = ({key, value}) => {
    setItem({...item, [key]: value})
  }

  const handleNameChange = ({key, value}) => {
    setItem({...item, [key]: value})
    setShowName(value)
  }

  const handleLocationChange = ({name, lat, lng}) => {
    const geo = `geo:${lat},${lng}`;
    setLocation({location_name: name, location_geo: geo})
  }

  const handleToggleChange = (event) => {
    const key = event.target.name;
    const value = event.target.checked;
    setItem({...item, [key]: value})
  }

  const handleSelectionChange = (event) => {
    let value = event.target.value;
    const key = event.target.name;
    if(key === 'category_id') {
      setCategoryId(value)
    }

    if(key === 'itunes_type_is_serial') {
      if(value === 'true') {
        value = true;
      } else {
        value = false;
      }
    }

    setItem({...item, [key]: value})
  }

  const handleQuillEditorChange = (value) => {
    // setItem({...item, description: value})
    setDescription(value);
  }

  const handleUpdate = (description) => {
    // window.scrollTo(0, 0);
    Mixpanel.identify(uid);
    Mixpanel.track('Show General Information Save clicked', {show_id: id});
    // setItem({...item, description})
    justcastApi.put(`/v1/shows/${id}`, {...item, ...location, description})
    .then((res) => {
      // get back updated obj
      const data = res.data;
      setItem(data);
      addWithTimeout({color: 'warning', message: `${showName} has been updated!`, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err)
      addWithTimeout({color: 'danger', message: 'Error', isOpen: true, timeout: 3000})
    })

  }

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;
      // setShowName(name);
      setItem(res.data);
      setShowName(name);
      setDescription(res.data.description);
      setLocation({location_geo: res.data.location_geo, location_name: res.data.location_name})

      menuContext.setSettingsMenus({
        title: 'Settings',
        subtitle: name,
        currentPageId: 'settings',
        currentTabId: 'settings',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website
      });

      // Mixpanel
      Mixpanel.identify(uid);
      Mixpanel.track('Show General Information loaded', {show_id: id});

      screenContext.finishFetching();

      if(res.data.category_id) {
        setCategoryId(res.data.category_id)
      } else {
        setCategoryId(1) // default category
      }
    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true)
      console.log(err)
    })

    justcastApi.get('/v1/languages')
    .then((res) => {
      setLanguages(res.data)
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get('/v1/categories')
    .then((res) => {
      setCategories(res.data)
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get('/v1/explicit_types')
    .then((res) => {
      setExplicitTypes(res.data)
    })
    .catch((err) => {
      console.log(err)
    })

  }, [id])

  useEffect(() => {
    if (categoryId) {
      justcastApi.get(`/v1/categories/${categoryId}/subcategories`)
      .then((res) => {
        setSubCategories(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [categoryId])

  if(denied) {
    return <Redirect to="/dashboard"/>
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> : <ShowForm
              name={showName}
              isDirectUpload={item.is_direct_upload}
              showNameEditable={item.show_name_editable ? item.show_name_editable : false}
              author={item.author}
              email={item.email}
              link={item.link}
              location_geo={location.location_geo}
              location_name={location.location_name}
              medium_type={item.medium_type}
              description={description}
              languageId={item.language_id}
              languages={languages}
              explicitTypeId={item.explicit_type_id}
              explicitTypes={explicitTypes}
              categoryId={item.category_id}
              categories={categories}
              subcategoryId={item.subcategory_id}
              itunesTypeIsSerial={item.itunes_type_is_serial}
              itunesComplete={item.itune_complete}
              itunesKeywords={item.itunes_keywords}
              locked={item.locked}
              itunesTypes={itunesTypes}
              copyright={item.copyright}
              subcategories={subCategories}
              handleQuillEditorChange={handleQuillEditorChange}
              handleSelectionChange={handleSelectionChange}
              handleToggleChange={handleToggleChange}
              handleUpdate={handleUpdate}
              handleInputValueChange={handleInputValueChange}
              handleNameChange={handleNameChange}
              handleLocationChange={handleLocationChange}
              />
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Show;