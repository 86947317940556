import React from "react";
import {Card, CardBody } from 'reactstrap';
import CardAvatar from './CardAvatar'
import { Link } from 'react-router-dom';


const CardItem = (props) => {

  const {
    title,
    description,
    href,
    icon,
  } = props;

  return (
    <Card>
      <CardBody>
        <div className="row align-items-center">
          <div className="col-auto">
            <CardAvatar avatar={icon}/>
          </div>
          <div className="col ml-n2">
            <h2 className="mb-1">{title}</h2>
            {description && <p className="small text-muted mb-1">{description}</p>}
          </div>
          <div className="col-auto">
            <Link color="primary" className="btn btn-sm btn-primary" to={href}>Settings</Link>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default CardItem;