import React from "react";
import { Button, Card, CardTitle, CardText, CardBody, CardFooter, CardHeader } from 'reactstrap';
import CardAvatar from './CardAvatar'

const ExampleMessage = ({title, subtitle, text, avatar}) => {
  if(title) {
    return (
      <Card style={{backgroundColor: "#355073"}}>
        <CardBody>
          <div className="mb-3">
            <div className="row align-items-center">
              <div className="col-auto"><CardAvatar avatar={avatar}/></div>
              <div className="col ml-n2">
                <h4 className="mb-1">{title}</h4>
                <p className="small text-muted mb-1">{subtitle}</p>
              </div>            
            </div>
          </div>
          <p className="mb-3">
            {text}
          </p>
        </CardBody>
      </Card>
    )
  }

  return null;
}

const TwitterConnectedBody = ({screenName, accountUrl, message, avatar, exampleTitle, exampleSubtitle, exampleText}) => {
  if(screenName) {
    return (
      <>
        <hr/>
        <CardBody>
          <p className="small text-muted mb-1">Connected to your account</p>
          <h4 className="mb-1">{screenName}</h4>
          <hr/>
          <p>{message}</p>
          <ExampleMessage title={exampleTitle} subtitle={exampleSubtitle} avatar={avatar} text={exampleText}/>
        </CardBody>
      </>
    )
  }
  return null;
}

export default TwitterConnectedBody;