import React from "react";
import {Card, CardBody, Row} from 'reactstrap';

const MetaCard = ({name, author, artworkUrl, podcastCount, email}) => {
  
  return (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <div className="col-auto">
            <div className="avatar avatar-lg">
              <img src={artworkUrl} alt="artwork" className="avatar-img rounded-circle"/>
            </div>
          </div>
          <div className="col ml-n2">
            <h4 className="mb-1">{name}</h4>
            {author ? <p className="small text-muted mb-1">{author}</p> : null}
            <p className="small mb-0">
              <span className="text-success">●</span> {podcastCount} episodes
            </p>            
          </div>
        </Row>
      </CardBody>
    </Card>
  )
}

export default MetaCard;