import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../api/justcast';
import {uploadHandler} from '../api/doUploader'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as MenuContext} from '../context/MenuContext'
import ImageUploadForm from '../components/ImageUploadForm';
import SimpleMedia from '../components/SimpleMedia'

const EpisodeArtwork = (props) => {
  const id = props.match.params.id;
  const showId = props.match.params.show_id;
  const {addWithTimeout} = useContext(AlertContext);
  const {setEpisodePageMenu} = useContext(MenuContext);
  const [showName, setShowName] = useState('');
  const [episodeName, setEpisodeName] = useState('');
  const [item, setItem] = useState({});
  const [artwork, setArtwork] = useState(null);
  const [podieoAllowTime, setPodieoAllowTime] = useState(0);
  const [imgUrl, setImgUrl] = useState('https://via.placeholder.com/150')

  const handleUpdate = () => {
    window.scrollTo(0, 0);
    justcastApi.put(`/v1/shows/${showId}/audioposts/${id}`, item)
    .then(() => {
      return uploadHandler(artwork)
    })
    .then((res) => {
      const url = res.data.path;
      return justcastApi.put(`/v1/shows/${showId}/audioposts/${id}`, {artwork_url: url});
    })
    .then((res) => {
      addWithTimeout({color: 'success', message: `Artwork has been updated!`, isOpen: true, timeout: 3000})
      if(res.data.artwork_url) {
        setImgUrl(res.data.artwork_url);
      }
      return;
    })
    .catch((err) => {
      console.log(err.message)
      addWithTimeout({color: 'danger', message: err.message, isOpen: true, timeout: 3000})
    })
  }

  useEffect(() => {
    justcastApi.get(`/v2/shows/${showId}/audioposts/${id}`)
    .then((res) => {
      const data = res.data;
      setItem(data);

      if(res.data.artwork_url) {
        setImgUrl(res.data.artwork_url)
      }
      if(data.show.allow_podieo_max_second && data.show.allow_podieo_max_second > 0) {
        setPodieoAllowTime(data.show.allow_podieo_max_second)
      }

      setEpisodePageMenu({
        title: "Artwork",
        currentPageId: "episodes",
        currentTabId: "artwork",
        subtitle: `${data.show.podcast_title} > ${data.episode_title}`,
        showName: data.show.podcast_title,
        showId,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow: data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website,
        r2Uploaded: data.r2_uploaded
      })

    })
    .catch((err) => {
      console.log(err)
    })

  },[id, showId])


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <SimpleMedia img={imgUrl} imgAltName={'podcast artwork'} headingLabel='Artwork URL' bodyText={imgUrl} />
            <hr/>
            <ImageUploadForm setArtwork={setArtwork} handleUpdate={handleUpdate} showId={showId} artworkHeight={1400} artworkWidth={1400}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default EpisodeArtwork;