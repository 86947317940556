import React, {useState, useEffect} from "react";
import {
  Modal, ModalBody, ModalFooter, ModalHeader, Table,
  Button, FormText, InputGroup, Form
} from 'reactstrap';
import { useForm } from "react-hook-form";

const SendMemberEmailsModal = ({ modalOn, toggleModal, handleSave}) => {
  const { register, getValues, setValue, reset, handleSubmit, watch, formState: { errors } } = useForm();

  const cancel = () => {
    toggleModal(!modalOn)
    setValue('confirmation', null)
  }

  return (
    <Modal isOpen={modalOn} fade={false} toggle={cancel}>
      <ModalHeader toggle={cancel}>Send instruction email to all subscribers</ModalHeader>
      <ModalBody>
        <p>You are going to send the instruction email to all subscribers</p>
        <p>Please type Yes to confirm.</p>
        <Form onSubmit={handleSubmit(handleSave)}>
          <InputGroup>
            <input
              type="text"
              placeholder="Yes"
              className="form-control"
              {...register("confirmation", {required: true, pattern: /Yes/})}
            />
            <Button size="sm" color="warning">Send</Button>
          </InputGroup>
          {errors.confirmation && <FormText color="danger">Time can't be empty, and must be Yes</FormText>}
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default SendMemberEmailsModal