import React, {useRef, useContext } from "react";
import ReactQuill from 'react-quill';
import { Form, FormGroup, Input, InputGroup,InputGroupAddon, InputGroupText, } from 'reactstrap';
import FormGroupInput from '../components/FormGroupInput';
import FormGroupInputSelect from '../components/FormGroupInputSelect';
import UpdateNotification from '../components/UpdateNotification';
import FormGroupInputSwitch from '../components/FormGroupInputSwitch';
import {Context as ModalContext} from '../context/ModalContext'
import {LocationInput} from '../components/location';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'video']
  ]
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

const mediumTypes = [{id: "podcast", label: "Podcast"}, {id: "music", label: "Music"}, {id: "video", label: "Video"}, {id: "film", label: "Film"}, {id: "audiobook", label: "Audiobook"}, {id: "newsletter", label: "Newsletter"}, {id: "blog", label: "Blog"}];

const ShowForm = ({
  name,
  isDirectUpload,
  showNameEditable,
  author,
  email,
  link,
  location_geo,
  location_name,
  description,
  languageId,
  languages,
  categoryId,
  categories,
  medium_type,
  subcategories,
  subcategoryId,
  explicitTypes,
  explicitTypeId,
  itunesTypeIsSerial,
  itunesComplete,
  itunesKeywords,
  copyright,
  itunesTypes,
  locked,
  handleInputValueChange,
  handleSelectionChange,
  handleQuillEditorChange,
  handleToggleChange,
  handleUpdate,
  handleNameChange,
  handleLocationChange
}) => {

  const {add} = useContext(ModalContext);
  const quillRef = useRef(null)

  if(name || isDirectUpload || showNameEditable) {
    return (
      <Form className="mb-4">
        <FormGroup>
        <label>Podcast name</label>
        {
          isDirectUpload ? <Input type="text" className="form-control" value={name} name="name" onChange={(event) => {
            handleNameChange({
              key: event.target.name,
              value: event.target.value
            })
          }}/> : <>
            {showNameEditable ? null : <small className="form-text text-muted">You can change your podcast name from your Dropbox.</small>}
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Input addon type="checkbox"
                  aria-label="Checkbox for change show name"
                  onChange={() => {

                    if(showNameEditable) {
                      const message = "You have disabled to change podcast name on our website. You can change your podcast name from your Dropbox. Please click Save Change button to commit this change."
                      add({color: 'primary', title: "Podcast name setting", message, isOpen: true, loading: false})
                    } else {
                      const message = "You have enabled to change podcast name on our website. Please click Save Change button to commit this change."
                      add({color: 'primary', title: "Podcast name setting", message, isOpen: true, loading: false})
                    }
                    handleInputValueChange({
                      key: "show_name_editable",
                      value: !showNameEditable
                    })
                  }}
                  checked={showNameEditable}/>
                </InputGroupText>
              </InputGroupAddon>
              {
                showNameEditable ? <Input type="text"
                className="form-control"
                name="alias_name"
                value={name}
                onChange={(event) => {
                  handleNameChange({
                    key: event.target.name,
                    value: event.target.value
                  })
                }}/> : <Input type="text" className="form-control" name="alias_name" disabled value={name} />
              }

            </InputGroup>
          </>
        }
        </FormGroup>

        <FormGroupInput
          inputLabel='Author'
          inputType='text'
          inputName="author"
          inputPlaceholder="Author name, e.g. Ben, David"
          inputValue={author ? author : ""}
          inputClassName="form-control"
          handleInputChange={handleInputValueChange}
        />
        <div className="row">
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Keywords'
              inputType='text'
              inputName="itunes_keywords"
              inputValue={itunesKeywords ? itunesKeywords : ""}
              inputClassName="form-control"
              inputPlaceholder="keyword1, keyword2, keyword3..."
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Homepage'
              inputType='text'
              inputName="link"
              inputValue={link ? link : ""}
              inputClassName="form-control"
              inputPlaceholder="If you don't have a website, consider using your social media URLs."
              handleInputChange={handleInputValueChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Email'
              inputType='text'
              inputName="email"
              inputValue={email ? email : ""}
              inputClassName="form-control"
              inputPlaceholder="Put your email address here"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Copyright'
              inputType='text'
              inputName="copyright"
              inputValue={copyright ? copyright : ""}
              inputClassName="form-control"
              inputPlaceholder={copyright ? copyright : `e.g. © ${new Date().getFullYear()} ${name} Co.`}
              handleInputChange={handleInputValueChange}
            />
          </div>
        </div>
        <hr/>
        <FormGroup>
          <label>Description</label>
          <small className="form-text text-muted">Give your podcast a description that provides potential subscribers a good overview of your content.</small>
          <ReactQuill
            ref={quillRef}
            value={description}
            modules={modules}
            formats={formats}
            onChange={handleQuillEditorChange}/>
        </FormGroup>
        <hr/>
        <div className="row">
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select"
              inputLabel="Language"
              inputName='language_id'
              optionLabel="name"
              optionId="id"
              options={languages}
              handleInputChange={handleSelectionChange}
              inputValue={languageId}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
                inputType="select"
                inputLabel="Medium"
                inputName='medium_type'
                optionLabel="label"
                optionId="id"
                options={mediumTypes}
                handleInputChange={handleSelectionChange}
                inputValue={medium_type ? medium_type : "podcast"}
              />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select"
              inputLabel="Category"
              inputName='category_id'
              optionLabel="name"
              optionId="id"
              options={categories}
              handleInputChange={handleSelectionChange}
              inputValue={categoryId}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select"
              inputLabel="Subcategory"
              inputName='subcategory_id'
              optionLabel="name"
              optionId="id"
              options={subcategories}
              handleInputChange={handleSelectionChange}
              inputValue={subcategoryId}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select"
              inputLabel="Podcast type"
              inputName='itunes_type_is_serial'
              optionLabel="label"
              optionId="value"
              mutedText={itunesTypes.filter((x) => x.value === (itunesTypeIsSerial ? itunesTypeIsSerial : false))[0]['mutedText']}
              options={itunesTypes}
              handleInputChange={handleSelectionChange}
              inputValue={itunesTypeIsSerial}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select"
              inputLabel="Explicit type"
              inputName='explicit_type_id'
              optionLabel="label"
              optionId="id"
              mutedText="If you specify true, indicating the presence of explicit content, Apple Podcasts displays an Explicit parental advisory graphic for your podcast.  Podcasts containing explicit material aren’t available in some Apple Podcasts territories."
              options={explicitTypes}
              handleInputChange={handleSelectionChange}
              inputValue={explicitTypeId}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <LocationInput
              handleLocationChange={handleLocationChange}
              location_name={location_name}
              location_geo={location_geo}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInputSwitch
              inputType='switch'
              inputName='itune_complete'
              inputLabel="Podcast update status"
              mutedText="This indicates that a podcast is complete and you will not post any more episodes in the future."
              inputValue={itunesComplete}
              inputCSSId='sort-by-name-switch'
              warningMessage=""
              handleInputChange={handleToggleChange}
            />
          </div>
        </div>
        <hr className="mt-5 mb-5"/>
        <UpdateNotification/>
        <div className="btn btn-block btn-primary" onClick={() => {
          const _description = quillRef?.current?.unprivilegedEditor?.getHTML();
          handleUpdate(_description)
        }}>Save change</div>
        {/* <Link to="/" className="btn btn-block btn-link text-muted">Cancel this change</Link> */}
      </Form>
    )
  } else {
    return null;
  }
}

export default ShowForm;