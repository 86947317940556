import React from 'react'
import { Spinner } from 'reactstrap';

export default function CardContent(props) {
  const {status, is_completed } = props;
  if(is_completed && status === "FAILED") {
    return (
      <div>
        <p>Something went wrong.  Click the 'Re-run' button to refresh the AI-generated content.</p>
      </div>
    )
  } else if (is_completed) {
    return (
      <>{props.children}</>
    )
  } else {
    return (
      <Spinner/>
    )
  }

}
