import React, {useEffect} from 'react'
import { useForm } from "react-hook-form";
import {useAtom} from 'jotai'
import { Collapse, Button, Card, CardBody, CardFooter, Badge, InputGroup, Label, Form, FormGroup, Row, Col, FormText } from 'reactstrap';
import { updatePlayerAtom } from './playerAtom'
import { updateChapterAtom } from './chapterAtom'
import { getHHMMSSFromSeconds, getSecondsFromHHMMSS} from '../../libs/timeTransformation'

export default function ChapterForm({handleCreate, handleUpdate}) {
  const [chapter, updateChapter] = useAtom(updateChapterAtom);
  const { register, getValues, setValue, reset, handleSubmit, watch, formState: { errors } } = useForm();
  const [player, setPlayer] = useAtom(updatePlayerAtom)

  useEffect(() => {
    if(chapter.id) {
      setValue("title", chapter.title)
      setValue("img", chapter.img)
      setValue("url", chapter.url)
      setValue("start_time", getHHMMSSFromSeconds(chapter.start_time))
    } else {
      setValue("title", null)
      setValue("img", null)
      setValue("url", null)
      setValue("start_time", null)
    }
  }, [chapter.id])

  const copyTheCurrentPlayingTime = () => {
    const currentTime = player.playedSeconds;
    const startTimeStr = getHHMMSSFromSeconds(currentTime);
    setValue('start_time', startTimeStr);
  }

  const handleCancel = () => {
    updateChapter({
      id: null,
      title: "",
      start_time: "",
      url: "",
      img: "",
      isOpen: false
    })
    reset()
  }

  const updateSuccessedCabllback = () => {
    updateChapter({
      id: null,
      title: "",
      start_time: "",
      url: "",
      img: "",
    })
    reset()
  }

  const handleSave = () => {
    if(chapter.id) {
      handleUpdate(chapter.id, {
        start_time: getSecondsFromHHMMSS(getValues('start_time')),
        toc: true,
        title: getValues('title'),
        url: getValues('url'),
        img: getValues('img'),
      }, () => {
        updateSuccessedCabllback()
      })
    } else {
      handleCreate({
        start_time: getSecondsFromHHMMSS(getValues('start_time')),
        toc: true,
        title: getValues('title'),
        url: getValues('url'),
        img: getValues('img'),
      }, () => {
        updateSuccessedCabllback()
      })
    }
  }

  const handlePlayClick = () => {
    const sec = getSecondsFromHHMMSS(getValues('start_time'))
    if(sec > 0) {
      player.playerRef.current.seekTo(sec, 'seconds')
    }
    setPlayer({
      playing: true
    })
  }

  return (
    <Collapse isOpen={chapter.isOpen}>
      <Form onSubmit={handleSubmit(handleSave)}>
        <Card>
          <CardBody>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="start_time">Start time</Label>
                  <InputGroup>
                    <Button onClick={handlePlayClick}><i className='fe fe-play'/></Button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="00:04:01"
                      {...register("start_time", {required: true, pattern: /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/})} />
                  </InputGroup>
                  {errors.start_time && <FormText color="danger">Time can't be empty, and please make sure it follows HH:MM:SS</FormText>}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="title">Chapter title</Label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Welcome" {...register("title", {required: true})} />
                  {errors.title && <FormText color="danger">Title can't be empty</FormText>}
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="url">Link</Label>
                  <input type="text" className="form-control" {...register("url")} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="img">Image URL</Label>
                  <input type="text" className="form-control" {...register("img")} />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{display: 'flex', gap: "10px"}}>
                <div className='btn btn-primary btn-sm' onClick={copyTheCurrentPlayingTime}>Use playing time</div>
              </div>
              <div style={{display: 'flex', gap: "10px", flexDirection: "row-reverse"}}>
                <Button size="sm" color="warning">Save</Button>
                <div className='btn btn-sm' onClick={handleCancel}>Cancel</div>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Form>
    </Collapse>
  )
}
