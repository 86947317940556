import createDataContext from './createDataContext';
import justcastApi from '../api/justcast'
import AsyncStorage from '../libs/AsyncStorage'
import {navigate} from '../libs/navigationRef'
import {Mixpanel} from '../api/mixpanel'

const authReducer = (state, action) => {
  switch (action.type) {
    case 'add_error':
      // when we are not yet auth
      return {...state, errorMessages: action.payload, signedIn: false}
    case 'add_error_for_set_new_password_page':
      return {...state, errorMessages: action.payload}
    case 'signin': {
      return {...action.payload, errorMessages: [], forgotPassowrdEmailSent: false}
    }
    case 'signout': {
      return {...action.payload, errorMessages: [], forgotPassowrdEmailSent: false}
    }
    case 'clear_error_message': {
      return {...state, errorMessages: []}
    }
    case 'forgot_password_email_sent': {
      return {...state, forgotPassowrdEmailSent: action.payload}
    }
    case 'reset_context_state': {
      return {...state, errorMessages: [], forgotPassowrdEmailSent: false}
    }
    case 'update_state': {
      return {...state, ...action.payload}
    }
    case 'add_new_mailer_account': {
      const mailer_lite_accounts = state.mailer_lite_accounts;
      const accounts = [...(mailer_lite_accounts ? mailer_lite_accounts : []), action.payload];
      const message = `Connected to your MailerLite account: ${action.payload.email}`;
      return {...state, mailer_lite_accounts: accounts, isOpen: true, message}
    }
    case 'delete_mailer_account': {
      const mailer_lite_accounts = state.mailer_lite_accounts;
      const {account_id, message, isOpen} = action.payload;
      const accounts = mailer_lite_accounts.filter((account) => account.id !== account_id);
      return {...state, mailer_lite_accounts: accounts, message, isOpen}
    }
    case 'delete_alby_connection': {
      const {message, isOpen} = action.payload;
      return {...state, alby_wallet_connections: [], message, isOpen}
    }
    default:
      return state;
  }
};

// make an api request to sign in with the email and password
// if we sign in successfully, modify our state, and say that we are authenticated
// if signing in failed, we need to reflect an error message
const signin = dispatch => {
  return async ({email, password}) => {
    try {
      const _email = email.toLowerCase();
      const res = await justcastApi.post('/auth/sign_in', {email: _email, password})
      const headers = res.headers;
      AsyncStorage.setHeaderItems(headers);

      dispatch({type: 'signin', payload: {...res.data.data, signedIn: true}})
      navigate('/dashboard')
    } catch (err) {
      AsyncStorage.removeHeaderItems()
      Mixpanel.track('Unsuccessful sign in', {email});
      dispatch({type: 'add_error', payload: err.response.data.errors})
    }
  }
}

const signup = dispatch => {
  // const referralCode = AsyncStorage.getItem('via');
  // const utmTerm = AsyncStorage.getItem('utm_term');
  return async ({email, password, recapchaValue}) => {
    try {
      const referralCode = localStorage.getItem('via');
      const utmTerm = localStorage.getItem('utm_term');
      const shopify_connection_public_id = localStorage.getItem('shopify_connection_public_id');

      // const res = await justcastApi.post('/auth', {email, password})
      const _email = email.toLowerCase();
      let userData = {email: _email, password, refer_code: referralCode, utm_term: utmTerm, recapcha_value: recapchaValue, shopify_connection_public_id};

      const res = await justcastApi.post('/auth', userData)
      const user = res.data.data;

      Mixpanel.identify(user.email);
      Mixpanel.track('Successful sign up');
      Mixpanel.people.set({
        $name: user.email,
        $email: user.email,
        $referral_code: referralCode
      });

      navigate('/signup-confirmation')
    } catch (err) {
      AsyncStorage.removeHeaderItems()
      Mixpanel.track('Unsuccessful sign up', {email});
      // console.log(err.response)
      dispatch({type: 'add_error', payload: err.response.data.errors.full_messages})
    }
  }
}

const resetPassword = dispatch => {
  return async ({password, passwordConfirmation, accessToken, uid, client}) => {
    try {
      AsyncStorage.setRequestHeaderItem('access-token', accessToken)
      AsyncStorage.setRequestHeaderItem('uid', uid)
      AsyncStorage.setRequestHeaderItem('client', client)
      const res = await justcastApi.put('/auth/password', {password: password, password_confirmation: passwordConfirmation})
      const headers = res.headers;
      AsyncStorage.setHeaderItems(headers)
      dispatch({type: 'signin', payload: {...res.data.data, signedIn: true}})
      navigate('/dashboard')
    } catch (err) {
      dispatch({type: 'add_error', payload: err.response.data.errors.full_messages})
    }
  }
}

const updatePassword = dispatch => {
  return async ({password, passwordConfirmation}) => {
    try {
      const res = await justcastApi.put('/auth/password', {password: password, password_confirmation: passwordConfirmation})
      const headers = res.headers;
      AsyncStorage.setHeaderItems(headers)
      dispatch({type: 'update_state', payload: {message: "Password updated!", isOpen: true}})
      setTimeout(() => {
        dispatch({type: 'update_state', payload: {message:null, isOpen: false}})
      }, 3000);
      navigate('/dashboard')
    } catch (err) {
      dispatch({type: 'add_error_for_set_new_password_page', payload: err.response.data.errors.full_messages})
    }
  }
}

const forgotPassword = dispatch => {
  return async (email) => {
    try {
      const res = await justcastApi.post('/auth/password', {email, "redirect_url": process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL})
      dispatch({type: 'forgot_password_email_sent', payload: true})
    } catch (err) {
      dispatch({type: 'add_error', payload: err.response.data.errors.full_messages})
    }
  }
}

const signout = dispatch => {
  const uid = AsyncStorage.getItem('uid');
  const client = AsyncStorage.getItem('client');
  const accessToken = AsyncStorage.getItem('access-token');
  return async () => {
    try {
      const res = await justcastApi.delete('/auth/sign_out', {
        uid, client, 'access-token': accessToken
      })

      Mixpanel.identify(uid);
      Mixpanel.track('Successful sign out');

      AsyncStorage.removeHeaderItems()
      dispatch({type: 'signout', payload: {signedIn: false}})
    } catch (err) {
      AsyncStorage.removeHeaderItems()

      Mixpanel.track('Unsuccessful sign out', {uid});
      dispatch({type: 'signout', payload: {signedIn: false}})
    }
  }
}

const tryLocalSignin = dispatch => {
  //console.log(AsyncStorage.getItem('access-token'))
  AsyncStorage.updateHeaderCommons(); // load headers into apis
  return async() => {
    try {
      const res = await justcastApi.get('/auth/validate_token');
      const headers = res.headers;
      const user = res.data.data;
      Mixpanel.identify(user.email);
      Mixpanel.track('User returns');

      AsyncStorage.setHeaderItems(headers)
      dispatch({type: 'signin', payload: {...res.data.data, signedIn: true}})
    } catch (err) {
      AsyncStorage.removeHeaderItems()
      dispatch({type: 'signout', payload: {signedIn: false}})
      // navigate('/signin')
    }
  }
}

const resetContextState = dispatch => () => {
  dispatch({type: 'reset_context_state'})
  navigate('/signin')
}

const clearErrorMessage = dispatch => () => {
  dispatch({type: 'clear_error_message'})
}

const closeAlert = dispatch => {
  return () => {
    dispatch({type: 'update_state', payload: {message:null, isOpen: false}})
  }
}

const updatePaymentPlan = dispatch => {
  return ({stripe_plan_id, expires_at}) => {
    dispatch({type: 'update_state', payload: {stripe_plan_id, expires_at}})
  }
}

const deletePaymentPlan = dispatch => () => {
  dispatch({type: 'update_state', payload: {stripe_plan_id: null}})
}

const createStripeConnectAccount = dispatch => {
  return ({details_submitted}) => {
    dispatch({type: 'update_state', payload: {stripe_connect_details_submitted: details_submitted}})
  }
}

const createMailerLiteAccount = dispatch => {
  return async (api_key) => {
    try {
      const res = await justcastApi.post('/v2/mailerlite_accounts', {api_key})
      dispatch({type: 'add_new_mailer_account', payload: res.data})
      setTimeout(() => {
        dispatch({type: 'update_state', payload: {message:null, isOpen: false}})
      }, 3000);
    } catch(err) {
      const message = err.response?.data?.message;
      dispatch({type: 'update_state', payload: {message, isOpen: true}})
      setTimeout(() => {
        dispatch({type: 'update_state', payload: {message:null, isOpen: false}})
      }, 3000);
    }
  }
}

const deleteMailerLiteAccount = dispatch => {
  return async (account_id) => {
    try {
      const res = await justcastApi.delete(`/v2/mailerlite_accounts/${account_id}`)
      dispatch({type: 'delete_mailer_account', payload: {account_id, message: res.data.message, isOpen: true}})
      setTimeout(() => {
        dispatch({type: 'update_state', payload: {message:null, isOpen: false}})
      }, 3000);
    } catch(err) {
      console.log(err)
    }
  }
}

const deleteAlbyConnection = dispatch => {
  return async (alby_connection_id) => {
    try {
      const res = await justcastApi.delete(`/v1/alby_connections/${alby_connection_id}`)
      dispatch({type: 'delete_alby_connection', payload: {message: res.data.message, isOpen: true}})
      setTimeout(() => {
        dispatch({type: 'update_state', payload: {message:null, isOpen: false}})
      }, 3000);
    } catch(err) {
      console.log(err)
    }
  }
}

const createAlbyConnection = () => {
  return async (params) => {
    try {
      const basePath = `/v1/alby_connections/obtain_auth_link`;
      // const apiPath = showId ? `${basePath}?show_id=${showId}` : basePath
      const apiPath = params ? `${basePath}?${params}` : basePath
      const res = await justcastApi.get(apiPath)
      const {redirect_url} = res.data;
      window.location = redirect_url;
    } catch(err) {
      console.log(err)
    }
  }
}

const addAlbyConnection = dispatch => {
  return ({alby_wallet_connections}) => {
    dispatch({type: 'update_state', payload: {alby_wallet_connections}})
  }
}


export const {Provider, Context} = createDataContext(
  authReducer,
  {
    tryLocalSignin,
    clearErrorMessage,
    resetContextState,
    closeAlert,
    signup,
    signin,
    signout,
    resetPassword,
    forgotPassword,
    updatePassword,
    updatePaymentPlan,
    deletePaymentPlan,
    createStripeConnectAccount,
    createMailerLiteAccount,
    deleteMailerLiteAccount,
    deleteAlbyConnection,
    createAlbyConnection,
    addAlbyConnection
  },
  {
    errorMessages: [],
    mailer_lite_accounts: [],
    alby_wallet_connections: [],
    signedIn: undefined,
    forgotPassowrdEmailSent: false,
    message: null,
    isOpen: false,
    allow_dropbox_subfolders: false,
  }
)


export default {};