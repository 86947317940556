import React from "react";
import {Card, CardBody, Row, Col} from 'reactstrap';

const SimpleStatsTile = ({title, number}) => {
  return (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <Col>
            <h6 className="card-title text-uppercase text-muted mb-2">{title}</h6>
            <span className="h2 mb-0">{number}</span>
          </Col>          
        </Row>
      </CardBody>
    </Card>
  )
}

export default SimpleStatsTile