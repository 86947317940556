import React from 'react'
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import PlayPauseButton from './PlayPauseButton';

const PlaylistItemImg = ({artwork}) => {
  if (artwork) {
    return (
      <p className="text-center mb-3"><img src={artwork} alt="episode artwork" className="img-fluid rounded" /></p>
    )
  }
  return null;
}

const HTMLDesc = ({description}) => {
  if(description) {
    return <div className="mb-3" dangerouslySetInnerHTML={{__html: description}}></div>
  }
  return null;
}

const PlaylistItem = ({id, name, artwork, showArtwork, audioDate, description, audioUrl}) => {
  return (
    <li className="list-group-item px-0">
      <div className="mb-3">
        <Row className="align-items-center">
          <div className="col-auto">
            <span className="avatar">
              <img src={showArtwork} alt="podcast artwork" className="avatar-img rounded"/>
            </span>
          </div>
          <div className="col ml-n2">
            <h4 className="card-title mb-1">{name}</h4>
            <p className="card-text small text-muted mb-1">
              <time dateTime={audioDate}>{audioDate}</time>
            </p>
          </div>
          <div className="col-auto">
            <PlayPauseButton artwork={showArtwork} audio_date={audioDate} id={id} url={audioUrl} description={description} name={name}/>
          </div>
        </Row>
      </div>
      <HTMLDesc description={description}/>
      <PlaylistItemImg artwork={artwork}/>      
    </li>
  )
}

export default PlaylistItem;