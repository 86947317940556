import React, {useContext, useState, useEffect } from "react";
import { Card, CardBody, Spinner } from 'reactstrap';
import justcastApi from '../../../api/justcast';
import {Context as MenuContext} from '../../../context/MenuContext'
import {Context as ScreenContext} from '../../../context/ScreenContext'
import { ListItem} from '../../../components/team/episodes'

export default function TeamPodcast(props) {
  const id = props.match.params.id;
  const menuContext = useContext(MenuContext);
  const screenContext = useContext(ScreenContext);
  const [episodes, setEpisodes] = useState([]);

  useEffect(() => {
    if(id) {
      screenContext.startFetching();

      justcastApi.get(`/v2/team_podcasts/${id}/team_audioposts`)
      .then((res) => {
        screenContext.finishFetching();
        const {show, audioposts} = res.data;
        setEpisodes(audioposts);

        menuContext.setTeamPodcastPageMenus({
          title: 'Episodes',
          subtitle: show.name,
          currentPageId: 'episodes',
          currentTabId: 'episodes',
          showId: id,
          landingPageUrl: show.landing_page_url,
          websiteUrl: show.player_page_link,
          rssFeed: show.rss_feed,
          passwordProtected: show.is_password_protected,
          advancedPrivate: show.is_advanced_private_feed,
          isPrivate: show.is_private,
          isPrivateShow: show.is_private_show,
        });
      })
      .catch((err) => {
        screenContext.finishFetching();
      })
    }
  }, [id])

  if(screenContext.state.loading) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <Card>
        <CardBody>
          <div className="row align-items-center">
            <div className="col">
              <h6 className="text-uppercase text-muted mb-2">Total Uploads</h6>
              <span className="h2 mb-0">{episodes.length}</span>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="list-group list-group-flush my-n3">
            {
              episodes.map((episode) => <ListItem
                key={episode.id}
                name={episode.name}
                showId={id}
                audiopostId={episode.id}
                publishStatus={episode.is_draft === true ? "Draft" : "Published/Scheduled"}
              />)
            }
          </div>
        </CardBody>
      </Card>

    </div>
  )
}
