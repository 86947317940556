import React from "react";
import {getHHMMSSFromSeconds} from '../../libs/timeTransformation'

const PlayerTimeLabel = ({seconds}) => {
  if(seconds) {
    const label = getHHMMSSFromSeconds(seconds);
    return (
      <div>{label}</div>
    )
  }

  return <div>00:00:00</div>;
}

export default PlayerTimeLabel;