import React from "react";
import moment from 'moment'
import 'flatpickr/dist/themes/dark.css'
import Flatpickr from 'react-flatpickr'
import {Card, CardBody, CardTitle, Row, Col, Form} from 'reactstrap';

const DateRangePicker = ({fromDate, setFromDate, toDate, setToDate}) => {
  return (
    <Card>
      <CardBody>
        <Form>
          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>From date</label>
                <Flatpickr 
                  className='form-control mb-3'
                  value={fromDate} 
                  onChange={setFromDate}/>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>To date</label>
                <Flatpickr 
                  className='form-control mb-3'
                  value={toDate} 
                  onChange={setToDate}/>
              </div>
            </div>            
          </div>
        </Form>               
      </CardBody>
    </Card>           
  )
}

export default DateRangePicker