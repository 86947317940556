import React, {useState, useRef} from 'react'
import { Row, Spinner } from 'reactstrap'
import ChooseFileButton from './ChooseFileButton'
import ButtonGroup from './ButtonGroup'
import DeleteButton from './DeleteButton'

const buttonLookup = {
  "uploadFile": ChooseFileButton,
  "buttonGroup": ButtonGroup,
  "deleteFile": DeleteButton,
  "loading": Spinner
}

export default function PhotoUpload({
  loading,
  src,
  artworkId,
  setArtworkModalOpen,
  uploadFile,
  deleteFile,
}) {
  const hiddenFileInput = useRef(null);
  const thumbnailRef = useRef(null);
  const [file, setFile] = useState(null);
  const buttonValue = loading ? "loading" : artworkId ? "deleteFile" : file ? "buttonGroup" : "uploadFile";
  const ImageButton = buttonLookup[buttonValue]
  const [fileDimension, setFileDimension] = useState(null);

  const handleChange = (event) => {
    const eventFile = event.target.files[0];
    const img = new Image();

    const errors = [];
    img.onload = function() {
      if(img.width < 1400) {
        errors.push(`This image must be at least 1400 pixels wide.`);
      }
      if(img.height < 1400) {
        errors.push(`This image must be at least 1400 pixels tall.`);
      }
      if(img.width !== img.height) {
        errors.push(`This image must be a square.`);
      }
      setFileDimension(`${img.width} x ${img.height} pixels`)

      if(errors.length === 0) {
        setFile(eventFile)
      } else {
        setArtworkModalOpen(errors);
      }
    }

    img.src = URL.createObjectURL(event.target.files[0])
  }

  const handleClick = () => {
    hiddenFileInput.current.click();
  }

  const handleRemove = () => {
    setFile(null);
    setFileDimension(null);
    hiddenFileInput.current.value = null;
  }

  const handleUpload = () => {
    if(thumbnailRef?.current) {
      uploadFile(file)
      setFile(null);
    }
  }

  return (
    <Row className="align-items-center">
      <div className='col-auto'>
        <div className='avatar avatar-lg'>
          {
            file ? <img ref={thumbnailRef} src={URL.createObjectURL(file)} className='avatar-img rounded'/> : <img src={src} className='avatar-img rounded'/>
          }
        </div>
      </div>
      <div className='col ml-n2'>
        <h4 className='mb-1 name'>Episode Artwork {fileDimension}</h4>
        {fileDimension && <p className='card-text small text-muted'>Apple requires Episode artwork to be Square; 3000 x 3000 pixels.  Learn more about the requiremen from <a href="https://podcasters.apple.com/support/896-artwork-requirements#episodes" target="_blank" name="apple artwork requirment">here</a>.</p>}
      </div>
      <div className='col-auto'>
        {/* <ChooseFileButton onClick={handleClick}/> */}
        <ImageButton
          handleClick={handleClick}
          handleRemove={handleRemove}
          handleUpload={handleUpload}
          handleDelete={deleteFile}
        />
        <input
          accept=".png,.jpg,.jpeg"
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{display: 'none'}}/>
      </div>
    </Row>
  )
}
