import React, {useContext, useState, useEffect} from "react";
import justcastApi from '../api/justcast';
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AuthContext} from '../context/AuthContext'
import {ConnectMailerLiteGroup, MailerLiteGroups, CardNote} from '../components/mailer-lite'

export default function PodcastMailerLiteIntegration(props) {
  const showId = props.match.params.show_id;
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const authContext = useContext(AuthContext);
  const {mailer_lite_accounts} = authContext.state;
  const {addWithTimeout} = useContext(AlertContext);

  const [show, setShow] = useState({});
  const [denied, setDenied] = useState(false);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mailerLitePodcastGroups, setMailerLitePodcastGroups] = useState([]);
  const [initLoading, setInitLoading] = useState(true);

  useEffect(() => {
    screenContext.startFetching();
    if(mailer_lite_accounts.length > 0) {
      justcastApi.get(`/v2/mailerlite_accounts/${mailer_lite_accounts[0].id}/groups.json`)
      .then((res) => {
        setGroups(res.data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        // console.log(err)
      })
    }

    justcastApi.get(`/v2/shows/${showId}/mailerlite_groups.json`)
    .then((res) => {
      setInitLoading(false)
      setShow(res.data.podcast);
      setMailerLitePodcastGroups(res.data.mailerlite_groups)
      screenContext.finishFetching();
      menuContext.setPrivateSubscribersrMenu({
        title: 'Connections',
        subtitle: res.data.podcast.name,
        currentPageId: 'subscribers',
        currentTabId: 'podcast-connections',
        showId: showId,
        landingPageUrl: res.data.podcast.landing_page_url,
        websiteUrl: res.data.podcast.player_page_link,
        rssFeed: res.data.podcast.rss_feed,
        passwordProtected: res.data.podcast.is_password_protected,
        advancedPrivate: res.data.podcast.is_advanced_private_feed,
        isPrivate: res.data.podcast.is_private,
        isPrivateShow: res.data.podcast.is_private_show
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true)
    })
  }, [showId])

  const handleGroupChange = (group) => {
    const idx = mailerLitePodcastGroups.findIndex((x) => x.id === group.id);
    if(idx > -1) {
      const _mailerLitePodcastGroups = [...mailerLitePodcastGroups];
      _mailerLitePodcastGroups[idx] = {...group};
      setMailerLitePodcastGroups(_mailerLitePodcastGroups);
    } else {
      const _mailerLitePodcastGroups = [{...group}, ...mailerLitePodcastGroups];
      setMailerLitePodcastGroups(_mailerLitePodcastGroups);
    }
  }

  const handleDeleteGroup = (group) => {
    justcastApi.delete(`/v2/shows/${showId}/mailerlite_groups/${group.id}.json`)
    .then((res) => {
      const deletedGroup = res.data
      const _mailerLitePodcastGroups = mailerLitePodcastGroups.filter((x) => x.id !== deletedGroup.id)
      setMailerLitePodcastGroups([..._mailerLitePodcastGroups])
      addWithTimeout({color: "warning", title: `${deletedGroup.name}`, message: `Disconnected with ${group.name}`, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err.response)
    })
  }

  if(loading || initLoading) {
    return null;
  }

  if(mailer_lite_accounts.length === 0) {
    return (
      <CardNote/>
    )
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <ConnectMailerLiteGroup
            showId={showId}
            accountId={mailer_lite_accounts[0].id}
            groups={groups}
            handleGroupChange={handleGroupChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <MailerLiteGroups showId={showId} groups={mailerLitePodcastGroups} handleDelete={handleDeleteGroup}/>
        </div>
      </div>
    </div>
  )
}
