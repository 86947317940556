import React, {useEffect, useState, useContext} from "react";
import { Link } from "react-router-dom";
import { Card, Button, CardBody, Row} from 'reactstrap';
import {Context as AuthContext} from '../../context/AuthContext'
import {Context as MenuContext} from '../../context/MenuContext'
import happinessImg from './../../assets/img/illustrations/happiness.svg'

export default function StripeConfirmation() {
  const authContext = useContext(AuthContext);
  const menuContext = useContext(MenuContext);
  const {plan_name} = authContext.state

  useEffect(() => {
    menuContext.setMainPageLeftMenu({title: 'Billing', subtitle: "email", currentPageId: 'home'});
  }, []);

  return (
    <div className="container-fluid">
      <Card>
        <CardBody className="text-center">
          <Row className="justify-content-center">
            <div className="col-12 col-xl-10">
              <img src={happinessImg} className="img-fluid mt-n5 mb-4" style={{maxWidth: '272px'}}/>
              <h2 className="mb-2">{`Thank you for purchasing your subscription.`}</h2>
              <p className="text-muted">{`We hope you enjoy the ${plan_name} experience.  Thank you for your supporting JustCast, your contribution is a big deal to our company.`}</p>
              <p>Happy Podcasting!</p>
              <Link to={'/dashboard'} className="btn btn-lg btn-primary">Home</Link>
            </div>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
