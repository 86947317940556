import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';

export default function ModalMessage(props) {
  const {toggle, isOpen, title} = props;

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <strong>{title}</strong>
      </ModalHeader>
      <ModalBody>
        {props.children}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}
