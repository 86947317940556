import React, {useEffect, useState, useContext} from "react";
import { Redirect } from "react-router-dom";
import { Spinner} from 'reactstrap';
import justcastApi from '../api/justcast';
import {uploadImageHandler} from '../api/doUploader'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import EpisodeImageUpload from '../components/EpisodeImageUpload';

const EpisodeImagesCreate = (props) => {
  const id = props.match.params.id;
  const [showId, setShowId] = useState('');
  const [showName, setShowName] = useState('');
  const [episodeName, setEpisodeName] = useState('');
  const [uploaded, setUploaded] = useState(false);

  const {addWithTimeout} = useContext(AlertContext);
  const {setEpisodePageMenu} = useContext(MenuContext);
  const screenContext = useContext(ScreenContext)

  useEffect(() => {
    justcastApi.get(`/v1/audioposts/${id}/audiopost_images`)
    .then((res) => {
      const data = res.data;
      setShowName(data.show.name)
      setShowId(data.show.id)
      setEpisodeName(data.audiopost.name);

      setEpisodePageMenu({
        title: "New Image",
        currentPageId: "episodes",
        currentTabId: "images",
        subtitle: `${data.show.podcast_title} > ${data.audiopost.episode_title}`,
        showName: data.show.podcast_title,
        showId: data.show.id,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow:data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website
      })

    })
    .catch((err) => {
      console.log(err)
    })
  },[id, showId])

  const handleAddNewImage = (artwork) => {
    screenContext.startFetching();
    const {image, subtitle, description} = {...artwork};

    uploadImageHandler(image)
    .then((res) => {
      const url = res.data.path;
      return justcastApi.post(`/v1/audioposts/${id}/audiopost_images`, {image_url: url, subtitle, description})
      // send a POST request
    })
    .then((res) => {
      // console.log(res.data)
      // redirect back to audipost images page
      setTimeout(() => {
        screenContext.finishFetching();
        setUploaded(true);
        addWithTimeout({color: 'warning', message: "Image uploaded!", isOpen: true, timeout: 3000})
      }, 1400)
    })
    .catch((err) => {
      screenContext.finishFetching();
      addWithTimeout({color: 'danger', message: "Failed to upload image :(", isOpen: true, timeout: 3000})
    })

  }

  if(uploaded) {
    return (
      <Redirect to={`/episodes/${id}/images`}/>
    )
  }



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
                screenContext.state.loading? <Spinner color="primary" /> : <EpisodeImageUpload
                audiopostId={id}
                handleUpload={handleAddNewImage}/>
            }
          </div>
        </div>
      </div>

    </>
  )
}

export default EpisodeImagesCreate;