import React, {useEffect, useState, useContext} from "react";
import { Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import {Context as AuthContext} from '../../context/AuthContext'
import {Context as MenuContext} from '../../context/MenuContext'
import PricingTable from '../../components/stripe/PricingTable'
import justcastApi from '../../api/justcast';
import ShopifyPricingTable from '../../components/shopify/ShopifyPricingTable'

export default function StripePricingTable() {
  const authContext = useContext(AuthContext);
  const menuContext = useContext(MenuContext);
  const {id, email, plan_name, stripe_id} = authContext.state;
  const [loading, setLoading] = useState(true)
  const [shopifyPricingTable, setShopifyPricingTable] = useState(false);
  const [shopifyPlans, setShopifyPlans] = useState([]);
  const [activeChargeName, setActiveChargeName] = useState(null);
  const [chargingId, setChargingId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(false);
    setErrorMessage(null)
  }

  useEffect(() => {
    menuContext.setMainPageLeftMenu({title: 'Plans', subtitle: "JustCast", currentPageId: 'home'});
    // http://localhost:3000/v2/shopify_application_charges/all_plans.json

    justcastApi.get(`/v2/shopify_application_charges/all_plans.json`)
    .then((res) => {
      const {plans, active_charge, shopify_pricing_table} = res.data;
      const { id, internal_plan_name} = active_charge || {}
      setShopifyPricingTable(shopify_pricing_table);
      setShopifyPlans(plans);
      setActiveChargeName(internal_plan_name)
      setChargingId(id)
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false)
    })
  }, []);

  const handleClick = (planName) => {
    if(planName === activeChargeName) {
      handleCancel()
    } else if (planName !== activeChargeName) {
      handleSubscribe(planName)
    }
  }

  const handleSubscribe = (planName) => {
    justcastApi.get(`/v2/shopify_application_charges/app_charge_url.json?plan_name=${planName}`)
    .then((res) => {
      const { confirmation_url } = res.data;
      if(confirmation_url) {
        window.location = confirmation_url;
      }
    })
    .catch((err) => {
      console.log(err.response.data)
      setErrorMessage(err.response.data)
      setIsOpen(true)
    })
  }

  const handleCancel = () => {
    justcastApi.put(`/v2/shopify_application_charges/${chargingId}/cancel.json`, {})
    .then((res) => {
      const { message } = res.data;
      // console.log(message)
      setActiveChargeName(null)
      setChargingId(null)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  if(loading) {
    return (
      <div className="container-fluid">
        <Spinner/>
      </div>
    )
  }

  if(shopifyPricingTable) {
    return (
      <div className="container-fluid">
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>Errors</ModalHeader>
          <ModalBody>
            <p>{errorMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
        <div className="row">
          <div className="col-12">
            <ShopifyPricingTable
              plans={shopifyPlans}
              subscribeClick={handleClick}
              activePlanName={activeChargeName}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {
        stripe_id && <div className="container-fluid">
          <div className="row justify-content-between align-items-center mb-5">
            <div className="col-12 col-md-9 col-xl-7">
              <h2 className="mb-2">
                {plan_name}
              </h2>
              <p className="text-muted mb-md-0">
                {`You're currently subscribed to the ${plan_name}. To view receipts, upgade your plan, and cancel your subscription.  Please click the Dashboard button.`}
              </p>
            </div>
            <div className="col-12 col-md-auto">
              <Button color="primary" to={`/stripe_dashboard`} tag={Link}>
                Billing Portal
              </Button>
            </div>
          </div>
        </div>
      }
      <PricingTable
        email={email}
        userId={id}
        planName={plan_name}
        customerId={stripe_id}
        pricingTableId={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
      />
      <div className="container-fluid" style={{marginTop: 40, marginBottom: 40}}>
        <div className="row justify-content-between align-items-center mb-5">
          <p>If this pricing doesn't meet your needs, please check out our other <Link to="/special-pricing">pricing</Link> options.</p>
        </div>
      </div>
    </>
  )
}
