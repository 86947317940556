import React, {useEffect, useState, useContext} from "react";
import {Link, Redirect} from 'react-router-dom';
import moment from 'moment'
import {Card, CardHeader, CardBody, Row, Spinner} from 'reactstrap';
import justcastApi from '../api/justcast'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as MenuContext} from '../context/MenuContext'
import ShowPageHeader from '../components/PageHeader/ShowPageHeader';
import SimpleLineChart from '../components/SimpleLineChart'
import SimpleStatsTile from '../components/SimpleStatsTile'
import DateRangePicker from '../components/DateRangePicker'
import TableCard from '../components/TableCard'
import {totalDownloadReportLineData, formatNumber} from './../libs/tranformData'
import BoostrapTable from '../components/BoostrapTable'
import {DateFormatCell, NumberFormatCell, DecodeURIFormatCell} from './../libs/TableCellFormat'
import {Mixpanel} from '../api/mixpanel'

const LinkToEpisodePage = ({cell}) => {
  return <Link to={`episodes/${cell.row.original.audiopost_id}/downloads`}>{cell.value}</Link>
}

const DownloadReport = (props) => {
  const authContext = useContext(AuthContext);
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const id = props.match.params.id;
  const uid = authContext.state.uid;

  const [reportFromDate, setReportFromDate] = useState()
  const [reportToDate, setReportToDate] = useState(moment().add(-1, 'days').format('YYYY-MM-DD'));
  const [downloads, setDownloads] = useState(0);
  const [totalEpisodes, setTotalEpisodes] = useState(0);
  const [lineData, setLineData] = useState({})
  const [userAgents, setUserAgents] = useState([]);  
  const [referrers, setReferrers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [items, setItems] = useState([]); // episodes
  const [denied, setDenied] = useState(false);

  const columns = [
    {
      Header: 'Name',
      accessor: 'audiopost_name',
      Cell: LinkToEpisodePage
    },
    {
      Header: 'Published Date',
      accessor: 'audio_date',
      Cell: DateFormatCell
    },
    {
      Header: 'Downloads #',
      accessor: 'download_count',
      Cell: NumberFormatCell
    },
    {
      Header: 'First 3 day #',
      accessor: 'first_three_day_count',
      Cell: NumberFormatCell      
    },
    {
      Header: '4th to 15th day #',
      accessor: 'three_to_fifteen_day_count',
      Cell: NumberFormatCell      
    },
    {
      Header: 'After 15th day #',
      accessor: 'later_fifteen_day_count',
      Cell: NumberFormatCell      
    }         
  ]

  const handleFromDateChange = (e) => {
    const datePicked = moment(e[0]).format('YYYY-MM-DD');
    Mixpanel.identify(uid);    
    Mixpanel.track('Download page', {show_id: id, input_field: "FromDate", value: datePicked});
    setReportFromDate(datePicked);
  }

  const handleToDateChange = (e) => {
    const datePicked = moment(e[0]).format('YYYY-MM-DD');
    Mixpanel.identify(uid);    
    Mixpanel.track('Download page', {show_id: id, input_field: "ToDate", value: datePicked});    
    setReportToDate(datePicked);
  }  

  useEffect(() => {
    screenContext.startFetching();

    Mixpanel.identify(uid);
    Mixpanel.track('Show Download loaded', {show_id: id});

    justcastApi.get(`/v1/shows/${id}/download_report_show_info`)
    .then((res) => {
      const name = res.data.name;
      

      setReportFromDate(moment(res.data.minimum_audio_date).format('YYYY-MM-DD'))
      screenContext.finishFetching();
      
      menuContext.setMetricsMenus({
        title: 'Downloads',
        subtitle: name,
        currentPageId: 'metrics',
        currentTabId: 'downloads',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show        
      });      
    })
    .catch((err) => {   
      screenContext.finishFetching(); 
      setDenied(true)  
      console.log(err)
    })
  }, [id])

  useEffect(() => {
    if(reportFromDate) {
      screenContext.startFetching();
      justcastApi.post(`/v1/shows/${id}/download_report`, {
        report_from_date: reportFromDate,
        report_end_date: reportToDate
      })
      .then((res) => {
        setDownloads(res.data.total_download);
        setTotalEpisodes(res.data.total_audioposts_count);
        const _lineData = totalDownloadReportLineData(res.data.download_by_time, "Downloads")        
        setLineData(_lineData);
        setUserAgents(res.data.user_agents)
        setReferrers(res.data.referrers)
        setCountries(res.data.countries)
        setItems(res.data.report_by_audioposts)
        screenContext.finishFetching();
      })
      .catch((err) => {
        console.log(err)
        screenContext.finishFetching();
      })
    }
  }, [reportFromDate, setReportToDate])


  if(denied) {
    return <Redirect to="/dashboard"/>
  }
    
  return (
    <>
      <div className="container-fluid">
        { 
          screenContext.state.loading ? <Spinner color="primary" /> : 
          <>
            <Row>
              <div className="col-12">
                <DateRangePicker fromDate={reportFromDate} setFromDate={handleFromDateChange} toDate={reportToDate} setToDate={handleToDateChange}/>
              </div>
            </Row>
            <Row>
              <div className="col-6 col-lg-6 col-xl">
                <SimpleStatsTile title="Total Episodes" number={formatNumber(totalEpisodes)}/>
              </div>
              <div className="col-6 col-lg-6 col-xl">
                <SimpleStatsTile title="Total Downloads" number={formatNumber(downloads)}/>
              </div>
            </Row>
            <Row>
              <div className="col-12 col-xl">
                <SimpleLineChart data={lineData}/>
              </div>
            </Row>
            <Row>
              <div className="col-12 col-lg-6">
                <TableCard title="User Agent" data={userAgents} columns={[{Header: "Name", accessor: "name", Cell: DecodeURIFormatCell}, {Header: "#", accessor: "count"}]} />
              </div>
              <div className="col-12 col-lg-6">
                <TableCard title="Country" data={countries} columns={[{Header: "Country", accessor: "name"}, {Header: "#", accessor: "count"}]} />
              </div>                                          
            </Row>
            <Row>
              <div className="col-12">
                <TableCard title="Referrer" data={referrers} columns={[{Header: "Name", accessor: "name"}, {Header: "#", accessor: "count"}]} />
              </div>              
            </Row>
            <Row>
              <div className="col-12">
                <Card>
                  <CardHeader>
                    <h4 className="card-header-title">Downloads by Episodes</h4>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <BoostrapTable data={items} columns={columns}/>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </>
        }
      </div>
    </>
  )
}

export default DownloadReport;