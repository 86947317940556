import React, {useEffect, useState, useContext} from "react";
import {Card, CardBody, Row, Spinner} from 'reactstrap';
import moment from 'moment'
import {Redirect} from 'react-router-dom'
import justcastApi from '../api/justcast'
import axios from 'axios';
import {dateRangeType} from '../libs/datediff'
import {joinCountryData} from '../libs/joinCountryData'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AuthContext} from '../context/AuthContext'
import {Mixpanel} from '../api/mixpanel'
import { DecodeURIFormatCell} from './../libs/TableCellFormat'
import FormGroupInputSelect from '../components/FormGroupInputSelect';
import {NumberCard, ColumnChartCard, CardGlobalMap, CardPodcastPlayers} from '../components/Metrics'
import ScreenBlocker from '../components/ScreenBlocker'

const EpisodeAnalyticsReport = (props) => {
  const menuContext = useContext(MenuContext);
  const authContext = useContext(AuthContext);
  const uid = authContext.state.uid;
  const id = props.match.params.id;
  const showId = props.match.params.show_id;
  const [screenBlocker, setScreenBlocker] = useState(false);
  const [denied, setDenied] = useState(false);
  const [audioposts, setAudioposts] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [audiopostId, setAudiopostId] = useState(props.match.params.id === 'report_latest' ? null : props.match.params.id);
  const [pageLoading, setPageLoading] = useState(true);
  const [summaryStatsLoading, setSummaryStatsLoading] = useState(true);

  const [summaryStats, setSummaryStats] = useState([]);

  const [listenerTrend, setListenerTrend] = useState({title: 'Listener Trends'});
  const [listenerTrendDate, setListenerTrendDate] = useState({label: 'All Time', fromDate: null, toDate: null, value: 'all_time', display: false, loading: true});
  const [listenerOptionSelected, setListenerOptionSelected] = useState(null);

  const [countryDownloads, setCountryDownloads] = useState({title: 'Global Listener Locations', loading: true});
  const [countryDownloadsDate, setCountryDownloadsDate] = useState({label: 'All Time', fromDate: null, toDate: null, value: 'all_time', display: false, loading: true});
  const [countryDownloadsOptionSelected, setCountryDownloadsOptionSelected] = useState(null);

  const [userAgentDownloads, setUserAgentDownloads] = useState({title: 'Podcast Players', loading: true});
  const [userAgentDownloadsDate, setUserAgentDownloadsDate] = useState({label: 'All Time', fromDate: null, toDate: null, value: 'all_time', display: false, loading: true});
  const [userAgentDownloadsOptionSelected, setUserAgentDownloadsOptionSelected] = useState(null);

  const handleSelectionChange = (event) => {
    setAudiopostId(Number(event.target.value));
    setListenerTrendDate({...listenerTrendDate, loading: true})
    setUserAgentDownloads({...userAgentDownloads, loading: true})
    setSummaryStatsLoading(true);
    props.history.push(`/shows/${showId}/episodes/${event.target.value}/metrics`)
  }

  const handleListenrOptionSelected = (x) => {
    const {label, value, from_date, to_date} = x;
    if(x.id === 'custom_date_range') {
      setListenerTrendDate({...listenerTrendDate, label, value, display: true})
    } else {
      setListenerTrend({...listenerTrend})
      setListenerTrendDate({
        label,
        value,
        fromDate: from_date,
        toDate: to_date,
        display: false,
        loading: true
      })
    }
    setListenerOptionSelected(x)
    // console.log(x);
  }

  const handleDateChange = (id, val) => {
    const datePicked = moment(val[0]).format('YYYY-MM-DD');
    setListenerTrendDate({...listenerTrendDate, [id]: datePicked, loading: true});
  }

  // user agents
  const handleUserAgentOptionSelected = (x) => {

    const {label, value, from_date, to_date} = x;
    if(x.id === 'custom_date_range') {
      setUserAgentDownloadsDate({...userAgentDownloadsDate, label, value, display: true})
    } else {
      setUserAgentDownloads({...userAgentDownloads})
      setUserAgentDownloadsDate({
        label,
        value,
        fromDate: from_date,
        toDate: to_date,
        display: false,
        loading: true
      })
    }
    setUserAgentDownloadsOptionSelected(x)
    // console.log(x);
  }

  const handleUserAgentDateChange = (id, val) => {
    const datePicked = moment(val[0]).format('YYYY-MM-DD');
    setUserAgentDownloadsDate({...userAgentDownloadsDate, [id]: datePicked, loading: true});
  }

  useEffect(() => {
    justcastApi.get(`/v1/shows/${showId}`)
    .then((res) => {
      const name = res.data.podcast_title;
      setScreenBlocker(res.data.skill_standard_analytics);

      menuContext.setMetricsMenus({
        title: 'Downloads',
        subtitle: name,
        currentPageId: 'metrics',
        currentTabId: 'episodes/report_latest/metrics',
        showId: showId,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        isLiveview: res.data.live_view,
        skill_podcast_website: res.data.skill_podcast_website
      });
    })
    .catch((err) => {
      // console.log(err)
      setDenied(true)
    })
  }, [showId])

  useEffect(() => {

    if(screenBlocker) {

      justcastApi.get(`/v1/shows/${showId}/download_report_audioposts`)
      .then((res) => {
        setAudioposts(res.data);
        setPageLoading(false)
        // props.match.params.id()
        if(audiopostId === null) {
          setAudiopostId(res.data[0]['id']);
        }
      })
      .catch((err) => {
        console.log(err)
        setPageLoading(false)
      })

      justcastApi.get(`/v1/shows/${showId}/metrics_options`)
      .then((res) => {
        const fromDate = res.data[3]['from_date'];
        const toDate = res.data[3]['to_date'];
        const label = res.data[3]['label'];
        const value = res.data[3]['value'];
        const options = res.data.map((item) => {
          return {...item, key: item['id']}
        })

        setDateOptions(options);

        setListenerOptionSelected(res.data[3])
        setListenerTrendDate({label, fromDate, toDate, value});

        setCountryDownloadsOptionSelected(res.data[3])
        setCountryDownloadsDate({label, fromDate, toDate, value});

        setUserAgentDownloadsOptionSelected(res.data[3])
        setUserAgentDownloadsDate({label, fromDate, toDate, value});
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }, [showId, screenBlocker])

  useEffect(() => {
    if(props.match.params.id === 'report_latest' && audioposts.length > 0) {
      setAudiopostId(audioposts[0]['id'])
    }
  }, [props.match.params.id])

  // Summary card
  useEffect(() => {
    if(audiopostId) {
      justcastApi.get(`/v1/shows/${showId}/audioposts/${audiopostId}/download_summary`)
      .then((res) => {
        const stats = [];
        const _stats = res.data.stats[0];
        stats.push({title: 'First 3 days', value: _stats['first_three_day_count']})
        stats.push({title: '4th to 15th days', value: _stats['three_to_fifteen_day_count']})
        stats.push({title: 'After 15 Days', value: _stats['later_fifteen_day_count']})
        stats.push({title: 'Total', value: _stats['total_count']})
        setSummaryStatsLoading(false);
        setSummaryStats(stats);

      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [showId, audiopostId])

  // Listener Trends
  useEffect(() => {
    if(audiopostId) {
      const fromDate = listenerTrendDate.fromDate;
      const toDate = listenerTrendDate.toDate;
      const dataType = dateRangeType(fromDate, toDate)
      if(listenerTrendDate.fromDate && listenerTrendDate.toDate) {
        const path = `/v1/shows/${showId}/audioposts/${audiopostId}/listener_trends?from_date=${fromDate}&to_date=${toDate}&type=${dataType}`
        justcastApi.get(path)
        .then((res) => {
          let totalDownloads = 0;
          const stats = res.data.stats;
          stats.forEach((x) => totalDownloads+= x.downloads ? x.downloads : 0);
          setListenerTrend({...res.data, totalDownloads});
          setListenerTrendDate({...listenerTrendDate, loading: false})
        })
        .catch((err) => {
          setListenerTrend({title: 'Listener Trends', subtitle: 'Not available', stats: []})
          setListenerTrendDate({...listenerTrendDate, loading: false})
          console.log(err);
        })
      }
    }
  }, [showId, audiopostId, listenerTrendDate.fromDate, listenerTrendDate.toDate])

  // Country Stats
  useEffect(() => {
    if(audiopostId) {
      const fromDate = countryDownloadsDate.fromDate;
      const toDate = countryDownloadsDate.toDate;
      if(fromDate && toDate) {
        let geoData = {};
        axios.get("https://justcast.sfo2.digitaloceanspaces.com/DashboardAssets/data/worldgeodata.json")
        .then((res) => {
          geoData = res.data;
          return justcastApi.get(`/v1/shows/${showId}/audioposts/${audiopostId}/country_breakdown?from_date=${fromDate}&to_date=${toDate}`)
        })
        .then((res) => {
          const countryData = res.data;
          const countryStats = countryData.stats;
          const {title, subtitle} = countryData;
          const stats = joinCountryData(geoData, countryStats);
          setCountryDownloads({title, subtitle, stats, loading: false})
        })
        .catch((err) => {
          console.log(err);
        })
      }
    }
  }, [showId, audiopostId, countryDownloadsDate.fromDate, countryDownloadsDate.toDate]);

  // User agents
  useEffect(() => {
    if(audiopostId) {
      const fromDate = userAgentDownloadsDate.fromDate;
      const toDate = userAgentDownloadsDate.toDate;
      if(fromDate && toDate) {
        justcastApi.get(`/v1/shows/${showId}/audioposts/${audiopostId}/user_agent_breakdown?from_date=${fromDate}&to_date=${toDate}`)
        .then((res) => {
          setUserAgentDownloads({...res.data, loading: false})
        })
        .catch((err) => {
          setUserAgentDownloads({title: "Episode Breakdown", subtitle: 'Not available', stats: [], loading: false})
        })
      }
    }
  }, [showId, audiopostId, userAgentDownloadsDate.fromDate, userAgentDownloadsDate.toDate])

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  if(screenBlocker === false) {
    return (
      <ScreenBlocker message="Please upgrade your account to access the data."/>
    )
  }

  if(pageLoading) {
    return (
      <div className="container-fluid">
        <Row>
          <div className="col-12">
            <Spinner color="primary"/>
          </div>
        </Row>
      </div>
    )
  }
  return (
    <div className="container-fluid">
      <Row>
        {
          summaryStatsLoading ? <div className="col-12"><Card><CardBody><Spinner/></CardBody></Card></div> :
          summaryStats.map((item, id) =>
            <div className="col-3" key={id}>
              <NumberCard title={item.title} value={item.value}/>
            </div>
          )
        }
      </Row>
      <Row>
        <div className="col-12">
          <Card>
            <CardBody>
              <FormGroupInputSelect
                inputType="select"
                inputLabel="Episode"
                inputName='audiopost_id'
                optionLabel="name"
                optionId="id"
                options={audioposts}
                handleInputChange={handleSelectionChange}
                inputValue={audiopostId}
              />
            </CardBody>
          </Card>
        </div>
      </Row>
      <ColumnChartCard
          loading={listenerTrendDate.loading}
          chartId={'listener_trends'}
          title={listenerTrend.title}
          subtitle={listenerTrend.subtitle}
          totalDownloads={listenerTrend.totalDownloads}
          data={listenerTrend.stats}
          optionsData={dateOptions}
          optionSelected={listenerOptionSelected}
          handleOptionChange={handleListenrOptionSelected}
          fromDate={listenerTrendDate.fromDate}
          toDate={listenerTrendDate.toDate}
          handleDateChange={handleDateChange}
          datePickerDisplay={listenerTrendDate.display}
        />
        <CardPodcastPlayers
          title={userAgentDownloads.title}
          columns={[{Header: "Name", accessor: "name", Cell: DecodeURIFormatCell}, {Header: "#", accessor: "downloads"}]}
          subtitle={userAgentDownloads.subtitle}
          loading={userAgentDownloads.loading}
          stats={userAgentDownloads.stats}
          optionsData={dateOptions}
          optionSelected={userAgentDownloadsOptionSelected}
          handleOptionChange={handleUserAgentOptionSelected}
          fromDate={userAgentDownloadsDate.fromDate}
          toDate={userAgentDownloadsDate.toDate}
          handleDateChange={handleUserAgentDateChange}
          datePickerDisplay={userAgentDownloadsDate.display}
        />
        <br/>
    </div>
  )
}

export default EpisodeAnalyticsReport;