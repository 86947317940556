import createDataContext from './createDataContext';

const modalReducer = (state, action) => {
  switch(action.type) {
    case 'add':    
      return {...action.payload}
    case 'add_with_messages':
      return {...action.payload}
    case 'reset': 
      return {...action.payload}
    case 'destroy_direct_podcast':
      return {...action.payload}
    case 'create_new_podcast':
      return {...action.payload}
    case 'handle_podcast_delete':
      return {...action.payload}
    case 'handle_deleted_podcast':
      return {...action.payload}
    default:
      return state
  }
}

const add = dispatch => {
  return ({color, title, message, isOpen, loading}) => {
    dispatch({type: 'add', payload: {color, title, message, isOpen, loading, inputs: [], messages: []}})
  }
}

const createNewPodcast = dispatch => {
  return () => {
    dispatch({type: 'create_new_podcast', payload: {startNewPodcast: true}})
  }
}

const destroyDirectPodcast = (dispatch) => {
  return ({title, name, endpoint, redirectURL, message, messages, deleteButtonLabel}) => {
    dispatch({type: 'destroy_direct_podcast', 
    payload: { title, name, endpoint, redirectURL, message, messages, deleteButtonLabel, deleteDirectPodcast: true}})
  }
}

const addWithInputs = dispatch => {
  return ({color, title, message, isOpen, inputs}) => {
    dispatch({type: 'add', payload: {color, title, message, isOpen, inputs, messages: []}})
  }
}

const addWithMessages = dispatch => {
  return ({color, title, messages, loading, isOpen}) => {
    dispatch({type: 'add_with_messages', payload: {color, title, loading, message: null, isOpen, inputs: [], messages}})
  }
}

const reset = dispatch => {
  return () => {
    dispatch({type: 'reset', 
    payload: {
      color: null, 
      title: null, 
      message: null,       
      isOpen: false, 
      loading: false,
      startNewPodcast: false, 
      deleteDirectPodcast: false,
      podcastDeleted: false,
      deleteButtonLabel: '',
      messages: [],
      name: [],
      endpoint: '',
      redirectURL: '',
      inputs: [], 
    }})
  }
}


const handlePodcastDelete = dispatch => {
  return () => {
    dispatch({type: 'handle_podcast_delete', 
    payload: {
      color: null, 
      title: null, 
      message: null,       
      isOpen: false, 
      startNewPodcast: false, 
      deleteDirectPodcast: false,
      podcastDeleted: true,
      deleteButtonLabel: '',
      messages: [],
      name: [],
      endpoint: '',
      redirectURL: '',
      inputs: [], 
    }})
  }
}

const handleDeletedPodcast = dispatch => {
  return () => {
    dispatch({type: 'handle_deleted_podcast', payload: {podcastDeleted: false}})
  }  
}

export const {Provider, Context} = createDataContext(
  modalReducer,
  {
    add,
    createNewPodcast,
    destroyDirectPodcast,
    handlePodcastDelete,
    handleDeletedPodcast,
    addWithInputs,
    addWithMessages,
    reset
  },
  {
    color: null, 
    title: null, 
    message: null,       
    isOpen: false, 
    startNewPodcast: false, 
    deleteDirectPodcast: false,
    podcastDeleted: false,
    loading: false,
    deleteButtonLabel: '',
    messages: [],
    name: [],
    endpoint: '',
    redirectURL: '',
    inputs: [], 
  }
)

export default {}