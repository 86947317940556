import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import BoostrapTable from './BoostrapTable'
import { DefaultColumnFilter } from '../libs/TableFilter'
import { PlainTextCell, DateFormatCell, BooleanCell } from '../libs/TableCellFormat'
import { XCircle, CheckCircle, Trash2, RefreshCcw, Send } from 'react-feather';

const IconCell = ({cell}) => {
  if(cell.value === true) {
    return <CheckCircle size={18} color="#5AD905"/>
  }
  return <XCircle size={18} />
}

const PrivatePodcastSubscribersTable = ({items, handleDelete, handleResend, handleReset, handleUpdateStatus, handleAccessLink}) => {
  const ActionCell = ({cell}) => {
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);
    const subscription_status = cell.row.original.subscription_status;

    return (
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret size="sm" color="primary">Settings</DropdownToggle>
        <DropdownMenu>
          {subscription_status === "APPROVED" && 
            <>
              <DropdownItem onClick={() => {
                  handleResend(cell.value)
              }}>
                Send instructions
              </DropdownItem>
              <DropdownItem onClick={() => {
                handleReset(cell.value)
              }}>
                Reset private feed
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => {
                handleAccessLink(cell.value)
              }}>
                Access page
              </DropdownItem>
              <DropdownItem divider />
            </>            
          }
          <DropdownItem onClick={() => {
            handleDelete(cell.value)
          }}>
            Delete
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>     
    )
  }

  const StatusCell = ({cell}) => {
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);
    const status = ["APPROVED", "REJECT"];

    return (
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret size="sm" color="warning">{cell.value}</DropdownToggle>
        <DropdownMenu>
          {
            status.map((status) => <DropdownItem key={status} onClick={() => {
              handleUpdateStatus(cell.row.index, cell.row.original.id, status)
              // handleUpdateStatus(index, cell.id, status)
            }}>{status}</DropdownItem>)
          }
        </DropdownMenu>
      </ButtonDropdown>
    )
  }

  const columns = [
    {
      Header: 'Email',
      accessor: 'email_address',
      Cell: PlainTextCell,
      Filter: DefaultColumnFilter,
    },
    {
      Header: "Full name",
      accessor: 'full_name',
      Cell: PlainTextCell,
    },     
    {
      Header: "Status",
      accessor: 'subscription_status',
      Cell: StatusCell
    },
    {
      Header: "Settings",
      accessor: 'id',
      Cell: ActionCell
    },
    {
      Header: 'Added',
      accessor: 'created_at',
      Cell: DateFormatCell
    },    
    {
      Header: 'Activated?',
      accessor: 'is_activated',
      Cell: IconCell
    },       
  ];

  return (
    <BoostrapTable data={items ? items : []} columns={columns}/>
  )
}

export default PrivatePodcastSubscribersTable;