import React from 'react';

const TopMenuTitle = ({title, subtitle}) => {
  return(
    <div className="col">
      <h6 className="header-pretitle">{subtitle}</h6>
      <h1 className="header-title">{title}</h1>
    </div>    
  )
}

export default TopMenuTitle;