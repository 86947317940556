import React, {useEffect, useState, useContext} from "react";
import { Row, Card, CardBody, CardHeader} from 'reactstrap';
import justcastApi from '../api/justcast'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {NumberCard } from '../components/Metrics'

const StripeAccountBalance = () => {
  const screenContext = useContext(ScreenContext);
  const menuContext = useContext(MenuContext);
  const authContext = useContext(AuthContext);
  const [available, setAvailable] = useState([]);
  const [pending, setPending] = useState([])

  useEffect(() => {
    fetch()
    // screenContext.startFetching();
    // getAffiliateCode()
    // setupCampaign()
    // getPayouts()
    menuContext.setMainPageLeftMenu({title: 'Payout', subtitle: 'Balance', currentPageId: 'home'});
  }, [])

  const fetch = () => {    
    justcastApi.get('/v1/stripe_accounts_balance_overview')
    .then((res) => {
      // screenContext.finishFetching();
      if(res.data && res.data.overview) {        
        setAvailable(res.data.overview.available)
        setPending(res.data.overview.pending)
      }
    }) 
    .catch((err) => {
      // screenContext.finishFetching();
      // todo error handling
      console.log(err);
    })       
  }  

  return (
    <div className="container-fluid">
      {
        available ? <>
          <h2>Available Fund</h2>
          <hr/>
          <Row>
            {
              available.map((item, id) => 
              <div className="col-3" key={`overview-${id}`}>
                <NumberCard title={item.currency} value={`$ ${(item.amount / 100).toLocaleString('en')}`}/>
              </div>
              )
            }
          </Row>
        </> : null
      }
      {
        pending ?       
        <>
          <h2>Pending Fund</h2>
          <hr/>      
          <Row>
            {
              pending.map((item, id) => 
              <div className="col-3" key={`pending-${id}`}>
                <NumberCard title={item.currency} value={`$ ${(item.amount / 100).toLocaleString('en')}`}/>
              </div>
              )
            }
          </Row> 
        </> : null
      } 
    </div>
  )
}

export default StripeAccountBalance;