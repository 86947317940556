import React, {useEffect, useState, useContext} from "react";
import {Context as ModalContext} from '../context/ModalContext'
import justcastApi from '../api/justcast';
import {Context as MenuContext} from '../context/MenuContext'
import {MainForm, ValueRecipients, AddValueRecipient} from '../components/value-to-value';
import {Context as AuthContext} from '../context/AuthContext'

const EpisodeValueForValue = (props) => {
  const add_wallet = props.location.search;
  const id = props.match.params.id;
  const showId = props.match.params.show_id;
  const {addWithMessages, add} = useContext(ModalContext);
  const {setEpisodePageMenu} = useContext(MenuContext);
  const [item, setItem] = useState({});
  const [items, setItems] = useState([]);
  const authContext = useContext(AuthContext);
  const {createAlbyConnection} = authContext
  const {alby_wallet_connections} = authContext.state;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    justcastApi.get(`/v1/shows/${showId}/audioposts/${id}`)
    .then((res) => {
      const data = res.data;
      setItem(data);
      setLoaded(true)
      const {value_recipients} = data;
      setItems(value_recipients ? value_recipients : [])
      setEpisodePageMenu({
        title: "Value for Value",
        currentPageId: "episodes",
        currentTabId: "value_for_value_settings",
        subtitle: `${data.show.podcast_title} > ${data.episode_title}`,
        showName: data.show.podcast_title,
        showId,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow: data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website,
        r2Uploaded: data.audio_files?.length > 0
      })
    })
    .catch((err) => {
      console.log(err)
    })

  },[id, showId])

  useEffect(() => {
    if(loaded && add_wallet === '?add_wallet=alby') {
      handleAblyClick();
    }
  }, [loaded])

  const handleInputChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setItem({...item, [name]: value});
  }

  const handleRecipientChange = (data) => {
    const {name, value, idx} = data;
    const newItems = [...items];
    newItems[idx][name] = value;
    setItems(newItems)
  }

  const handleDeleteRecipient = (idx) => {
    const newItems = [...items];
    const item_id = newItems[idx]['id'];
    newItems.splice(idx, 1)
    setItems(newItems)
    if(item_id) {
      justcastApi.delete(`/v1/audioposts/${id}/value_recipients/${item_id}`)
      .then((res) => {
        const {value_recipients, deleted_value_recipient} = res.data;
        setItems(value_recipients);
        add({
          color: "primary",
          title: 'Remove Value Recipient',
          message: deleted_value_recipient ? `Deleted Value Recipient ${deleted_value_recipient.name}` : "Deleted Value Recipient",
          isOpen: true,
          loading: false
        });
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }

  const handleAddNewRecipient = () => {
    const newItems = [...items];
    newItems.push({
      name: "",
      value_type:"",
      address: "",
      fee: 0,
      split: 10,
      custom_key: "",
      custom_value: "",
    })

    setItems(newItems);
  }

  const handleSave = () => {
    const valueRecipients = items.map((x, idx) => ({...x, id: idx+1}))

    justcastApi.put(`/v1/shows/${showId}/audioposts/${id}`, {
      ...item, value_recipients: valueRecipients
    })
    .then((res) => {
      const data = res.data;
      setItem(data);
      const {value_recipients} = data;
      setItems(value_recipients ? value_recipients : [])
      add({
        color: "primary",
        title: 'Update Value for Value',
        message: 'Updated settings and recipients',
        isOpen: true,
        loading: false
      })
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const addAlbyToItems = (alby_wallet) => {
    const {keysend_custom_key, keysend_custom_value, keysend_pubkey, lightning_address} = alby_wallet;
    const newItem = {
      name: lightning_address,
      value_type:"node",
      address: keysend_pubkey,
      custom_key: keysend_custom_key,
      custom_value: keysend_custom_value,
      fee: 0,
      split: 0
    }
    const newItems = [newItem, ...items];
    setItems(newItems)
  }

  const handleAblyClick = () => {
    if(alby_wallet_connections.length > 0) {
      add({
        color: "primary",
        title: 'Ably',
        message: 'Getting wallet data from Ably',
        isOpen: true,
        loading: true
      });

      justcastApi.get(`/v1/alby_connections/value4value`)
      .then((res) => {
        addAlbyToItems(res.data);
        add({
          color: "primary",
          title: 'Ably',
          message: 'Getting wallet data from Ably',
          isOpen: false,
          loading: false
        });
      })
      .catch((err) => {
        console.log(err)
        add({
          color: "primary",
          title: 'Ably',
          message: 'Getting wallet data from Ably',
          isOpen: false,
          loading: false
        });
      })
    } else {
      createAlbyConnection(`show_id=${showId}&audiopost_id=${id}`)
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <MainForm
              value_type={item.value_type}
              value_method={item.value_method}
              value_suggested_amount={item.value_suggested_amount}
              handleChange={handleInputChange}
            >
              <div style={{marginBottom: "20px"}}>
                <AddValueRecipient handleAddClick={handleAddNewRecipient} handleAblyClick={handleAblyClick}/>
                <ValueRecipients
                  items={items}
                  handleChange={handleRecipientChange}
                  handleDelete={handleDeleteRecipient}
                />
                <div className="btn btn-block btn-primary" onClick={handleSave}>Save change</div>
              </div>
            </MainForm>
          </div>
        </div>
      </div>
    </>
  )
}

export default EpisodeValueForValue;