import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const MigrationButton = ({allowMigration}) => {
  const url = `${process.env.REACT_APP_V1_RAILS_REDIRECT}/admin/migration`;

  if(allowMigration) {
    return (
      <Button color="primary"
      type="button"
      onClick={() => {
        window.open(url, '_blank');
      }}
      >Migrate</Button>
    )
  } 
  
  return null;
}

const AuthModal = ({message, isOpen, className, allowMigration, buttonLabel}) => {
  const [modal, setModal] = useState(isOpen);
  const toggle = () => setModal(!modal);


  useEffect(() => {
    setModal(isOpen);
  }, [isOpen])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader>Message</ModalHeader>
        <ModalBody>
          {message}
        </ModalBody>
        <ModalFooter>
          <MigrationButton allowMigration={allowMigration}/>
          <Button color="secondary" onClick={toggle}>{buttonLabel ? buttonLabel : 'Cancel'}</Button>
        </ModalFooter>
      </Modal>    
    </>
  )
}

export default AuthModal;