import React, {useState, useEffect}  from 'react'
import { Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, ButtonGroup } from 'reactstrap';
import {useDropzone} from 'react-dropzone'
import {imageSizeChecker} from './ImageSizeChecker'

const FileUploadDropzone = ({squareArtwork, acceptFile, handleFileChange, handleMessages, handleMessageTitle, handleUploadFileChange, setConvertToMp3}) => {
  
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal)
  }
  const handleChooseConvertAudioFile = (is_convert) => {
    setModal(false);
    setConvertToMp3(is_convert);
  }

  const {acceptedFiles, rejectedFiles, getRootProps, getInputProps} = useDropzone({
    accept: acceptFile,
    onDrop: (acceptedFiles, rejectedFiles) => {
      const errors = [];

      if(rejectedFiles.length > 0) {
        
        // const errors = [`Only ${acceptFile.join(',')} will be accepted`];
        errors.push(`Only ${acceptFile.join(',')} will be accepted`)
        rejectedFiles.forEach((file) => {          
          errors.push(`Rejected file: ${file.path}`)
        })
        
        //setImage(null);
        // setErrors(errors)        
        handleMessageTitle('Errors');
        handleFileChange('', '', '');
        handleUploadFileChange(null);
      }

      if(acceptedFiles.length > 0) {
        
        imageSizeChecker({acceptedFiles}, ({width, height}) => {
          if(squareArtwork && width !== height) {
            errors.push(`This image must be a square. But the width x height is ${width} x ${height}`);
            handleMessages(errors);
            handleFileChange('', '', '');
          }
          // errors.push(err);
        })

        if(errors.length === 0) {
          const src = URL.createObjectURL(acceptedFiles[0]);        
          const file = acceptedFiles[0];        
          const name = file['name'];
          const size = `${ Math.floor(file['size'] * 100 / (1024 * 1024)) / 100} MB`;
          const type = file['type'];
          const messages = [name, type, size];

          if(!squareArtwork && ['audio/mp3', 'audio/mpeg'].indexOf(type) === -1) {
            // raise question if user want to convert the file to mp3
            setModal(true)
          }
          handleUploadFileChange(file);
          handleMessages(messages);
          handleMessageTitle('Success');        
          handleFileChange(src, file['size'], type);
        }
        // imageOnDrop(acceptedFiles, setImage, setFiles, setErrors)
      }   
      if(errors.length > 0) {
        handleMessages(errors);
      }                  
    }
  });

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle}>Convert audio file</ModalHeader>
        <ModalBody>
          <p>This audio file is not a mp3. Spotify requires all audio to be in ISO/IEC 11172-3 MPEG-1 Part 3 (MP3) audio with bitrates between 96 and 320 kbps. In other words, an audio mp3 file with good quality audio.</p>
          <p>Would you like us to convert your into a mp3 with 128 kbps?</p>
          <ButtonGroup>
            <Button color="primary" onClick={() => handleChooseConvertAudioFile(true)}>Yes</Button>
            <Button color="secondary" onClick={() => handleChooseConvertAudioFile(false)}>No</Button>
          </ButtonGroup>
        </ModalBody>
      </Modal>
      <div {...getRootProps({className: 'dropzone'})}>
        <div className="dz-default dz-message">
          <input {...getInputProps()}/>
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
      </div>
    </>     
  )  
}

export default FileUploadDropzone;