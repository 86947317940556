import React, {useContext, useState, useEffect} from "react";
import { Redirect } from "react-router-dom";
import { Form, Row, Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import justcastApi from '../../api/justcast';
import {Context as ScreenContext} from '../../context/ScreenContext'
import {Context as MenuContext} from '../../context/MenuContext'
import ToastMessage from '../../components/ToastMessage'
import { NewActionForm, ActionListItem } from './components'

const AdvancedDripFeedSettings = (props) => {
  const showId = props.match.params.show_id;
  const [timeoutId, setTimeoutId] = useState(null);

  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)

  const [show, setShow] = useState({});
  const [denied, setDenied] = useState(false);
  const [toast, setToast] = useState({open: false});

  const [initiators, setInitiators] = useState([]);
  const [actions, setActions] = useState([]);
  const [errorMessages, setErrorMessage] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false)

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v2/shows/${showId}/show_scheduler_actions.json`)
    .then((res) => {
      setShow(res.data.show);
      setInitiators(res.data.initiators)
      setActions(res.data.show_scheduler_actions)
      screenContext.finishFetching();

      if(res.data.show.is_advanced_private_feed !== true) {
        setDenied(true)
      }
      menuContext.setPrivateSubscribersrMenu({
        title: 'Settings',
        subtitle: res.data.show.name,
        currentPageId: 'subscribers',
        currentTabId: 'pragmatic-feed',
        showId: showId,
        passwordProtected: res.data.show.is_password_protected,
        advancedPrivate: res.data.show.is_advanced_private_feed,
        isPrivate: res.data.show.is_private,
        isPrivateShow: res.data.show.is_private_show
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true)
    })
  }, [showId])

  const handleCreate = (data) => {
    justcastApi.post(`/v2/shows/${showId}/show_scheduler_actions.json`, data)
    .then((res) => {
      setActions([res.data, ...actions])
      setToast((prev) => ({...prev, title: "Success", message: "Action added.", open: true}))
    })
    .catch((err) => {
      const data = err.response?.data;
      setOpenErrorModal(true);
      setErrorMessage(data.messages)
    })
  }

  const handleDelete = (action_id) => {
    justcastApi.delete(`/v2/shows/${showId}/show_scheduler_actions/${action_id}.json`)
    .then((res) => {
      const deletedId = res.data.id;
      setActions(actions.filter((action) => action.id !== deletedId))
      // setActions([res.data, ...actions])
      setToast((prev) => ({...prev, title: "Success", message: "Action removed.", open: true}))
    })
    .catch((err) => {
      const data = err.response?.data;
      setOpenErrorModal(true);
      setErrorMessage(data.messages)
    })
  }

  const toggleToast = () => {
    const open = !toast.open;
    setToast((prev) => ({...prev, open}))
  }

  const toggle = () => {
    setOpenErrorModal(false)
    setErrorMessage([])
  }

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  return (
      <>
        <ToastMessage
          show={toast.open}
          title={toast.title}
          message={toast.message}
          toggle={toggleToast}
        />
        <Modal isOpen={openErrorModal} toggle={toggle}>
          <ModalHeader>Error</ModalHeader>
          <ModalBody>
            <ul>
              {
                errorMessages.map((error, id) => <li key={id}>{error}</li>)
              }
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
        <div className="container-fluid">
          <NewActionForm
            handleCreate={handleCreate}
            initiatorOptions={initiators}
          />
          <Card>
            <CardHeader>
              <h4 className="card-header-title">Actions</h4>
            </CardHeader>
            <CardBody>
              <div className="list-group list-group-flush my-n3">
                {
                  actions.map((action) => <ActionListItem key={action.id} id={action.id} action_name={action.action_name} action_value={action.action_value} initiator_name={action.initiator_name} action_unit={action.action_unit} handleDelete={handleDelete}/>)
                }
              </div>
            </CardBody>
          </Card>
        </div>
      </>
    )
  }

export default AdvancedDripFeedSettings;