import React, {useContext}  from "react";
import { Button, ButtonGroup, Badge } from 'reactstrap';
import {Link} from 'react-router-dom'
import moment from 'moment';
import {Context as ModalContext} from '../context/ModalContext'
import justcastApi from '../api/justcast'
import EpisodeListItemLink from "./EpisodeListItemLink";

const EpisodeListItem = (props) => {
  const { linkStatus, isDraft, publishStatus, showId, audiopostId, name,fileName, enabled_mailing, audioDate, isDirectUpload, downloadCount, r2Uploaded} = props;
  const { destroyDirectPodcast, add } = useContext(ModalContext);
  // const episodeLink = r2Uploaded ? `/podcasts/${showId}/episodes/${audiopostId}` : `/shows/${showId}/episodes/${audiopostId}`;

  const handleDeleteClicked = () => {
    destroyDirectPodcast({
      title: 'Are you absolutely sure?',
      name: name,
      message: "Unexpected bad things will happen if you don’t read this!",
      messages: [
        `This action cannot be undone. This will permanently delete the ${name} episode, download statistics, artworks, audiogram and remove all collaborator associations.`,
        `Please type ${name} to confirm.`
      ],
      deleteButtonLabel: "episode",
      endpoint: `/${r2Uploaded ? "v2" : "v1"}/shows/${showId}/audioposts/${audiopostId}`
    })
  }

  const handleSendTestEmail = () => {
    add({
      color: "primary",
      title: 'Email campaign',
      message: "Sending test email campaign",
      isOpen: true,
      loading: true
    });

    justcastApi.post(`/v1/audioposts/${audiopostId}/send_campaign_test_email`)
    .then((res) => {
      const message = res.data.message;
      add({
        color: "primary",
        title: 'Email campaign',
        message: message,
        isOpen: true,
        loading: false
      });
    })
    .catch((err) => {
      console.log(err.response.message);
      // const message = res.data.message;
      add({
        color: "primary",
        title: 'Email campaign',
        message: "Failed to send email campaign",
        isOpen: true,
        loading: false
      });
    })
  }

  const handleSendEmail = () => {
    add({
      color: "primary",
      title: 'Email campaign',
      message: "Sending email campaign",
      isOpen: true,
      loading: true
    });

    justcastApi.post(`/v1/audioposts/${audiopostId}/send_campaign`)
    .then((res) => {
      const message = res.data.message;
      add({
        color: "primary",
        title: 'Email campaign',
        message: message,
        isOpen: true,
        loading: false
      });
    })
    .catch((err) => {
      console.log(err.response.message);
      // const message = res.data.message;
      add({
        color: "primary",
        title: 'Email campaign',
        message: "Failed to send email campaign",
        isOpen: true,
        loading: false
      });
    })
  }

  return (
    <div className="list-group-item">
      <div className="row align-items-center">
        {/* <div className="col-auto">
          <input
            onChange={handleChange}
            type="checkbox"
            id="is_draft"
            name="is_draft"
            value={isDraft}
            checked={isDraft}
            />
        </div> */}
        <div className="col ml-n2">
          <h4 className="mb-1">
            {/* <Link to={episodeLink}>{name}</Link> */}
            <EpisodeListItemLink
              name={name}
              showId={showId}
              audiopostId={audiopostId}
              r2Uploaded={r2Uploaded}
              linkStatus={linkStatus}
            />
          </h4>
          <p className="text-muted mb-0">Publish date: {moment(audioDate).format("MMM Do YYYY, h:mm:ss a")}</p>
          {!isDirectUpload && <p className="text-warning mb-0">File name: {fileName}</p>}
          <h2 style={{display: 'flex', gap: "10px", marginTop: "10px"}}>
            {downloadCount && <Badge color="warning">Downloads: {downloadCount}</Badge>}
            <Badge color="primary">{publishStatus}</Badge>
          </h2>
        </div>
        <div className="col-auto">
        {
          enabled_mailing ?
          <ButtonGroup size="sm" style={{marginRight: '10px'}}>
            <Button color="primary" onClick={handleSendTestEmail}>Send test email</Button>
            <Button color="warning" onClick={handleSendEmail}>Send email</Button>
          </ButtonGroup> : null
        }
        {
          isDirectUpload ?
            <div className="btn btn-danger btn-sm" onClick={handleDeleteClicked}>
              <i className="fe fe-trash-2"/> Delete
            </div>
           : null
        }
        </div>
      </div>
    </div>
  )
}

export default EpisodeListItem;