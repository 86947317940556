import React from "react";

const CardAvatar = ({avatar}) => {
  if(avatar) {
    return (
      <div className="avatar avatar-md">
        <img src={avatar} className="avatar-img"/>
      </div>
    )
  }
  return null;
}

export default CardAvatar