import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner} from 'reactstrap';

const Body = ({loading, successMessage, errorMessage}) => {
  if(loading) {
    return <Spinner/>
  } else if(successMessage) {
    return (
      <>
        <p>{successMessage}</p>
      </>
    )
  } else if (errorMessage) {
    return <p>{errorMessage}</p>
  } else {
    return null;
  }
}

export default function DropboxFilePickerModal(props) {
  const {
    modal,
    toggle,
    loading,
    successMessage,
    errorMessage
  } = props;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>adding your files...</ModalHeader>
      <ModalBody>
        <Body loading={loading} successMessage={successMessage} errorMessage={errorMessage}/>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
