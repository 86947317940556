import React from "react";
import { Form, FormGroup, Row, Input, Button, Card, CardBody } from 'reactstrap';
import 'flatpickr/dist/themes/dark.css'
import Flatpickr from 'react-flatpickr'
import Select from 'react-select'
import PublishOptions from '../../episode/PublishOptions'
import Editor from '../../episode/Editor'
import {LocationInput} from '../../location';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
  })
}

export default function EditForm(props) {
  const {
    name,
    quillRef,
    explicitTypes,
    episodeTypes,
    description,
    explicit_type_id,
    episode_type_id,
    audio_date,
    episode_number,
    seasons,
    season_id,
    itunes_keywords,
    location_name,
    is_draft,
    publishStatus,
    handleInputChange,
    setDescription,
    handleLocationChange,
    handleSubmit
  } = props;

  const handleDatepickerChange = (date) => {
    handleInputChange({target: {name: "audio_date", value: date[0]}})
  }

  const handlePublishStatusChange = (e) => {
    handleInputChange({target: {name: 'is_draft', value: e.value}})
  }

  const handleSelectChange = (inputName, event) => {
    handleInputChange({target: {name: inputName, value: event.id}})
  }

  const handleQuillEditorChange = (e) => {
    // setDescription(e);
  }

  return (
    <Form className="mb-4" onSubmit={handleSubmit}>
      <FormGroup>
        <label>Name</label>
        <Input
          type="text"
          className="form-control"
          name="name"
          placeholder="The title of your new episode"
          value={name}
          onChange={handleInputChange}
        />
      </FormGroup>
      <Row>
        <div className="col-12 col-md-6">
          <FormGroup>
            <label style={{display: 'flex', justifyContent: 'space-between'}}>
              <span>Publish Date</span>
            </label>
            <Flatpickr
              className='form-control mb-3'
              data-enable-time
              value={audio_date}
              onClose={handleDatepickerChange}/>
              <small className="form-text text-warning">Pick a date and time for release.</small>
          </FormGroup>
        </div>
        <div className="col-12 col-md-6">
          <FormGroup>
            <label>Publish status</label>
            <div style={{marginBottom: '0.75rem'}}>
              <PublishOptions
                value={publishStatus}
                handleChange={handlePublishStatusChange}
              />
            </div>
            <div>
              <small className="form-text text-warning">{is_draft ? "Manually publish when you’re ready." : "Goes live after processing is complete."}</small>
            </div>
          </FormGroup>
        </div>
      </Row>
      <div>
        {props.children}
      </div>
      <Row>
        <div className="col-12 col-md-6">
          <FormGroup>
            <label><span>Season</span></label>
            <div style={{marginBottom: '0.75rem'}}>
              {
                seasons.length > 0 ? <Select
                value={seasons.filter((x) => x.id === season_id)[0]}
                options={seasons}
                styles={customStyles}
                onChange={(e) => {
                  handleSelectChange('season_id', e)
                }}
                /> : <Input className='form-control mb-3' name="season" disabled/>
              }
              {
                seasons.length == 0 && <div><small className="form-text text-warning">The season option isn't available currently. Please ask the podcast owner to create one</small></div>
              }
            </div>
          </FormGroup>
        </div>
        <div className="col-12 col-md-6">
          <FormGroup>
            <label>Episode Number</label>
            <Input
              type="text"
              className="form-control"
              name="episode_number"
              placeholder="1, 2, 3..."
              value={episode_number ? episode_number : ""}
              onChange={handleInputChange}
            />
          </FormGroup>
        </div>
      </Row>
      <Row>
        <div className="col-12 col-md-6">
          <FormGroup>
            <label><span>Explicit type</span></label>
            <Select
              value={explicitTypes.filter((x) => x.id === explicit_type_id)[0]}
              options={explicitTypes}
              styles={customStyles}
              onChange={(e) => {
                handleSelectChange('explicit_type_id', e)
              }}
            />
            <div style={{marginTop: '0.75rem'}}>
              <small className="form-text text-muted">{explicitTypes.filter((x) => x.id === explicit_type_id)[0]['description']}</small>
            </div>
          </FormGroup>
        </div>
        <div className="col-12 col-md-6">
          <FormGroup>
            <label><span>Episode type</span></label>
            <Select
              value={episodeTypes.filter((x) => x.id === episode_type_id)[0]}
              options={episodeTypes}
              styles={customStyles}
              onChange={(e) => {
                handleSelectChange('episode_type_id', e)
              }}
            />
            <div style={{marginTop: '0.75rem'}}>
              <small className="form-text text-muted">Use Trailer for a short, promotional piece of content that represents a preview of a show.  Use bonus for extra content (for example, behind the scenes information or interviews with the cast)</small>
            </div>
          </FormGroup>
        </div>
      </Row>
      <hr/>
      <FormGroup>
        <label>Description</label>
        <Editor
          quillRef={quillRef}
          value={description}
          handleQuillEditorChange={handleQuillEditorChange}
        />
      </FormGroup>
      <Row>
        <div className="col-12">
          <FormGroup>
            <label>Search Keywords</label>
            <Input
              type="text"
              className="form-control"
              name="itunes_keywords"
              placeholder="e.g. business,investment"
              value={itunes_keywords || ""}
              onChange={handleInputChange}
            />
            <div style={{marginTop: '0.75rem'}}><small className="form-text text-muted">Add relevant search keywords about your episode to help people find it.</small></div>
          </FormGroup>
        </div>
      </Row>
      <Row>
        <div className="col-12">
          <LocationInput
            handleLocationChange={handleLocationChange}
            locationName={location_name}
          />
        </div>
      </Row>
    </Form>
  )
}
