import React from "react";
import { Form, FormGroup, Input, Button, Row, Col} from 'reactstrap';
import FormGroupInputSwitch from '../components/FormGroupInputSwitch';
import FormGroupInput from '../components/FormGroupInput';

const WidgetSettingForm = (props) => {
  const {
    hide_widget_subscribe, 
    hide_widget_share, 
    playlist_full_height,
    hide_widget_pub_date, 
    is_advanced_private_feed,
    block_widget,
    hide_more_info_from_widget,
    handleToggleChange, 
    handleSubmit
  } = props;

  return (
    <Form className="mb-4" onSubmit={handleSubmit}>
      <Row>
        <Col className="md-6">
          {props.children}
        </Col>
        <Col className="md-6">
          <FormGroupInputSwitch          
            inputType='switch'
            inputName='hide_widget_pub_date'
            inputLabel="Hide publish date"
            mutedText="If you choose to hide publish date, the publish date will not show on the widget player."
            inputValue={hide_widget_pub_date}
            inputCSSId='hide-widget-pub-date-switch'
            handleInputChange={handleToggleChange}
          />
        </Col>
      </Row>
      {/* <hr/>
      <FormGroupInputSwitch          
        inputType='switch'
        inputName='hide_widget_subscribe'
        inputLabel="Hide subscribe tab"
        mutedText="If you choose to hide subscribe tab, the subscribe tab will not show on the widget player."
        inputValue={hide_widget_subscribe}
        inputCSSId='hide-widget-subscribe-switch'
        handleInputChange={handleToggleChange}
      />    
      <hr/>
      <FormGroupInputSwitch          
        inputType='switch'
        inputName='hide_widget_share'
        inputLabel="Hide share tab"
        mutedText="If you choose to hide share tab, the share tab will not show on the widget player."
        inputValue={hide_widget_share}
        inputCSSId='hide-widget-share-switch'
        handleInputChange={handleToggleChange}
      /> */}
      <hr/>
      <Row>
        <Col className="md-6">
          <FormGroupInputSwitch
            inputType='switch'
            inputName='hide_more_info_from_widget'
            inputLabel="Hide more info"
            mutedText="If you choose to hide more info tab, the more info tab will not show on the widget player."
            inputValue={hide_more_info_from_widget}
            inputCSSId='hide_more_info_from_widget-switch'
            handleInputChange={handleToggleChange}
          />  
        </Col>
        <Col className="md-6">
          <FormGroupInputSwitch          
            inputType='switch'
            inputName='playlist_full_height'
            inputLabel="Show all episodes"
            mutedText="If you choose to show all episodes, then player shows all the tracks."
            inputValue={playlist_full_height}
            inputCSSId='playlist_full_height-switch'
            handleInputChange={handleToggleChange}
          />    
        </Col>
      </Row>    
      {/* {
        is_advanced_private_feed ? 
        <>
          <hr/>
          <FormGroupInputSwitch          
            inputType='switch'
            inputName='block_widget'
            inputLabel="Allow widget to show up on allowed sites"
            mutedText="To have the player widget to show up on your webpage, you will need to add your webpage URL to allowed site."
            inputValue={block_widget}
            inputCSSId='hide-widget-switch'
            handleInputChange={handleToggleChange}
          />          
        </> : null
      } */}
    </Form>
  )
}

export default WidgetSettingForm;