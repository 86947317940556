import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../../api/justcast';
import {Context as MenuContext} from '../../context/MenuContext'
import NewForm from './NewForm';
import { Redirect } from 'react-router-dom'

const NewAd = () => {
  const { setDynamicContentMenu } = useContext(MenuContext);
  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    setDynamicContentMenu({
      title: 'New File',
      subtitle: "Dynamic Contents",
      currentPageId: 'dynamic-contents',
      currentTabId: "dynamic-contents"
    });
  }, [])

  const handleSave = (event) => {
    const formData = new FormData();
    formData.append("title", event.title);
    formData.append("duration", event.duration);
    formData.append("description", event.description);
    formData.append("file", event.file[0])

    justcastApi.post(`/v2/ad_contents`, formData)
    .then((res) => {
      setIsRedirect(true);
    })
    .catch((err) => {

    })
  }

  if(isRedirect) {
    return <Redirect to='/dynamic-contents'/>
  }

  return (
    <div className="container-fluid">
      <NewForm handleSave={handleSave} />
    </div>
  )
}


export default NewAd;