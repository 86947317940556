import React, {useEffect, useState, useContext, useRef} from "react";
import ReactPlayer from 'react-player';
import axios from 'axios';
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import AudioInputRange from './AudioInputRange'
import AudioPlayerButton from './AudioPlayerButton'
import TimeString from '../../libs/TimeString'
import WaveformElementDrawer from './WaveformElementDrawer'

const timeStringHandler = new TimeString();

const PodieoAudio = () => {
  const playRef = useRef(null);

  const {state, updateState} = useContext(PodieoAudioContext);
  const { audioContext, playerDivId} = state;
  
  const updateDuration = (seconds) => {    
    if(seconds) {
      const clipEndSeconds = seconds > state.maxClipDuration ? state.maxClipDuration : seconds / 2
      const clipEndText = timeStringHandler.getTimeString(clipEndSeconds)      
      updateState({duration: seconds,  clipEndSeconds, clipEndText, onReady: true})
      
      // setTimeout(() => {
      //   initAudioContext()
      // }, 1000)
    }
  }

  const updateOnProgress = (seconds) => {
    if(seconds && seconds.playedSeconds) {
      if(seconds.playedSeconds >= state.clipEndSeconds) {
        updateState({playedSeconds: seconds.playedSeconds, playing: false })  
      } else {
        updateState({playedSeconds: seconds.playedSeconds })
        // if(audioInputRangeRef.current.clientWidth && audioInputRangeRef.current.clientWidth > 0) {
        //   const page = (seconds.playedSeconds * 2) % audioInputRangeRef.current.clientWidth;
        //   audioInputRangeRef.current.scrollLeft = page * audioInputRangeRef.current.clientWidth;
        // }        
      }      
    }    
  }

  const handlePlayPause = () => {    
    // if(!state.playing && playRef) {
    //   playRef.seekTo(state.playedSeconds, 'seconds');
    // }
    if(state.playedSeconds >= state.clipEndSeconds) {
      playRef.current.seekTo(state.clipFromSeconds, 'seconds')    
      updateState({playing: !state.playing, playedSeconds: state.clipFromSeconds})
    } else {
      playRef.current.seekTo(state.playedSeconds, 'seconds')
      updateState({playing: !state.playing})
    }        
  }

  const initAudioContext = () => {    
     
    if( audioContext === null) {
      const _audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const _analyser = _audioContext.createAnalyser();
      const _audioEle = document.getElementById(playerDivId);
      const _source = _audioContext.createMediaElementSource(_audioEle);
      
      _source.connect(_analyser);
      _source.connect(_audioContext.destination);
      // get decoded audio data
      updateState({
        audioContext: _audioContext,
        audioAnalyser: _analyser,
        audioEle: _audioEle,
        audioSource: _source
      })
    }
  }
  
  useEffect(() => {        
    const playAble = ReactPlayer.canPlay(state.url);
    updateState({playAble});
  }, [state.url])

  return (
    <>
      <ReactPlayer
        // id={playerDivId}
        width="0" 
        height="0"      
        ref={playRef}
        url={state.url}
        config={{ file: { attributes: { 
          id: playerDivId,
          crossOrigin: "anonymous"
          // crossOrigin: "true"
        } } }}          
        onDuration={updateDuration}
        playing={state.playing}
        progressInterval={100}
        onProgress={updateOnProgress}
      />
      <div style={{display: "flex"}}>
        <AudioPlayerButton handlePlayPause={handlePlayPause}/>
        <AudioInputRange />
      </div>
      <WaveformElementDrawer />
    </>
  )
}

export default PodieoAudio;