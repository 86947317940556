import React from "react";
import CloudDriveCard from './CloudDriveCard';
import {getDropboxAuthUrl} from '../libs/DropboxOAuth';
import {getAzureAppAuthUrl} from '../libs/AzureAppOAuth';

const setupConnection = (clouddrive) => {
  switch(clouddrive) {
    case 'dropbox':
      getDropboxAuthUrl((url) => {
        if(url) {
          window.location = url;
        }
      });
    case 'onedrive':
      return getAzureAppAuthUrl();
    default:
      return null;
  }
}

const CloudDriveList = (params) => {
  const {connections, handleSync, handleDestroy, toggleModal} = params;

  const handleOnClick = (e) => {
    const index = e.target.id;
    const connection = connections[index];
    const action = e.target.name;

    if(action === 'setup' || action === 'reconnect') {
      setupConnection(connection.type);
    } else if(action === 'sync') {
      if(connection['data']) {
        const id = connection['data']['id'];
        const type = connection['type'];
        handleSync(type, id);
      }
    } else if (action === 'delete') {
      if(connection['data']) {
        const id = connection['data']['id'];
        const type = connection['type'];
        handleDestroy(type, id)
      }
    } else if (action === 'settings') {
      toggleModal();
    }
    // console.log(connection)
    // console.log(e.target.name);
    // console.log(e.target.id);

  }

  return (
    connections.map((clouddrive, idx) =>
      <div className="col-12 col-lg-6" key={idx}>
        <CloudDriveCard
          id={idx}
          data={clouddrive.data}
          name={clouddrive.name}
          isActive={clouddrive.active}
          handleOnClick={handleOnClick}
        />
      </div>
    )
  )

}

export default CloudDriveList;