import React, {useEffect, useState, useContext} from "react";
import { Provider } from "jotai";
import justcastApi from '../../api/justcast';
import {Context as ModalContext} from '../../context/ModalContext'
import {Context as MenuContext} from '../../context/MenuContext'
import {Chapters, AudioChapterMakerPlayer, ChapterForm} from '../../components/episode-chapters'

const EpisodeChaptersPage = (props) => {
  const modalContext = useContext(ModalContext);
  const [loading, setLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const {show_id, id} = props.match.params;
  const {setEpisodePageMenu} = useContext(MenuContext);
  const [chapters, setChapters] = useState([]);
  const [audioSrc, setAudioSrc] = useState(null)

  useEffect(() => {
    justcastApi.get(`/v2/shows/${show_id}/audioposts/${id}/chapters`)
    .then((res) => {
      setLoading(false);
      const data = res.data
      setChapters(data.chapters)
      setAudioSrc(data.audiopost.audio_src);
      setEpisodePageMenu({
        title: "Chapters",
        subtitle: `${data.show.podcast_title} > ${data.audiopost.episode_title}`,
        currentPageId: "episodes",
        currentTabId: "chapters",
        showName: data.show.podcast_title,
        showId: show_id,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow:data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website,
        r2Uploaded: data.audiopost.r2_uploaded
      })
    })
    .catch((err) => {
      //TODO alert something went wrong
      setLoading(false)
      setPageError(true);
      console.log(err)
    })
  },[show_id, id])

  const handleAddChapter = (data, cb) => {
    justcastApi.post(`/v2/shows/${show_id}/audioposts/${id}/chapters`, data)
    .then((res) => {
      setChapters(res.data.chapters);
      modalContext.addWithMessages({
        color: "primary",
        title: 'Success',
        messages: ["Added a new chapter"],
        isOpen: true,
        loading: false
      })
      if(cb) {
        cb()
      }
    })
    .catch((err) => {
      const messages = err.response.data.messages
      const keys = Object.keys(messages);
      const msgs = keys.map((key) => `${key} ${messages[key].join(", ")}`)
      modalContext.addWithMessages({
        color: "primary",
        title: 'Success',
        messages: msgs,
        isOpen: true,
        loading: false
      })
    })
  }

  const handleUpdateChapter = (chapterId, data, cb) => {
    justcastApi.put(`/v2/shows/${show_id}/audioposts/${id}/chapters/${chapterId}`, data)
    .then((res) => {
      setChapters(res.data.chapters)
      modalContext.addWithMessages({
        color: "primary",
        title: 'Success',
        messages: ["Chapter has updated"],
        isOpen: true,
        loading: false
      })
      if(cb) {
        cb()
      }
    })
    .catch((err) => {
      const messages = err.response.data.messages
      const keys = Object.keys(messages);
      const msgs = keys.map((key) => `${key} ${messages[key].join(", ")}`)
      modalContext.addWithMessages({
        color: "primary",
        title: 'Success',
        messages: msgs,
        isOpen: true,
        loading: false
      })
    })
  }

  const handleDestroyChapter = (chapterId) => {
    justcastApi.delete(`/v2/shows/${show_id}/audioposts/${id}/chapters/${chapterId}`)
    .then((res) => {
      setChapters(res.data.chapters)
      modalContext.addWithMessages({
        color: "primary",
        title: 'Success',
        messages: ["Chapter has been deleted"],
        isOpen: true,
        loading: false
      })
    })
    .catch((err) => {
      const messages = err.response.data.messages
      const keys = Object.keys(messages);
      const msgs = keys.map((key) => `${key} ${messages[key].join(", ")}`)
      modalContext.addWithMessages({
        color: "primary",
        title: 'Success',
        messages: msgs,
        isOpen: true,
        loading: false
      })
    })
  }

  const handleAddMarker = ({duration}) => {
    justcastApi.post(`/v2/shows/${show_id}/audioposts/${id}/add_chapter_marker`, {duration})
    .then((res) => {
      modalContext.addWithMessages({
        color: "primary",
        title: 'Success',
        messages: [res.data.message],
        isOpen: true,
        loading: false
      })
    })
    .catch((err) => {
      console.log(err)
      modalContext.addWithMessages({
        color: "primary",
        title: 'Success',
        messages: ["Something went wrong"],
        isOpen: true,
        loading: false
      })
    })
  }

  if(loading || pageError) return null;

  return (
    <Provider>
      <div className="container-fluid">
        <div>
          <AudioChapterMakerPlayer url={audioSrc}/>
          <Chapters
            chapters={chapters}
            handleDestroyChapter={handleDestroyChapter}
            handleAddMarker={handleAddMarker}
          >
            <ChapterForm handleCreate={handleAddChapter} handleUpdate={handleUpdateChapter}/>
          </Chapters>
        </div>
      </div>
    </Provider>
  )
}

export default EpisodeChaptersPage;