import config from './AzureAppConfig';

// https://docs.microsoft.com/en-us/onedrive/developer/rest-api/getting-started/graph-oauth?view=odsp-graph-online#code-flow
// Code flow
// This also allows your application to receive a refresh token that will enable long-term use of the API in some scenarios, to allow access when the user isn't actively using your application.

// GET https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id={client_id}&scope={scope}&response_type=code&redirect_uri={redirect_uri}

// Response
// https://myapp.com/auth-redirect?code=df6aa589-1080-b241-b410-c4dff65dbf7c

// Redeem the code for access tokens
// POST https://login.microsoftonline.com/common/oauth2/v2.0/token
// Content-Type: application/x-www-form-urlencoded
// client_id={client_id}&redirect_uri={redirect_uri}&client_secret={client_secret}
// &code={code}&grant_type=authorization_code

// Response
// {
//   "token_type":"bearer",
//   "expires_in": 3600,
//   "scope":"wl.basic onedrive.readwrite",
//   "access_token":"EwCo...AA==",
//   "refresh_token":"eyJh...9323"
// }
// export const getAzureAppAuthUrl = () => {
//   return new UserAgentApplication({
//     auth: {
//       clientId: config.appId,      
//       redirectUri: process.env.REACT_APP_AZURE_APP_REDIRECT_URL
//     }
//   })   
// }

export const getAzureAppAuthUrl = () => {
  const client_id = config.appId;
  const scope = config.scopes.join(" ");
  const redirect_uri = process.env.REACT_APP_AZURE_APP_REDIRECT_URL;
  return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&scope=${scope}
  &response_type=code&redirect_uri=${redirect_uri}`;
}

export const getAzureAppReedemParams = (code) => {
  const client_id = config.appId;
  const redirect_uri = process.env.REACT_APP_AZURE_APP_REDIRECT_URL;
  const client_secret = config.appSecret;

  return {
    client_id,
    redirect_uri,
    client_secret,
    code,
    grant_type: "authorization_code"
  }
}

export default {}