import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap';

export default function EpisodeListItemLink({
  r2Uploaded,
  showId,
  audiopostId,
  linkStatus,
  name
}) {

  if(r2Uploaded) {
    return <Link to={`/podcasts/${showId}/episodes/${audiopostId}`}>{name}</Link>
  } else if (linkStatus) {
    return <div>{name} <Badge>uploading</Badge></div>
  } else {
    return <Link to={`/shows/${showId}/episodes/${audiopostId}`}>{name}</Link>
  }
}
