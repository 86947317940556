import { atom } from "jotai";

const temp = {
  id: "",
  name: "Loading....",
  audio_url: "",
  duration: "",
  durationSeconds: 0,
  playedSeconds: 0,
  seekSeconds: 0,
  message: "loading...",
  onReady: false,
  playerRef: null,
  playing: false,
  onSeeking: false,
}

export const playerAtom = atom(temp)

export const addPlayerAtom = atom(null, (_get, set, item) => {
  set(playerAtom, () => {
    if(item) {
      const currentState = _get(playerAtom);
      // console.log(input.item)
      const {title, id, audio_url, artwork_url, duration} = item
      return {...currentState, title, id, audio_url, artwork_url, duration};
    }
  });
})

export const updatePlayerStatus = atom(null, (_get, set, item) => {
  set(playerAtom, () => {
    const currentState = _get(playerAtom);
    if(item) {
      return {...currentState, ...item};
    } else {
      return currentState
    }
  })
})

export const addPlayerRefAtom = atom(null, (_get, set, playerRef) => {
  set(playerAtom, () => {
    const currentState = _get(playerAtom);
    if(playerRef) {
      return {...currentState, playerRef: playerRef};
    } else {
      return currentState;
    }
  })
})

export const toggleSeekingAtom = atom(null, (_get, set, _) => {
  set(playerAtom, () => {
    const currentState = _get(playerAtom);
    const {playerRef} = currentState;
    const onSeeking = !currentState.onSeeking;
    if(onSeeking === true) {
      return {...currentState, onSeeking};
    } else {
      const seekSeconds = currentState.seekSeconds;
      playerRef.seekTo(seekSeconds, 'seconds')
      return {...currentState, onSeeking, playedSeconds: seekSeconds};
    }
  })
})

export const togglePlayPauseAtom = atom(null, (_get, set, _) => {
  set(playerAtom, () => {
    const currentState = _get(playerAtom);
    const {playing} = currentState;
    return {...currentState, playing: !playing};
  })
})

export const changeSeekSecondsAtom = atom(null, (_get, set, seconds) => {
  set(playerAtom, () => {
    const currentState = _get(playerAtom);
    return {...currentState, seekSeconds: seconds};
  })
})

export const SeekToSecondsAtom = atom(null, (_get, set, delta) => {
  set(playerAtom, () => {
    const currentState = _get(playerAtom);
    const {playedSeconds, durationSeconds, playerRef} = currentState;
    let seconds = playedSeconds + delta;
    if(seconds <= 0) {
      seconds = 0;
    } else if (seconds >= durationSeconds) {
      seconds = durationSeconds;
    }
    playerRef.seekTo(seconds, 'seconds')
    return {...currentState, seekSeconds: seconds};
  })
})

export const playInSecondsAtom = atom(null, (_get, set, _seconds) => {
  set(playerAtom, () => {
    const currentState = _get(playerAtom);
    const {playing, durationSeconds, playerRef} = currentState;
    let seconds = _seconds;
    if(seconds <= 0) {
      seconds = 0;
    } if (seconds >= durationSeconds) {
      seconds = durationSeconds;
    }
    playerRef.seekTo(seconds, 'seconds')
    if(playing === true) {
      return {...currentState, playedSeconds: seconds};
    } else {
      return {...currentState, playedSeconds: seconds, playing: true};
    }
  })
})

export const getProgressSecondsAtom = atom((get) => {
  const {onSeeking, seekSeconds, playedSeconds} = get(playerAtom);
  if(onSeeking) {
    return seekSeconds;
  } else {
    return playedSeconds;
  }
})
