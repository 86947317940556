import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../api/justcast';
import {CardItem, TwitterConnectedBody, MailchimpConnectedBody, MailchimpButtonForm} from '../components/IntegrationCard'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ModalContext} from '../context/ModalContext'
import twitter from '../assets/img/integrations/twitter.svg'
import mailchimp from '../assets/img/integrations/mailchimp.svg'

const ShowIntegrations = (props) => {
  const id = props.match.params.id;
  const [showName, setShowName] = useState('')
  const [item, setItem] = useState({});
  const [twitterConnection, setTwitterConnection] = useState({})
  const [mailchimpConnection, setMailchimpConnection] = useState({})
  const menuContext = useContext(MenuContext);
  const {add} = useContext(ModalContext);

  useEffect(() => {
    justcastApi.get(`/v1/shows/${id}/integrations`)
    .then((res) => {
      const name = res.data.podcast_title;
      setShowName(name);
      setItem(res.data);      
      if(res.data.twitter_connection) {
        setTwitterConnection(res.data.twitter_connection)
      }

      if(res.data.mailchimp_connection) {
        setMailchimpConnection(res.data.mailchimp_connection)
      }

      menuContext.setPodcastPageMenu({
        title: 'Integrations',
        subtitle: name,
        currentPageId: 'integrations',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website       
      });      
    })
    .catch((err) => {
      console.log(err)
    })        
  },[id])  

  const twitterConnect = () => {
    justcastApi.post(`/v1/shows/${id}/twitter_connections`)
    .then((res) => {
      const requestUrl = res.data['request_url'];
      // window.open(requestUrl);
      window.location.href = requestUrl;
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const twitterDelete = () => {
    const twitterId = twitterConnection.id;

    if(twitterConnection) {
      justcastApi.delete(`/v1/shows/${id}/twitter_connections/${twitterId}`)
      .then((res) => {
        setTwitterConnection({})
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }

  const mailchimpConnect = () => {
    justcastApi.post(`/v1/shows/${id}/mailchimp_connections`)
    .then((res) => {
      const redirect_url = res.data['redirect_url'];
      // window.open(requestUrl);
      window.location.href = redirect_url;
    })
    .catch((err) => {
      console.log(err)
    })
    // window.location.href = requestUrl;
  }

  const mailChimpDelete = () => {
    const mailchimpId = mailchimpConnection.id;

    if(mailchimpConnect) {
      justcastApi.delete(`/v1/shows/${id}/mailchimp_connections/${mailchimpId}`)
      .then((res) => {
        setMailchimpConnection({})
      })
      .catch((err) => {
        console.log(err)
      })
    }    
  }

  const handleMailchimpButtonChange = (changed) => {
    setMailchimpConnection({...mailchimpConnection, ...changed});
  }

  const handleMailchimpSubmit = () => {
    const mailchimpId = mailchimpConnection.id;
    justcastApi.put(`/v1/shows/${id}/mailchimp_connections/${mailchimpId}`, mailchimpConnection)
    .then((res) => {
      setMailchimpConnection(res.data);
      add({color: 'danger', title: "Subscribe button updated" , message: "Success updated the newsletter subscribe button settings.", isOpen: true})        
    })
    .catch((err) => {
      console.log(err)
      add({color: 'danger', title: "Uh oh! Failed to update the button text" , message: "Please screenshot this error and email us.", isOpen: true})        
    })
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <CardItem 
              title="Twitter Auto-tweeting"
              description="Connect with your Twitter account to allow JustCast to automatically post new tweet when episodes are published."
              buttonTitle={twitterConnection && twitterConnection.screen_name ? "Refresh" : "Sign in with Twitter"}
              icon={twitter}
              screenName={twitterConnection.screen_name ? `@${twitterConnection.screen_name}` : null}
              buttonClick={twitterConnect}                                                                      
              handleDelete={twitterDelete}
            >
              <TwitterConnectedBody
                screenName={twitterConnection.screen_name ? `@${twitterConnection.screen_name}` : null}
                accountUrl={`https://twitter.com/${twitterConnection.screen_name}`}
                message={"Auto-tweeting enabled.  Tweets will post at episode publishing time, similar to this example:"}
                exampleTitle={twitterConnection.screen_name}
                exampleSubtitle={`@${twitterConnection.screen_name} - 2h`}
                exampleText={`Just published a new episode of ${showName}: {{ Title of your episode }}.  Listen`}
                avatar={twitter}
              />
            </CardItem>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CardItem 
              title="Mailchimp Auto-sending"
              description="Connect with your Mailchimp account to allow JustCast to send new episode campaign to your newsletter subscribers."
              buttonTitle={mailchimpConnection && mailchimpConnection.campagin_web_id ? null : "Sign in with Mailchimp"}
              icon={mailchimp}
              screenName={mailchimpConnection.id ? "Connected" : null}
              buttonClick={mailchimpConnect}                                                                      
              handleDelete={mailChimpDelete}
            >
              <MailchimpConnectedBody
                campagin_web_id={mailchimpConnection.campagin_web_id}
                list_web_url={mailchimpConnection.list_web_url}
                campaign_web_url={mailchimpConnection.campaign_web_url}
                template_web_url={mailchimpConnection.template_web_url}
              >
                <MailchimpButtonForm 
                  show_form={mailchimpConnection.show_form}
                  button_text={mailchimpConnection.button_text}
                  success_message={mailchimpConnection.success_message}
                  button_title_message={mailchimpConnection.button_title_message}
                  handleChange={handleMailchimpButtonChange}
                  handleSubmit={handleMailchimpSubmit}
                />
              </MailchimpConnectedBody>
            </CardItem>
          </div>
        </div>        
      </div>
    </>
  )
}

export default ShowIntegrations;