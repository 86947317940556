import React from "react";
import { Card, CardBody} from 'reactstrap';

const PersonNote = (props) => {
  return (
    <Card>
      <CardBody>
        {props.children}
      </CardBody>
    </Card>
  )
}

export default PersonNote;