import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../api/justcast';
import {DateFormatCell, PlainTextCell, PennyToDollarFormatCell} from './../libs/TableCellFormat'
import { Button, Input, InputGroup, InputGroupAddon, Card, CardBody, CardHeader } from 'reactstrap';
import BoostrapTable from './BoostrapTable'
import {Context as AlertContext} from '../context/AlertContext'

// affiliate_code_id: 1
// commission_amount: null
// created_at: "2020-06-20T06:07:28.837Z"
// id: 1
// payout_date: null
// status: "registered"
// stripe_plan_id: null
// updated_at: "2020-06-20T06:07:28.837Z"
// user_id: 1

const AffiliatePayoutSummary = ({affiliateCode, payouts, handlePaypalChange}) => {
  const [copied, setCopied] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy');
  const {addWithTimeout} = useContext(AlertContext);

  const columns = [
    {
      Header: 'Signup Date',
      accessor: 'created_at',
      Cell: DateFormatCell
    },    
    {
      Header: 'Payout Status',
      accessor: 'status',
      Cell: PlainTextCell
    },
    {
      Header: 'Payout Date',
      accessor: 'payout_date',
      Cell: DateFormatCell
    },
    {
      Header: 'Commission amount',
      accessor: 'commission_amount',
      Cell: PennyToDollarFormatCell
    }       
  ];

  const affilateLink = () => {
    return `${process.env.REACT_APP_LANDING_PAGE}?via=${affiliateCode.code}`;
  }

  const handleCopyClicked = () => {
    const copyText = document.querySelector(`#input-${affiliateCode.code}`);
    copyText.select();
    document.execCommand("Copy");
    setCopyButtonLabel('Copied');
    setCopied(true);
    // change back to Copy in 3 seconds
    setTimeout(() => {
      setCopyButtonLabel("Copy");
      setCopied(false);
    }, 3000)    
  }

  const updatePaypal = () => {
    const url = `/v1/affiliate_campaigns/${affiliateCode.affiliate_campaign_id}/affiliate_codes/${affiliateCode.id}`;
    justcastApi.put(url, {paypal_address: affiliateCode.paypal_address})
    .then(() => {
      addWithTimeout({color: 'warning', message: `Paypal account info has been updated!`, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err);
      addWithTimeout({color: 'danger', message:  'Failed to update paypal account.', isOpen: true, timeout: 3000})
    })
  }
  
  return (
    <>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">              
            <h4 className="card-header-title">
              Referral link
            </h4>              
          </div>
          <div className="card-body">
            <p>Your unique referral link you can share on your blog, podcast, Twitter, or to your email newsletter list</p>
            <InputGroup>
              <Input valid={copied} readOnly value={affilateLink()} id={`input-${affiliateCode.code}`} />
              <InputGroupAddon addonType="prepend">
                <Button color="primary" onClick={handleCopyClicked}>{copyButtonLabel}</Button>
              </InputGroupAddon>          
            </InputGroup>
            <hr/>
            <p>Paypal Account</p>
            <InputGroup>
              <Input 
                value={affiliateCode.paypal_address} 
                id={`input-paypal-address`}
                onChange={(e) => {
                  handlePaypalChange(e.target.value)
                }}
              />
              <InputGroupAddon addonType="prepend">
                <Button color="primary" onClick={updatePaypal}>Update</Button>   
              </InputGroupAddon>          
            </InputGroup>
          </div>
        </div>
        <Card>
          <CardHeader>
            <h4 className="card-header-title">Payouts</h4>
          </CardHeader>
          <CardBody>
            <BoostrapTable data={payouts} columns={columns}/>
          </CardBody>          
        </Card>  
      </div>        
    </>
  )
}

export default AffiliatePayoutSummary; 