import React, {useEffect, useState, useContext} from "react";
import { Redirect } from "react-router-dom";
import queryString from 'query-string'
import { Spinner } from 'reactstrap';
import justcastApi from '../../api/justcast'
import {Context as ModalContext} from '../../context/ModalContext'
import {Context as AuthContext} from '../../context/AuthContext'

const MailChimpIntegrationCallback = (props)  => {
  const [redirect, setRedirect] = useState('');
  const [loading, setLoading] = useState(true);
  const {add} = useContext(ModalContext);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const values = queryString.parse(props.location.search);    
    if(values && values['code']) {
      const code = values['code'];
      
      // console.log({oauth_token, oauth_verifier})
      justcastApi.post(`/v1/mailchimp_connections_code`, {
        code: code
      })
      .then((res) => {
        const showId = res.data.show_id;
        const campaign_web_url = res.data.campaign_web_url;

        setLoading(false);
        add({color: 'danger', title: "Thank you" , message: `<p>Mailchimp connection has been setup.</p><p>We have sent you an example email.</p>`, isOpen: true})
        setRedirect(`/shows/${showId}/integrations`)
      })
      .catch((err) => {        
        setLoading(false)
        add({color: 'danger', title: "Uh oh! Failed to setup Mailchimp connection." , message: "Connection has not been verified with Mailchimp.", isOpen: true})        
        setRedirect(`/dashboard`)
      })
    }
  },[])

  if(loading) {
    return <Spinner color="primary" />;
  }
  
  if(redirect) {
    // wait 3 seconds to redirect, and having a spinner to indicating it's loading    
    return <Redirect to={redirect}/>
  }

  return (<></>) 
}

export default MailChimpIntegrationCallback;