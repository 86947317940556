import React from 'react'
import { Modal, ModalBody, ModalFooter, Button, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import { Play, Trash2 } from 'react-feather';

export default function WordEditingModal({
  openModal,
  toggleModal,
  jumpToWordTime,
  deleteWord,
  editWord,
  handlEditWordChange,
  wordSave
}) {
  return (
    <Modal isOpen={openModal} toggle={toggleModal}>
      <div className='jc-transcript-toolbar'>
        <div className='jc-toolbar-button play' onClick={jumpToWordTime}><Play size="24"/></div>
        <div className='jc-toolbar-button delete' onClick={deleteWord}><Trash2 size="24"/></div>
      </div>
      <ModalBody>
        <InputGroup>
          <Input value={editWord} onChange={handlEditWordChange}/>
          <InputGroupAddon addonType="append">
            <Button color="primary" onClick={wordSave}>Save</Button>
          </InputGroupAddon>
        </InputGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
