import React, {useEffect, useState, useContext} from "react";
import moment from 'moment'
import {Redirect} from 'react-router-dom'
import {Row, Spinner} from 'reactstrap';
import justcastApi from '../api/justcast'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AuthContext} from '../context/AuthContext'
import SimpleLineChart from '../components/SimpleLineChart'
import SimpleStatsTile from '../components/SimpleStatsTile'
import DateRangePicker from '../components/DateRangePicker'
import TableCard from '../components/TableCard'
import {subscriptionReportLineData, totalDownloadReportLineData, formatNumber, sortedDataByTargetName} from './../libs/tranformData'
import { DecodeURIFormatCell, PercentageFormatCell} from './../libs/TableCellFormat'
import {Mixpanel} from '../api/mixpanel'

// This is the subscriber report for each podcast

const SubscriptionReport = (props) => {
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext);
  // Mixpanel
  const authContext = useContext(AuthContext);
  const uid = authContext.state.uid;

  const id = props.match.params.id;  
  const [lineData, setLineData] = useState({})
  const [downloadLineData, setDownloadLineData] = useState({})
  const [subscribers, setSubscribers] = useState(0)
  const [downloads, setDownloads] = useState(0)
  const [episodesCount, setEpisodeCount] = useState(0);
  const [reportFromDate, setReportFromDate] = useState(moment().add(-30, 'days').format('YYYY-MM-DD'))
  const [reportToDate, setReportToDate] = useState(moment().add(-1, 'days').format('YYYY-MM-DD'));
  const [userAgents, setUserAgents] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [countries, setCountries] = useState([]);
  const [referrers, setReferrers] = useState([])
  const [denied, setDenied] = useState(false);

  const handleFromDateChange = (e) => {
    const datePicked = moment(e[0]).format('YYYY-MM-DD');
    Mixpanel.identify(uid);    
    Mixpanel.track('Analytic page', {show_id: id, input_field: "FromDate", value: datePicked});
    setReportFromDate(datePicked);
  }

  const handleToDateChange = (e) => {
    const datePicked = moment(e[0]).format('YYYY-MM-DD');
    Mixpanel.identify(uid);
    Mixpanel.track('Analytic page', {show_id: id, input_field: "ToDate", value: datePicked});    
    setReportToDate(datePicked);
  }
  
  useEffect(() => {
    
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.name;      

      menuContext.setMetricsMenus({
        title: 'Metrics',
        subtitle: name,
        currentPageId: 'metrics',
        currentTabId: 'metrics',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show        
      });

      Mixpanel.identify(uid);
      Mixpanel.track('Show Analytics loaded', {show_id: id});      
    })
    .catch((err) => {      
      console.log(err)
      setDenied(true)
    })
  }, [id])

  useEffect(() => {
    screenContext.startFetching();

    const fromDate = moment(reportFromDate).format('DD-MM-YYYY');
    const toDate = moment(reportToDate).format('DD-MM-YYYY');
    justcastApi.post(`/v1/shows/${id}/subscription_report`, {report_from_date: fromDate, report_end_date: toDate})
    .then((res) => {
      const data = res.data.count_subscriber;
      const _lineData = totalDownloadReportLineData(data, "Subscribers")
      const _downloadLineData = totalDownloadReportLineData(res.data.count_download, "Downloads")
      const _subscribers = data[data.length-1].count
      
      setLineData(_lineData);
      setDownloadLineData(_downloadLineData);
      setSubscribers(_subscribers);
      setUserAgents(res.data.user_agents);
      setReferrers(res.data.referrers);
      setCountries(res.data.countries);
      setEpisodeCount(res.data.audiopost_count);
      setDownloads(res.data.download_count);
      screenContext.finishFetching();
    }).catch((err) => {
      console.log(err)
      screenContext.finishFetching();
    })
  }, [id, reportFromDate, reportToDate])

  if(denied) {
    return <Redirect to="/dashboard"/>
  }
  
  return (
    <>
      <div className="container-fluid">
        {  
          screenContext.state.loading ? <Spinner color="primary" /> : 
          <>
            <div className="row">
              <div className="col-12">
                <DateRangePicker fromDate={reportFromDate} setFromDate={handleFromDateChange} toDate={reportToDate} setToDate={handleToDateChange}/>
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-lg-4 col-xl">
                <SimpleStatsTile title="Subscribers" number={formatNumber(subscribers)}/>
              </div>
              <div className="col-4 col-lg-4 col-xl">
                <SimpleStatsTile title="Downloads" number={formatNumber(downloads)}/>
              </div>
              <div className="col-4 col-lg-4 col-xl">
                <SimpleStatsTile title="Episodes" number={formatNumber(episodesCount)}/>
              </div>              
            </div>        
            <div className="row">
              <div className="col-6 col-md-12 col-lg-6">
                <SimpleLineChart data={lineData}/>
              </div>
              <div className="col-6 col-md-12 col-lg-6">
                <SimpleLineChart data={downloadLineData}/>
              </div>              
            </div>     
            <Row>
              <div className="col-12 col-lg-6">
                <TableCard title="% of Subscriber by User Agents" data={userAgents} columns={[{Header: "Name", accessor: "name", Cell: DecodeURIFormatCell}, {Header: "#", accessor: "count", Cell: PercentageFormatCell}]} />
              </div>
              <div className="col-12 col-lg-6">                
                <TableCard title="% of Subscriber by Countries" data={countries} columns={[{Header: "Country", accessor: "name", Cell: DecodeURIFormatCell}, {Header: "#", accessor: "count", Cell: PercentageFormatCell}]} />
              </div>          
            </Row>
            <Row>
              <div className="col-12">                
                <TableCard title="% of Subscriber by Referrer" data={referrers} columns={[{Header: "Name", accessor: "name", Cell: DecodeURIFormatCell}, {Header: "#", accessor: "count", Cell: PercentageFormatCell}]} />
              </div>              
            </Row>
          </>
        }
      </div>   
    </>
  )
}

export default SubscriptionReport