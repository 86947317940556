import React from "react";
import { Form, FormGroup, Input, Button, Row } from 'reactstrap';
import FormGroupInput from '../components/FormGroupInput';

const SocialNetworkForm = ({
  handleInputValueChange, 
  facebook_page, 
  twitter,
  instagram_profile,
  slack,
  apple_podcast,
  google_podcast,
  overcast,
  spotify,
  pocket_cast,
  breaker,
  castro,
  radio_public,
  castbox,
  tune_in,
  stitcher,
  amazon_podcast_link,
  patreon_support_link,
  paypal_url,
  buy_me_a_coffee_url,
  square_cash,
  podverse,
  fountain,
  mastodon,
  matrix
}) => {
  
  return (
    <>
      <h2>Social Media</h2>
      <p className="form-text text-muted">Social Media accounts to be used on your JustCast website.</p>
      <hr/>
      <Form className="mb-4">
        <Row>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Facebook'
              inputType='text'
              inputName="facebook_page"
              inputValue={facebook_page ? facebook_page : ""}
              inputClassName="form-control"
              inputPlaceholder="https://facebook.com/justcast"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Twitter'
              inputType='text'
              inputName="twitter_handle"
              inputValue={twitter ? twitter : ""}
              inputClassName="form-control"
              inputPlaceholder="https://twitter.com/justcast"
              handleInputChange={handleInputValueChange}
            />
          </div> 
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Slack'
              inputType='text'
              inputName="slack"
              inputValue={slack ? slack : ""}
              inputClassName="form-control"
              inputPlaceholder="https://slack.com/justcast"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Instagram'
              inputType='text'
              inputName="instagram_profile"
              inputValue={instagram_profile ? instagram_profile : ""}
              inputClassName="form-control"
              inputPlaceholder="https://instagram.com/justcast"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Mastodon'
              inputType='text'
              inputName="mastodon"
              inputValue={mastodon ? mastodon : ""}
              inputClassName="form-control"
              inputPlaceholder="https://podcastindex.social/web/@montekaka"
              handleInputChange={handleInputValueChange}
            />
          </div> 
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Matrix'
              inputType='text'
              inputName="matrix"
              inputValue={matrix ? matrix : ""}
              inputClassName="form-control"
              inputPlaceholder="@bob:bob.com"
              handleInputChange={handleInputValueChange}
            />
          </div>                                        
        </Row>
      </Form>
      <h2>Podcast Listening Apps</h2>      
      <p className="form-text text-muted">Links to your podcast on various Listening apps and services.  There will be displayed on your JustCast website.</p>
      <hr/>
      <Form className="mb-4">
        <Row>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Apple podcasts'
              inputType='text'
              inputName="apple_podcast"
              inputValue={apple_podcast ? apple_podcast : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Google podcasts'
              inputType='text'
              inputName="google_podcast"
              inputValue={google_podcast ? google_podcast : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>          
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Overcast'
              inputType='text'
              inputName="overcast"
              inputValue={overcast ? overcast : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Spotify'
              inputType='text'
              inputName="spotify"
              inputValue={spotify ? spotify : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Pocket Casts'
              inputType='text'
              inputName="pocket_casts"
              inputValue={pocket_cast ? pocket_cast : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Breaker'
              inputType='text'
              inputName="breaker"
              inputValue={breaker ? breaker : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>          
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Castro'
              inputType='text'
              inputName="castro"
              inputValue={castro ? castro : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Radio Public'
              inputType='text'
              inputName="radio_public"
              inputValue={radio_public ? radio_public : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Castbox'
              inputType='text'
              inputName="castbox"
              inputValue={castbox ? castbox : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='TuneIn'
              inputType='text'
              inputName="tune_in"
              inputValue={tune_in ? tune_in : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Stitcher'
              inputType='text'
              inputName="stitcher"
              inputValue={stitcher ? stitcher : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Amazon Podcast'
              inputType='text'
              inputName="amazon_podcast_link"
              inputValue={amazon_podcast_link ? amazon_podcast_link : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>  
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Podverse'
              inputType='text'
              inputName="podverse"
              inputValue={podverse ? podverse : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div> 
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Fountain'
              inputType='text'
              inputName="fountain"
              inputValue={fountain ? fountain : ""}
              inputClassName="form-control"
              handleInputChange={handleInputValueChange}
            />
          </div>                             
        </Row>
      </Form>
      <h2>Support us</h2>
      <p className="form-text text-muted">This tag lists possible donation/funding links for the podcast.</p>
      <hr/>      
      <Form className="mb-4">
        <Row>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Patreon'
              inputType='text'
              inputName="patreon_support_link"
              inputValue={patreon_support_link ? patreon_support_link : ""}
              inputClassName="form-control"
              inputPlaceholder="https://www.patreon.com/ALTube"
              handleInputChange={handleInputValueChange}
            />
          </div> 
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Paypal'
              inputType='text'
              inputName="paypal_url"
              inputValue={paypal_url ? paypal_url : ""}
              inputClassName="form-control"
              inputPlaceholder="https://www.paypal.com/donate/?hosted_button_id=TZ4X7L2DSRMAL"
              handleInputChange={handleInputValueChange}
            />
          </div>  
        </Row>
        <Row>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Buy Me A Coffee'
              inputType='text'
              inputName="buy_me_a_coffee_url"
              inputValue={buy_me_a_coffee_url ? buy_me_a_coffee_url : ""}
              inputClassName="form-control"
              inputPlaceholder="https://www.buymeacoffee.com/felifromgermany"
              handleInputChange={handleInputValueChange}
            />
          </div> 
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Square Cash'
              inputType='text'
              inputName="square_cash"
              inputValue={square_cash ? square_cash : ""}
              inputClassName="form-control"
              inputPlaceholder="https://cash.app/$yourcashtag"
              handleInputChange={handleInputValueChange}
            />
          </div>                                        
        </Row>
      </Form>
    </>
  )  
}

export default SocialNetworkForm;

