import React, {useEffect, useState, useContext } from "react";
import { Form, FormGroup, Button, CustomInput } from 'reactstrap';
import ColorPicker from './ColorPicker'
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import {Context as AuthContext} from '../../context/AuthContext'
import {Context as ModalContext} from '../../context/ModalContext'
import waveformdemoImg from '../../assets/img/waveform/waveformdemo.png'
import progressbarImg from '../../assets/img/waveform/progressbar.png'
import sampelData from '../../api/audioSample.js';

const PanelElementsTab = () => {
  const defaultSoundWave = {id: null, type: null, width: null, height: null, x: 10, y: 100, barHeight: 40, barWidth: 5, barGap: 2, barColor: '#fff', fillColor: "#E35986"}
  const defalutProgressBar = {id: null, type: null, width: null, height: 20, x: 0, y: 0, filledColor: "#fff", backgroundFillOpacity: "0.5", playedFillOpacity: "0.8", fontSize: 12, showTimer: true}
	
  const {state, updateState} = useContext(PodieoAudioContext);
  const authContext = useContext(AuthContext);
  const modalContext = useContext(ModalContext)

  const {skill_control_justcast_budge} = authContext.state;
  const {selectingObject, soundWave, progressBar, showBadge} = state;
  const {add} = modalContext;

  const [displayColorPicker, setDisplayColorPicker] = useState(false);   

	const handleDeleteSoundWave = () => {
		updateState({soundWaveData: [], soundWaveAdded: false, soundWave: defaultSoundWave, selectingObject: {type: 'elements', id: null}})
	}

	const addSoundWave = () => {
		if(soundWave.id === null) {
      defaultSoundWave['id'] = 'soundwave';
      defaultSoundWave['width'] = state.demoSvgWidth - 20;
			updateState({audioRawData: sampelData.audioRawData, soundWaveData: sampelData.svgData, soundWaveAdded: true, soundWave: defaultSoundWave, selectingObject: {type: 'elements', id: 'soundwave'}})
    } 
    else {
      updateState({selectingObject: {type: 'elements', id: 'soundwave'}})
    }
  }
  
  const handleSoundWaveColorChanged = (x) => {      
    // console.log(x)
    if(selectingObject.type === 'elements' && selectingObject.id === 'soundwave') {
      // ...x      
      const newSoundWave = {...soundWave, ...{barColor: x.color}};
      updateState({soundWave: newSoundWave})
    } 
  }  

  // progress bar handlers

  const addProgressBar = () => {
		if(progressBar.id === null) {
      defalutProgressBar['id'] = 'progressbar';
      defalutProgressBar['width'] = state.demoSvgWidth;
			updateState({progressBarAdded: true, progressBar: defalutProgressBar, selectingObject: {type: 'elements', id: 'progressbar'}})
    } 
    else {
      updateState({selectingObject: {type: 'elements', id: 'progressbar'}})
    }
  }  

	const handleDeleteProgressBar = () => {
		updateState({ progressBarAdded: false, progressBar: defalutProgressBar, selectingObject: {type: 'elements', id: null}})
  }
  
  const handleProgressBarColorChanged = (x) => {      
    // console.log(x)
    if(selectingObject.type === 'elements' && selectingObject.id === 'progressbar') {
      // ...x      
      const newItem = {...progressBar, ...{filledColor: x.color}};
      updateState({progressBar: newItem})
    } 
  }    

  const handleShowTimerSwitchToggled = (e) => {
    const id = e.target.id;
    const checked = e.target.checked;
    const item = {[id]: checked}
    const newItem = {...progressBar, ...item}
    updateState({progressBar: newItem});
  }

  const handleJustCastBadgeToggled = (e) => {    
    if(skill_control_justcast_budge) {
      updateState({showBadge: e.target.checked})
      // console.log({id: e.target.id, checked: e.target.checked})
    } else {
      add({color: 'danger', title: "JustCast watermark" , message: "If you want to remove the JustCast watermark, please consider to upgrade to Professional plan.", isOpen: true})
    }    
  }
		
	return (
		<div>
			<div style={{"display": "flex", "justifyContent": "space-between", "alignItems": "center"}}>
				<div className="d-none d-lg-block"					
					style={{backgroundColor: "#020408", paddingLeft: 10, paddingRight: 10, borderRadius: "10px", "cursor": "pointer"}}
					onClick={addSoundWave}
				>
					<img src={waveformdemoImg} width="140"/>
				</div>
				<div className="d-lg-none"
					onClick={addSoundWave}
				>Sound Wave</div>
        {
          state.soundWaveAdded ?
          <Button
					onClick={handleDeleteSoundWave}
					><span className="fe fe-trash"/></Button> :
          <Button
					onClick={addSoundWave}
					><span className="fe fe-plus"/></Button>
        }

			</div>      
			<hr/>
      {
        state.soundWaveAdded && selectingObject.type === 'elements' && selectingObject.id === 'soundwave' ? 
        <>
          <div style={{display: "flex", "justifyContent": "space-between", "alignItems": "center"}}>
            <div>Bar Color</div>
            <ColorPicker 
                color={soundWave.barColor ? soundWave.barColor : "#abc"}
                handleColorChange={handleSoundWaveColorChanged}       
                tagName="color"
                width="32px"
                height="16px"
                displayColorPicker={displayColorPicker}
                setDisplayColorPicker={setDisplayColorPicker}
              />        
          </div>
          <hr/>
        </> : null
      }


      <div style={{"display": "flex", "justifyContent": "space-between", "alignItems": "center"}}>
				<div className="d-none d-lg-block"					
					style={{backgroundColor: "#020408", paddingLeft: 10, paddingRight: 10, borderRadius: "10px", "cursor": "pointer"}}
					onClick={addProgressBar}
				>
					<img src={progressbarImg} width="140"/>
				</div>
				<div className="d-lg-none"
					onClick={addProgressBar}
				>Progress bar</div>
        {
          state.progressBarAdded ?
          <Button
					onClick={handleDeleteProgressBar}
					><span className="fe fe-trash"/></Button> :
          <Button
					onClick={addProgressBar}
					><span className="fe fe-plus"/></Button>
        }
			</div>       
      <hr/>

      {
        state.progressBarAdded && selectingObject.type === 'elements' && selectingObject.id === 'progressbar' ? 
        <>
          <div style={{display: "flex", "justifyContent": "space-between", "alignItems": "center"}}>
            <div>Progress Color</div>
            <ColorPicker 
                color={progressBar.filledColor ? progressBar.filledColor : "#abc"}
                handleColorChange={handleProgressBarColorChanged}       
                tagName="color"
                width="32px"
                height="16px"
                displayColorPicker={displayColorPicker}
                setDisplayColorPicker={setDisplayColorPicker}
              />        
          </div>
          <hr/>
          <div>
            <Form>
              <FormGroup>
                <div>
                  <CustomInput 
                    type="switch" 
                    id="showTimer" 
                    name="showtimer" 
                    label="Incudes progress time" 
                    checked={progressBar.showTimer}
                    onChange={handleShowTimerSwitchToggled}
                  />
                </div>
              </FormGroup>
            </Form>
          </div>
          <hr/>
        </> : null
      }
      <div style={{"display": "flex", "justifyContent": "space-between", "alignItems": "center"}}>
        <div>JustCast Watermark</div>
        <CustomInput 
          type="switch" 
          id="justcast-badge" 
          name="justcastBagetSwitch"
          onChange={handleJustCastBadgeToggled}
          checked={showBadge}
        />
      </div>
      <hr/>
		</div>
	)
}

export default PanelElementsTab;