import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../api/justcast'
import {Mixpanel} from '../api/mixpanel'
import introVideos from '../libs/introVideos'
import {getDropboxAuthUrl} from '../libs/DropboxOAuth';
import {getAzureAppAuthUrl} from '../libs/AzureAppOAuth';
import {Context as AuthContext} from '../context/AuthContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import { Card, CardText, CardBody, CardTitle, Button, Spinner } from 'reactstrap';
import ShowGroupCardList from '../components/ShowGroupCardList'
import CloudDrivesButtonGroup from '../components/CloudDrivesButtonGroup'
import DeleteDirectUploadModal from '../components/DeleteDirectUploadModal'
import NewSignupGreeding from '../components/NewSignupGreeding'
import happinessImg from './../assets/img/illustrations/happiness.svg'
import NewPodcastModal from '../components/NewPodcastModal';
import {PermissionModal} from '../components/FileUploader';
import VideoCardList from '../components/VideoCardList'
import AddShopifyModal from "../components/connections/AddShopifyModal"

const imgStyle = {
  maxWidth: '272px'
};

const Shows = ()  => {
  const [shopifyConnectionModal, setShopifyConnectionModal] = useState(false)
  const screenContext = useContext(ScreenContext);
  const menuContext = useContext(MenuContext);
  // const modalContext = useContext(ModalContext);
  const {state, handleDeletedPodcast, addWithMessages } = useContext(ModalContext);
  const authContext = useContext(AuthContext)
  const { skill_direct_upload } = authContext.state

  // const [loading, setLoading] = useState(true);
  const [shows, setShows] = useState();
  const [connections, setConnections] = useState();
  const [defaultConnections, setDefaultConnections] = useState([]);

  const fetchShows = () => {
    screenContext.startFetching();
    justcastApi.get('/v2/shows')
    .then((res) => {
      screenContext.finishFetching();
      setShows(res.data);
      // setLoading(false);
    })
    .catch((err) => {
      screenContext.finishFetching();
      // console.log(err)
    })
  }

  const fetchDropboxConnection = () => {
    justcastApi.get('/v1/clouddrive_connections')
    .then((res) => {
      const actived = res.data.filter((drive) => drive.active === true);
      setDefaultConnections(res.data);
      setConnections(actived);
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const generateAssetFolderOnDropbox = (showId) => {
    justcastApi.post(`/v1/shows/${showId}/create_dropbox_artwork_folders`)
    .then((res) => {
      addWithMessages(
        {color: "primary", title: "Success", messages: [res.data.message, "Learn more?"], loading: false, isOpen: true}
      )
    })
    .catch((err) => {
      addWithMessages(
        {color: "primary", title: "Error", messages: [err.message], loading: false, isOpen: true}
      )
    })
  }

  useEffect(() => {
    menuContext.setPodcastsPageMenus({title: 'Podcasts', subtitle: "Overview", currentPageId: 'home', currentTabId: 'shows'});
    fetchShows();
    fetchDropboxConnection();

    const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_CHAT_WEBSITE_ID;
    if(CRISP_WEBSITE_ID) {
      // Include the Crisp code here, without the <script></script> tags
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;

      (function() {
        var d = document;
        var s = d.createElement("script");

        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    }
  }, [])

  useEffect(() => {
    if(state.podcastDeleted === true) {
      fetchShows();
      handleDeletedPodcast();
    }
  }, [state.podcastDeleted])

  return (
    <div className="container-fluid">
      {skill_direct_upload ? <NewPodcastModal/> : <PermissionModal/>}
      {screenContext.state.loading ? <Spinner color="primary" /> : <><AddShopifyModal/><ShowList generateAssetFolderOnDropbox={generateAssetFolderOnDropbox} connections={connections} defaultConnections={defaultConnections} shows={shows}/></> }
    </div>
  )
}

const ShowList = ({connections, defaultConnections, shows, generateAssetFolderOnDropbox}) => {
  if(!connections || !shows) return null;
  if(connections.length > 0 || shows.length > 0) {
    return (
      <div className="row">
        <DeleteDirectUploadModal/>
        <ShowGroupCardList items={shows} generateAssetFolderOnDropbox={generateAssetFolderOnDropbox}/>
      </div>
    )
  } else {
    return <GetStarted connections={defaultConnections}/>
  }
}

const GetStarted = ({connections}) => {
  const authContext = useContext(AuthContext);
  const { createNewPodcast } = useContext(ModalContext);
  const {uid, skill_direct_upload} = authContext.state;
  const [greedingModal, setGreedingModal] = useState(false); // do not show greeding, looks like greeding is adding addition step and user does not like it

  const connectionClicked = (clouddrive) => {
    switch(clouddrive) {
      case 'dropbox':
        getDropboxAuthUrl((url) => {
          if(url) {
            window.location = url;
          }
        });
      case 'onedrive':
        return getAzureAppAuthUrl();
      default:
        return null;
    }
  }

  const toggleGreedingModal = () => {
    setGreedingModal(!greedingModal);
  }

  const handleNewPodcastClicked = () => {
    createNewPodcast();
  }

  return (
    <>
      <NewSignupGreeding modal={greedingModal} toggle={toggleGreedingModal} className={'primary'}/>
      <Card>
        <CardBody className="text-center">
          <div className="row justify-content-center">
            <div className="col-12">
              <img src={happinessImg} className="img-fluid mt-n5 mb-4" style={imgStyle}/>
              <h2>Start your first podcast</h2>
              <div>
                <p>Start publishing episodes by creating a podcast. Podcasts can have episodes, which your audience will hear in a podcast app or on the web.</p>
              </div>
              {/* {
                skill_direct_upload ?  <div style={{marginBottom: "16px"}}>
                <Button color="primary" onClick={handleNewPodcastClicked}>Start a podcast <i className="fe fe-mic"/></Button>
                </div>  : null
              } */}
              <div style={{marginBottom: "16px"}}>
                <Button color="primary" onClick={handleNewPodcastClicked}>Start a podcast <i className="fe fe-mic"/></Button>
                </div>
              {/* <CloudDrivesButtonGroup drives={connections} handleClick={connectionClicked}/> */}
              {/* <div className="btn btn-primary lift" onClick={connectWithDropbox}>SET UP NOW</div> */}
            </div>
          </div>
        </CardBody>
      </Card>
      <VideoCardList videos={introVideos}/>
    </>
  )
}

export default Shows