import React, {useContext} from "react";
import moment from 'moment'
import MinimizePlayerButton from './MinimizePlayerButton'
import WidgetPlayerControl from './WidgetPlayerControl'
import WidgetPlayerMoreInfo from './WidgetPlayerMoreInfo'

var momentDurationFormatSetup = require("moment-duration-format");

const FullPlayer = ({
  minimize, audio_date, 
  artwork, name, description, url, duration, 
  playedSeconds, played, playing, handleDuration, 
  handleProgress, handlPlayPauseClick, handleSliderChange, handleSeekMouseDown, 
  handleSeekMouseUp, handlePlayerRef, handleMinimizePlayer, progressBarIdName,
  section, handleSectionChange, embedUrl, shareUrl,shareOnFacebook, shareOnTwitter,
  rssFeed,
  facebook_page,
  twitter_handle,
  playerControlSquare,
}) => {
  
  const date = moment(audio_date).format('YYYY-MM-DD');
  const embedCode = `<iframe src='${embedUrl}' width='100%' height='180' frameborder='0' scrolling='no' seamless='true' style='width:100%; height:180px;'></iframe>`
  const podcastApps = []

  const playerControlClass = playerControlSquare ? 'widget-player-app' : 'widget-player-app widget-player-app-rounded';

  return (
    <div className='widget-player-container dark-html-widget-player'>
      <section className={playerControlClass}>
        <div className="artwork">
          <img src={artwork ? artwork : "https://justcast.herokuapp.com/images/default_thumb_show_image.png"} />
        </div>
        <div className="main">
          <WidgetPlayerControl
            date={date} name={name} playing={playing} handlPlayPauseClick={handlPlayPauseClick}
            progressBarIdName={progressBarIdName} playedSeconds={playedSeconds} duration={duration}
            handleSeekMouseDown={handleSeekMouseDown} handleSeekMouseUp={handleSeekMouseUp} 
            handleSliderChange={handleSliderChange} section={section}
            handleSectionChange={handleSectionChange}            
          />
          <WidgetPlayerMoreInfo section={section === 'subscribe'} 
            title='Subscribe' 
            shareInputs={[{'label':"RSS", 'url': rssFeed}]}
            shareIconWithLabels={podcastApps}
          />
          <WidgetPlayerMoreInfo section={section === 'share'} 
            title='Share' 
            shareInputs={[{'label':"Embed", 'url': embedCode}, {'label':"Share", 'url':shareUrl}]}
            shareIconWithLabels={[{'label': "Facebook", url: shareOnFacebook, iconName:"fe fe-facebook"}, {'label': "Twitter", url: shareOnTwitter, iconName:"fe fe-twitter"}]}
            />            
          <WidgetPlayerMoreInfo 
            section={section === 'more_info'} 
            title={name} 
            description={description}
            shareIconWithLabels={[{'label': "Facebook", url: facebook_page, iconName:"fe fe-facebook"}, {'label': "Twitter", url: twitter_handle, iconName:"fe fe-twitter"}]}
          />
        </div>       
        <div className="minimize-button">
          <MinimizePlayerButton handleMinimizePlayer={handleMinimizePlayer} handleSectionChange={handleSectionChange} section={section}/>
        </div>
      </section>
    </div>    
  )
}

export default FullPlayer;