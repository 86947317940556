import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem, ModalFooter } from 'reactstrap';

export default function PeopleListModal({
  modal,
  toggleHost,
  toggle,
  people
}) {
  if(people) {
    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Select a person from the list
        </ModalHeader>
        <ModalBody>
          <ListGroup>
          {
            people.map((person) => {
              return (
                <ListGroupItem key={person.id} style={{
                  display: "flex",
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  {person.name}
                  <Button size="sm" color="warning" outline={!(person.selected ? true : false)} onClick={() => {
                    toggleHost(person)
                  }}>{person.selected ? "Selected" : "Select"}</Button>
                </ListGroupItem>
              )
            })
          }
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Done</Button>
        </ModalFooter>
      </Modal>
    )
  }

  return null;
}
