import React from "react";
import {Link} from 'react-router-dom'
import moment from 'moment';
import { Badge } from 'reactstrap';

const ListItem = ({
  showId,
  audiopostId,
  name,
  audioDate,
  publishStatus,
}) => {
  return (
    <div className="list-group-item">
      <div className="row align-items-center">
        <div className="col ml-n2">
          <h4 className="mb-1">
          <Link to={`/team_podcasts/${showId}/team_episodes/${audiopostId}`}>{name}</Link>
          </h4>
          <p className="text-muted mb-0">Publish date: {moment(audioDate).format("MMM Do YYYY, h:mm:ss a")}</p>
          <Badge color="primary">{publishStatus}</Badge>
        </div>
        <div className="col-auto">
          <Link
            to={`/team_podcasts/${showId}/team_episodes/${audiopostId}`}
            className="btn btn-primary btn-sm"
          >Edit</Link>
        </div>
      </div>
    </div>
  )
}

export default ListItem;