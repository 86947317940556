import React from "react";
import { CustomInput } from 'reactstrap';

const FormGroupListItemInputSwitch = (props) => {
  const {
    inputLabel,
    inputType,
    inputName,
    messageTitle,
    warningMessage,
    inputValue,
    inputClassName,
    handleInputChange,
    inputCSSId,
    mutedText
  } = props;

  return (
    <div className="list-group-item">
      <div className="row align-items-center">
        <div className="col">
          <h4 className="mb-1">{inputLabel}</h4>
          <small className="text-muted">{mutedText}</small>
        </div>
        <div className="col-auto">
          <CustomInput
              type={inputType}
              id={inputCSSId}
              onChange={handleInputChange}
              name={inputName}
              checked={inputValue}
          />
        </div>
      </div>
      {props.children}
    </div>
  )
}

export default FormGroupListItemInputSwitch;