import React, { useEffect, useState } from "react";
import io from "socket.io-client"
import { useAtom } from "jotai";
import {Spinner, Progress, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { resetModalAtom, setModalAtom, saveFileAtom } from './modalAtom'
import {setSocketConnection} from '../../jotai'
// import {Context as SocketContext} from '../context/SocketContext'

const ChapterModal = ({audiopostId}) => {
  const [modal, setModal] = useAtom(setModalAtom)
  const [, saveChapterToFile] = useAtom(saveFileAtom);
  const [, resetModal] = useAtom(resetModalAtom);

  const [socketState, setSocket] = useAtom(setSocketConnection);

  useEffect(() => {
    if(socketState && socketState.socket === null) {
      const _ = io(process.env.REACT_APP_MS_MEDIA_ANALYZER_PROXY);
      setSocket(_);
    }
  }, []);
  
  useEffect(() => {
    if(modal.addNewSocket) {
      socketState.socket.on(`audioposts_${audiopostId}_metas`, (data) => {
        // console.log(data);
        setModal(data)
      })
      setModal({addNewSocket: false})
    }
  }, [modal])

  if(modal.isOpen) {
    return (
      <Modal isOpen={modal.isOpen}>
        <ModalHeader toggle={resetModal}>
          <strong>{modal.title}</strong>
        </ModalHeader>   
        <ModalBody>          
          <div>
            {modal.message ? <p>{modal.message}</p> : null}
            {modal.isLoading ? <Spinner color="primary"/> : null}
            {
              modal.isSaveButtonOn ? <div><Button color="secondary" block onClick={() => {saveChapterToFile(audiopostId)}}>Add Chapter Markers to audio file</Button></div> : null
            }              
            {
              modal.progress && modal.progress >= 0 ? <Progress animated color="success" value={modal.progress} /> : null
            }
          </div>
        </ModalBody> 
        <ModalFooter>
          <Button color="primary" onClick={resetModal}>Done</Button>
        </ModalFooter>            
      </Modal>
    )
  }
  return null;
}

export default ChapterModal;