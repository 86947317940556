import React, {useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Button, InputGroupAddon, InputGroup } from 'reactstrap';
import { EyeOff, Eye } from 'react-feather';
import AuthModal from '../components/AuthModal';

const HandleMigrationModal = ({allowMigration}) => {
  if(allowMigration) {
    const message = 'Your account is currently with our old system.  Would you like to migrate it to our new platform?'
    return (
      <AuthModal message={message} isOpen={true} color={'defaut'} allowMigration={allowMigration}/>
    )
  }

  return null;
}

const RedirectBackButton = ({buttonId, buttonDisabled, redirectBack, submitButtonText}) => {
  const url = `${process.env.REACT_APP_V1_RAILS_REDIRECT}/admin/main`;
  if(redirectBack) {
    return (
      <Button color="primary"
          type="button"
          onClick={() => {
            window.open(url, '_blank');
          }}
          className="btn btn-primary btn-block mt-3"
        >Sign in</Button>
    )
  } else {
    return (
      <Button color="primary"
        disabled={buttonDisabled && buttonId === 'justcast_signup'}
        type="submit"
        id={buttonId}
        className="btn btn-primary btn-block mt-3"
      >{submitButtonText}</Button>
    )
  }
}

const AuthForm = ({ headerText, errorMessages, message, buttonId, showPasswordInput, onSubmit, submitButtonText, redirectBack, allowMigration }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [recapchaValue, setRecapchatValue] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [eyeOn, setEyeOn] = useState(false);

  const url = `${process.env.REACT_APP_V1_RAILS_REDIRECT}/admin/main`;

  const recapchaChanged = (value) => {
    if(value) {
      setButtonDisabled(false);
      setRecapchatValue(value);
    }
  }

  const handleExpired = () => {
    setButtonDisabled(true);
    setRecapchatValue('');
  }

  const toggleEyeOn = (event) => {
    event.preventDefault();
    setEyeOn(!eyeOn)
  }

  return (
    <>
      <HandleMigrationModal allowMigration={allowMigration}/>
      <p className="text-muted text-center mb-5">{headerText}</p>
      <Form onSubmit={(event) => {
        if(redirectBack) {
          window.open(url, '_blank');
        } else {
          onSubmit({email, password, recapchaValue});
        }
        event.preventDefault();
      }}>
        <FormGroup>
          <label>Email Address</label>
          <Input type="text"
            name="email"
            placeholder="name@address.com"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
            }}/>
        </FormGroup>
        {
          message && message.length > 0 &&
          <div>{message}</div>
        }
        {
          showPasswordInput &&
          <FormGroup>
            <div className="row">
              <div className="col">
                <label>Password</label>
              </div>
            </div>
            <InputGroup>
              <Input type={eyeOn ? "text" : "password"}
                className="form-control form-control-appended"
                name="password"
                value={password} onChange={(event) => {
                setPassword(event.target.value)
              }}/>
              <InputGroupAddon addonType="append" onClick={toggleEyeOn}><Button color="secondary">{eyeOn ? <Eye size={16} /> : <EyeOff size="16"/>}</Button></InputGroupAddon>
            </InputGroup>
          </FormGroup>
        }
        {buttonId === 'justcast_signup' && <FormGroup>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={recapchaChanged}
            onExpired={handleExpired}
            theme="dark"
          />
        </FormGroup>}
        <RedirectBackButton buttonDisabled={buttonDisabled} redirectBack={redirectBack} submitButtonText={submitButtonText} buttonId={buttonId ? buttonId : "justcast"}/>
        <br/>
        <div>
          <Link to="/forgot_password" className="form-text text-white">Forgot your password?</Link>
        </div>
      </Form>
      {
        errorMessages && errorMessages.length > 0 &&
        <div>{errorMessages.join(' ')}</div>
      }
    </>
  )
}

export default AuthForm;