import React from "react";
import { Form } from 'reactstrap';
import ImageUploader from '../components/ImageUploader'

const ImageUploadForm = ({setArtwork, handleUpdate, showId, artworkWidth, artworkHeight}) => {
  return (
    <Form className="mb-4">
      <ImageUploader setArtwork={setArtwork} artworkWidth={artworkWidth} artworkHeight={artworkHeight}/>
      <hr className="mt-5 mb-5"/>
      <div className="btn btn-block btn-primary" onClick={handleUpdate}>Save change</div>
      {/* <Link to={`/shows/${showId}/episodes`} className="btn btn-block btn-link text-muted">Cancel this change</Link>       */}
    </Form>    
  )
}

export default ImageUploadForm;