import React from 'react'

const Thumb = ({file, removeFile, setImage}) => {
  return (
    <li className="list-group-item px-0">
      <div className="row align-items-center">
        <div className="col-auto">
          <div className="avatar">
            <img
                className="avatar-img rounded"
                src={URL.createObjectURL(file)}
              />
          </div>
        </div>
        <div className="col ml-n3">
          <h4 className="mb-1">{file.path}</h4>
          {/* <p className="small text-muted mb-0">...</p> */}
        </div>
        <div className="col-auto">
          <div onClick={() => {
            removeFile([])
            setImage(null);
          }}><i className="fe fe-more-fe fe-x"></i></div>
        </div>
      </div>
    </li>
  )
}

const Thumbs = ({files, removeFile, setImage}) => {
  return (
    <div className="col-12 col-md-6">
      <div className="card bg-light border">
        <div className="card-body">
          <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
            {
              files.map(file => 
                <Thumb file={file} removeFile={removeFile} setImage={setImage} key={file.path}/>
              )
            }
          </ul>
        </div>
      </div>      
    </div>   
  )
}

export default Thumbs;