import React from 'react'
import { Card, CardBody, Row, Col, CardFooter} from 'reactstrap';

const EpisodeImageItem= ({id, image_url, subtitle, description, handeDelete}) => {
  
  const onDelete = () => {
    handeDelete(id)
  }

  return (
    <Card>
      <img src={image_url} className="card-img-top"/>  
      <CardBody>
        { subtitle ? <h5 className="card-title">{subtitle}</h5> : null}
        { description ? <p className="card-text">{description}</p> : null}
      </CardBody>
      <CardFooter>
        <div className="btn btn-primary btn-sm" onClick={onDelete}>
          <span className="fe fe-trash"/> Delete
        </div>
      </CardFooter>
    </Card>
  )
}

export default EpisodeImageItem;