// const webSafeFonts = [
//   "Arial, Helvetica, sans-serif",
//   "Courier New, Courier, monospace",
//   "Roboto, Roboto, Roboto",
//   "Great vibes, Great vibes, Great vibes",
//   // "Impact, Charcoal, sans-serif",
//   "Palatino Linotype, Book Antiqua, Palatino, serif",  
//   "Tahoma, Geneva, sans-serif",
//   "Times New Roman, Times, serif",   
//   "Verdana, Geneva, sans-serif", 
// ]

const googleFonts = [
  "Lato",  
  "Great vibes",
  "Open Sans",
  "Roboto",
  // "Slabo",
  "Oswald",  
  "Concert One",
  "Pacifico",
  // "Do Hyeon",
  "Russo One",
  "Anton",
  "Lobster",
  "Dancing Script",
]

export default googleFonts