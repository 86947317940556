import React, {useState, useEffect, useContext, useRef} from "react";
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import TextDnD from './TextDnD';
import ImageDnD from './ImageDnD';
import WaveformDnD from './WaveformDnD';
import ProgressBarDnD from './ProgressBarDnD';
import TimeString from '../../libs/TimeString'

const multipler = {
  'start': 0,
  'middle': 0.5,
  'end': 1,
}

const timeStringHandler = new TimeString();

const SvgDemoArea = () => {

  const canvasDemoRef = useRef(null);

  const {state, updateState} = useContext(PodieoAudioContext);
  const {selectingObject, texts, images, demoSvgWidth, demoSvgHeight, demoSvgRatio, showBadge,
    buttons, soundWave, soundWaveData, soundWaveAdded, countSoundWaveChanged, justcastBadge,
    progressBarAdded, progressBar, clipFromSeconds, clipEndSeconds, playedSeconds} = state;

  const [countClicked, setCountClicked] = useState(0);
  const [imageSimpleClicked, setImageSimpleClicked] = useState(false);

  const indexOfTexts = ({x, y}) => {
    const texts = state.texts;
    if(texts.length > 0) {
      for(let i = 0; i < texts.length; i++ ) {
        const text = texts[i];
        // const textY = Number(text.y)
        const width = Math.max(...(text.name.split("\n").map((t) => t.length))) * Number(text.fontSize) * demoSvgRatio/ 1.5;
        const height = Number(text.fontSize) * text.name.split("\n").length * demoSvgRatio;
        const textX = Number(text.x) - multipler[text.textAnchor] * width ;
        const textY = Number(text.y) + ((text.name.split("\n").length - 1) * Number(text.fontSize) * demoSvgRatio);        
        if( textX <= x && textX + width >= x && textY >= y && textY - height <= y ) {
          return i;
        }
      }
    }
    return -1;
  }

  const progressBarClicked = ({x, y}) => {
    if(state.progressBar.id) {
      const progressBar = state.progressBar;
      const progressBarX = Number(progressBar.x);
      const progressBarY = Number(progressBar.y);
      const {width, height} = progressBar;
      if(progressBarX <= x && progressBarX + Number(width) >= x && progressBarY <= y && progressBarY + Number(height) >= y) {
        return true;
      }
    }
    return false;
  }

  const soundWaveClicked = ({x, y}) => {
    if(state.soundWave.id) {
      const soundWave = state.soundWave;
      const soundWaveX = Number(soundWave.x);
      const soundWaveY = Number(soundWave.y);
      const {width, barHeight} = soundWave;
      if(soundWaveX <= x && soundWaveX + Number(width) >= x && soundWaveY <= y && soundWaveY + Number(barHeight) >= y) {
        return true;
      }
    }
    return false;
  }

  const handleDnDElementClicked = (selectingObject) => {
    if(selectingObject.type) {
      const buttonIndex = buttons.findIndex(b => b.id === selectingObject.type)
      if(buttonIndex > -1) {
        const {id, title} = buttons[buttonIndex];
        const buttonSelectedId = id;
        const buttonSelectedTitle = title;
        updateState({selectingObject, buttonSelectedId, buttonSelectedTitle})
      }
    } else {
      updateState({selectingObject});
    }      
    // const buttonIndex = buttons.findIndex(b => b.id === "texts")
    // const {id, title} = buttonIndex[buttonIndex]
  }

  const handleTextDnD = (cid, x, y) => {
    // find the text from texts by id
    const textIndex = texts.findIndex(t => t.id === cid);
    if(textIndex > -1) {
      const text = texts[textIndex];
      const newText = {...text, x, y};
      texts[textIndex] = newText;
      // updateState({ texts, ...{selectingObject: {type: "texts", cid}}})
      updateState(texts);
      setTimeout(() => {
        updateState({selectingObject: {id: cid, type: "texts"}})
      }, 1)          
    }
  }

  const hanleJustCastBadge = (x, y, id) => {
    updateState({justcastBadge: {...justcastBadge, x, y}})
    setTimeout(() => {
      updateState({selectingObject: {id: "justcast-badge", type: "elements"}})
    }, 1)    
  }

  const handleImageLocationChanged = (x, y, id) => {
    // find the image based on the selected object id, update x, y
    // setDemoSpec({...demoSpec, imageXaxis, imageYaxis})      
    const imageIndex = images.findIndex(t => t.id === id);
    if(imageIndex > -1) {
      const image = images[imageIndex];
      if(x === Number(image.x) && y === Number(image.y)) {
        setImageSimpleClicked(true);
      } else {
        setImageSimpleClicked(false);
      }
      if(x + image.width > demoSvgWidth) {        
        x = demoSvgWidth - image.width;        
      }
      const newImage = {...image, x, y};
      images[imageIndex] = newImage;
      updateState(images);
      // this is a interesting case, because we have a click event on the parent div to delete our selectd element
      // and handleDragStop have a click event that trigger the parent div to delete the current element
      // so that we will need to reset back here
      setTimeout(() => {
        updateState({selectingObject: {id, type: "images"}})
      }, 50)      
    }
  }  

  const handleImageSizeChanged = (deltaWidth, deltaHeight, x, y, id) => {
    // find the image based on the selected object id, update x, y, width, height
    const imageIndex = images.findIndex(t => t.id === id);
    if(imageIndex > -1) {
      const image = images[imageIndex];         
      if(x === Number(image.x) && y === Number(image.y) && deltaWidth === 0 && deltaHeight === 0) {
        setImageSimpleClicked(true);
      } else {
        setImageSimpleClicked(false);
      }
      const width = image.width + deltaWidth;
      const height = image.height + deltaHeight;

      const newImage = {...image, x, y, width, height};
      images[imageIndex] = newImage;    
      updateState(images);
      setTimeout(() => {
        updateState({selectingObject: {id, type: "images"}})
      }, 50)       
    }    
  }  

  // sound wave 
  const handleSoundWaveChange = (x, y) => {
    soundWave.x = x;
    soundWave.y = y;
    const newCountSoundWaveChanged = countSoundWaveChanged + 1;
    updateState({soundWave, countSoundWaveChanged: newCountSoundWaveChanged});

    setTimeout(() => {
      updateState({selectingObject: {id: 'soundwave', type: "elements"}})
    }, 10)      
  }

  const handleSoundWaveSizeChanged = (deltaWidth, deltaHeight, x, y) => {
    const width = (soundWave.width ? soundWave.width : demoSvgWidth) + deltaWidth;
    const barHeight = soundWave.barHeight + deltaHeight;
    soundWave.x = x;
    soundWave.y = y;
    soundWave.width = width;
    soundWave.barHeight = barHeight;
    
    const newCountSoundWaveChanged = countSoundWaveChanged + 1;
    updateState({soundWave, countSoundWaveChanged: newCountSoundWaveChanged});

    setTimeout(() => {
      updateState({selectingObject: {id: 'soundwave', type: "elements"}})
    }, 10)      
  }

  // progress bar
  const handleProgressBarChange = (x, y) => {
    progressBar.x = x;
    progressBar.y = y;    
    updateState({progressBar});

    setTimeout(() => {
      updateState({selectingObject: {id: 'progressbar', type: "elements"}})
    }, 10)      
  }

  const handleProgressBarSizeChanged = (deltaWidth, deltaHeight, x, y) => {
    const width = (progressBar.width ? progressBar.width : demoSvgWidth) + deltaWidth;
    const height = progressBar.height + deltaHeight;
    progressBar.x = x;
    progressBar.y = y;
    progressBar.width = width;
    progressBar.height = height;

    updateState({progressBar});

    setTimeout(() => {
      updateState({selectingObject: {id: 'progressbar', type: "elements"}})
    }, 10)      
  }

  const handleSelectedImageClicked = ({clientX, clientY}) => {
    
    setTimeout(() => {
      // console.log(imageSimpleClicked);
      if(imageSimpleClicked) {
        const rect = canvasDemoRef.current.getBoundingClientRect();      
        const x = clientX - rect.x;
        const y = clientY - rect.y;
        // we will like to check soundwave and progressbar
        const soundWaveIsClicked = soundWaveClicked({x, y});

        if(soundWaveIsClicked) {
          updateState({selectingObject: {id: 'soundwave', type: "elements"}, buttonSelectedId: "elements", buttonSelectedTitle: "Elements"})
          return;
        }

        const progressBarIsClicked = progressBarClicked({x, y});
        if(progressBarIsClicked) {
          updateState({selectingObject: {id: 'progressbar', type: "elements"}, buttonSelectedId: "elements", buttonSelectedTitle: "Elements"})
          return;
        }

        const index = indexOfTexts({x, y})
        if(index > -1) {
          const text = state.texts[index];
          updateState({selectingObject: {id: text.id, type: "texts"}, buttonSelectedId: "texts", buttonSelectedTitle: "Text"})
        } 

      }
      // done, set the state back
      setImageSimpleClicked(false);
    }, 100)
  }
  
  return (
    <div      
      onClick={() => {
        const _countClicked = countClicked + 1;
        setCountClicked(_countClicked)
        setTimeout(() => {
          setCountClicked(0)
        }, 400)     
        if(_countClicked > 1) {
          handleDnDElementClicked({type: null, id: null})
        }        
        // console.log(_countClicked)
        // handleDnDElementClicked({type: null, id: null})
      }}     
    >
      {
        texts.map((text) => {
          return (
            <TextDnD
              id={text.id}
              key={text.id}
              defaultLocation={{
                x: text.x,
                // y: text.y,
                y: Number(text.y) + ((text.name.split("\n").length - 1) * Number(text.fontSize) * demoSvgRatio),
                width: Math.max(...(text.name.split("\n").map((t) => t.length))) * Number(text.fontSize) * demoSvgRatio/ 1.5,
                height: Number(text.fontSize) * text.name.split("\n").length * demoSvgRatio,
                textAnchor: text.textAnchor
                // width: 40,
                // height: 10
              }}
              enabled={selectingObject.type === "texts" && selectingObject.id === text.id}
              handleDragStop={(x, y) => {
                const newY = y - (text.name.split("\n").length - 1) * Number(text.fontSize) * demoSvgRatio
                handleTextDnD(text.id, x, newY)
              }}
            />
          )
        })
      }      
      {
        images.map((image) => {
          return (
            <ImageDnD
              id={image.id}
              key={image.id}
              defaultLocation={{
                imageXaxis: image.x,
                imageYaxis: image.y,
                imageWidth: image.width,
                imageHeight: image.height
              }}
              maxWidth={Math.max(Number(demoSvgWidth) - Number(image.x), image.width)}
              maxHeight={Math.max(Number(demoSvgHeight) - Number(image.y), image.height)}
              enabled={selectingObject.type === "images" && selectingObject.id === image.id}
              handleDragStop={handleImageLocationChanged}
              handelResizeStop={handleImageSizeChanged}  
              handleOnClick={handleSelectedImageClicked} 
            />
          )
        })
      }  
      <WaveformDnD
        defaultLocation={
          {
            x: soundWave.x,
            y: soundWave.y,
            width: soundWave.width ? soundWave.width : demoSvgWidth - 20,
            height: soundWave.barHeight,
          }
        }
        handleDragStop={handleSoundWaveChange}
        handelResizeStop={handleSoundWaveSizeChanged}
        enabled={soundWaveAdded && selectingObject.type === "elements" && selectingObject.id === 'soundwave'}
      />

      <ProgressBarDnD
        defaultLocation={
          {
            x: progressBar.x,
            y: progressBar.y,
            width: progressBar.width ? progressBar.width : demoSvgWidth,
            height: progressBar.height,
          }
        }
        handleDragStop={handleProgressBarChange}
        handelResizeStop={handleProgressBarSizeChanged}
        enabled={progressBarAdded && selectingObject.type === "elements" && selectingObject.id === 'progressbar'}
      />      

      <ImageDnD                
        defaultLocation={{
          imageXaxis: justcastBadge.x === 0 ? demoSvgWidth / 2 - 45 : justcastBadge.x,
          imageYaxis: justcastBadge.y === 0 ? demoSvgHeight - 40 : justcastBadge.y,
          imageWidth: justcastBadge.width,
          imageHeight: justcastBadge.height
        }}
        enabled={showBadge && selectingObject.type === "elements" && selectingObject.id === "justcast-badge"}
        handleDragStop={hanleJustCastBadge}
        enableResizing={false}
      /> 

      <svg xmlns="http://www.w3.org/2000/svg" version="1.2" 
        height={state.demoSvgHeight} 
        width={state.demoSvgWidth}
        ref={canvasDemoRef} 
      >          
          <rect 
            fill={state.backgroundColor ? state.backgroundColor : state.defaultBackgroundColor} x="0" y="0" rx="0" ry="0"
            height={state.demoSvgHeight} 
            width={state.demoSvgWidth} />
          {
            images.map((image) => {
              return (
                <g
                  key={image.id}
                  onClick={() => {
                    setTimeout(() => {
                      handleDnDElementClicked({type: "images", id: image.id})
                    }, 50)                    
                  }}                  
                >
                  <image 
                    href={image.artwork} 
                    x={image.x} 
                    y={image.y} 
                    height={image.height} 
                    width={image.width}
                  />
                </g>
              )   
            })
          }
          {
            texts.map((text) => {
              return (
                <g 
                  key={text.id}
                  onClick={() => {
                    setTimeout(() => {
                      handleDnDElementClicked({type: "texts", id: text.id})
                    }, 50)                    
                  }}
                >
                  {
                    text.name.split("\n").map((textline, textlineid) => 
                      <text
                        key={`textline-${text.id}-${textlineid}`}
                        x={(text.x)} 
                        y={Number(text.y)+((Number(text.fontSize) * demoSvgRatio + 2) * textlineid)}                   
                        fill={text.color} 
                        textAnchor={text.textAnchor}
                        // textAnchor="middle"
                        fontStyle={text.fontStyle} // italic
                        fontWeight={text.fontWeight} // bolder
                        // fontFamily="Helvetica, Arial, Lucida Grande, sans-serif" 
                        fontFamily={text.fontFamily}
                        style={{fontSize: (Number(text.fontSize) * demoSvgRatio )+"px" }}>
                          {textline}                          
                      </text>
                    )
                  }
                {/* <text                   
                  x={text.x} 
                  y={text.y}                   
                  fill={text.color} 
                  fontFamily="Helvetica, Arial, Lucida Grande, sans-serif" 
                  style={{fontSize: (Number(text.fontSize) * (2.2639 / 2))+"px" }}>
                    {text.name}                          
                </text> */}
                </g>
              )
            })
          }
          {
            progressBarAdded ? 
            <>
            {
              progressBar.showTimer ? <>
              <text                   
                x={progressBar.x} 
                y={progressBar.y + progressBar.height + 12} 
                fill={progressBar.filledColor} 
                fontFamily="Helvetica, Arial, Lucida Grande, sans-serif" 
                style={{fontSize: (Number(progressBar.fontSize))+"px" }}>
                  {timeStringHandler.getTimeString(clipFromSeconds)}                          
              </text>

              <text                   
                // x={(progressBar.width ? progressBar.width : demoSvgWidth) + progressBar.x - (1 + timeStringHandler.getTimeString(clipEndSeconds - playedSeconds)).length * 6.5} 
                x={(progressBar.width ? progressBar.width : demoSvgWidth) + Number(progressBar.x) } 
                y={progressBar.y + progressBar.height + 12} 
                fill={progressBar.filledColor} 
                textAnchor="end"                
                fontFamily="Helvetica, Arial, Lucida Grande, sans-serif" 
                style={{fontSize: (Number(progressBar.fontSize))+"px" }}>
                  -{timeStringHandler.getTimeString(clipEndSeconds - playedSeconds)}                          
              </text> 
              </> : null
            }                                 
              <rect 
                  x={progressBar.x}
                  y={progressBar.y}
                  rx="0" 
                  ry="0"
                  width={(progressBar.width ? progressBar.width : demoSvgWidth) * ( state.playedSeconds < state.clipFromSeconds ? 0 : (state.playedSeconds - state.clipFromSeconds) / (state.clipEndSeconds - state.clipFromSeconds > 10 ? state.clipEndSeconds - state.clipFromSeconds : 1))}
                  height={progressBar.height}
                  fill={progressBar.filledColor}
                  fillOpacity="80%"
              />
              <g
                onClick={() => {
                  setTimeout(() => {
                    handleDnDElementClicked({type: "elements", id: 'progressbar'})
                  }, 10)                   
                }}               
              >
              <rect 
                x={progressBar.x}
                y={progressBar.y}
                rx="0" 
                ry="0"
                width={progressBar.width ? progressBar.width : demoSvgWidth}
                height={progressBar.height}
                fill={progressBar.filledColor}
                fillOpacity="50%"
              />
              </g>
            </> : null
          }          
          {                        
            soundWaveData.map((data, idx) => {
              return <rect key={idx.toString()} fill={soundWave.barColor} x={data.xAxis} y={data.yAxis} width={data.barWidth} height={data.height} rx="0" ry="0"/>
            }              
            )
          }           
          {
              soundWaveAdded ? <g 
              onClick={() => {
                setTimeout(() => {
                  handleDnDElementClicked({type: "elements", id: 'soundwave'})
                }, 10)                    
              }}          
            >
              <rect 
                x={soundWave.x}
                y={soundWave.y}
                rx="0" 
                ry="0"
                width={soundWave.width ? soundWave.width : demoSvgWidth - 20}
                height={soundWave.barHeight}
                fillOpacity="0%"
                />
              </g> : null
          }          
          {                                           
            showBadge ? <g                 
              onClick={() => {
                setTimeout(() => {
                  handleDnDElementClicked({type: "elements", id: "justcast-badge"})
                }, 50)                    
              }}
            >
              <image 
                href={justcastBadge.artwork} 
                x={justcastBadge.x === 0 ? demoSvgWidth / 2 - 45 : justcastBadge.x} 
                y={justcastBadge.y === 0 ? demoSvgHeight - 40 : justcastBadge.y} 
                height={justcastBadge.height} 
                width={justcastBadge.width}
              /> 
            </g> : null            
          }
      </svg>
    </div>
  )
}

export default SvgDemoArea;