import React, {useContext, useState} from "react";
import { Redirect } from "react-router";
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AlertContext} from '../context/AlertContext'
import { Label, Form, FormGroup, Input, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import justcastApi from '../api/justcast';
import ErrorMessagesList from './ErrorMessagesList'
import FormGroupListItemInputSwitch from '../components/FormGroupListItemInputSwitch';

const NewPodcastModal = () => {
  const {state, reset} = useContext(ModalContext);
  const alertContext = useContext(AlertContext);
  const [name, setName] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  // const [redirect, setRedirect] = useState(false);
  const [showId, setShowId] = useState(null)

  const handleNameChange = (e) => {
    const v = e.target.value;
    setName(v);
  }

  const handleToggleChange = () => {
    setIsPrivate(!isPrivate)
  }

  const handleSave = (e) => {
    e.preventDefault();
    justcastApi.post('/v2/shows', {name, is_advanced_private_feed: isPrivate})
    .then((res) => {
      setShowId(res.data.id); // redirect to show page
      reset(); // reset the modal
      const name = res.data.name;
      alertContext.addWithTimeout({color: 'warning', title: 'Podcast created!', message: `The ${name} was created.`, isOpen: true, timeout: 2000}) // color, title, message, isOpen, timeout
      // add notification
    })
    .catch((err) => {
      const errors = err.response.data;
      if(errors.message) {
        setErrorMessages([errors.message]);
      } else {
        setErrorMessages(errors);
      }
    })
  }


  if(showId) {
    return (
      <Redirect to={`/shows/${showId}/overview`} />
    )
  }

  if(state.startNewPodcast) {
    return (
      <Modal isOpen={state.startNewPodcast}>
        <ModalHeader toggle={reset}>
          <strong>New Podcast</strong>
        </ModalHeader>
        <ModalBody>
          <ErrorMessagesList errors={errorMessages}/>
          <Form onSubmit={handleSave}>
            <FormGroup>
              <Label for="podcastName">Podcast name</Label>
              <Input type="text" name="name" id="podcastName" placeholder="podcast name" value={name} onChange={handleNameChange}/>
            </FormGroup>
            <FormGroupListItemInputSwitch
              inputType='switch'
              inputName='is_advanced_private_feed'
              inputLabel="Members Private Podcast"
              mutedText="Restrict access to a group of subscribers with a private podcast.  Each subscriber will receive an invite via email."
              inputValue={isPrivate}
              inputCSSId='advanced-private-show-switch'
              messageTitle={"Message"}
              warningMessage=""
              handleInputChange={handleToggleChange}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={reset}>Cancel</Button>
          <Button color="primary" onClick={handleSave}>Create</Button>
        </ModalFooter>
      </Modal>
    )
  }
  return null;
}

export default NewPodcastModal;