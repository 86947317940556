import axios from 'axios';
const mime = require('mime-types');

const instance = axios.create({
  baseURL: process.env.REACT_APP_MS_UPLOADER_PROXY 
});

export const uploadHandler = (file) => {  
  const data = new FormData()
  data.append('file', file)
  return instance.post('/api/upload', data)
}

export const uploadImageHandler = (file) => {
  if(file) {
    const data = new FormData()
    data.append('file', file)
    return instance.post('/api/upload_images', data)  
  }
  return new Promise((resolve, reject) => {
    resolve(null);
  })
}

export const replaceImageHandler = (file, fileName) => {
  if(file) {
    const data = new FormData()
    if(fileName) {  
      data.append('file', file, `|justcast|${fileName}`) // add |justcast| as spreader      
    } else {
      data.append('file', file)
    }    

    return instance.post('/api/upload_images', data)
  }
  
  return new Promise((resolve, reject) => {
    resolve(null);
  })
}

export const uploadFileHandler = (file) => {
  const data = new FormData()
  data.append('file', file);

  const onUploadProgress = (data) => {
    console.log(Math.round((100 * data.loaded) / data.total));
  }
  return instance.post('/api/upload_file', data, onUploadProgress)  
}

export const uploadFileWithCondition = (file) => {
  if(file) {
    const data = new FormData()
    data.append('file', file)
    return instance.post('/api/upload_images', data)  
  } else {
    return new Promise((resolve, reject) => {
      resolve({data: {path: null}});
    })
  }
}

export const uploadChapters = (data) => {
  return instance.post('/api/upload_podcast_chapters', data)
}

export default {}