import React, {useContext, useState, useEffect } from "react";
import defaultImg from './../../../assets/img/avatars/teams/team-logo-5.jpg'
import { Card, CardBody } from 'reactstrap';
import justcastApi from '../../../api/justcast';
import {Context as MenuContext} from '../../../context/MenuContext'
import { Link } from "react-router-dom";

export default function TeamPodcasts() {
  const menuContext = useContext(MenuContext);
  const [shows, setShows] = useState([]);

  useEffect(() => {
    menuContext.setPodcastsPageMenus({title: 'Team Podcasts', subtitle: "Overview", currentPageId: 'home', currentTabId: "team_podcasts"});

    justcastApi.get(`/v2/team_podcasts`)
    .then((res) => {
      setShows(res.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }, [])

  return (
    <div className="container-fluid">
      {
        shows.map((podcast) => <Card key={podcast.id}>
          <CardBody>
            <div className="row align-items-center">
              <div className="col-auto">
                <Link to={`/team_podcasts/${podcast.id}`} className="avatar avatar-md">
                  <img src={podcast.artwork_url_256 ||  defaultImg} className="avatar-img rounded"/>
                </Link>
              </div>
              <div className="col ml-n2">
                <h4 className="mb-1"><Link to={`/team_podcasts/${podcast.id}`}>{podcast.podcast_title}</Link></h4>
              </div>
            </div>
          </CardBody>
          {
            podcast.is_private_show && <div className="card-footer card-footer-boxed">
              <div className="row align-items-center">
                <div className="col-6 col-md-auto"><span className="badge badge-primary"> Private</span></div>
              </div>
          </div>
          }
        </Card>)
      }
    </div>
  )
}
