import React from "react";
import {  Alert, ListGroup, ListGroupItem, CardBody, Badge } from 'reactstrap';

const MailchimpConnectedBody = (props) => {
  const {campagin_web_id, list_web_url, campaign_web_url, template_web_url} = props;

  if(campagin_web_id) {
    return (      
      <CardBody>
        <Alert color="primary">Go to Episodes page to send email campaign.</Alert>
        <p>You can go to the Mailchimp web application to review campagin, list, and template settings.</p>
        <h3 className="mb-1" style={{ padding: '0px 0px 20px 0px'}}>Settings</h3>
        <ListGroup>
          <ListGroupItem tag="a" target="_blank" href={list_web_url} style={{color: "white"}}><Badge color="primary"><span className="fe fe-list"/></Badge> View subscriber list settings in the Mailchimp web application</ListGroupItem>
          <ListGroupItem tag="a" target="_blank" href={template_web_url} style={{color: "white"}}><Badge color="primary"><span className="fe fe-file-text"/></Badge> View email template settings in the Mailchimp web application</ListGroupItem>
        </ListGroup>
        <hr/>
        {props.children}
      </CardBody>
    )
  }

  return null;

}

export default MailchimpConnectedBody;