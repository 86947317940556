import React, {useContext, useState, useEffect, useCallback} from "react";
import { Link } from "react-router-dom";
import queryString from 'query-string'
import {Context as AuthContext} from '../context/AuthContext'
import AuthForm from '../components/AuthForm'
import justcastApi from './../api/justcast'
import happinessImg from './../assets/img/illustrations/happiness.svg'
import ForgotPasswordEmailSent from '../components/ForgotPassowrdEmailSent'
import AuthModal from '../components/AuthModal';
import AsyncStorage from '../libs/AsyncStorage'
import {Mixpanel} from '../api/mixpanel'

const SignIn = (props)  => {
  const {state, signin} = useContext(AuthContext);
  const [showPasswordInput, setShowPasswordInput] = useState(true)
  const [redirectBack, setRedirectBack] = useState(false)
  const [allowMigration, setAllowMigration] = useState(false)

  const [message, setMessage] = useState("Free access to our dashboard.");
  const [modalMessage, setModalMessage] = useState({})

  useEffect(() => {
    const values = queryString.parse(props.location.search);

    const {message, shopify_connection_public_id} = values || {};

    if(shopify_connection_public_id) {
      AsyncStorage.setShopifyPublicId(shopify_connection_public_id)
      setModalMessage({message: "You're all set! JustCast is now added to your Shopify shop.  To get started, please sign in to your JustCast account.  Don't have an account yet? No worries! Creating one is quick and easy.", isOpen: true});
    } else if (message) {
      setMessage(message);
      setModalMessage({message, isOpen: true});
    }

    AsyncStorage.setReferralCode(props.location.search);
    Mixpanel.track('Sign in page loaded');
  }, [])

  if(state.forgotPassowrdEmailSent) {
    return (
      <ForgotPasswordEmailSent bodyText={"If the email address you provide is associated with an account in our records, you will receive an email with instructions for resetting your password.  If you don't receive this email, please check your junk mail folder or contact us."}/>
    )
  }

  return (
    <>
      <AuthModal message={modalMessage.message} isOpen={modalMessage.isOpen} className={"default"} buttonLabel={"OK"}/>
      <div className="container authentication">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 d-none d-md-inline-block">
            <div className="text-center"><img src={happinessImg} alt="..." className="img-fluid"/></div>
          </div>
          <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
            <h1 className="display-4 text-center mb-3">Sign in</h1>
            <AuthForm
              headerText={message}
              errorMessages={state.errorMessages}
              submitButtonText="Sign in"
              showPasswordInput={showPasswordInput}
              redirectBack={redirectBack}
              allowMigration={allowMigration}
              onSubmit={signin}
            />
            <br/>
            <div className="text-center">
              <small className="text-muted text-center">
                Don't have an account?  Please go back to <Link to="/signup">Sign up</Link>.
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignIn


