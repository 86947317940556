import React from 'react'
import Season from './Season'

export default function SeasonList({seasons, editClicked, deleteClicked}) {
  if(seasons) {
    return (
      <div className='list-group list-group-flush list-group-activity my-n3'>
        {
          seasons.map((season, idx) => {
            return (
              <Season key={`season-${idx+1}`} name={season.name} number={season.number}>
                <div className="col-auto">
                  <div className="btn btn-sm btn-danger" onClick={() => {
                    deleteClicked(season.id)
                  }}>Delete</div> {" "}
                  <div className="btn btn-sm btn-primary" onClick={() => {
                    editClicked(season)
                  }}>Edit</div>
                </div>
              </Season>)
          })
        }
      </div>
    )
  }

  return null;
}
