import React, {useEffect, useState, useContext} from "react";
import { Link, Redirect } from "react-router-dom";
import justcastApi from '../api/justcast';
import UpdateNotification from '../components/UpdateNotification';
import ShowSettingsForm from '../components/ShowSettingsForm';
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import { Spinner } from 'reactstrap';

const ShowSettings = (props) => {

  const id = props.match.params.id;
  const {addWithTimeout} = useContext(AlertContext);
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const [showName, setShowName] = useState('');
  const [item, setItem] = useState({});
  const [isAdvancedPrivatePodcast, setIsAdvancedPrivatePodcast] = useState(false);
  const [goToPrivatePodcastSubscribersPage, setGoToPrivatePodcastSubscribersPage] =  useState(false);
  const [denied, setDenied] = useState(false);
  const [timeZones, setTimeZones] = useState([]);
  const [isPrivateShow, setIsPrivateShow] = useState(false);

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;

      if(!res.data.time_zone) {
        res.data.time_zone = 'UTC';
      }
      setShowName(name);
      setIsAdvancedPrivatePodcast(res.data.is_advanced_private_feed)
      setIsPrivateShow(res.data.is_private_show);
      setItem(res.data);
      screenContext.finishFetching();

      menuContext.setSettingsMenus({
        title: 'Advanced',
        subtitle: name,
        currentPageId: 'settings',
        currentTabId: 'advanced_settings',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
      setDenied(true)
    })

    justcastApi.get('/v1/help/time_zones')
    .then((res) => {
      setTimeZones(res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  },[id])

  const handleToggleChange = (event) => {
    // console.log(event.target.name, event.target.checked)
    const key = event.target.name;
    const value = event.target.checked;
    let data = {[key]: value}
    setItem({...item, ...data})
  }

  const handleInputValueChange = ({key, value}) => {
    setItem({...item, [key]: value})
  }

  const handleSelectionChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setItem({...item, [key]: value})
  }

  const handleUpdate = (event) => {
    event.preventDefault();
    justcastApi.put(`/v1/shows/${id}`, item)
    .then((res) => {
      setItem(res.data);
      addWithTimeout({color: 'warning', message: `${showName} has been updated!`, isOpen: true, timeout: 3000})
      // redirect to advanced private podcast subscriber manage page, if we set the show to advanced prviate podcast
      if(isAdvancedPrivatePodcast === false && res.data.is_advanced_private_feed === true) {
        setGoToPrivatePodcastSubscribersPage(true);
      } else if (isPrivateShow !== res.data.is_private_show) {
        window.location.reload();
      }
    })
    .catch((err) => {
      let message = "Error";
      if(err.response && err.response.data && err.response.data.error) {
        message = err.response.data.error
      }
      addWithTimeout({color: 'danger', message: message, isOpen: true, timeout: 3000})
    })
  }

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  if(goToPrivatePodcastSubscribersPage) {
    return <Redirect to={`/shows/${id}/subscribers`} />
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> :
              <>
                <ShowSettingsForm
                  is_private={item.is_private}
                  is_advanced_private_feed={item.is_advanced_private_feed ? item.is_advanced_private_feed : false}
                  is_password_protected={item.is_password_protected ? item.is_password_protected : false}
                  username={item.username}
                  password={item.password}
                  facebook_pixel_base_code={item.facebook_pixel_base_code}
                  google_analytics_id={item.google_analytics_id}
                  sort_by_name={item.sort_by_name}
                  hide_season={item.hide_season}
                  manual_release={item.manual_release}
                  prefix={item.prefix}
                  timeZones={timeZones}
                  timeZone={item.time_zone}
                  drip_separate_days={item.drip_separate_days}
                  map_extention_from={item.map_extention_from}
                  map_extention_to={item.map_extention_to}
                  handleSelectionChange={handleSelectionChange}
                  handleToggleChange={handleToggleChange}
                  handleInputValueChange={handleInputValueChange}
                  handleSubmit={handleUpdate}
                  locked={item.locked}
                  item={item}
                />
                <UpdateNotification/>
                <div className="btn btn-block btn-primary" onClick={handleUpdate}>Save change</div>
                <br/>
                {/* <Link to={`/shows/${id}/episodes`} className="btn btn-block btn-link text-muted">Cancel this change</Link> */}
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ShowSettings