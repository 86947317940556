import React from "react";
import { useAtom } from 'jotai';
import { CustomInput, Col, Card, CardBody } from 'reactstrap';
import { Play, Pause } from 'react-feather';
import {
  playerAtom, 
  toggleSeekingAtom, 
  changeSeekSecondsAtom,
  getProgressSecondsAtom,
  togglePlayPauseAtom,
  SeekToSecondsAtom,
  
} from './playerAtom'
import PlayerTimeLabel from './PlayerTimeLabel'

const PlayerControls = () => {
  const [player] = useAtom(playerAtom);
  const [, toggleSeeking] = useAtom(toggleSeekingAtom);
  const [, changeSeekSeconds] = useAtom(changeSeekSecondsAtom)
  const { durationSeconds, playing } = player;
  const [playedSeconds, _] = useAtom(getProgressSecondsAtom)
  const [, togglePlayPause] = useAtom(togglePlayPauseAtom)
  const [, SeekToSeconds] = useAtom(SeekToSecondsAtom);
  const seekSeconds = [{label: '-5s', value: -5}, {label: '+5s', value: 5}];

  return (
    <Card style={{marginTop: "20px"}}>
      <CardBody>
        <CustomInput
          id="chapters-player-range"
          type="range" 
          value={playedSeconds}
          min={0}
          max={durationSeconds}
          step='any'            
          onMouseDown={toggleSeeking}
          onMouseUp={toggleSeeking}
          onChange={(event) => {
            changeSeekSeconds(Number(event.target.value))
          }}
        />
        <div style={{
          marginTop: '10px', 
          display: 'flex',
          alignItems: 'center'
        }}
        >
          {playing ? <Pause size={24} onClick={togglePlayPause} style={{cursor: 'pointer'}}/> : <Play size={24} onClick={togglePlayPause} style={{cursor: 'pointer'}}/>}
          <div style={{display: 'flex', marginLeft: '10px'}}>
            {
              seekSeconds.map((sec, idx) => {
                return <div 
                  onClick={() =>{
                    SeekToSeconds(sec.value)
                  }}
                  key={(idx+1).toString()} 
                  style={{marginRight: '10px', cursor: 'pointer'}}>{sec.label}</div>
              })
            }
          </div>
          <div style={{marginLeft: "10px", display: 'flex', width: '140px', justifyContent: 'space-between'}}>
            <PlayerTimeLabel seconds={playedSeconds}/>
            <div>/</div>
            <PlayerTimeLabel seconds={durationSeconds}/>
          </div>             
        </div>     
      </CardBody>    
    </Card>
  )
}

export default PlayerControls;