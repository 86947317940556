import moment from 'moment'

export const datediff = (fromDateStr, toDateStr) => {
  if(fromDateStr && toDateStr) {
    const fromDate = moment(fromDateStr);
    const toDate = moment(toDateStr);
    const duration = moment.duration(toDate.diff(fromDate));
    return Math.floor(duration.asDays());
  }
  return 0;
}

export const dateRangeType = (fromDateStr, toDateStr) => {
  const days = datediff(fromDateStr, toDateStr);
  if(days < 30) {
    return 'day';
  } else if (days < 180) {
    return 'week';
  } else if (days < 365 * 2) {
    return 'month'
  } else {
    return 'year';
  }  
}

export {};