import React, {useContext, useState, useEffect} from "react";
import Select from 'react-select'
import { Card, CardHeader, CardBody, Button, CardFooter } from 'reactstrap';
import justcastApi from '../../api/justcast';
import {Context as ModalContext} from '../../context/ModalContext'
import {Context as AlertContext} from '../../context/AlertContext'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
  })
}

export default function ConnectMailerLiteGroup({accountId, showId, groups, handleGroupChange}) {

  const [selectedGroup, setSelectedGroup] = useState({label: "", id: ""});
  const {add, reset} = useContext(ModalContext);
  const {addWithTimeout} = useContext(AlertContext);

  const handleAddGroup = () => {
    // TODO: some sort of loading....?
    // Then when it done, some sort of notification
    // add({title: `${selectedGroup.label}`, message: "Importing members...", isOpen: true, loading: true})
    justcastApi.post(`/v2/shows/${showId}/mailerlite_groups`, {
      mailerlite_group: {
        mailer_lite_group_id: selectedGroup.id,
        mailer_lite_group_name: selectedGroup.label
      }
    })
    .then((res) => {
      handleGroupChange(res.data)
    })
    .catch((err) => {
      add({title: `${selectedGroup}`, message: "Something went wrong.  Please screenshot this page and send it to justcastapp@gmail.com", isOpen: true, loading: false})
      console.log(err)
    })
  }

  if(!groups) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <div className="row align-items-center">
          <div className="col">
            <h2 className="card-header-title">MailerLite Groups</h2>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Select
          value={selectedGroup}
          options={groups.map((group) => {
            return {label: group.name, id: group.id, value: group.id}
          })}
          styles={customStyles}
          onChange={(e) => {
            setSelectedGroup(e)
          }}
        />
      </CardBody>
      <CardFooter>
        <Button color="primary" onClick={handleAddGroup} disabled={selectedGroup["id"] === ""}>Add group</Button>
      </CardFooter>
    </Card>
  )
}
