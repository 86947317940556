import React, {useEffect} from "react";
import { useAtom } from 'jotai';
import justcastApi from '../api/justcast';
import { 
  addPodcastAtom,
  addEpisodesAtom,
  addPlayerAtom,
} from './../jotai'
import { PlayerContainer} from './../components/MobilePlayer'


const MobilePlayerScreen = (props)  => {
  
  const id = props.match.params.id;
  // const {name} = podcastAtom;
  const [, podcastSet] = useAtom(addPodcastAtom);
  const [, episodesSet] = useAtom(addEpisodesAtom);
  const [, playerSet] = useAtom(addPlayerAtom);
    
  useEffect(() => {
    justcastApi(`/v1/shows/${id}/audioposts`)
    .then((res) => {
      const data = res.data;
      const show = data.show;
      const audioposts = data.audioposts;
      episodesSet({items: audioposts})
      podcastSet(show);
      playerSet({item: audioposts[0]});
    })
    .catch((err) => {
      console.log('gg');
      // redirect to main page of what so ever
    })
  }, [id])

  return (
    <PlayerContainer/> 
  )
}

export default MobilePlayerScreen