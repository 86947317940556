import React, {useState, useEffect, useContext} from 'react'
import { Spinner } from 'reactstrap';
import {Elements} from 'react-stripe-elements';
// import CheckoutForm from './../Components/Stripe/CheckoutForm'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AuthContext} from '../context/AuthContext'
import justcastApi from '../api/justcast'
import SimplePageHeader from '../components/PageHeader/SimplePageHeader'
import StripeCheckoutForm from '../components/StripeCheckoutForm'

const PlanNew = (props) => {
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext);
  const authContext = useContext(AuthContext);

  const email = authContext.state.email;

  const planId = props.match.params.id;
  const [plan, setPlan] = useState({id: "", name: '', amount: 0, interval: '', currency: ""})

  useEffect(() => {
    screenContext.startFetching();
    menuContext.setMainPageLeftMenu({title: 'Plans', subtitle: email, currentPageId: 'home'});

    justcastApi.get(`/v1/plans/${planId}`)
    .then((res) => {
      screenContext.finishFetching();
      setPlan({...res.data});
    })
    .catch((err) => {
      screenContext.finishFetching();
      // TODO: Redirect to payments option page
      // console.log(err)
    })    
  }, [planId])
  
  return (
    <>      
      <div className="container-fluid">
        {screenContext.state.loading ? <Spinner color="primary" /> : <BodyDiv name={plan.name} price={plan.amount/100} planId={plan.id}/> }
      </div>
    </>
  )
}

const BodyDiv = ({name, price, planId}) => {

  return (
    <>
      <SimplePageHeader
        headerPreTitle={'Plans'}
        headerTitle={planId}
        headerLinks={[{
          id:'cancel-button',
          type: 'link',
          className:"btn btn-primary",
          to: '/plans',
          text:'Cancel'
        }]}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="card">
              <div className="card-body">
                <Elements>
                  <StripeCheckoutForm price={price} name={name} planId={planId}/>
                </Elements>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlanNew;