export const sanitizeConf = {
  allowedTags: ["span"],
  allowedAttributes: { span: ["id","name","class","value"] }
};

export const parseSpans = (nodes) => {
  // we are doing this crazy merge word ("#text") to the prev or later node..., all because Firefox
  const result = [];
  let orphanWords = [];
  nodes.forEach((node) => {
    const word = node.textContent.trim();
    if(node.nodeName === "#text") {
      if(result.length > 0) {
        const k = result.length - 1;
        result[k]['word'] = `${result[k]['word']} ${word}`;
      } else {
        orphanWords.push(word);
      }
    } else {
      const item = { word }
      const attrNames = node.getAttributeNames();
      for(let i = 0; i < attrNames.length; i++) {
        const name = attrNames[i];
        item[name] = node.getAttribute(name);
      }
      result.push(item);
    }
  })

  if(orphanWords.length > 0 && result.length > 0) {
    result[0]['word'] = `${orphanWords.join(" ")} ${result[0]['word']}`;
    orphanWords = [];
  } else if (orphanWords.length > 0 && result === 0) {
    // TODO: handle this
    return;
  }

  return result;
}

export const binarySearch = (arr, target) => {
  let startIndex = 0;
  let endIndex = arr.length - 1;

  while(startIndex <= endIndex) {
    let middleIndex = Math.floor((startIndex + endIndex) / 2);
    if(target === arr[middleIndex]) {
      return middleIndex;
    }
    if(target > arr[middleIndex]) {
      startIndex = middleIndex + 1;
    }
    if(target < arr[middleIndex]) {
      endIndex = middleIndex - 1;
    }
  }

  return endIndex;
}

export const sanitizeText = (text) => {
  return text.replaceAll('<br>', "").replaceAll("\n", " ").replaceAll("\&nbsp", " ")
}