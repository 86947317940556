import React, {useEffect, useState, useContext } from "react";
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import PanelImagePreviewThumb from './PanelImagePreviewThumb'
import PanelAddNewItem from './PanelAddNewItem'
import ModalImageUploader from './ModalImageUploader'

const PanelImageTab = () => {
  const {state} = useContext(PodieoAudioContext); 
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleAddNewImageClicked = () => {
    toggle();
  }

  return (
    <>      
      {state.images.map((image, index) => 
          <PanelImagePreviewThumb
            key={image.id}
            id={image.id}
            artworkUrl={image.artwork}
            width={image.width}
            height={image.height}
          />
        )
      }      
      <PanelAddNewItem 
        title={'Add new Image'}
        handleClick={handleAddNewImageClicked}
      />
      <ModalImageUploader modal={modal} toggleModal={toggle}/>
    </>
  )
}

export default PanelImageTab;