import createDataContext from './createDataContext';

// const initState = {
//   stepName: null,
//   showId: null,
//   audiopostId: null,
//   showName: null,
//   audiopostName: null,          
//   statusCode: null,
//   message: null,
//   uploadedFileSize: 0,
//   totalSize: 100,
// }

const initState = {
  socket: null,
  channels: [],
  updatingAudioposts: {}
}

const fileUploadReducer = (state, action) => {
  switch(action.type) {
    case 'add':
      return {...state, ...action.payload}
    case 'add_channel':
      return {...state, ...action.payload}
    case 'add_data_to_channel':    
      const {channel, data} = action.payload;  
      const tmp = {...state.updatingAudioposts};
      tmp[channel] = data;
      return {...state, updatingAudioposts: tmp}
    case 'reset': 
      return {...initState}
    default:
      return state    
  }
}

const add = dispatch => {
  return (socket) => {
    dispatch({type: 'add', payload: socket})
  }
}

const reset = dispatch => {
  return () => {
    dispatch({type: 'reset'})
  }
}

const addChannel = dispatch => {
  return (channels, updatingAudioposts) => {
    dispatch({type: 'add_channel', payload: {channels, updatingAudioposts}})
  }
}

const addDataToChannel = dispatch => {
  return (channel, data) => {
    dispatch({type: 'add_data_to_channel', payload: {channel, data}})
  }
}

export const {Provider, Context} = createDataContext(
  fileUploadReducer,
  {
    add,
    reset,
    addChannel,
    addDataToChannel
  },
  initState
)

