import React, {useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Button} from 'reactstrap'
import Sendgrid from '../../assets/img/posts/sendgrid-example.webp'

export default function SuccessMessage({email, toggle}) {
  const [copyState, setCopyState] = useState(false);

  const hanldeCopyClick = () => {
    setCopyState(true);
    setTimeout(() => {
      setCopyState(false);
    }, 2000)
  }

  return (
    <div>
      <h3>Sender has been created</h3>
      <p>To verify sender identity, check your inbox at {email}</p>
      <p>You will receive an email from SendGrid, please forward it to us at <span className='text-warning'>justcastapp@gmail.com</span> (see example screenshot for reference). Please allow up to 48 hours for review.</p>
      <div>
        <span>justcastapp@gmail.com </span>
        <CopyToClipboard text={"justcastapp@gmail.com"}
          onCopy={hanldeCopyClick}
        >
          <Button color="primary" size="sm">Copy to clipboard</Button>
        </CopyToClipboard>
        {copyState && <span className='text-success'> Copied.</span>}
      </div>
      <div style={{padding: 20}}>
        <img src={Sendgrid} alt="Sendgrid example" className="avatar-img rounded"/>
      </div>
      <Button className="float-right" color="primary" onClick={toggle}>Close</Button>
    </div>
  )
}
