import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../api/justcast';
import {uploadImageHandler} from '../api/doUploader'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import ImagesList from '../components/ImagesList'
import {ImageUploaderModal} from '../components/ImageUploaderModal'
import ScreenBlocker from '../components/ScreenBlocker'

const ShowWebsiteImages = (props) => {
  const id = props.match.params.id;
  const [screenBlocker, setScreenBlocker] = useState(false);
  const menuContext = useContext(MenuContext);
  const [showName, setShowName] = useState('');
  const [item, setItem] = useState({});
  const [images, setImages] = useState([]);
  const {addWithTimeout} = useContext(AlertContext);
  const [imageModal, setImageModal] = useState(false);
  const [uploadingImage, setUploadingImage] = useState({});
  const [uploadingImageStatus, setUploadingImageStatus] = useState(false);

  const handleUpdateImages = (res) => {
    setImages([
      {
        id: 'header_img_url', 
        title: 'Header Image', 
        src: res.data.header_img_url,
        label: 'Replace the default website header text with an image of your own.  Recommended dimensions: 500px max width, and wider than taller.'
      },
      {
        id: 'social_sharing_image', 
        title: 'Social Sharing Image', 
        src: res.data.social_sharing_image,
        label: 'Add an image that will display when share on Twitter, Facebook, etc.  Recommended dimensions: 1024x512px.'
      },
      {
        id: 'custom_favicon_image', 
        title: 'Custom Favicon Image', 
        src: res.data.custom_favicon_image,
        label: 'Add a special square icon that will display in web browser tabs.  Recommended dimensions: 192x192px.' 
      },
      {
        id: 'about_page_header_image',
        title: "About us Header Image",
        src: res.data.about_page_header_image,
        label: 'Replace the default website header text with an image of your own.  Recommended dimensions: 500px max width, and wider than taller.'
      }
    ])
  }


  const toggleImageModal = () => {
    setImageModal(!imageModal);
  }

  const openImageUploadModal = (image) => {
    setUploadingImage({...image});
    setImageModal(true);
  }

  const deleteImage = (image) => {
    const key = image.id;
    justcastApi.put(`/v1/shows/${id}`, {[key]: null})
    .then((res) => {
      setItem(res.data);
      handleUpdateImages(res);
    })
    .catch((err) => {
      console.log(err)
    })       
  }

  const handleUpload = (imageSrc) => {
    if(imageSrc) {
      // upload
      uploadImageHandler(imageSrc)
      .then((res) => {
        const url = res.data.path;
        const key = uploadingImage.id;        
        return justcastApi.put(`/v1/shows/${id}`, {[key]: url});
      })
      .then((res) => {
        setUploadingImageStatus(true);      
        setTimeout(() => {
          setItem(res.data);
          handleUpdateImages(res);
          setUploadingImageStatus(false);
          setImageModal(false);
          addWithTimeout({color: 'warning', title: `Image upload`, message: "Successful uploaded the image", isOpen: true, timeout: 2000});
          // alert uploaded
        }, 1000)
      })
      .catch((err) => {
        console.log(err)
        setUploadingImageStatus(false);
        setImageModal(false);
        addWithTimeout({color: 'warning', title: `Image upload`, message: "Failed uploaded the image", isOpen: true, timeout: 2000});
        // alert failed to upload
      })      
    } else {
      // error
      setImageModal(false)
    }
        
  }
  
  useEffect(() => {    
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;
      setShowName(name);
      handleUpdateImages(res)

      setScreenBlocker(res.data.skill_podcast_website);

      menuContext.setWebsiteMenu({
        title: 'Images',
        subtitle: name,
        currentPageId: 'website_settings',
        currentTabId: 'website_images',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website       
      });   
      setItem(res.data);

    })
    .catch((err) => {
      
    })    
  }, [id]) 
  

  if(screenBlocker === false) {
    return (
      <ScreenBlocker message="Please upgrade your account to access the data."/>
    )
  }  

  return (
    <>
      <ImageUploaderModal 
        modal={imageModal} 
        toggle={toggleImageModal}
        data={uploadingImage}
        uploading={uploadingImageStatus}
        handleUpload={handleUpload}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <ImagesList 
              images={images} 
              openModal={openImageUploadModal}
              deleteHandler={deleteImage}
            />
          </div>
        </div>
      </div>  
    </>
  )  
}

export default ShowWebsiteImages;