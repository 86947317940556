export const imageSizeChecker = ({acceptedFiles}, cb) => {
  // const errors = [];
  const img = new Image();
  img.src = URL.createObjectURL(acceptedFiles[0])

  img.onload = function() {
    const width = img.width;
    const height = img.height;

    cb({width, height})
  }
}

export default {};