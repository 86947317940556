import React from 'react';
import { Spinner } from 'reactstrap';

const SpinnerLoading = ({title}) => {
  return (
    <div className="d-flex align-items-center">
      <h4>{title}</h4>
      <Spinner color="dark" className="ml-auto" style={{ width: '3rem', height: '3rem' }}/>
    </div>  
  )
}

export default SpinnerLoading
