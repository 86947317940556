import React from 'react'
import { ListGroup, ListGroupItem, Card, CardBody } from 'reactstrap';

export default function ShopifyPricing({
  name,
  price,
  features,
  activePlanName,
  subscribeClick,
}) {

  const onClick = () => {
    subscribeClick(name)
  }
  return (
    <ListGroupItem>
      <div className='row align-items-center'>
        <div className='col'>
          <h4 className='mb-1'>{name} - ${price}</h4>
          <small className='text-muted'>{features.join(", ")}</small>
        </div>
        <div className='col-auto'>
          <button className={`btn btn-sm btn-${activePlanName === name ? "danger" : "primary"}`} onClick={onClick}>{activePlanName === name ? "Cancel" : "Subscribe"}</button>
        </div>
      </div>
    </ListGroupItem>
  )
}
