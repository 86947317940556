import React, {useState, useEffect, useContext, useRef} from "react";
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import Drawer from '../../libs/Drawer';

const WaveformElementDrawer = () => {
  const {state, updateState} = useContext(PodieoAudioContext);
  const {
    playing, playerDivId, onReady, soundWave, demoSvgWidth,soundWaveAdded,
    audioContext, audioAnalyser, audioEle, audioSource, countSoundWaveChanged
  } = state;
  const [intervalID, setIntervalID] = useState(0);
  const [svgDrawer, setSvgDrawer] = useState(null)

  const initAudioContext = () => {
    // setup the connection between the audio and audio context to analyze audio
    if( audioContext === null) {
      const _audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const _analyser = _audioContext.createAnalyser();
      const _audioEle = document.getElementById(playerDivId);
      const _source = _audioContext.createMediaElementSource(_audioEle);
      _source.connect(_analyser);
      _source.connect(_audioContext.destination);
      
      updateState({
        audioContext: _audioContext,
        audioAnalyser: _analyser,
        audioEle: _audioEle,
        audioSource: _source
      })
    }
    //id: null, type: null, width: null, height: null, barHeight: 40, barWidth: 5, barGap: 2, barColor: '#fff', fillColor: "#E35986"
    // setup the class the drawer class
    if(soundWave.id) {
      // const drawer = new Drawer(demoSvgWidth, 10, 10, soundWave.barGap, soundWave.barWidth, soundWave.barHeight, soundWave.x, soundWave.y)
      const drawer = new Drawer(soundWave.width ? soundWave.width : demoSvgWidth - 20, 0, 0, soundWave.barGap, soundWave.barWidth, soundWave.barHeight, soundWave.x, soundWave.y)
      setSvgDrawer(drawer)
    }
  }

  useEffect(() => { 
    if(onReady && soundWaveAdded) {
      initAudioContext()
    }
  }, [onReady, soundWaveAdded])

  useEffect(() => {
    if(svgDrawer) {
      const width = soundWave.width ? soundWave.width : demoSvgWidth - 20;
      svgDrawer.updateLocation(soundWave.x, soundWave.y, width, soundWave.barHeight)
      if(!playing) {
        const soundWaveData = svgDrawer.generateSvg(state.audioRawData, soundWave.barColor);
        updateState({soundWaveData})
      }
    }
  }, [countSoundWaveChanged])

  useEffect(() => {    
    if(soundWaveAdded) {
      if(playing) {
        if(intervalID === 0) {
          if(audioAnalyser) {
            // get the data every 100 ms, aka draw the waveform every 100 ms
            const _intervalId = setInterval(() => {            
              let array = new Float32Array(1024);
              audioAnalyser.getFloatTimeDomainData(array);            
              if(svgDrawer) {
                const soundWaveData = svgDrawer.generateSvg(array, soundWave.barColor);
                const audioRawData = array; // we are saving the audio feq data so that we can redraw the soundwave later.  e.g. we pause the audio, and move the soundwave to other location,  we will like to be able to draw it again on the new position
                // console.log("----------audio data------------")
                // console.log(audioRawData.toString())
                // console.log("----------svg data------------")
                // console.log(JSON.stringify(soundWaveData))
                updateState({audioRawData, soundWaveData})
              }            
            } , 100)
            setIntervalID(_intervalId)      
          }  
        }   
      } else {
        // stop - if we stop the player, we will like to stop the timer internal too
        if(intervalID !== 0) {
          // cancel the setInterval
          clearInterval(intervalID);
          setIntervalID(0)
        }
      }
    } else {
      if(intervalID !== 0 && playing) {
          clearInterval(intervalID);
          setIntervalID(0)        
      }
    }
  }, [playing, soundWaveAdded, audioAnalyser])  

  return (
    <></>
  )
}

export default WaveformElementDrawer;