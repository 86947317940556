import React, {useEffect, useState, useContext} from "react";
import io from "socket.io-client";
import moment from 'moment'
import {Redirect} from 'react-router-dom'
import justcastApi from '../api/justcast'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as SocketContext} from '../context/SocketContext'
import { LiveviewCard} from '../components/Metrics'
import {Mixpanel} from '../api/mixpanel'

const ShowDownloadLiveview = (props) => {
  const menuContext = useContext(MenuContext);
  const authContext = useContext(AuthContext);
  const socketContext = useContext(SocketContext);
  const uid = authContext.state.uid;

  const id = props.match.params.id;
  const [denied, setDenied] = useState(false);
  const {state, add, addDataToChannel, addChannelForLiveDownloads} = socketContext;
  const {socket, channels, livedownloads}  = state;
  
  const [dateTime, setDateTime] = useState(new Date());
  // const [dataFeed, setDataFeed] = useState([]);

  const handleSocketCallback = (data, channelName) => {
    // setDateTime(new Date());
    addDataToChannel(channelName, data);
  }

  useEffect(() => {  
    // https://stackoverflow.com/questions/53090432/react-hooks-right-way-to-clear-timeouts-and-intervals

    let timer1 = setInterval(() => {
      setDateTime(new Date())
      // console.log('hi', new Date())
    }, 60000);
    
    return () => {
      // console.log(`clear time out ${timer1}`)
      clearInterval(timer1)
    }
  }, [])

  useEffect(() => {
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;      
  
      menuContext.setMetricsMenus({
        title: 'Live View',
        subtitle: name,
        currentPageId: 'metrics',
        currentTabId: 'download_liveview',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        isLiveview: res.data.live_view,
        skill_podcast_website: res.data.skill_podcast_website 
      });
  
      // establish socket connection
      // setSocket(io(process.env.REACT_APP_MS_MEDIA_ANALYZER_PROXY));
      if(!socket) {
        add({
          socket: io(process.env.REACT_APP_MS_MEDIA_ANALYZER_PROXY)
        })
      }

      Mixpanel.identify(uid);
      Mixpanel.track('Show Download Liveview loaded', {show_id: id});
    })
    .catch((err) => {      
      // console.log(err)
      setDenied(true)
    })
  }, [id])  

  useEffect(() => {
    // observating live_download data with socket.io
    if (!socket) return;
    const channelName = `live_download_${id}`;    
    if(channels.indexOf(channelName) === -1) {
      addChannelForLiveDownloads([...channels, channelName], {...livedownloads, [channelName]: []})
      // addChannel([...channels, channelName])
      // console.log(`listen to live_download_${id}`)
      socket.on(channelName, (data) => {
        handleSocketCallback(data, channelName)
      })
    }
  }, [socket])  

  if(denied) {
    return <Redirect to="/dashboard"/>
  }
  
  return (
    <>
      <div className="container-fluid">
        <LiveviewCard 
          dateTime={dateTime}
          stats={livedownloads && livedownloads[`live_download_${id}`] ? livedownloads[`live_download_${id}`] :[]}
          title={"Liveview"}
        />
      </div>
    </>
  )
}

export default ShowDownloadLiveview;