import React from "react";
import { Spinner } from 'reactstrap';
import { useAtom } from "jotai";
import PlayerContainer from './PlayerContainer'
import PlayerControls from './PlayerControls'
import InputCardLayout from './InputCardLayout'
import {playerAtom} from './playerAtom'

const ChaptersPlayer = ({item, audiopostId, type}) => {
  const [playerState] = useAtom(playerAtom);

  return (
    <div>
      <PlayerContainer player={item}/>
      {playerState.onReady ? <div><InputCardLayout audiopostId={audiopostId} type={type}><PlayerControls/></InputCardLayout></div> : <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />}      
    </div>
  )
}

export default ChaptersPlayer;