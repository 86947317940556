import React, {useEffect, useState, useContext} from "react";
import {Redirect} from 'react-router-dom'
import justcastApi from '../../api/justcast';
import {Context as ScreenContext} from '../../context/ScreenContext'
import {Context as MenuContext} from '../../context/MenuContext'
import { Button, Form, InputGroup, FormText, Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap';
import { useForm } from "react-hook-form";

export default function Sharing(props) {
  const id = props.match.params.id;
  const screenContext = useContext(ScreenContext);
  const menuContext = useContext(MenuContext);
  const [showName, setShowName] = useState('');
  const [teams, setTeams] = useState([]);
  const [denied, setDenied] = useState(false);
  const { register, getValues, setValue, reset, handleSubmit, watch, setError, clearErrors, formState: { errors } } = useForm();

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v2/shows/${id}/teams`)
    .then((res) => {
      screenContext.finishFetching();
      const { show } = res.data;
      setShowName(res.data.show.name)

      menuContext.setPodcastPageMenu({
        title: 'Share Podcast',
        subtitle: show.name,
        currentPageId: 'sharing',
        showId: id,
        landingPageUrl: show.landing_page_url,
        websiteUrl: show.player_page_link,
        rssFeed: show.rss_feed,
        passwordProtected: show.is_password_protected,
        advancedPrivate: show.is_advanced_private_feed,
        isPrivate: show.is_private,
        isPrivateShow: show.is_private_show,
        skill_podcast_website: show.skill_podcast_website
      });

      setTeams(res.data.teams)
      // console.log(res.data)
    })
    .catch((err) => {
      screenContext.finishFetching();
      // console.log(err)
      setDenied(true)
    })

  }, [])

  const addNewMember = (data) => {
    justcastApi.post(`/v2/shows/${id}/teams`, data)
    .then((res) => {
      reset()
      setTeams(prevState => {
        return [...prevState, res.data]
      } )
    })
    .catch((err) => {
      if(err.response.data.message) {
        setError('email_address', {type: '400', message: err.response.data.message})
      }
      // console.log(err)
    })
  }

  const removeMember = (teammate_id) => {
    justcastApi.delete(`/v2/shows/${id}/teams/${teammate_id}`)
    .then((res) => {
      const deleted_team_id = res.data.id;
      setTeams(prevState => {
        return [...prevState.filter((team) => team.id !== deleted_team_id)]
      })
    })
    .catch((err) => {
      console.log(err);
    })
  }

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <div className="container-fluid">
      <p className="text-muted mb-4">Only the owner of the podcast can access this page.</p>
      <Card>
        <CardHeader>
          <h4 className="card-header-title">New member</h4>
        </CardHeader>
        <CardBody>
          <p>Sharing a Podcast with other JustCast users allows them to view, and modify it.</p>
          <Form onSubmit={handleSubmit(addNewMember)}>
            <InputGroup>
              <input
                type="text"
                placeholder="nathan@abc.com"
                className="form-control"
                {...register("email_address", {required: true})}
              />
              <Button color="primary">Share</Button>
            </InputGroup>
            {errors.email_address && <FormText color="danger">{errors.email_address.message}</FormText>}
          </Form>
        </CardBody>
      </Card>
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">Team</h4>
        </div>
        <div className="card-body">
          <ul className="list-group list-group-flush list my-n3">
            {teams.map((team) => {
              return (
                <li className="list-group-item" key={team.id}>
                  <div className="row align-items-center">
                    <div className="col">
                      <h4 className="mb-1 email">{team.teammate_email_address}</h4>
                    </div>
                    <div className="col-auto">
                      <Button size="sm" color="danger" onClick={() => {
                        removeMember(team.id)
                      }}>Remove</Button>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
