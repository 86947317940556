import React, {useEffect, useState, useContext} from "react";
import {Redirect} from 'react-router-dom'
import justcastApi from '../api/justcast';
import {Context as AuthContext} from '../context/AuthContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import { Alert, Button, Card, CardHeader, CardBody, Modal, ModalBody, ModalHeader} from 'reactstrap';
import SenderAuthForm from '../components/sendgrid-senders/SenderAuthForm'
import SuccessMessage from '../components/sendgrid-senders/SuccessMessage'

export default function SenderAuthentications() {
  const menuContext = useContext(MenuContext);
  const [modal, setModal] = useState(false);
  const [senderAuthentications, setSenderAuthentications] = useState([]);
  const [formErrors, setFormErrors] = useState(null);
  const [success, setSuccess] = useState(false);
  const [successEmail, setSuccessEmail] = useState(null);

  useEffect(() => {
    justcastApi.get(`/v2/sender_domains`)
    .then((res) => {
      menuContext.setMainPageLeftMenu({title: 'Sender Authentications', subtitle: "Overview", currentPageId: 'sender_authentications'});
      setSenderAuthentications(res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])

  const handleDelete = (id) => {
    justcastApi.delete(`/v2/sender_domains/${id}`)
    .then((res) => {
      const newData = senderAuthentications.filter((x) => x.id !== res.data.id);
      if(newData.length == 0) {
        setSenderAuthentications([]);
      } else {
        setSenderAuthentications(newData)
      }
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const toggle = () => {
    setModal(!modal)
    setSuccess(false);
    setSuccessEmail(null)
  }

  const handleCreate = (data) => {
    const payload = {...data};
    payload['country'] = data['country']['value'];
    justcastApi.post(`/v2/sender_domains`, payload)
    .then((res) => {
      setFormErrors(null)
      setSuccess(true);
      setSenderAuthentications([res.data, ...senderAuthentications])
      setSuccessEmail(res.data.from_email);
    })
    .catch((err) => {
      const errors = {}
      const data = err.response?.data;
      for(let i = 0; i < data.length; i++) {
        const {field, message} = data[i]
        errors[field] = message;
      }
      setFormErrors(errors)
      setSuccessEmail(null)
    })
  }

  return (
    <div className="container-fluid">
      <Modal isOpen={modal} toggle={toggle} backdrop={false}>
        <ModalHeader toggle={toggle}>
          Create a Sender
        </ModalHeader>
        <ModalBody>
          {
            success ? <SuccessMessage email={successEmail} toggle={toggle}/> : <>
              <p className="text-muted">You are required to include your contact information, including a physical mailing address, inside every promotional email you send in order to comply with the anti-spam laws such as CAN-SPAM and CASL.</p>
              <SenderAuthForm handleCreate={handleCreate} formErrors={formErrors} toggle={toggle}/>
            </>
          }
        </ModalBody>
      </Modal>
      <Card>
        <CardHeader>Single Sender Verification</CardHeader>
        <CardBody>
          <p>Provide your own email address to use as a sender</p>
          <Button size="sm" color="primary" onClick={toggle}>Add a new Sender Email</Button>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Sender Verifications</CardHeader>
        <CardBody>
          <p className="text-muted">
            If your request has been pending for more than 24 hours, please ensure that you have forwarded the email from Sendgrid to justcastapp@gmail.com.
          </p>
        </CardBody>
        <div className="table-responsive">
          <table className="table table-sm table-nowrap card-table">
            <thead>
              <tr>
                <th>Email address</th>
                <th className="text-center">Verified</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody className="font-size-base">
              {
                senderAuthentications.map((sender) => (
                  <tr key={sender.id}>
                    <td>{sender.from_email}</td>
                    <td className="text-center">
                      {sender.verified ? <span className="badge bg-primary">Verified</span> : <span className="badge bg-danger">Pending</span>}
                    </td>
                    <td className="text-center">
                      <Button size="sm" onClick={() => {
                        handleDelete(sender.id)
                      }}>Delete</Button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  )
}
