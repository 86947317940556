import React, {useEffect, useState, useContext} from "react";
import {Redirect} from 'react-router-dom'
import justcastApi from '../../api/justcast';
import {Context as AuthContext} from '../../context/AuthContext'
import {Context as ScreenContext} from '../../context/ScreenContext'
import {Context as MenuContext} from '../../context/MenuContext'
import { Spinner } from 'reactstrap';
import {Tree} from '../../components/sub-show-explorer'

const DropboxFolders = (props) => {
  const screenContext = useContext(ScreenContext);
  const menuContext = useContext(MenuContext);
  const authContext = useContext(AuthContext);
  const [treeData, setTreeData] = useState([])

  useEffect(() => {
    menuContext.setMainPageLeftMenu({title: 'Dropbox Folders', subtitle: "Overview", currentPageId: 'home'});
    justcastApi.get('/v2/dropbox_folders')
    .then((res) => {
      setTreeData(res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <div className="container-fluid">
      <Tree treeData={treeData}/>
    </div>
  )
}

export default DropboxFolders;