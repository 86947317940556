import React from 'react'
import ShopifyPricing from './ShopifyPricing';
import { ListGroup, Card, CardBody } from 'reactstrap';

export default function ShopifyPricingTable({
  plans,
  activePlanName,
  subscribeClick
}) {
  if(plans) {
    return (
      <Card>
        <CardBody>
          <ListGroup className="list-group-flush my-n3">
            {
              plans.map((plan, idx) =>
                <ShopifyPricing
                  name={plan.name}
                  price={plan.price}
                  features={plan.features}
                  key={idx}
                  subscribeClick={subscribeClick}
                  activePlanName={activePlanName}
                />)
            }
          </ListGroup>
        </CardBody>
      </Card>
    )
  }

  return null;
}
