export const updateSelectedPeople = ({
  hosts,
  people
}) => {
  const dicts = {}
  for(let i = 0; i < hosts.length; i++) {
    const host = hosts[i];
    const hostPersonId = host.person_id;
    dicts[hostPersonId] = hostPersonId;
  }
  const _people = [...people];
  for(let j = 0; j < _people.length; j++) {
    const personId = _people[j]['id'];
    if(dicts[personId] === undefined) {
      _people[j]['selected'] = false;
    } else {
      _people[j]['selected'] = true;
    }
  }
  return _people;
}