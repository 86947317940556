import React, {useContext, useState, useEffect} from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Card, CardText, CardBody, CardTitle, Button, Spinner, Row, Col,
  Form, InputGroup, InputGroupAddon, Input, CardHeader,
  FormGroup, Label, Modal, ModalBody, ModalHeader
} from 'reactstrap';
import { CSVLink } from "react-csv";
import justcastApi from '../api/justcast';
import FilePreviewModal from '../components/FilePreviewModal';
import PrivateLinkInviteModal from '../components/PrivateLinkInviteModal';
import SendMemberEmailsModal from '../components/SendMemberEmailsModal';
import UpdateNotification from '../components/UpdateNotification';
import PrviatePodcastSubscribersTable from '../components/PrviatePodcastSubscribersTable'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as MenuContext} from '../context/MenuContext'

const PrivatePodcastScreen = (props) => {
  const showId = props.match.params.show_id;

  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const {addWithTimeout} = useContext(AlertContext);

  const [showName, setShowName] = useState('');
  const [show, setShow] = useState({});
  const [subscribers, setSubscribers] = useState([]);
  const [denied, setDenied] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [fullName, setFullName] = useState("")
  const [inviteLink, setInviteLink] = useState('');

  const [csvModal, setCsvModal] = useState(false);
  const [inviteLinkModal, setInviteLinkModal] = useState(false);
  const [sendAllEmailsModal, setSendAllEmailModals] = useState(false);

  const [statusTabs, setStatusTabs] = useState([
    {name: "All", params: ""},
    {name: "Approved", params: "?subscription_status=Approved"},
    {name: "Pending", params: "?subscription_status=Pending"},
    {name: "Reject", params: "?subscription_status=Reject"}
  ]);

  const [activeStatus, setActiveStatus] = useState("All");

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${showId}/private_feeds`)
    .then((res) => {
      const private_feeds = res.data.private_feeds.map((private_feed) => {
        const {id, email_address, full_name, slug, subscription_status, is_activated, client_token, rss_feed, web_player_url, created_at, updated_at , show_id, instructions_url} = private_feed;
        return {id, email_address, full_name, slug, subscription_status, is_activated, client_token, rss_feed, web_player_url, created_at, updated_at , show_id, instructions_url}
      })
      setSubscribers(private_feeds);
      setShow(res.data.show);
      setInviteLink(res.data.show.private_page_invite_link);
      setShowName(res.data.show.name);
      screenContext.finishFetching();
      if(res.data.show.is_advanced_private_feed !== true) {
        setDenied(true)
      }
      menuContext.setPrivateSubscribersrMenu({
        title: 'Subscribers',
        subtitle: res.data.show.name,
        currentPageId: 'subscribers',
        currentTabId: 'subscribers',
        showId: showId,
        landingPageUrl: res.data.show.landing_page_url,
        websiteUrl: res.data.show.player_page_link,
        rssFeed: res.data.show.rss_feed,
        passwordProtected: res.data.show.is_password_protected,
        advancedPrivate: res.data.show.is_advanced_private_feed,
        isPrivate: res.data.show.is_private,
        isPrivateShow: res.data.show.is_private_show
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true)
    })
  }, [showId])

  const addSubscriber = () => {
    if(emailAddress.length > 6) {
      justcastApi.post(`/v1/shows/${showId}/private_feeds`, {
        email_address: emailAddress,
        full_name: fullName
      })
      .then((res) => {
        setSubscribers([res.data, ...subscribers]);
        setEmailAddress("");
        setFullName("")
      })
      .catch((err) => {
        addWithTimeout({color: 'warning', message: err.response.data.error, isOpen: true, timeout: 3000})
      })
    } else {
      addWithTimeout({color: 'warning', message: `Please make sure ${emailAddress} is a validated email address.`, isOpen: true, timeout: 3000})
    }
  }

  const handleSetSubscriberSlug = (id) => {
    justcastApi.get(`/v1/shows/${showId}/subscriber_new_slug/${id}`)
    .then((res) => {
      const message = res.data.message;
      addWithTimeout({color: 'warning', message: message, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handleDeleteSubscriber = (id) => {
    justcastApi.delete(`/v1/shows/${showId}/private_feeds/${id}`)
    .then((res) => {
      const _subscribers = subscribers.filter(subscriber => subscriber.id !== res.data.id)
      setSubscribers(_subscribers);
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handleResendSubscriber = (id) => {
    justcastApi.get(`/v1/shows/${showId}/subscriber_resend/${id}`)
    .then((res) => {
      const message = res.data.message;
      addWithTimeout({color: 'warning', message: message, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const addMultipleEmails = (emails) => {
    justcastApi.post(`/v1/shows/${showId}/import_multiple_emails`, {
      email_addresses: emails
    })
    .then((res) => {
      const newSubscribers = res.data;
      const count = newSubscribers ? newSubscribers.length : 0;
      addWithTimeout({color: 'warning', message: `Added ${count} new subscribers`, isOpen: true, timeout: 3000})
      setSubscribers([...newSubscribers, ...subscribers]);
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const getSubscriberInviteLink = (id) => {
    const subscriber = subscribers.filter((subscriber) => subscriber.id === id)
    if(subscriber.length === 1) {
      console.log(subscriber)
      window.open(subscriber[0].instructions_url)
    }
  }

  const handleUpdateStatus = (index, id, subscription_status) => {
    // console.log({
    //   index, id, subscription_status
    // })
    justcastApi.put(`/v1/shows/${showId}/private_feeds/${id}`, {subscription_status})
    .then((res) => {
      // subscribers, setSubscribers
      const _subscribers = [...subscribers];
      _subscribers[index] = res.data;
      setSubscribers(_subscribers);
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const handleStatusTabClick = (tab) => {
    const {name, params} = tab;
    justcastApi.get(`/v1/shows/${showId}/private_feeds${params}`)
    .then((res) => {
      setSubscribers(res.data.private_feeds);
    })
    .catch((err) => {
      console.log(err);
    })
    setActiveStatus(name);
  }

  const handleSendManyEmails = () => {
    justcastApi.post(`/v2/shows/${showId}/send_email_to_members`, {})
    .then((res) => {
      setSendAllEmailModals(false)
      const message = res.data.message;
      addWithTimeout({color: 'warning', message, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err);
      setSendAllEmailModals(false)
    })
  }

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <>
      <div className="container-fluid">
        <FilePreviewModal
          modalOn={csvModal}
          toggleModal={setCsvModal}
          saveEmails={addMultipleEmails}
        />
        <PrivateLinkInviteModal
          modalOn={inviteLinkModal}
          toggleModal={setInviteLinkModal}
          link={inviteLink}
        />
        <SendMemberEmailsModal
          modalOn={sendAllEmailsModal}
          toggleModal={setSendAllEmailModals}
          handleSave={handleSendManyEmails}
        />
        {
          screenContext.state.loading ? <Spinner color="primary" /> : <>
            <div className="row">
              <div className="col-12">
                <Card>
                  <CardHeader>
                    <div className="row align-items-center">
                      <div className="col">
                        <h2 className="card-header-title">Subscriber</h2>
                      </div>
                      <div className="col-auto">
                        <div className="btn btn-outline-warning"
                          onClick={() => setCsvModal(true)}
                        >
                          Add multiple subscribers
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="btn btn-outline-success"
                          onClick={() => setInviteLinkModal(true)}
                        >
                          Share Invite Link
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={(event) => {
                      event.preventDefault()
                      addSubscriber()
                    }}>
                      <Row form>
                        <Col md={6}>
                            <FormGroup>
                            <Label for="emailAddress" hidden>Email</Label>
                            <Input
                              type="email"
                              name="email"
                              id="emailAddress"
                              placeholder="newsubscriber@example.com"
                              value={emailAddress}
                              onChange={(e) => {
                                setEmailAddress(e.target.value)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <InputGroup>
                            <Label for="fullName" hidden>Full name</Label>
                            <Input
                              type="fullName"
                              name="fullName"
                              id="fullName"
                              placeholder="Full Name"
                              value={fullName}
                              onChange={(e) => {
                                setFullName(e.target.value)
                              }}
                            />
                            <InputGroupAddon addonType="append">
                              <Button color="primary" onClick={addSubscriber}>
                                Add Subscriber
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Card>
                  <CardBody>
                    <div className="header">
                      <div className="header-body">
                        <div className="row align-items-end">
                          <div className="col">
                            <h6 className="header-pretitle text-secondary">subscribers</h6>
                            <div style={{display: 'flex', gap: "10px", alignContent: 'center'}}>
                              <h1 className="header-title text-white">{activeStatus}</h1>
                              <CSVLink
                                data={subscribers}
                                filename={`${showName}-members.csv`}
                                className="btn btn-primary btn-sm"
                                target="_blank"
                              >
                                Export to CSV
                              </CSVLink>
                              <Button
                                color="danger"
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  setSendAllEmailModals(true)
                                }}
                              >
                                Send To all members
                              </Button>
                            </div>
                          </div>
                          <div className="col-auto">
                            <ul className="nav nav-tabs header-tabs">
                              {
                                statusTabs.map((tab, idx) => {
                                  return (
                                    <li className="nav-item" key={tab.name} style={{cursor: 'pointer'}}>
                                      <div className={`nav-link text-center ${tab.name === activeStatus ? "active" : ""}`} onClick={() => {
                                        handleStatusTabClick(tab);
                                      }}>
                                        {tab.name}
                                      </div>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="header-footer">
                        <div className="table-responsive">
                          <PrviatePodcastSubscribersTable
                            items={subscribers}
                            handleReset={handleSetSubscriberSlug}
                            handleDelete={handleDeleteSubscriber}
                            handleResend={handleResendSubscriber}
                            handleUpdateStatus={handleUpdateStatus}
                            handleAccessLink={getSubscriberInviteLink}
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default PrivatePodcastScreen;