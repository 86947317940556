import React, {useState, useEffect} from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import justcastApi from '../../api/justcast'
import AsyncStorage from '../../libs/AsyncStorage'

export default function AddShopifyModal() {

  const [message, setMessage] = useState("Please take a moment to confirm if you'd like to integrate JustCast with your Shopify store.")
  const [isOpen, setOpen] = useState(false);
  const [clickedInstall, setClickedInstall] = useState(false);

  useEffect(() => {
    const shopify_connection_public_id = AsyncStorage.getItem('shopify_connection_public_id')
    if(shopify_connection_public_id) {
      setOpen(true);
    }
  }, [])

  const closeModal = () => {
    setOpen(false)
  }

  const add = () => {
    const shopify_connection_public_id = AsyncStorage.getItem('shopify_connection_public_id')
    if(shopify_connection_public_id) {
      setClickedInstall(true)
      justcastApi.post('/v2/shopify_connection_users', {
        public_id: shopify_connection_public_id
      }).then((res) => {
        setMessage(res.data.message)
      })
      .catch((err) => {
        setMessage(err.response.data)
      })
      .finally(() => {
        AsyncStorage.removeShopifyPublicId();
      })
    }
  }


  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={closeModal}>
        <strong>Shopify App Installation confirmation</strong>
      </ModalHeader>
      <ModalBody>
        {message}
      </ModalBody>
      <ModalFooter>
        {clickedInstall ? <Button color="secondary" onClick={closeModal}>Close</Button> :
        <>
          <Button color="secondary" onClick={closeModal}>Cancel</Button>
          <Button color="primary" onClick={add}>Install</Button>
        </>
        }
      </ModalFooter>
    </Modal>
  )
}
