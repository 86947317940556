import React  from "react";

const ErrorMessagesList = ({errors}) => {
  if(errors && errors.length > 0) {
    return (
      <div>
        <h3 className="text-danger">Errors:</h3> 
        <ul>
          {
            errors.map((str, i) => 
              <li className="text-danger" key={`error-${i}`}>{str}</li>
            )
          }
        </ul>
      </div>
    )
  }

  return null;
}

export default ErrorMessagesList;