import React, {useEffect, useState, useContext, useRef} from "react";
// import socketIOClient from "socket.io-client";
import { Row, Col, Card, CardBody, CardHeader, Button, ButtonGroup } from 'reactstrap';
// const Promise = require('bluebird');
import Promise from 'bluebird'
import justcastApi from '../api/justcast';
import {uploadImageHandler} from '../api/doUploader'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as PodieoAudioContext} from '../context/PodieoAudioContext'
import {Context as SocketContext} from '../context/SocketContext'
import PodieoAudio from '../components/Podieos/PodieoAudio'
import LeftSidePanel from '../components/Podieos/LeftSidePanel'
import SvgDemoArea from '../components/Podieos/SvgDemoArea'
import {Mixpanel} from '../api/mixpanel'

const EpisodePodieosCreate = (props) => {
  const svgDemoRef = useRef(null);
  const authContext = useContext(AuthContext);
  const uid = authContext.state.uid;
  // const socketContext = useContext(SocketContext)
  const userState = authContext.state;
  const {setEpisodePageMenu} = useContext(MenuContext);

  const {state, add, updateState, updateSvgState} = useContext(PodieoAudioContext);
  // const socketState = socketContext.state;
  // const addSocket = socketContext.add;

  const id = props.match.params.id;

  const [showId, setShowId] = useState('');
  const [showName, setShowName] = useState('');
  const [episodeName, setEpisodeName] = useState('');
  const [openningSocket, setOpenningSocket] = useState(false);
  // const [audioURL, setAudioURL] = useState('');

  useEffect(() => {
    console.log('openningSocket changed...', openningSocket)
  }, [openningSocket])

  useEffect(() => {
    justcastApi.get(`/v1/audioposts/${id}/podieos`)
    .then((res) => {
      const data = res.data;
      setShowName(data.show.name);
      setShowId(data.show.id);
      setEpisodeName(data.audiopost.name);

      Mixpanel.identify(uid);
      Mixpanel.track('Episode audiogram create page loaded', {show_id: data.show.id, audiopost_id: data.audiopost.id});

      if(state.url === "" || state.url !== data.audiopost.audio_url) {
        add(data.audiopost.audio_url)
      }

      setEpisodePageMenu({
        title: "New Audiogram",
        currentPageId: "episodes",
        currentTabId: "podieos",
        subtitle: `${data.show.podcast_title} > ${data.audiopost.episode_title}`,
        showName: data.show.podcast_title,
        showId: data.show.id,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow:data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website
      })

      return data.audiopost.name;
      // setArtworkUrl(data.show.artwork_url);
      // setAuthor(data.show.author);
      // setAudioURL(data.audiopost.audio_url);

    })
    .then((audiopost_name) => {
      return justcastApi.get('/v1/video_specs')
      .then((res) => {
        const options = res.data;
        // set square as the default
        const canvasId = options[2]['id'];
        const canvasName = options[2]['name'];
        const canvasCode = options[2]['code'];
        const canvasWidth = options[2]['width'];
        const canvasHeight = options[2]['height'];
        const _options = options.map((option) => {
          return {...option, value: option.id, label: option.name}
        })

        const texts = [{id: Math.floor(Math.random() * Math.floor(10000)).toString() + "|" + Date.now().toString(), name: audiopost_name, fontSize: "24", color: "#fff", x: 0, y:40, maxLineLength: audiopost_name.length, width: 0, height: 24, textAnchor: "start", textAnchorAdjustVal: 0, aligIcon: 'AlignLeft', fontWeight: "normal", fontStyle: "normal", fontFamily: "Roboto"}]

        const demoDivWidth = svgDemoRef.current.clientWidth;
        const demoDivHeight = svgDemoRef.current.clientHeight;

        updateSvgState({demoDivWidth, demoDivHeight, canvasWidth, canvasHeight})
        updateState({texts, canvasId, canvasName, canvasCode, canvasWidth, canvasHeight, videoSpecs: _options})
      })
    })
    .catch((err) => {
      console.log(err)
    })
  },[id])

  const handleButtonClicked = (button) => {
    const buttonSelectedId = button.id;
    const buttonSelectedTitle = button.title;
    const selectingObject = {type: null, id: null};
    updateState({buttonSelectedId, buttonSelectedTitle, selectingObject})
  }

  const handleCreateButtonClick = () => {
    const prepareImages = state.images;
    if(state.showBadge) {
      prepareImages.push(state.justcastBadge);
    }

    const data = {
      url: state.url,
      clipFromSeconds: state.clipFromSeconds,
      clipEndSeconds: state.clipEndSeconds,
      demoSvgRatio: state.demoSvgRatio,
      canvasWidth: state.canvasWidth,
      canvasHeight: state.canvasHeight,
      backgroundColor: state.backgroundColor,
      images: prepareImages,
      texts: state.texts,
      soundWaveAdded: state.soundWaveAdded,
      soundWave: state.soundWave,
      progressBarAdded: state.progressBarAdded,
      progressBar: state.progressBar
    }

    // upload local images to d.o.
    const images = data.images;
    const uploadImgs = [];
    const uploadIndex = [];
    images.forEach((image, i) => {
      if(image.file) {
        const file = image.file;
        uploadIndex.push(i);
        uploadImgs.push(file);
      }
    })

    Promise.mapSeries(uploadImgs, (file, index) => {
      return uploadImageHandler(file)
      .then((fileContents) => {
        return fileContents.data.path
      })
    })
    .then((remotePaths) => {
      for(let i = 0; i < uploadIndex.length; i++) {
        const id = uploadIndex[i];
        images[id]['artwork'] = remotePaths[i];
      }
      return justcastApi.post(`/v1/audioposts/${id}/generate_audiogram`, data)
    })
    .then((res) => {
      // const podieoId = res.data.id;
      window.location.href = `/episodes/${id}/podieos`;

    })
    .catch((err) => {
      console.log(err)
    })
  }

  return (
    <>
      <div className="container-fluid">
        <Card>
          <CardBody>
            <ButtonGroup>
              {
                state.buttons.map((button) =>
                  <Button
                    color="warning"
                    key={button.id}
                    outline={button.id !== state.buttonSelectedId}
                    onClick={() => {
                      handleButtonClicked(button)
                    }}
                  >
                    <span className={button.iconClass}></span> {button.title}
                  </Button>
                )
              }
            </ButtonGroup>
            <Button
              color="primary"
              className="float-right"
              onClick={handleCreateButtonClick}
            >
              Create
            </Button>
          </CardBody>
        </Card>
        <Row>
          <Col xs="4">
            <LeftSidePanel/>
          </Col>
          <Col xs="8">
            <Card>
              <div className="card-body" ref={svgDemoRef} style={{"minHeight": "460px"}}>
                <SvgDemoArea/>
              </div>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <PodieoAudio/>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default EpisodePodieosCreate