import React, {useEffect, useState, useContext} from "react";
import { Link, Redirect } from "react-router-dom";
import { Card, CardBody, Button, CardFooter } from 'reactstrap';
import justcastApi from '../api/justcast';
import UpdateNotification from '../components/UpdateNotification';
import ShowSettingsForm from '../components/ShowSettingsForm';
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AuthContext} from '../context/AuthContext'
import { Spinner } from 'reactstrap';
import {TipjarSettingCard} from '../components/Tipjar'

const ShowTipjar = (props) => {
  const id = props.match.params.id;
  // const {addWithTimeout, add} = useContext(AlertContext);
  const {add} = useContext(ModalContext);
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const authContext = useContext(AuthContext)
  const [showName, setShowName] = useState('');
  const [item, setItem] = useState({});
  const [tmpPrice, setTmpPrice] = useState({});
  const [isAdvancedPrivatePodcast, setIsAdvancedPrivatePodcast] = useState(false);
  const [denied, setDenied] = useState(false);
  const [enabledTipJar, setEnabledTipJar] = useState(false);
  const [tipJarId, setTipJarId] = useState(null);
  const [currencyCodes, setCurrencyCodes] = useState([]);
  const [prices, setPrices] = useState([]);
  const [priceTypes, setPriceTypes] = useState([]);

  const {skill_stripe_tip_jar} = authContext.state
  // const [currencyCode, setCurrencyCode] = useState(null);

  const setupStripeConnectAccount = () => {
    justcastApi.post(`/v1/stripe_accounts`, {
      return_url: `${process.env.REACT_APP_STRIPE_CONNECT_ACCOUNT_RETURN_URL}/tip_jar/${id}`,
      refresh_url: `${process.env.REACT_APP_STRIPE_CONNECT_ACCOUNT_REFRESH_URL}/tip_jar/${id}`,
    })
    .then((res) => {
      const url = res.data.url;
      if(url) {
        window.location = url;
      }
    })
    .catch((err) => {
      add({color: 'danger', message: `Error to get link to connect to Stripe. Please screenshot your screen and send it to us on justcastapp@gmail.com.`, isOpen: true})
    })
  }

  const handleSave = ({tipjar}) => {
    justcastApi.post(`/v1/shows/${id}/tip_jar`, {
      tip_jar_status: tipjar,
      prices,
    })
    .then((res) => {      
      setEnabledTipJar(res.data.active);      
      const seller_product_prices = res.data.seller_product_prices.map((seller_product_price) => {
        const unit_amount = seller_product_price['unit_amount'];
        const unit_amount_text = unit_amount ? (Math.round(unit_amount) / 100).toFixed(2) : "0.00";
        return {...seller_product_price, unit_amount_text }
      })      
      setPrices(seller_product_prices);
      setTipJarId(res.data.id);
      // add alert
      // addWithTimeout()
      add({color: 'warning', message: `Tip jar info has been updated!`, isOpen: true})
    })
    .catch((err) => {
      // console.log(err)
      console.log(err.message)
    })
  }

  const handlePriceChanged = (idx, price) => {   
    const tmpPrices = [...prices];
    const tmpPrice = {...tmpPrices[idx]};
    const tmp = {...tmpPrice, ...price};
    // console.log(tmpPrice)
    tmpPrices[idx] = tmp;
    setPrices(tmpPrices)
  }

  const handleRemovePrice = (idx) => {
    const tmpPrices = [...prices].filter((_, id) => id !== idx);
    // console.log(tmpPrices.length);
    setPrices(tmpPrices);
  }

  const handleAddNewPrice = () => {
    // get max prices order_id
    const position_order = Math.max(...(prices.map((x) => x.position_order))) + 1;    
    const price = {...tmpPrice, position_order}
    const tmpPrices = [...prices, price];
    setPrices(tmpPrices);
  }

  const handleSorting = (result) => {
    const items = Array.from(prices);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newPrices = items.map((price, position_order_idx) => {
      return {...price, position_order: position_order_idx + 1}
    })
    setPrices(newPrices);
  }
  
  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}/tip_jar_prices`)
    .then((res) => {
      const show = res.data.show;
      const name = show.podcast_title;      
      setShowName(name);
      
      setEnabledTipJar(show.enabled_tip_jar);
      setIsAdvancedPrivatePodcast(show.is_advanced_private_feed)
      setItem(show);
      if(show.tip_jar) {        
        setTipJarId(show.tip_jar.id)
      }      

      setPriceTypes(res.data.price_types);
      const _prices = res.data.prices.map((price) => {
        const unit_amount = price['unit_amount'];
        const unit_amount_text = unit_amount ? (Math.round(unit_amount) / 100).toFixed(2) : "0.00";
        return {...price, unit_amount_text }
      })

      setPrices(_prices);
      const tmp_price = res.data.tmp_price
      const unit_amount = tmp_price['unit_amount'];
      const unit_amount_text = unit_amount ? (Math.round(unit_amount) / 100).toFixed(2) : "0.00";

      setTmpPrice({...tmp_price, unit_amount_text});

      screenContext.finishFetching();

      menuContext.setTipjarMenu({
        title: 'Tip Jar',
        subtitle: name,
        currentPageId: 'tip_jar',
        currentTabId: 'tip_jar',
        showId: id,
        landingPageUrl: show.landing_page_url,
        websiteUrl: show.player_page_link,
        rssFeed: show.rss_feed,
        passwordProtected: show.is_password_protected,
        advancedPrivate: show.is_advanced_private_feed,
        isPrivate: show.is_private,
        isPrivateShow: show.is_private_show,
        skill_podcast_website: show.skill_podcast_website       
      });      
    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
      setDenied(true)
    })
  },[id])

  useEffect(() => {
    justcastApi.get('/v1/product_currency_codes')
    .then((res) => {
      setCurrencyCodes(res.data)      
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])  

  if(denied) {
    return <Redirect to="/dashboard"/>
  }
  
  if(skill_stripe_tip_jar) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">            
            <TipjarSettingCard          
              currencyCodes={currencyCodes}
              handleSave={handleSave}
              enabledTipJar={enabledTipJar} 
              details_submitted={authContext.state.stripe_connect_details_submitted}
              setupCallback={setupStripeConnectAccount}
              tipJarId={tipJarId}
              prices={prices}
              priceTypes={priceTypes}
              handleAddNewPrice={handleAddNewPrice}
              handlePriceChanged={handlePriceChanged}
              handleRemove={handleRemovePrice}
              handleSorting={handleSorting}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Card>
            <CardBody className="text-center">
              <h2>Tip jar</h2>
              <p>Ability to use the Tip Jar, which lets you collect tips from your listeners</p>
              <p className="text-warning">This feature is still under testing; please send us an email if you are interested in joining our beta program.</p>
              <a className="btn btn-primary" href="mailto:justcastapp@gmail.com?subject=Join%20Tip%20Jar%20Beta">              
                <i className="fe fe-mail"/> Email us
              </a>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ShowTipjar;