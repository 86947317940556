import React, {useState } from "react";
import {   
  Modal, ModalBody, ModalFooter, ModalHeader, Table,
  Button, Input, InputGroup, InputGroupAddon
} from 'reactstrap';

const PrivateLinkInviteModal = ({modalOn, toggleModal, link}) => {
  const cancel = () => {
    toggleModal(!modalOn)
  }

  const [copied, setCopied] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy');

  const handleCopyClicked = () => {
    const copyText = document.querySelector(`#input-text-link`);
    copyText.select();
    document.execCommand("Copy");
    setCopyButtonLabel('Copied');
    setCopied(true);
    // change back to Copy in 3 seconds
    setTimeout(() => {
      setCopyButtonLabel("Copy");
      setCopied(false);
    }, 3000)    
  }  

  return (
    <Modal isOpen={modalOn} fade={false} toggle={cancel}>
      <ModalHeader toggle={cancel}>Share Invite Link</ModalHeader>
      <ModalBody>
        <p>
          Share this link with anyone you have added to your private podcast as a subscriber.
          They'll simply need to enter their email address to gain access.
        </p>

        <InputGroup>  
          <Input valid={copied} readOnly value={link} id="input-text-link" />
          <InputGroupAddon addonType="prepend">                                
            <Button color="secondary" onClick={handleCopyClicked}>{copyButtonLabel}</Button>
          </InputGroupAddon>          
        </InputGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={cancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default PrivateLinkInviteModal;