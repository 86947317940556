import React, {useState, useContext}  from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.svg'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import {Context as MenuContext} from '../../context/MenuContext'
import LeftSideMenuItem from './LeftSideMenuItem'

const LeftSideMenu = () => {
  const [navStatus, setNavStatus] = useState(false);
  const {state} = useContext(MenuContext);
  const {leftSideItems, currentPageId} = state;
  
  const handleClick = () => {
    setNavStatus(!navStatus);
  }

  return (
    <Navbar className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
      <div className="container-fluid">
        <NavbarToggler onClick={handleClick} />
        <NavbarBrand to="/dashboard" tag={Link}>
          <img src={logo} className="navbar-brand-img mx-auto size-2x"/>
        </NavbarBrand>
        <Collapse isOpen={navStatus} navbar>
          <Nav className="navbar-nav mb-md-4" navbar>
            {
              leftSideItems.map((navItem) => 
                <LeftSideMenuItem 
                  name={navItem.name} 
                  link={navItem.link} 
                  target={navItem.target} 
                  key={navItem.id}
                  active={navItem.id === currentPageId}
                />
              )
            }
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  )
}

export default LeftSideMenu;