const html = (folderName) => {
  return `<h2>How to add a new episode</h2>
  <ol>
    <li>
      Open your Dropbox app on your computer or go to <a href="https://www.dropbox.com" target="_blank">Dropbox.com</a>
    </li>
    <li>
      If you are using the dropbox website, click on the Files on the left hand side menu first.  Open the Apps folder.
    </li>
    <li>
      Open the JustCast folder
    </li>
    <li>
      Drag and drop your audio file into the folder call <strong>${folderName}</strong>
    </li>
  </ol>`
}

export default html;