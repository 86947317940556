import React, {useState} from 'react'
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import justcastApi from '../../api/justcast'
import { Redirect } from "react-router";

export default function ConnectForm({publicId}) {
  const [message, setMessage] = useState("Please take a moment to confirm if you'd like to integrate JustCast with your Shopify store.")
  const [isOpen, setOpen] = useState(true);
  const [clickedInstall, setClickedInstall] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const add = () => {
    if(publicId) {
      setClickedInstall(true)
      justcastApi.post('/v2/shopify_connection_users', {
        public_id: publicId
      }).then((res) => {
        setMessage(res.data.message)
      })
      .catch((err) => {
        setMessage(err.response.data)
      })
    }
  }


  const closeModal = () => {
    setOpen(false)
    setRedirectUrl("/dashboard")
  }

  if(isOpen) {
    return (
      <Card>
        <CardHeader>
          Shopify App Installation confirmation
        </CardHeader>
        <CardBody>
          <p>{message}</p>
          {clickedInstall ? <Button color="secondary" onClick={closeModal}>Close</Button> :
            <>
              <Button color="secondary" onClick={closeModal}>Cancel</Button>{" "}
              <Button color="primary" onClick={add}>Install</Button>
            </>
            }
        </CardBody>
      </Card>
    )
  }

  if(redirectUrl) {
    return (
      <Redirect to={redirectUrl} />
    )
  }

  return null;

}
