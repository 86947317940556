import React, {useEffect, useState, useContext, useRef} from "react";
import io from "socket.io-client";
import {Button} from 'reactstrap';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import justcastApi from '../api/justcast';
import EpisodeForm from '../components/EpisodeForm';
import {SeasonForm} from '../components/seasons'
import {EpisodePeople, PeopleListModal, NewPersonModal} from '../components/episode-people'
import {uploadFileHandler} from '../api/doDirectUpload'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as FileUploadContext} from '../context/FileUploadContext'

const Episode = (props) => {
  const quillRef = useRef(null);
  const id = props.match.params.id;
  const showId = props.match.params.show_id;
  const {addWithTimeout} = useContext(AlertContext);
  const modalContext = useContext(ModalContext);
  const {setEpisodePageMenu} = useContext(MenuContext);

  const [showName, setShowName] = useState('');
  const [episodeName, setEpisodeName] = useState('');
  const [podieoAllowTime, setPodieoAllowTime] = useState(0);
  const [audioDate, setAudioDate] = useState(null);
  const [item, setItem] = useState({});
  const [explicitTypes, setExplicitTypes] = useState([])
  const [episodeTypes, setEpisodeTypes] = useState([])
  const [supportFiles, setSupportFiles] = useState([]);
  const [show, setShow] = useState({});
  const [fileTypeGroup, setFileGroupType] = useState('');
  const [currentAudioURL, setCurrentAudioURL] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [newFileMeta, setNewFileMeta] = useState({file_size: null, support_file_type: null, duration: null})
  const [seasons, setSeasons] = useState([]);
  const [seasonId, setSeasonId] = useState(-1);
  const [isSeasonModalOpen, setSeasonModalOpen] = useState(false);
  const [newSeason, setNewSeason] = useState({
    name: "", number: "", id: null
  });
  const [errorSeasonMessages, setSeasonErrorMessages] = useState({});

  const [people, setPeople] = useState([]); // podcast level
  const [hosts, setHosts] = useState([]) // episode level
  const [personTaxonomies, setPersonTaxonomies] = useState([]);
  const [peopleModal, setPeopleModal] = useState(false);
  const [addPersonModal, setAddPersonModal] = useState(false);
  const [submitButtonLabel, setSubmitButtonLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [convertToMp3, setConvertToMp3] = useState(false);

  useEffect(() => {
    if(item?.id) {
      if(item.is_draft === true) {
        setSubmitButtonLabel('Publish')
        setSaveButtonLabel('Save')
      } else {
        setSubmitButtonLabel('Unpublish')
        setSaveButtonLabel('Save')
      }
    }
  }, [item?.is_draft])

  useEffect(() => {
    const dicts = {}
    for(let i = 0; i < hosts.length; i++) {
      const host = hosts[i];
      const hostPersonId = host.person_id;
      dicts[hostPersonId] = hostPersonId;
    }
    const _people = [...people];
    for(let j = 0; j < _people.length; j++) {
      const personId = _people[j]['id'];
      if(dicts[personId] === undefined) {
        _people[j]['selected'] = false;
      } else {
        _people[j]['selected'] = true;
      }
    }
    setPeople(_people);

  }, [people.length, hosts.length])

  const toggleHost = (person) => {
    if(hosts.filter((x) => x.person_id === person.id).length === 0) {
      setHosts([...hosts, {...person, id: null, person_id: person.id, audiopost_id: id}]);
    } else {
      const _hosts = [...hosts];
      const __hosts = _hosts.filter((host) => host.person_id !== person.id)
      setHosts(__hosts);
    }
  }

  const togglePeopleModal = () => {
    setPeopleModal(!peopleModal)
  }

  const toggleNewPersonModal = () => {
    setAddPersonModal(!addPersonModal);
  }

  // file context
  const fileUploadContext = useContext(FileUploadContext);
  const {state, add, addChannel, addDataToChannel} = fileUploadContext;
  const {socket, channels, updatingAudioposts}  = state;
  const subscribeChannelName = `direct_upload_${id}`
  const channelData = updatingAudioposts[subscribeChannelName];

  const {directUploadStatusCode, uploadedFileSize, fileSize, title, audioUrl} = channelData ? channelData : {directUploadStatusCode: null, uploadedFileSize: null, title: null, audioUrl: null};

  const handleSocketCallback = (channelName, data) => {
    // setDateTime(new Date());
    addDataToChannel(channelName, data);
  }
  // end file context

  const handleDatepickerChange = (date) => {
    const audioDate = date[0];

    if(audioDate > new Date()) {
      // console.log(formatDistance(subDays(new Date(), 3), new Date()))
      const message = "Did you want this published at a future time/date? This episode won't be released in " + formatDistance(audioDate, new Date())
      addWithTimeout({color: 'primary', message, isOpen: true, timeout: 5000})
    }
    setAudioDate(audioDate)
    //setItem({...item, 'audio_date': date[0]})
  }

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setItem({...item, [key]: value})
  }

  const handleQuillEditorChange = (value) => {
    setItem({...item, description: value})
  }

  const handleLocationChange = ({name, lat, lng}) => {
    const geo = `geo:${lat},${lng}`;
    setItem({location_name: name, location_geo: geo});
  }

  const handleInputValueChange = ({key, value}) => {
    if(key === "duration") {
      setNewFileMeta({...newFileMeta, duration: value})
    } else {
      setItem({...item, [key]: value})
    }
  }

  const handleMediaFileChange = (src, file_size, support_file_type) => {
    setNewFileMeta({...newFileMeta, file_size, support_file_type})
    if(src) {
      setItem({...item, 'audio_url': src})
    } else {
      setItem({...item, 'audio_url': currentAudioURL})
    }
  }

  const handleProgressUpload = (data) => {
    // uploadedSize: 0, totalSize: 0
    const  {uploadedSize, totalSize, audiopostId} = data;
    const channelName = `direct_upload_${audiopostId}`;

    addDataToChannel(channelName, {
      fileSize: totalSize,
      uploadedFileSize: uploadedSize,
      title: "Initialing file upload...",
      directUploadStatusCode: 202,
    });
    // setUploadingFileSize(data);
    // setModalContent({...modalContent, title: "Initialing file upload..."})
  }

  const handleSave = () => {
    handleUpdate(item);
  }

  const handleToggleDraft = () => {
    handleUpdate({...item, is_draft: !item.is_draft})
  }

  const handleUpdate = (item) => {
    const description = quillRef?.current?.unprivilegedEditor?.getHTML();
    const errorMessages = [];
    for(let i = 0; i < hosts.length; i++) {
      const host = hosts[i];
      if(host.roles === undefined || host.roles === null || (host.roles && host.roles.length === 0)) {
        errorMessages.push(`${host.name} is missing roles.`)
      }
    }

    if(errorMessages.length > 0) {
      const msgstr = errorMessages.join(', and ')

      modalContext.add({
        color: "primary",
        title: 'Error:',
        message: msgstr,
        isOpen: true,
        loading: false
      })
      return;
    }

    const newDuration = uploadFile && newFileMeta.duration ? newFileMeta.duration : item.duration;
    const newFileSize = uploadFile && newFileMeta.file_size ? newFileMeta.file_size : item.file_size;
    const newSupportFileType = uploadFile && newFileMeta.support_file_type ? newFileMeta.support_file_type : item.support_file_type;
    const newAudioURL = uploadFile ? null : currentAudioURL;
    const newAduioDate = audioDate ? audioDate : item.audio_date;

    setItem({...item, audio_url: newAudioURL, description});

    justcastApi.put(`/v1/shows/${showId}/audioposts/${id}`, {
      ...item,
      description,
      audio_url: newAudioURL,
      duration: newDuration,
      file_size: newFileSize,
      audio_date: newAduioDate,
      support_file_type: newSupportFileType,
      season_id: seasonId > 0 ? seasonId : null,
      hosts: hosts,
      client_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    .then((res) => {
      const data = res.data;
      const {season_id} = data;
      setItem(data);
      setSeasonId(season_id === null ? -1 : season_id);
      if(uploadFile) {
        setIsUploading(true);
        // establish socket connection
        if(!socket && !data.audio_url && data.direct_upload_status_code !== 400 ) {
          add({
            socket: io(process.env.REACT_APP_MS_DIRECT_UPLOAD_PROXY)
          })
        }
        return uploadFileHandler(uploadFile, showId, id, showName, item.name, handleProgressUpload, convertToMp3)
      }
      return null;
    })
    .then((_) => {
      // addWithTimeout({color: 'warning', message: `${episodeName} has been updated!`, isOpen: true, timeout: 3000})
      modalContext.add({
        color: "primary",
        title: 'Success:',
        message: `${episodeName} has been updated!`,
        isOpen: true,
        loading: false
      })
    })
    .catch((err) => {
      if(err.response && err.response.data) {
        const error = err.response.data.error;
        if(error) {
          modalContext.add({
            color: "primary",
            title: 'Error:',
            message: error,
            isOpen: true,
            loading: false
          })
        } else {
          const errMsgs = err.response.data;
          let errorMessages = '';
          for(const errMsg in errMsgs) {
            const messages = errMsgs[errMsg];
            errorMessages += messages.join(' ');
          }
          if(errorMessages.length > 0) {
            modalContext.add({
              color: "primary",
              title: 'Error:',
              message: errorMessages,
              isOpen: true,
              loading: false
            })
          }
        }
      }
      else {
        console.log(JSON.stringify(err))
        modalContext.add({
          color: "primary",
          title: 'Error:',
          message: "Error",
          isOpen: true,
          loading: false
        })
      }

    })
  }

  useEffect(() => {
    justcastApi.get(`/v1/shows/${showId}/people`)
    .then((res) => {
      setPeople(res.data.people)
      const options = res.data.person_taxonomies.map((x, idx) => {
        return {
          value: x.code, label: x.role_title, ...x
        }
      });
      setPersonTaxonomies(options)
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get(`/v1/shows/${showId}/audioposts/${id}`)
    .then((res) => {
      const data = res.data;
      if(data.support_file && data.support_file.file_type_group) {
        setFileGroupType(data.support_file.file_type_group)
      }

      if(data.hosts && data.hosts.length > 0) {
        setHosts(data.hosts)
      }
      setItem(data);
      setShowName(data.show.podcast_title);
      setEpisodeName(data.episode_title);
      setSeasonId(data.season_id ? data.season_id : -1);

      if(!data.audio_url && data.direct_upload_status_code !== 400) {
        setIsUploading(true);
      };

      if(data.audio_url) {
        setCurrentAudioURL(data.audio_url);
      }
      if(data.show.allow_podieo_max_second && data.show.allow_podieo_max_second > 0) {
        setPodieoAllowTime(data.show.allow_podieo_max_second)
      }
      setEpisodeName(data.episode_title);
      setShow(data.show);
      // setIsDirectUpload(data.show.is_direct_upload);
      // setShowNameEditable(data.show.show_name_editable)

      // establish socket connection
      // if we are still uploading
      if(!socket && !data.audio_url && data.direct_upload_status_code !== 400 ) {
        add({
          socket: io(process.env.REACT_APP_MS_DIRECT_UPLOAD_PROXY)
        })
      }

      setEpisodePageMenu({
        title: "Settings",
        subtitle: `${data.show.podcast_title} > ${data.episode_title}`,
        currentPageId: "episodes",
        currentTabId: "settings",
        showName: data.show.podcast_title,
        showId,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow:data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website
      })
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get('/v1/explicit_types')
    .then((res) => {
      setExplicitTypes(res.data)
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get('/v1/episode_types')
    .then((res) => {
      setEpisodeTypes(res.data)
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get('/v1/allow_upload_files')
    .then((res) => {
      //setSupportFiles(res.data)
      const data = res.data.map((x) => x.file_extension);
      setSupportFiles(data);
    })
    .catch((err) => {
      console.log(err)
    })

    justcastApi.get(`/v1/shows/${showId}/seasons`)
    .then((res) => {
      setSeasons(res.data);
    })
    .catch((err) => {
      console.log(err)
    })

  },[id, showId])

  useEffect(() => {
    // observating uploading progress with socket.io
    if (!socket) return;
    if(isUploading === true) {
      const channelName = subscribeChannelName;

      if(channels.indexOf(channelName) === -1) {
        addChannel([...channels, channelName], {...updatingAudioposts, [channelName]: {title: "Processing..."}})
        // addChannel([...channels, channelName])
        // console.log(`listen to live_download_${id}`)
        socket.on(channelName, (data) => {
          handleSocketCallback(channelName, data)
        })
      }
    }
  }, [socket, isUploading])

  useEffect(() => {
    if(audioUrl) {
      // reset back to init state;
      setIsUploading(false);
      setCurrentAudioURL(audioUrl)
      setNewFileMeta({file_size: null, support_file_type: null, duration: null});
      setUploadFile(null);
    }
  }, [audioUrl])

  useEffect(() => {
    if(directUploadStatusCode && uploadedFileSize) {
      if(audioUrl) {
        setItem({...item, ...{
          direct_upload_status_code: directUploadStatusCode,
          uploaded_file_size: uploadedFileSize,
          file_size: fileSize,
          audio_url: audioUrl
        }});
      } else {
        setItem({...item, ...{
          direct_upload_status_code: directUploadStatusCode,
          uploaded_file_size: uploadedFileSize,
          file_size: fileSize
        }});
      }
    }
  }, [directUploadStatusCode, uploadedFileSize, fileSize, audioUrl])

  const handleTitleChange = ({key, value}) => {
    setItem({...item, [key]: value})
    setEpisodeName(value)
  }

  const handleSeasonChange = (id) => {
    setSeasonId(id)
  }

  const handleAddSeason = () => {
    setSeasonModalOpen(true);
  }

  const handleNewSeasonModalToggle = () => {
    setSeasonModalOpen(false);
  }

  const handleInputNewSeasonChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setNewSeason({...newSeason, [name]: value});
  }

  const handleSeasonSave = () => {
    justcastApi.post(`/v1/shows/${showId}/seasons`, {
      name: newSeason.name,
      number: newSeason.number
    })
    .then((res) => {
      setSeasonId(res.data.id);
      setSeasonErrorMessages({})
      setNewSeason({name: "", number: "", id: null})
      setSeasons([res.data, ...seasons]);
      setSeasonModalOpen(false);
      addWithTimeout({color: 'primary', message: `Added a new season`, isOpen: true, timeout: 3000});
    })
    .catch((err) => {
      setSeasonErrorMessages(err.response.data)
    })
  }

  const handleDeletePerson = (idx) => {
    const _hosts = hosts.filter((_, i) => i !== idx);
    setHosts(_hosts);
  }

  const handleUpdatePersonRole = (idx, roles) => {
    // console.log({idx, roles});
    const _hosts = [...hosts];
    _hosts[idx]["roles"] = roles;
    setHosts(_hosts);
  }

  const handleSorting = (result) => {
    const items = Array.from(hosts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newPeople = items.map((person, position_order_idx) => {
      return {...person, sort_order: position_order_idx + 1}
    })
    setHosts(newPeople);
  }

  const handleAddNewPerson = ({name, email, roles, roleTitle, groupTitle}) => {
    const _person = {name, email, roles, role: roleTitle, group: groupTitle}
    setAddPersonModal(false);
    justcastApi.post(`/v1/shows/${showId}/people`, _person)
    .then((res) => {
      const person = res.data;

      modalContext.addWithMessages({
        color: "primary",
        title: 'Adding a new person',
        messages: [],
        isOpen: true,
        loading: true
      })

      setTimeout(() => {
        setHosts([...hosts, {...person, id: null, person_id: person.id, audiopost_id: id}]);
        setPeople([...people, person])
        modalContext.addWithMessages({
          color: "primary",
          title: 'Success add a new person',
          messages: [`Name: ${person.name}`, `Role: ${person.role}`],
          isOpen: true,
          loading: false
        })
      }, 900)
    })
    .catch((err) => {
      if(err.response.status === 500) {
        modalContext.add({
          color: "primary",
          title: 'Error:',
          message: "Something went wrong.  Please contact us for help.",
          isOpen: true,
          loading: false
        })
      } else if(err && err.response && err.response.data) {
        const errs = err.response.data;
        const keys = Object.keys(errs);

        const messages = keys.map((key) => `${key} ${errs[key].join(',')}`)

        modalContext.addWithMessages({
          color: "primary",
          title: 'Error:',
          messages: messages,
          isOpen: true,
          loading: false
        })

      } else {
        console.log(err);

        modalContext.add({
          color: "primary",
          title: 'Error:',
          message: "Something went wrong.  Please contact us for help.",
          isOpen: true,
          loading: false
        })
      }
    })
  }

  return (
    <>
      <PeopleListModal
        people={people}
        modal={peopleModal}
        toggleHost={toggleHost}
        toggle={togglePeopleModal}
      />
      <NewPersonModal
        addHost={handleAddNewPerson}
        modal={addPersonModal}
        toggle={toggleNewPersonModal}
        personTaxonomies={personTaxonomies}
      />
      <div className="container-fluid">
        <SeasonForm
          title={"Add a new season"}
          isOpen={isSeasonModalOpen}
          toggle={handleNewSeasonModalToggle}
          data={newSeason}
          errorMessages={errorSeasonMessages}
          onChange={handleInputNewSeasonChange}
        >
          <Button color="primary" onClick={handleSeasonSave}>Save</Button>
        </SeasonForm>
        <div className="row">
          <div className="col-12">
            <EpisodeForm
              quillRef={quillRef}
              handleTitleChange={handleTitleChange}
              backLink={`/shows/${showId}/episodes`}
              handleUpdate={handleSave}
              handleToggleDraft={handleToggleDraft}
              isDirectUpload={show.is_direct_upload}
              showNameEditable={show.show_name_editable}
              name={episodeName}
              audio_date={item.audio_date}
              created_at={item.created_at}
              description={item.description}
              location_geo={item.location_geo}
              location_name={item.location_name}
              itunesKeywords={item.itunes_keywords}
              explicitTypeId={item.explicit_type_id}
              episodeTypeId={item.episode_type_id}
              explicitTypes={explicitTypes}
              episodeTypes={episodeTypes}
              supportFiles={supportFiles}
              newFileSize={newFileMeta.file_size}
              artworkUrl={item.artwork_url}
              episodeNumber={item.episode_number}
              fileSize={item.file_size}
              audioUrl={item.audio_url}
              season_id={seasonId}
              uploadedFileSize={item.uploaded_file_size}
              directUploadStatusCode={item.direct_upload_status_code}
              fileTypeGroup={fileTypeGroup}
              seasons={seasons}
              uploadFile={uploadFile}
              handleUploadFileChange={setUploadFile}
              handleMediaFileChange={handleMediaFileChange}
              handleQuillEditorChange={handleQuillEditorChange}
              handleSelectionChange={handleSelectionChange}
              handleDatepickerChange={handleDatepickerChange}
              handleInputValueChange={handleInputValueChange}
              handleLocationChange={handleLocationChange}
              handleSeasonChange={handleSeasonChange}
              submitButtonLabel={submitButtonLabel}
              saveButtonLabel={saveButtonLabel}
              handleAddSeason={handleAddSeason}
              setConvertToMp3={setConvertToMp3}
            >
              <EpisodePeople
                toggleNewPersonModal={toggleNewPersonModal}
                people={people}
                hosts={hosts}
                handleSorting={handleSorting}
                toggleModal={togglePeopleModal}
                personTaxonomies={personTaxonomies}
                handleDeletePerson={handleDeletePerson}
                handleTaxonomyChange={handleUpdatePersonRole}
              />
            </EpisodeForm>
          </div>
        </div>
      </div>
    </>
  )
}

export default Episode;