import React, {useEffect, useState, useContext} from "react";
import { Redirect } from "react-router-dom";
import queryString from 'query-string'
import { Spinner } from 'reactstrap';
import justcastApi from '../api/justcast'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AuthContext} from '../context/AuthContext'

const TwitterIntegrationCallback = (props)  => {
  const [redirect, setRedirect] = useState('');
  const [loading, setLoading] = useState(true);
  const {add} = useContext(ModalContext);
  const authContext = useContext(AuthContext) 

  useEffect(() => {
    const values = queryString.parse(props.location.search);    
    if(values && values['oauth_token']) {
      const oauth_token = values['oauth_token'];
      const oauth_verifier = values['oauth_verifier'];
      // console.log({oauth_token, oauth_verifier})
      justcastApi.post(`/v1/twitter_connections_refresh`, {
        oauth_token,
        oauth_verifier
      })
      .then((res) => {
        const showId = res.data.show_id;
        setLoading(false);
        add({color: 'danger', title: "Thank you" , message: "Twitter connection has been setup.", isOpen: true})
        setRedirect(`/shows/${showId}/integrations`)
      })
      .catch((err) => {        
        setLoading(false)
        add({color: 'danger', title: "Uh oh! Failed to setup Twitter connection." , message: "Connection has not been verified with Twitter.", isOpen: true})        
        setRedirect(`/dashboard`)
      })
    }
  },[])

  if(loading) {
    return <Spinner color="primary" />;
  }
  
  if(redirect) {
    // wait 3 seconds to redirect, and having a spinner to indicating it's loading    
    return <Redirect to={redirect}/>
  }

  return (<></>) 
}

export default TwitterIntegrationCallback;