import React from 'react'
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader } from 'reactstrap';

export default function PodcastSenderCard(props) {
  const { verifiedSenders} = props;

  if(verifiedSenders?.length > 0) {
    return props.children;
  } else {
    return (
      <Card>
        <CardHeader>
          Sender Identity
        </CardHeader>
        <CardBody>
          <p>The type of Sender Identity you use to send email can impact your deliverability and what your recipients see in their inbox.</p>
          <p>We can help you set it up so that your fans will receive emails from your own email address.</p>
          <Link to={`/sender_authentications`} className="btn btn-primary btn-sm">Get started</Link>
        </CardBody>
      </Card>
    )
  }
}
