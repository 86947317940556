import { atom } from "jotai";

export const playerConfigAtom = atom({
  hidePubDate: true,
  hideMoreInfo: true,
  playlistFullHeight: false
});

export const updatePlayerConfigAtom = atom((get) => get(playerConfigAtom), (_get, set, configs) => {
  const state = _get(playerConfigAtom);
  set(playerConfigAtom, {...state, ...configs});
})

export const playerColorOptiosAtom = atom([
  {id: 'primaryBackgroundColor', label: 'Player background color', value: 'primaryBackgroundColor'},
  {id: 'primaryButtonColor', label: 'Player button color', value: 'primaryButtonColor'},
  {id: 'primaryTextColor', label: 'Text color', value: 'primaryTextColor'},
  {id: 'progressBarFilledColor', label: 'Time bar color', value: 'progressBarFilledColor'},
  {id: 'progressBarBackgroundColor', label: 'Time bar background color', value: 'progressBarBackgroundColor'},
  {id: 'playlistBackgroundColor', label: 'Playlist background color', value: 'playlistBackgroundColor'},
  {id: 'playlistTextColor', label: 'Playlist text color', value: 'playlistTextColor'},
  {id: 'chapterBackgroundColor', label: 'Chapter background color', value: 'chapterBackgroundColor'},
  {id: 'chapterTextColor', label: 'Chapter text color', value: 'chapterTextColor'},  
]);

// {
//   hidePubDate: true,
//   primaryBackgroundColor: "#0c1824",
//   primaryButtonColor: "#f7f8f9",
//   primaryTextColor: "#f7f8f9",
//   progressBarFilledColor: "#f7f8f9",
//   progressBarBackgroundColor: "#8A8175",
//   // progressBarThumbColor: "#02030A",
//   playlistBackgroundColor: "#30343c",
//   playlistTextColor: "#f7f8f9",
//   chapterBackgroundColor: "#30343c",
//   chapterTextColor:  "#f7f8f9"
// }