import React, {useState, useRef} from 'react'
import {useAtom} from 'jotai'
import { Card, CardBody, Button, ButtonGroup } from 'reactstrap';
import ReactPlayer from 'react-player'
import {getHHMMSSFromSeconds} from '../../libs/timeTransformation'
import { initAtom, updatePlayerAtom } from './playerAtom'
import { updateChapterAtom } from './chapterAtom'

export default function AudioChapterMakerPlayer(props) {
  const { url } = props;
  const playerRef = useRef(null);
  const [onSeeking, setOnSeeking] = useState(true);
  const [, updatePlayer] = useAtom(updatePlayerAtom)
  const [chapter, toggleChapter] = useAtom(updateChapterAtom)
  const [configs, setInit] = useAtom(initAtom);
  const {playing, duration, playedSeconds} = configs;

  const handlePlayPauseClick = () => {
    // setPlaying(!playing)
    updatePlayer({playing: !playing})
  }

  const addNewChapter = () => {
    toggleChapter({
      id: null,
      title: "",
      start_time: "",
      url: "",
      img: "",
      isOpen: true
    });
  }

  const seekTo = (delta) => {
    let seekToSecond = playedSeconds + delta;
    if(seekToSecond < 0) {
      seekToSecond = 0
    } else if ( seekToSecond > duration) {
      seekToSecond = duration
    }
    playerRef.current.seekTo(seekToSecond, 'seconds')
  }

  return (
    <>
      <Card>
        <CardBody>
          <input
            className='jc-chapter-maker-player-slider'
            type="range"
            min={0}
            max={duration}
            step={0.01}
            value={playedSeconds}
            onChange={(e) => {
              updatePlayer({playedSeconds: Number(e.target.value)});
            }}
            onPointerDown={() => {
              setOnSeeking(false);
            }}
            onPointerUp={() => {
              setOnSeeking(true)
              playerRef.current.seekTo(playedSeconds, 'seconds')
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              background: `linear-gradient(90deg, #53a0e4 ${duration <= 1 ? 0 : playedSeconds * 100 / duration}%,  #d6f6fa ${duration <= 1 ? 0 : playedSeconds * 100 / duration}% )`
            }}
          />
          <div className='audiopost-waveform-timestamps'>
            <div>{getHHMMSSFromSeconds(playedSeconds)}</div>
            <div>{getHHMMSSFromSeconds(duration)}</div>
          </div>
          <ReactPlayer
            ref={playerRef}
            url={url}
            // controls={true}
            width="0%"
            height="0%"
            onDuration={(duration) => {
              setInit({
                playerRef: playerRef,
                duration
              })
            }}
            onProgress={(res) => {
              if(onSeeking) {
                updatePlayer({playedSeconds: res.playedSeconds})
              }
            }}
            playing={playing}
          />
          <div className='audiopost-waveform-control'>
            <div style={{display: 'flex', gap: "10px"}}>
              <div className='audiopost-waveform-playPause-button' onClick={handlePlayPauseClick}>
                {playing ? <i className='fe fe-pause'/> : <i className='fe fe-play'/>}
              </div>
              <ButtonGroup size="sm">
                <Button color="secondary" onClick={() => {
                  seekTo(-5)
                }}>-5s</Button>
                <Button color="primary" onClick={() =>{ seekTo(5)}}>+5s</Button>
              </ButtonGroup>
            </div>
            <Button size="sm" color="warning" onClick={() => {
              addNewChapter()
            }} disabled={chapter.isOpen}>Add new chapter</Button>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
