// this one is mainly using for uploading larger file
// such audio and video
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_MS_DIRECT_UPLOAD_PROXY 
});

export const uploadFileHandler = (file, showId, audiopostId, showName, audiopostName, handleProgressUpload, convertToMp3) => {
  const data = new FormData()
  data.append('file', file);
  data.append('showId', showId);
  data.append('audiopostId', audiopostId);
  data.append('showName', showName);
  data.append('audiopostName', audiopostName);
  data.append('convertToMp3', convertToMp3);

  const onUploadProgress = (data) => {
    // console.log(Math.round((100 * data.loaded) / data.total));
    handleProgressUpload({
      uploadedSize: data.loaded, 
      totalSize: data.total,
      audiopostId: audiopostId,
    })
  }  
  
  // const header = {'Content-Type': 'multipart/form-data' }
  return instance.post('/api/direct_upload', data, {onUploadProgress})
}

export default {}