import React from "react";
import {useAtom} from 'jotai'
import {NinjaPlayer} from 'react-podcast-ninja'
import {playerConfigAtom} from '../jotai'
import WidgetCodeSection from './WidgetCodeSection'

const PlaylistWidget = ({showId, episodes, is_advanced_private_feed, allowSite}) => {
  const [configs] = useAtom(playerConfigAtom);

  if(episodes && episodes.length > 0) {
    return (
      <>
        <div className="row">
          <h2 className="col-12">Multi-episode player:</h2>
        </div>
        <div className="row">
          {/* <div className="col-12" dangerouslySetInnerHTML={{__html: `<iframe src='${process.env.REACT_APP_LANDING_PAGE}/widget/${showId}/audioposts' width='100%' height='370' frameborder='0' scrolling='no' seamless='true' style='width:100%; height:370px;'></iframe>` }} />             */}
          <div className="col-12">
            <NinjaPlayer
              configs={configs}
              playerId={`${showId}-playlist`}
              episodes={episodes}
              themeName="retro"
            />
          </div>
        </div>
        <WidgetCodeSection
          showId={showId}
          is_advanced_private_feed={is_advanced_private_feed}
          allowSite={allowSite}
        />
      </>
    )
  }
  return null;
}

export default PlaylistWidget;