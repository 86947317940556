import React from 'react'
import { Card, CardHeader, CardBody, Button, CustomInput, Row, Alert, CardFooter } from 'reactstrap';

export default function MailerLiteGroups({showId, groups, handleDelete}) {

  const handleRemoveGroup = (group) => {
    handleDelete(group)
  } 

  if(!groups) return null;

  return (
    <Card>
      <CardHeader>
        <h2 className="card-header-title">Connected</h2>
      </CardHeader>
      <CardBody>        
        <div className="list-group list-group-flush">
          {
            groups.map((group) => 
              <div className="list-group-item" key={group.id}>
                <div className="row align-items-center">
                  <div className="col ml-n2">{group.name}</div>
                  <Button color="danger" size="sm" onClick={() => {
                    handleRemoveGroup(group)
                  }}>Delete</Button>
                </div>
              </div>
            )
          }
        </div>
      </CardBody>
    </Card>
  )
}
