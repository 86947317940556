import React from 'react'

export default function Season(props) {
  const {name, number} = props;
  
  return (
    <div className="list-group-item">
      <div className="row">
        <div className="col-auto">
          <div className="avatar avatar-sm">
            <div className="avatar-title font-size-lg bg-success-soft rounded-circle text-success">
              <span>{number}</span>
            </div>
          </div>
        </div>
        <div className="col">
          <h3>{name ? name : `Season ${number}`}</h3>
        </div>
        {
          props.children
        }
      </div>
    </div>
  )
}
