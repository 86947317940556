class Drawer { 
  constructor(screenWidth, marginRight, marginLeft, barGap, barWidth, barHeight, xAxis, yAxis) {
    this.screenWidth = screenWidth;
    this.marginRight = marginRight;
    this.marginLeft = marginLeft;
    this.barGap = barGap; 
    this.barWidth = barWidth;
    this.barHeight = barHeight;
    this.xAxis = xAxis;
    this.yAxis = yAxis
  }

  updateSpec(screenWidth, marginRight, marginLeft, barGap, barWidth, barHeight, xAxis, yAxis) {
    this.screenWidth = screenWidth;
    this.marginRight = marginRight;
    this.marginLeft = marginLeft;
    this.barGap = barGap; 
    this.barWidth = barWidth;
    this.barHeight = barHeight;
    this.xAxis = xAxis;
    this.yAxis = yAxis
  }

  updateLocation(xAxis, yAxis, screenWidth, barHeight) {
    this.xAxis = xAxis;
    this.yAxis = yAxis
    this.screenWidth = screenWidth;
    this.barHeight = barHeight;
  }

  // determine how many data point we are plotting per screen  
  _setBarPerScreen() {
    return (this.screenWidth - this.marginRight - this.marginLeft) / (this.barGap + this.barWidth);
  }

  // determin points
  screenData(audioData) {
    const barsPerScreen = this._setBarPerScreen();
    const k = Math.ceil(audioData.length / barsPerScreen);
    const normalizedAudioData = [];
    let maxVal = null
    for(let j = 0; j < audioData.length; j++ ) {
      const currentVal = audioData[j];
      if( maxVal === null || currentVal > maxVal ) {
        maxVal = currentVal;        
      }
      if(j > 0 && j % k === 0 ) {
        normalizedAudioData.push(maxVal < 0 ? 0.05 : maxVal * 0.7);
        maxVal = null;
      } else if (j === barsPerScreen - 1) {
        normalizedAudioData.push(maxVal < 0 ? 0.05 : maxVal * 0.7);
        maxVal = null;
      }
    }
    return normalizedAudioData;
  }

  generateSvg(audioData, filledColor) {
    const bars = this.screenData(audioData);    
    const svgs = [];
    for(let j = 0; j < bars.length; j++) {
      const xAxis = this.xAxis + this.marginLeft + j * (this.barWidth + this.barGap)
      // const yAxis = this.yAxis - this.barHeight * bars[j];
      // const height = (this.barHeight * bars[j]) * 2;      
      const yAxis = this.yAxis - this.barHeight * bars[j] + this.barHeight * 0.5
      const height = this.barHeight * bars[j] * 2;
      svgs.push({
        filledColor,
        xAxis,
        yAxis,
        barWidth: this.barWidth,
        height,
      });
    }
    return svgs;
  }
}

export default Drawer