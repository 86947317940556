import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../../../api/justcast';
import {Context as MenuContext} from '../../../context/MenuContext'
import { ContentCard} from "../../../components/ai-contents";
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import ToastMessage from '../../../components/ToastMessage'

const AiContents = (props) => {
  const {audiopost_id, transcript_id} =  props.match.params;
  const {setAiContentPageMenu} = useContext(MenuContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastTitle, setToastTitle] = useState("")
  const [toastMessage, setToastMessage] = useState("")
  const [timeoutId, setTimeoutId] = useState(null);
  const [contentPrompts, setContentPrompts] = useState([]);

  useEffect(() => {
    justcastApi.get(`/v2/audioposts/${audiopost_id}/transcripts/${transcript_id}/content_prompts`)
    .then((res) => {
      const {
        show,
        audiopost,
        content_prompts
      } = res.data;

      setContentPrompts(content_prompts)

      setAiContentPageMenu({
        title: "AI Content",
        currentPageId: "episodes",
        currentTabId: "ai-contents",
        subtitle: `${show.podcast_title} > ${audiopost.name}`,
        showName: show.podcast_title,
        showId: show.id,
        episodeId: audiopost_id,
        transcriptId: transcript_id,
        landingPageUrl: show.landing_page_url,
        websiteUrl: show.player_page_link,
        rssFeed: show.rss_feed,
        passwordProtected: show.is_password_protected,
        advancedPrivate: show.is_advanced_private_feed,
        isPrivate: show.is_private,
        isPrivateShow: show.is_private_show,
        skill_podcast_website: show.skill_podcast_website
      })
    })

  }, [])

  const handleReRun = (content_prompt_id) => {
    setModalOpen(true);
    if(timeoutId) {
      clearTimeout(timeoutId)
    };

    justcastApi.post(`/v2/audioposts/${audiopost_id}/transcripts/${transcript_id}/content_prompts/${content_prompt_id}/rerun`)
    .then((res) => {
      const { id, label } = res.data

      const k = contentPrompts.map((contentPrompt) => contentPrompt.id).indexOf(id);
      const nextContentPrompts = [...contentPrompts];
      nextContentPrompts[k] = res.data;
      setContentPrompts(nextContentPrompts);

      setToastOpen(true)
      setToastTitle("Success")
      setToastMessage(`Generate a new ${label}`)

      const _timeOutId = setTimeout(() => {
        setToastOpen(false)
      }, 2000)

      setModalOpen(false);
      setTimeoutId(_timeOutId)
    })
    .catch((err) => {
      // toast something went wrong
      console.log(err)
      setModalOpen(false);
      setToastOpen(true);
      setToastTitle("Failed")
      setToastMessage(`Something went wrong`)
    })
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }

  const toggleToast = () => {
    setToastOpen(!toastOpen)
  }

  return (
    <div className="container-fluid">
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Generating new content</ModalHeader>
        <ModalBody>
          <Spinner/>
        </ModalBody>
      </Modal>
      <ToastMessage
        show={toastOpen}
        toggle={toggleToast}
        title={toastTitle}
        message={toastMessage}
      />
      {contentPrompts.map((contentPrompt) => {
        return (
          <div className="row" key={contentPrompt.id}>
            <div className="col-12">
              <ContentCard
                data={contentPrompt}
                handleReRun={handleReRun}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AiContents;