import React, {useState, useEffect, useContext} from 'react'
import { Spinner } from 'reactstrap';
import moment from 'moment';
import {Context as AuthContext} from '../context/AuthContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import justcastApi from '../api/justcast'
import ReceiptPageComponent from '../components/ReceiptPageComponent'

const Receipt = (props) => {
  const screenContext = useContext(ScreenContext)
  const authContext = useContext(AuthContext);
  const menuContext = useContext(MenuContext);
  const email = authContext.state.email;  
  const receiptId = props.match.params.id;
  const [receipt, setReceipt] = useState({id: '', amount: 0, receipt_date: '', card_brand: '', card_last4: '', stripe_id: ''});

  useEffect(() => {
    screenContext.startFetching();
    getReceipt();
    menuContext.setMainPageLeftMenu({title: `Invoice ${receiptId}`, subtitle: 'Receipts', currentPageId: 'home'});
  }, [receiptId])

  const getReceipt = () => {
    justcastApi.get(`/v1/charges/${receiptId}`)
    .then((res) => {
      const data = res.data;
      setReceipt({
        ...receipt, 
        id: data.id,
        amount: data.amount,
        receipt_date: data.receipt_date,
        card_brand: data.card_brand,
        card_last4: data.card_last4,
        stripe_id: data.stripe_id
      });
      screenContext.finishFetching();
    })
    .catch((err) => {
      //TODO: handle error
      screenContext.finishFetching();
      console.log(err);
    })
  }

  return (
    <>      
      <div className="container-fluid">
        {screenContext.state.loading ? <Spinner color="primary" /> : <BodyDiv id={receipt.id} email={email} amount={receipt.amount} receipt_date={receipt.receipt_date} card_brand={receipt.card_brand} card_last4={receipt.card_last4} stripe_id={receipt.stripe_id}/> }
      </div>
    </>
  )
}

const BodyDiv = ({id, email, amount, receipt_date, card_brand, card_last4, stripe_id}) => {
  return (
    <>      
      <div className="container-fluid">
        <ReceiptPageComponent
          id={id} 
          email={email} 
          amount={amount} 
          receipt_date={receipt_date} 
          card_brand={card_brand} 
          card_last4={card_last4} 
          stripe_id={stripe_id}
        />
      </div>
    </>   
  )
}

export default Receipt;