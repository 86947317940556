import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

export default function LabelTooltip({id, message}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <span id={id}><i className='fe fe-info'/></span>
      <Tooltip placement="right" isOpen={tooltipOpen} target={id} toggle={toggle}>
        {message}
      </Tooltip>
    </>
  )
}
