import React, {useState, useEffect, useContext} from "react";
import { Button, ButtonGroup} from 'reactstrap';
import { useAtom } from 'jotai';
import { saveChapterAtom, deleteChapterAtom } from './chaptersAtom'

const ChapterDelete = ({id, idx, audiopostId, handleDelete}) => {
  // const [, deleteChapter] = useAtom(deleteChapterAtom);
  const handleDeleteClick = () => {
    handleDelete({id, idx, audiopostId})
  }

  return (    
    <Button color="danger" onClick={handleDeleteClick}><i className="fe fe-trash-2"/></Button>    
  )
}

export default ChapterDelete;