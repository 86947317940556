import React, {useContext, useState, useEffect} from "react";
import Select from 'react-select'
import { Card, CardHeader, CardBody, Button, CardFooter } from 'reactstrap';
import {Context as ModalContext} from '../../context/ModalContext'
import {Context as AlertContext} from '../../context/AlertContext'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
  })
}

export default function SelectProductCard({title, items, handleAddItem, buttonLabel}) {
  const [selectedGroup, setSelectedGroup] = useState({label: "", id: ""});

  if(!items) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <div className="row align-items-center">
          <div className="col">
            <h2 className="card-header-title">{title}</h2>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Select
          value={selectedGroup}
          options={items.map((group) => {
            return {label: group.label, id: group.id, value: group.id}
          })}
          styles={customStyles}
          onChange={(e) => {
            setSelectedGroup(e)
          }}
        />
      </CardBody>
      <CardFooter>
        <Button color="primary" onClick={() => {
          handleAddItem(selectedGroup)
        }}>{buttonLabel}</Button>
      </CardFooter>
    </Card>
  )
}
