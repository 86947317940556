import React from 'react'
import { Link } from "react-router-dom";

export default function CardNote() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <p>To use the MailerLite integration, please connect your JustCast account with MailerLite.</p>
          <Link to="/connections" className="btn btn-primary btn-sm">Get started</Link>
        </div>
      </div>
    </div>
  )
}
