import React, {useState, useRef, useContext} from "react";
import { Form, FormGroup, Row, Input, Button, Card, CardBody } from 'reactstrap';
import 'flatpickr/dist/themes/dark.css'
import Flatpickr from 'react-flatpickr'
import {formatDistance } from 'date-fns'
import {Context as AlertContext} from '../../../context/AlertContext'
import {Context as ModalContext } from '../../../context/ModalContext'
import FormGroupInputSelect from '../../FormGroupInputSelect';
import NeedToConvertModal from "../../episode/NeedToConvertModal"
import UploadStatusLabel from '../../episode/UploadStatusLabel'
import {directUploadAudio} from '../../../api/activestorage';
import AudioPreviewPlayer from "../../episode/AudioPreviewPlayer"
import AudioFilePicker from "../../episode/AudioFilePicker"
import justcastApi from '../../../api/justcast';


export default function NewForm(props) {
  const {
    showId,
    explicitTypes,
    episodeTypes,
    setRedirectPage,
  } = props;

  const {addWithTimeout} = useContext(AlertContext);
  const modalContext = useContext(ModalContext)

  const [name, setName] = useState("");
  const hanleNameChange = (event) => {
    setName(event.target.value)
  }

  const [audioDate, setAudioDate] = useState(new Date());
  const handleDatepickerChange = (date) => {
    const _audioDate = date[0];

    if(_audioDate > new Date()) {
      const message = "Do you want this published at a future time/date? This episode won't be released in " + formatDistance(audioDate, new Date())
      addWithTimeout({color: 'warning', message, isOpen: true, timeout: 5000})
    }
    setAudioDate(_audioDate)
  }

  const [episodeTypeId, setEpisodeTypeId] = useState(null);
  const handleEpisodeTypeChange = (e) => {
    const value = e.target.value;
    setEpisodeTypeId(value);
  }

  const [explicitTypeId, setExplicitTypeId] = useState(null);
  const handleExplicitTypeChange = (e) => {
    const value = e.target.value;
    setExplicitTypeId(value);
  }

  const [needToConvert, setNeedToConvert] = useState(false);
  const [modal, setModal] = useState(false);
  const handleChooseConvert = (convert) => {
    setNeedToConvert(convert);
    setModal(false)
  }

  const toggle = () => {
    setModal(!modal);
  }

  const [progress, setProgress] = useState(0);
  const [audioSrc, setAudioSrc] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [duration, setDuration] = useState(null);
  const [durationSecond, setDurationSecond] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadedBlob, setUploadedBlob] = useState(null);

  const handleDurationChanged = (sec, timeStr) => {
    setDuration(timeStr);
    setDurationSecond(sec)
  }
  const handleRemoveFileClicked = () => {
    // setFileName("");
    setAudioFile(null);
    setAudioSrc(null);
    setProgress(0);
    setDuration(null);
    setDurationSecond(0)
    setUploadingFile(false)
    setUploadedBlob(null)
    setNeedToConvert(false);
  }

  const handleFileChange = (file) => {
    setAudioFile(file);
    if(file) {
      // setFileName(file['name'])
      if(name?.length === 0) {
        setName(file['name']);
      }

      setUploadingFile(true);

      const upload = directUploadAudio({
        file: file
      }, (val) => {
        setProgress(val)
      })

      upload.create((err, blob) => {
        if(err) {
          console.log(err)
        } else {
          setUploadedBlob(blob)
          setUploadingFile(false)
        }
      })

    } else {
      // setFileName("")
      setUploadingFile(false)
      setNeedToConvert(false)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(uploadingFile) {
      modalContext.addWithMessages({
        color: "primary",
        title: 'Error',
        messages: ["Can't save while uploading audio file."],
        isOpen: true,
        loading: false
      })
      return;
    }
    if(!audioFile) {
      // TODO alert modal to tell user need to upload audio file
      modalContext.addWithMessages({
        color: "primary",
        title: 'Error',
        messages: ["Audio file must be included before saving your episode."],
        isOpen: true,
        loading: false
      })
      return;
    }
    const data = {
      name,
      explicit_type_id: explicitTypeId || explicitTypes[1]?.id,
      episode_type_id: episodeTypeId || episodeTypes[0]?.id,
      duration,
      duration_second: durationSecond,
      audio_date: audioDate,
      content_type: audioFile.type,
      signed_id: uploadedBlob?.signed_id,
      need_to_convert: needToConvert,
      is_draft: true
    }

    justcastApi.post(`/v2/team_podcasts/${showId}/team_audioposts/`, {
      audiopost: data
    })
    .then((res) => {
      const data = res.data;
      addWithTimeout({color: 'warning', message: "Episode saved as a draft", isOpen: true, timeout: 8000});
      modalContext.addWithMessages({
        messages: []
      })
      setRedirectPage(`/team_podcasts/${showId}/team_episodes/${data.id}`)
    })
    .catch((err) => {
      if(err.response.data.message) {
        modalContext.addWithMessages({
          color: "primary",
          title: 'Error',
          messages: [err.response.data.message],
          isOpen: true,
          loading: false
        })
      } else {
        const messages = err.response.data.messages;
        const errorMessages = Object.keys(messages).map((name) => `${name}: ${messages[name].join(",")}`)
        modalContext.addWithMessages({
          color: "primary",
          title: 'Error',
          messages: errorMessages,
          isOpen: true,
          loading: false
        })
      }
    })
  }

  return (
    <>
      <NeedToConvertModal
        modal={modal}
        toggle={toggle}
        handleChooseConvert={handleChooseConvert}
      />
      <Form className="mb-4" onSubmit={handleSubmit}>
        <FormGroup>
          <label>Name</label>
          <Input
            type="text"
            className="form-control"
            name="name"
            placeholder="The title of your new episode"
            value={name}
            onChange={hanleNameChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Publish date</label>
          <Flatpickr
            className='form-control mb-3'
            data-enable-time
            value={audioDate}
            onClose={handleDatepickerChange}/>
            <small className="form-text text-warning">Schedule a future release by change to a future date</small>
        </FormGroup>

        <Row>
          <div className="col-12 col-md-6">
            {explicitTypes && <FormGroupInputSelect
              inputType="select"
              inputLabel="Explicit type"
              inputName='explicit_type_id'
              optionLabel="label"
              optionId="id"
              mutedText="If you specify true, indicating the presence of explicit content, Apple Podcasts displays an Explicit parental advisory graphic for your podcast.  Podcasts containing explicit material aren’t available in some Apple Podcasts territories."
              options={explicitTypes}
              handleInputChange={handleExplicitTypeChange}
              inputValue={explicitTypeId || explicitTypes[1]?.id}
            />}
          </div>
          <div className="col-12 col-md-6">
            {episodeTypes && <FormGroupInputSelect
              inputType="select"
              inputLabel="Episode type"
              inputName='episode_type_id'
              optionLabel="name"
              optionId="id"
              mutedText="Use Trailer for a short, promotional piece of content that represents a preview of a show.  Use bonus for extra content (for example, behind the scenes information or interviews with the cast)"
              options={episodeTypes}
              handleInputChange={handleEpisodeTypeChange}
              inputValue={episodeTypeId || episodeTypes[0]?.id }
            />}
          </div>
        </Row>
        <hr/>
        <FormGroup>
          <label>Audio File</label>
          {audioSrc ? <AudioPreviewPlayer
            url={audioSrc}
            title={audioFile?.name}
            fileSize={audioFile?.size}
            handleDurationChanged={handleDurationChanged}
          >
          { uploadingFile ? <UploadStatusLabel progress={progress}/> : <div className='btn btn-danger btn-sm' onClick={handleRemoveFileClicked}>
              <span className="fe fe-trash"/> Delete
            </div>}
          </AudioPreviewPlayer> :
          <AudioFilePicker
            setSrc={setAudioSrc}
            setFile={handleFileChange}
            setModal={setModal}
          />}
          <div style={{marginTop: "8px"}}><small className="text-muted">Supported file types are MP3, WAV, or M4A. Audio files will be encoded up to 128kbps MP3s and the file size cannot exceed 1GB. Variable bit rate files under 128 kbps are not supported.</small></div>
        </FormGroup>
        <hr/>
        <div style={{margin: "40px 0 40px 0", display: "flex", columnGap: "10px", justifyContent: "flex-end"}}>
          <Button color="primary">Save as Draft</Button>
        </div>
      </Form>
    </>
  )

}