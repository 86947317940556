import React, {useState} from 'react'
import Tree from './Tree'
import {ListGroupItem } from 'reactstrap';
import { Folder, FolderMinus, FolderPlus} from 'react-feather';
import { Link } from "react-router-dom";

const FolderIcon = ({showChildren}) => {
  if (showChildren) {
    return <FolderMinus/>
  } else {
    return <FolderPlus/>
  }
}

export default function TreeNode({node}) {
  const { children, id, name } = node;
  const [showChildren, setShowChildren] = useState(false);
  const handleClick = () => {
    setShowChildren(!showChildren);
  };

  return (
    <ListGroupItem>
      <div style={{marginBottom: "10px", display: 'flex', alignItems: "center", justifyContent: "space-between"}}>
        <div style={{display: 'flex', alignItems: "center", gap: "10px"}}>
          <div>
            {children.length == 0 ? <div><Folder/></div> : <div onClick={handleClick} style={{cursor: "pointer"}}><FolderIcon showChildren={showChildren}/></div>}
          </div>
          <div>{name}</div>
        </div>
        <Link to={`/shows/${id}/episodes`} className="btn btn-primary btn-sm">View</Link>
      </div>
      <div style={{paddingLeft: "10px"}}>
        {showChildren && <Tree treeData={children}/>}
      </div>
    </ListGroupItem>
  )
}
