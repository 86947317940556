import React, {useState} from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'

export default function ArtworkUploadModal({modal, toggle, errors}) {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Something went wrong</ModalHeader>
        <ModalBody>
          {
            errors?.map((error, i) => <p key={i}>{error}</p>)
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
    </Modal>
  )
}
