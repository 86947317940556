import React from "react";
import {Rnd} from 'react-rnd';

const multipler = {
  'start': 0,
  'middle': 0.5,
  'end': 1,
}

const TextDnD = ({defaultLocation, handleDragStop, enabled}) => {
  if(defaultLocation && enabled) {
    return (
      <Rnd
        // default={{ 
        //   x: defaultLocation.x, 
        //   y: defaultLocation.y,
        //   width: defaultLocation.width,  
        //   height: defaultLocation.height 
        // }}
        size={{
          width: defaultLocation.width + 10, 
          height: defaultLocation.height + 10
        }}
        position={{
          x: defaultLocation.x - multipler[defaultLocation.textAnchor] * defaultLocation.width, 
          y: (defaultLocation.y - defaultLocation.height)
        }}
        onDragStop={(e, d) => {
          handleDragStop(d.x + multipler[defaultLocation.textAnchor] * defaultLocation.width, d.y + defaultLocation.height);
        }}
        enableResizing
        bounds="parent"
        style={{ borderColor: "#F17BB0", borderStyle: "dotted", borderWidth: "2px"}}
        >  
      </Rnd>
    )
  }

  return null;
};

export default TextDnD;