import React from "react";
import ValueRecipient from './ValueRecipient'

const ValueRecipients = (props) => {
  const {items, handleChange, handleDelete} = props;

  if(items) {
    return (
      items.map((item, idx) => 
        <ValueRecipient
          key={(idx+1).toString()}
          idx={idx}
          id={item.id}
          name={item.name}
          value_type={item.value_type}
          address={item.address}
          fee={item.fee}
          split={item.split}
          custom_key={item.custom_key}
          custom_value={item.custom_value}
          handleChange={handleChange}
          handleDelete={handleDelete}
        />
      )    
    )
      
  }

  return null;
}

export default ValueRecipients;
