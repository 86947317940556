import React, { useEffect } from 'react'
import { Card, CardBody, CardHeader, Form, Col, Row, FormGroup, Label, FormText, Button } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.isSelected ? 'white' : 'blue',
  })
}

export default function PodcastSenderForm({defaultSender, verifiedSenders, handleSave, handleRemoveSenderId}) {
  const { control, register, handleSubmit, formState: { errors, isDirty }, getValues, setValue } = useForm();
  const onSubmit = data => handleSave(data);

  const handleDelete = () => {
    setValue('sender_email', null)
    handleRemoveSenderId()
  }

  return (
    <Card>
      <CardHeader>
        Sender Identity
      </CardHeader>
      <CardBody>
        <Form>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="sender_email">Email address</Label>
                <Controller
                  name="sender_email"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <Select
                    {...field}
                    defaultValue={defaultSender}
                    // value={defaultSender || getValues('sender_email')}
                    styles={customStyles}
                    options={verifiedSenders}
                  />}
                />
                {errors.sender_email && <FormText color="warning">Email address can't be empty</FormText>}
                {/* {formErrors?.country && <FormText color="warning">{formErrors?.country}</FormText>} */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12} >
              <div style={{display: "flex", columnGap: "10px", justifyContent: "flex-end"}}>
                {defaultSender?.id && !isDirty && <Button color="danger" onClick={handleDelete}>Disconnect</Button>}
                <Button onClick={handleSubmit(onSubmit)} color="primary">Save</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}
