import React, {useContext} from "react";
import {Context as ModalContext} from '../context/ModalContext'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import TextInputClipboard  from './TextInputClipboard'
import QrCodeComponent  from './QrCodeComponent'

const MessageModal = ({}) => {
  const {state, reset} = useContext(ModalContext);

  if(state.isOpen) {
    return (
      <Modal isOpen={state.isOpen}>
        <ModalHeader toggle={reset}>
          <strong>{state.title}</strong>
        </ModalHeader>
        <ModalBody>
          {
            state.messages && state.messages.length > 0 ? <><ul>{state.messages.map((msg, idx) => <li key={`modal-message-${idx+1}`}>{msg}</li>)}</ul></> : null
          }
          {state.message ? 
            <><div dangerouslySetInnerHTML={{__html: state.message}}/><br/></> : null}
          {
            state.loading ? <Spinner color="warning"/> : null
          }            
          {
            state.inputs.map((link) => 
              <div key={link.id}>
                <h2>{link.name}</h2>
                <div>
                  <TextInputClipboard id={link.id} inputValue={link.url} name={link.name} />                  
                  <br/>
                </div>
                {
                  link.qrLink ? <QrCodeComponent link={link.qrLink}/> : null
                }                          
              </div>             
            )
          }
          
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={reset}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
  return null;
}

export default MessageModal;
