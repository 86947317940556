import React from "react";
import { Button, Card, CardBody, CardHeader, CardFooter, Input, InputGroup, Label, Form, FormGroup, Row, Col, FormText } from 'reactstrap';
import AudioPreviewPlayer from "./AudioPreviewPlayer";
import {Link} from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";

const EditForm = (props) => {
  const { setAttachmentSrc, attachmentSrc, attachmentFilename, handleSave, title, description } = props;
  const accept = ['audio/mp3', 'audio/mpeg']

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const handleDurationChanged = (seconds) => {
    setValue('duration', seconds)
  }

  const handleRemoveFileClicked = () => {
    setValue('file', null);
    setValue('duration', 0)
    setAttachmentSrc(null)
  }

  const file = watch("file");
  const { ref, ...registerDescription } = register('description');
  if(title) {
    setValue('title', title)
  }

  if(description) {
    setValue('description', description)
  }

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <Card>
        <CardHeader>
          New File
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="title">Name</Label>
                <InputGroup>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Intro..."
                    {...register("title", {required: true})} />
                </InputGroup>
                {errors.title && <FormText color="danger">Name can't be empty</FormText>}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {
                (attachmentSrc || (file && file[0])) && <AudioPreviewPlayer
                  url={attachmentSrc || URL.createObjectURL(file[0])}
                  title={attachmentFilename || file[0]?.name}
                  handleDurationChanged={handleDurationChanged}
                >
                  <div className='btn btn-danger btn-sm' onClick={handleRemoveFileClicked}>
                    <span className="fe fe-trash"/> Delete
                  </div>
                </AudioPreviewPlayer>
              }
              {!(attachmentSrc || (file && file[0])) && <FormGroup>
                  <input
                    type="file"
                    accept={accept.join(",")}
                    id="audiopicker"
                    name="audio file picker"
                    {...register("file", {required: true})}
                  />
                </FormGroup>
              }
              {errors.file && <FormText color="danger">File can't be empty</FormText>}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="description">Description</Label>
                <InputGroup>
                  <Input type="textarea" name="description" id="description" innerRef={ref} {...registerDescription}/>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <div style={{display: "flex", flexDirection: "row-reverse"}}>
            <div style={{display: 'flex', gap: "10px", flexDirection: "row-reverse"}}>
              <Button color="primary">Save</Button>
              <Link className='btn btn-secondary' to="/dynamic-contents">Cancel</Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </Form>
  )
}

export default EditForm;