import React, {useState} from 'react'
import { FormGroup, Input, Button, Row, InputGroup, InputGroupAddon, Card, CardBody } from 'reactstrap';
import Select from 'react-select'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PersonNote from './PersonNote'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
  })
}

export default function EpisodePeople({
  people,
  personTaxonomies,
  hosts,
  handleSorting,
  toggleModal,
  handleTaxonomyChange,
  handleDeletePerson,
  toggleNewPersonModal
}) {

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    handleSorting(result)
  }

  if(people) {
    return (
      <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h2>People</h2>
          <div>
            <Button color="primary" size="sm" onClick={toggleModal}>Add a host</Button>
          </div>
        </div>
        <Card>
          <CardBody>
            <p>This specifies a person of interest to the podcast. It is primarily intended to identify people like hosts, co-hosts and guests. Can't find your host?</p>
            <Button color="warning" size="sm" onClick={toggleNewPersonModal}>Add a new one</Button>
          </CardBody>
        </Card>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="people">
            {
              (provided) => (
                <div className="people" {...provided.droppableProps} ref={provided.innerRef}>
                  {
                    hosts.map((host, idx) => {
                      return (
                        <Draggable isDragDisabled={false} key={`person-${idx+1}`} draggableId={`person-${idx+1}`} index={idx}>
                          {(provided) => (
                            <div className="host" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <div className="col-12 col-md-6">
                                      <FormGroup>
                                        <label>Name</label>
                                        <InputGroup>
                                          <InputGroupAddon addonType="prepend"><Button color="danger" onClick={() => {
                                            handleDeletePerson(idx);
                                          }}><span className='fe fe-trash'/></Button></InputGroupAddon>
                                          <Input disabled value={host.name}/>
                                        </InputGroup>
                                      </FormGroup>
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <FormGroup>
                                        <label>Roles</label>
                                        <Select
                                          value={host.roles}
                                          isMulti
                                          options={personTaxonomies}
                                          styles={customStyles}
                                          onChange={(e) => {
                                            handleTaxonomyChange(idx, e)
                                          }}
                                        />
                                      </FormGroup>
                                    </div>
                                  </Row>
                                </CardBody>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      )
                    })
                  }
                  {provided.placeholder}
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>
    )
  }

  // TODO: return something to ask user to add people to the show
  return null;
}
