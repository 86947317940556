import { atom } from "jotai";
import justcastApi from '../../api/justcast'

const temp = {
  title: "",
  subtitle: null,
  message: null,
  progress: null,
  isSaveButtonOn: true,
  isOpen: false,
  isLoading: false,
  addNewSocket: false,
}

export const modalAtom = atom(temp);

export const setModalAtom = atom((get) => {
  return get(modalAtom)
}, (_get, set, data) => {
  const prevState = _get(modalAtom);
  set(modalAtom, {...prevState, ...data})
})

export const resetModalAtom = atom(null, (get, set, _) => {
  set(modalAtom, {
    title: "",
    subtitle: null,
    message: null,
    progress: null,
    isSaveButtonOn: true,
    isOpen: false,
    isLoading: false,
    addNewSocket: false,
  })
})

export const toggleModalAtom = atom(null, (get, set, _) => {
  const prevData = get(modalAtom);
  const {isOpen} = prevData;

  set(modalAtom, {...prevData, isOpen: !isOpen})
}) 

export const saveFileAtom = atom(null, (get, set, audiopostId) => {

  justcastApi.post(`/v1/audioposts/${audiopostId}/add_chapters_meta_to_audio`, {})
  .then((res) => {
    const resData = res.data;
    set(modalAtom, {...resData, isOpen: true, isSaveButtonOn: false, addNewSocket: true, isLoading: true})
  })
  .catch((err) => {
    console.log(err);
  })  
})
