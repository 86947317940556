import createDataContext from './createDataContext';

const initState = {
  socket: null,
  channels: [],
  livedownloads: {}
}

const socketReducer = (state, action) => {
  switch(action.type) {
    case 'add':
      return {...state, ...action.payload}
    case 'add_channel':
      return {...state, ...action.payload}
    case 'add_channel_livedata':
      return {...state, ...action.payload}
    case 'add_data_to_channel':
      const {channel, data} = action.payload;
      const tmp = {...state.livedownloads};
      const arr = tmp[channel];
      if(arr.length >= 100) {
        // keep 100
        arr.pop();
        tmp[channel] = [data, ...arr];
      } else {
        tmp[channel] = [data, ...arr];
      }
      return {...state, livedownloads: tmp}
    case 'reset': 
      return {...initState}
    default:
      return state    
  }
}

const add = dispatch => {
  return (socket) => {
    dispatch({type: 'add', payload: socket})
  }
}

const reset = dispatch => {
  return () => {
    dispatch({type: 'reset'})
  }
}

const addChannel = dispatch => {
  return (channels) => {
    dispatch({type: 'add_channel', payload: {channels}})
  }
}

const addChannelForLiveDownloads = dispatch => {
  return (channels, livedownloads) => {
    dispatch({type: 'add_channel_livedata', payload: {channels, livedownloads}})
  }
}

const addDataToChannel = dispatch => {
  return (channel, data) => {
    dispatch({type: 'add_data_to_channel', payload: {channel, data}})
  }
}

export const {Provider, Context} = createDataContext(
  socketReducer,
  {
    add,
    addChannel,
    addChannelForLiveDownloads,
    addDataToChannel,
    reset
  },
  initState
)
