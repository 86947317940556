import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const NewSignupGreeding = ({modal, toggle, className}) => {
	return (
		<div>
			<Modal isOpen={modal} 
				modalTransition={{ timeout: 700 }} 
				backdropTransition={{ timeout: 1300 }}
				toggle={toggle} className={className}>
					<ModalHeader toggle={toggle}>Welcome to JustCast</ModalHeader>
					<ModalBody>
						<strong>Thanks for siging up - your account's ready to go!</strong>
						<p>We built JustCast to be the easiest way to get your podcast up and running.</p>
						<p>That way you can spend more time on making your show the best it can be.</p>
						<p>If you ever need a hand with anything, please contact us at justcastapp@gmail.com</p>
						<p>Cheers and happy podcasting!</p>
						<p>Roland &amp; Josh</p>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={toggle} block>OK! Let's see my account</Button>
					</ModalFooter>
			</Modal>
		</div>
	)
}

export default NewSignupGreeding;