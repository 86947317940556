import React from 'react'
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'video']
  ]
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

export default function Editor({
  quillRef, handleQuillEditorChange, value
}) {
  return (
    <ReactQuill
      ref={quillRef}
      value={value || ""}
      modules={modules}
      formats={formats}
      onChange={handleQuillEditorChange}
    />
  )
}
