import React, {useState, useEffect} from "react";
import { Card, CardBody, CardFooter,Button, Row, CustomInput } from 'reactstrap';
import TipjarSettings from './TipjarSettings';
import TipjarPriceTable from './TipjarPriceTable'

const TipjarEnableCard = (props) => {
  const {
    enabledTipJar, 
    handleSave,
    prices,
    tipJarId,
    handlePriceChanged,
    priceTypes,
    handleAddNewPrice,
    handleRemove,
    handleSorting,
    currencyCodes
  } = props;

  const [tipjar, setTipjar] = useState(false);

  useEffect(() => {
    setTipjar(enabledTipJar)
  }, [enabledTipJar])

  const toggleTipJar = () => {
    setTipjar(!tipjar);
  }

  const handleSaveClicked = () => {
    handleSave({tipjar});
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row className="align-items-center justify-content-between">
            <div className="col ml-n2">
              <h4 className="mb-1">
                {
                  enabledTipJar ? <>Disable the Tip Jar</> : <>Enable the Tip Jar</>
                }
              </h4>
              <p className="small text-muted mb-1">
                {
                  enabledTipJar ? <>Turning off your tip jar will disable the Tip Jar for your subscribers. Any outstanding recurring tips will be canceled.</> 
                  : <>Enable the Tip Jar to start receiving donations from your listeners today, paid out to your bank account.</>
                }              
              </p>           
            </div>
            <div className="col-auto">
              <CustomInput 
                className="d-md-inline-flex"
                type="switch" 
                id="tipjar" 
                name="tipjarswitch"
                checked={tipjar}
                onChange={toggleTipJar} 
                label={tipjar ? "Tip Jar Enabled" : "Enable Tip Jar"} 
              />
            </div>            
            
          </Row>
        </CardBody>
      </Card>
      <TipjarPriceTable
        tipjar={tipjar}
        prices={prices}
        priceTypes={priceTypes}
        handlePriceChanged={handlePriceChanged}
        currencyCodes={currencyCodes}
        handleRemove={handleRemove}
        handleSorting={handleSorting}
      />
      {
        tipjar ? <Card>
        <CardBody className="text-center">
          <h2>New Price</h2>
          <Button color="warning" onClick={handleAddNewPrice}>
            <i className="fe fe-plus-circle"/> Add
          </Button>
        </CardBody>
        </Card> : null
      }
      
      { tipjar !== enabledTipJar || tipJarId ? <div><hr/><Button color="primary" block onClick={handleSaveClicked}>Save</Button><br/></div> : null}
    </>
  );
}

export default TipjarEnableCard;