import React, {useState, useEffect, useContext} from "react";
import { Card, CardBody, Button } from 'reactstrap';
import { useAtom } from 'jotai';
import {Context as ModalContext} from '../../context/ModalContext'
import {
  soundbitesAtom,
  deleteSoundbiteAtom,
  addSoundbiteAtom,
  saveSoundbitesAtom
} from './soundbitesAtom'
import SoundbiteInputCard from './SoundbiteInputCard'

const SoundbitesInputCard = (props) => {
  const {audiopostId} = props;
  const { add } = useContext(ModalContext);
  const [soundbites] = useAtom(soundbitesAtom);
  const [, deleteSoundbite] = useAtom(deleteSoundbiteAtom);
  const [, addSoundbite] = useAtom(addSoundbiteAtom);
  const [, saveSoundbites] = useAtom(saveSoundbitesAtom)

  const handleSave = () => {
    saveSoundbites(audiopostId)
  }

  return (
    <div>
      {
        soundbites.map((x, idx) => {
          return (
            <SoundbiteInputCard key={`soundbites-${idx+1}`}
              audiopostId={audiopostId}
              title={x.title}
              startTime={x.start_time}
              startTimeText={x.startTimeText}
              endTime={x.end_time}
              endTimeText={x.endTimeText}              
              idx={idx}
              id={x.id}
              handleDelete={deleteSoundbite}
            />                        
          )
        })
      }
      
      <Card onClick={addSoundbite} style={{cursor: 'pointer'}}>
        <CardBody>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <i className="fe fe-plus-circle" style={{"fontSize": 24}}/>
            Add new soundbite
          </div>
        </CardBody>
      </Card>    
      {props.children}  
      <Card>
        <CardBody>
          <Button onClick={handleSave} color="primary" name="Save" block>Save</Button>
        </CardBody>        
      </Card>      
    </div>
  )
}

export default SoundbitesInputCard;