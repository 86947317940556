import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../../api/justcast';
import {Link} from 'react-router-dom'
import {Context as MenuContext} from '../../context/MenuContext'
import {
  Row, Alert, Button,
  Card, CardHeader, CardBody,
  Modal, ModalBody, ModalHeader,
  ListGroup, ListGroupItem
} from 'reactstrap';

export default function AdLibrary() {
  const { setDynamicContentMenu } = useContext(MenuContext);
  const [files, setFiles] = useState([])

  useEffect(() => {
    justcastApi.get(`/v2/ad_contents.json`)
    .then((res) => {
      setDynamicContentMenu({
        title: 'Overview',
        subtitle: "Dynamic Contents",
        currentPageId: 'dynamic-contents',
        currentTabId: "dynamic-contents"
      });

      if(res.data.length > 0) {
        setFiles(res.data);
      }
    })
    .catch((err) => {
      console.log('hgg')
    })
  }, [])

  const handleDeleteClicked = (id) => {
    justcastApi.delete(`/v2/ad_contents/${id}.json`)
    .then((res) => {
      setFiles(files.filter((file) => file.id !== id))
    })
    .catch((err) => {

    })
  }

  return (
    <div className="container-fluid">
        <Row>
          <div className="col-12">
            <Card>
              <CardBody>
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted mb-2">Total files</h6>
                    <span className="h2 mb-0">{files.length}</span>
                  </div>
                  <div className="col-auto" style={{display: "flex", gap: "10px"}}>
                    <Link to="/dynamic-contents/new" className={`btn btn-sm btn-primary`}>Upload</Link>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="col-12">
            <Card>
              <CardHeader>
                <h4 className="card-header-title">Files</h4>
              </CardHeader>
              <CardBody>
                <ListGroup className="list-group list-group-flush list my-n3">
                  {
                    files.map((file) => {
                      return (
                        <ListGroupItem key={file.id}>
                          <Row className="align-items-center">
                            <div className="col ml-n2">
                              <h4 className="mb-1">
                                <Link to={`/dynamic-contents/${file.id}`}>{file.title}</Link>
                              </h4>
                            </div>
                            <div className="col-auto">
                              <div className="btn btn-danger btn-sm" onClick={() => {
                                handleDeleteClicked(file.id)
                              }}>
                                <i className="fe fe-trash-2"/> Delete
                              </div>
                            </div>
                          </Row>
                        </ListGroupItem>
                      )
                    })
                  }
                </ListGroup>
              </CardBody>
            </Card>
          </div>
        </Row>
    </div>
  )
}
