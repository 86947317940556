import React  from 'react';
import moment from 'moment';
import logoImg from './../assets/img/logo.png'

const ReceiptPageComponent = ({id, amount, email, receipt_date, stripe_id, card_brand, card_last4}) => {
  return (
    <div className="card card-body p-5">
      <div className="row">
        <div className="col text-center">        
          <img src={logoImg} alt="logo" className="img-fluid mb-4 receipt-page-logo" />
          <h2 className="mb-2">
            Receipt from JustCast
          </h2>
          <p className="text-muted mb-6">
            Invoice #{id}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">          
          <h6 className="text-uppercase text-muted">
            Invoiced from
          </h6>
          <p className="text-muted mb-4">
            <strong className="text-body">JustCast</strong> <br/>
            209 Juniper way<br/>          
            Montebello, CA
          </p>
          <h6 className="text-uppercase text-muted">
            Invoiced ID
          </h6>
          <p className="mb-4">
            #{id}
          </p>
        </div>
        <div className="col-12 col-md-6 text-md-right">
          <h6 className="text-uppercase text-muted">
            Invoiced to
          </h6>
          <p className="text-muted mb-4">
            <strong className="text-body">{email}</strong>
            <br/>
            <br/>
            <br/>
          </p>

          <h6 className="text-uppercase text-muted">
            Receipt date
          </h6>
          <p className="mb-4">
            <time dateTime={moment(receipt_date).format('MM-DD-YYYY')}>{moment(receipt_date).format('MMM DD, YYYY')}</time>
          </p>
          
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table my-4">              
              <tbody>
                <tr>
                  <td className="px-0 border-top border-top-2">
                    Transaction ID
                  </td>
                  <td colSpan="2" className="px-0 text-right border-top border-top-2">
                    {stripe_id}
                  </td>
                </tr>
                <tr>
                  <td className="px-0 border-top border-top-2">
                    Charged to
                  </td>
                  <td colSpan="2" className="px-0 text-right border-top border-top-2"> {card_brand} (**** **** **** {card_last4})</td>
                </tr>
                <tr>
                  <td className="px-0 border-top border-top-2">
                    <strong>Total amount</strong>
                  </td>
                  <td colSpan="2" className="px-0 text-right border-top border-top-2">
                    <span className="h3">
                      $ {amount/100}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr className="my-5"/>
          
          
          <h6 className="text-uppercase">
            Notes
          </h6>          
          <p className="text-muted mb-0">
            We really appreciate your business and if there’s anything else we can do, please let us know!
          </p>

        </div>
      </div>
    </div>
  )
}

export default ReceiptPageComponent