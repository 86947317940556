import React, {useEffect, useState, useContext} from "react";
import { Button, Spinner, Progress, } from 'reactstrap';
import justcastApi from '../../../api/justcast';
import {Context as MenuContext} from '../../../context/MenuContext'
import {Context as ScreenContext} from '../../../context/ScreenContext'
import {AudioPreviewPlayer, DownloadFileButton, ToastMessage} from '../../../components/transcript-segments'
import ModalMessage from '../../../components/ModalMessage'

const TranscriptSegments = (props) => {
  const {audiopost_id, transcript_id} =  props.match.params;
  const {setAiContentPageMenu} = useContext(MenuContext);
  const screenContext = useContext(ScreenContext);
  const [episode, setEpisode] = useState(null);
  const [title, setTitle] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [textUrl, setTextUrl] = useState(null)
  const [srtUrl, setSrtUrl] = useState(null)
  const [vttUrl, setVttUrl] = useState(null)
  const [toastOpen, setToastOpen] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [toastTitle, setToastTitle] = useState(null);
  const [toastMsg, setToastMsg] = useState(null);
  const [isSuccessed, setIsSuccessed] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [modalTranscript, setModalTranscript] = useState({});
  const [transcript, setTranscript] = useState({});

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v2/audioposts/${audiopost_id}/transcripts/${transcript_id}/transcript_sentences`)
    .then((res) => {
      screenContext.finishFetching();
      const {
        show,
        audiopost,
        paragraphs,
        transcript_txt_url,
        transcript_srt_url,
        transcript_vtt_url,
        is_successed,
        is_failed,
        is_processing,
        is_completed,
        words_start_time,
        words_id
      } = res.data;

      setTextUrl(transcript_txt_url)
      setSrtUrl(transcript_srt_url)
      setVttUrl(transcript_vtt_url)
      setEpisode(audiopost);

      setTitle(audiopost.name);
      setAudioUrl(audiopost.audio_src);
      setIsSuccessed(is_successed);
      setIsFailed(is_failed);
      setIsProcessing(is_processing)
      setIsCompleted(is_completed)

      setTranscript({
        paragraphs,
        wordsStartTime: words_start_time,
        wordsId: words_id
      })

      setAiContentPageMenu({
        title: "Transcript",
        currentPageId: "episodes",
        currentTabId: "segments",
        // subtitle: audiopost.name,
        subtitle: `${show.podcast_title} > ${audiopost.name}`,
        showName: show.podcast_title,
        showId: show.id,
        episodeId: audiopost_id,
        transcriptId: transcript_id,
        landingPageUrl: show.landing_page_url,
        websiteUrl: show.player_page_link,
        rssFeed: show.rss_feed,
        passwordProtected: show.is_password_protected,
        advancedPrivate: show.is_advanced_private_feed,
        isPrivate: show.is_private,
        isPrivateShow: show.is_private_show,
        skill_podcast_website: show.skill_podcast_website
      })
    })
    .catch((err) => {
      screenContext.finishFetching();
    })
  }, [])

  const toggleToast = () => {
    setToastOpen(!toastOpen)
  }

  const toggleModal = () => {
    setModalTranscript({})
  }

  const handleUpdateWord = (word_id, text) => {
    justcastApi.put(`/v2/audioposts/${audiopost_id}/transcripts/${transcript_id}/transcript_sentences/${word_id}`, {
      text
    })
    .then((res) => {
      const { words_start_time, words_id, paragraphs} = res.data;
      setTranscript({
        wordsStartTime: words_start_time,
        wordsId: words_id,
        paragraphs: paragraphs
      })

      if(timeoutId) {
        clearTimeout(timeoutId)
      };

      setToastOpen(true)
      setToastTitle('Success')
      setToastMsg('Update Successful!')

      const _timeOutId = setTimeout(() => {
        setToastOpen(false);
      }, 2000)

      setTimeoutId(_timeOutId)
    })
    .catch((err) => {
      setToastOpen(true)
      setToastTitle('Failed')
      setToastMsg('Something went wrong!')
    })
  }

  const handleDeleteWord = (word_id) => {
    justcastApi.delete(`/v2/audioposts/${audiopost_id}/transcripts/${transcript_id}/transcript_sentences/${word_id}`)
    .then((res) => {
      const { words_start_time, words_id, paragraphs} = res.data;
      setTranscript({
        wordsStartTime: words_start_time,
        wordsId: words_id,
        paragraphs: paragraphs
      })

      if(timeoutId) {
        clearTimeout(timeoutId)
      };

      setToastOpen(true)
      setToastTitle('Success')
      setToastMsg('Delete Successful!')

      const _timeOutId = setTimeout(() => {
        setToastOpen(false);
      }, 2000)

      setTimeoutId(_timeOutId)
    })
    .catch((err) => {
      setToastOpen(true)
      setToastTitle('Failed')
      setToastMsg('Something went wrong!')
    })
  }

  const handleUpdateSpeaker = (data) => {
    justcastApi.post(`/v2/audioposts/${audiopost_id}/transcripts/${transcript_id}/speakers`, data).then((res) => {
      const { words_start_time, words_id, paragraphs} = res.data;
      setTranscript({
        wordsStartTime: words_start_time,
        wordsId: words_id,
        paragraphs: paragraphs
      })

      if(timeoutId) {
        clearTimeout(timeoutId)
      };

      setToastOpen(true)
      setToastTitle('Success')
      setToastMsg('Updated Speaker name!')

      const _timeOutId = setTimeout(() => {
        setToastOpen(false);
      }, 2000)

      setTimeoutId(_timeOutId)
    }).catch((err) => {
      setToastOpen(true)
      setToastTitle('Failed')
      setToastMsg('Something went wrong!')
    })
  }

  if(screenContext.state.loading) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  if(isFailed) {
    return (
      <div className="container-fluid" style={{paddingBottom: 20}}>
        <ModalMessage
          title={modalTranscript.title}
          isOpen={modalTranscript.isOpen}
          toggle={toggleModal}>
          <p>{modalTranscript.message}</p>
        </ModalMessage>
        <p>Something went wrong.  Please screenshot this and email to us.</p>
      </div>
    )
  }

  if(isProcessing) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  if(!transcript.paragraphs) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  if(isCompleted && episode && audioUrl) {
    return (
      <div>
        <ToastMessage
          title={toastTitle}
          message={toastMsg}
          show={toastOpen}
          toggle={toggleToast}
        />
       <AudioPreviewPlayer
          title={title}
          url={audioUrl}
          transcript={transcript}
          handleUpdateWord={handleUpdateWord}
          handleDeleteWord={handleDeleteWord}
          handleUpdateSpeaker={handleUpdateSpeaker}
        >
          <div style={{marginBottom: 20, display: 'flex', gap: 8, justifyContent: 'flex-end'}}>
            <DownloadFileButton
              label="Download Text"
              url={textUrl}
              fileName={`${title} transcript.txt`}
              fileType="text/plain"
            />
            <DownloadFileButton
              url={srtUrl}
              fileName={`${title} transcript.srt`}
              fileType="application/x-subrip"
              label="SRT"
            />
            <DownloadFileButton
              url={vttUrl}
              fileName={`${title} transcript.vtt`}
              fileType="text/vtt"
              label="VTT"
            />
          </div>
        </AudioPreviewPlayer>
      </div>
    )
  }

  return null;
}

export default TranscriptSegments;