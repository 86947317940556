import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';

const LeftSideMenuItem = ({name, link, target, active}) => {
  if(target === '_blank') {
    return (
      <NavItem>
        <NavLink onClick={() => {
          window.open(link)
        }}>
          {name}
        </NavLink>
      </NavItem>      
    )
  }
  return (
    <NavItem active={active}>
      <NavLink to={link} tag={Link}>
        {name}
      </NavLink>
    </NavItem>
  )
}

export default LeftSideMenuItem;