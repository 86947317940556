import React from "react";
import { Input } from 'reactstrap';

export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length

  return (
    <Input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      bsSize="sm"
      placeholder={`Search ${count} records...`}
    />
  )
}
