import React, {useState} from "react";
import { SketchPicker } from 'react-color'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',    
  })
}

const swatch = {
  padding: '4px',
  background: '#fff',
  borderRadius: '4px',
  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  display: 'inline-block',
  cursor: 'pointer',  
}

const popover = {
  position: 'absolute',
  zIndex: '2',  
}

const cover = {
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
}


const ColorPickerControl = ({color, handleChange, displayColorPicker, handleClick}) => {
  if(displayColorPicker) {
    return (
      <div style={popover}>
        <div style={ cover } onClick={ handleClick }/>
        <SketchPicker color={ color } onChange={ handleChange } />
      </div>
    )
  }
  
  return null;
}

const SimpleColorPicker = ({
  color, 
  name, 
  handleColorChange, 
  id, 
  width, 
  height
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  }

  const handleChange = (selectedColor) => {
    handleColorChange({[id]: selectedColor.hex})
  }  

  return (
    <>
      <div style={swatch} onClick={handleClick}>
        <div style={ {
          backgroundColor: color ? color : "#fff",
          width: '32px',
          height: '31px',
          borderRadius: '4px'
        } } />
      </div>  
      <ColorPickerControl color={color ? color : "#fff"} handleChange={handleChange} displayColorPicker={displayColorPicker} handleClick={handleClick}/>    
    </>
  )
}

export default SimpleColorPicker