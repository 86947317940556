import React from 'react'
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

export default function PodcastSenderToast({toggle, show, title, message}) {

  return (
    <Toast isOpen={show}>
      <ToastHeader toggle={toggle}>
        {title}
      </ToastHeader>
      <ToastBody>
        {message}
      </ToastBody>
    </Toast>
  )
}
