import React from "react";
import { Media } from 'reactstrap';

const SimpleMedia = ({img, imgAltName, headingLabel, bodyText, handleRemoveFile}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-auto">
            <img src={img} className="rounded artwork-img"/>
          </div>
          <div className="col ml-n2">
            <h4 className="mb-1">{headingLabel}</h4>
            <div className="small mb-1">
              {bodyText}
            </div>
            {handleRemoveFile && <div className="btn btn-outline-danger btn-sm" onClick={handleRemoveFile}>Remove artwork</div>}
          </div>
        </div>
      </div>      
    </div>
  )
}

export default SimpleMedia;