import React, {useEffect, useState, useContext} from "react";
import { Redirect } from "react-router-dom";
import queryString from 'query-string'
import { Spinner } from 'reactstrap';
import justcastApi from '../api/justcast'
import {Context as AlertContext} from '../context/AlertContext'

const StripeAccountConnectFailed = (props)  => {
  // const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(true);
  // const alertContext = useContext(AlertContext);
  const {addWithTimeout, add} = useContext(AlertContext);

  if(redirect) {
    
    setTimeout(() => {
      addWithTimeout({color: 'info', message: `Failed to connect to Stripe.  Please try again.`, isOpen: true, timeout: 3000})
    }, 300);

    return <Redirect to="/connections"/>
  }

  return (<></>)   
}

export default StripeAccountConnectFailed;