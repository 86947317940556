import React, {useEffect, useState, useContext } from "react";
import { Row, Col, Form, FormGroup, Button, ButtonGroup, ButtonToolbar,
  Label, Input, InputArea, Card, CardBody, CardHeader,
  InputGroup, InputGroupText, InputGroupAddon
} from 'reactstrap';
import { AlignCenter, AlignLeft, AlignRight, Bold, Italic } from 'react-feather';
import CreatableSelect from 'react-select/creatable';
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import ColorPicker from './ColorPicker'
import PanelAddNewItem from './PanelAddNewItem'
import webSafeFonts from "../../api/webSafeFonts";

const anchorList = ['start', 'middle', 'end'];
const anchorTextIcon = ['AlignLeft', 'AlignCenter', 'AlignRight'];
const fontSize = ['12', '24', '36', '48', '60', '72', '96'];
// an control bar on top
// add list of text

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.isSelected ? 'white' : 'blue',
  })
}

const createOption = (label) => {
  return {
    label: label.split(', ').length > 0 ? label.split(', ')[0] : label,
    // value: label.toLowerCase().replace(/\W/g, ''),
    value: label
  }
};

const TextConfig = ({value, optionsList, handleChange, handleOnFocus}) => {  
  const defaultOptions = optionsList.map((item) => createOption(item));  
  const [options, setOptions] = useState(defaultOptions);
  
  //const [value, setValue] = useState(undefined);
  
  return (
    <div>
      <CreatableSelect
        isClearable
        options={options}
        value={createOption(value)}
        onChange={(e) => {
          if(e && e.value) {
            handleChange(e.value.toString())
          }
        }}
        onMenuOpen={() => {
          handleOnFocus(true);
        }}      
        styles={customStyles}
        onCreateOption={(val) => {    
          const newOption = createOption(val);
          setOptions([...options, newOption])
          handleChange(val.toString())
        }}
      /> 
    </div>
  )
}

const PanelTextTab = () => {
  const defaultText = {
    id:"", name: "", fontSize: "24", color: "#fff", x: "10", y: "40", maxLineLength: 0, width: "", height: "", textAnchor: "start", textAnchorAdjustVal: 0, aligIcon: 'AlignLeft', fontWeight: "normal", fontStyle: "normal", fontFamily: "Roboto"
  };

  const getRandomInt = () => {
    return Math.floor(Math.random() * Math.floor(10000)).toString() + "|" + Date.now().toString();
  }
  
  const {state, updateState} = useContext(PodieoAudioContext); 
  const {selectingObject, demoSvgRatio} = state;

  const [selectingText, setSelectedText] = useState(defaultText);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  
  const [hideInputTextDelete, setHideInputTextDelete] = useState(false);

  useEffect(() => {
    if(selectingObject.type === "texts" && selectingObject.id) {
      const textIndex = state.texts.findIndex(t => t.id === selectingObject.id);
      if(textIndex > -1) {
        const text = state.texts[textIndex];
        setSelectedText(text);
      }
    }
  }, [selectingObject.id])

  const handleDeleted = (id) => {
    const textIndex = state.texts.findIndex(t => t.id === id);
    if(textIndex > -1) {
      //const text = state.texts[textIndex];
      const texts = state.texts;
      texts.splice(textIndex, 1);
      updateState({texts})
    }
    if(state.selectingObject.type === "texts" && state.selectingObject.id === id) {
      updateState({selectingObject: {type: null, id: null}})
    }
  }

  const handleTextInputClicked = (id) => {
    // getIndexTextById(x)
    const textIndex = state.texts.findIndex(t => t.id === id);
    if(textIndex > -1) {
      const text = state.texts[textIndex];
      updateState({selectingObject: {type: "texts", id}})
      setSelectedText(text);
    }
  }

  const handleInputChanged = (id, name) => {
    setSelectedText({...selectingText, ...name})
    const textIndex = state.texts.findIndex(t => t.id === id);
    if(textIndex > -1) {
      const text = state.texts[textIndex];      
      const maxLineLength = Math.max(...(name.name.split("\n").map((x) => x.length)));      
      const width = maxLineLength * Number(text.fontSize) * demoSvgRatio / 1.5
      const newText = {...text, ...name, maxLineLength, width};
      const texts = state.texts;
      texts[textIndex] = newText;
      updateState({texts})
    }
  }

  const handleFontSizeChanged = (fontSize) => {
    // console.log(fontSize)
    const textIndex = state.texts.findIndex(t => t.id === selectingText.id);
    if(textIndex > -1) {
      const text = state.texts[textIndex];
      const width = text.maxLineLength * Number(text.fontSize) * demoSvgRatio / 1.5 ;
      const newText = {...text, fontSize, width};

      const texts = state.texts;
      texts[textIndex] = newText;
      updateState({texts});
      setSelectedText(newText);      
    }    
    setHideInputTextDelete(false);
  }

  const handleFontFamilyChange = (fontFamily) => {
    const textIndex = state.texts.findIndex(t => t.id === selectingText.id);
    if(textIndex > -1) {
      const text = state.texts[textIndex];
      const newText = {...text, fontFamily};
      const texts = state.texts;
      texts[textIndex] = newText;
      updateState({texts});
      setSelectedText(newText);
    }
    setHideInputTextDelete(false);
  }

  const handleColorChanged = (x) => {      
    const textIndex = state.texts.findIndex(t => t.id === selectingText.id);
    if(textIndex > -1) {      
      const text = state.texts[textIndex];
      const newText = {...text, ...x};
      const texts = state.texts;
      texts[textIndex] = newText;
      updateState({texts})
      setSelectedText(newText);
    } 
    // const newChange = {...selectingText, ...x};  
    // setSelectedText(newChange);    
  }

  const handleAddNewTextClicked = () => {
    const id = getRandomInt()
    // add it to context too
    setSelectedText({...defaultText, id})
    updateState({selectingObject: {type: "texts", id},  texts: [...state.texts, {...defaultText, id}]})
  }

  const handleAnchorChange = (val) => {
    // start -> middle : x = x + width * 0.5
    // start -> end : x = x + width * 1
    // middle -> start : x = x - width * 0.5
    // middle -> end : x = x + width * 0.5
    // end -> middle : x = x - width * 0.5
    // end -> start : x = x - width * 1
    const textIndex = state.texts.findIndex(t => t.id === selectingObject.id);
    if(textIndex > -1) {
      const k = 0.5;    
      const texts = state.texts;
      const text = texts[textIndex];
      
      
      const width = text.maxLineLength * Number(text.fontSize) * demoSvgRatio / 1.5 ;
      
      // const width = text.width > 1 ? Number(text.width) : text.maxLineLength * Number(text.fontSize) * demoSvgRatio / 1.5 ;
      const toIdx = anchorList.indexOf(val);
      const aligIcon = anchorTextIcon[toIdx] // 'AlignLeft'
      const fromIdx = anchorList.indexOf(text.textAnchor);
      
      const textAnchorAdjustVal = (toIdx - fromIdx) * k;
      const x = Number(text.x) + textAnchorAdjustVal * width;
      const newText = {...text, ...{x, textAnchorAdjustVal, textAnchor: val, width, aligIcon}};
      texts[textIndex] = newText;
      setSelectedText(newText)
      updateState({texts});
    }
  }

  const handleTextBoldClick = () => {
    const textIndex = state.texts.findIndex(t => t.id === selectingObject.id);
    if(textIndex > -1) {
      const texts = state.texts;
      const text = texts[textIndex];      

      let fontWeight = "normal";
      if(text.fontWeight === "normal") {
        fontWeight = "bolder"
      }
      
      const newText = {...text, fontWeight}
      texts[textIndex] = newText;
      setSelectedText(newText)
      updateState({texts});      
    }
  }

  const handleTextItalicClick = () => {
    const textIndex = state.texts.findIndex(t => t.id === selectingObject.id);
    if(textIndex > -1) {
      const texts = state.texts;
      const text = texts[textIndex];      

      let fontStyle = "normal";
      if(text.fontStyle === "normal") {
        fontStyle = "italic"
      }

      const newText = {...text, fontStyle}
      texts[textIndex] = newText;
      setSelectedText(newText)
      updateState({texts});      
    }

  }

  return (
    <>
      {
        selectingObject.type === "texts" && selectingObject.id ? <>
          <Row>
            <Col sm="8">
              <TextConfig 
                handleOnFocus={setHideInputTextDelete}
                optionsList={fontSize}
                value={selectingText.fontSize}
                handleChange={handleFontSizeChanged}
              />
            </Col>
            <Col sm="4" style={{"display": "flex", "justifyContent": "center", "alignItems":"center", "alignItems": "center"}}>
              <ColorPicker 
                color={selectingText.color ? selectingText.color : "#fff"}
                handleColorChange={handleColorChanged}       
                tagName="color"
                width="48px"
                height="26px"
                displayColorPicker={displayColorPicker}
                setDisplayColorPicker={setDisplayColorPicker}
              />
            </Col>        
          </Row>
          <br/>
          <Row>
            <Col sm="12">
              <TextConfig 
                handleOnFocus={setHideInputTextDelete}
                optionsList={webSafeFonts}
                value={selectingText.fontFamily}
                handleChange={handleFontFamilyChange}
              />              
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="8">
              <ButtonToolbar>
                <ButtonGroup>
                  {
                    anchorList.map((item, itemId) =>      
                      <Button 
                        outline={item === selectingText.textAnchor ? false : true}
                        color="warning"
                        onClick={() => {
                          handleAnchorChange(item)
                        }}
                        key={itemId.toString()}
                      >
                        <TextAlignmentIcon id={itemId}/>
                      </Button>
                    )
                  }
                </ButtonGroup>
              </ButtonToolbar>              
            </Col>
            <Col sm="4">
              <ButtonToolbar>
                <ButtonGroup>
                  <Button
                    color="warning"
                    outline={selectingText.fontStyle === "normal" ? true : false}
                    onClick={handleTextItalicClick}
                  >
                    <StyleButtonIcon name={'italic'}/>
                  </Button>
                  <Button
                    color="warning"
                    outline={selectingText.fontWeight === "normal" ? true : false}
                    onClick={handleTextBoldClick}
                  ><StyleButtonIcon name={'bolder'}/></Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>          
          <hr/>        
        </> : null
      }
      

      {state.texts.map((text, index) => 
        <TextInputItem          
          onClicked={handleTextInputClicked}
          handleDelete={handleDeleted}
          name={text.name}
          fontSize={text.fontSize}
          color={text.color}
          x={text.x}
          y={text.y}
          width={text.width}
          height={text.height}
          handleInputChange={handleInputChanged}
          key={text.id}
          id={text.id}
          selectedElementId={selectingObject.id}
          displayColorPicker={displayColorPicker || hideInputTextDelete}
        />
        )
      }
      <PanelAddNewItem title={"Add new Text"} handleClick={handleAddNewTextClicked}/>
    </>
  )
}

const TextAlignmentIcon = ({id}) => {
  if(anchorTextIcon[id] === 'AlignLeft') {
    return <AlignLeft size={16}/>
  }
  if(anchorTextIcon[id] === 'AlignRight') {
    return <AlignRight size={16}/>
  }   
  if(anchorTextIcon[id] === 'AlignCenter') {
    return <AlignCenter size={16}/>
  }
  return null;   
}

const StyleButtonIcon = ({name}) => {
  if(name === 'bolder') {
    return <Bold size={16}/>
  }
  if(name === 'italic') {
    return <Italic size={16}/>
  }
  return null;
}

const TextInputItem = ({
  name, fontSize, color, x, y, width, 
  height, handleDelete, onClicked, 
  handleInputChange, id, displayColorPicker, selectedElementId
}) => { 
  return (
    <>
      <InputGroup>
        <Input 
          onFocus={() => {
            onClicked(id)
          }}
          valid={id === selectedElementId ? true : false}
          type="textarea"
          value={name ? name : ""}
          onChange={(e) => {
            // console.log(e.target.value.replace(/(?:\r\n|\r|\n)/g, 'hhhh'))
            handleInputChange(id, {name: e.target.value})
          }}
        />
        {
          !displayColorPicker ? (
            <InputGroupAddon addonType="append">          
              <Button 
                onClick={() => {
                  handleDelete(id)
                }}            
              ><span className="fe fe-trash"/></Button>
            </InputGroupAddon>              
          ) : null
        }
         
      </InputGroup>
      <br/>
    </>
  )
}

export default PanelTextTab;