import React, {useEffect, useState, useContext} from "react";
import { Card, CardBody, Spinner, Progress} from 'reactstrap';
import justcastApi from '../../../api/justcast';
import {Context as MenuContext} from '../../../context/MenuContext'
import {Context as ScreenContext} from '../../../context/ScreenContext'
import {AudioPreviewPlayer, DownloadFileButton, ToastMessage, SpeakerForm} from '../../../components/transcript-segments'
import ModalMessage from '../../../components/ModalMessage'

export default function Transcript(props) {
  const {show_id, id} = props.match.params;
  const [transcriptId, setTranscriptId] = useState(null);
  const menuContext = useContext(MenuContext);
  const screenContext = useContext(ScreenContext);
  const [toastOpen, setToastOpen] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [missingTranscript, setMissingTranscript] = useState(false);
  const [episode, setEpisode] = useState(null);
  const [title, setTitle] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [textUrl, setTextUrl] = useState(null)
  const [srtUrl, setSrtUrl] = useState(null)
  const [vttUrl, setVttUrl] = useState(null)
  const [wordStartTimes, setWordStartTimes] = useState([]);
  const [isSuccessed, setIsSuccessed] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [toastTitle, setToastTitle] = useState(null);
  const [toastMsg, setToastMsg] = useState(null);
  const [modalTranscript, setModalTranscript] = useState({});
  const [transcript, setTranscript] = useState({});

  useEffect(() => {
    justcastApi.get(`/v2/team_podcasts/${show_id}/team_audioposts/${id}/transcript`)
    .then((res) => {
      screenContext.finishFetching();
      const {
        show,
        audiopost,
        paragraphs,
        transcript_txt_url,
        transcript_srt_url,
        transcript_vtt_url,
        is_successed,
        is_failed,
        is_processing,
        is_completed,
        words_start_time,
        words_id
      } = res.data;

      setTranscriptId(res.data.id);
      setTextUrl(transcript_txt_url)
      setSrtUrl(transcript_srt_url)
      setVttUrl(transcript_vtt_url)
      setEpisode(audiopost);

      setTitle(audiopost.name);
      setAudioUrl(audiopost.audio_src);
      setIsSuccessed(is_successed);
      setIsFailed(is_failed);
      setIsProcessing(is_processing)
      setIsCompleted(is_completed)

      setTranscript({
        paragraphs,
        wordsStartTime: words_start_time,
        wordsId: words_id
      })

      menuContext.setTeamEpisodePageMenus({
        title: 'Transcript',
        subtitle: `${show.podcast_title} > ${audiopost.name}`,
        currentPageId: 'episode',
        currentTabId: 'transcript',
        showId: show_id,
        episodeId: audiopost.id,
        landingPageUrl: show.landing_page_url,
        websiteUrl: show.player_page_link,
        rssFeed: show.rss_feed,
        passwordProtected: show.is_password_protected,
        advancedPrivate: show.is_advanced_private_feed,
        isPrivate: show.is_private,
        isPrivateShow: show.is_private_show,
      });
    })
    .catch((err) => {
      const {show, audiopost} = err.response.data;
      screenContext.finishFetching();
      if(show && audiopost) {
        menuContext.setTeamEpisodePageMenus({
          title: 'Transcript',
          subtitle: `${show.name} > ${audiopost.name}`,
          currentPageId: 'episode',
          currentTabId: 'transcript',
          showId: show_id,
          episodeId: audiopost.id,
          landingPageUrl: show.landing_page_url,
          websiteUrl: show.player_page_link,
          rssFeed: show.rss_feed,
          passwordProtected: show.is_password_protected,
          advancedPrivate: show.is_advanced_private_feed,
          isPrivate: show.is_private,
          isPrivateShow: show.is_private_show,
        });
        setMissingTranscript(true);
      }
    })
  }, [show_id, id])

  const toggleToast = () => {
    setToastOpen(!toastOpen)
  }

  const toggleModal = () => {
    setModalTranscript({})
  }

  const handleCreateTranscript = (data, event) => {
    event.preventDefault()
    justcastApi.post(`/v2/team_podcasts/${show_id}/team_audioposts/${id}/team_transcripts`, data)
    .then((res) => {
      setModalTranscript({
        isOpen: true,
        message: "Feel free to close this window. Typically, a one-hour podcast takes 12 minutes to transcribe. Please check back later to review your transcript.",
        title: "Preparing your transcript..."
      })
      setIsProcessing(true)
      setMissingTranscript(false);
    })
    .catch((err) => {
      setIsFailed(true)
      setMissingTranscript(false);
      setModalTranscript({
        isOpen: true,
        message: err?.response?.data?.messages,
        title: "Warning"
      })
    })
  }

  const handleUpdateWord = (word_id, text) => {
    justcastApi.put(`/v2/team_podcasts/${show_id}/team_audioposts/${id}/team_transcripts/${transcriptId}/team_transcript_sentences/${word_id}`, {
      text
    })
    .then((res) => {
      const { words_start_time, words_id, paragraphs} = res.data;
      setTranscript({
        wordsStartTime: words_start_time,
        wordsId: words_id,
        paragraphs: paragraphs
      })

      if(timeoutId) {
        clearTimeout(timeoutId)
      };

      setToastOpen(true)
      setToastTitle('Success')
      setToastMsg('Update Successful!')

      const _timeOutId = setTimeout(() => {
        setToastOpen(false);
      }, 2000)

      setTimeoutId(_timeOutId)
    })
    .catch((err) => {
      setToastOpen(true)
      setToastTitle('Failed')
      setToastMsg('Something went wrong!')
    })
  }

  const handleDeleteWord = (word_id) => {
    justcastApi.delete(`/v2/team_podcasts/${show_id}/team_audioposts/${id}/team_transcripts/${transcriptId}/team_transcript_sentences/${word_id}`)
    .then((res) => {
      const { words_start_time, words_id, paragraphs} = res.data;
      setTranscript({
        wordsStartTime: words_start_time,
        wordsId: words_id,
        paragraphs: paragraphs
      })

      if(timeoutId) {
        clearTimeout(timeoutId)
      };

      setToastOpen(true)
      setToastTitle('Success')
      setToastMsg('Delete Successful!')

      const _timeOutId = setTimeout(() => {
        setToastOpen(false);
      }, 2000)

      setTimeoutId(_timeOutId)
    })
    .catch((err) => {
      setToastOpen(true)
      setToastTitle('Failed')
      setToastMsg('Something went wrong!')
    })
  }

  const handleUpdateSpeaker = (data) => {
    justcastApi.post(`/v2/team_podcasts/${show_id}/team_audioposts/${id}/team_transcripts/${transcriptId}/speakers`, data).then((res) => {
      const { words_start_time, words_id, paragraphs} = res.data;
      setTranscript({
        wordsStartTime: words_start_time,
        wordsId: words_id,
        paragraphs: paragraphs
      })

      if(timeoutId) {
        clearTimeout(timeoutId)
      };

      setToastOpen(true)
      setToastTitle('Success')
      setToastMsg('Updated Speaker name!')

      const _timeOutId = setTimeout(() => {
        setToastOpen(false);
      }, 2000)

      setTimeoutId(_timeOutId)
    }).catch((err) => {
      setToastOpen(true)
      setToastTitle('Failed')
      setToastMsg('Something went wrong!')
    })
  }

  if(screenContext.state.loading) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  if(isSuccessed && episode) {
    return (
      <>
        <ToastMessage
          title={toastTitle}
          message={toastMsg}
          show={toastOpen}
          toggle={toggleToast}
        />
        <AudioPreviewPlayer
          title={title}
          url={audioUrl}
          transcript={transcript}
          handleUpdateWord={handleUpdateWord}
          handleDeleteWord={handleDeleteWord}
          handleUpdateSpeaker={handleUpdateSpeaker}
          >
          <div style={{marginBottom: 20, display: 'flex', gap: 8, justifyContent: 'flex-end'}}>
            <DownloadFileButton
              label="Download Text"
              url={textUrl}
              fileName={`${title} transcript.txt`}
              fileType="text/plain"
            />
            <DownloadFileButton
              url={srtUrl}
              fileName={`${title} transcript.srt`}
              fileType="application/x-subrip"
              label="SRT"
            />
            <DownloadFileButton
              url={vttUrl}
              fileName={`${title} transcript.vtt`}
              fileType="text/vtt"
              label="VTT"
            />
          </div>
        </AudioPreviewPlayer>
      </>
    )
  }

  if(isFailed) {
    return (
      <div className="container-fluid" style={{paddingBottom: 20}}>
        <ModalMessage
          title={modalTranscript.title}
          isOpen={modalTranscript.isOpen}
          toggle={toggleModal}>
          <p>{modalTranscript.message}</p>
        </ModalMessage>
        <p>Something went wrong.  Please screenshot this and let us know.</p>
      </div>
    )
  }

  if(missingTranscript) {
    return (
      <div className="container-fluid" style={{paddingBottom: 20}}>
        <Card>
          <CardBody>
            <SpeakerForm
              handleSave={handleCreateTranscript}
            />
          </CardBody>
        </Card>
      </div>
    )
  }

  if(isProcessing) {
    return (
      <div className="container-fluid" style={{paddingBottom: 20}}>
        <ModalMessage
          title={modalTranscript.title}
          isOpen={modalTranscript.isOpen}
          toggle={toggleModal}>
          <p>{modalTranscript.message}</p>
        </ModalMessage>
        <Progress animated value={42}/>
      </div>
    )
  }

  return null;
}
