import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Button } from 'reactstrap';
import queryString from 'query-string'
import {Context as AuthContext} from '../context/AuthContext'

const ResetPassword = (props) => {
  const [reauth, setReauth] = useState({'accessToken':'', 'uid': '', 'client': '', 'password': '', 'passwordConfirmation':''})
  const {resetPassword} = useContext(AuthContext);

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    setReauth({
      ...reauth,
      'accessToken': values['access-token'],
      'uid': values['uid'],
      'client': values['client']
    })
  },[])

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-5 col-xl-4 my-5">
          <h1 className="display-4 text-center mb-3">Set new password</h1>
          <Form onSubmit={(event) => {
            resetPassword(reauth);
            event.preventDefault();
            }}>
              <FormGroup>
                <label>New password</label>
                <Input type="password" 
                  name="new_password"
                  placeholder="New password"
                  value={reauth.password} 
                  onChange={(event) => {
                  setReauth({...reauth, password: event.target.value})           
                }}/>
              </FormGroup>
              <FormGroup>
                <label>Confirm new password</label>
                <Input type="password" 
                  name="confirm_new_password"
                  placeholder="Confirm new password"
                  value={reauth.passwordConfirmation} 
                  onChange={(event) => {
                  setReauth({...reauth, passwordConfirmation: event.target.value})           
                }}/>
              </FormGroup>
              <Button color="primary"
                type="submit"
                className="btn btn-primary btn-block mt-3" 
              >Set new password</Button>
          </Form>
          <br/>
          <div className="text-center">
            <small className="text-muted text-center">
              <Link to="/signin">Go to login page</Link>
            </small>            
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword