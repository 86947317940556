import React, {useState, useEffect} from 'react'
import justcastApi from '../../api/justcast'

export default function PricingTable({email, userId, planName, customerId, pricingTableId}) {

  const [sessionId, setSessionId] = useState(null)
  const [newCustomer, setNewCustomer] = useState(null)
  const [loading, setLoading] = useState(true);

  const getSessionKey = () => {
    justcastApi.get('/v2/stripe_customer_session')
    .then((res) => {
      if(res?.data?.client_secret) {
        setSessionId(res.data.client_secret)
      } else {
        setNewCustomer(true)
      }
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
      setNewCustomer(true)
    })
  }

  useEffect(() => {
    if(email && userId) {
      if(!planName) {
        if(customerId) {
          getSessionKey()
        } else {
          setLoading(false);
          setNewCustomer(true)
        }
      }
    }
  }, [])

  if(loading) {
    return null
  } else if (sessionId) {
    return <stripe-pricing-table
      // pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
      pricing-table-id={pricingTableId}
      publishable-key={process.env.REACT_APP_STRIPE_API_KEY}
      client-reference-id={userId} // this wil be our internal user_id
      customer-session-client-secret={sessionId}
    />
  } else if(newCustomer) {
    return <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={process.env.REACT_APP_STRIPE_API_KEY}
      client-reference-id={userId} // this wil be our internal user_id
      customer-email={email}
    />
  }

  return null;
}
