import React, {useState, useEffect} from "react";
import { Card, CardBody, Button, Col, Row, FormGroup, InputGroup, Input, InputGroupAddon, FormFeedback, FormText} from 'reactstrap';
import { useAtom } from 'jotai';
// import {} from './chaptersAtom'
import {
  playerAtom,
  playInSecondsAtom
} from './playerAtom'
import { chapterChangeAtom } from './chaptersAtom'
import {checkHHMMSS, getHHMMSSFromSeconds} from '../../libs/timeTransformation'
import ChapterInputField from './ChapterInputField'
import ChapterDelete from './ChapterDelete'

const ChapterInputCard = (props) => {
  const [player] = useAtom(playerAtom);
  const {playedSeconds} = player;
  const [startTimeInputValue, setStartTimeInputValue] = useState('');
  const [initStartTime, setInitStartTime] = useState(true);

  const {audiopostId, title, startTime, startTimeText, idx, id, url, img, handleDelete} = props;  
  const [, playInSeconds] = useAtom(playInSecondsAtom);
  const [, chapterChange] = useAtom(chapterChangeAtom)

  useEffect(() => {
    if(initStartTime) {
      if((startTimeText === undefined) && startTime >= 0) {
        const label = getHHMMSSFromSeconds(startTime)
        setStartTimeInputValue(label)
        setInitStartTime(false)
      } else if (startTimeText) {          
        setStartTimeInputValue(startTimeText)
        setInitStartTime(false)
      } else {
        setStartTimeInputValue("")
        setInitStartTime(false)
      }
    }
  }, [startTimeText, startTime])
  // const [__, changeChapter] = useAtom(changeChapterValueAtom);
  // const [, removeChapter] = useAtom(removeChapterAtom)

  const handleCopyPlayedSeconds = () => {
    const label = getHHMMSSFromSeconds(playedSeconds);    
    setStartTimeInputValue(label)
    chapterChange({idx, name: 'startTimeText', value: label})
  }

  const handlePlay = () => {
    playInSeconds(startTime)
  }

  const handleStartTimeInputChange = (data) => {
    const label = data.value;
    chapterChange(data)
    setStartTimeInputValue(label)
  }


  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <ChapterDelete id={id} idx={idx} audiopostId={audiopostId} handleDelete={handleDelete}/>
                </InputGroupAddon>  
                <ChapterInputField 
                  name="title" 
                  type="text" 
                  placeholder="Enter chapter title" 
                  value={title} 
                  id={id} 
                  idx={idx}
                  category="chapter"
                  handleOnChange={chapterChange}
                  invalid={title && title.length > 1 ? false : true}
                />
                 <InputGroupAddon addonType="append">
                  <Button 
                    disabled={startTimeInputValue && startTimeInputValue.length > 1 ? false : true}
                    onClick={handlePlay} 
                    color="primary">Play</Button>
                 </InputGroupAddon>
                 <FormFeedback>Title can't be empty</FormFeedback>
              </InputGroup>              
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <ChapterInputField
                  invalid={checkHHMMSS(startTimeInputValue) ? false : true} 
                  name="startTimeText" 
                  type="text"
                  placeholder="Start time (hh:mm:ss)" 
                  value={startTimeInputValue} 
                  category="chapter"
                  handleOnChange={handleStartTimeInputChange}                  
                  id={id} 
                  idx={idx}/>
                <InputGroupAddon addonType="append"><Button onClick={handleCopyPlayedSeconds} color="primary">Use playing time</Button></InputGroupAddon>
                <FormFeedback>Time can't be empty, and please make sure it follows HH:MM:SS</FormFeedback>
              </InputGroup>
            </FormGroup>
            
          </Col>   
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <ChapterInputField 
                  name="url" 
                  type="text" 
                  placeholder="Link" 
                  value={url} 
                  id={id} 
                  idx={idx}
                  category="chapter"
                  handleOnChange={chapterChange}                  
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <ChapterInputField 
                  name="img" 
                  type="text"
                  placeholder="Image Link" 
                  value={img} 
                  category="chapter"
                  handleOnChange={chapterChange}                  
                  id={id} 
                  idx={idx}/>              
              </InputGroup>
            </FormGroup>
          </Col>   
        </Row>                  
      </CardBody>
    </Card>
  )
}

export default ChapterInputCard;