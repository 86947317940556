// https://github.com/Podcastindex-org/podcast-namespace/blob/main/chapters/jsonChapters.md
import { atom } from "jotai";
import justcastApi from '../../api/justcast'
// import {getSecondsFromHHMMSS, getHHMMSSFromSeconds} from './../../libs'
import {getSecondsFromHHMMSS, getHHMMSSFromSeconds} from '../../libs/timeTransformation'
import {playerAtom} from './playerAtom'

// Version 1.2 - Updated on 2021.04.15
const temp = []

export const chaptersAtom = atom(temp);
export const chaptersStatusMessageAtom = atom(null);

export const chapterChangeAtom = atom(null, (get, set, item) => {
  const prevState = get(chaptersAtom);
  const {idx, name, value} = item;
  const updateChapter = {...prevState[idx]}
  if(name === 'startTimeText') {
    updateChapter['start_time'] = getSecondsFromHHMMSS(value);
  }
  updateChapter[name] = value;  
  const newState = [...prevState]
  newState[idx] = updateChapter;
  set(chaptersAtom, newState)
})

export const addChapterAtom = atom(null, (get, set, _) => {
  const prevState = get(chaptersAtom);
  const item = {startTimeText: "", title: "", url: "", img: "", toc: true}
  set(chaptersAtom, [...prevState, item])
})

export const saveChaptersAtom = atom(null, (get, set, audiopostId) => {
  const items = get(chaptersAtom);
  const playerState = get(playerAtom);
  const {durationSeconds} = playerState;
  
  justcastApi.post(`/v1/audioposts/${audiopostId}/many_chapters`, {
    items,
    duration_second: durationSeconds
  })
  .then((res) => {
    const resData = res.data;
    set(chaptersAtom, resData)
    set(chaptersStatusMessageAtom, "Chapters updated.")
    setTimeout(() => {
      set(chaptersStatusMessageAtom, null)
    }, 3000)
  })
  .catch((err) => {
    console.log(err);
  })

})

export const saveChapterAtom = atom(null, async (get, set, item) => {
  const prevState = get(chaptersAtom);
  const {audiopostId, id, idx} = item;
  const data = prevState[idx];
  
  if(id) {
    // update
    justcastApi.put(`/v1/audioposts/${audiopostId}/chapters/${id}`, data)   
    .then((res) => {
      const resData = res.data;
      const newData = [...prevState];
      newData[idx] = resData;
      set(chaptersAtom, newData)
    })
    .catch((err) => {
      console.log(err.response.data)
    })    
  } else {
    // create 
    justcastApi.post(`/v1/audioposts/${audiopostId}/chapters`, data)   
    .then((res) => {
      const resData = res.data;
      const newData = [...prevState];
      newData[idx] = resData;
      set(chaptersAtom, newData)
    })
    .catch((err) => {
      console.log(err.response.data)
    })
  }
})

export const deleteChapterAtom = atom(null, async (get, set, item) => {
  const prevState = get(chaptersAtom);
  const {audiopostId, id, idx} = item;
  const newData = prevState.filter((v, i) => i !== idx);
  if(id) {
    // destroy
    justcastApi.delete(`/v1/audioposts/${audiopostId}/chapters/${id}`)
    .then((res) => {
      // const newData = [...prevState];
      set(chaptersAtom, newData)      
    }).catch((err) => {
      console.log(err.response.data)
    })    
  } else {
    // remove    
    // newData.splice(idx, 1)
    set(chaptersAtom, newData)
  }
})
