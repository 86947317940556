import React, {useEffect, useState, useContext} from "react";
import { Alert, Spinner } from 'reactstrap';
import justcastApi from '../../api/justcast'
import {Context as AuthContext} from '../../context/AuthContext'
import {Context as MenuContext} from '../../context/MenuContext'
// import {Context as ScreenContext} from '../../context/ScreenContext'

export default function StripeDashboard() {
  const authContext = useContext(AuthContext);
  const menuContext = useContext(MenuContext);
  const email = authContext.state.email;
  const [error, setError] = useState(null);

  useEffect(() => {
    menuContext.setMainPageLeftMenu({title: 'Billing', subtitle: email, currentPageId: 'home'});

    justcastApi(`/v2/stripe_dashboard`)
    .then((res) => {
      window.location.replace(res.data?.stripe_dashboard_url)
    })
    .catch((err) => {
      setError(err.response.data.messages)
    })
  }, []);

  if(error) {
    return (
      <div className="container-fluid">
        <Alert color="primary">
          {error}.
        </Alert>
        <p>To learn more about your account info.  Please screenshot this page and send an email to justcastapp@gmail.com.</p>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <Spinner/>
    </div>
  )
}
