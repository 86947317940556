import React from 'react'
import { Card, CardHeader, CardBody, Button, CustomInput, Row, Alert, CardFooter } from 'reactstrap';

export default function ConnectedProducts({items, handleDelete, title, openProductSettingModal}) {
  return (
    <Card>
      <CardHeader>
        <h2 className="card-header-title">{title}</h2>
      </CardHeader>
      <CardBody>
        <div className="list-group list-group-flush">
          {
            items?.map((group) =>
              <div className="list-group-item" key={group.id}>
                <div className="row align-items-center">
                  <div className="col ml-n2">{group.name}</div>
                  <div>
                    <Button color="primary" size="sm" onClick={() => {
                      openProductSettingModal(group.shopify_product_id)
                    }}>Settings</Button>{" "}
                    <Button color="danger" size="sm" onClick={() => {
                      handleDelete(group.id)
                    }}>Delete</Button>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </CardBody>
    </Card>
  )
}
