import React from "react";
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'
import AuthRoute from '../components/AuthRoute'
import MobilePlayerRoute from '../components/MobilePlayerRoute'
import LandingPage from '../screens/LandingPage'
import SignIn from '../screens/SignIn'
import SignUp from '../screens/SignUp'
import SignupConfirmation from '../screens/SignupConfirmation'
import ChangePassword from '../screens/ChangePassword'
import ResetPassword from '../screens/ResetPassword'
import ForgotPassword from '../screens/ForgotPassword'
import Dashboard from '../screens/Dashboard'
import Shows from '../screens/Shows'
import Show from '../screens/Show'
import ShowCustomize from '../screens/ShowCustomize'
import ShowIntegrations from '../screens/ShowIntegrations'
import ShowImport from '../screens/ShowImport'
import ShowNotion from '../screens/ShowNotion'
import Playlist from '../screens/Playlist'
import ShowSettings from '../screens/ShowSettings'
import SocialLinks from '../screens/SocialLinks'
import SubscriptionReport from '../screens/SubscriptionReport'
import ShowAnalyticsReport from '../screens/ShowAnalyticsReport'
import EpisodeAnalyticsReport from '../screens/EpisodeAnalyticsReport'
import DownloadReport from '../screens/DownloadReport'
import Episodes from '../screens/Episodes'
import Episode from '../screens/Episode'
import EpisodesSorting from '../screens/EpisodesSorting'
import SubfolderShow from '../screens/SubfolderShow'
import EpisodeValueForValue from '../screens/EpisodeValueForValue'
import TwitterIntegrationCallback from '../screens/TwitterIntegrationCallback'
import ClouddriveConnections from '../screens/ClouddriveConnections'
import DropboxConnectCallback from '../screens/DropboxConnectCallback'
import OneDriveConnectionCallback from '../screens/AzureAppConnectionCallback'
import PlanNew from '../screens/PlanNew'
import PlanCardUpdate from '../screens/PlanCardUpdate'
import Plans from '../screens/Plans'
import BecomeAffiliate from '../screens/BecomeAffiliate'
import Receipts from '../screens/Receipts'
import Receipt from '../screens/Receipt'
import Widgets from '../screens/Widgets'
import ShowArtwork from '../screens/ShowArtwork'
import EpisodeArtwork from "../screens/EpisodeArtwork";
import EpisodeChapters from '../screens/EpisodeChapters'
import EpisodeNew from "../screens/EpisodeNew";
import { NewEpisode as EpisodeNewPage, EpisodePage, EpisodeChaptersPage, EpisodeAssetsPage} from "../screens/episodes/index"
import EpisodePodieos from "../screens/EpisodePodieos";
import EpisodeImages from "../screens/EpisodeImages";
import EpisodeImagesCreate from "../screens/EpisodeImagesCreate";
import EpisodePodieosCreate from "../screens/EpisodePodieosCreate";
import ShowHeaderImage from "../screens/ShowHeaderImage";
import IntroVideos from '../screens/IntroVideos';
import ShowDistributions from '../screens/ShowDistributions';
import PodcastLinks from '../screens/PodcastLinks';
import PrivatePodcastScreen from '../screens/PrivatePodcastScreen';
import PrivatePodcastTipSettings from '../screens/PrivatePodcastTipSettings';
import PodcastMailerLiteIntegration from '../screens/PodcastMailerLiteIntegration'
import ShowNew from '../screens/ShowNew';
import ShowOverview from '../screens/ShowOverview'
import ShowValueForValue from '../screens/ShowValueForValue'
import ShowWebsiteSettings from '../screens/ShowWebsiteSettings'
import ShowWebsiteImages from '../screens/ShowWebsiteImages'
import ShowDownloadLiveview from '../screens/ShowDownloadLiveview'
import ShowPeople from '../screens/ShowPeople'
import ShowPerson from '../screens/ShowPerson'
import ShowSeaons from '../screens/Seasons'
import EpisodesRearrange from '../screens/EpisodesRearrange'
import ShowPeopleSorting from '../screens/ShowPeopleSorting'
import ShowTipjar from '../screens/ShowTipjar'
import ShowTips from '../screens/ShowTips'
import ShowTipjarFee from '../screens/ShowTipjarFee'
import EpisodeSoundbites from '../screens/EpisodeSoundbites'
import StripeAccountBalance from '../screens/StripeAccountBalance'
import MobilePlayerScreen from '../screens/MobilePlayerScreen'
import StripeAccountConnectCallback from '../screens/StripeAccountConnectCallback'
import StripeAccountConnectFailed from '../screens/StripeAccountConnectFailed'
import {MailChimpIntegrationCallback} from '../screens/callbacks'
import AlbyConnectionCallback from "../screens/callbacks/AlbyConnectionCallback";
import InvitationEmail from "../screens/InvitationEmail";
import DropboxFolders from "../screens/specials/DropboxFolders";
import {StripeDashboard, StripePricingTable, StripeConfirmation, StripeSecretPricingTable} from "../screens/users"
import SenderAuthentications from "../screens/SenderAuthentications"
import TranscriptSegments from "../screens/transcripts/segments/TranscriptSegments"
import AiContents from "../screens/transcripts/segments/AiContents"
import { MemberPodcastSettings, AdvancedDripFeedSettings } from "../screens/member-podcasts"
import { Sharing } from '../screens/teams'
import { TeamPodcasts, TeamPodcast, TeamPodcastSettings } from '../screens/teams/podcasts'
import { TeamEpisode, TeamTranscript, TeamAiContents, TeamNewEpisode} from '../screens/teams/episodes'
import { ShopifyConnection, ShopifyConnectionCallback, ShopifyPodcastConnection, ShopifyOauth, ShopifyOauthCallback, ShopifyBillingCallback } from "../screens/shopify-connection";
import { PodcastConnections} from "../screens/podcast-connections"
import { NewAd, AdLibrary, UpdateAd } from '../screens/ad-contents-library'

const MainRoutes = () => {
  return (
    <Switch>
      <AuthRoute exact path='/' component={SignIn}/>
      <AuthRoute exact path='/signin' component={SignIn}/>
      <AuthRoute exact path='/signup' component={SignUp}/>
      <AuthRoute exact path='/signup-confirmation' component={SignupConfirmation}/>
      <AuthRoute exact path='/password_reset' component={ResetPassword}/>
      <AuthRoute exact path='/forgot_password' component={ForgotPassword}/>
      <MobilePlayerRoute exact path='/shows/:id/mobile-player' component={MobilePlayerScreen}/>
      <MobilePlayerRoute exact path="/shopify-oauth" component={ShopifyOauth}/>
      <MobilePlayerRoute exact path="/shopify-oauth-callback" component={ShopifyOauthCallback}/>
      <PrivateRoute exact path="/stripe_dashboard" component={StripeDashboard} />
      <PrivateRoute exact path="/stripe_pricing_table" component={StripePricingTable} />
      <PrivateRoute exact path="/special-pricing" component={StripeSecretPricingTable} />
      <PrivateRoute exact path='/dashboard' component={Shows}/>
      <PrivateRoute exact path='/change_password' component={ChangePassword}/>
      <PrivateRoute exact path='/plans' component={StripePricingTable}/>
      <PrivateRoute exact path='/billing' component={StripeDashboard}/>
      <PrivateRoute exact path="/confirmation_page" component={StripeConfirmation}/>
      <PrivateRoute exact path='/receipts/:id' component={Receipt}/>
      <PrivateRoute exact path='/plans/:id/new' component={PlanNew}/>
      <PrivateRoute exact path='/update_credit_card' component={PlanCardUpdate}/>
      <PrivateRoute exact path='/connections' component={ClouddriveConnections}/>
      <PrivateRoute exact path='/dropbox_connection_oauth_callback' component={DropboxConnectCallback}/>
      <PrivateRoute exact path='/onedrive_connection_oauth_callback' component={OneDriveConnectionCallback}/>
      <PrivateRoute exact path='/twitter_connection_oauth_callback' component={TwitterIntegrationCallback}/>
      <PrivateRoute exact path='/mailchimp_connection_oauth_callback' component={MailChimpIntegrationCallback}/>
      <PrivateRoute exact path='/alby_connection_oauth_callback' component={AlbyConnectionCallback}/>
      <PrivateRoute exact path='/alby_connection_oauth_callback/:show_id' component={AlbyConnectionCallback}/>
      <PrivateRoute exact path='/alby_connection_oauth_callback/:show_id/audioposts/:audiopost_id' component={AlbyConnectionCallback}/>
      <PrivateRoute exact path='/introvideos' component={IntroVideos}/>
      <PrivateRoute exact path='/affiliates' component={BecomeAffiliate}/>
      <PrivateRoute exact path='/stripe_account_balance' component={StripeAccountBalance}/>
      <PrivateRoute exact path='/shows' component={Shows}/>
      <PrivateRoute exact path='/shows/new' component={ShowNew}/>
      <PrivateRoute exact path='/shows/:id/overview' component={ShowOverview}/>
      <PrivateRoute exact path='/shows/:id/episode_number' component={EpisodesRearrange}/>
      <PrivateRoute exact path='/shows/:id/episode_sorting' component={EpisodesSorting}/>
      <PrivateRoute exact path='/shows/:id/sub_folder' component={SubfolderShow}/>
      <PrivateRoute exact path='/shows/:show_id/episodes/:id' component={Episode}/>
      <PrivateRoute exact path='/shows/:id/settings' component={Show}/>
      <PrivateRoute exact path='/shows/:id/advanced_settings' component={ShowSettings}/>
      <PrivateRoute exact path='/shows/:id/value_for_value_settings' component={ShowValueForValue}/>
      <PrivateRoute exact path='/shows/:id/metrics' component={ShowAnalyticsReport}/>
      <PrivateRoute exact path='/shows/:id/download_liveview' component={ShowDownloadLiveview}/>
      <PrivateRoute exact path='/shows/:show_id/episodes/:id/chapters' component={EpisodeChapters}/>
      <PrivateRoute exact path='/shows/:show_id/episodes/:id/soundbites' component={EpisodeSoundbites}/>
      <PrivateRoute exact path='/shows/:show_id/episodes/:id/metrics' component={EpisodeAnalyticsReport}/>
      <PrivateRoute exact path='/shows/:id/old_metrics' component={SubscriptionReport}/>
      <PrivateRoute exact path='/shows/:id/old_downloads' component={DownloadReport}/>
      {/* <PrivateRoute exact path='/shows/:id/episodes/:audiopost_id/downloads' component={EpisodeDownloadReport}/> */}
      <PrivateRoute exact path='/shows/:id/import_from_rss' component={ShowImport}/>
      <PrivateRoute exact path='/shows/:id/social' component={SocialLinks}/>
      <PrivateRoute exact path='/shows/:id/links' component={PodcastLinks}/>
      <PrivateRoute exact path='/shows/:id/integrations' component={ShowIntegrations}/>
      <PrivateRoute exact path='/shows/:id/widgets' component={Widgets}/>
      <PrivateRoute exact path='/shows/:id/customize' component={ShowCustomize}/>
      <PrivateRoute exact path='/shows/:id/artwork' component={ShowArtwork}/>
      <PrivateRoute exact path='/shows/:id/header_image' component={ShowHeaderImage}/>
      <PrivateRoute exact path='/shows/:id/playlist' component={Playlist}/>
      <PrivateRoute exact path='/shows/:id/notion' component={ShowNotion}/>
      <PrivateRoute exact path='/shows/:id/distributions' component={ShowDistributions}/>
      <PrivateRoute exact path='/shows/:id/website_settings' component={ShowWebsiteSettings}/>
      <PrivateRoute exact path='/shows/:id/website_images' component={ShowWebsiteImages}/>
      <PrivateRoute exact path='/shows/:show_id/subscribers' component={PrivatePodcastScreen}/>
      <PrivateRoute exact path='/shows/:show_id/private-settings' component={MemberPodcastSettings}/>
      <PrivateRoute exact path='/shows/:show_id/pragmatic-feed' component={AdvancedDripFeedSettings}/>
      <PrivateRoute exact path='/shows/:show_id/private-tip-settings' component={PrivatePodcastTipSettings}/>
      <PrivateRoute exact path='/shows/:show_id/invitation-email' component={InvitationEmail}/>
      <PrivateRoute exact path='/shows/:id/new-episode' component={EpisodeNew}/>
      <PrivateRoute exact path='/shows/:id/episode/new' component={EpisodeNewPage}/>
      <PrivateRoute exact path='/podcasts/:show_id/episodes/:id/chapters' component={EpisodeChaptersPage}/>
      <PrivateRoute exact path='/podcasts/:show_id/episodes/:id/assets' component={EpisodeAssetsPage}/>
      <PrivateRoute exact path='/podcasts/:show_id/episodes/:id' component={EpisodePage}/>
      <PrivateRoute exact path='/shows/:id/episodes' component={Episodes}/>
      <PrivateRoute exact path='/shows/:id/tip_jar' component={ShowTipjar}/>
      <PrivateRoute exact path='/shows/:id/tips' component={ShowTips}/>
      <PrivateRoute exact path='/shows/:id/tip_jar_fees' component={ShowTipjarFee}/>
      <PrivateRoute exact path='/shows/:id/people_settings' component={ShowPeople}/>
      <PrivateRoute exact path='/shows/:id/people_sorting' component={ShowPeopleSorting}/>
      <PrivateRoute exact path='/shows/:show_id/people/:id' component={ShowPerson}/>
      <PrivateRoute exact path='/shows/:show_id/episodes/:id/artwork' component={EpisodeArtwork}/>
      <PrivateRoute exact path='/shows/:show_id/seasons' component={ShowSeaons}/>
      <PrivateRoute exact path='/episodes/:id/podieos' component={EpisodePodieos}/>
      <PrivateRoute exact path='/episodes/:id/images' component={EpisodeImages}/>
      <PrivateRoute exact path='/episodes/:id/images/create' component={EpisodeImagesCreate}/>
      <PrivateRoute exact path='/episodes/:id/podieos/create' component={EpisodePodieosCreate}/>
      <PrivateRoute exact path='/shows/:show_id/episodes/:id/value_for_value_settings' component={EpisodeValueForValue}/>
      <PrivateRoute exact path='/stripe_connect_refresh_callback' component={StripeAccountConnectFailed}/>
      <PrivateRoute exact path='/stripe_connect_return_callback' component={StripeAccountConnectCallback}/>
      <PrivateRoute exact path='/stripe_connect_refresh_callback/tip_jar/:tip_jar_id' component={StripeAccountConnectFailed}/>
      <PrivateRoute exact path='/stripe_connect_return_callback/tip_jar/:tip_jar_id' component={StripeAccountConnectCallback}/>
      <PrivateRoute exact path='/dropbox_folders' component={DropboxFolders}/>
      <PrivateRoute exact path='/sender_authentications' component={SenderAuthentications}/>
      <PrivateRoute exact path='/episodes/:audiopost_id/transcripts/:transcript_id/segments' component={TranscriptSegments}/>
      <PrivateRoute exact path='/episodes/:audiopost_id/transcripts/:transcript_id/ai_contents' component={AiContents}/>
      <PrivateRoute exact path='/shows/:id/sharing' component={Sharing}/>
      <PrivateRoute exact path='/team_podcasts' component={TeamPodcasts}/>
      <PrivateRoute exact path='/team_podcasts/:id' component={TeamPodcast}/>
      <PrivateRoute exact path='/team_podcasts/:id/settings' component={TeamPodcastSettings}/>
      <PrivateRoute exact path='/team_podcasts/:show_id/team_episode/new' component={TeamNewEpisode}/>
      <PrivateRoute exact path='/team_podcasts/:show_id/team_episodes/:id' component={TeamEpisode}/>
      <PrivateRoute exact path='/team_podcasts/:show_id/team_episodes/:id/transcript' component={TeamTranscript}/>
      <PrivateRoute exact path='/team_podcasts/:show_id/team_episodes/:audiopost_id/ai_contents' component={TeamAiContents}/>
      <PrivateRoute exact path="/connections/shopify-connection" component={ShopifyConnection}/>
      <PrivateRoute exact path="/connections/shopify-connection-callback" component={ShopifyConnectionCallback}/>
      <PrivateRoute exact path="/shopify-billing-callback" component={ShopifyBillingCallback}/>
      <PrivateRoute exact path='/shows/:show_id/podcast-connections' component={PodcastConnections}/>
      <PrivateRoute exact path='/shows/:show_id/podcast-connections/mailerlite' component={PodcastMailerLiteIntegration}/>
      <PrivateRoute exact path='/shows/:show_id/podcast-connections/shopify' component={ShopifyPodcastConnection}/>
      <PrivateRoute exact path='/dynamic-contents/new' component={NewAd}/>
      <PrivateRoute exact path='/dynamic-contents' component={AdLibrary}/>
      <PrivateRoute exact path='/dynamic-contents/:id' component={UpdateAd}/>
    </Switch>
  )
}

export default MainRoutes