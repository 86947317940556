import React, {useState, useEffect, useRef} from 'react'
import justcastApi from '../../api/justcast'
import { Column } from '@ant-design/charts';
import {Card, Badge, Col, FormGroup, Form} from 'reactstrap';
import DatePickerRange from '../Metrics/DatePickerRange'
import moment from 'moment'
import Select from 'react-select'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
  })
}

const defaultAudioOption = {id: "", label: "All Episodes", value: "All Episodes"};
const defaultListenerOption = {id: "", label: "All Listeners", value: "All Listeners"};

const getParamsStr = (params) => {
  const selectedParams = params.filter((val) => val[Object.keys(val)] !== "" );
  const paramStr = selectedParams.map((val) => {return `${Object.keys(val)}=${val[Object.keys(val)]}`});
  return paramStr.join("&")
}

const enumerateDaysBetweenDates = (fromDateStr, toDateStr) => {
  const date = [];
  const fromDate = moment(fromDateStr);
  const toDate = moment(toDateStr);
  const days = toDate.diff(fromDate, 'days');
  if(days > 0) {
    for(let i = 0; i < days; i++) {
      date.push({reported_at: fromDate.add(1, 'days').format("YYYY-MM-DD"), download_count: 0})
    }
  }
  return date;
}

const mergeTwoDateList = (mainList, supplementList) => {
  if(mainList.length > 0) {
    const fromDateStr = mainList[0]['reported_at'];
    const fromDate = moment(fromDateStr);
    for(let i = 0; i < supplementList.length; i++) {
      const {reported_at, download_count} = supplementList[i];
      const reportedDate = moment(reported_at);
      const days = reportedDate.diff(fromDate, 'days');
      if(days > -1) {
        const dataPoitn = mainList[days];
        dataPoitn['download_count'] = download_count;
      }
    }
    return mainList;
  }

  return supplementList;
}

export default function DownloadChartOverTime({showId, isAdvancedPrivate}) {
  const chartRef = useRef();
  const [audioOptions, setAudioOptions] = useState([]);
  const [downloads, setDownloads] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [audiopostId, setAudiopostId] = useState("");
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [listeners, setListeners] = useState([]);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [totalDownloads, setTotalDownloads] = useState(0);

  useEffect(() => {
    if(showId && isAdvancedPrivate) {
      justcastApi.get(`/v1/shows/${showId}/private_download_default_params?time_zone=${timeZone}`)
      .then((res) => {
        const {from_date, to_date, audioposts, subscribers} = res.data;
        setFromDate(from_date)
        setToDate(to_date)
        setAudioOptions([defaultAudioOption, ...(audioposts.map((value) => {return {value: value[0], id: value[0], label: value[1]}}))])
        setListeners([defaultListenerOption, ...(subscribers.map((sub) => { return {value: sub, id: sub, label: sub}}))])
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [showId, isAdvancedPrivate])

  const handleDateChange = (name, val) => {
    const datePicked = moment(val[0]).startOf('day').format();
    if(name === 'fromDate') {
      setFromDate(datePicked);
    } else {
      setToDate(datePicked);
    }
  }

  useEffect(() => {
    if(fromDate && toDate) {
      const params = [
        {from_date: fromDate},
        {to_date: toDate},
        {subscriber_email: subscriberEmail},
        {audiopost_id: audiopostId},
        {time_zone: timeZone}
      ]

      const downloadsOverTime = enumerateDaysBetweenDates(fromDate, toDate);
      const queryStr = getParamsStr(params);
      justcastApi.get(`/v1/shows/${showId}/private_download_count_overtime?${queryStr}`)
      .then((res) => {
        const {download_overtime} = res.data;
        setDownloads(mergeTwoDateList(downloadsOverTime, download_overtime));
        let totalDownloadCount = 0;
        for(let i = 0; i < download_overtime.length; i++) {
          totalDownloadCount += download_overtime[i]['download_count']
        }
        setTotalDownloads(totalDownloadCount)
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [fromDate, toDate, audiopostId, subscriberEmail])

  if(showId && isAdvancedPrivate) {
    const chartConfig = {
      xField: 'reported_at',
      yField: 'download_count',
      color: '#FCD161',
      meta: {
        reported_at: { alias: 'Period' },
        download_count: { alias: 'Downloads' },
      },
      data: downloads || [],
    }

    return (
      <Card>
        <div className="card-header">
          <h3 className="card-header-title mr-auto">Stats</h3>
        </div>
        <div className='card-body'>
          <Form>
            <div className="form-row">
              <Col>
                <FormGroup>
                  <label>Select Episode</label>
                  <Select
                    defaultValue={defaultAudioOption}
                    options={audioOptions}
                    styles={customStyles}
                    onChange={(e) => {
                      setAudiopostId(e.id);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label>Listener Email</label>
                  <Select
                    defaultValue={defaultListenerOption}
                    options={listeners}
                    styles={customStyles}
                    onChange={(e) => {
                      setSubscriberEmail(e.id)
                    }}
                  />
                </FormGroup>
              </Col>
            </div>
          </Form>
          <DatePickerRange
            display={true}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={(x) => {
              handleDateChange('fromDate', x)
            }}
            setToDate={(x) => {
              handleDateChange('toDate', x)
            }}
          />
          {totalDownloads > 0 && <div style={{marginTop: 40}}>
            <h1><Badge color="warning">Total downloads: {totalDownloads}</Badge></h1>
          </div>}
          <div style={{marginTop: 40, marginLeft: 10, marginRight: 10, marginBottom: 10}}>
            <Column {...chartConfig} chartRef={chartRef} />
          </div>
        </div>
      </Card>
    )
  } else {
    return null;
  }
}
