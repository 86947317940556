import React, {useEffect, useState} from 'react'
import { Row, Col, FormGroup, Button, Modal, ModalHeader, ModalBody, Input, ModalFooter } from 'reactstrap';
import Select from 'react-select'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',    
  })
}

export default function NewPersonModal({
  modal,
  addHost,
  toggle,
  personTaxonomies,
}) {

  const [name, setName] = useState("")
  const [email, seEmail] = useState("")
  const [roles, setRoles] = useState([]);
  const [roleTitle, setRoleTitle] = useState("")
  const [groupTitle, setGroupTitle] = useState("")
  // addHost(person)

  const handleTaxonomyChange = (e) => {
    
    if(e && e !== null && e.length > 0) {
      const _roles = e.map((role) => {
        const {code, group_title, role_title, value, label} = role;
        return {code, group_title, role_title, value, label}
      })

      const {role_title, group_title} = e[0]; // the first one will be our primary
      setRoles(_roles);
      setRoleTitle(role_title);
      setGroupTitle(group_title);
    } else {
      setRoles([]);
      setRoleTitle("");
      setGroupTitle("");
    }
  }

  useEffect(() => {
    setName("")
    seEmail("")
    setRoles([]);
    setRoleTitle("");
    setGroupTitle("");
  }, [modal])

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Add a new Host
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label>Name (required)</label>
              <Input
                type={"text"}
                name={"name"}
                id={"name"}
                placeholder="Joe Smith"
                value={name ? name : ""}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />              
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label>Email</label>
              <Input
                  type={"text"}
                  name={"email"}
                  id={"email"}
                  value={email ? email : ""}
                  onChange={(e) => {
                    seEmail(e.target.value)
                  }}
                  placeholder="joe.smith@gmail.com"
                />                 
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <label>Roles (require)</label>
              <Select 
                value={roles}
                isMulti
                options={personTaxonomies}
                styles={customStyles}
                onChange={handleTaxonomyChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button> {" "}
        <Button color="primary" onClick={() => {
          addHost({name, email, roles, roleTitle, groupTitle})
        }}>Save</Button>
      </ModalFooter>
    </Modal>
  )
  

  return null;
}
