import React, { useState }  from "react";
import { HelpCircle } from 'react-feather';
import {Link} from 'react-router-dom'
import {Row, Spinner, Card, Tooltip} from 'reactstrap';

const IconTooltip = ({message, id}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  if(message) {
    return (
      <>
        <HelpCircle size={18} id={id}/>
        <Tooltip placement="bottom" isOpen={tooltipOpen} target={id} toggle={toggle}>{message}</Tooltip>
      </>
    )
  }
  return null;
}

const SummaryStateCard = (props) => {
  const {id, loading, message, subtitle, title, stats} = props;

  if(loading) {
    return (
      <Card>
        <div className="card-header">
          {title ? <h3 className="card-header-title mr-auto">{title}</h3> : null}
        </div>
        <div className="card-body">
          <Spinner/>
        </div>
      </Card>
    )
  }

  return (
    <Card>
      <div className="card-header">
        {title ? <h3 className="card-header-title mr-auto">{title}</h3> : null}
      </div>
      <div className="card-body">
        {
          subtitle ? <div className="text-muted mb-3">{subtitle} <IconTooltip id={id} message={message}/></div> : null
        }
        {
          stats && <div className="row align-items-center">
          {
            stats.map((stat, id) => 
              <div className="col-6 col-md-12 col-lg-6" key={id}>
                <h4 className="text-uppercase text-muted mb-2">{stat.label}</h4>
                {
                  stat.link ? <Link className="text-white" to={stat.link}><span className="h2 mb-0 text-warning">{Math.floor(stat.value)}</span></Link> : <span className="h2 mb-0 text-warning">{Math.floor(stat.value)}</span>
                }              
              </div>
            )
          }
        </div>
        }

      </div>
    </Card>
  );
}

export default SummaryStateCard;