import React from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TipjarPrice from './TipjarPrice'

const TipjarPriceTable = (props) => {
  const {
    prices, 
    tipjar, 
    priceTypes,
    handleRemove, 
    handlePriceChanged, 
    handleSorting, 
    currencyCodes
  } = props;
  
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    handleSorting(result)
  }

  if(prices && prices.length > 0 && tipjar) {
    return (
      <>
      <hr/>
      <h2>Prices</h2>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
              {
                prices.map((price, idx) => {
                  return (
                    <Draggable isDragDisabled={false} key={idx} draggableId={`price-${idx}`} index={idx}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                          <TipjarPrice                          
                            // key={idx}
                            id={price.id}
                            idx={idx}                            
                            currencyCodes={currencyCodes}
                            priceTypes={priceTypes}
                            unit_amount={price.unit_amount}
                            unit_amount_text={price.unit_amount_text}
                            active={price.active}
                            recurring_interval_name={price.recurring_interval_name}
                            recurring_interval={price.recurring_interval}                            
                            product_currency_code_id={price.product_currency_code_id}
                            product_currency_code_value={price.product_currency_code_value}
                            stripe_payment_url={price.stripe_payment_url}
                            handlePriceChanged={handlePriceChanged}
                            handleRemove={handleRemove}
                          />
                        </div>                        
                      )}
                    </Draggable>
                  )
                })
              }
              {provided.placeholder}
            </div>
          )}          
        </Droppable>
      </DragDropContext>
      </>
    )
  }

  return null;
}

export default TipjarPriceTable;