import React from "react";
import { Link } from "react-router-dom";
import { Card, CardText, CardBody, CardTitle, Button, Spinner } from 'reactstrap';

const EmbedVideo = ({videoUrl}) => {
  if(videoUrl) {
    return (
      <div className="embed-responsive embed-responsive-16by9 rounded mb-3">
        <iframe className="embed-responsive-item" src={videoUrl} allowFullScreen></iframe>
      </div>
    )
  }
  return null;
}

const VideoCardItem = ({iconclass, title, subtitle, videoUrl, isDropbox, dropboxUrl}) => {
  if(title) {
    return (
      <Card>
        <CardBody>
          <div className="mb-3">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className={iconclass}></span>
              </div>
              <div className="col ml-n2">
                <h2 className="mb-1">{title}</h2>
              </div>
            </div>
          </div>
          <p className="mb-3">
            {subtitle}
          </p>
          {
            isDropbox && <p className="mb-3">Connect your Dropbox with JustCast, click <Link to="/connections">here</Link> to get started</p>
          }
          <EmbedVideo videoUrl={videoUrl}/>
          {isDropbox && dropboxUrl && <>
            <h2>Create a new podcast with Dropbox</h2>
            <ol>
              <li>Connect your Dropbox with JustCast, click <Link to="/connections">here</Link> to get started</li>
              <li>
                Open your Dropbox app on your computer or go to <a href="https://www.dropbox.com" target="_blank">Dropbox.com</a>
              </li>
              <li>
                If you are using the dropbox website, click on the Files on the left hand side menu first.  Open the Apps folder.
              </li>
              <li>
                Open the JustCast folder.  (Please click <a href={dropboxUrl}>here</a> to setup the conneciton between Dropbox and JustCast, if you don't see any folder called JustCast inside your Dropbox.)
              </li>
              <li>
                Create a new folder and give it the same name as your Podcast.
              </li>
            </ol>
          </>}
        </CardBody>
      </Card>
      )
    }

    return null;
}

export default VideoCardItem;