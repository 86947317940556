import React, {useRef, useState, useEffect} from "react";
import ReactPlayer from 'react-player'
import { useAtom } from 'jotai';
import {playerAtom, addPlayerAtom, addPlayerRefAtom, updatePlayerStatus} from './playerAtom'

const PlayerContainer = (props) => {
  const playerRef = useRef(null);
  const [player] = useAtom(playerAtom);
  const [, addPlayer] = useAtom(addPlayerAtom);
  const [, playerStatusSet] = useAtom(updatePlayerStatus);

  useEffect(() => {
    addPlayer(props.player)
  }, [props.player])

  if(player && player.audio_url) {

    return <ReactPlayer 
      ref={playerRef}
      url={player.audio_url} 
      controls={false}
      height={"0px"}
      width={"0px"}    
      onDuration={(res) => {
        const durationSeconds = res;
        playerStatusSet({durationSeconds})
      }}
      onProgress={(res) => {
        const playedSeconds = res.playedSeconds;
        playerStatusSet({playedSeconds})
      }}
      onReady={(res) => {
        if(res) {
          playerStatusSet({onReady: true, playerRef: res})
        }
      }}
      playing={player.playing}     
    />
  }

  return null;
}

export default PlayerContainer;