import React from 'react'
import { Button } from 'reactstrap';

const actionLabels = {
  sequential_feed: "Drip an episode every",
  episode_expires_after: "Episode expiration",
  podcast_full_access_after: "Allow full access after",
  podcast_expires_after: "Podcast expiration",
}

export default function ActionListItem({
  id,
  action_name,
  action_value,
  action_unit,
  initiator_name,
  handleDelete
}) {
  return (
    <div className="list-group-item">
      <div className="row align-items-center">
        <div className="col">
          <h4 className="mb-1">
            {actionLabels[action_name]} {action_value} {action_unit}
          </h4>
          <small className="text-muted">
            applies to {initiator_name}
          </small>
        </div>
        <div className='col-auto'>
          <Button className="btn btn-sm btn-danger" onClick={() => {
            handleDelete(id)
          }}>Delete</Button>
        </div>
      </div>
    </div>
  )
}
