import React, {useEffect, useState, useContext} from 'react'
import { Spinner } from 'reactstrap';
import justcastApi from '../../api/justcast'
import queryString from 'query-string'
import { Link } from 'react-router-dom';

export default function ShopifyOauthCallback(props) {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    const { code, hmac, state } = values;
    const message = props.location.search.replace("?", "").replace(`&hmac=${values["hmac"]}`, "");

    justcastApi.post("/v2/shopify_connections.json", {
      shop_name: state,
      authorization_code: code,
      hmac,
      message
    })
    .then((res) => {
      // setLoading(false)
      // console.log(res.data);
      window.location.href = res.data.redirect_url
    })
    .catch((err) => {
      setErrorMessage(err.response.data)
      setLoading(false)
    })
  }, [])

  if(loading) {
    return (
      <div className="container-fluid">
        <Spinner color="warning"/>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <p>{errorMessage}</p>
    </div>
  )
}

// http://localhost:3000/shopify/redirect/callback?code=8f46bbef6e25d1b299a3c8d870a63786&hmac=861419330a5c1d53285ecc49d72c58e5a297b1c56270bebe9ca2652fc07383fa&host=YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvcXVpY2tzdGFydC0yYWQwOWQ3Nw&shop=quickstart-2ad09d77.myshopify.com&state=12345&timestamp=1705130022
