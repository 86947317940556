import React, {useEffect, useState, useContext} from 'react'
import {Context as AlertContext} from '../../context/AlertContext'
import {Context as AuthContext} from '../../context/AuthContext'
import {Context as MenuContext} from '../../context/MenuContext'
import justcastApi from '../../api/justcast'
import ConnectForm from './ConnectForm'
import queryString from 'query-string'

export default function ShopifyConnection(props) {
  const {addWithTimeout, add} = useContext(AlertContext);
  const menuContext = useContext(MenuContext);
  const authContext = useContext(AuthContext)

  const [loading, setLoading] = useState(true);
  const [connection, setConnection] = useState(null)
  const [shopName, setShopName] = useState(null);
  const [publicId, setPublicId] = useState(null);

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    const {shopify_connection_public_id} = values || {};
    if(shopify_connection_public_id) {
      setPublicId(shopify_connection_public_id)
    }

    menuContext.setMainPageLeftMenu({title: 'Shopify', subtitle: "Connections", currentPageId: 'connections'});
    justcastApi.get("/v2/shopify_connections.json")
    .then((res) => {
      setLoading(false)
      setConnection(res.data.id);
      setShopName(res.data.shop_name)
    })
    .catch((err) => {
      setLoading(false)
    })
  }, [])

  if(loading) {
    return null;
  }

  if(connection) {
    return (
      <div className="container-fluid">
        <p>✅ Your Shopify store is now successfully connected with JustCast.</p>
        <p>You can go to Shopify <a href={`https://admin.shopify.com/store/${shopName}/settings/apps?tab=installed`} target="_blank">Apps and sales channels</a> to manage your Shopify and JustCast connection.</p>
      </div>
    )
  }

  if(publicId) {
    return (
      <div className="container-fluid">
        <ConnectForm publicId={publicId}/>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <p>Please visit <a href="https://apps.shopify.com/justcast">Shopify App store</a> to learn more.</p>
    </div>
  );
}
