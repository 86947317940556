import React, {useEffect, useState, useContext} from "react";
import queryString from 'query-string'
import {Link, Redirect} from 'react-router-dom'
import justcastApi from '../api/justcast'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AlertContext} from '../context/AlertContext'
import EpisodesList from '../components/EpisodesList'
import SummaryCard from '../components/SummaryCard'
import SortingDropdownButton from '../components/SortingDropdownButton'
import DeleteDirectUploadModal from '../components/DeleteDirectUploadModal'
import {PermissionModal} from '../components/FileUploader';
import { Spinner } from 'reactstrap';
import {Mixpanel} from '../api/mixpanel'
import DropboxFilePickerModal from "../components/file-picker-dropbox/DropboxFilePickerModal";

const Episodes = (props) => {
  const screenContext = useContext(ScreenContext)
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const {setEpisodesMenus, handleAudiopostsSort, state} = useContext(MenuContext);
  const {published_date_sort, name_sort} = state;
  const [dropboxFileModal, setDropboxFileModel] = useState(false)
  // TODO put current page on the URL, so that we can pass it to episode page
  const modalContext = useContext(ModalContext);
  const {podcastDeleted} = modalContext.state;

  const id = props.match.params.id;
  const values = queryString.parse(props.location.search);
  const [items, setItems] = useState([]); // episodes
  const [denied, setDenied] = useState(false);
  const [isDirectUpload, setIsDirectUpload] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dropboxFiles, setDropboxFiles] = useState([]);
  const [dropboxModalLoading, setDropboxModalLoading] = useState(true);
  const [dropboxFileErrorMessage, setDropboxFileErrorMessage] = useState(null)
  const [dropboxFileSuccessMessage, setDropboxFileSuccessMessage] = useState(null)

  const uid = authContext.email;
  const {type} = values || {};
  const fetchEpisodes = () => {

    screenContext.startFetching();

    const orderStrings = [];
    if(published_date_sort) {
      orderStrings.push(`published_date_sort=${published_date_sort}`)
    }
    if(name_sort) {
      orderStrings.push(`name_sort=${name_sort}`)
    }
    if(type) {
      orderStrings.push(`filter_on=${type}`)
    }

    let endpointUrl = `/v2/shows/${id}/audioposts`;
    // let endpointUrl = `/v1/shows/${id}/audioposts_dashboard`;

    if(orderStrings.length > 0) {
      endpointUrl = `${endpointUrl}?${orderStrings.join("&")}`
    }

    //`/v1/shows/${id}/audioposts_dashboard?published_date_sort=ASC&name_sort=DESC`
    justcastApi.get(endpointUrl)
    .then((res) => {
      setItems(res.data.audioposts);
      setIsDirectUpload(res.data.show.is_direct_upload);
      screenContext.finishFetching();

      setEpisodesMenus({
        title: 'Episodes',
        subtitle: res.data.show.podcast_title,
        currentPageId: 'episodes',
        currentTabId: 'episodes',
        showId: id,
        landingPageUrl: res.data.show.landing_page_url,
        websiteUrl: res.data.show.player_page_link,
        rssFeed: res.data.show.rss_feed,
        passwordProtected: res.data.show.is_password_protected,
        advancedPrivate: res.data.show.is_advanced_private_feed,
        isPrivate: res.data.show.is_private,
        isPrivateShow: res.data.show.is_private_show,
        isDirectUpload: res.data.show.is_direct_upload,
        skill_podcast_website: res.data.show.skill_podcast_website,
        shopifyApp: res.data.is_shopify_app
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      // add notifcation
      if(err.response.status === 401) {
        setDenied(true)
      }
    })
  }

  const handleSortingClick = ({name, value}) => {
    handleAudiopostsSort({name, value})
  }

  useEffect(() => {
    fetchEpisodes()
  }, [id, published_date_sort, name_sort])

  useEffect(() => {
    if(podcastDeleted) {
      fetchEpisodes()
    }
  }, [podcastDeleted])

  const handleUpdateStatusClick = () => {
    if(selectedItems.length > 0) {
      justcastApi.post(`/v1/shows/${id}/draft_bulk_update`, {
        audiopost_ids: selectedItems
      }).then((res) => {
        setSelectedItems([]);
        alertContext.addWithTimeout({color: 'warning', title: "Success", message: res.data.message, isOpen: true, timeout: 2000}) // color, title, message, isOpen, timeout
      }).catch((err) => {
        console.log(err);
        alertContext.addWithTimeout({color: 'warning', title: "Failed", message: "Something went wrong, please screenshot this is and send it to justcastapp@gmail.com", isOpen: true, timeout: 2000}) // color, title, message, isOpen, timeout
      })
    }
  }

  const handleItemDraftToggle = (episodeId) => {
    const _items = [...items];
    const itemIdx = _items.findIndex((item) => item.id === episodeId)
    _items[itemIdx]['is_draft'] = !_items[itemIdx]['is_draft']
    setItems(_items)
    const _selectedItems = [...selectedItems];
    const k = _selectedItems.indexOf(episodeId);
    if(k === -1) {
      _selectedItems.push(episodeId);
    } else {
      _selectedItems.splice(k, 1);
    }
    setSelectedItems(_selectedItems);
  }

  const handleDropboxFilePicker = (files) => {
    setDropboxFileModel(true)
    setDropboxModalLoading(true)
    setDropboxFiles(files)
    justcastApi.post(`/v2/shows/${id}/upload_dropbox_files`, {
      files
    })
    .then((res) => {
      setDropboxModalLoading(false)
      setDropboxFileErrorMessage(null)
      setDropboxFileSuccessMessage(res.data.message)
    })
    .catch((err) => {
      setDropboxModalLoading(false)
      setDropboxFileErrorMessage(err.response.data.message)
      setDropboxFileSuccessMessage(null)
    })
  }

  const toggleDropboxFileModal = () => {
    setDropboxFileModel(!dropboxFileModal)
  }

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <>
      <div className="container-fluid">
        <DeleteDirectUploadModal/>
        <DropboxFilePickerModal
          modal={dropboxFileModal}
          toggle={toggleDropboxFileModal}
          data={dropboxFiles}
          loading={dropboxModalLoading}
          successMessage={dropboxFileSuccessMessage}
          errorMessage={dropboxFileErrorMessage}
        />
        <PermissionModal/>
        <div className="row">
          <SummaryCard
            className="col-12 col-md-12"
            title="Total uploads"
            value={items.length}
            isDirectUpload={isDirectUpload}
            buttonLabel="New Episode"
            buttonLink={`/shows/${id}/episode/new`}
            buttonClassName="primary"
            dropboxSuccessCallback={handleDropboxFilePicker}
          />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="card-header-title">Episodes</h4>
                  </div>
                  {/* <div className="col-auto">
                    <div className="btn btn-sm btn-primary" onClick={handleUpdateStatusClick}>Update status</div>
                  </div> */}
                  <div className="col-auto">
                    <SortingDropdownButton label="Publish date sort order" name="published_date_sort" value={published_date_sort} handleClick={handleSortingClick}/>{' '}
                    <SortingDropdownButton label="Title sort order" name="name_sort" value={name_sort} handleClick={handleSortingClick}/>{' '}
                  </div>
                </div>
              </div>
              <div className="card-body">
                {
                  screenContext.state.loading ? <Spinner color="primary" /> : <EpisodesList items={items} isDirectUpload={isDirectUpload}/>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Episodes;