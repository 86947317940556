import React, {useState} from 'react'
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {useDropzone} from 'react-dropzone'
import WarningMessage from './WarningMessage'


//https://github.com/react-dropzone/react-dropzone/issues/321
const imageOnDrop = (acceptedFiles, setImage, setFiles, setErrors) => {
  //TODO: replace setFiles with setArtwork
  // exactly
  const errors = [];
  const img = new Image();
  img.onload = function(){
    if(img.width < 1) {
      errors.push(`This image must be more than 1 pixels wide.`);
    }
    if(img.height < 1) {
      errors.push(`This image must be more than 1 pixels height.`);
    }
    
    if(errors.length > 0) {
      // error hanlding...
      setErrors(errors)
      setFiles([]);
      //  setArtwork(null);
    } else {
      setErrors([])
      setFiles(acceptedFiles)
      setImage(acceptedFiles[0])
      //handleUpload(acceptedFiles[0]);
    }
  }
  
  img.src = URL.createObjectURL(acceptedFiles[0])  
  

  //setFiles(acceptedFiles)
}

const ImageUploader = ({setImage}) => {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  // const [image, setImage] = useState(null);
  
  const {acceptedFiles, rejectedFiles, getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles, rejectedFiles) => {
      if(rejectedFiles.length > 0) {
        
        const errors = ['Only *.jpeg, *.png and *.ico images will be accepted'];
        rejectedFiles.forEach((file) => {
          errors.push(`Rejected file: ${file.path}`)
        })
        setFiles([]);
        setImage(null);
        setErrors(errors)
      }
      if(acceptedFiles.length > 0) {
        imageOnDrop(acceptedFiles, setImage, setFiles, setErrors)
      }      
    }
  });
    
  return (
    <>
      <Row>
        <WarningMessage 
          text={`Please make sure your image size is more than 1px by 1px. (Only *.jpeg and *.png images will be accepted)`}
          files={files}
          setImage={setImage}
          removeFile={setFiles}
          errors={errors}/>
        <div className="col-12 col-md-6">        
          <div {...getRootProps({className: 'dropzone'})}>
            <div className="dz-default dz-message">
              <input {...getInputProps()}/>
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </div>        
        </div>          
      </Row>
    </>
  )
}

export default ImageUploader;