import React from 'react'
import { ListGroup } from 'reactstrap';
import TreeNode from './TreeNode'

export default function Tree({treeData}) {
  return (
    <ListGroup>
      {
        treeData.map((node) => (
          <TreeNode node={node} key={node.id}/>
        ))
      }
    </ListGroup>
  )
}
