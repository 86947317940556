import React from "react";
import EpisodeListItem from './EpisodeListItem'

const EpisodesList = ({items, isDirectUpload }) => {
  return (
    <div className="list-group list-group-flush my-n3">
      {
        items.map((item) =>
          <EpisodeListItem
            key={item.id}
            showId={item.show_id}
            audiopostId={item.id}
            enabled_mailing={item.enabled_mailing}
            name={item.episode_title}
            fileName={item.name}
            audioDate={item.audio_date}
            isDirectUpload={isDirectUpload}
            isDraft={item.is_draft === true }
            publishStatus={item.is_draft === true ? "Draft" : "Published/Scheduled"}
            downloadCount={item.download_count}
            r2Uploaded={item.r2_uploaded}
            linkStatus={item.dashboard_link_status}
          />
        )
      }
    </div>
  )
}

export default EpisodesList;