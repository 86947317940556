import React, {useState, useContext} from "react";
import {Context as ModalContext} from '../context/ModalContext'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const SimpleModal = ({
  className,
  modalClassName,
  id,
  buttonLabel,
  title,
  message,
  htmlMessage,
  buttonHandler
}) => {
  // const {state, reset} = useContext(ModalContext);
  const [isOpen, setIsOpen] = useState(false);

  const reset = () => {
    setIsOpen(!isOpen);
  }

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
    if(buttonHandler) {
      buttonHandler()
    }
  }

  return (
    <>
      <Button id={id} color={className} onClick={reset}>{buttonLabel}</Button>
      <Modal isOpen={isOpen} className={modalClassName}>
        <ModalHeader toggle={reset}>{title}</ModalHeader>
        <ModalBody>
          <BodyMessage message={message} htmlMessage={htmlMessage}/>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleButtonClick}>Done</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const BodyMessage = ({message, htmlMessage}) => {
  if(htmlMessage) {
    return <div dangerouslySetInnerHTML={{__html: htmlMessage}}/>
  }
  return (
    <div>{message}</div>
  )
}

export default SimpleModal;
