import React, {useEffect, useState, useContext} from "react";
import {Row, CardDeck, Card, CardBody, col} from 'reactstrap';
import justcastApi from '../api/justcast'
import {dateRangeType} from '../libs/datediff'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as MenuContext} from '../context/MenuContext'
import {HeaderPhotoCardWidget, SummaryCard, TimeLineCard} from '../components/OverviewWidget'
import {SummaryStateCard, ColumnChartCard, ImageCard } from '../components/Metrics'
import {PermissionModal} from '../components/FileUploader'
import {DownloadChartOverTime} from '../components/private-podcast'

const ShowOverview = (props)  => {
  const id = props.match.params.id;
  const {setPodcastPageMenu} = useContext(MenuContext);
  const modalContext = useContext(ModalContext);
  const { add } = modalContext;
  const [audioposts, setAudioposts] = useState([]);
  const [rowItems, setRowItems] = useState([]);
  const [isAdvancedPrivate, setIsAdvancedPrivate] = useState(false);

  useEffect(() => {
    justcastApi.get(`/v2/shows/${id}`)
    .then((res) => {
      setPodcastPageMenu({
        title: 'Overview',
        subtitle: res.data.podcast_title,
        currentPageId: 'overview',
        currentTabId: 'overview',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website
      });

      setRowItems([
        {label: 'Uploaded Episodes', value: res.data.total_audioposts_count, link: `/shows/${id}/episodes`},
        {label: 'Published Episodes', value: res.data.published_count, link: `/shows/${id}/episodes?type=published`},
        {label: 'Scheduled', value: (res.data.total_audioposts_count - res.data.published_count), link: `/shows/${id}/episodes?type=scheduled`}
      ]);

      setAudioposts(res.data.audioposts)
      setIsAdvancedPrivate(res.data.is_advanced_private_feed)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [id])

  return (
    <div className="container-fluid">
      <PermissionModal/>
      <Row>
        <div className="col-12">
          <div className="card-deck">
            <SummaryStateCard
              id={"summary"}
              title={"Summary Stats"}
              subtitle={""}
              stats={rowItems}
              loading={false}
            />
          </div>
        </div>
      </Row>
      <Row style={{paddingTop: "20px"}}>
        <div className="col-12">
          <DownloadChartOverTime showId={id} isAdvancedPrivate={isAdvancedPrivate}/>
        </div>
      </Row>
      {/* <Row>
        <div className="col-12">
          <TimeLineCard
            showId={id}
            title="Recent Uploads"
            link={`/shows/${id}/episodes`}
            linkTitle="View all"
            items={audioposts}
          />
        </div>
      </Row> */}
    </div>
  )
}

export default ShowOverview;