import React from 'react'
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'video']
  ]
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link'
];

export default function CustomEmailText({
  bodyText, quillRef, onChange
}) {
  return (
    <ReactQuill
      ref={quillRef}
      value={bodyText} 
      modules={modules}
      formats={formats}
      onChange={onChange}
      placeholder="Here's your RSS feed. Hope you enjoy."
    />
  )
}
