import React from 'react'
import { CardHeader, CardFooter, Button } from 'reactstrap';

export default function ContentPagnation(props) {
  const {id, totalPage, currentPage, updatePage, title, handleReRun} = props;

  const handleNextPage = () => {
    if(totalPage > (currentPage + 1)) {
      updatePage(currentPage + 1);
    }
  }

  const handlePrev = () => {
    if(currentPage > 0) {
      updatePage(currentPage - 1);
    }
  }

  const handleClick = () => {
    handleReRun(id);
  }

  return (
    <>
      <CardHeader>
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title">{title}</h4>
          </div>
          <div className="col-auto">
            <Button size="sm" color="primary" onClick={handleClick}>Re-run</Button>
          </div>
        </div>
      </CardHeader>
      {props.children}
      <CardFooter>
        <div style={{
          display: 'flex',
          flexDirection: 'row-reverse'
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: "10px"
          }}>
            <Button size="sm" name="previous page" onClick={handlePrev}>Previous</Button>
            <div>{currentPage + 1} / {totalPage}</div>
            <Button size="sm" name="next page" onClick={handleNextPage}>Next</Button>
          </div>
        </div>
      </CardFooter>
    </>
  )
}
