import React, {useContext, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Button } from 'reactstrap';
import {Context as AuthContext} from '../context/AuthContext'
import {Context as MenuContext} from '../context/MenuContext'

const ChangePassword = () => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const {state, clearErrorMessage, updatePassword} = useContext(AuthContext);
  const menuContext = useContext(MenuContext);

  useEffect(() => {
    menuContext.setMainPageLeftMenu({title: 'Change password', subtitle: state.email, currentPageId: 'home'});
  }, [])

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-5 col-xl-4 my-5">
          <h1 className="display-4 text-center mb-3">Set new password</h1>
          <Form onSubmit={(e) => {
            e.preventDefault()
            updatePassword({password, passwordConfirmation});
          }}>
              <FormGroup>
                <label>New password</label>
                <Input type="password" 
                  name="new_password"
                  placeholder="New password"
                  value={password} 
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}/>
              </FormGroup>
              <FormGroup>
                <label>Confirm new password</label>
                <Input type="password" 
                  name="confirm_new_password"
                  placeholder="Confirm new password"
                  value={passwordConfirmation} 
                  onChange={(event) => {
                    setPasswordConfirmation(event.target.value)
                  }}/>
              </FormGroup>
              {
                state.errorMessages && state.errorMessages.length > 0 &&
                <div>{state.errorMessages.join(' ')}</div>
              }              
              <Button color="primary"
                type="submit"
                className="btn btn-primary btn-block mt-3" 
              >Update password</Button>
          </Form>
          {/* <br/>
          <div className="text-center">
            <small className="text-muted text-center">
              <Link to="/dashboard">Cancel</Link>
            </small>            
          </div> */}
        </div>
      </div>
    </div>
  )  
}

export default ChangePassword;