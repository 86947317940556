import React, {useState, useRef, useEffect} from 'react'
import ReactPlayer from 'react-player'
import { Play, Pause, Trash } from 'react-feather';

const buttonStyle = {
  height: "40px",
  width: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "100%",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "#53a0e4",
  backgroundColor: "#53a0e4",
  cursor: "pointer",
  flexShrink: 0
}

const metaStyle = {
  display: "flex",
  gap: '4px'
}

const containerStyle = {
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "rgba(255,255,255,.1)",
  // minHeight:"88px",
  display: "flex",
  gap: "10px",
  flexDirection: "column",
  alignContent: "space-between"
}

const getFileSizeStr = (fileSize) => {
  if(fileSize < 1000000000) {
    return `${(Math.round(fileSize / 10000) / 100).toLocaleString()} MB`
  } else {
    return `${(Math.round(fileSize / 10000000) / 100).toLocaleString()} GB`
  }
}

export default function AudioPreviewPlayer(props) {
  const { url, title, fileSize, handleDurationChanged, fileDurationStr } = props;
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [durationStr, setDurationStr] = useState(null);
  const [played, setPlayed] = useState(null);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [onSeeking, setOnSeeking] = useState(true);
  const [fileInfo, setFileInfo] = useState([]);

  useEffect(() => {
    const strs = [];

    if(fileSize) {
      const str = getFileSizeStr(fileSize)
      strs.push(str);
    }
    if(durationStr || fileDurationStr) {
      if(strs.length > 0) {
        strs.push("-")
      }
      strs.push(durationStr || fileDurationStr);
    }

    setFileInfo(strs);
  }, [fileSize, durationStr, fileDurationStr])

  const handlePlayPauseClick = () => {
    setPlaying(!playing)
  }

  return (
    <>
      <div>
        <div style={containerStyle}>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 20px 0 20px"
          }}>
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "20px"
            }}>
              <div
                style={buttonStyle}
                name="play button"
                onClick={handlePlayPauseClick}
              >
                {playing ? <Pause/> : <Play/> }
              </div>
              <div >
                <div>{title}</div>
                <div style={metaStyle} className="text-muted">
                  {fileInfo.map((str, idx) => <div key={idx}>{str}</div>)}
                </div>
              </div>
            </div>
            <div style={{flexShrink: 0}}>
              {props.children}
            </div>
          </div>
          <div>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 10px 0 10px"
            }} className="text-muted">
              <small>{played}</small>
              <small>{durationStr}</small>
            </div>
          </div>
        </div>
        <input
          className='jc-preview-player-slider'
          type="range"
          min={0}
          max={duration}
          step={0.01}
          value={playedSeconds}
          onChange={(e) => {
            setPlayedSeconds(Number(e.target.value));
          }}
          onPointerDown={() => {
            setOnSeeking(false);
          }}
          onPointerUp={() => {
            setOnSeeking(true)
            playerRef.current.seekTo(playedSeconds, 'seconds')
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            background: `linear-gradient(90deg, #53a0e4 ${duration <= 1 ? 0 : playedSeconds * 100 / duration}%,  #d6f6fa ${duration <= 1 ? 0 : playedSeconds * 100 / duration}% )`
          }}
        />
      </div>
      <ReactPlayer
        ref={playerRef}
        url={url}
        controls={false}
        width="0%"
        height="0%"
        onDuration={(res) => {
          const date = new Date(null);
          date.setSeconds(res);
          setDuration(res);
          const durationTimeStr = date.toISOString().slice(11, 19);
          setDurationStr(durationTimeStr)
          if(handleDurationChanged) {
            handleDurationChanged(res, durationTimeStr)
          }
        }}
        onProgress={(res) => {
          const date = new Date(null);
          if(onSeeking) {
            date.setSeconds(res.playedSeconds);
            setPlayedSeconds(res.playedSeconds)
            setPlayed(date.toISOString().slice(11, 19))
          }
        }}
        config={{
          file: {
            attributes: {
              preload: 'none'
            }
          }
        }}
        playing={playing}
      />
    </>
  )
}
