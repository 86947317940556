import React from "react";
import {Rnd} from 'react-rnd';

const WaveformDnD = ({defaultLocation, handleDragStop, handelResizeStop, enabled}) => {
  if(defaultLocation && enabled) {
    return (
      <Rnd
        default={{ 
          x: defaultLocation.x, 
          y: defaultLocation.y,
          width: defaultLocation.width,  
          height: defaultLocation.height
        }}
        onDragStop={(e, d) => {
          handleDragStop(d.x, d.y);
        }}
        onResizeStop={(e, d, refToElement, delta, pos) => {
          handelResizeStop(delta.width, delta.height, pos.x, pos.y)
        }}
        // enableResizing // this means disabled, which kinda funny, but yay.
        bounds="parent"
        // style={{backgroundColor: 'rgb(52, 52, 52)', opacity: 0.6}}
        style={{borderColor: "#F17BB0", borderStyle: "dotted", borderWidth: "2px"}}
        >  
      </Rnd>
    )
  }
           
  return null;
}

export default WaveformDnD;