import { atom } from "jotai";

const options = [
  {name: "audioposts.name", label: "Episode title", ordering: "ASC", selected: false},
  {name: "audioposts.audio_date", label: "Publish date", ordering: "ASC", selected: false},
  {name: "audioposts.episode_number", label: "Episode number", ordering: "ASC", selected: false},
  {name: "season_number", label: "Season number", ordering: "ASC", selected: false},
];

export const availableOptionsAtom = atom([]);
export const selectedOrdersAtom = atom([]);
export const changeOptionAtom = atom(null);

export const setAvaiableOptionsAtom = atom((get) => get(availableOptionsAtom), (_get, set, selected) => {
  set(availableOptionsAtom, () => {
    const items = [];
    const hashSelected = {};

    for(let i = 0; i < selected.length; i++) {
      const {name} = selected[i];
      hashSelected[name] = true;
    }

    for(let i = 0; i < options.length; i++) {
      const option = options[i];
      const name = option['name'];
      if(hashSelected[name]) {
        items.push({...option, selected: true});
      } else {
        items.push({...option, selected: false});
      }
    }

    return items;
  });
})

export const setSelectedOrdersAtom = atom((get) => get(selectedOrdersAtom), (_get, set, input) => {
  set(selectedOrdersAtom, () => {
    const hashOptions = {};
    for(let i = 0; i < options.length; i++) {
      const {name} = options[i];
      hashOptions[name] = true;
    }

    const items = [];
    for(let i = 0; i < input.length; i++) {
      const option = input[i];
      const name = option['name'];
      if(hashOptions[name]) {
        items.push(option);
      }
    }

    return items;
  });
})

export const handleDnDSelectedOrdersAtom = atom(null, (get, set, result) => {
  const orders = get(selectedOrdersAtom);
  const items = Array.from(orders);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);
  set(selectedOrdersAtom, () => {
    return items
  })  
})

export const setChangeOptionAtom = atom(null, (get, set, changedOption) => {
  const availableOptions = get(availableOptionsAtom);  
  const selectedOrders = get(selectedOrdersAtom);

  set(changeOptionAtom, () => {
    return changedOption;
  })

  set(availableOptionsAtom, () => {
    const options = [];  
    for(let i = 0; i < availableOptions.length; i++) {
      const option = {...availableOptions[i]};
      if(option["name"] === changedOption["name"]) {
        options.push({...changedOption})
      } else {
        options.push(option)
      }
    }  
    return options;
  })

  set(selectedOrdersAtom, () => {
    const options = [];
    let found = false;
    for(let i = 0; i < selectedOrders.length; i++) {
      const selectedItem = {...selectedOrders[i]};
      if(selectedItem['name'] === changedOption["name"]) {
        found = true;
        if(changedOption["selected"]) {
          options.push({...changedOption});
        }        
      } else {
        options.push(selectedItem);
      }
    }
    
    if(found === false) {
      options.push({...changedOption});
    }

    return options;
  })
})