import React, { useState, useEffect } from 'react';
import {Row, Spinner, Card, CardBody} from 'reactstrap';
import DateRangePicker from './DateRangePicker'
import DatePickerRange from './DatePickerRange'

const CardGlobalMap = (props) => {
  const {
    datePickerDisplay,
    chartId,
    title,
    subtitle,
    loading,
    data,
    optionsData,
    optionSelected,
    fromDate,
    toDate,
    handleOptionChange,
    handleDateChange
  } = props;


  if(loading) {
    return (
      <Card>
        <div className="card-header">
          <h4 className="card-header-title mr-auto">{title}</h4>
        </div>
        <div className="card-body"><Spinner/></div>
      </Card>
    )
  } else {

    return (
      <Card>
        <div className="card-header">
          <div>
            <div style={{fontSize: 24, fontWeight: 'bold'}}>{title}</div>
            {
              subtitle ? <p className="text-muted mb-3">{subtitle}</p> : null
            }
          </div>
        </div>
        <CardBody>
          <div className="row align-items-center">
            <div className="col-4">
              <DateRangePicker
                options={optionsData}
                selected={optionSelected}
                handleChanged={handleOptionChange}
              />
            </div>
          </div>
          <br/>
          <DatePickerRange
            display={datePickerDisplay}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={(x) => {
              handleDateChange('fromDate', x)
            }}
            setToDate={(x) => {
              handleDateChange('toDate', x)
            }}
          />
          <br/>
          { props.children }
        </CardBody>
      </Card>
      // <div>
      //   <div id={chartId}>
      //     <div >
      //       <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      //         <div>
      //           <div style={{fontSize: 24, fontWeight: 'bold'}}>{title}</div>
      //           {
      //             subtitle ? <p className="text-muted mb-3">{subtitle}</p> : null
      //           }
      //         </div>
      //         <div className="col-4">
      //           <DateRangePicker
      //             options={optionsData}
      //             selected={optionSelected}
      //             handleChanged={handleOptionChange}
      //           />
      //         </div>
      //       </div>
      //     </div>
      //     <hr/>
      //     <div >
      //       <DatePickerRange
      //         display={datePickerDisplay}
      //         fromDate={fromDate}
      //         toDate={toDate}
      //         setFromDate={(x) => {
      //           handleDateChange('fromDate', x)
      //         }}
      //         setToDate={(x) => {
      //           handleDateChange('toDate', x)
      //         }}
      //       />
      //     </div>
      //   </div>
      //   {
      //     props.children
      //   }
      // </div>
    )
  }
}

export default CardGlobalMap