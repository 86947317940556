import createDataContext from './createDataContext';

const screenReducer = (state, action) => {
  switch(action.type) {
    case 'start_fetching':
      return {...action.payload}
    case 'finish_fetching': 
      return {...action.payload}
    default:
      return state
  }
}

const startFetching = dispatch => {
  return () => {
    dispatch({type: 'start_fetching', payload: {loading: true}})
  }
}

const finishFetching = dispatch => {
  return () => {
    dispatch({type: 'finish_fetching', payload: {loading: false}})
  }
}

export const {Provider, Context} = createDataContext(
  screenReducer,
  {
    startFetching,
    finishFetching,
  },
  {
    loading: false
  }
)

export default {}