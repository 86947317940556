import React, {useEffect, useState, useContext} from "react";
import { Link, Redirect } from "react-router-dom";
import justcastApi from '../api/justcast';
import UpdateNotification from '../components/UpdateNotification';
import ShowSettingsForm from '../components/ShowSettingsForm';
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AuthContext} from '../context/AuthContext'

const ShowTipjarFee = (props) => {
  const id = props.match.params.id;
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const authContext = useContext(AuthContext)
  const [showName, setShowName] = useState('');
  const [tipJarId, setTipJarId] = useState(null);  
  const [denied, setDenied] = useState(false);
  const [tips, setTips] = useState([]);

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;      
      setShowName(name);          
      
      if(res.data.tip_jar) {        
        setTipJarId(res.data.tip_jar.id)
      }      
      // setCurrencyCode({id: res.data.default_currency_code.id, label: res.data.default_currency_code.code});
      screenContext.finishFetching();

      menuContext.setTipjarMenu({
        title: 'Fees',
        subtitle: name,
        currentPageId: 'tip_jar',
        currentTabId: 'tip_jar_fees',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website        
      });      
    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
      setDenied(true)
    })
  },[id])


  if(denied) {
    return <Redirect to="/dashboard"/>
  }  
  
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="card card-body">
          <div className="row">
            <div className="col">
              <h2 className="mb-2">Fees</h2>
              <p>The amount deposited in your account will be less than the tip amount.  This is breakdown of applicable fees.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table my-4">
                  <thead>
                    <tr>
                      <th className="px-0 bg-transparent border-top-0">
                        <span className="h6">Fee type</span>
                      </th>     
                      <th className="px-0 bg-transparent border-top-0 text-right">
                        <span className="h6">Amount</span>
                      </th>
                    </tr>
                  </thead> 
                  <tbody>
                    <tr>
                      <td className="px-0">Payment processing fee</td>
                      <td className="px-0 text-right">2.9% + $0.30</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="px-0 text-right border-top border-top-2">
                        For a $2.00 tip, you would be paid $1.64.
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="px-0 text-right border-top border-top-2">
                        For a $5.00 tip, you would be paid $4.55.
                      </td>
                    </tr>                    
                  </tbody>                 
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowTipjarFee