module.exports = {
  appId: process.env.REACT_APP_AZURE_APP_ID,
  appSecret: process.env.REACT_APP_AZURE_APP_SECRET,
  scopes: [
    "offline_access",
    "Files.ReadWrite",
    "user.read",
    "Files.ReadWrite.AppFolder"
    // "onedrive.readonly",
    // "onedrive.readwrite",
    // "onedrive.appfolder",
    // "calendars.read"
  ]
}