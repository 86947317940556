import React, {FC, RefObject, useRef, useContext, useState, useEffect} from "react";
// import ReactPlayer from 'react-player'
// import Plyr from 'plyr-react'
// import 'plyr/dist/plyr.css'
import { Link } from "react-router-dom";
import 'flatpickr/dist/themes/dark.css'
import Flatpickr from 'react-flatpickr'
import ReactQuill from 'react-quill';
import { Form, FormGroup, Input, Button, Row, Card, CardBody, InputGroup } from 'reactstrap';
import FormGroupInputSelect from '../components/FormGroupInputSelect';
import FormGroupInput from '../components/FormGroupInput';
import UpdateNotification from '../components/UpdateNotification';
import {FileUploader, FileUploadProgressbar} from '../components/FileUploader'
import {EpisodeSeasonSelect} from '../components/seasons'
import {LocationInput} from '../components/location';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'video']
  ]
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

const EpisodeForm = (props) => {
  const {  
    quillRef,
    showNameEditable,
    isCreateForm,
    backLink,
    name,
    season_id,
    description,
    artworkUrl,
    audio_date,
    location_geo,
    location_name,  
    episodeNumber,
    audioUrl,
    newFileSize,
    fileTypeGroup,
    created_at,
    explicitTypes,
    explicitTypeId,
    episodeTypes,
    episodeTypeId,
    isDirectUpload,
    itunesKeywords,
    supportFiles,
    seasons,
    fileSize,
    uploadedFileSize,
    uploadFile,
    directUploadStatusCode, 
    handleRemoveImage, 
    handleMediaFileChange,
    handleSelectionChange,
    handleQuillEditorChange,
    handleDatepickerChange,
    handleInputValueChange,
    handleUpdate,
    handleToggleDraft,
    handleTitleChange,
    handleUploadFileChange,
    setConvertToMp3,
    handleArtworkChange,
    handleArtworkFileChange,
    submitButtonLabel,
    saveButtonLabel,
    handleLocationChange,
    handleSeasonChange,
    handleAddSeason
  } = props;

  const [imageMessages, setImageMessages] = useState([]);
  const [audioMessages, setAudioMessages] = useState([]);
  
  const removeImageMessages = () => {
    handleRemoveImage();
    setImageMessages([]);
  }

  
  if(name || isDirectUpload || showNameEditable) {    
    return (
      <Form className="mb-4">
        <FormGroup>
          <label>Episode name</label>
          {
            isDirectUpload ? <>
              <Input type="text" className="form-control" name="name" value={name} placeholder="The title of your new episode" onChange={(event) => {
                handleTitleChange({
                  key: event.target.name,
                  value: event.target.value
                })  
              }}/>
            </> : <>
              {showNameEditable ? null : <small className="form-text text-muted">You can go to Dropbox to change the file name to change the episode name</small>}              
              {
                showNameEditable ? <Input type="text" 
                className="form-control" 
                name="alias_name"
                value={name} 
                onChange={(event) => {
                  handleTitleChange({
                    key: event.target.name,
                    value: event.target.value
                  })  
                }}/> : <Input type="text" className="form-control" name="alias_name" disabled value={name} />
              }                        
            </>
          }
        </FormGroup>
        <Row>
          <div className="col-12">
            <FormGroup>
              <label>Publish date</label>
              <Flatpickr 
                className='form-control mb-3'
                data-enable-time
                value={audio_date ? audio_date : created_at} 
                onClose={handleDatepickerChange}/>          
                {
                  isDirectUpload ? <small className="form-text text-warning">Schedule a future release by change to a future date</small> : null
                }              
            </FormGroup>
          </div>
        </Row>
        <Row>
          <div className="col-12 col-md-6">
            <EpisodeSeasonSelect seasons={seasons} season_id={season_id} handleSeasonChange={handleSeasonChange} handleAddSeason={handleAddSeason}/>
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInput
              inputLabel='Episode Number'
              inputType='text'
              inputName="episode_number"
              inputValue={episodeNumber ? episodeNumber : ""}
              inputClassName="form-control"
              inputPlaceholder="1, 2, 3..."
              handleInputChange={handleInputValueChange}
            />
          </div>          
        </Row>
        {
          isDirectUpload ? <hr/> : null 
        }
        <FileUploadProgressbar
          isCreateForm={isCreateForm}
          url={audioUrl}
          newFileSize={newFileSize}
          fileTypeGroup={fileTypeGroup}
          isDirectUpload={isDirectUpload}
          totalSize={fileSize}
          uploadedSize={uploadedFileSize}
          statusCode={directUploadStatusCode}
          handleMediaFileChange={handleMediaFileChange}
          handleUploadFileChange={handleUploadFileChange}
          handleDurationChange={handleInputValueChange}
          clearMessages={setAudioMessages}
        />
        {
          isDirectUpload ? 
          <>
            <br/>
            <FileUploader 
              title={`${isCreateForm ? '' : 'Replace'} Audio File`}
              acceptFile={supportFiles}
              uploadFile={uploadFile}
              messages={audioMessages}
              setMessages={setAudioMessages}
              messageBoxSubtitle="Please make sure your media file is in audio or video format."
              handleFileChange={handleMediaFileChange}
              handleUploadFileChange={handleUploadFileChange}
              setConvertToMp3={setConvertToMp3}
            />
          </> : null
        }        
        <hr/>
        <Row>
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select" 
              inputLabel="Explicit type"
              inputName='explicit_type_id'
              optionLabel="label"
              optionId="id"
              mutedText="If you specify true, indicating the presence of explicit content, Apple Podcasts displays an Explicit parental advisory graphic for your podcast.  Podcasts containing explicit material aren’t available in some Apple Podcasts territories."
              options={explicitTypes}
              handleInputChange={handleSelectionChange}
              inputValue={explicitTypeId}
            /> 
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select" 
              inputLabel="Episode type"
              inputName='episode_type_id'
              optionLabel="name"
              optionId="id"
              mutedText="Use Trailer for a short, promotional piece of content that represents a preview of a show.  Use bonus for extra content (for example, behind the scenes information or interviews with the cast)"
              options={episodeTypes}
              handleInputChange={handleSelectionChange}
              inputValue={episodeTypeId}
            />
          </div>
        </Row>
        <hr/>
        <Card>
          <CardBody>
            <Row className="align-items-center">
              <div className="col-auto">
                <img src={artworkUrl ? artworkUrl : 'https://via.placeholder.com/150'} className="rounded artwork-img"/>
              </div>
              <div className="col ml-n2">
                <FormGroupInput
                  inputLabel='Artwork URL'
                  inputType='text'
                  inputName="artwork_url"
                  inputValue={artworkUrl ? artworkUrl : ""}
                  inputClassName="form-control"
                  inputPlaceholder="Put your artwork URL here"
                  mutedText="This a feature request for Beeerrrta.  You can paste any image url you host on the internet here, instead of uploading one."
                  handleInputChange={handleInputValueChange}
                />
              </div>
              <div className="col-auto">
                <Button color="danger" size="sm" disabled={artworkUrl ? false : true} onClick={removeImageMessages}>
                  <i className="fe fe-trash"/> Remove
                </Button>
              </div>
            </Row>
          </CardBody>
        </Card>
        {
          isDirectUpload && isCreateForm ? <FileUploader 
            title=""
            squareArtwork={true}
            acceptFile={['image/jpeg', 'image/png']}
            messageBoxSubtitle="Upload an artwork/photo for you episode."
            messages={imageMessages}
            setMessages={setImageMessages}
            handleFileChange={handleArtworkChange}
            handleUploadFileChange={handleArtworkFileChange}
          /> : null
        }        
        <hr/>
        <div className="row">
          <div className="col-12">
            <FormGroupInput
              inputLabel='Keywords'
              inputType='text'
              inputName="itunes_keywords"
              inputValue={itunesKeywords ? itunesKeywords : ""}
              inputClassName="form-control"
              inputPlaceholder="e.g. keyword1, keyword2, keyword3..."
              mutedText="Defaults to your podcast keywords."
              handleInputChange={handleInputValueChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <LocationInput
              handleLocationChange={handleLocationChange}
              location_name={location_name}
              location_geo={location_geo}
            />            
          </div>
        </div>
        <hr/>
        <FormGroup>
          <label>Description</label>
          <ReactQuill
            ref={quillRef}
            value={description ? description : ""} 
            modules={modules}
            formats={formats}
            onChange={handleQuillEditorChange}/>
        </FormGroup>
        {props.children}
        <hr className="mt-5 mb-5"/>
        <UpdateNotification/>
        <div style={{marginBottom: "80px", display: "flex", columnGap: "10px", justifyContent: "flex-end"}}>
          <div className="btn btn-warning" onClick={handleToggleDraft}>{submitButtonLabel}</div>
          <div className="btn btn-primary" onClick={handleUpdate}>{saveButtonLabel}</div>
          <Link to={backLink} className="btn btn-link text-muted">Cancel</Link>
        </div>
      </Form>
    )
  } else {
    return null;
  }
}

export default EpisodeForm;