import React from "react";
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';

const ShowNoteCard = ({name, artworkUrl, description}) => {
  return (
    <Card>
      <CardBody>
        <div className="mb-3">
          <div className="row align-items-center">
            <div className="col-auto">
              <span className="avatar">
                <img src={artworkUrl} alt="podcast artwork" className="avatar-img rounded-circle"/>
              </span>              
            </div>
            <div className="col ml-n2">
              <h4 className="card-title mb-1">{name}</h4>
            </div>
          </div>
        </div>
        <p className="mb-3">
          {description}
        </p>
      </CardBody>
    </Card>
  )
}

export default ShowNoteCard;