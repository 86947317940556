import React, {useContext, useEffect} from 'react'
import { Spinner } from 'reactstrap';
import {Elements} from 'react-stripe-elements';
import justcastApi from '../api/justcast'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as MenuContext} from '../context/MenuContext'
import StripeUpdateCardForm from '../components/StripeUpdateCardForm'

const PlanCardUpdate = () => {
  const authContext = useContext(AuthContext);
  const menuContext = useContext(MenuContext);
  const email = authContext.state.email;
  const stripe_plan_id = authContext.state.stripe_plan_id;

  useEffect(() => {
    menuContext.setMainPageLeftMenu({title: 'Update Credit Card', subtitle: email, currentPageId: 'home'});
  }, [])

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="card">
              <div className="card-body">
                {
                  stripe_plan_id ? 
                  <Elements>
                    <StripeUpdateCardForm/>
                  </Elements> : <p>You haven't subscribe to any plan yet.</p>
                }
              </div>
            </div>
          </div>
        </div>        
      </div>
    </>
  )
}

export default PlanCardUpdate;