import React from 'react';
import { Link } from 'react-router-dom';
import ClipboardModalComponent from './ClipboardModalComponent'

const HeaderNavItem = ({to, className, text, isUrl, type}) => {
  if(type === 'clipboard') {
    return (
      <ClipboardModalComponent
        id="text"
        className={className}
        buttonLabel={text}
        inputValue={to}
      />
    )
  }

  if(isUrl) {
    return (
      <li className="nav-item">
        <a href={to} className={className} target="_blank">{text}</a>
      </li>
    )
  }

  return (
    <li className="nav-item">
      <Link to={to} className={className}>{text}</Link>
    </li>
  )
}

const PageHeaderNavItems = ({headerNavItems}) => {
  if(headerNavItems && headerNavItems.length > 0) {
    return (
      <div className="row align-items-center">
        <div className="col">                  
          <ul className="nav nav-tabs nav-overflow header-tabs">
            {
              headerNavItems.map((navItem) => 
                <HeaderNavItem 
                  key={navItem.text}
                  text={navItem.text}
                  to={navItem.to}
                  isUrl={navItem.isUrl}
                  type={navItem.type}
                  className={navItem.className}
                />
              )
            }                              
          </ul>
        </div>
      </div>        
    )
  } else {
    return null;
  }
}

export default PageHeaderNavItems;