import React from 'react';
import { Row, Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { Link } from "react-router-dom";

export default function PersonTable(props) {
  const {showId, people, handleDeleteClicked} = props;

  if(people && people.length > 0) {
    return (
      <Card>
        <CardHeader>
          <h3 className="card-header-title">People</h3>
          <div className='text-right'>
            <Link to={`/shows/${showId}/people_sorting`}>
              <Button color="primary" size="sm">Sort People</Button>
            </Link>
          </div>
        </CardHeader>
        <CardBody>
          <div className="list-group list-group-flush my-n3">
            {
              people.map((person) => <div key={person.id} className="list-group-item">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <Link to={`/shows/${showId}/people/${person.id}`} className="avatar">
                      {person.img ? <img src={person.img} alt={person.name} className="avatar-img rounded-circle"/> : <span className="avatar-title rounded-circle">{person.name.slice(0, 2)}</span>}
                    </Link>
                  </div>
                  <div className="col ml-n2">
                    <h4 className="mb-1"><Link to={`/shows/${showId}/people/${person.id}`}>{person.name}</Link></h4>
                    <div><span className="badge badge-primary">{person.group}</span> <span className="badge badge-primary">{person.role}</span></div>
                    {/* <p>{person.description}</p> */}
                    {/* {person.href ? <p>Home page: <a href={person.href} target="_blank">{person.href}</a></p> : null} */}
                  </div>
                  <div className="col-auto ">
                    <div className="btn btn-danger btn-sm" onClick={() => {
                      handleDeleteClicked(person.id)
                    }}>
                      <i className="fe fe-trash-2"/> Delete
                    </div>
                  </div>
                </div>
              </div>)
            }
          </div>
        </CardBody>
      </Card>
    );
  }

  return null;

}


// <div>
//         {props.children}
//         {people.map((person) => <Card key={person.id}>
//           <CardBody>
//             <div className="mb-3">
//               <Row className="align-items-center">
//                 <div className="col-auto">
//                   <Link to={`/shows/${showId}/people/${person.id}`} className="avatar avatar-md">
//                     {person.img ? <img src={person.img} alt={person.name} className="avatar-img rounded"/> : <span className="avatar-title rounded-circle">{person.name.slice(0, 2)}</span>}
//                   </Link>
//                 </div>  
//                 <div className="col ml-n2">
//                   <h4 className="mb-1"><Link to={`/shows/${showId}/people/${person.id}`}>{person.name}</Link></h4>
//                   <p>{person.description}</p>
//                   {person.href ? <p>Home page: <a href={person.href} target="_blank">{person.href}</a></p> : null}
//                 </div> 
//                 <div className="col-auto d-none d-sm-block">
//                   <div className="btn btn-danger btn-sm" onClick={() => {
//                     handleDeleteClicked(person.id)
//                   }}>
//                     <i className="fe fe-trash-2"/> Delete
//                   </div>
//                 </div>
//               </Row>
//             </div>
            
//           </CardBody>
//           <CardFooter className="card-footer-boxed">
//             <div className="row align-items-center">
//               <div className="col-6 col-md-auto"><span className="badge badge-primary">{person.group}</span> <span className="badge badge-primary">{person.role}</span></div>
//             </div>
//           </CardFooter>
//         </Card>)}
//       </div>