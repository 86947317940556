import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import * as serviceWorker from './serviceWorker';
import {setNavigator} from '../src/libs/navigationRef'
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './assets/stylesheets/theme-dark.min.css'
import './assets/stylesheets/custom.css'
import './assets/stylesheets/widget-player.css'
import './assets/stylesheets/mobile-player.css'
import './assets/fonts/feather/feather.min.css'
import './assets/stylesheets/stripe.css'
import './assets/stylesheets/chapters.css'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NHN5344',
}

TagManager.initialize(tagManagerArgs)

const history = createBrowserHistory();

ReactDOM.render(
<Router history={history} ref={() => {setNavigator(history)} }><App /></Router>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
