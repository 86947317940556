import React from "react";
import axios from 'axios'
import { Progress } from 'reactstrap';
import moment from 'moment';

const ItemBody = ({name, width, height, progress, message, duration, id, remoteUrl, createdAt, handleDeleteClick}) => {

  if(remoteUrl) {
    return (
      <>
        <div className="col ml-n2">
          <h4 className="card-title mb-1 name">{width} x {height}</h4>
          <p className="card-text small text-muted mb-1">{duration}</p>
          <p className="card-text small text-muted">Created on <time dateTime={moment(createdAt).format('MM-DD-YYYY')}>{moment(createdAt).format('MMM DD, YYYY')}</time></p>
        </div>
        <div className="col-auto">
          <a href={remoteUrl} download={`${name}_${height}x${width}.mp4`} className="btn btn-sm btn-white d-none d-md-inline-block" target="_blank">Download</a>
        </div>
        <div className="col-auto">
          <div className="btn btn-sm btn-white" onClick={() => {
            handleDeleteClick(id)
          }}>
            <i className="fe fe-trash-2"></i>
          </div>
        </div>           
      </>
    )
  }

  return (
    <div className="col ml-n2">
      <div>{message ? message : "Working hard to generate the vidoe"}</div>
      <Progress animated color="success" value={progress ? progress * 100 : 10} />
    </div>    
  )
}

const PodieoListItem = ({name, width, height, progress, message, duration, id, remoteUrl, createdAt, formatType, handleDeleteClick}) => {

  return (
    <li className="list-group-item px-0">
      <div className="row align-items-center">
        <div className="col-auto">
          <a href={remoteUrl} download={`${name}_${height}x${width}.mp4`} className="avatar avatar-lg" target="_blank">
            <span className="avatar-title rounded bg-white text-secondary">
              <span className="fe fe-film"></span>
            </span>
          </a>
        </div>
        <ItemBody 
          name={name}
          width={width}
          height={height}
          duration={duration}
          message={message}
          progress={progress}
          id={id}
          handleDeleteClick={handleDeleteClick}
          remoteUrl={remoteUrl}
          createdAt={createdAt}
        />
      </div>
    </li>
  )
}

export default PodieoListItem;