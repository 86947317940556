import React, {useEffect, useState, useContext} from "react";
import { Link, Redirect } from "react-router-dom";
import justcastApi from '../api/justcast';
import UpdateNotification from '../components/UpdateNotification';
import ShowSettingsForm from '../components/ShowSettingsForm';
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AuthContext} from '../context/AuthContext'
import { Spinner } from 'reactstrap';
import {TipjarSettingCard, TipsTable} from '../components/Tipjar'

const ShowTips = (props) => {
  const id = props.match.params.id;
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const authContext = useContext(AuthContext)
  const [showName, setShowName] = useState('');
  const [tipJarId, setTipJarId] = useState(null);  
  const [denied, setDenied] = useState(false);
  const [tips, setTips] = useState([]);

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;      
      setShowName(name);          
      
      if(res.data.tip_jar) {        
        setTipJarId(res.data.tip_jar.id)
      }      
      // setCurrencyCode({id: res.data.default_currency_code.id, label: res.data.default_currency_code.code});
      screenContext.finishFetching();

      menuContext.setTipjarMenu({
        title: 'Tips Overview',
        subtitle: name,
        currentPageId: 'tip_jar',
        currentTabId: 'tips',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website        
      });      
    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
      setDenied(true)
    })
  },[id])

  useEffect(() => {
    justcastApi.get(`/v1/shows/${id}/tips`)
    .then((res) => {
      const tipsData = res.data.charges.map((charge) => {
        const {id, stripe_checkout_id, stripe_payment_intent_id, stripe_customer_email, stripe_customer_id, currency, amount_discount, amount_tax, amount_subtotal, amount_total, card_brand, last4, card_exp_month, card_exp_year, network, status, receipt_url, seller_product_id, created_at, updated_at, card_exp_date} = charge;
        return {stripe_customer_email, currency, amount_total, card_brand, card_exp_date, last4, receipt_url,  created_at}
      })

      setTips(tipsData)

    })
    .catch((err) => {
      console.log(err)
    })
  }, [id])

  if(denied) {
    return <Redirect to="/dashboard"/>
  }  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <TipsTable data={tips}/>
        </div>
      </div>
    </div>
  )
}

export default ShowTips;