import React from 'react';
import {Row, Card, CardBody} from 'reactstrap';
import {Link} from 'react-router-dom'

const NumberCard = ({title, value, link}) => {
  return (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-muted mb-2">{title}</h6>
            {
              link ? <Link className="text-white" to={link}><span className="h2 mb-0 text-warning">{value}</span></Link> : <span className="h2 mb-0 text-warning">{value}</span>
            }            
          </div>
        </Row>
      </CardBody>
    </Card>
  )
}

export default NumberCard;