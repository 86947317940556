import React, {useEffect, useState, useContext} from "react";
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import {Context as AuthContext} from '../../context/AuthContext'
import {Context as MenuContext} from '../../context/MenuContext'
import PricingTable from '../../components/stripe/PricingTable'

export default function StripeSecretPricingTable() {
  const authContext = useContext(AuthContext);
  const menuContext = useContext(MenuContext);
  const {id, email, plan_name, stripe_id} = authContext.state;

  useEffect(() => {
    menuContext.setMainPageLeftMenu({title: 'Plans', subtitle: "JustCast", currentPageId: 'home'});
  }, []);

  return (
    <>
      {
        stripe_id && <div className="container-fluid">
          <div className="row justify-content-between align-items-center mb-5">
            <div className="col-12 col-md-9 col-xl-7">
              <h2 className="mb-2">
                {plan_name}
              </h2>
              <p className="text-muted mb-md-0">
                {`You're currently subscribed to the ${plan_name}. To view receipts, upgade your plan, and cancel your subscription.  Please click the Dashboard button.`}
              </p>
            </div>
            <div className="col-12 col-md-auto">
              <Button color="primary" to={`/stripe_dashboard`} tag={Link}>
                Billing Portal
              </Button>
            </div>
          </div>
        </div>
      }
      <PricingTable
        email={email}
        userId={id}
        planName={plan_name}
        customerId={stripe_id}
        pricingTableId={process.env.REACT_APP_STRIPE_SECRET_PRICING_TABLE_ID}
      />
      <div className="container-fluid" style={{marginTop: 40, marginBottom: 40}}>
        <div className="row justify-content-between align-items-center mb-5">
          <p>If this pricing doesn't meet your needs, please check out our other <Link to="/plans">pricing</Link> options.</p>
        </div>
      </div>
    </>
  )
}
