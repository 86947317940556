import React from 'react'

export default function UploadStatusLabel({progress}) {
  const statment = () => {
    if(progress) {
      if(progress < 0.04) {
        return "Preparing"
      } else {
        return `Uploading ${progress.toLocaleString("en", {style: "percent"})}`
      }
    }
    return null;
  }

  return (
    <div className='text-warning'>
      {statment()}
    </div>
  )
}
