const startValueChanged = (vals, margin, min, max) => {
  let startTime = vals[0];
  let playingTime = vals[1];
  let endTime = vals[2]; 

  if(startTime > endTime) {
    endTime = startTime + margin > max ? max : startTime + margin// change to max duration
  }
  if(playingTime < startTime) {
    playingTime = startTime;
  }
  return [startTime, playingTime, endTime];
}

const endValueChanged = (vals, margin, min, max) => {
  let startTime = vals[0];
  let playingTime = vals[1];
  let endTime = vals[2]; 

  if(startTime > endTime) {
    startTime = endTime - margin < min ? min : endTime - margin// change to max duration
  }
  if(playingTime < startTime) {
    playingTime = startTime;
  }
  if(playingTime > endTime) {
    playingTime = (startTime + endTime) / 2;
  }
  return [startTime, playingTime, endTime];
}

const playingValueChanged = (vals, margin, min, max) => {
  let startTime = vals[0];
  let playingTime = vals[1];
  let endTime = vals[2]; 
  
  if(playingTime < startTime) {
    startTime = playingTime;
  }
  if(playingTime > endTime) {
    startTime = playingTime;
    endTime = startTime + margin > max ? max : startTime + margin;
  }
  return [startTime, playingTime, endTime];
}

export const updateValues = (newValues, changedIdx, margin, min, max) => {
  // const idx = getChangedIndex(originalValues, newValues);
  
  switch(changedIdx) {
    case 0:
      return startValueChanged(newValues, margin, min, max)
    case 1: 
      return playingValueChanged(newValues, margin, min, max)
    case 2: 
      return endValueChanged(newValues, margin, min, max)
    default:
      return newValues;
  }
}

export default {};