import React from 'react';
// import {Link, Redirect} from 'react-router-dom';
import { differenceInMinutes, formatDistance } from 'date-fns'
import {Row, Spinner, Card} from 'reactstrap';

const LiveviewCard = (props) => {
  const { stats, title, dateTime} = props;
  const plainText = (x) => x;
  const minuteAgo = (x) => {
    // console.log(dateTime)
    return `${formatDistance(dateTime, new Date(x))} ago` ;
  };

  const headers = [
    {
      id: 'audiopost_name',
      name: 'Name',
      format: plainText
    },
    {
      id: 'created_at',
      name: 'Time',
      format: minuteAgo
    },
    {
      id: 'browser',
      name: 'Browser',
      format: plainText
    },
    {
      id: 'city',
      name: 'City',
      format: plainText
    },
    {
      id: 'country',
      name: 'Country',
      format: plainText
    },    
  ]

  return (
    <Card>
      <div className="card-header">
        <h4 className="card-header-title">{title}</h4>
      </div>
      <div className="table-responsive mb-0">
        <table className="table table-sm table-nowrap card-table">
          <thead>
            <tr>
              {
                headers.map((header) => 
                  <th key={header.id}>{header.name}</th>
                )
              }
            </tr>
          </thead>
          {
            stats.length > 0 ? <tbody>
              {
                stats.map((row) => 
                  <tr key={row.id}>
                    {
                      headers.map((header) => 
                        <td key={`${row.id}|${header.id}`}>
                          {header.format(row[header.id])}                          
                        </td>
                      )
                    }
                  </tr>
                )
              }
            </tbody> : null
          }
        </table>
      </div>				
    </Card>
  )
}

export default LiveviewCard;