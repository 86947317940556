import React, {useEffect, useState, useContext} from "react";
import {Redirect} from 'react-router'
import {Context as MenuContext} from '../../../context/MenuContext'
import {Context as ScreenContext} from '../../../context/ScreenContext'
import justcastApi from '../../../api/justcast';
import { NewForm } from '../../../components/team/episodes'

const NewEpisode = (props) => {
  const showId = props.match.params.show_id;
  const screenContext = useContext(ScreenContext);
  const menuContext = useContext(MenuContext);
  const [explicitTypes, setExplicitTypes] = useState([])
  const [episodeTypes, setEpisodeTypes] = useState([])
  const [redirectPage, setRedirectPage] = useState(null);

  useEffect(() => {
    if(showId) {
      screenContext.startFetching();

      justcastApi.get(`/v2/team_podcasts/${showId}`)
      .then((res) => {
        screenContext.finishFetching();
        const show = res.data;
        menuContext.setTeamPodcastPageMenus({
          title: 'New Episode',
          subtitle: show.name,
          currentPageId: 'episodes',
          currentTabId: 'new_episode',
          showId: showId,
          landingPageUrl: show.landing_page_url,
          websiteUrl: show.player_page_link,
          rssFeed: show.rss_feed,
          passwordProtected: show.is_password_protected,
          advancedPrivate: show.is_advanced_private_feed,
          isPrivate: show.is_private,
          isPrivateShow: show.is_private_show,
        });
      })
      .catch((err) => {
        screenContext.finishFetching();
        setRedirectPage("/team_podcasts");
      })

      justcastApi.get('/v1/explicit_types')
      .then((res) => {
        setExplicitTypes(res.data)
      })
      .catch((err) => {
        console.log(err)
      })

      justcastApi.get('/v1/episode_types')
      .then((res) => {
        setEpisodeTypes(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [showId])

  if(redirectPage) {
    return <Redirect to={redirectPage}/>
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12" style={{margin: "0 0 60px 0"}}>
          <NewForm
            showId={showId}
            explicitTypes={explicitTypes}
            episodeTypes={episodeTypes}
            setRedirectPage={setRedirectPage}
          />
        </div>
      </div>
    </div>
  );
}

export default NewEpisode;