import React from "react";
import { FormGroup, Input, FormFeedback } from 'reactstrap';
import FormMutedText from './FormMutedText'

const FormGroupInput = (props) => {
  const {inputLabel, inputType, inputName, inputPlaceholder, inputValue, inputClassName, handleInputChange, mutedText, invalid, invalidMessage} = props;
  return (
    <FormGroup>
      <label>{inputLabel}</label>
      <FormMutedText text={mutedText}/>
      {props.children}
      <Input
        type={inputType}
        name={inputName}
        className={inputClassName}
        value={inputValue}
        invalid={invalid}
        placeholder={inputPlaceholder}
        onChange={(event) => {
          handleInputChange({
            key: event.target.name,
            value: event.target.value
          })
        }}/>
        <FormFeedback>{invalidMessage}</FormFeedback>
    </FormGroup>
  )
}

export default FormGroupInput;
