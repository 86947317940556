import React from 'react'
import {useAtom} from 'jotai'
import { Button, Card, CardBody, CardHeader, Badge, CardFooter } from 'reactstrap';
import ChaptersList from './ChaptersList'
import { playerAtom  } from './playerAtom'

export default function Chapters(props) {
  const {chapters, handleDestroyChapter, handleAddMarker} = props;

  const [playerState] = useAtom(playerAtom);
  const { duration } = playerState;

  const handleAddMarkers = () => {
    handleAddMarker({duration})
  }

  return (
    <>
      {props.children}
      <div className="audiopost-chapters-list" style={{paddingBottom: "20px"}}>
        <Card>
          <CardHeader>
            <h4 className='card-header-title'>Chapters</h4>
            <div style={{display: 'flex', flexDirection: "row-reverse"}}>
              <Badge color="secondary">total: {chapters.length}</Badge>
            </div>
          </CardHeader>
          <CardBody>
            <ChaptersList chapters={chapters} handleDestroyChapter={handleDestroyChapter}/>
          </CardBody>
          {chapters && chapters.length > 0 && <CardFooter>
            <div style={{display: "flex", justifyContent: "space-between", alignContent: 'center'}}>
              <div>Add chapters to audio file</div>
              <div style={{display: 'flex', flexDirection: "row-reverse"}}>
                <Button color="info" size="sm" onClick={handleAddMarkers}>Save</Button>
              </div>
            </div>
          </CardFooter>}
        </Card>
      </div>
    </>
  )
}
