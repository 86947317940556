import React, {useContext, useState, useEffect} from "react";
import justcastApi from '../api/justcast';
import {Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Context as AuthContext} from '../context/AuthContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import FormGroupInputSwitch from '../components/FormGroupInputSwitch';
import AffiliatePayoutSummary from '../components/AffiliatePayoutSummary';

const BecomeAffiliate = () => {
  const screenContext = useContext(ScreenContext);
  const authContext = useContext(AuthContext);
  const menuContext = useContext(MenuContext);
  const [campaign, setCampaign] = useState({});
  const [affiliateCode, setAffiliateCode] = useState(null);
  const [payouts, setPayouts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const email = authContext.state.email;

  useEffect(() => {
    screenContext.startFetching();
    getAffiliateCode()
    setupCampaign()
    getPayouts()
    menuContext.setMainPageLeftMenu({title: 'Affiliate Dashboard', subtitle: email, currentPageId: 'home'});
  }, [])

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  const getAffiliateCode = () => {
    justcastApi.get('/v1/affiliate_code_for_current_user')
    .then((res) => {
      screenContext.finishFetching();
      if(res.data) {
        //console.log(res.data)
        setAffiliateCode(res.data);
      }
    })
    .catch((err) => {
      screenContext.finishFetching();
      // todo error handling
      console.log(err);
    })
  }

  const setupCampaign = () => {
    return justcastApi.get('/v1/affiliate_campaigns')
    .then((res) => {
      if(res.data && res.data[0]) {
        setCampaign(res.data[0]);
      }
    })
    .catch((err) => {
      // todo error handling
      console.log(err);
    })
  }

  const getPayouts = () => {
    return justcastApi.get('/v1/payouts')
    .then((res) => {
      //console.log(res.data)
      setPayouts(res.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const createAffiliate = (data) => {
    // POST affiliate_campaigns/:affiliate_campaign_id/affiliate_codes
    // signed_agreement
    // user_signed_initial
    // paypal_address
    // return as
    // accepted_term_of_service: true
    // affiliate_campaign_id: 1
    // code: "1"
    // created_at: "2020-06-18T07:21:33.889Z"
    // id: 1
    // paypal_address: "jc@gg.com"
    // updated_at: "2020-06-18T07:21:33.889Z"
    // user_id: 1
    // user_signed_initial: "jc"

    const url = `/v1/affiliate_campaigns/${campaign.id}/affiliate_codes`

    if(data.signed_agreement && data.user_signed_initial && data.paypal_address) {
      justcastApi.post(url, data)
      .then((res) => {
        getAffiliateCode()
        setupCampaign()
        getPayouts()
      })
      .catch((err) => {
        console.log(err)
      })
    } else {
      toggleModal();
    }
  }

  const handlePaypalChange = (paypal) => {
    setAffiliateCode({...affiliateCode, ...{paypal_address: paypal}});
  }

  return (
    <div className="main-content">
      {
        screenContext.state.loading ? <></> :
        <>
        {
          affiliateCode ?
            <AffiliatePayoutSummary affiliateCode={affiliateCode} payouts={payouts} handlePaypalChange={handlePaypalChange}/>
            : (
              <>
                <WarningModal submittingData={campaign} modal={showModal} toggle={toggleModal}/>
                <AffiliateWelcome affiliateCode={affiliateCode} createAffiliate={createAffiliate}/>
              </>
            )
        }
        </>
      }
    </div>
  )
}

const AffiliateWelcome = ({affiliateCode, createAffiliate}) => {
  const [agreement, setAgreement] = useState({})
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  }
  return (
    <>
      <TermsOfServiceModal modal={showModal} toggle={toggleModal}/>
      <div className="pt-7 pb-6">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6">
              <h1 className="display-3 text-center text-white">
                JustCast podcast affiliate program
              </h1>
              <p className="lead text-center text-muted">
                You can earn a 20% commission on the first payment for each podcast customer you refer to JustCast.
              </p>
              <p className="lead text-center text-muted">
                Sign up below and you'll get a unique referral link you can share on your blog, podcast, Twitter, or to your email newsletter list.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>Your initial</label>
              <Input
                placeholder="G.G."
                name="user_signed_initial"
                value={agreement.user_signed_initial ? agreement.user_signed_initial : ''}
                onChange={(e) => {
                  setAgreement({...agreement,  ...{[e.target.name]: e.target.value}})
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>Your Paypal address</label>
              <Input
                placeholder="jc@gg.com"
                name="paypal_address"
                value={agreement.paypal_address ? agreement.paypal_address : ''}
                onChange={(e) => {
                  setAgreement({...agreement,  ...{[e.target.name]: e.target.value}})
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInputSwitch
              inputType='switch'
              inputName='signed_agreement'
              inputLabel="Terms of Service"
              mutedText="Agree to the Service for affiliates."
              inputValue={agreement.signed_agreement ? agreement.signed_agreement  : false}
              inputCSSId='signed-agreement-switch'
              handleInputChange={(e) => {
                const val = agreement.signed_agreement ? agreement.signed_agreement  : false;
                setAgreement({...agreement,  ...{[e.target.name]: !val}})
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="card bg-light border">
              <div className="card-body">
                <h4 className="mb-2"><i className="fe fe-alert-triangle"></i> Warning</h4>
                <p className="small text-muted mb-0">
                  <span className="text-primary jc-link" onClick={toggleModal}>Read our Terms of Service</span> for affiliates.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-5 mb-5"/>
        <div className="btn btn-block btn-primary" onClick={() => {
          createAffiliate(agreement)
        }}>
          Sign up
        </div>
        <p className="text-muted text-center" style={{marginTop: 20 , marginBottom: 40}}>
          By signing up you agree to the <span className="text-primary jc-link" onClick={toggleModal}>Terms of Service</span>.
        </p>
      </div>
    </>
  )
}

const WarningModal = ({submittingData, modal, toggle}) => {
  return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Warning</ModalHeader>
        <ModalBody>
          <ul>
            {submittingData.user_signed_initial ? null : <li>Initial must be filled out.</li>}
            {submittingData.paypal_address ? null : <li>Paypal address must be filled out, so that we can send you the payment later.</li>}
            {submittingData.signed_agreement ? null : <li>Please accept the Term of Service.</li>}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Done</Button>
        </ModalFooter>
      </Modal>
  )
}

const TermsOfServiceModal = ({modal, toggle}) => {
  return (

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Term of Service</ModalHeader>
        <ModalBody>
          <p>Last updated Jun 17, 2020</p>
          <p>There are a few rules about our affiliate program you should know about. No “gotchas” here, just some terms to keep everyone happy.</p>
          <ul>
            <li>Self-referrals are not allowed.</li>
            <li>Abuse or gaming will result in having your account banned.</li>
            <li>In some cases, we can give credit to an affiliate even if the customer didn’t sign up through an affiliate link or coupon code. If you have a case like this, please contact us and we’ll do our best to help.</li>
            <li>No search engine ads (especially on branded terms or domain names), Facebook ads or other ads that would compete with our own marketing and cause potential confusion for customers.</li>
            <li>No ads that link to our website or anything similar that would compete with our own paid marketing and drive up our costs and potentially cause confusion.</li>
            <li>No pretending to be acting on behalf of us (ie. as an employee).</li>
            <li>We reserve the right to change the Terms of Service for our affiliate program at any time. </li>
          </ul>
          <p>We reserve the right to terminate your affiliate account for violation of any of the rules at our sole discretion. Commissions generated by mechanisms that are in violation of our Terms of Service will not be paid or owed.</p>
          <p>We will pay out your affiliate commissions once your balance owing hits $20.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Done</Button>
        </ModalFooter>
      </Modal>

  )
}

export default BecomeAffiliate;