import React, {useEffect, useState, useContext} from "react";
import { Link, Redirect } from "react-router-dom";
import {Context as AuthContext} from '../context/AuthContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import { Spinner } from 'reactstrap';
import justcastApi from '../api/justcast';
import SocialNetworkForm from '../components/SocialNetworkForm'
import UpdateNotification from '../components/UpdateNotification';
import ScreenBlocker from '../components/ScreenBlocker'

const SocialLinks = (props) => {
  const id = props.match.params.id;
  const {addWithTimeout} = useContext(AlertContext);
  const screenContext = useContext(ScreenContext);
  const menuContext = useContext(MenuContext);
  const [showName, setShowName] = useState('');
  const [item, setItem] = useState({});
  const [denied, setDenied] = useState(false);
  const [screenBlocker, setScreenBlocker] = useState(false);

  const handleInputValueChange = ({key, value}) => {    
    setItem({...item, [key]: value})
  }

  const handleUpdate = () => {    
    justcastApi.put(`/v1/shows/${id}`, item)
    .then((res) => {      
      addWithTimeout({color: 'warning', message: `${showName} has been updated!`, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err)
      addWithTimeout({color: 'danger', message: 'Error', isOpen: true, timeout: 3000})
    })
  }

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;
      setShowName(name);
      setItem(res.data);      
      screenContext.finishFetching();

      setScreenBlocker(res.data.skill_podcast_website);

      menuContext.setWebsiteMenu({
        title: 'Social',
        subtitle: name,
        currentPageId: 'website_settings',
        currentTabId: 'social',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website        
      });        
    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true)
      console.log(err)
    })    
  },[id])


  if(denied) {
    return <Redirect to="/dashboard"/>
  }
  
  if(screenBlocker === false) {
    return (
      <ScreenBlocker message="Please upgrade your account to access the data."/>
    )
  }  
  
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> : <>
                <SocialNetworkForm
                  facebook_page={item.facebook_page}
                  twitter={item.twitter_handle}
                  instagram_profile={item.instagram_profile}
                  slack={item.slack}
                  apple_podcast={item.apple_podcast}
                  google_podcast={item.google_podcast}
                  overcast={item.overcast}
                  spotify={item.spotify}
                  pocket_cast={item.pocket_casts}
                  breaker={item.breaker}
                  castro={item.castro}
                  radio_public={item.radio_public}
                  castbox={item.castbox}
                  tune_in={item.tune_in}
                  stitcher={item.stitcher}
                  amazon_podcast_link={item.amazon_podcast_link}
                  patreon_support_link={item.patreon_support_link}
                  paypal_url={item.paypal_url}
                  square_cash={item.square_cash}
                  buy_me_a_coffee_url={item.buy_me_a_coffee_url}
                  podverse={item.podverse}
                  fountain={item.fountain}
                  mastodon={item.mastodon}
                  matrix={item.matrix}                  
                  handleInputValueChange={handleInputValueChange}
                />
                <hr className="mt-5 mb-5"/>
                <UpdateNotification/>
                <div className="btn btn-block btn-primary" onClick={handleUpdate}>Save change</div>
                <br/>
                {/* <Link to={`/shows/${id}/episodes`} className="btn btn-block btn-link text-muted">Cancel this change</Link> */}
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default SocialLinks;