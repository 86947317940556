import React, {Suspense} from 'react';
import {StripeProvider} from 'react-stripe-elements';
import MainRoutes from './components/MainRoutes'
import { Provider as JotaiProvider} from "jotai";
import {Provider as AuthProvider} from './context/AuthContext'
import {Provider as AlertProvider} from './context/AlertContext'
import {Provider as ModalProvider} from './context/ModalContext'
import {Provider as ScreenProvider} from './context/ScreenContext'
import {Provider as PlayerProvider} from './context/PlayerContext'
import {Provider as PodieoAudio} from './context/PodieoAudioContext'
import {Provider as SocketProvider} from './context/SocketContext'
import {Provider as MenuProvider} from './context/MenuContext'
import {Provider as FileUploadContext} from './context/FileUploadContext'
import {Provider as MobilePlayerProvider} from './context/MobilePlayerContext'

function App() {
  return (   
    <JotaiProvider>
      <Suspense fallback={<></>}>
        <AuthProvider>
          <AlertProvider>
            <ModalProvider>
              <ScreenProvider>
                <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                  <PlayerProvider>
                    <PodieoAudio>
                      <SocketProvider>
                        <FileUploadContext>
                          <MenuProvider>
                            <MainRoutes />
                          </MenuProvider>
                        </FileUploadContext>
                      </SocketProvider>
                    </PodieoAudio>
                  </PlayerProvider>
                </StripeProvider>
              </ScreenProvider>
            </ModalProvider>
          </AlertProvider>
        </AuthProvider>  
      </Suspense>
    </JotaiProvider>       
  );
}

export default App;
