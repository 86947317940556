import React, {useContext, useState} from "react";
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AlertContext} from '../context/AlertContext'
import { Alert, Form, FormGroup, Input, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import justcastApi from '../api/justcast';
import ErrorMessagesList from './ErrorMessagesList'

const DeleteDirectUploadModal = () => {

  const {state, reset, handlePodcastDelete} = useContext(ModalContext);
  const alertContext = useContext(AlertContext);
  const [name, setName] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errorMessages, setErrorMessages] = useState([]);
  const [deletingMessage, setDeletingMessage] = useState('')

  const handleNameChange = (e) => {
    const v = e.target.value;
    if(v.toLowerCase() === state.name.toLowerCase()) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    setName(v);
  }

  const handleSave = (e) => {    
    e.preventDefault();
    setButtonDisabled(true);
    setDeletingMessage(`Deleting ${name}`);
    justcastApi.delete(state.endpoint)
    .then((res) => {      
      reset(); // reset the modal 
      const msg = res.data.message;
      alertContext.addWithTimeout({color: 'warning', title: `${state.deleteButtonLabel} destroy!`, message: msg, isOpen: true, timeout: 2000}) // color, title, message, isOpen, timeout
      handlePodcastDelete();
      setButtonDisabled(false);
      setDeletingMessage("");
      // add notification      
    })
    .catch((err) => {
      const data = err.response.data;
      const errors = [];
      for(const key in data) {
        data[key].forEach((x) => {
          const str = `${key} ${x}`;
          errors.push(str);
        })
      }      
      setButtonDisabled(false);
      setErrorMessages(errors);
      setDeletingMessage("");
    })
  }

  if(state.deleteDirectPodcast) {
    return (
      <Modal isOpen={state.deleteDirectPodcast}>
        <ModalHeader toggle={reset}>
          <strong>{state.title}</strong>
        </ModalHeader>
        <ModalBody>
          <Alert color="warning">
            {state.message}
          </Alert>
          {
            state.messages.map((msg, id) => 
              <p key={`modal-delete-message-${id}`}>{msg}</p>
            )
          }
          <Form onSubmit={handleSave}>
            <FormGroup>
              <Input type="text" name="name" id="name" placeholder="name" value={name} onChange={handleNameChange}/>
            </FormGroup>
            <Button color="danger" block disabled={buttonDisabled}>I understand the consequences, delete this {state.deleteButtonLabel}.</Button>
          </Form>
          {deletingMessage ? <><br/><p className="text-warning">{deletingMessage}</p></> : null}
          <ErrorMessagesList errors={errorMessages}/>          
        </ModalBody>
      </Modal>
    )
  }
  return null;
}

export default DeleteDirectUploadModal;