import React, {useState, useEffect} from 'react'

export default function WidgetCodeSection({
  showId,
  is_advanced_private_feed,
  allowSite,
  episodeId
}) {

  const [widgetUrl, setWidgetUrl] = useState(null);

  useEffect(() => {
    if(is_advanced_private_feed) {
      if(allowSite) {
        if(episodeId) {
          setWidgetUrl([`widget/${showId}/episodes`, `/${episodeId}`, `?widget_code=${allowSite}`].join(""))
        } else {
          setWidgetUrl([`widget/${showId}/episodes`,`?widget_code=${allowSite}`].join(""))
        }
      } else {
        setWidgetUrl(null);
      }
    } else {
      if(episodeId) {
        setWidgetUrl(`widget/${showId}/episodes/${episodeId}`)
      } else {
        setWidgetUrl(`widget/${showId}/episodes`)
      }
    }
  }, [showId, is_advanced_private_feed, episodeId, allowSite])

  if(is_advanced_private_feed) {
    if(widgetUrl) {
      return (
        <div className="row widget-code-section">
          <div className="col-12">
            <h2>Here's what the embed code looks like:</h2>
            <code className="text-white">
              {`<iframe src='${process.env.REACT_APP_LANDING_PAGE}/${widgetUrl}' width='100%' height='370px' frameborder='0' scrolling='yes' seamless='true' style="height:370px; width:100%;"></iframe>`}
            </code>
          </div>
       </div>
      )
    }
    return (
      <div className="row widget-code-section">
        <div className="col-12">
          <h2>Embed code is not available</h2>
          <p>This is a private podcast designed exclusively for our members. To add the widget to your website, you will need to enable it. Please scroll to the bottom of the page and add the security code.</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className="row widget-code-section">
        <div className="col-12">
          <h2>Here's what the embed code looks like:</h2>
          <code className="text-white">
            {`<iframe src='${process.env.REACT_APP_LANDING_PAGE}/${widgetUrl}' width='100%' height='370px' frameborder='0' scrolling='yes' seamless='true' style="height:370px; width:100%;"></iframe>`}
          </code>
        </div>
      </div>
    )
  }
}
