// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
class CSVEmailAddressParser {
  constructor(data, emailIdx, fullNameIdx = null) {
    this.data = data;
    this.emailIdx = emailIdx;
    this.fullNameIdx = fullNameIdx;
    this.re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  getUsers() {
    const results = [];

    for(let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      const email = this.re.test(row[this.emailIdx])
      const fullName = this.fullNameIdx ? row[this.fullNameIdx] : null;
      if(email) {
        const item = {
          email: row[this.emailIdx].toLowerCase()
        }
        if(fullName) {
          item['full_name'] = fullName
        }
        results.push(item);
      }
    }

    return results;
  }

  setEmailIdx(id) {
    this.emailIdx = id;
  }

  setFullNameIdx(id) {
    this.fullNameIdx = id;
  }
}

export default CSVEmailAddressParser;