import React from 'react'
import TranscriptCard from './TranscriptCard'

export default function TranscriptContainer(props) {
  const {handleDoubleClickedWord, paragraphs, playSentence, updateSpeakerModal} = props;
  if(paragraphs) {
    return (
      <div className="container-fluid">
        {props.children}
        <div className="row">
          <div className="col-12">
            <div
              onDoubleClick={handleDoubleClickedWord}
            >
              {
                paragraphs.map((paragraph, idx) => {
                  const {text, start_time_str, minmima_index, speaker_name, start_time, sentence_speaker_code} = paragraph;
                  return (
                    <TranscriptCard
                      playSentence={playSentence}
                      key={idx}
                      text={text}
                      start_time={start_time}
                      start_time_str={start_time_str}
                      speaker_name={speaker_name}
                      minmima_index={minmima_index}
                      sentence_speaker_code={sentence_speaker_code}
                      updateSpeakerModal={updateSpeakerModal}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null;
}
