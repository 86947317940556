import React, {useEffect, useState} from 'react'
import { usePlacesWidget } from "react-google-autocomplete";
import {  FormGroup, Input } from 'reactstrap';

export default function LocationInput(props) {
  const {handleLocationChange, locationName, location_name} = props;

  const [locationInput, setLocationInput] = useState("")

  useEffect(() => {
    if(location_name) {
      setLocationInput(location_name)
    }
  }, [location_name])

  const { ref: bootstrapRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      // lat, lng
      if(place && place.geometry) {
        const location = place.geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        const name = bootstrapRef.current.value;
        setLocationInput(name);
        handleLocationChange({name, lat, lng});
      }
    },
    options: {types: ["geocode", "establishment"]},
  });

  return (
    <FormGroup>
      <label>Location</label>
      <small className="form-text text-muted">
        This is intended to describe the location of editorial focus for a podcast's content (i.e. "what place is this podcast about?")
      </small>
      <Input
        innerRef={bootstrapRef}
        value={locationName || locationInput}
        onChange={(event) => {
          setLocationInput(event.target.value);
          if(event.target.value === "") {
            handleLocationChange({name: null, lat: null, lng: null});
          }
        }}
      />
    </FormGroup>
  )
}
