import React from "react";
import { Link } from "react-router-dom";
import {Button} from 'reactstrap';

const ErrorPageBody = ({toggle, isErrorPage}) => {
  if(isErrorPage) {
    return (
      <Button tag={Link} to="/plans" color="warning" onClick={toggle}>Upgrade now</Button>
    )
  }

  return null;
}

export default ErrorPageBody;