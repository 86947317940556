import React, {useState} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, InputGroup, InputGroupAddon} from 'reactstrap';

const ClipboardModalComponent = ({id, buttonLabel, className, modalClassName, inputValue}) => {

    const [modal, setModal] = useState(false);
    const [copied, setCopied] = useState(false);
    const [copyButtonLabel, setCopyButtonLabel] = useState('Copy');
  
    const toggle = () => setModal(!modal);
  
    const handleCopyClicked = () => {
      const copyText = document.querySelector(`#input-${id}`);
      copyText.select();
      document.execCommand("Copy");
      setCopyButtonLabel('Copied');
      setCopied(true);
      // change back to Copy in 3 seconds
      setTimeout(() => {
        setCopyButtonLabel("Copy");
        setCopied(false);
      }, 3000)    
    }
  
    return (
      <>
        {/* <Button color={className} onClick={toggle}>{buttonLabel}</Button> */}
        <li className="nav-item">
          <span onClick={toggle} className={className} >{buttonLabel}</span>
        </li>
        <Modal isOpen={modal} toggle={toggle} className={modalClassName}>
          <ModalHeader toggle={toggle}>{buttonLabel}</ModalHeader>
          <ModalBody>
            <InputGroup>
              <Input valid={copied} readOnly value={inputValue} id={`input-${id}`} />
              <InputGroupAddon addonType="prepend">
                <Button color="primary" onClick={handleCopyClicked}>{copyButtonLabel}</Button>
              </InputGroupAddon>          
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
  

  export default ClipboardModalComponent;