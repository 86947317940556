import React, {useContext} from "react";
import { Form, FormGroup, Input, Button, Row, Card, CardBody } from 'reactstrap';
import FormGroupInputSwitch from '../components/FormGroupInputSwitch';
import FormGroupInputSelect from '../components/FormGroupInputSelect';
import FormGroupListItemInputSwitch from '../components/FormGroupListItemInputSwitch';
import FormGroupInput from '../components/FormGroupInput';
import {Context as AuthContext} from './../context/AuthContext'

const ShowSettingsForm = ({
  map_extention_from,
  map_extention_to,
  facebook_pixel_base_code,
  google_analytics_id,
  hide_season,
  timeZone,
  timeZones,
  is_private,
  is_advanced_private_feed,
  is_password_protected,
  username,
  password,
  drip_separate_days,
  sort_by_name,
  prefix,
  manual_release,
  handleToggleChange,
  handleInputValueChange,
  handleSelectionChange,
  handleSubmit,
  locked,
  item
}) => {
  const authContext = useContext(AuthContext);
  const userState = authContext.state;

  return (
    <Form className="mb-4" onSubmit={handleSubmit}>
      <Row>
        <div className="col-12 col-md-6">
          <FormGroupInputSwitch
            inputType='switch'
            inputName='manual_release'
            inputLabel="Manual release"
            mutedText="If you choose the show be manual release, episodes will not show up on your rss feed until you choose a publish date."
            inputValue={manual_release ? manual_release : false}
            inputCSSId='manual-release-switch'
            warningMessage=""
            handleInputChange={handleToggleChange}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormGroupInputSelect
            inputType="select"
            inputLabel="Time zone"
            inputName='time_zone'
            optionLabel="key"
            optionId="key"
            options={timeZones}
            handleInputChange={handleSelectionChange}
            inputValue={timeZone}
          />
        </div>
      </Row>
      <hr className="mt-5 mb-5"/>
      <Row>
        <div className="col-12 col-md-6">
          <FormGroupInputSwitch
            inputType='switch'
            inputName='hide_season'
            inputLabel="Hide Season"
            mutedText="Hide the season tag in the RSS feed."
            inputValue={hide_season ? hide_season : false}
            inputCSSId='hide-season-switch'
            warningMessage=""
            handleInputChange={handleToggleChange}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormGroupInputSwitch
            inputType='switch'
            inputName='locked'
            inputLabel="Locked"
            mutedText="The purpose is to tell other podcast platforms whether they are allowed to import this feed.  A value of yes means that any attempt to import this feed into a new platform should be rejected."
            inputValue={locked}
            inputCSSId='sort-by-name-switch-locked'
            warningMessage=""
            handleInputChange={handleToggleChange}
          />
        </div>
      </Row>
      {
        item && (!(item.is_private || item.is_password_protected || item.is_advanced_private_feed)) &&
        <>
          <hr/>
          <FormGroupInput
            inputLabel='Prefix'
            inputType='text'
            inputName="prefix"
            mutedText={'You can add a third party tool to measure your downloads and other information.  In order to facilitate this we would need to add the 3rd party URL prefix to all your enclosure tags.'}
            inputPlaceholder="https://chtbl.com/track/G56855/"
            inputValue={prefix ? prefix : ""}
            inputClassName="form-control"
            handleInputChange={handleInputValueChange}
          />
        </>
      }
      <hr/>
      <h2 className="mb-2">Private Feed</h2>
      <small className="text-muted">If you want to limit access to content, you should consider creating a private podcast.  There are three types of private feed you can create.</small>
      <p><small className="text-warning">Once a podcast is made private, your RSS feed location will be changed, and it won't be able to change back.</small></p>
      <Card>
        <CardBody>
          <div className="list-group list-group-flush my-n3">
            <FormGroupListItemInputSwitch
              inputType='switch'
              inputName='is_private'
              inputLabel="Unlisted"
              mutedText="If you choose the show to be unlisted, the link will become a random number and you need to send your audience the link yourself."
              inputValue={is_private ? is_private : false}
              inputCSSId='private-show-switch'
              handleInputChange={handleToggleChange}
            />
            <FormGroupListItemInputSwitch
              inputType='switch'
              inputName='is_password_protected'
              inputLabel="Password protected"
              mutedText="If you choose the show to be password protected, the link will become a randome number and you need to send your audience the link yourself."
              inputValue={is_password_protected}
              inputCSSId='password-protected-show-switch'
              handleInputChange={handleToggleChange}
            >
              {
                is_password_protected ?
                <div style={{marginTop: "10px"}}>
                  <Row>
                    <div className="col-12 col-md-6">
                      <FormGroupInput
                          inputLabel='Username'
                          inputType='text'
                          inputName="username"
                          inputValue={username ? username : ""}
                          inputClassName="form-control"
                          inputPlaceholder="user name"
                          handleInputChange={handleInputValueChange}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                      <FormGroupInput
                          inputLabel='Password'
                          inputType='password'
                          inputName="password"
                          inputValue={password ? password : ""}
                          inputClassName="form-control"
                          inputPlaceholder="password"
                          handleInputChange={handleInputValueChange}
                        />
                    </div>
                  </Row></div> : null
              }
            </FormGroupListItemInputSwitch>
            <FormGroupListItemInputSwitch
              inputType='switch'
              inputName='is_advanced_private_feed'
              inputLabel="Members only"
              mutedText="Restrict access to a group of subscribers with a private podcast.  Each subscriber will receive an invite via email."
              inputValue={is_advanced_private_feed}
              inputCSSId='advanced-private-show-switch'
              messageTitle={"Message"}
              warningMessage=""
              handleInputChange={handleToggleChange}
            >
            </FormGroupListItemInputSwitch>
          </div>
        </CardBody>
      </Card>
      <hr/>
    </Form>
  )
}

export default ShowSettingsForm;