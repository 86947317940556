import React, {useContext} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap';
import {Context as FileUploadContext} from '../../context/FileUploadContext'

const ModalBox = (props) => {
  const {modal, setModal, channelName} = props;
  const fileUploadContext = useContext(FileUploadContext);
  const {updatingAudioposts} = fileUploadContext.state;
  const channelData = updatingAudioposts[channelName];
  
  const {directUploadStatusCode, uploadedFileSize, fileSize, title} = channelData ? channelData : {directUploadStatusCode: null, uploadedFileSize: null, message: null};
  // const displayUploadFileSize = uploadedFileSize > 0 ? uploadedFileSize : uploadedSize;
  // const modalTitle = message ? message : title;

  const toggle = () => {
    setModal(!modal);
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title ? title : "Preparing, please don't close this screen."}</ModalHeader>
      <ModalBody>
        {
          fileSize > 0 ? <>
            <p>{Math.floor(uploadedFileSize  * 100 /fileSize)}%</p>
            <Progress animated value={uploadedFileSize} max={fileSize}/>
          </> : <p>{uploadedFileSize}</p>
        }
      </ModalBody>
      <ModalFooter>
        <Button color={directUploadStatusCode === 200 ? "warning" : "secondary"} onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalBox;