import React from "react";
import { Card, CardBody, Button, Row, Col} from 'reactstrap';

const AddValueRecipient = (props) => {
  const {handleAddClick, handleAblyClick} = props;

  return (
    <Card>
      <CardBody>
        <p>
          The Value Recipient designates various destinations for payments to be sent to during consumption of the enclosed media. Each recipient is considered to receive a "split" of the total payment according to the number of shares given in the split attribute.
        </p>
        <hr/>
        <div>
          <Button color="warning" onClick={handleAblyClick}>Add Alby Wallet</Button>{" "}
          <Button color="secondary" onClick={handleAddClick}>Add Recipient</Button>
        </div>
      </CardBody>
    </Card>
  )
}

export default AddValueRecipient;