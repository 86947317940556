import React, {useState, useContext, useEffect } from "react";
import {useDropzone} from 'react-dropzone'
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Input, InputGroupAddon } from 'reactstrap';
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import FastAverageColor from 'fast-average-color';

const getRandomInt = () => {
  return Math.floor(Math.random() * Math.floor(10000)).toString() + "|" + Date.now().toString();
}

const ModalImageUploader = ({modal, toggleModal}) => {

  const defaultImage = {
    id:"", artwork: "", x: "50", y: "40", width: "40", height: "40", isRemoteURL: false, file: null, mediaType: null
  };

  const [errors, setErrors] = useState([]);
  const {state, updateState} = useContext(PodieoAudioContext);
  const [imageURL, setImageURL] = useState('')
  // const [image, setImage] = useState(defaultImage);
  
  const setBackgroundColorByImage = (imageURL, cb) => {
    // check the image average color        
    if(state.backgroundColor === state.defaultBackgroundColor) {
      const fac = new FastAverageColor();
      fac.getColorAsync(imageURL).then((color) => {
        cb(color.hex, null);
      })
      .catch((e) => {
        cb(null, e);
      })
    } else {
      cb(null, "Background color has been set.");
    }
  }

  //https://github.com/react-dropzone/react-dropzone/issues/321
  const imageOnDrop = (acceptedFiles, callbackImage) => {
    //TODO: replace setFiles with setArtwork
    // exactly
    const img = new Image();
    
    img.onload = function() {        
      const path = URL.createObjectURL(acceptedFiles[0]);
      const mediaTypes = acceptedFiles[0].path.split('.');
      const mediaType = mediaTypes[mediaTypes.length-1]

      callbackImage({id: getRandomInt(), artwork: path, file: acceptedFiles[0], width: img.width * 0.4 * state.demoSvgRatio, height: img.height * 0.4 * state.demoSvgRatio, mediaType})
    }

    img.src = URL.createObjectURL(acceptedFiles[0])
  }

  const handleInputSave = () => {
    const img = new Image();
  
    img.onload = function() {
      const mediaTypes = imageURL.split('.');
      const mediaType = mediaTypes[mediaTypes.length-1]
      const errors = [];      
      if(['jpeg', 'png', 'jpg'].indexOf(mediaType) === -1) {
        errors.push('Only *.jpeg, *.jpg, and *.png images will be accepted');
        setErrors(errors)
      } else if (img && img.height < 10 && img.width < 10){
        errors.push('Image must be greater than 10px by 10px');
        setErrors(errors)
      } else if(img && img.height > 10 && img.width > 10) {

        const newImage = {...defaultImage, ...{id: getRandomInt(), artwork: imageURL, width: img.width * 0.4 * state.demoSvgRatio, height: img.height * 0.4 * state.demoSvgRatio, isRemoteURL: true, mediaType}};

        setBackgroundColorByImage(imageURL, (backgroundColor, err) => {
          if(backgroundColor) {               
            updateState({backgroundColor, selectingObject: {type: "images", id: newImage.id},  images: [...state.images, newImage]})
          } else {
            updateState({selectingObject: {type: "images", id: newImage.id},  images: [...state.images, newImage]})
          }  
          setImageURL('');
          toggleModal()
        })
        
        // updateState({selectingObject: {type: "images", id: newImage.id},  images: [...state.images, newImage]})        
        // toggleModal()
      }    
      //({id: getRandomInt(), artwork: imageURL, width: img.width, height: img.height})
    }

    img.src = imageURL;
  }
  
  const {acceptedFiles, rejectedFiles, getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: (acceptedFiles, rejectedFiles) => {
      if(acceptedFiles.length > 1) {
        //TODO
        //Allow user to add many files at a time
        const errors = ['Please upload one file at a time.'];
        setErrors(errors)
        return;
      }
      if(rejectedFiles.length > 0) {
        
        const errors = ['Only *.jpeg and *.png images will be accepted'];
        rejectedFiles.forEach((file) => {
          errors.push(`Rejected file: ${file.path}`)
        })

        // setArtwork(null)
        // setFiles([]);
        setErrors(errors)
        return;
      }
      if(acceptedFiles.length === 1) {
        imageOnDrop(acceptedFiles, (callbackImage) => {
          // * state.demoSvgRatio   
          const newImage = {...defaultImage, ...callbackImage};
          setBackgroundColorByImage(callbackImage.artwork, (backgroundColor, err) => {
            if(backgroundColor) {               
              updateState({backgroundColor, selectingObject: {type: "images", id: newImage.id},  images: [...state.images, newImage]})
            } else {
              updateState({selectingObject: {type: "images", id: newImage.id},  images: [...state.images, newImage]})
            }                        
            toggleModal()
          })
        })        
      }
    }
  });

  return (
    <>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add new Image</ModalHeader>
        <ModalBody>
          <div>
            {
              errors.length > 0 ? <Alert color="warning">{errors.join(' ')}</Alert>  : null
            }

            <div {...getRootProps({className: 'dropzone'})}>
              <div className="dz-default dz-message">
                <input {...getInputProps()}/>
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>              
            </div>
            <hr/>
            <div>
              <InputGroup>
                <Input                  
                  value={imageURL ? imageURL : ""}
                  onChange={(e) => {
                    setImageURL(e.target.value)
                  }}
                />
                <InputGroupAddon addonType="append">          
                  <Button 
                    onClick={handleInputSave}            
                  >Save</Button>
                </InputGroupAddon>            
              </InputGroup>
            </div>
          </div>
        </ModalBody>        
      </Modal>
    </>
  )
}

export default ModalImageUploader;