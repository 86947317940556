import React from "react";
import ChaptersInputCard from './ChaptersInputCard'
import SoundbitesInputCard from './SoundbitesInputCard'

const InputCardLayout = (props) => {
  const {type, audiopostId} = props;

  if(type === 'chapters') {
    return <ChaptersInputCard audiopostId={audiopostId}>{props.children}</ChaptersInputCard>
  } else if (type === 'soundbites') {
    return <SoundbitesInputCard audiopostId={audiopostId}>{props.children}</SoundbitesInputCard>
  } else {
    return null;
  }
}

export default InputCardLayout;