import React, {useEffect, useState, useContext} from "react";
import {Redirect} from 'react-router-dom'
import {Button} from 'reactstrap';
import justcastApi from '../api/justcast';
import {Context as AuthContext} from '../context/AuthContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import {SeasonList, SeasonForm} from '../components/seasons'

const Seasons = (props) => {
  const showId = props.match.params.show_id;
  const {addWithTimeout} = useContext(AlertContext);
  const screenContext = useContext(ScreenContext);
  const {setEpisodesMenus} = useContext(MenuContext);
  const [seasons, setSeasons] = useState([]);
  const [denied, setDenied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [newSeason, setNewSeason] = useState({
    name: "", number: "", id: null
  });
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v2/shows/${showId}`)
    .then((res) => {
      const name = res.data.podcast_title;
      setEpisodesMenus({
        title: 'Seasons',
        subtitle: name,
        currentPageId: 'episodes',
        currentTabId: 'seasons',
        showId: showId,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        isDirectUpload: res.data.is_direct_upload,
        skill_podcast_website: res.data.skill_podcast_website,
        shopifyApp: res.data.is_shopify_app
      });
      screenContext.finishFetching();
    })
    .then(() => {
      return justcastApi.get(`/v1/shows/${showId}/seasons`)
    })
    .then((res) => {
      setSeasons(res.data);
    })
    .catch((err) => {
      screenContext.finishFetching();
      setDenied(true);
    });

  }, [showId]);

  const handleAddNewSeason = () => {
    setIsOpen(true);
    setTitle("Add a new season")
    setNewSeason({name: "", number: "", id: null})
  }

  const handleToggle = () => {
    setIsOpen(false);
    setErrorMessages({})
  }

  const handleInputChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setNewSeason({...newSeason, [name]: value});
  }

  const editClicked = (season) => {
    setTitle("Edit season")
    setNewSeason(season)
    setIsOpen(true);
  }

  const handleDelete = (id) => {
    justcastApi.delete(`/v1/shows/${showId}/seasons/${id}`)
    .then((res) => {
      const deletedData = res.data;
      const _newSeasons = seasons.filter((season) => season.id !== deletedData.id)
      setSeasons(_newSeasons);
      addWithTimeout({color: 'primary', message: `Delete Season ${deletedData.number}`, isOpen: true, timeout: 3000});
    })
    .catch((err) => {
      console.log(err.response.data)
    })
  }

  const handleSave = () => {
    if(newSeason.name || newSeason.number) {
      if(newSeason.id) {
        // update
        justcastApi.put(`/v1/shows/${showId}/seasons/${newSeason.id}`, {
          name: newSeason.name,
          number: newSeason.number
        })
        .then((res) => {
          setErrorMessages({})
          const _newSeasons = [...seasons];
          const index = _newSeasons.findIndex((season) => season.id === res.data.id);
          _newSeasons[index] = res.data;
          setSeasons(_newSeasons)
          setIsOpen(false);
          addWithTimeout({color: 'primary', message: `Updated Season ${res.data.number}`, isOpen: true, timeout: 3000});
        })
        .catch((err) => {
          setErrorMessages(err.response.data)
        })
      } else {
        // create
        justcastApi.post(`/v1/shows/${showId}/seasons`, {
          name: newSeason.name,
          number: newSeason.number
        })
        .then((res) => {
          setErrorMessages({})
          setSeasons([res.data, ...seasons]);
          setIsOpen(false);
          addWithTimeout({color: 'primary', message: `Added a new season`, isOpen: true, timeout: 3000});
        })
        .catch((err) => {
          setErrorMessages(err.response.data)
        })
      }
    } else {
      setIsOpen(false);
    }
  }

  return (
    <div className="container-fluid">
      <SeasonForm
        title={title}
        isOpen={isOpen}
        toggle={handleToggle}
        data={newSeason}
        errorMessages={errorMessages}
        onChange={handleInputChange}
      >
        <Button color="primary" onClick={handleSave}>Save</Button>
      </SeasonForm>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="card-header-title">Seasons</h4>
                </div>
                <div className="col-auto">
                  <div className="btn btn-primary btn-sm" onClick={handleAddNewSeason}>Add New Season</div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <SeasonList
                seasons={seasons}
                editClicked={editClicked}
                deleteClicked={handleDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Seasons;