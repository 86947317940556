import React from "react";
import moment from 'moment'

export const DateFormatCell = ({cell}) => {  
  if(cell.value) {
    const date = moment(cell.value).format('YYYY-MM-DD')
    return <>{date}</>
  }
  return <>N/A</>
}
 
export const DateAgoFormatCell = ({cell}) => {  
  // const date = moment(cell.value).startOf('day').fromNow()
  const date = moment(cell.value).fromNow()
  return <>{date}</>
}

export const NumberFormatCell = ({cell}) => {
  if(cell.value) {
    return cell.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  return <>N/A</>
}

export const PercentageFormatCell = ({cell}) => {
  return (Math.round(cell.value * 100 * 100) / 100).toString() +'%';
  
  // cell.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const PennyToDollarFormatCell = ({cell}) => {
  if(cell.value) {
    const cellStr = (cell.value / 100).toString()
    return `$ ${cellStr}`
  }
  return <>N/A</>
}

export const PlainTextCell = ({cell}) => {
  return cell.value
}

export const DecodeURIFormatCell = ({cell}) => {
  return decodeURI(cell.value.toString());
}

export const BooleanCell = ({cell}) => {
  if(cell.value) {
    return cell.value.toString();
  }

  return 'false';
}

export default {};