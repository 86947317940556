import React from 'react'
import {Card, CardBody, CardFooter, Row, Col, Button, ButtonGroup} from 'reactstrap'
import icon from '../../assets/img/icons/shopify-icon.svg'
import { Link } from 'react-router-dom';

export default function ShopifyConnection() {
  return (
    <Card>
      <CardBody className="text-center">
        <div className="card-avatar avatar avatar-lg mx-auto">
          <img src={icon} alt="Shopify Connection" className="avatar-img rounded"/>
        </div>
        <h2 className="mb-3">Shopify</h2>
      </CardBody>
      <CardFooter className="card-footer-boxed">
        <Row className="align-items-center justify-content-between">
          <div className="col-auto"/>
          <div className="col-auto">
            <Link to="/connections/shopify-connection" name="settings" className="btn btn-primary btn-sm">Settings</Link>
          </div>
        </Row>
      </CardFooter>
    </Card>
  )
}
