import React, {useState} from "react";
import {useAtom} from 'jotai'
import {updatePlayerConfigAtom, playerColorOptiosAtom} from '../jotai'
import SimpleColorPicker from './SimpleColorPicker'
import Select from 'react-select'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.isSelected ? 'white' : 'blue',
  })
}

const WidgetPlayerColorPicker = () => {

  const [configs, setConfigs] = useAtom(updatePlayerConfigAtom);
  const [options] = useAtom(playerColorOptiosAtom);
  const [selectedOption, setSelectedOption] = useState({id: 'primaryBackgroundColor', label: 'Player background color', value: 'primaryBackgroundColor'})

  const handleChange = (x) => {
    setConfigs(x);
  }

  const hndleSelect = (x) => {
    setSelectedOption(x)
  }

  return (
    <div style={{display: "flex", alignItems: "center", gap: "10px", marginTop: "20px"}}>
      <div style={{flexGrow: 1}}>
        <Select
          defaultValue={options[0]}
          styles={customStyles}
          options={options}
          onChange={hndleSelect}
        />
      </div>
      <SimpleColorPicker 
        id={selectedOption["id"]}
        name={selectedOption["label"]}
        color={configs[selectedOption["id"]]}
        handleColorChange={handleChange}
      />
    </div>
  )
}

export default WidgetPlayerColorPicker;