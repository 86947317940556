import React from "react";
import {Rnd} from 'react-rnd';

const ImageDnD = ({defaultLocation, handleDragStop, handelResizeStop, enableResizing,  handleOnClick, enabled, id, maxWidth, maxHeight}) => {
  if(defaultLocation && enabled) {
    return (
      <Rnd
        default={{ 
          x: defaultLocation.imageXaxis, 
          y: defaultLocation.imageYaxis,
          width: defaultLocation.imageWidth,  
          height: defaultLocation.imageHeight 
        }}
        onDragStop={(e, d) => {
          // setDemoSpec({...demoSpec, ...{imageXaxis: d.x, imageYaxis: d.y}})
          // console.log({ x: d.x, y: d.y })
          handleDragStop(d.x, d.y, id);          
        }}
        onResizeStop={(e, d, refToElement, delta, pos) => {
          // console.log({delta}) // new deltaWidth, deltaHeight
          // console.log(pos) // new x, y
          handelResizeStop(delta.width, delta.height, pos.x, pos.y, id)
        }}
        enableResizing={enableResizing}
        onClick={(e) => {
          if(handleOnClick) {
            handleOnClick({clientX: e.clientX, clientY: e.clientY})
          }          
        }}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        bounds="parent"
        //style={{backgroundColor: 'rgb(52, 52, 52)', opacity: 0.6}}
         style={{ borderColor: "#F17BB0", borderStyle: "dotted", borderWidth: "2px"}}
        >  
      </Rnd>
    )
  }
           
  return null;
}

export default ImageDnD;