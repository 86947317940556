import React from 'react';
import { Card, CardHeader, CardBody} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const PeopleSortList = (props) => {
  const {showId, people, handleSorting} = props;

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    handleSorting(result)
  }

  if(people && people.length > 0) {
    return (
      <Card>
        <CardHeader>
          <h3 className="card-header-title">People</h3>
          <div className='text-right'>
            {props.children}
          </div>
        </CardHeader>
        
        <CardBody>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="people">
              {
                (provided) => (
                  <div className="people list-group list-group-flush" {...provided.droppableProps} ref={provided.innerRef}>
                    {
                      people.map((person, idx) => {
                        return (
                          <Draggable isDragDisabled={false} key={`person-${idx+1}`} draggableId={`person-${idx+1}`} index={idx}>
                            {(provided) => (
                              <div className="list-group-item" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                <div className="row">
                                  <div className="col-auto">
                                    <div className="avatar">
                                      {person.img ? <img src={person.img} alt={person.name} className="avatar-img rounded-circle"/> : <span className="avatar-title rounded-circle">{person.name.slice(0, 2)}</span>}
                                    </div>
                                  </div>
                                  <div className="col ml-n2">
                                    <h4 className="mb-1">{person.name}</h4>
                                    <div><span className="badge badge-primary">{person.group}</span> <span className="badge badge-primary">{person.role}</span></div>
                                    <p>{person.description}</p>
                                    {person.href ? <p>Home page: {person.href}</p> : null}
                                  </div>                  
                                </div>  
                              </div>
                            )}
                          </Draggable>
                        )                      
                      })
                    }
                    {provided.placeholder}
                  </div>
                )
              }    
              
            </Droppable>
          </DragDropContext>
        </CardBody>
      </Card>
    )
  }

  return null;
}

export default PeopleSortList;