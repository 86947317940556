import React, {useContext} from "react";
import {Button } from 'reactstrap';
import {Context as AuthContext} from '../context/AuthContext'

export default function SignupConfirmation() {
  const {resetContextState} = useContext(AuthContext);
  const handleOnClick = () => {
    resetContextState();    
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-5 col-xl-4 my-5">
          <h1 className="display-4 text-center mb-3">Confirm your email</h1>
          <p className="text-muted text-center mb-5">
            Please check your inbox for a confirmation email.  Click the link in the email to confirm your email address.
          </p>
          <Button color="primary"
            type="submit"
            onClick={handleOnClick}
            className="btn btn-primary btn-block mt-3" 
          >Return to Sign in</Button>          
        </div>
      </div>
    </div>
  )
}
