import React, {useEffect, useState, useContext} from "react";
import sanitizeHtml from 'sanitize-html';
import justcastApi from '../api/justcast';
import ShowWebsiteForm from '../components/ShowWebsiteForm';
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import ScreenBlocker from '../components/ScreenBlocker'

const gaValidator = (str) => {
  let re = /(UA)-(.*)-(.*)/;
  let ok = re.exec(str);
  if(ok) {
    return true;
  }
  return false;
}

const ShowWebsiteSettings = (props) => {
  const id = props.match.params.id;
  const [screenBlocker, setScreenBlocker] = useState(false);

  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext);
  const [showName, setShowName] = useState('');  
  const [item, setItem] = useState({});
  const [audioposts, setAudioposts] = useState([]);
  const {addWithTimeout} = useContext(AlertContext);
  const [aboutPageContentError, setAboutPageContentError] = useState('');
  const [googleAnalyticsError, setGoogleAnalyticsError] = useState('');
  // const [aboutPageContent, setAboutPageContent] = useState('');
  // const [googleAnalyticsID, setGoogleAnalyticsID] = useState('');

  useEffect(() => {    
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;
      setShowName(name);
      // setAboutPageContent(res.data.about_page_content);
      // setGoogleAnalyticsID(res.data.google_analytics_id);
      setScreenBlocker(res.data.skill_podcast_website);

      menuContext.setWebsiteMenu({
        title: 'Settings',
        subtitle: name,
        currentPageId: 'website_settings',
        currentTabId: 'website_settings',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website       
      });   
      setItem(res.data);
    })
    .catch((err) => {
      
    })   
    
    justcastApi.get(`/v1/shows/${id}/download_report_audioposts`)
    .then((res) => {
      const items = [ {id: 0, name: 'No episode set...'}, ...res.data];      
      setAudioposts(items)
    })
    .catch((err) => {

    })
  }, [id])

  const handleUpdate = () => {
    const sanitizedAboutPageContent = sanitizeHtml(item.about_page_content);    
    let aboutPageValid = true;
    if(item.about_page_content && sanitizedAboutPageContent.length !== item.about_page_content.length) {     
      aboutPageValid = false
      setAboutPageContentError("Your HTML code is too fancy to us to handle it.  We only support the plain simple HTML for now.  If you need more advanced feature, please send us an email to justcastapp@gmail.com")
      setTimeout(() => {
        setAboutPageContentError('');
        aboutPageValid = true;
      }, 3000)
    }

    let gaTrue = true;
    
    if(item.google_analytics_id) {
      gaTrue = gaValidator(item.google_analytics_id);
    }
  
    if(!gaTrue) {
      setGoogleAnalyticsError("Your Google analytics ID does not have the expected format.  Please dobule check it.  If your GA ID is correct, please send us an email to justcastapp@gmail.com")
      setTimeout(() => {
        setGoogleAnalyticsError('');
        gaTrue = true;
      }, 3000)      
    }

    if(aboutPageValid && gaTrue) {
      justcastApi.put(`/v1/shows/${id}`, item)
      .then((res) => {
        setItem(res.data);
        addWithTimeout({color: `warning`, message: `${showName} has been updated!`, isOpen: true, timeout: 3000})     
      })
      .catch((err) => {
        let message = "Error";
        if(err.response && err.response.data && err.response.data.error) {
          message = err.response.data.error
        }
        addWithTimeout({color: 'danger', message: message, isOpen: true, timeout: 3000})
      })
    }   
  }

  const handleInputValueChange = ({key, value}) => {    
    // console.log({key, value})
    setItem({...item, [key]: value})
  }

  if(screenBlocker === false) {
    return (
      <ScreenBlocker message="Please upgrade your account to access the data."/>
    )
  }  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <ShowWebsiteForm
            data={item}
            audioposts={audioposts}
            handleSubmit={handleUpdate}
            googleAnalyticsError={googleAnalyticsError}
            aboutPageContentError={aboutPageContentError}
            handleInputValueChange={handleInputValueChange}
          />
          <hr className="mt-5 mb-5"/>
          <div className="btn btn-block btn-primary" onClick={handleUpdate}>Save change</div>
          <br/>
        </div>
      </div>
    </div>  
  )
}

export default ShowWebsiteSettings;