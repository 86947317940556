import React, { useEffect, useContext} from "react";
import { Card, CardBody, Button } from 'reactstrap';
import { useAtom } from 'jotai';
import {
  chaptersAtom,
  addChapterAtom,
  saveChaptersAtom,
  chaptersStatusMessageAtom,
  deleteChapterAtom
} from './chaptersAtom'
import { modalAtom, setModalAtom, saveFileAtom } from './modalAtom'
import ChapterInputCard from './ChapterInputCard'
import ChapterModal from './ChapterModal'

const ChaptersInputCard = (props) => {
  const {audiopostId} = props;
  // const { add } = useContext(ModalContext);
  const [message] = useAtom(chaptersStatusMessageAtom);

  const [chapters] = useAtom(chaptersAtom);
  const [, deleteChapter] = useAtom(deleteChapterAtom);
  // const {chapters, version} = chaptersJotai;
  const [, addChapter] = useAtom(addChapterAtom);
  const [, saveChapters] = useAtom(saveChaptersAtom)

  // const [modal] = useAtom(modalAtom);
  const [modal, setModal] = useAtom(setModalAtom)

  const handleSave = () => {
    saveChapters(audiopostId)
  }

  // const [chaptersData, setChaptersData] = useState(null)
  // const [message] = useChaptersHook(chaptersData);

  useEffect(() => {
    if(message) {
      setModal({
        title: 'Update Chapters',
        message, 
        isOpen: true,
        loading: false,
        isSaveButtonOn: true
      })
    }
  }, [message])

  return (
    <div> 
      <ChapterModal audiopostId={audiopostId}/>     
      {
        chapters.map((x, idx) => {
          return (
            <ChapterInputCard key={`chapters-${idx+1}`}
              audiopostId={audiopostId}
              title={x.title}
              startTime={x.start_time}
              startTimeText={x.startTimeText}
              url={x.url}
              img={x.img}
              idx={idx}
              id={x.id}
              handleDelete={deleteChapter}
            />                        
          )
        })
      }
      
      <Card onClick={addChapter} style={{cursor: 'pointer'}}>
        <CardBody>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <i className="fe fe-plus-circle" style={{"fontSize": 24}}/>
            Add new chapter
          </div>
        </CardBody>
      </Card>    
      {props.children}  
      <Card>
        <CardBody>
          <Button onClick={handleSave} color="primary" name="Save" block>Save and Update</Button>
        </CardBody>
      </Card>
    </div>
  )
}

export default ChaptersInputCard;