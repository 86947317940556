import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {Line} from 'react-chartjs-2';

const options = {
  scales: {
    yAxes: [{
      ticks: {
        fontColor: "#E3E3E3"
      }
    }],
    xAxes: [{
      ticks: {
        fontColor: "#E3E3E3"
      }
    }]
  }
}

const legend = {
  labels: {
    fontColor: "#E3E3E3"
  }  
}

const SimpleLineChart = ({data}) => {
  return (
    <Card>
      <CardBody>
        <Line data={data} options={options} legend={legend}/>
      </CardBody>
    </Card>
  )
}

export default SimpleLineChart;