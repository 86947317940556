import React from "react";
import { Card, CardBody, Button, CardFooter } from 'reactstrap';
import TipjarEnableCard from './TipjarEnableCard'

const TipjarSettingCard = (props) => {
  const {
    enabledTipJar, 
    handleSave,
    details_submitted, 
    setupCallback,
    currencyCodes,
    prices,
    priceTypes,
    tipJarId,
    handlePriceChanged,
    handleAddNewPrice,    
    handleRemove,
    handleSorting
  } = props;

  if(details_submitted) {
    // has stripe setup
    // toggle to determine to enable Tip Jar
    return (
      <TipjarEnableCard 
        handleSave={handleSave}
        enabledTipJar={enabledTipJar}
        currencyCodes={currencyCodes}
        priceTypes={priceTypes}
        prices={prices}
        tipJarId={tipJarId}
        handleAddNewPrice={handleAddNewPrice}
        handlePriceChanged={handlePriceChanged}
        handleRemove={handleRemove}
        handleSorting={handleSorting}
      />
    )
  } 

  // not yet setup stripe
  return (
    <Card>
      <CardBody className="text-center">
        <h2 className="mb-3">Create a tip jar for your podcast</h2>
        <p className="small text-muted mb-1">Start receiving donations from your listeners today, paid out to your bank account.</p>
      </CardBody>
      <CardFooter className="card-footer-boxed text-center">
        <Button color="primary" onClick={setupCallback}>Set up a tip jar</Button>
      </CardFooter>      
    </Card>
  )
}

export default TipjarSettingCard