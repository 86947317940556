import React, {useContext} from "react";
import { Alert} from 'reactstrap';
import {Context as AlertContext} from '../context/AlertContext'

const UpdateNotification = () => {
  const {state, reset} = useContext(AlertContext);
  if(state.message) {
    return (
      <Alert color={state.color} isOpen={state.visible} toggle={reset}>
        {state.message}
      </Alert>
    )
  } 
  return null;  
}

export default UpdateNotification;