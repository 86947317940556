import React, {useEffect, useState, useContext} from "react";
import { Redirect } from "react-router-dom";
import queryString from 'query-string'
import { Spinner } from 'reactstrap';
import justcastApi from '../../api/justcast'
import {Context as ModalContext} from '../../context/ModalContext'
import {Context as AuthContext} from '../../context/AuthContext'

// addAlbyConnection
const AlbyConnectionCallback = (props)  => {
  const [redirect, setRedirect] = useState('');
  const [loading, setLoading] = useState(true);
  const {add} = useContext(ModalContext);
  const {addAlbyConnection} = useContext(AuthContext);
  const values = queryString.parse(props.location.search);
  const show_id = props.match.params.show_id;
  const audiopost_id = props.match.params.audiopost_id;

  useEffect(() => {
    if(values && values['code']) {
      const code = values['code'];
      // console.log({oauth_token, oauth_verifier})
      justcastApi.post(`/v1/alby_connections`, {
        access_code: code,
        show_id,
        audiopost_id
      })
      .then((res) => {
        addAlbyConnection({alby_wallet_connections: [res.data]})
        add({color: 'danger', title: "Thank you" , message: `<p>Alby connection has been setup.</p>`, isOpen: true})
        if(audiopost_id && show_id) {
          setRedirect(`/shows/${show_id}/episodes/${audiopost_id}/value_for_value_settings?add_wallet=alby`)
        } else if(show_id) {
          setRedirect(`/shows/${show_id}/value_for_value_settings?add_wallet=alby`)
        } else {
          setRedirect(`/connections`)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        add({color: 'danger', title: "Uh oh! Failed to setup Alby connection." , message: "Connection has not been verified with Alby.", isOpen: true})        
        setRedirect(`/connections`)
      })
    }
  },[])

  if(loading) {
    return <Spinner color="primary" />;
  }
  
  if(redirect) {
    // wait 3 seconds to redirect, and having a spinner to indicating it's loading    
    return <Redirect to={redirect}/>
  }

  return (<></>) 
}

export default AlbyConnectionCallback;