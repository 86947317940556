import React from "react";
import { formatDistance } from 'date-fns'
import {Card, CardBody, CardFooter, Row, Button} from 'reactstrap'
import CloudDriveCardButton from './CloudDriveCardButton'
import OneDriveSvg from '../assets/img/icons/onedrive.svg'
import DropboxSvg from '../assets/img/icons/dropbox.svg'
// import {Link} from 'react-router-dom'

const IconImage = ({name}) => {
  if(name === 'Dropbox') {
    return (
      <div className="card-avatar avatar avatar-lg mx-auto">
        <img src={DropboxSvg} alt="Dropbox Connection" className="avatar-img rounded"/>
      </div>
    )
  } else if (name === 'OneDrive') {
    return (
      <div  className="card-avatar avatar avatar-lg mx-auto">
        <img src={OneDriveSvg} alt="OneDrive Connection" className="avatar-img rounded"/>
      </div>
    )
  } else {
    return null;
  }
}

const Description = ({data}) => {
  if(data && data.account_email && data.last_sync_time) {
    const {account_email, last_sync_time} = data;
    const lastSyncTime = `${formatDistance(new Date(), new Date(last_sync_time))} ago` ;
    return (
      <>
        <p className="small text-muted mb-1">{account_email}</p>
        <p className="small text-muted mb-1">Last updates found: {lastSyncTime}</p>
      </>
    )
  } else {
    return null;
  }
}

const CloudDriveCard = (params) => {
  // TODO
  // adds better handle on emailAddress and lastSyncDate

  const { name, isActive, data, id, handleOnClick} = params;

  return (
    <Card>
      <CardBody className="text-center">
        <IconImage name={name}/>
        <h2 className="mb-3">{name}</h2>
        <Description data={data}/>
      </CardBody>
      <CardFooter className="card-footer-boxed">
        <Row className="align-items-center justify-content-between">
          <div className="col-auto">
            <small>
              {isActive ? <><span className="text-success">●</span> Connected</> : <><span className="text-secondary">●</span> Not Connect</>}
            </small>
          </div>
          <div className="col-auto">
            <CloudDriveCardButton isActive={isActive} handleOnClick={handleOnClick} id={id}/>
          </div>
        </Row>
      </CardFooter>
    </Card>
  )
}

export default CloudDriveCard;