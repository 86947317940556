import React from 'react';
import {Row, Spinner, Card, CardBody} from 'reactstrap';
import BoostrapTable from './../BoostrapTable'
import { NumberFormatCell } from '../../libs/TableCellFormat'
import DateRangePicker from './DateRangePicker'
import DatePickerRange from './DatePickerRange'
import WordCloudCard from './WordCloudCard'

const CardPodcastPlayers = (props) => {
  const {
    datePickerDisplay,
    columns,
    loading, 
    stats, 
    title, 
    subtitle,
    optionsData, 
    optionSelected, 
    fromDate,
    toDate,
    handleOptionChange,
    handleDateChange    
  } = props;

  if(loading) {
		return (
			<Card>
				<div className="card-header">
					<h4 className="card-header-title mr-auto">{title}</h4>
				</div>				
				<div className="card-body"><Spinner/></div>				
			</Card>
		)
  } else {
    return (
      <Card>
				<div className="card-header">
          <div>
            <div style={{fontSize: 24, fontWeight: 'bold'}}>{title}</div>
            {
              subtitle ? <p className="text-muted mb-3">{subtitle}</p> : null
            }
          </div>          					
				</div>
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-4">
              <DateRangePicker
                options={optionsData}
                selected={optionSelected}
                handleChanged={handleOptionChange}
              />
            </div>
          </div>
          <br/>
          <DatePickerRange
            display={datePickerDisplay}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={(x) => {
              handleDateChange('fromDate', x)
            }}
            setToDate={(x) => {
              handleDateChange('toDate', x)
            }}
          />   
          <br/>
          <div className="card-deck">
            <Card>
              <CardBody>
                <div className="table-responsive mb-0">
                  <BoostrapTable data={stats} columns={columns}/>
                </div>
              </CardBody>
            </Card>
            <Card>
              <WordCloudCard data={stats}/>
            </Card>            
          </div>          
        </div>        
      </Card>
    )
  }

}

export default CardPodcastPlayers;