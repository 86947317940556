import { DirectUpload } from "@rails/activestorage"

const baseURL = process.env.REACT_APP_API_PROXY_SERVER_BASE_PATH;

const directUploadAudio = ({file, show_id}, directUploadWillStoreCallback) => {
  const uid = localStorage.getItem('uid');
  const client = localStorage.getItem('client')
  const access_token = localStorage.getItem('access-token');
  const params = {
    uid,
    access_token,
    client,
  }

  if(show_id) {
    params["show_id"] = show_id
  }

  const param_str = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
  return new DirectUpload(file, `${baseURL}/rails/active_storage/direct_uploads?${param_str}`, {
    directUploadWillStoreFileWithXHR: (request) => {
      request.upload.addEventListener("progress",
        event => {
          const {loaded, total} = event;
          if(loaded && total && total > 0 && loaded > 0) {
            directUploadWillStoreCallback(loaded/total);
          }
        })
    },
  });
}

export {
  directUploadAudio
}