import React, {useState, useRef} from 'react'
import justcastApi from '../../api/justcast';
import { Button } from 'reactstrap';

export default function DownloadFileButton({
  label,
  url,
  fileName,
  fileType
}) {
  const aRef = useRef(null);
  const [href, setHref] = useState("");

  const handleClick = () => {
    // we can optimize this if we want
    // cache copy after user downloaded the file, and server it to them the same one
    // except user changed the transcript
    justcastApi.get(url)
    .then((res) => {
      const blob = new Blob([res.data.replace(/&#39;/g, "'")], {type: fileType})
      const fileDownloadUrl = URL.createObjectURL(blob);
      setHref(fileDownloadUrl)
      aRef.current.click();
    })
    .catch((err) => {
      console.log(err)
    })
  }

  return (
    <>
      <Button color="primary" size="sm" onClick={handleClick}>
        <span className="fe fe-download"/> {label}
      </Button>
      <a href={href} ref={aRef} download={fileName}/>
    </>
  )
}
