import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import CardContent from './CardContent'
import ContentPagnation from './ContentPagnation'

export default function KeywordsCard({data, handleReRun}) {
  const {title, status, is_completed, ai_contents, id} = data;
  const [currentPage, setCurrentPage] = useState(0);

  const totalPage = ai_contents && ai_contents.length > 0 ? ai_contents.length : 0;

  const updatePage = (number) => {
    setCurrentPage(number);
  }

  const handleReRunClicked = (id) => {
    handleReRun(id);
    setCurrentPage(0)
  }

  return (
    <Card>
      <ContentPagnation id={id} title={title} totalPage={totalPage} currentPage={currentPage} updatePage={updatePage} handleReRun={handleReRunClicked}>
        <CardBody>
          <CardContent status={status} is_completed={is_completed}>
            <p>
              {ai_contents[currentPage]?.join(", ")}
            </p>
          </CardContent>
        </CardBody>
      </ContentPagnation>
    </Card>
  )
}