import React, {useEffect, useState, useContext} from "react";
import {Row, Col, Card, CardBody, CardTitle, Spinner, CardFooter, FormGroup, Input } from 'reactstrap';
import {Context as AlertContext} from '../context/AlertContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import justcastApi from '../api/justcast';
import ShowCustomizeForm from '../components/ShowCustomizeForm';
import ScreenBlocker from '../components/ScreenBlocker'

const ShowCustomize = (props) => {
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext);
  const id = props.match.params.id;
  const [screenBlocker, setScreenBlocker] = useState(false);
  const [showName, setShowName] = useState('');
  const [item, setItem] = useState({});
  const [showColorThemes, setShowColorThemes] = useState([]);
  const [showColorThemeId, setShowColorThemeId] = useState('');
  const [showColorThemeLabel, setShowColorThemeLabel] = useState('');
  const [websiteColorSchem, setWebsiteColorSchem] = useState({})
  const {addWithTimeout} = useContext(AlertContext);
  const [meetTheHostsTitle, setMeetTheHostsTitle] = useState("");
  const [aboutPageTitle, setAboutPageTitle] = useState("");

  // const [buttonColor, setButtonColor] = useState('');
  // const [buttonTextColor, setButtonTextColor] = useState('');
  // const [cardBackgroundColor, setCardBackgroundColor] = useState('');

  useEffect(() => {
    screenContext.startFetching();

    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;
      setShowName(name);

      menuContext.setWebsiteMenu({
        title: 'Page Customize',
        subtitle: name,
        currentPageId: 'website_settings',
        currentTabId: 'customize',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website        
      });      
      
      if(res.data.showColorThemeId) {        
        setShowColorThemeId(res.data.showColorThemeId)
        setShowColorThemeLabel(res.data.showColorThemeLabel)
      }

      setItem(res.data);
      setScreenBlocker(res.data.skill_podcast_website);

      setMeetTheHostsTitle(res.data.meet_hosts_title ? res.data.meet_hosts_title : "Meet the Hosts");
      setAboutPageTitle(res.data.about_page_title ? res.data.about_page_title : "About the Show");

      setWebsiteColorSchem({
        buttonColor: res.data.buttonColor,
        buttonTextColor: res.data.buttonTextColor,
        cardBackgroundColor: res.data.cardBackgroundColor,
        textColor: res.data.textColor
      })
      screenContext.finishFetching();      
    }) 
    .catch((err) => {
      screenContext.finishFetching();
    })

    justcastApi.get(`/v1/show_color_themes`)
    .then((res) => {      
      setShowColorThemes(res.data)
    }) 
    .catch((err) => {
    })
  },[id])

  const setColor = (e) => {
    setWebsiteColorSchem({...websiteColorSchem, ...e})
  }

  const setTheme = (e) => {
    // console.log(e)
    setShowColorThemeId(e.id)
    setShowColorThemeLabel(e.label)
    // look up colors from the theme array based on id
    const themes = showColorThemes.filter((theme) => theme.id === e.id)
    if(themes.length === 1) {
      const theme = themes[0];
      setWebsiteColorSchem({
        buttonColor: theme.button_color,
        buttonTextColor: theme.button_text_color,
        cardBackgroundColor: theme.card_background_color,
        textColor: theme.text_color
      })
    }
  }

  const handleUpdate = () => {
    const theme = {
      show_color_theme_id: showColorThemeId,
      button_color: websiteColorSchem.buttonColor,
      button_text_color: websiteColorSchem.buttonTextColor,
      card_background_color: websiteColorSchem.cardBackgroundColor,
      text_color: websiteColorSchem.textColor,
      meet_hosts_title :meetTheHostsTitle,
      about_page_title: aboutPageTitle
    }

    justcastApi.put(`/v1/shows/${id}`, theme)
    .then((res) => {    
      addWithTimeout({color: 'warning', message: `${showName} design has been updated!`, isOpen: true, timeout: 6000})
      window.open(res.data.player_page_link)
      // redirect back to show page
      // open a new webpage
    })
    .catch((err) => {
      addWithTimeout({color: 'danger', message: `Failed to update design of ${showName}`, isOpen: true, timeout: 6000})
    })
  }

  const handleMeetTheHostTitleChange = (evt) => {
    setMeetTheHostsTitle(evt.target.value);
  }

  const handleAboutPageTitleChange = (evt) => {
    setAboutPageTitle(evt.target.value);
  }  

  if(screenBlocker === false) {
    return (
      <ScreenBlocker message="Please upgrade your account to access the data."/>
    )
  }    

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> : <> 
                <Card>
                  <CardBody>
                    <CardTitle>Design</CardTitle>
                    <p>This colors will be applied to your podcast website.</p>
                    <hr/>  
                    <ShowCustomizeForm 
                      showColorThemes={showColorThemes} 
                      showColorThemeId={showColorThemeId}
                      showColorThemeLabel={showColorThemeLabel}
                      websiteColorSchem={websiteColorSchem}
                      setColor={setColor}
                      setTheme={setTheme}
                    />
                    <hr/>
                    <div>
                      <h2>About Us page</h2>
                    </div>
                    <div>
                      <p>Change the text "About the Show"</p>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <label>Title</label>
                            <Input
                              type={"text"}
                              name={"title"}
                              id={"about_page_title"}
                              placeholder="Meet the Hosts"
                              value={aboutPageTitle}
                              onChange={handleAboutPageTitleChange}
                            />                             
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <p>Change the title of Hosts section</p>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <label>Title</label>
                            <Input
                              type={"text"}
                              name={"title"}
                              id={"meet_hosts_title"}
                              placeholder="Meet the Hosts"
                              value={meetTheHostsTitle}
                              onChange={handleMeetTheHostTitleChange}
                            />                             
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>     
                                        
                    {/* <Link to={`/shows/${id}/links`} className="btn btn-block btn-link text-muted">Cancel this change</Link>*/}
                  </CardBody>
                  <CardFooter>
                  <div className="btn btn-block btn-primary" onClick={handleUpdate}>Save change</div>
                  </CardFooter>
                </Card>
              </>
            }            
          </div>
        </div>
      </div>  
    </>
  )
}

export default ShowCustomize;