import { atom } from "jotai";

export const chapterAtom = atom({
  id: null,
  title: null,
  start_time: "",
  url: "",
  img: "",
  isOpen: false
})

export const toggleChapterEditor = atom(null, (get, set, _) => {
  const state = get(chapterAtom);
  set(chapterAtom, () => {
    return {
      ...state, isOpen: !(state.isOpen)
    }
  })
})

export const updateChapterAtom = atom((get) => get(chapterAtom), (_get, set, data) => {
  const state = _get(chapterAtom);  
  set(chapterAtom, () => {
    return {
      ...state, ...data
    }
  })
})

// export const initAtom = atom((get) => get(wavesurferAtom), (_get, set, data) => {
//   const {playerRef, duration, onReady} = data;
//   set(wavesurferAtom, () => {
//     return {
//       playerRef, 
//       duration, 
//       onReady,
//       playedSeconds: 0,
//       playing: false,        
//     };
//   });
// })