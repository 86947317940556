import React, {useState, useRef, useContext} from "react";
import { Link } from "react-router-dom";
import 'flatpickr/dist/themes/dark.css'
import Flatpickr from 'react-flatpickr'
import {formatDistance } from 'date-fns'
import {Context as ModalContext} from '../../context/ModalContext'
import {Context as AlertContext} from '../../context/AlertContext'
import { Form, FormGroup, Input, Button, Row, Card, CardBody, InputGroup } from 'reactstrap';
import justcastApi from '../../api/justcast';
import AudioFilePicker from "./AudioFilePicker"
import AudioFileForm from './AudioFileForm'
import FormGroupInputSelect from '../FormGroupInputSelect';
import {LocationInput} from '../location';
import {EpisodeSeasonSelect} from '../seasons'
import Editor from "./Editor"
import AudioPreviewPlayer from "./AudioPreviewPlayer"
import {directUploadAudio} from '../../api/activestorage';
import UploadStatusLabel from './UploadStatusLabel'
import NeedToConvertModal from "./NeedToConvertModal"

const NewEpisodeForm = (props) => {
  const {
    showId, explicitTypes, episodeTypes,
    seasons, seasonId, handleAddSeason, setSeasonId,
    hosts, setRedirectPage
  } = props;

  const quillRef = useRef(null);
  const [modal, setModal] = useState(false);
  const modalContext = useContext(ModalContext);
  const {addWithTimeout} = useContext(AlertContext);
  const [keyword, setKeyword] = useState("")
  const [audioSrc, setAudioSrc] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [duration, setDuration] = useState(null);
  const [durationSecond, setDurationSecond] = useState(null);
  const [name, setName] = useState("");
  // const [uploaded, setUploaded] = useState(0);
  const [episodeTypeId, setEpisodeTypeId] = useState(null);
  const [explicitTypeId, setExplicitTypeId] = useState(null);
  const [audioDate, setAudioDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [locationName, setLocationName] = useState(null);
  const [locationGeo, setLocationGeo] = useState(null);
  const [episodeNumber, setEpisodeNumber] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadedBlob, setUploadedBlob] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [needToConvert, setNeedToConvert] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSave();
  }

  const handleSave = (isDraft) => {
    if(uploadingFile) {
      modalContext.addWithMessages({
        color: "primary",
        title: 'Error',
        messages: ["Can't save while uploading audio file."],
        isOpen: true,
        loading: false
      })
      return;
    }
    if(!audioFile) {
      // TODO alert modal to tell user need to upload audio file
      modalContext.addWithMessages({
        color: "primary",
        title: 'Error',
        messages: ["Audio file must be included before saving your episode."],
        isOpen: true,
        loading: false
      })
      return;
    }
    const message = quillRef?.current?.unprivilegedEditor?.getHTML();
    const data = {
      name,
      explicit_type_id: explicitTypeId || explicitTypes[1]?.id,
      episode_type_id: episodeTypeId || episodeTypes[0]?.id,
      duration,
      duration_second: durationSecond,
      itunes_keywords: keyword,
      audio_date: audioDate,
      hosts,
      episode_number: episodeNumber,
      content_type: audioFile.type,
      signed_id: uploadedBlob?.signed_id,
      need_to_convert: needToConvert
    }
    if(message) {
      data['description'] = message;
    }
    if (seasonId && seasonId > 0) {
      data['season_id'] = seasonId;
    }
    if(locationGeo) {
      data['location_geo'] = locationGeo;
    }
    if(locationName) {
      data['location_name'] = locationName;
    }
    data['is_draft'] = isDraft;

    justcastApi.post(`/v2/shows/${showId}/audioposts/`, data)
    .then((res) => {
      // console.log(res.data);
      // redirect to the episode page
      // add alert
      const data = res.data;
      const id = data.id;
      const draft_status = data.is_draft;
      let message = "";
      if(draft_status === true) {
        message = "Episode saved as a draft";
      } else if (audioDate > new Date()) {
        message = `Episode scheduled to release at ${formatDistance(audioDate, new Date())}`;
      } else {
        message = "Episode is published."
      }
      addWithTimeout({color: 'warning', message: message, isOpen: true, timeout: 8000});
      modalContext.addWithMessages({
        messages: []
      })
      setRedirectPage(`/podcasts/${showId}/episodes/${id}`)
    })
    .catch((err) => {
      if(err.response.data.message) {
        modalContext.addWithMessages({
          color: "primary",
          title: 'Error',
          messages: [err.response.data.message],
          isOpen: true,
          loading: false
        })
      } else {
        const messages = err.response.data.messages;
        const errorMessages = Object.keys(messages).map((name) => `${name}: ${messages[name].join(",")}`)
        modalContext.addWithMessages({
          color: "primary",
          title: 'Error',
          messages: errorMessages,
          isOpen: true,
          loading: false
        })
      }
    })
  }

  const handleQuillEditorChange = (value) => {
    setDescription(value);
  }

  const handleRemoveFileClicked = () => {
    setFileName("");
    setAudioFile(null);
    setAudioSrc(null);
    setProgress(0);
    setDuration(null);
    setDurationSecond(0)
    setUploadingFile(false)
    setUploadedBlob(null)
    setNeedToConvert(false);
  }

  const handleLocationChange = ({name, lat, lng}) => {
    const geo = `geo:${lat},${lng}`;
    setLocationName(name);
    setLocationGeo(geo)
  }

  const hanleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  }

  const handleEpisodeNumber = (event) => {
    setEpisodeNumber(event.target.value);
  }

  const handleFileChange = (file) => {
    setAudioFile(file);
    if(file) {
      setFileName(file['name'])
      if(name?.length === 0) {
        setName(file['name']);
      }

      setUploadingFile(true);

      const upload = directUploadAudio({
        file: file,
        show_id: showId
      }, (val) => {
        setProgress(val)
      })

      upload.create((err, blob) => {
        if(err) {
          console.log(err)
        } else {
          setUploadedBlob(blob)
          setUploadingFile(false)
        }
      })

    } else {
      setFileName("")
      setUploadingFile(false)
      setNeedToConvert(false)
    }
  }

  const handleExplicitTypeChange = (e) => {
    const value = e.target.value;
    setExplicitTypeId(value);
  }

  const handleEpisodeTypeChange = (e) => {
    const value = e.target.value;
    setEpisodeTypeId(value);
  }

  const handleDatepickerChange = (date) => {
    const _audioDate = date[0];

    if(_audioDate > new Date()) {
      const message = "Do you want this published at a future time/date? This episode won't be released in " + formatDistance(audioDate, new Date())
      addWithTimeout({color: 'warning', message, isOpen: true, timeout: 5000})
    }
    setAudioDate(_audioDate)
  }

  const handleSeasonChange = (id) => {
    setSeasonId(id)
  }

  const handleDurationChanged = (sec, timeStr) => {
    setDuration(timeStr);
    setDurationSecond(sec)
  }

  const handleChooseConvert = (convert) => {
    setNeedToConvert(convert);
    setModal(false)
  }

  const toggle = () => {
    setModal(!modal);
  }

  return (
    <>
      <NeedToConvertModal modal={modal} toggle={toggle} handleChooseConvert={handleChooseConvert}/>
      <Form className="mb-4" onSubmit={handleSubmit}>
        <FormGroup>
          <label>Name</label>
          <Input
            type="text"
            className="form-control"
            name="episode-name"
            placeholder="The title of your new episode"
            value={name}
            onChange={hanleNameChange}
          />
        </FormGroup>
        <Row>
          <div className="col-12">
            <FormGroup>
              <label>Publish date</label>
              <Flatpickr
                className='form-control mb-3'
                data-enable-time
                value={audioDate}
                onClose={handleDatepickerChange}/>
                <small className="form-text text-warning">Schedule a future release by change to a future date</small>
            </FormGroup>
          </div>
        </Row>
        <hr/>
        <FormGroup>
          <label>Audio File</label>
          {audioSrc ? <AudioPreviewPlayer
            url={audioSrc}
            title={audioFile?.name}
            fileSize={audioFile?.size}
            handleDurationChanged={handleDurationChanged}
          >
          { uploadingFile ? <UploadStatusLabel progress={progress}/> : <div className='btn btn-danger btn-sm' onClick={handleRemoveFileClicked}>
              <span className="fe fe-trash"/> Delete
            </div>}
          </AudioPreviewPlayer> :
          <AudioFileForm
            setSrc={setAudioSrc}
            setFile={handleFileChange}
            setModal={setModal}
          />}
          <div style={{marginTop: "8px"}}><small className="text-muted">Supported file types are MP3, WAV, or M4A. Audio files will be encoded up to 128kbps MP3s and the file size cannot exceed 1GB. Variable bit rate files under 128 kbps are not supported.</small></div>
        </FormGroup>
        <hr/>
        <Row>
          <div className="col-12 col-md-6">
            <EpisodeSeasonSelect
              seasons={seasons}
              season_id={seasonId}
              handleSeasonChange={handleSeasonChange}
              handleAddSeason={handleAddSeason}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroup>
              <label>Episode Number</label>
              <Input
                type="text"
                className="form-control"
                name="episode_number"
                placeholder="1, 2, 3..."
                value={episodeNumber ? episodeNumber : ""}
                onChange={handleEpisodeNumber}
              />
            </FormGroup>
          </div>
        </Row>
        <Row>
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select"
              inputLabel="Explicit type"
              inputName='explicit_type_id'
              optionLabel="label"
              optionId="id"
              mutedText="If you specify true, indicating the presence of explicit content, Apple Podcasts displays an Explicit parental advisory graphic for your podcast.  Podcasts containing explicit material aren’t available in some Apple Podcasts territories."
              options={explicitTypes}
              handleInputChange={handleExplicitTypeChange}
              inputValue={explicitTypeId || explicitTypes[1]?.id}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select"
              inputLabel="Episode type"
              inputName='episode_type_id'
              optionLabel="name"
              optionId="id"
              mutedText="Use Trailer for a short, promotional piece of content that represents a preview of a show.  Use bonus for extra content (for example, behind the scenes information or interviews with the cast)"
              options={episodeTypes}
              handleInputChange={handleEpisodeTypeChange}
              inputValue={episodeTypeId || episodeTypes[0]?.id }
            />
          </div>
        </Row>
        <hr/>
        <FormGroup>
          <label>Description</label>
          <Editor
            quillRef={quillRef}
            value={description}
            handleQuillEditorChange={handleQuillEditorChange}
          />
        </FormGroup>
        <hr/>
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <label>Search Keywords</label>
              <small className="form-text text-muted">Add relevant search keywords about your episode to help people find it.</small>
              <Input
                type="text"
                className="form-control"
                name="keyword"
                placeholder="e.g. business,investment"
                value={keyword}
                onChange={handleKeywordChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <LocationInput
              handleLocationChange={handleLocationChange}
            />
          </div>
        </div>
        <hr/>
        {props.children}
        <hr/>
        <div style={{margin: "40px 0 40px 0", display: "flex", columnGap: "10px", justifyContent: "flex-end"}}>
          <Link to={`/shows/${showId}/episodes`} className="btn btn-link text-muted">Cancel</Link>
          <div className="btn btn-outline-warning" onClick={() => {
            handleSave(true)
          }}>Save Draft</div>
          <div className="btn btn-primary" onClick={() => {
            handleSave(false)
          }}>Publish</div>
        </div>
      </Form>
    </>
  )
}

export default NewEpisodeForm;