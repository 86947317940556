import React, {useEffect, useState} from 'react';
import { WordCloud } from '@ant-design/charts';

const WordCloudCard = (props) => {
  const {data} = props;

  var config = {
    data: data,
    wordField: 'name',
    weightField: 'downloads',
    color: '#FCD161',
    wordStyle: {
      fontFamily: 'Verdana',
      fontSize: [
        24,
        80
      ]
    },
    interactions: [{ type: 'element-active' }],
    state: { active: { style: { lineWidth: 3 } } }
  };  

  if(data) {
    return <WordCloud {...config} />;
  }

  return null;
}

export default WordCloudCard;