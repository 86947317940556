import React, {useMemo} from "react";
import { CSVLink } from "react-csv";
import { Button, Input, InputGroup, InputGroupAddon, Card, CardBody, CardHeader } from 'reactstrap';
import {DateFormatCell, PlainTextCell, PennyToDollarFormatCell} from '../../libs/TableCellFormat'
import { DefaultColumnFilter } from '../../libs/TableFilter'
import BoostrapTable from '../BoostrapTable'

const TipsTable = (props) => {
  
  const {data} = props;

  const columns = useMemo(() => [
    {
      Header: 'Email',
      accessor: 'stripe_customer_email',
      Cell: PlainTextCell,
      Filter: DefaultColumnFilter,
      // filter: 'fuzzyText',
    },
    {
      Header: 'Total Amount',
      accessor: 'amount_total',
      Cell: PennyToDollarFormatCell
    },
    {
      Header: 'Card Brand',
      accessor: 'card_brand',
      Cell: PlainTextCell
    },
    {
      Header: 'Card Exp MM/YYYY',
      accessor: 'card_exp_date',
      Cell: PlainTextCell
    },
    {
      Header: 'Last 4',
      accessor: 'last4',
      Cell: PlainTextCell
    },    
    {
      Header: 'Date',
      accessor: 'created_at',
      Cell: DateFormatCell
    },      
  ])

  if(data) {
    return (
      <Card>
        <CardHeader>
          <CSVLink
            data={data}
            filename={`tips.csv`}
            className="btn btn-primary btn-sm"
            target="_blank"
          >
            Export to CSV
          </CSVLink> 
        </CardHeader>
        <CardBody>
          <div className="table-responsive">
            <BoostrapTable data={data} columns={columns}/>
          </div>
        </CardBody>
      </Card>
    )
  }

  return null;
}

export default TipsTable; 