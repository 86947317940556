import React from "react";
import { Form, FormGroup, Input} from 'reactstrap';

const MainForm = (props) => {

  const {value_type, value_method, value_suggested_amount, handleChange} = props;

  return (
    <div>
      <Form className="mb-4">
        <FormGroup>
          <label>Type (required)</label>
          <Input 
            type="text" 
            className="form-control" 
            value={value_type ? value_type : ""} 
            name="value_type"
            placeholder="This is the service slug of the cryptocurrency or protocol layer. e.g. bitcoin, lightning" 
            onChange={handleChange}/>
        </FormGroup>
        <FormGroup>
          <label>Method (required)</label>
          <Input 
            type="text" 
            className="form-control" 
            value={value_method ? value_method : ""} 
            name="value_method"
            placeholder="This is the transport mechanism that will be used. e.g. keysend, amp, wallet, node." 
            onChange={handleChange}/>
        </FormGroup> 
        <FormGroup>
          <label>Suggested (optional)</label>
          <Input 
            type="text" 
            className="form-control" 
            value={value_suggested_amount ? value_suggested_amount : ""} 
            name="value_suggested_amount"
            placeholder="This is an optional suggestion on how much cryptocurrency to send with each payment. e.g. 0.00000005000" 
            onChange={handleChange}/>
        </FormGroup>                
      </Form>
      {props.children}
    </div>
  )
}

export default MainForm;