import React, {useState} from 'react'
import { Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner} from 'reactstrap';
import FileUploadDropzone  from './FileUploadDropzone'
import MessageBox  from './MessageBox'

const FileUploader = ({
  title,
  subtitle,
  squareArtwork,
  uploadFile,
  messageBoxSubtitle,
  acceptFile,
  messages,
  setMessages,
  handleUploadFileChange,
  handleFileChange,
  setConvertToMp3
}) => {

  // const [messages, setMessages] = useState([]);
  const [messageTitle, setMessageTitle] = useState('');
  
  return (
    <>
      <label>{title}</label>
      <Row>
        <div className="col-12 col-md-6">
          <MessageBox
            uploadFile={uploadFile}
            title={messageTitle}
            subtitle={messageBoxSubtitle}
            messages={messages}
          />
        </div>
        <div className="col-12 col-md-6">        
          <FileUploadDropzone 
            squareArtwork={squareArtwork}
            acceptFile={acceptFile}
            handleFileChange={handleFileChange}
            handleMessages={setMessages}
            handleMessageTitle={setMessageTitle}
            handleUploadFileChange={handleUploadFileChange}
            setConvertToMp3={setConvertToMp3}
          />
        </div> 
      </Row>      
    </>
  )
}

export default FileUploader