// https://github.com/Podcastindex-org/podcast-namespace/blob/main/docs/1.0.md#soundbite
import { atom } from "jotai";
import justcastApi from '../../api/justcast'
// import {getSecondsFromHHMMSS, getHHMMSSFromSeconds} from './../../libs'
import {getSecondsFromHHMMSS, getHHMMSSFromSeconds} from '../../libs/timeTransformation'

// Version 1.2 - Updated on 2021.04.15
const temp = []

export const soundbitesAtom = atom(temp);
export const soundbitesStatusMessageAtom = atom(null);

export const soundbiteChangeAtom = atom(null, (get, set, item) => {
  const prevState = get(soundbitesAtom);
  const {idx, name, value} = item;
  const updateSoundbite = {...prevState[idx]}
  if(name === 'startTimeText') {
    updateSoundbite['start_time'] = getSecondsFromHHMMSS(value);
  } else if (name === 'endTimeText') {
    updateSoundbite['end_time'] = getSecondsFromHHMMSS(value);
  } 
  updateSoundbite[name] = value;  
  const newState = [...prevState]
  newState[idx] = updateSoundbite;
  set(soundbitesAtom, newState)
})

export const addSoundbiteAtom = atom(null, (get, set, _) => {
  const prevState = get(soundbitesAtom);
  const item = {startTimeText: "", endTimeText: "", title: ""}
  set(soundbitesAtom, [...prevState, item])
})


export const saveSoundbitesAtom = atom(null, (get, set, audiopostId) => {
  const items = get(soundbitesAtom);

  justcastApi.post(`/v1/audioposts/${audiopostId}/many_soundbites`, {items})
  .then((res) => {
    const resData = res.data;
    set(soundbitesAtom, resData)
    set(soundbitesStatusMessageAtom, "Soundbites updated.")
    setTimeout(() => {
      set(soundbitesStatusMessageAtom, null)
    }, 3000)
  })
  .catch((err) => {
    console.log(err);
  })

})

export const deleteSoundbiteAtom = atom(null, async (get, set, item) => {
  const prevState = get(soundbitesAtom);
  const {audiopostId, id, idx} = item;  
  if(id) {
    // destroy
    justcastApi.delete(`/v1/audioposts/${audiopostId}/soundbites/${id}`)
    .then((res) => {
      const newData = prevState.filter((v, i) => i !== idx);
      // const newData = [...prevState];
      set(soundbitesAtom, newData)      
    }).catch((err) => {
      console.log(err.response.data)
    })    
  } else {
    // remove    
    // newData.splice(idx, 1)
    const newData = prevState.filter((v, i) => i !== idx);
    set(soundbitesAtom, newData)
  }
})
