import React, {useEffect, useState, useContext} from "react";
import justcastApi from '../api/justcast'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as ScreenContext} from '../context/ScreenContext'
import { Spinner, Card, CardBody } from 'reactstrap';
import {PersonNote, PersonTable, PersonForm} from '../components/people'
import {uploadImageHandler} from '../api/doUploader'

const ShowPeople = (props) => {
  const id = props.match.params.id;
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const {addWithMessages, add} = useContext(ModalContext);
  const [showName, setShowName] = useState('');
  const [personTaxonomies, setPersonTaxonomies] = useState([]);
  const [person, setPerson] = useState({
    name: "", description: "", href: "", img: "", email: "", role: "", group: "", mastodon: "", matrix: "",
    facebook_url: "", twitter_url: "", linkedin_url: "", tiktok_url: "", instagram_url: "", youtube_url: "", 
    roles: []
  })
  const [people, setPeople] = useState([])
  const [artwork, setArtwork] = useState("");

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}/people`)
    .then((res) => {
      const name = res.data.podcast_title;
      setShowName(name);
      setPeople(res.data.people)
      const options = res.data.person_taxonomies.map((x, idx) => {
        return {
          value: x.code, label: x.role_title, ...x
        }
      });

      setPersonTaxonomies(options)
      screenContext.finishFetching();
      
      menuContext.setSettingsMenus({
        title: 'Person',
        subtitle: name,
        currentPageId: 'settings',
        currentTabId: 'people_settings',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        skill_podcast_website: res.data.skill_podcast_website     
      });     
            
    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
      // const messages = err.response.data.message.map((x) =>)

    
      // setDenied(true)
    })    
  }, [])

  const handlePersonChange = (evt) => {
    const {value, name} = evt.target;
    const _person = {...person, [name]: value};
    setPerson(_person);
  }

  const handlePersonSave = () => {
    addWithMessages({
      color: "primary", 
      title: 'Add a new person',
      message: "Adding new person",
      messages: [], 
      isOpen: true,
      loading: true
    })   

    uploadImageHandler(artwork)
    .then((imgRes) => {
      if(imgRes) {        
        return {...person, img: imgRes.data.path};
      } else {
        return person;
      }
    })
    .then((_person) => {
      return justcastApi.post(`/v1/shows/${id}/people`, _person)
    })
    .then((res) => {
      addWithMessages({
        color: "primary", 
        title: 'Adding a new person',
        messages: [], 
        isOpen: true,
        loading: true
      })   

      setTimeout(() => {
        setPeople([...people, res.data])
        setPerson({name: "", description: "", href: "", img: "", email: "", group: "", role: "", code: "", roles: []})
        addWithMessages({
          color: "primary", 
          title: 'Success add a new person',
          messages: [`Name: ${person.name}`, `Role: ${person.role}`], 
          isOpen: true,
          loading: false
        })   
      }, 5000)

      return res.data;
    })
    .catch((err) => {
      // console.log(err.response.data.message);
      if(err.response.status === 500) {
        add({
          color: "primary", 
          title: 'Error:',
          message: "Something went wrong.  Please contact us for help.", 
          isOpen: true,
          loading: false
        }) 
      } else if(err && err.response && err.response.data) {
        const errs = err.response.data;
        const keys = Object.keys(errs);

        const messages = keys.map((key) => `${key} ${errs[key].join(',')}`)

        addWithMessages({
          color: "primary", 
          title: 'Error:',
          messages: messages, 
          isOpen: true,
          loading: false
        })                    

      } else {
        console.log(err);

        add({
          color: "primary", 
          title: 'Error:',
          message: "Something went wrong.  Please contact us for help.", 
          isOpen: true,
          loading: false
        })  
      }
       
    })
  }

  const handleDeleteClicked = (personId) => {
    justcastApi.delete(`/v1/shows/${id}/people/${personId}`)
    .then((res) => {
      const removedId = res.data.id;
      const _peopled = people.filter((x) => x.id !== removedId);
      setPeople(_peopled)

      add({
        color: "primary", 
        title: 'Deleted:',
        message: `Removed the ${res.data.name}.`, 
        messages: [],
        isOpen: true,
        loading: false
      })
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handleTaxonomyChange = (e) => {
    
    if(e && e !== null && e.length > 0) {
      const _roles = e.map((role) => {
        const {code, group_title, role_title, value, label} = role;
        return {code, group_title, role_title, value, label}
      })

      const {role_title, group_title} = e[0]; // the first one will be our primary
      const _person = {...person, roles: _roles, role: role_title, group: group_title};
      setPerson(_person);
    } else {
      const _person = {...person, roles: [], role: "", group: ""};
      setPerson(_person);
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              screenContext.state.loading ? <Spinner color="primary" /> : 
              <div>
                <PersonNote>
                  <p>This element specifies a person of interest to the podcast. It is primarily intended to identify people like hosts, co-hosts and guests.</p>
                </PersonNote>
                <PersonForm
                  name={person.name}
                  description={person.description}
                  href={person.href}
                  img={person.img}
                  role={person.role}
                  group={person.group}
                  roles={person.roles}
                  email={person.email}
                  facebook_url={person.facebook_url}
                  twitter_url={person.twitter_url}
                  linkedin_url={person.linkedin_url}
                  tiktok_url={person.tiktok_url}
                  instagram_url={person.instagram_url}
                  youtube_url={person.youtube_url}
                  patreon={person.patreon}
                  matrix={person.matrix}
                  mastodon={person.mastodon}
                  personTaxonomies={personTaxonomies}
                  handleChange={handlePersonChange}
                  handleTaxonomyChange={handleTaxonomyChange}
                  handleSave={handlePersonSave}
                  setArtwork={setArtwork}
                />
                <PersonTable showId={id} people={people} handleDeleteClicked={handleDeleteClicked} />
              </div>              
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ShowPeople;