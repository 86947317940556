import React, {useContext, useState} from 'react'
import {Card, CardBody, CardFooter, Row, Col, Button, ButtonGroup} from 'reactstrap'
import icon from '../../assets/img/icons/mailerlite-icon.svg'
import {Context as AuthContext} from '../../context/AuthContext'
import MailerLiteApiKeyModal from './MailerLiteApiKeyModal'

export default function MailerLiteConnection() {
  const authContext = useContext(AuthContext);
  const {createMailerLiteAccount, deleteMailerLiteAccount} = authContext;
  const {mailer_lite_accounts} = authContext.state;
  const buttonLabel = mailer_lite_accounts.length > 0 ? "Update" : "Connect"
  const [modal, setModal] = useState(false);

  // onclick then open a modal
  // 1. modal ask user to change the token, or delete the connection
  // TODO
  // because we do allow multiple connection, maybe having a list of token in the future  

  const handleOnClick = () => {
    setModal(true);
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  const handleDelete = () => {
    // for now, we only have one account per user
    // TODO: allow user to pick which account to delete
    const id = mailer_lite_accounts[0]?.id;
    deleteMailerLiteAccount(id)
  }

  return (
    <>
    <MailerLiteApiKeyModal isOpen={modal} toggleModal={toggleModal}/>
    <Card>
      <CardBody className="text-center">
        <div className="card-avatar avatar avatar-lg mx-auto">
          <img src={icon} alt="MailerLite Connection" className="avatar-img rounded"/>
        </div>
        <h2 className="mb-3">MailerLite</h2>
        <Button name="mailerlite-learn-more" size="sm" color="primary" target="_blank" href={`${process.env["REACT_APP_LANDING_PAGE"]}/mailerlite-podcast-integration`}>Learn more</Button>
      </CardBody>
      <CardFooter className="card-footer-boxed">
        {mailer_lite_accounts.length === 0 && 
          <Row className="align-items-center justify-content-between">
            <div className="col-auto">
              <span className="text-secondary">●</span> Not Connect
            </div>
            <div className="col-auto">
              <Button name="mailerlite-learn-more" size="sm" color="warning" target="_blank" href="https://dashboard.mailerlite.com/integrations/api">API Key</Button> {" "}
              <Button name="connect" size="sm" color="primary" onClick={handleOnClick}>Connect</Button>
            </div>
          </Row>
        }
        {mailer_lite_accounts.length > 0 && <div style={{display: 'flex', flexDirection: "column", gap: "20px"}}>
          {
            mailer_lite_accounts?.map((mailer_lite_account) => {
              return (
                <Row className="align-items-center justify-content-between" key={mailer_lite_account.id}>
                  <div className="col-auto">
                    {mailer_lite_account.is_active ? <><span className="text-success">●</span> Connected</> : <><span className="text-secondary">●</span> Not Connect</>}
                  </div>
                  <div className="col-auto">
                    <Button name="delete" size="sm" color="secondary" onClick={handleDelete}>Delete</Button>
                  </div>
                </Row>
              )
            })
          }
        </div>}
      </CardFooter>
    </Card>
    </>
  )
}
