import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import { Collapse, Button, Card, CardBody, CardFooter, Badge, InputGroup, Label, Form, FormGroup, Row, Col, FormText } from 'reactstrap';
import Select from 'react-select'
import LabelTooltip from './LabelTooltip'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.isSelected ? 'white' : 'blue',
  })
}

const countries = [
  "United States",
  "Afghanistan",
  "Aland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua And Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegowina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic Of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthelemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
].map((c) => { return {value: c, label: c}})


export default function SenderAuthForm({handleCreate, formErrors, toggle}) {
  const { control, register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => handleCreate(data);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row form>
        <Col md={12}>
          <FormGroup>
            <Label for="nickname">Nickame <LabelTooltip id="nickname" message="Nickname is for your reference only; this field will not be displayed to recipients."/></Label>
            <input
              type="text"
              className="form-control"
              placeholder="Nathan" {...register("nickname", {required: true})} />
            {errors.nickname && <FormText color="warning">Nickname can't be empty</FormText>}
            {formErrors?.nickname && <FormText color="warning">{formErrors?.nickname}</FormText>}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={12}>
          <FormGroup>
            <Label for="from_name">From Name <LabelTooltip id="from_name" message="Friendly name to show recipient rather than the email address."/></Label>
            <input
              type="text"
              className="form-control"
              placeholder="Nathan" {...register("from_name", {required: true})} />
            {errors.from_name && <FormText color="warning">From name can't be empty</FormText>}
            {formErrors?.from_name && <FormText color="warning">{formErrors?.from_name}</FormText>}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={12}>
          <FormGroup>
            <Label for="from_email">From Email Address <LabelTooltip id="from_email" message="Verification is required. If this email domain doesn't match one of your authenticated domains, you'll need to verify ownership of this email address before using this sender. We'll send this email address a verification email after you create this sender."/></Label>
            <input
              type="text"
              className="form-control"
              placeholder="nathan@abc.com" {...register("from_email", {required: true})} />
            {errors.from_email && <FormText color="warning">From Email can't be empty</FormText>}
            {formErrors?.from_email && <FormText color="warning">{formErrors?.from_email}</FormText>}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={12}>
          <FormGroup>
            <Label for="reply_to">Reply To Email <LabelTooltip id="reply_to" message="Email address that recipient will reply to."/></Label>
            <input
              type="text"
              className="form-control"
              placeholder="nathan@abc.com" {...register("reply_to", {required: true})} />
            {errors.reply_to && <FormText color="warning">Reply to can't be empty</FormText>}
            {formErrors?.reply_to && <FormText color="warning">{formErrors?.reply_to}</FormText>}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={12}>
          <FormGroup>
            <Label for="address">Company Address</Label>
            <input
              type="text"
              className="form-control"
              placeholder="123 Main St" {...register("address", {required: true})} />
            {errors.address && <FormText color="warning">Company address can't be empty</FormText>}
            {formErrors?.address && <FormText color="warning">{formErrors?.address}</FormText>}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="city">City</Label>
            <input
              type="text"
              className="form-control"
              placeholder="San Diego" {...register("city", {required: true})} />
            {errors.city && <FormText color="warning">City can't be empty</FormText>}
            {formErrors?.city && <FormText color="warning">{formErrors?.city}</FormText>}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="country">Country</Label>
            <Controller
              name="country"
              rules={{ required: true }}
              control={control}
              render={({ field }) => <Select
                {...field}
                styles={customStyles}
                options={countries}
              />}
            />
            {errors.country && <FormText color="warning">Country can't be empty</FormText>}
            {formErrors?.country && <FormText color="warning">{formErrors?.country}</FormText>}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="zip">Zip Code</Label>
            <input
              type="text"
              className="form-control"
              placeholder="89213" {...register("zip")} />
          </FormGroup>
        </Col>
      </Row>
      <div style={{display: "flex", columnGap: "10px", justifyContent: "flex-end"}}>
        <Button color="danger" onClick={toggle}>Cancel</Button>
        <Button color="primary">Save</Button>
      </div>
    </Form>
  )
}
