import React from 'react'
import { FormGroup, Input, FormMutedText, Button } from 'reactstrap';

const SelectionOptions = ({options}) => {
  return (
    options.map((item) =>
      <option
      key={"season"+item.id.toString()}
      value={item.id} >{item.name ? item.name : item.number}</option>
    )
  )
}

export default function EpisodeSeasonSelect({seasons, season_id, handleSeasonChange, handleAddSeason}) {

  const handleInputChange = (e) => {
    handleSeasonChange(e.target.value);
  }

  return (
    <FormGroup>
      <label>Season</label>
      <div style={{display: 'flex', gap: "5px"}}>
        <Input
          className="episode-season"
          type="select"
          name="season_id"
          value={season_id ? season_id : -1}
          placeholder={"Pick a season"}
          onChange={handleInputChange}
        >
        <option
          value={-1} >Create your first season</option>
          <SelectionOptions options={seasons}/>
        </Input>
        <div className='btn btn-secondary' style={{ whiteSpace: 'nowrap'}} onClick={handleAddSeason}>Add season?</div>
      </div>
    </FormGroup>
  )
}
