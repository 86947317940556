import React, {useContext, useState} from 'react'
import {Card, CardBody, CardFooter, Row, Col, Button, ButtonGroup} from 'reactstrap'
import icon from '../../assets/img/icons/alby-icon.svg'
import {Context as AuthContext} from '../../context/AuthContext'

export default function AlbyConnection() {
  const authContext = useContext(AuthContext);
  const {createAlbyConnection, deleteAlbyConnection} = authContext;
  const {alby_wallet_connections} = authContext.state;

  const handleConnect = () => {
    createAlbyConnection()
  }

  const handleDelete = () => {
    const id = alby_wallet_connections[0]?.id;
    if(id) {
      deleteAlbyConnection(id)
    }
  }

  return (
    <Card>
      <CardBody className="text-center">
        <div className="card-avatar avatar avatar-lg mx-auto">
          <img src={icon} alt="Alby Connection" className="avatar-img rounded"/>
        </div>
        <h2 className="mb-3">Alby</h2>
        <p>Start accepting value4value payments</p>
      </CardBody>
      <CardFooter className="card-footer-boxed">
        {alby_wallet_connections.length === 0 && 
          <Row className="align-items-center justify-content-between">
            <div className="col-auto">
              <span className="text-secondary">●</span> Not Connect
            </div>
            <div className="col-auto">
              <Button name="connect" size="sm" color="primary" onClick={handleConnect}>Connect</Button>
            </div>
          </Row>
        }
        {alby_wallet_connections.length > 0 && <div style={{display: 'flex', flexDirection: "column", gap: "20px"}}>
            <Row className="align-items-center justify-content-between">
              <div className="col-auto">
                <span className="text-success">●</span> Connected                
              </div>
              <div className="col-auto">
                <Button name="delete" size="sm" color="secondary" onClick={handleDelete}>Delete</Button>
              </div>
            </Row>
        </div>}        
      </CardFooter>
    </Card>
  )
}
