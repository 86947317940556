import React from 'react'
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import Pagnation from './Pagnation'
import PlaylistItem from './PlaylistItem'

const PlaylistItems = ({items, showArtwork}) => {  
  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col><h4 className="card-header-title">{`Episodes (${items.length})`}</h4></Col>
        </Row>
      </CardHeader>
      <CardBody>
        <ul className="list-group list-group-lg list-group-flush list my-n4">
          {
            items.map(item => {
              return <PlaylistItem 
                key={item.id.toString()}
                id={item.id}
                name={item.name} 
                showArtwork={showArtwork} 
                artwork={item.artwork_url}
                description={item.description}
                audioDate={item.audio_date}
                audioUrl={item.audio_url}
              />;
            })
          }
        </ul>
      </CardBody>
    </Card>
  )
}

export default PlaylistItems;