import React, {useContext} from "react";
import { Alert } from 'reactstrap';
import {Context as AlertContext} from '../context/AlertContext'
import {Context as AuthContext} from '../context/AuthContext'

const UserAlert = () => {
  const {state, reset} = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  if(state.message) {
    return (
      <Alert color={state.color} isOpen={state.visible} toggle={reset}>
        {state.message}
      </Alert>
    )
  } else if(authContext.state.message) {
    return (
      <Alert color='warning' isOpen={authContext.state.isOpen} toggle={authContext.closeAlert}>
        {authContext.state.message}
      </Alert>
    )    
  }
  return null;
} 

export default UserAlert;