import React from 'react'
import Messages from './Messages';

const MessageBox = ({title, uploadFile, subtitle, messages}) => {
  if( messages && messages.length > 0) {
    return (
      <Messages title={title} messages={messages}/>
    )
  }

  return (    
    <div className="card bg-light border">
      <div className="card-body">        
        <h4 className="mb-2">
          <i className="fe fe-alert-triangle"></i> Warning
        </h4>
        <p className="small text-muted mb-0">
          {subtitle}
        </p>
      </div>
    </div>        
  )    

}

export default MessageBox;
