import React from 'react';
import Select from 'react-select'
import {Row, Spinner, Card} from 'reactstrap';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',    
  }),
}

const DateRangePicker = (props) => {
  const {options, selected, handleChanged} = props;
  
  if(selected && options && options.length > 0) {
    
    return (
      <Select
        options={options}
        value={selected}
        onChange={handleChanged}
        styles={customStyles}
      />
    )
  }
  return null;
}

export default DateRangePicker;