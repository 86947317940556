import { Dropbox } from 'dropbox';

//set credentials
// const dropbox = dropboxV2Api.authenticate({
//   client_id: process.env.REACT_APP_DROPBOX_CLIENT_ID,
//   client_secret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET,
//   redirect_uri: process.env.REACT_APP_DROPBOX_REDIRECT_URL
// });

//generate and visit authorization sevice 
// const authUrl = dropbox.generateAuthUrl();
//after redirection, you should receive code
// dropbox.getToken(code, (err, result, response) => {
  //you are authorized now!
// });

export const getDropboxAuthUrl = (cb) => {
  const dbx = new Dropbox({fetch:fetch, clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID});
  const callbackURL = `${process.env.REACT_APP_DROPBOX_REDIRECT_URL}`
  // const authUrl = dbx.getAuthenticationUrl(callbackURL);

  dbx.auth.getAuthenticationUrl(callbackURL, null, 'code', 'offline', null, 'none', false)
  .then((authUrl) => {
    // console.log(authUrl)
    cb(authUrl)
  })
  .catch((err) => {
    console.log(err)
    cb(null);
  })  
}

export default {};