import React, {useState} from 'react'
import { Link } from "react-router-dom";
import { ArrowDown, ArrowRight, Folder  } from 'react-feather';
import ExplorerList from './ExplorerList'

const TreeItem = ({node}) => {
  const [expand, setExpand] = useState(false);

  const toggle = () => {
    setExpand(!expand);
  }

  if(node && node.id) {
    return (
      <div style={{marginBottom: "20px"}}>
        <div style={{
          marginTop: "10px",
          display: "flex",
          alignItems: 'center',
          gap: "10px"          
        }}>
          <div style={{
            display: "flex",
            alignItems: 'center',
            gap: "10px"
          }} onClick={toggle} >
            {expand ? <ArrowDown/> : <ArrowRight/>} <Folder/> 
          </div>
          <div style={{fontSize: "16px"}}>{node.name}</div>
          <Link className='btn btn-primary btn-sm' to={`/shows/${node.id}/overview`}>View</Link>
        </div>
        <div style={{marginLeft: "20px"}}>
          {
            expand && <ExplorerList showId={node.id}/>
          }
        </div>
      </div>
    )
  }

  return null;
}

export default TreeItem;

{/* <Card>
<CardHeader><h4>{node.name}</h4></CardHeader>
<CardBody>
  <div onClick={toggle}>click</div>
  {expand && <Card>
      <ExplorerList showId={node.id}/>
  </Card>}
</CardBody>
</Card> */}