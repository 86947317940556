import React, {useEffect, useState, useContext} from "react";
import { Redirect } from "react-router-dom";
import queryString from 'query-string'
import { Spinner } from 'reactstrap';
import justcastApi from '../api/justcast'
import {Context as AlertContext} from '../context/AlertContext'

const OneDriveConnectionCallback = (props)  => {  
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const alertContext = useContext(AlertContext);

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    if(values && values['code']) {
      const token = values['code'];
      justcastApi.post(`/v1/one_drive_connections`, {code: token})
      .then((res) => {
        // console.log(res)
        setLoading(false)
        setRedirect(true)  
        alertContext.addWithTimeout({color: 'warning', message: `We are successfully connect to OneDrive`, isOpen: true, timeout: 3000})
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false)
        setRedirect(true)
        alertContext.addWithTimeout({color: 'warning', message: `We are failed to connect to OneDrive.  Please make sure you are connecting to a Personal OneDrive, not the Business one.  We're only supporting OneDrive Personal at the moment.`, isOpen: true, timeout: 6000})
      })    
    }
  },[])

  if(redirect) {
    return <Redirect to="/connections"/>
  }
  if(loading) {
    return <Spinner color="primary" />;
  }
  return (<>Hello world</>) 
}

export default OneDriveConnectionCallback