import React, {useEffect, useState, useContext} from "react";
import io from "socket.io-client";
// import useSocket from 'use-socket.io-client';
import queryString from 'query-string'
import { Link } from "react-router-dom";
import {Button, Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import justcastApi from '../api/justcast';
import {Context as MenuContext} from '../context/MenuContext'
import PodieoList from '../components/Podieos/PodieoList';
import {Context as AlertContext} from '../context/AlertContext'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as SocketContext} from '../context/SocketContext'
import {Mixpanel} from '../api/mixpanel'

const EpisodePodieos = (props) => {
  const id = props.match.params.id;
  const [showId, setShowId] = useState('');

  // const [socket, setSocket] = useState(null);
  const socketContext = useContext(SocketContext);
  const authContext = useContext(AuthContext);
  const uid = authContext.state.uid;
  const {setEpisodePageMenu} = useContext(MenuContext);
  const {state, add, addChannel} = socketContext;
  const {socket, channels}  = state;
  // const [socketConnected, setSocketConnected] = useState(false);

  const {addWithTimeout, message} = useContext(AlertContext);
  const [showName, setShowName] = useState('');
  const [episodeName, setEpisodeName] = useState('');
  const [duration, setDuration] = useState('')
  const [items, setItems] = useState([]);
  const [podieoAllowTime, setPodieoAllowTime] = useState(0);
  const [listenToSocket, setListenToSocket] = useState(false);
  const [socketReceived, setSocketReceived] = useState(0);

  // addWithTimeout({color: 'info', message: `It ususally takes 10 minutes to generate a 10 minutes video, please sit back and relax.`, isOpen: true, timeout: 3000})
  const fetchData = () => {
    justcastApi.get(`/v1/audioposts/${id}/podieos`)
    .then((res) => {
      const data = res.data;
      setShowName(data.show.name)
      setShowId(data.show.id)
      setEpisodeName(data.audiopost.name)
      setDuration(data.audiopost.duration)
      setItems(data.podieos)

      Mixpanel.identify(uid);
      Mixpanel.track('Episode audiogram page loaded', {show_id: data.show.id, audiopost_id: data.audiopost.id});

      const remoteUrls = data.podieos.filter((p, i) => {
        return p.remote_url === null
      })

      if(remoteUrls.length > 0) {
        setListenToSocket(true);
      }

      if(data.show.allow_podieo_max_second && data.show.allow_podieo_max_second > 0) {
        setPodieoAllowTime(data.show.allow_podieo_max_second)
      }

      setEpisodePageMenu({
        title: "Audiogram",
        currentPageId: "episodes",
        currentTabId: "podieos",
        subtitle: `${data.show.podcast_title} > ${data.audiopost.episode_title}`,
        showName: data.show.podcast_title,
        showId: data.show.id,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow:data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website
      })

    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handleDeletePodieo = (podieoId) => {

    justcastApi.delete(`/v1/audioposts/${id}/podieos/${podieoId}`)
    .then((res) => {
      const _items = items.filter((item) => {
        return item.id !== res.data.id;
      })
      setItems(_items);
    })
    .catch((err) => {
      console.log(err)
    })
    console.log(id, podieoId);
  }

  const handleSocketCallback = (result) => {
    // console.log(result)
    const {message, progress, finish, remote_url} = result;
    const itemIds = items.map((item) => item.id);
    const k = itemIds.indexOf(result.id);
    if(k > -1) {
      const item = items[k];
      const newItem = {...item, message, progress, finish, remote_url}
      // setSocketReceived(socketReceived + 1);
      const _items = [...items];
      _items[k] = newItem;
      setItems(_items);
    }
  }

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    if(values && values['message']) {
      const message = values['message'];
      addWithTimeout({color: 'warning', message, isOpen: true, timeout: 5000})
    }
    fetchData()
    // socket.connect();
    // const socket = socketIOClient("localhost:7100");
    // socket.on("audiogram_data", data => console.log(data));

  },[id])

  useEffect(() => {
    // establish socket connection
    // setSocket(io(process.env.REACT_APP_MS_MEDIA_ANALYZER_PROXY));
    if(!socket) {
      add({socket: io(process.env.REACT_APP_MS_MEDIA_ANALYZER_PROXY)})
    }
  }, [])

  useEffect(() => {
    if (!socket || items.length === 0) return;
    const channelName = `audiogram_data_${id}`;
    if(channels.indexOf(channelName) === -1) {
      addChannel([...channels, channelName])
      // console.log(`listen to live_download_${id}`)
      socket.on(channelName, data => {
        handleSocketCallback(data)
      })
    }
  }, [socket, listenToSocket])

  return (
    <>
      <div className="container-fluid">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <div className="row align-items-center">
                  <Col>
                    <h4 className="card-header-title">Video Trailers</h4>
                  </Col>
                  <div className="col-auto">
                    <Link className="btn btn-sm btn-warning" to={`/episodes/${id}/podieos/create`}>New</Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <PodieoList
                  items={items}
                  name={episodeName}
                  handleDeleteClick={handleDeletePodieo}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default EpisodePodieos