import React, {useContext} from "react";
import { Label, Form, FormGroup, FormText, Input, Button, Row, Card, CardBody } from 'reactstrap';
import FormGroupInput from '../components/FormGroupInput';
import FormGroupInputSelect from '../components/FormGroupInputSelect';
import FormGroupInputSwitch from '../components/FormGroupInputSwitch';

const ShowSettingsForm = ({data, audioposts, googleAnalyticsError, aboutPageContentError, handleSubmit, handleInputValueChange}) => {
  const {
    skill_podcast_website, 
    is_private, 
    is_password_protected, 
    is_advanced_private_feed, 
    site_intro_text, 
    about_page_content, 
    google_analytics_id,
    facebook_pixel_base_code,
    recommend_audiopost_id, 
    recommend_episode,
    brand_link_back,
    hide_subscribe_page
  } = data;
  
  return(
    <Form className="mb-4" onSubmit={handleSubmit}>
      <FormGroupInput
        inputLabel="Site Intro Text"
        inputType='textarea'
        inputName="site_intro_text"
        inputPlaceholder="Website introduction text.  Displayed on the home page, below the logo and navigation."
        inputValue={site_intro_text ? site_intro_text : ""}
        inputClassName="form-control"
        handleInputChange={handleInputValueChange}
      />
      <FormGroupInput
        inputLabel="About Page Content"
        inputType='textarea'
        invalid={aboutPageContentError ? true : false}
        invalidMessage={aboutPageContentError}
        inputName="about_page_content"
        inputPlaceholder="About page content.  Can include plain HTML."
        inputValue={about_page_content ? about_page_content : ""}
        inputClassName="form-control"
        handleInputChange={handleInputValueChange}
      />      
      {
        data && skill_podcast_website && (!(is_private || is_password_protected || is_advanced_private_feed)) &&
        <>
          <hr/>
          <FormGroupInput
            inputLabel='Embed &#38; Sharing Analytics'
            inputType='text'
            inputName="google_analytics_id"
            mutedText={'Track page views of embeddable players and social sharing pages on Google Analytics.'}
            inputPlaceholder="UA-XXXXXX-1"
            inputValue={google_analytics_id ? google_analytics_id : ""}
            inputClassName="form-control"
            handleInputChange={handleInputValueChange}
            invalid={googleAnalyticsError ? true : false}
            invalidMessage={googleAnalyticsError}            
          />
          <FormGroupInput
            inputLabel='Facebook pixel'
            inputType='text'
            inputName="facebook_pixel_base_code"
            mutedText={'Track page views of embeddable players and social sharing pages on Facebook pixel.'}
            inputPlaceholder="12345678901234"
            inputValue={facebook_pixel_base_code ? facebook_pixel_base_code : ""}
            inputClassName="form-control"
            handleInputChange={handleInputValueChange}       
          />
          <hr/>          
          <FormGroupInputSelect
            inputType="select" 
            inputLabel="Recommened / Featured Episode"
            inputName='recommend_audiopost_id'
            optionLabel="name"
            optionId="id"
            mutedText="Episode will display prominently on your website homepage as a good episode to start with."
            options={audioposts}
            handleInputChange={(event) => {
              handleInputValueChange({key: event.target.name, value: event.target.value === "0" ? null : event.target.value})
            }}
            inputValue={recommend_audiopost_id}
          />
          <hr/>
          <div className="row">
            <div className="col-12 col-md-6">
              <FormGroupInputSwitch
                inputType='switch'
                inputName='brand_link_back'
                inputLabel="Remove JustCast logo"
                mutedText="Hide our logo at the bottom of your JustCast page."
                inputValue={brand_link_back}
                inputCSSId='brand_link_back-switch'
                warningMessage=""
                handleInputChange={(event) => {
                  handleInputValueChange({key: event.target.name, value: !brand_link_back})
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormGroupInputSwitch
                inputType='switch'
                inputName='hide_subscribe_page'
                inputLabel="Hide subscribe page"
                mutedText="Hide subscribe page on the website"
                inputValue={hide_subscribe_page}
                inputCSSId='hide_subscribe_page-switch'
                warningMessage=""
                handleInputChange={(event) => {
                  handleInputValueChange({key: event.target.name, value: !hide_subscribe_page})
                }}
              />
            </div>            
          </div>          
        </>        
      }  
    </Form>
  )
}

export default ShowSettingsForm;