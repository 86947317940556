import { atom } from "jotai";

export const filterAtom = atom("");

export const changeFilterAtom = atom(null, (_get, set, input) => {
  set(filterAtom, () => {
    return input.value;
  } );
})

export default {};