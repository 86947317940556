import React, {useEffect, useState, useContext} from "react";
import { Link } from "react-router-dom";
import justcastApi from '../api/justcast';
import EpisodeImageItem from '../components/EpisodeImageItem';
import {Context as MenuContext} from '../context/MenuContext'

const EpisodeImages = (props) => {
  const id = props.match.params.id;
  const [showId, setShowId] = useState('');
  const [showName, setShowName] = useState('');
  const [episodeName, setEpisodeName] = useState('');
  const [images, setImages] = useState([]);
  const {setEpisodePageMenu} = useContext(MenuContext);

  useEffect(() => {
    justcastApi.get(`/v1/audioposts/${id}/audiopost_images`)
    .then((res) => {
      const data = res.data;
      setShowName(data.show.name)
      setShowId(data.show.id)
      setEpisodeName(data.audiopost.name)
      setImages(data.images)

      setEpisodePageMenu({
        title: "Images",
        currentPageId: "episodes",
        currentTabId: "images",
        subtitle: `${data.show.podcast_title} > ${data.audiopost.episode_title}`,
        showName: data.show.podcast_title,
        showId: data.show.id,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow:data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website
      })

    })
    .catch((err) => {
      console.log(err)
    })
  },[id, showId])

  const handleDelete = (audiopost_image_id) => {
    // console.log(id)
    // console.log(audiopost_image_id)
    justcastApi.delete(`/v1/audioposts/${id}/audiopost_images/${audiopost_image_id}`)
    .then((res) => {
      const deleted_audiopost_image_id = res.data.id;
      const removedImages = images.filter((item) => {
        return item.id !== deleted_audiopost_image_id
      })
      setImages(removedImages);
    })
    .catch((err) => {
      console.log('err')
    })
  }


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card-columns">
            <div className="card bg-secondary text-white p-3 text-center">
              <div
                className="card-body mb-0">
                  <p>Upload Photo</p>
                  <Link to={`/episodes/${id}/images/create`} className="btn btn-rounded-circle btn-white">
                    <span className="fe fe-plus"/>
                  </Link>
              </div>
            </div>
            {
              images.map((image) =>
                <EpisodeImageItem
                  handeDelete={handleDelete}
                  id={image.id}
                  key={image.id.toString()}
                  image_url={image.image_url}
                  subtitle={image.subtitle}
                  description={Image.description}/>
              )
            }
          </div>
        </div>
      </div>

    </>
  )
}

export default EpisodeImages;