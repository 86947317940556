import React from 'react'

const Pagnation = ({
  previousPage, 
  canPreviousPage, 
  nextPage, 
  canNextPage, 
  pageIndex, 
  pageSize,
  pageOptions,
  gotoPage,
  setPageSize,
  pageSizeOptions
}) => {
  return (
    <>    
      <div className="input-group">
        <div className="input-group-prepend">
          <div className="input-group-text" id="btnGroupAddon">Page</div>            
        </div>
        <input type="text" className="form-control col-2" 
          value={pageIndex + 1 || 1} 
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page)
          }}
        />
        <div className="input-group-append">
          <div className="input-group-text" id="btnGroupAddon">of {pageOptions.length}</div>            
        </div>
      </div>
      <br/>
      <div className="btn-toolbar" role="toolbar">
        <div className="btn-group mr-2" role="group" >
          <button type="button" className="btn btn-secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>        
        </div>      
        <div className="btn-group mr-2" role="group" >
          <button type="button" className="btn btn-secondary" onClick={() => nextPage()} disabled={!canNextPage}>Next</button>            
        </div>
        <div className="btn-group">
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}>
              {pageSizeOptions.map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
          </select>        
        </div>             
      </div>  
    </>
  )
}

export default Pagnation;