import React, {useContext} from 'react';
import {Link} from 'react-router-dom'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AuthContext} from '../context/AuthContext'
import DropboxChooser from 'react-dropbox-chooser'

const SummaryCardButton = ({isDirectUpload, buttonLabel, buttonLink, buttonClassName, dropboxSuccessCallback}) => {
  const { createNewPodcast } = useContext(ModalContext);
  const { state } = useContext(AuthContext);
  const { dropbox_connection_upload_only } = state;

  if(isDirectUpload) {
    return (
      <div className="col-auto" style={{display: "flex", gap: "10px"}}>
        {dropbox_connection_upload_only && <DropboxChooser
          appKey={process.env?.REACT_APP_DROPBOX_CLIENT_ID}
          success={dropboxSuccessCallback}
          multiselect={true}
          extensions={['.mp3', '.mp4', '.m4a', '.m4b', '.m4v', '.mov', '.aac', '.mpeg']}
        >
          <div className="btn btn-primary btn-sm">Add from Dropbox</div>
        </DropboxChooser>}
        <Link to={buttonLink} className={`btn btn-sm btn-${buttonClassName}`}>{buttonLabel}</Link>
      </div>
    )
  }

  return null;
}

const SummaryCard = ({className, title, value, isDirectUpload, buttonLabel, buttonLink, buttonClassName, dropboxSuccessCallback}) => {
  return (
    <div className={className}>
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col">
              <h6 className="text-uppercase text-muted mb-2">{title}</h6>
              <span className="h2 mb-0">{value}</span>
            </div>
            <SummaryCardButton
              isDirectUpload={isDirectUpload}
              buttonLabel={buttonLabel}
              buttonLink={buttonLink}
              buttonClassName={buttonClassName}
              dropboxSuccessCallback={dropboxSuccessCallback}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SummaryCard;