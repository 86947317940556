import React from 'react'
import ChapterItem from './ChapterItem'

export default function ChaptersList({chapters, handleDestroyChapter}) {
  if(chapters) {
    return (
      <div className='audiopost-chapters-list-items'>
        {
          chapters.map((chapter, idx) => {
            return (
              <ChapterItem key={idx} 
                id={chapter.id} 
                title={chapter.title} 
                start_time={chapter.start_time}
                img={chapter.img}
                url={chapter.url}
                handleDestroyChapter={handleDestroyChapter}
              />
            )
          })
        }
      </div>
    )
  }

  return null;
}
