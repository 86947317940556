import React, {useEffect, useState, useContext} from "react";
import { Link } from "react-router-dom";
import { Button, Form, InputGroup, InputGroupAddon, Input} from 'reactstrap';
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as AuthContext} from '../context/AuthContext'
import justcastApi from '../api/justcast';
import { Spinner } from 'reactstrap';
import ShowPageHeader from '../components/PageHeader/ShowPageHeader';
import TextInputClipboard from '../components/TextInputClipboard'

const PodcastLinks = (props) => {
  const id = props.match.params.id;
  const screenContext = useContext(ScreenContext);
  const {addWithTimeout} = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const userState = authContext.state;

  const [showName, setShowName] = useState('')
  const [item, setItem] = useState({});
  const [links, setLinks] = useState([]);
  
  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.name;
      setShowName(name);
      setItem(res.data);
      const _links = [];
      const showDistribution = (res.data.is_private !== true && res.data.is_advanced_private_feed !== true && res.data.is_password_protected !== true) ? true : false; 

      if (res.data.is_advanced_private_feed !== true) {
        _links.push({
          name: "RSS feed", url: res.data.rss_feed, id: 'rss'
        })
      }

      if (res.data.skill_podcast_website && showDistribution) {        
        _links.push({
          name: "Social Media Landing Page", url: res.data.landing_page_url, id: 'landing_page'
        })   

        _links.push({
          name: "JustCast Website", url: res.data.player_page_link, id: 'website', viewLabel: 'Customize', viewLink: `/shows/${id}/customize`
        })     
      }
      setLinks(_links)
      screenContext.finishFetching();
    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
    })    
  }, [id])

  return (
    <>
      <ShowPageHeader 
        showId={id}
        isPrivate={item.is_private}
        isAdvancedPrivateFeed={item.is_advanced_private_feed}
        headerPreTitle={'Links'}        
        headerTitle={showName}
        currentPageName={'links'}
        showSlug={item.slug}
      />
      <div className="container-fluid">
        {
          screenContext.state.loading ? <Spinner color="primary" /> : links.map((link) => 
            <div className="row" key={link.id}>
              <div className="col-12">
                <div className="card card-body">
                  <div className="row">
                    <h2 className="col-12">{link.name}</h2>
                    <div className="col-12">
                      <p></p>
                    </div>                
                    <div className="col-12">
                      <TextInputClipboard id={link.id} inputValue={link.url} name={link.name} viewLabel={link.viewLabel} viewLink={link.viewLink}/>
                    </div>                
                  </div>              
                </div>
              </div>
            </div>          
          )
        }
        
      </div>
    </>
  )
}

export default PodcastLinks;