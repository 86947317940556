import React, { useContext, useRef, useEffect, useState} from "react";
import {Context as PodieoAudioContext} from '../../context/PodieoAudioContext'
import { Range, getTrackBackground } from 'react-range';
import { updateValues} from '../../libs/playerInputRangeHandler'
import TimeString from '../../libs/TimeString'
import { useCurrentWidth } from '../../hooks'

const timeStringHandler = new TimeString();

const AudioInputRange = () => {

  const COLORS = ['#233C8C', '#A4A6B1', '#A4A6B1', '#233C8C'];

  const {state, updateState} = useContext(PodieoAudioContext);
  let width = useCurrentWidth();
  

  // const [values, setValues] = useState([25, 50, 75])
  const STEP = state.step;
  const MIN = 0;
  const MAX = state.duration > 0 ? state.duration : state.maxClipDuration;
  const [controlBarWidth, setControlBarWidth] = useState(0);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [showScrollBar, setShowScrollBar] = useState(false);
  const [srolledIndex, setScrolledIndex] = useState(null);
  const scrollbarRef = useRef(null);

  const values = [state.clipFromSeconds, state.playedSeconds, state.clipEndSeconds];
  
  const handleChange = (vals) => {
    updateState({playing: false})
    // stop the player
    // setValues(vals)
    const clipFromSeconds = vals[0];
    const playedSeconds = vals[1];
    const clipEndSeconds = vals[2];
    const clipFromText = timeStringHandler.getTimeString(clipFromSeconds)
    const clipEndText = timeStringHandler.getTimeString(clipEndSeconds)
    updateState({clipFromSeconds, playedSeconds, clipEndSeconds, clipFromText, clipEndText})
  }

  const handleFinalChange = (changedIdx) => {
    const finalValues = updateValues(values, changedIdx, 20, MIN, MAX);

    const clipFromSeconds = finalValues[0];
    const playedSeconds = finalValues[1];
    const clipEndSeconds = finalValues[2];

    const clipFromText = timeStringHandler.getTimeString(clipFromSeconds)
    const clipEndText = timeStringHandler.getTimeString(clipEndSeconds)

    updateState({clipFromSeconds, playedSeconds, clipEndSeconds, clipFromText, clipEndText})    
  }

  useEffect(() => {
    const clientWidth = scrollbarRef.current.clientWidth;       
    setControlBarWidth(clientWidth)
    if(state.duration > clientWidth) {
      setScrollBarWidth(state.duration);        
      setShowScrollBar("scroll");
    } else {
      //console.log(scrollbarRef.current.clientWidth)
      setScrollBarWidth(clientWidth); 
      setShowScrollBar(null); 
    }

    // JOSH 2020-10-16
    // WE REMOVE THE AUTO UPDATE FOR THE MOMENT
    // BECAUSE IT COMES WITH SOME EFFECT WE DO NOT WANT
    // IT'S NOT PERFECT AT THE MOMENT
    // const listener = () => {
    //   const clientWidth = scrollbarRef.current.clientWidth;       
    //   setControlBarWidth(clientWidth)
    //   if(state.duration > clientWidth) {
    //     setScrollBarWidth(state.duration);        
    //     setShowScrollBar("scroll");
    //   } else {
    //     //console.log(scrollbarRef.current.clientWidth)
    //     setScrollBarWidth(clientWidth); 
    //     setShowScrollBar(null); 
    //   }
    // }
    // let timeoutId = null;
    // clearTimeout(timeoutId);
    // timeoutId = setTimeout(() => listener(), 150);
  }, [width, state.duration])

  useEffect(() => {
    // console.log(scrollbarRef.current.scrollLeft);
    // if playing, and seconds changed
    // we will like to move the scrollbar slowly
    const playedPixel = state.playedSeconds;
    const k = Math.floor(playedPixel / controlBarWidth);
    if(state.playing &&  k > srolledIndex) {
      setScrolledIndex(k);      
      scrollbarRef.current.scrollLeft += controlBarWidth - 10;
    }    
  }, [state.playedSeconds])

  useEffect(() => {
    // this one is to adjust, we change the played time during pause
    // and we will like to jump to the right spot once it plays again
    const playedPixel = state.playedSeconds;
    const scrollLeft = scrollbarRef.current.scrollLeft;
    if(state.playing && (playedPixel > scrollLeft + 10 || playedPixel < scrollLeft - 10) ) {      
      scrollbarRef.current.scrollLeft = playedPixel - 10;
    }
  }, [state.playing])

  return (
    <div style={{      
      width: "100%",
      //overflowX: state.duration * 2 >= scrollBarWidth ? "scroll" : null,
      overflowX: showScrollBar
    }}
      ref={scrollbarRef}
      // onScroll={() => {
      //   const r = scrollbarRef.current.clientWidth / (state.duration * 2);
      //   const scrollLeft = scrollbarRef.current.scrollLeft;
      //   const playedPixel = state.playedSeconds;
      //   console.log({scrollLeft, playedPixel: playedPixel * 2, r: playedPixel * 2 /scrollLeft, scrollbarWidth: scrollbarRef.current.clientWidth, duration: state.duration})
      // }}
      className="jc-media-scrollbar"
    >
      <div>
        <Range
          allowOverlap
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={handleChange}
          // onFinalChange={handleFinalChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '60px',
                display: 'flex',
                // width: `${Math.max( 2 * state.duration, scrollBarWidth)}px`,            
                width: `${scrollBarWidth}px`,
                backgroundColor: "#233C8C",
                opacity: 0.7
              }}            
            >
              <div
                ref={props.ref}
                style={{
                  height: '4px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values:  values,
                    colors: COLORS,
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: 'center'
                }}
              >
                {children}
              </div>            
            </div>
          )}
          renderThumb={({ props, isDragged, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '60px',
                width: index === 1 ? '4px' : '6px',
                // borderRadius: '4px',
                backgroundColor: isDragged ? '#C16B90' : (index === 1 ? '#BC809E' : '#1D1C1D'),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: index === 1 ? 1 : 0.7,
                // boxShadow: '0px 2px 6px #AAA'
              }}
              onMouseUp={() => {
                handleFinalChange(index)
              }}
            >
              <div
                style={{
                  height: '16px',
                  width: '5px',
                  backgroundColor: isDragged ? '#C16B90' : (index === 1 ? '#BC809E' : '#1D1C1D'),
                  // backgroundColor: "#1D1C1D"
                }}
              />
            </div>
          )}        
        >
        </Range>
      </div>
    </div>
  )
}

export default AudioInputRange