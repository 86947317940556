import { props } from "bluebird";
import React, {useState} from "react";
import { Button, Input, InputGroup, InputGroupAddon} from 'reactstrap';

const InputWithCopyButton = ({inputValue, id}) => {
  
  const [copied, setCopied] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy');

  const handleCopyClicked = () => {
    const copyText = document.querySelector(`#input-${id}`);
    copyText.select();
    document.execCommand("Copy");
    setCopyButtonLabel('Copied');
    setCopied(true);
    // change back to Copy in 3 seconds
    setTimeout(() => {
      setCopyButtonLabel("Copy");
      setCopied(false);
    }, 3000)    
  }

  return (
    <InputGroup>
      <Input valid={copied} readOnly value={inputValue} id={`input-${id}`} />
      <InputGroupAddon addonType="prepend">
        <Button color="primary" onClick={handleCopyClicked}>{copyButtonLabel}</Button>
      </InputGroupAddon>          
    </InputGroup>
  )
}

export default InputWithCopyButton