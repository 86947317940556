import React, {useState, useEffect} from "react";
import { Card, CardBody, CardFooter, Button, Row, 
  CustomInput, Input, Col, FormGroup, Label, InputGroupAddon, InputGroup,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import Select from 'react-select'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',    
  })
}

const featuredStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',    
  })
}

const TipjarPrice = (props) => {
  const {
    idx, 
    id, 
    unit_amount,
    unit_amount_text, 
    active,
    priceTypes,
    recurring_interval_name,
    recurring_interval, 
    product_currency_code_id, 
    product_currency_code_value,
    currencyCodes,
    stripe_payment_url,
    handlePriceChanged,
    handleRemove
  } = props;

  const [dropdownOpen, setDropDownOpen] = useState(false);

  const [copyState, setCopyState] = useState(false);

  const handleChange = (e) => {
    if(e.target.name === "unit_amount") {
      // if
      // handlePriceChanged(idx, {unit_amount: e.target.value})
      const number = Number(e.target.value);
      if(number) {
        handlePriceChanged(idx, {unit_amount_text: e.target.value, unit_amount: e.target.value * 100})
      } else if (e.target.value.length === 0) {
        handlePriceChanged(idx, {unit_amount_text: "", unit_amount: 0})
      }
      // const number = Number(e.target.value);
      // if(number) {
      //   handlePriceChanged(idx, {unit_amount: number})
      // } else if(e.target.value.length === 0) {
      //   handlePriceChanged(idx, {unit_amount: ""})
      // }
    }
  }

  const handleClicked = (active) => {
    handlePriceChanged(idx, {active: active})
  }

  const handlDropdown = () => {
    setDropDownOpen(!dropdownOpen)
  }

  const handleSelectChange = (item) => {
    handlePriceChanged(idx, item)
  }

  const hanldeCopyClick = () => {
    setCopyState(true);
    setTimeout(() => {
      setCopyState(false);
    }, 2000)
  }

  return (
    <Card>
      <CardBody>
        <h3>$ {unit_amount ? (Math.round(unit_amount) / 100).toFixed(2) : "0.00"} - {recurring_interval_name}</h3>
        {
          stripe_payment_url && <Row className="align-items-center justify-content-between" style={{marginBottom: "10px"}}>
            <div className="col">
              <InputGroup>
                <Input valid={copyState} placeholder="stripe_payment_url" readOnly value={stripe_payment_url}/>
                <InputGroupAddon addonType="append">
                  <CopyToClipboard text={stripe_payment_url}
                    onCopy={hanldeCopyClick}
                  >
                    <Button color="warning">{copyState ? "Copied" : "Copy"}</Button>
                  </CopyToClipboard>                  
                </InputGroupAddon>
              </InputGroup>
            </div>
          </Row>
        }

        {/* <h4 className="mb-1">Unit amount</h4> */}
        {/* <p className="small text-warning mb-1">The unit amount in cents to be charged, represented as a whole integer if possible.</p> */}
        <Row className="align-items-center justify-content-between">
          <div className="col-3">
            {
              id ? <Input disabled
                value={product_currency_code_value}
                onChange={handleSelectChange}
              />              
              : <Select              
                // name="currency"
                value={{label: product_currency_code_value, id: product_currency_code_id, value: product_currency_code_value}}
                options={currencyCodes.map((item) => {
                  return { label: item.code, id: item.id, value: item.id }
                })}               
                onChange={
                  (e) => {                    
                    handleSelectChange({product_currency_code_value: e.label, product_currency_code_id: e.id})
                  }
                }
                styles={customStyles}
              />
            }                        
          </div>
          <div className="col ml-n1">
            <Input 
              // type="price" 
              name="unit_amount"
              id={`unit_amount-${idx}`}
              placeholder="e.g. 5.00" 
              // value={unit_amount}
              value={unit_amount_text}
              disabled={id ? true : false}
              onChange={handleChange}
            />
          </div>
          <div className="col-3">
            {
              id ? <Input disabled
                value={recurring_interval_name ? recurring_interval_name : ""}
                onChange={handleSelectChange}
              /> : 
              <Select              
                // name="currency"
                value={{label: recurring_interval_name, id: recurring_interval, value: recurring_interval}}
                options={priceTypes.map((item) => {
                  return { label: item.name, id: item.recurring_interval, value: item.recurring_interval }
                })}               
                onChange={(e) => {
                  handleSelectChange({recurring_interval_name: e.label, recurring_interval: e.id})
                }}
                styles={customStyles}
              />
            }            
          </div>
          <div className="col-auto">
            <Dropdown isOpen={dropdownOpen} toggle={handlDropdown}>
              <DropdownToggle caret color={active ? "primary" : "secondary"}>
                {active ? "Enabled" : "Disable"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => {
                  handleClicked(true)
                }}>Enable</DropdownItem>
                <DropdownItem onClick={() => {
                  handleClicked(false)
                }}>Disable</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          {
            !id ? <div className="col-auto">
              <div className="btn btn-danger" onClick={() => {
                handleRemove(idx)
              }}><i className="fe fe-trash-2"/></div>
            </div> : null
          }          
        </Row>
      </CardBody>
    </Card>
  )
}

export default TipjarPrice;