import React, {useEffect, useState, useContext} from "react";
import { Redirect } from "react-router-dom";
import queryString from 'query-string'
import { Spinner } from 'reactstrap';
import justcastApi from '../api/justcast'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as AuthContext} from '../context/AuthContext'

const StripeAccountConnectCallback = (props)  => {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const {addWithTimeout, add} = useContext(AlertContext);
  const {createStripeConnectAccount} = useContext(AuthContext);

  let redirectUrl = "/connections";
  const tip_jar_id = props.match.params.tip_jar_id;
  if(tip_jar_id) {
    redirectUrl = `/shows/${tip_jar_id}/tip_jar`
  }

  useEffect(() => {
    justcastApi.get(`/v1/stripe_accounts_retrieve`)
    .then((res) => {
      addWithTimeout({color: 'warning', message: res.data.message, isOpen: true, timeout: 3000})
      createStripeConnectAccount({details_submitted: res.data.details_submitted})
      setRedirect(true);
    })
    .catch((err) => {
      addWithTimeout({color: 'info', message: `Failed to connect to Stripe.  Please try again.`, isOpen: true, timeout: 3000})
      setRedirect(true);
    })
  }, [])

  if(redirect) {
    return <Redirect to={redirectUrl}/>
  }
  if(loading) {
    return <Spinner color="primary" />;
  }

  return (<>Hello world</>)   
}

export default StripeAccountConnectCallback;