import React from 'react'
import {useDropzone} from 'react-dropzone'

const AudioFilePicker = ({setSrc, setFile, setModal}) => {

  const accept = ['audio/mp3', 'audio/mpeg', 'audio/aac', 'audio/wav', 'video/mp4', 'audio/x-m4a', 'audio/x-m4b', 'video/quicktime', 'video/x-m4v']
  const mp3 = ['audio/mp3', 'audio/mpeg'];

  const {acceptedFiles, rejectedFiles, getRootProps, getInputProps} = useDropzone({
    accept,
    onDrop: (acceptedFiles, rejectedFiles) => {
      const errors = [];

      if(rejectedFiles.length > 0) {

        errors.push(`Only ${accept.join(',')} will be accepted`)
        rejectedFiles.forEach((file) => {
          errors.push(`Rejected file: ${file.path}`)
        })

      }

      if(acceptedFiles.length > 0) {

        if(errors.length === 0) {
          const src = URL.createObjectURL(acceptedFiles[0]);
          const file = acceptedFiles[0];
          // const name = file['name'];
          // const size = `${ Math.floor(file['size'] * 100 / (1024 * 1024)) / 100} MB`;
          const type = file['type'];
          if(mp3.indexOf(type) === -1) {
            // callback type
            setModal(true);
          }
          setSrc(src)
          setFile(file)
        }
        // imageOnDrop(acceptedFiles, setImage, setFiles, setErrors)
      }
      if(errors.length > 0) {
        // handleMessages(errors);
        setSrc(null)
        setFile(null)
      }
    }
  });

  return (
    <div {...getRootProps({className: 'dropzone'})}>
      <div className="dz-default dz-message">
        <input {...getInputProps()}/>
        <p>Drag and drop an audio file or <span style={{
          textDecoration: "underline",
          fontWeight: 600
        }}>Browse</span></p>
      </div>
    </div>
  )
}

export default AudioFilePicker;