import React, {useState, useEffect} from "react";
import {
  Modal, ModalBody, ModalFooter, ModalHeader, Table,
  Button, Input, FormGroup
} from 'reactstrap';
import CSVReader from 'react-csv-reader'
import CSVEmailAddressParser from '../libs/CSVEmailAddressParser';

const TablePrview = ({emails}) => {
  if(emails && emails.length > 0) {
    return (
      <>
        <hr/>
        <Table size="sm">
          <thead>
            <tr>
              <th>Email Address</th>
              <th>Full name</th>
            </tr>
          </thead>
          <tbody>
            {
              emails.map((user, id) => {
                return (
                  <tr key={id.toString()}>
                    <td>{user.email}</td>
                    <td>{user.full_name}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </>
    )
  }

  return null;
}

const FilePreviewModal = ({modalOn, toggleModal, saveEmails}) => {
  const [emails, setEmails] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [emailColumnIdx, setEmailColumnIdx] = useState(1);
  const [fullNameColumnIdx, setFullNameColumnIdx] = useState(null);

  const save = () => {
    toggleModal(!modalOn)
    setEmails([])
    saveEmails(emails)
  }

  const cancel = () => {
    setEmails([])
    toggleModal(!modalOn)
  }

  const handleInputChange = (event) => {
    const value = event.target.value;
    setEmailColumnIdx(value);
  }

  const handleFullNameChange = (event) => {
    const value = event.target.value;
    setFullNameColumnIdx(value);
  }

  useEffect(() => {
    if(csvData && emailColumnIdx && Number(emailColumnIdx) > 0) {
      const idx = Number(emailColumnIdx) - 1
      const kdx = fullNameColumnIdx ? Number(fullNameColumnIdx) - 1 : fullNameColumnIdx;
      const newEmailParser = new CSVEmailAddressParser(csvData, idx, kdx);
      const newUsers = newEmailParser.getUsers()
      setEmails(newUsers)
    }
  }, [csvData, emailColumnIdx, fullNameColumnIdx])

  return (
    <Modal isOpen={modalOn} fade={false} toggle={cancel}>
      <ModalHeader toggle={cancel}>Add many subscribers via CSV</ModalHeader>
      <ModalBody>
        <p>Upload a CSV (cooma-separate file) containing email addresses and we'll pull out all emails that are not yet subscribers to review in the next step.</p>
        <p>If you have an Excel or Google spreadsheet, you can export those as a CSV and upload here.</p>
        <div style={{display: 'flex', gap: "10px", flexDirection: "column"}}>
          <CSVReader onFileLoaded={(data, fileInfo) => {
            setCsvData(data);
          }} />
          <div>
            <FormGroup>
              <label>Column Number (Email address)</label>
              <Input
                bsSize="sm"
                className="form-control"
                type='number'
                name="email-column-index"
                value={emailColumnIdx}
                placeholder={"2"}
                onChange={handleInputChange}
              />
            </FormGroup>
          </div>
          <div>
            <FormGroup>
              <label>Column Number (Full name)</label>
              <Input
                bsSize="sm"
                className="form-control"
                type='number'
                name="full-name-column-index"
                value={fullNameColumnIdx || "" }
                placeholder={"e.g. 3"}
                onChange={handleFullNameChange}
              />
            </FormGroup>
          </div>
          {emails.length > 0 && <div>Total Emails: {emails.length}</div>}
        </div>
        <TablePrview emails={emails}/>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={save} disabled={!(emails && emails.length > 0)}>Upload Emails from CSV</Button>
        <Button color="danger" onClick={cancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default FilePreviewModal