import React, {useEffect, useState, useContext} from "react";
import moment from 'moment'
import {Card, CardBody, CardHeader, Row} from 'reactstrap';
import {Redirect, Link} from 'react-router-dom'
import justcastApi from '../api/justcast'
import axios from 'axios';
import {dateRangeType} from '../libs/datediff'
import {joinCountryData} from '../libs/joinCountryData'
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AuthContext} from '../context/AuthContext'
import { DecodeURIFormatCell, PercentageFormatCell} from './../libs/TableCellFormat'
import {SummaryStateCard, ColumnChartCard, CardTable, CardGlobalMap, CardPodcastPlayers} from '../components/Metrics'
import {Mixpanel} from '../api/mixpanel'
import ScreenBlocker from '../components/ScreenBlocker'

// This is the subscriber report for each podcast

const ShowAnalyticsReport = (props) => {
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext);
  // Mixpanel
  const authContext = useContext(AuthContext);
  const uid = authContext.state.uid;

  const id = props.match.params.id;
  const [screenBlocker, setScreenBlocker] = useState(false);

  const [denied, setDenied] = useState(false);
  const [averageDownload, setAverageDownload] = useState({loading: true, title:"Average Downloads per Episode"});
  const [estimatdSubscribers, setEstimatdSubscribers] = useState({loading: true, title:"Estimated Subscribers"});
  const [dateOptions, setDateOptions] = useState([]);
  const [episodesStat, setEpisodesStat] = useState({title: 'Episode Breakdown', stats: [],loading: true})

  const [listenerTrend, setListenerTrend] = useState({title: 'Listener Trends'});
  const [listenerTrendDate, setListenerTrendDate] = useState({label: 'All Time', fromDate: null, toDate: null, value: 'all_time', display: false, loading: true});
  const [listenerOptionSelected, setListenerOptionSelected] = useState(null);

  const [countryDownloads, setCountryDownloads] = useState({title: 'Global Listener Locations', loading: true});
  const [countryDownloadsDate, setCountryDownloadsDate] = useState({label: 'All Time', fromDate: null, toDate: null, value: 'all_time', display: false, loading: true});
  const [countryDownloadsOptionSelected, setCountryDownloadsOptionSelected] = useState(null);

  const [userAgentDownloads, setUserAgentDownloads] = useState({title: 'Podcast Players', loading: true});
  const [userAgentDownloadsDate, setUserAgentDownloadsDate] = useState({label: 'All Time', fromDate: null, toDate: null, value: 'all_time', display: false, loading: true});
  const [userAgentDownloadsOptionSelected, setUserAgentDownloadsOptionSelected] = useState(null);
  const [regionDownloads, setRegionDownloads] = useState([]);

  const handleListenrOptionSelected = (x) => {
    const {label, value, from_date, to_date} = x;
    if(x.id === 'custom_date_range') {
      setListenerTrendDate({...listenerTrendDate, label, value, display: true})
    } else {
      setListenerTrend({...listenerTrend})
      setListenerTrendDate({
        label,
        value,
        fromDate: from_date,
        toDate: to_date,
        display: false,
        loading: true
      })
    }
    setListenerOptionSelected(x)
    // console.log(x);
  }

  const handleDateChange = (id, val) => {
    const datePicked = moment(val[0]).format('YYYY-MM-DD');
    setListenerTrendDate({...listenerTrendDate, [id]: datePicked, loading: true});
  }

  // map
  const handleCountryOptionSelected = (x) => {

    const {label, value, from_date, to_date} = x;
    if(x.id === 'custom_date_range') {
      setCountryDownloadsDate({...countryDownloadsDate, label, value, display: true})
    } else {
      setCountryDownloads({...countryDownloads})
      setCountryDownloadsDate({
        label,
        value,
        fromDate: from_date,
        toDate: to_date,
        display: false,
        loading: true
      })
    }
    setCountryDownloadsOptionSelected(x)
    // console.log(x);
  }

  const handleMapDateChange = (id, val) => {
    const datePicked = moment(val[0]).format('YYYY-MM-DD');
    setCountryDownloadsDate({...countryDownloadsDate, [id]: datePicked, loading: true});
  }

  // user agents
  const handleUserAgentOptionSelected = (x) => {

    const {label, value, from_date, to_date} = x;
    if(x.id === 'custom_date_range') {
      setUserAgentDownloadsDate({...userAgentDownloadsDate, label, value, display: true})
    } else {
      setUserAgentDownloads({...userAgentDownloads})
      setUserAgentDownloadsDate({
        label,
        value,
        fromDate: from_date,
        toDate: to_date,
        display: false,
        loading: true
      })
    }
    setUserAgentDownloadsOptionSelected(x)
    // console.log(x);
  }

  const handleUserAgentDateChange = (id, val) => {
    const datePicked = moment(val[0]).format('YYYY-MM-DD');
    setUserAgentDownloadsDate({...userAgentDownloadsDate, [id]: datePicked, loading: true});
  }

  useEffect(() => {

    justcastApi.get(`/v1/shows/${id}`)
    .then((res) => {
      const name = res.data.podcast_title;
      setScreenBlocker(res.data.skill_standard_analytics);

      menuContext.setMetricsMenus({
        title: 'Metrics',
        subtitle: name,
        currentPageId: 'metrics',
        currentTabId: 'metrics',
        showId: id,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show,
        isLiveview: res.data.live_view,
        skill_podcast_website: res.data.skill_podcast_website
      });

      Mixpanel.identify(uid);
      Mixpanel.track('Show Analytics loaded', {show_id: id});
    })
    .catch((err) => {
      // console.log(err)
      setDenied(true)
    })
  }, [id])

  useEffect(() => {
    if(screenBlocker) {
      justcastApi.get(`/v1/shows/${id}/metrics_average_downloads_per_episode`)
      .then((res) => {
        setAverageDownload({...res.data, loading: false});
      })
      .catch((err) => {
        setAverageDownload({title: 'Average Downloads per Episode', subtitle: 'Not available', loading: false, stats: []})
        console.log(err);
      })

      justcastApi.get(`/v1/shows/${id}/metrics_estimated_subscribers`)
      .then((res) => {
        setEstimatdSubscribers({...res.data, loading: false});
      })
      .catch((err) => {
        setEstimatdSubscribers({title: 'Estimated Subscribers', subtitle: 'Not available', loading: false, stats: []})
        console.log(err);
      })

      justcastApi.get(`/v1/shows/${id}/metrics_options`)
      .then((res) => {
        const fromDate = res.data[3]['from_date'];
        const toDate = res.data[3]['to_date'];
        const label = res.data[3]['label'];
        const value = res.data[3]['value'];
        const options = res.data.map((item) => {
          return {...item, key: item['id']}
        })

        setDateOptions(options);

        setListenerOptionSelected(res.data[3])
        setListenerTrendDate({label, fromDate, toDate, value});

        setCountryDownloadsOptionSelected(res.data[3])
        setCountryDownloadsDate({label, fromDate, toDate, value});

        setUserAgentDownloadsOptionSelected(res.data[3])
        setUserAgentDownloadsDate({label, fromDate, toDate, value});
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }, [id, screenBlocker])

  // Listener Trends
  useEffect(() => {
    const fromDate = listenerTrendDate.fromDate;
    const toDate = listenerTrendDate.toDate;
    const dataType = dateRangeType(fromDate, toDate)
    if(listenerTrendDate.fromDate && listenerTrendDate.toDate) {
      const path = `/v1/shows/${id}/metrics_listener_trends?from_date=${fromDate}&to_date=${toDate}&type=${dataType}`
      justcastApi.get(path)
      .then((res) => {
        let totalDownloads = 0;
        const stats = res.data.stats;
        stats.forEach((x) => totalDownloads+= x.downloads ? x.downloads : 0);
        setListenerTrend({...res.data, totalDownloads});
        setListenerTrendDate({...listenerTrendDate, loading: false})
      })
      .catch((err) => {
        setListenerTrend({title: 'Listener Trends', subtitle: 'Not available', stats: []})
        setListenerTrendDate({...listenerTrendDate, loading: false})
        // console.log(err);
      })
    }
  }, [id, listenerTrendDate.fromDate, listenerTrendDate.toDate])

  // Episodes stats
  useEffect(() => {
    justcastApi.get(`/v1/shows/${id}/metrics_episode_breakdown`)
    .then((res) => {
      setEpisodesStat({...res.data, loading: false})
    })
    .catch((err) => {
      setEpisodesStat({title: "Episode Breakdown", subtitle: 'Not available', stats: [], loading: false})
    })
  }, [id])

  // Country Stats
  useEffect(() => {
    const fromDate = countryDownloadsDate.fromDate;
    const toDate = countryDownloadsDate.toDate;
    if(fromDate && toDate) {
      let geoData = {};
      axios.get("https://justcast.sfo2.digitaloceanspaces.com/DashboardAssets/data/worldgeodata.json")
      .then((res) => {
        geoData = res.data;
        return justcastApi.get(`/v1/shows/${id}/metrics_country_breakdown?from_date=${fromDate}&to_date=${toDate}`)
      })
      .then((res) => {
        const countryData = res.data;
        const countryStats = countryData.stats;
        const {title, subtitle} = countryData;
        const stats = joinCountryData(geoData, countryStats);

        setCountryDownloads({title, subtitle, stats, loading: false})
      })
      .catch((err) => {
        console.log(err);
      })

      justcastApi.get(`/v1/shows/${id}/metrics_region_breakdown?from_date=${fromDate}&to_date=${toDate}`)
      .then((res) => {
        setRegionDownloads(res.data.stats)
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }, [id, countryDownloadsDate.fromDate, countryDownloadsDate.toDate]);

  // User agents
  useEffect(() => {
    const fromDate = userAgentDownloadsDate.fromDate;
    const toDate = userAgentDownloadsDate.toDate;
    if(fromDate && toDate) {
      justcastApi.get(`/v1/shows/${id}/metrics_user_agent_breakdown?from_date=${fromDate}&to_date=${toDate}`)
      .then((res) => {
        setUserAgentDownloads({...res.data, loading: false})
      })
      .catch((err) => {
        setUserAgentDownloads({title: "Episode Breakdown", subtitle: 'Not available', stats: [], loading: false})
      })
    }

  }, [id, userAgentDownloadsDate.fromDate, userAgentDownloadsDate.toDate])

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  if(screenBlocker === false) {
    return (
      <ScreenBlocker message="Please upgrade your account to access the data."/>
    )
  }

  return (
    <>
      <div className="container-fluid">
        <div className="card-deck">
          <SummaryStateCard
            id={"average_downloads_per_episodes_card"}
            title={averageDownload.title}
            subtitle={averageDownload.subtitle}
            message={averageDownload.message}
            stats={averageDownload.stats}
            loading={averageDownload.loading}
          />
          <SummaryStateCard
            id={"estimated_subscribers"}
            title={estimatdSubscribers.title}
            subtitle={estimatdSubscribers.subtitle}
            message={estimatdSubscribers.message}
            stats={estimatdSubscribers.stats}
            loading={estimatdSubscribers.loading}
          />
        </div>
        <br/>
        <ColumnChartCard
          loading={listenerTrendDate.loading}
          chartId={'listener_trends'}
          title={listenerTrend.title}
          subtitle={listenerTrend.subtitle}
          totalDownloads={listenerTrend.totalDownloads}
          data={listenerTrend.stats}
          optionsData={dateOptions}
          optionSelected={listenerOptionSelected}
          handleOptionChange={handleListenrOptionSelected}
          fromDate={listenerTrendDate.fromDate}
          toDate={listenerTrendDate.toDate}
          handleDateChange={handleDateChange}
          datePickerDisplay={listenerTrendDate.display}
        />
        <CardTable
          title={episodesStat.title}
          subtitle={episodesStat.subtitle}
          loading={episodesStat.loading}
          stats={episodesStat.stats}
        />
        <CardPodcastPlayers
          title={userAgentDownloads.title}
          columns={[{Header: "Name", accessor: "name", Cell: DecodeURIFormatCell}, {Header: "#", accessor: "downloads"}]}
          subtitle={userAgentDownloads.subtitle}
          loading={userAgentDownloads.loading}
          stats={userAgentDownloads.stats}
          optionsData={dateOptions}
          optionSelected={userAgentDownloadsOptionSelected}
          handleOptionChange={handleUserAgentOptionSelected}
          fromDate={userAgentDownloadsDate.fromDate}
          toDate={userAgentDownloadsDate.toDate}
          handleDateChange={handleUserAgentDateChange}
          datePickerDisplay={userAgentDownloadsDate.display}
        />
        <CardGlobalMap
          chartId={'world_map'}
          title={countryDownloads.title}
          subtitle={countryDownloads.subtitle}
          loading={countryDownloads.loading}
          data={countryDownloads.stats}
          optionsData={dateOptions}
          optionSelected={countryDownloadsOptionSelected}
          handleOptionChange={handleCountryOptionSelected}
          fromDate={countryDownloadsDate.fromDate}
          toDate={countryDownloadsDate.toDate}
          handleDateChange={handleMapDateChange}
          datePickerDisplay={countryDownloadsDate.display}
        >
          {
            regionDownloads && regionDownloads.map((regionDownload, idx) => {
              return (
                <div className="list-group-item" key={`region-download-${idx}`}>
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Row>
                      <div className="col-auto">
                        <img src={regionDownload.flag} alt={`${regionDownload.country} flag`} width="24px"/>
                      </div>
                      <div className="col ml-n2">
                        <h4 className="text-body text-focus mb-1">{regionDownload.region}</h4>
                        <p className="small text-muted mb-0">{regionDownload.area}</p>
                      </div>
                    </Row>
                    <div>
                      <h4 className="text-body text-focus mb-1">{regionDownload.downloads}</h4>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </CardGlobalMap>
        <br/>
      </div>

    </>
  )
}

export default ShowAnalyticsReport