import React from 'react';
import { Card, Row, Col, CardBody, Form, FormGroup, Input, Button} from 'reactstrap';
import Select from 'react-select'
import ImageUploader from '../ImageUploader'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',    
  })
}

export default function PersonForm(props) {
  const {personTaxonomies, handleTaxonomyChange, roles,
    name, description, img, href, role, group, code, email, matrix, mastodon,
    facebook_url, twitter_url, linkedin_url, tiktok_url, instagram_url, youtube_url, patreon,
  setArtwork, handleChange, handleSave} = props;

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label>Name (required)</label>
              <Input
                type={"text"}
                name={"name"}
                id={"name"}
                placeholder="Joe Smith"
                value={name ? name : ""}
                onChange={handleChange}
              />              
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label>Email</label>
              <Input
                  type={"text"}
                  name={"email"}
                  id={"email"}
                  value={email ? email : ""}
                  onChange={handleChange}                  
                  placeholder="joe.smith@gmail.com"
                />              
            </FormGroup>
          </Col>          
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <ImageUploader setArtwork={setArtwork} artworkWidth={20} artworkHeight={20} isSquare={false}/>
            </FormGroup>
          </Col>
        </Row>          
        <Row>
          <Col md={6}>
            <FormGroup>
              <label>Homepage</label>
              <Input
                  type={"text"}
                  name={"href"}
                  id={"href"}
                  value={href ? href : ""}
                  onChange={handleChange}                    
                  placeholder="https://linktr.ee/jsmith"
                />              
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label>Roles (require)</label>
              <Select 
                value={roles}
                isMulti
                options={personTaxonomies}
                styles={customStyles}
                onChange={handleTaxonomyChange}
              />
              {/* <Input
                type={"text"}
                name={"href"}
                id={"href"}
                placeholder="https://linktr.ee/jsmith"
              />               */}
            </FormGroup>
          </Col>          
        </Row>      
        <Row>
          <Col md={12}>
            <FormGroup>
              <label>Description</label>
              <Input 
                type="textarea" 
                name="description" 
                id="description" 
                placeholder="Description" 
                value={description ? description : ""}
                onChange={handleChange}                
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label>Facebook</label>
              <Input 
                type="text" 
                name="facebook_url" 
                id="facebook_url" 
                placeholder="https://www.facebook.com/herbafitemily/" 
                value={facebook_url ? facebook_url : ""}
                onChange={handleChange}                
              />
            </FormGroup>            
          </Col>
          <Col md={6}>
            <FormGroup>
              <label>Twitter</label>
              <Input 
                type="text" 
                name="twitter_url" 
                id="twitter_url" 
                placeholder="https://twitter.com/cogentgene" 
                value={twitter_url ? twitter_url : ""}
                onChange={handleChange}                
              />
            </FormGroup>            
          </Col> 
          <Col md={6}>
            <FormGroup>
              <label>LinkedIn</label>
              <Input 
                type="text" 
                name="linkedin_url" 
                id="linkedin_url" 
                placeholder="https://www.linkedin.com/in/leejamesws/" 
                value={linkedin_url ? linkedin_url : ""}
                onChange={handleChange}                
              />
            </FormGroup>            
          </Col> 
          <Col md={6}>
            <FormGroup>
              <label>Tiktok</label>
              <Input 
                type="text" 
                name="tiktok_url" 
                id="tiktok_url" 
                placeholder="https://tiktok.com/@thebrookej" 
                value={tiktok_url ? tiktok_url : ""}
                onChange={handleChange}                
              />
            </FormGroup>            
          </Col>  
          <Col md={6}>
            <FormGroup>
              <label>Instagram</label>
              <Input 
                type="text" 
                name="instagram_url" 
                id="instagram_url" 
                placeholder="https://www.instagram.com/aluminumtube/" 
                value={instagram_url ? instagram_url : ""}
                onChange={handleChange}                
              />
            </FormGroup>            
          </Col>       
          <Col md={6}>
            <FormGroup>
              <label>Youtube</label>
              <Input 
                type="text" 
                name="youtube_url" 
                id="youtube_url" 
                placeholder="https://www.youtube.com/c/EngineeringwithUtsav" 
                value={youtube_url ? youtube_url : ""}
                onChange={handleChange}                
              />
            </FormGroup>            
          </Col>
          <Col md={6}>
            <FormGroup>
              <label>Mastodon</label>
              <Input 
                type="text" 
                name="mastodon" 
                id="mastodon" 
                placeholder="https://podcastindex.social/web/@montekaka" 
                value={mastodon ? mastodon : ""}
                onChange={handleChange}                
              />
            </FormGroup>            
          </Col>
          <Col md={6}>
            <FormGroup>
              <label>Matrix</label>
              <Input 
                type="text" 
                name="matrix" 
                id="matrix" 
                placeholder="@bob:bob.com" 
                value={matrix ? matrix : ""}
                onChange={handleChange}                
              />
            </FormGroup>            
          </Col>          
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label>Patreon</label>
              <Input 
                type="text" 
                name="patreon" 
                id="patreon" 
                placeholder="https://www.patreon.com/m/shestheskipper" 
                value={patreon ? patreon : ""}
                onChange={handleChange}                
              />
            </FormGroup>            
          </Col>           
        </Row>
        <Row>
          <Col md={12}>
            <hr/>            
            <Button color="primary" onClick={handleSave}>Save</Button>
            {props.children}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
