import React, {useEffect, useState, useContext} from "react";
import {Redirect} from 'react-router-dom'
import justcastApi from '../api/justcast';
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AuthContext} from '../context/AuthContext'
import { Spinner } from 'reactstrap';
import EpisodeListSortable from '../components/EpisodeListSortable'

const EpisodesRearrange = (props) => {
  const screenContext = useContext(ScreenContext);
  const {setSortingMenu } = useContext(MenuContext);
  const {state} = useContext(AuthContext)
  const {add} = useContext(ModalContext);

  const id = props.match.params.id;
  const [episodes, setEpisodes] = useState([]); // episodes
  const [denied, setDenied] = useState(false);
  const [pubDateByIncrement, setPubDateByIncrement] = useState(true);

  const fetchEpisodes = () => {
    screenContext.startFetching();
    let endpointUrl = `/v1/shows/${id}/audioposts_sorted`;

    justcastApi.get(endpointUrl)
    .then((res) => {
      setEpisodes(res.data.audioposts);
      screenContext.finishFetching();

      setSortingMenu({
        title: 'Episodes Number',
        subtitle: res.data.show.podcast_title,
        currentPageId: 'episode_sorting',
        currentTabId: 'episode_number',
        showId: id,
        landingPageUrl: res.data.show.landing_page_url,
        websiteUrl: res.data.show.player_page_link,
        rssFeed: res.data.show.rss_feed,
        passwordProtected: res.data.show.is_password_protected,
        advancedPrivate: res.data.show.is_advanced_private_feed,
        isPrivate: res.data.show.is_private,
        isPrivateShow: res.data.show.is_private_show,
        isDirectUpload: res.data.show.is_direct_upload,
        skill_podcast_website: res.data.show.skill_podcast_website,
        allow_dropbox_subfolders: state.allow_dropbox_subfolders
      });
    })
    .catch((err) => {
      screenContext.finishFetching();
      // add notifcation
      if(err.response.status === 401) {
        setDenied(true)
      }
    })
  }

  const handleSorting = (result) => {
    const items = Array.from(episodes);
    const k = result.source.index;
    const p = result.destination.index
    items[k]['dnd_touch'] = true;
    items[p]['dnd_touch'] = true;

    const [reorderedItem] = items.splice(k, 1);
    items.splice(p, 0, reorderedItem);
    const newEpisodes = items.map((ep, position_order_idx) => {
      return {...ep, episode_number: position_order_idx + 1}
    })
    setEpisodes(newEpisodes);
  }

  const handleSaveChange = () => {
    // We did not do this because most of our podcast do not have episodes number at the moment.
    // this will give us issue.  e.g. a podcast has 3 items, all 3 have episode_number: nil,
    // if we only update the dnd_touch one, that will be end with 1,2,nil

    // const updatedEpisodes = episodes.filter((x) => x['dnd_touch'] === true);
    let endpointUrl = `/v1/shows/${id}/audioposts_sorted`;
    justcastApi.post(endpointUrl, {
      updated_episodes: episodes
    })
    .then((res) => {
      setEpisodes(res.data);

      add({
        color: "primary",
        title: 'Saved',
        message: "Episodes rearrange have been saved.",
        isOpen: true,
        loading: false
      })
    })
    .catch((err) => {
      console.log(err)

      add({
        color: "primary",
        title: 'Error:',
        message: "Something went wrong.  Please contact us for help.",
        isOpen: true,
        loading: false
      })
    })
    // console.log(episodes)
  }

  useEffect(() => {
    fetchEpisodes()
  }, [id])

  const handlePreview = () => {
    screenContext.startFetching();
    justcastApi.post(`/v1/shows/${id}/preview_episodes_publish_date`, {
      increment: pubDateByIncrement
    })
    .then((res) => {
      setEpisodes(res.data)
      setTimeout(() => {
        screenContext.finishFetching();
      }, 400)
    })
    .catch((err) => {
      console.log(err);
      setTimeout(() => {
        screenContext.finishFetching();
      }, 400)
    })
  }

  if(denied) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <div className="container-fluid">
      {/* <Card>
        <CardHeader>
          <div className="row align-items-center">
            <div className="col">
              <h3 className="card-header-title">Episode Publish date</h3>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-12">
              <p>Publish each episode one day apart based on the current order.</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ButtonGroup>
                <Button size="sm" color="warning" outline={!pubDateByIncrement} onClick={() => {
                  setPubDateByIncrement(true)
                }}>Ascending</Button>
                <Button size="sm" color="warning" outline={pubDateByIncrement} onClick={() => {
                  setPubDateByIncrement(false)
                }}>Descending</Button>
              </ButtonGroup>
            </div>
            <div>
              <Button color="primary" size="sm" onClick={handlePreview}>Preview change</Button> {" "}
            </div>
          </div>
        </CardBody>
      </Card> */}

      <div className="row">
        <div className="col-12">
          <div className="alert alert-warning" role="alert">
            Use drag and drop to set your episode number
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="card-header-title">Episodes</h4>
                </div>
                <div className="col-auto">
                  <div className="btn btn-primary btn-sm" onClick={handleSaveChange}>Save changes</div>
                </div>
              </div>
            </div>
            <div className="card-body">
              {
                screenContext.state.loading ? <Spinner color="primary" /> : <EpisodeListSortable items={episodes} handleSorting={handleSorting}/>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EpisodesRearrange;