import React from 'react'
import { Modal, ModalHeader, ModalBody, FormText, ModalFooter, Button, Label, Form, FormGroup} from 'reactstrap';
import { useForm } from "react-hook-form";

export default function SpeakerNameModal({
  speakerModal,
  toggleModal,
  handleSave
}) {

  const {
    register, reset, handleSubmit, formState: { errors }
  } = useForm({
    values: {
      speaker_name: speakerModal.speaker_name,
      sentence_speaker_code: speakerModal.sentence_speaker_code,
      minmima_index: speakerModal.minmima_index,
      replace_all: false
    }
  });

  const closeModal = () => {
    toggleModal();
    reset();
  }

  const onSubmit = (data) => {
    handleSave(data)
    toggleModal();
    reset();
  }

  return (
    <Modal isOpen={speakerModal.open} toggle={closeModal}>
      <ModalHeader>
        Update Speaker
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="speaker_name">Speaker Name</Label>
            <input
              id="speaker_name"
              name="speaker_name"
              type="text"
              placeholder="Joe"
              className="form-control"
              {...register("speaker_name", {required: true, minLength: 2 })}
            />
            {errors.speaker_name && <FormText color="danger">The name is required and must be at least 2 characters long.</FormText>}
          </FormGroup>
          <FormGroup check>
            <Label check>
              <input type="checkbox" name="radioSameSpeaker" className='form-check-input'
              {...register("replace_all")}
              />{' '}
              {`Replace all ${speakerModal.speaker_name}`}
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSubmit(onSubmit)} color="primary">Save</Button>{' '}
        <Button color="secondary" onClick={closeModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
