import React from 'react'
import { useForm } from "react-hook-form";
import { Button, Label, Form, FormGroup, Alert} from 'reactstrap';

export default function SpeakerForm({
  handleSave
}) {

  const {
    register, reset, handleSubmit, formState: { errors }
  } = useForm({
    values: {
      is_multiple_speakers: false
    }
  });

  const onSubmit = (data, event) => {
    handleSave(data, event)
    reset();
  }

  return (
    <>
      <h4 className="alert-heading">Missing transcript</h4>
      <p>This episode has not been transcribed yet. Would you like to generate one?</p>
      <Form>
        <FormGroup check inline>
          <Label check>
            <input type="checkbox" name="radioMultipleSpeakers" className='form-check-input'
            {...register("is_multiple_speakers")}
            />{' Has multiple speakers'}
          </Label>
        </FormGroup>
        <Button onClick={handleSubmit(onSubmit)}>Generate transcript</Button>
      </Form>
    </>
  )
}
