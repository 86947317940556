import React from 'react'
import { Card, CardBody, Button, CustomInput, Row } from 'reactstrap';

export default function PremiumSellerPrice({price, idx, handlePriceToggled}) {
  return (
    <Card>
      <CardBody>
        <Row className="align-items-center justify-content-between">
          <div className="col">
            <h4 className="mb-1">{price.unit_amount_text} {price.product_currency_code_value}</h4>
            <p className="small text-muted mb-1">{price.recurring_interval_name}</p>
          </div>
          <div className="col-auto">
            <CustomInput 
              type="switch"
              id={`is_premium_subscription-${price.id}`}
              onChange={handlePriceToggled}
              name={idx}
              checked={price.is_premium_subscription ? price.is_premium_subscription : false}
            />
          </div>
        </Row>
      </CardBody>
    </Card>
  )
}
