import React, {useState, useEffect, useContext} from 'react';
import { Redirect } from "react-router-dom";
import {CardElement, injectStripe} from 'react-stripe-elements';
import justcastApi from '../api/justcast'
import {Context as AuthContext} from '../context/AuthContext'
import {Context as ModalContext} from '../context/ModalContext'
import {Context as AlertContext} from '../context/AlertContext'
import SpinnerLoading from './SpinnerLoading'

const StripeCheckoutForm = ({price, name, planId, stripe}) => {
  const authContext = useContext(AuthContext);
  const {addWithMessages} = useContext(ModalContext);
  const {addWithTimeout} = useContext(AlertContext);

  const [completeState, setCompleteState] = useState(false)
  const [paymentState, setPaymentState] = useState(false);

  const email = authContext.state.email;
  
  const handlSubmit = (ev) => {
    stripe.createToken({name: email})
    .then((token) => {
      setPaymentState(true);
      if(token) {
        const url = `/v1/subscriptions`;
        return justcastApi.post(url, {
          "plan": planId,
          "stripeToken": token.token.id          
        })
      } else {
        throw new Error('Please try again with another credit card')
      }  
      // throw new Error('Please try again with another credit card')    
    })
    .then((res) => {   
      const data = res.data;
      return authContext.updatePaymentPlan({stripe_plan_id: data.stripe_plan_id, expires_at: data.expires_at});
    })
    .then(() => {
      setPaymentState(false);
      setCompleteState(true);
      addWithTimeout({color: "warning", title: 'Payment Success', message: "Your payment was successful!  Happy podcasting!", isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      setPaymentState(false);
      if(err.response && err.response.data) {
        const message = err.response.data.errors[0];        
        addWithMessages({
          color: "primary", title: 'Error', messages: [message, "Please try with another card."] , isOpen: true, loading: false
        })
      } else if (err.message) {      
        addWithMessages({
          color: "primary", title: 'Error', messages: [err.message, "Please try with another card."] , isOpen: true, loading: false
        })
      }
    })    
  }

  if (completeState) return <Redirect to='/plans' />;
  if (paymentState) return <SpinnerLoading title={'This will take few seconds, please waiting.'}></SpinnerLoading>

  return (
    <div>
      <div className="stipe-form checkout">
        <h5>PAYMENT INFORMATION</h5>
        <p>{name}</p>
        <CardElement />        
        <button onClick={handlSubmit}>{`Pay $${price}`}</button>
      </div>      
    </div>
  )
}

export default injectStripe(StripeCheckoutForm);