import moment from 'moment'
import _ from 'underscore'

export const formatNumber = (num) => {  
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
}

export const sortedDataByTargetName = (data, targetName) => {
  if(data) {
    const targetData = data[targetName];
    return _.sortBy(targetData, 'count').reverse();
  }
  return [];
}

export const subscriptionReportLineData = (data, title) => {
  const labels = data.map((item) => moment(item.date, "YYYY-MM-DD").format('MMM D'))
  const values = data.map((item) => item.data ? item.data.subscriber_count : 0);
  return {
    labels,
    datasets: [
      {
        label: title,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#E3E3E3)',
        borderColor: '#2c7be5', // line color
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBackgroundColor: '#fff',
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#E3E3E3',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 1,
        pointRadius: 0,
        pointHitRadius: 10,
        data: values
      }
    ]
  };  
}


export const downloadReportLineData = (data, title) => {
  const labels = data.map((item) => moment(item.date, "YYYY-MM-DD").format('MMM D'))
  const values = data.map((item) => item.data ? item.data.download_count : 0);
  return {
    labels,
    datasets: [
      {
        label: title,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#E3E3E3)',
        borderColor: '#2c7be5', // line color
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBackgroundColor: '#fff',
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#E3E3E3',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 1,
        pointRadius: 0,
        pointHitRadius: 10,
        data: values
      }
    ]
  };  
}


export const totalDownloadReportLineData = (data, title) => {
  const labels = data.map((item) => moment(item.date, "YYYY-MM-DD").format('MMM D'))
  const values = data.map((item) => item.count ? item.count : 0);
  return {
    labels,
    datasets: [
      {
        label: title,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#E3E3E3)',
        borderColor: '#2c7be5', // line color
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBackgroundColor: '#fff',
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#E3E3E3',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 1,
        pointRadius: 0,
        pointHitRadius: 10,
        data: values
      }
    ]
  };  
}

export default {};