import React from 'react'
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Thumbs from './Thumbs'

const ImageUploaderErrors = ({errors}) => {
  return (
    <div className="col-12 col-md-6">
      <div className="card bg-light border">
        <div className="card-body">        
          <h4 className="mb-2">
            <i className="fe fe-alert-triangle"></i> Errors
          </h4>
          <ul>
            {
              errors.map((err) => 
                <li key={err}>{err}</li>
              )
            }
          </ul>
        </div>
      </div>    
    </div>
  ) 
}

const WarningMessage = ({text, files, errors, removeFile, setImage}) => {
  if(errors.length > 0) {
    return <ImageUploaderErrors errors={errors}/>
  } else if(files.length > 0) {
    return (
      <Thumbs files={files} removeFile={removeFile} setImage={setImage}/>
    )   
  } else if(text) {
    return (
      <div className="col-12 col-md-6">
        <div className="card bg-light border">
          <div className="card-body">        
            <h4 className="mb-2">
              <i className="fe fe-alert-triangle"></i> Warning
            </h4>
            <p className="small text-muted mb-0">
              {text}
            </p>
          </div>
        </div>    
      </div>
    )    
  }
  return null;
}
  

export default WarningMessage;